import {css} from "styled-components";
import {fontFamilyBold, fontFamilyBoldImportant, fontFamilyRegular} from "style/fonts";

export const EditorStyle = css`
  //@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;500;700&display=swap');

  .SsqEditor {
    font-size: 15px;
    line-height: 1.7;
    letter-spacing: -0.2px;
    //font-weight: normal;
    ${fontFamilyRegular};
  }

  .SsqEditor > * {
    margin: 0;
  }

  .SsqEditor b {
    //font-weight: 600 !important;
    ${fontFamilyBoldImportant};
  }

  .SsqEditor .cke_editable {
    font-size: 15px;
    line-height: 1.7;
    /* Fix for missing scrollbars with RTL texts. (#10488) */
    word-wrap: break-word;
  }


  /*preview style*/

  body.SsqEditor {
    width: 770px;
    overflow-x: auto;
  }

  .SsqEditor.cke_editable {
    font-size: 15px;
    line-height: 1.7;
    word-wrap: break-word;
    ${fontFamilyRegular};
  }

  .SsqEditor.cke_editable p {
    padding: 0;
    margin: 0;
    outline: 0;
    border: 0;
    letter-spacing: -0.2px;
  }

  .SsqEditor blockquote {
    font-style: normal;
    padding: 2px 0;
    border-style: solid;
    border-color: #ccc;
    border-width: 0;
  }

  .SsqEditor .cke_contents_ltr blockquote {
    padding-left: 20px;
    padding-right: 8px;
    border-left-width: 5px;
  }

  .SsqEditor .cke_contents_rtl blockquote {
    padding-left: 8px;
    padding-right: 20px;
    border-right-width: 5px;
  }

  .SsqEditor a {
    color: #0782C1;
  }

  .SsqEditor ol, .SsqEditor ul, .SsqEditor dl {
    padding: 0 20px
  }

  .SsqEditor ol li {
    list-style-type: decimal;
    padding-left: 5px;
    list-style-position: inside
  }

  .SsqEditor ul {
  }

  .SsqEditor ul li {
    position: relative;
    list-style-type: square;
    list-style-position: outside;
  }

  .SsqEditor .Special_ul {
    position: relative;
  }

  .SsqEditor ul li::marker {
    color: #407EDB;
    font-size: 13px;
  }

  .SsqEditor h1, .SsqEditor h2, .SsqEditor h3, .SsqEditor h4, .SsqEditor h5, .SsqEditor h6 {
    margin: 0;
    line-height: 1.5;
    ${fontFamilyBold};
    font-weight: normal !important; // 아이폰에서, 폰트가 두꺼워지는 현상 방지.
  }

  .ck-content h1 {
    font-size: 18px;
    color: #407EDB;
    margin-bottom: 4px;
  }

  .ck-content h2 {
    font-size: 16px;
    color: #2A2C2F;
    padding: 0;
    margin-bottom: 4px;
  }

  .ck-content h3 {
    font-size: 15px;
    color: #2A2C2F;
    margin-bottom: 4px;
  }

  .SsqEditor strong {
    ${fontFamilyBoldImportant};
  }

  .SsqEditor hr, .ck-content hr {
    border: 0px;
    border-top: 1px solid #C7D6EA;
    margin: 10px 0;
    height: 0px;
  }

  .SsqEditor img {
    max-width: 100%;
    height: auto !important;
  }

  .SsqEditor img.right {
    border: 1px solid #ccc;
    float: right;
    margin-left: 15px;
    padding: 5px;
  }

  .SsqEditor img.left {
    border: 1px solid #ccc;
    float: left;
    margin-right: 15px;
    padding: 5px;
  }

  .SsqEditor pre {
    white-space: pre-wrap; /* CSS 2.1 */
    word-wrap: break-word; /* IE7 */
    -moz-tab-size: 4;
    tab-size: 4;
  }

  .SsqEditor .marker {
    background: linear-gradient(to top, #FFFAA0 50%, transparent 50%) !important;
  }

  .SsqEditor .Special {
    background: #F7F8FB;
    border-left: solid 2px #407DD9;
    padding: 8px 16px;
    font-size: 15px;
  }

  .SsqEditor span[lang] {
    font-family: inherit !important;
  }

  .SsqEditor figure:not(.image) {

  }

  .SsqEditor figure img {
    margin: 0;
  }

  .SsqEditor figure > figcaption {
    text-align: center;
    display: block; /* For IE8 */
    margin-top: 4px;
  }

  .SsqEditor a > img {
    padding: 1px;
    margin: 1px;
    border: none;
    //outline: 1px solid #0782C1;
  }

  /* Widget Styles */

  .SsqEditor .code-featured {
    border: 5px solid red;
  }

  .SsqEditor .math-featured {
    padding: 20px;
    box-shadow: 0 0 2px rgba(200, 0, 0, 1);
    background-color: rgba(255, 0, 0, 0.05);
    margin: 10px;
  }

  .SsqEditor .image-clean {
    border: 0;
    background: none;
    padding: 0;
  }

  .SsqEditor .image-clean > figcaption {
    font-size: .9em;
    text-align: right;
  }

  .SsqEditor .image-grayscale {
    background-color: white;
    color: #666;
  }

  .SsqEditor .image-grayscale img, img.image-grayscale {
    filter: grayscale(100%);
  }

  .SsqEditor figure:has(table) {
    background-color: transparent;
    border: none;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .SsqEditor table, .ck-content .table table {
    border-collapse: collapse;
    border-color: #C7D6EA;
    border-top: solid 2px #407DD9;
    border-bottom: solid 1px #90A0B6;
    border-left: none;
    border-right: none;
    max-width: 100%;
  }

  .SsqEditor table th, .SsqEditor table td, .ck-content .table table th, .ck-content .table table td {
    border: solid 1px #C7D6EA;
    background-color: transparent;
    padding: 7px 8px;
    text-align: left;
  }

  .ck-content .table .ck-table-resized {
    table-layout: fixed;
    width: 100%;
  }

  .ck.ck-button.ck-heading_subtitle, .ck-content .subtitle {
    color: #7783a1
  }

  .ck-content mark.marker-yellow {
    background: linear-gradient(to top, #fffaa0 50%, transparent 50%);
  }


  //.SsqEditor th:first-child, .SsqEditor td:first-child, .ck-content .table th:first-child, .ck-content .table td:first-child {
  //  border-left: none;
  //}
  //
  //.SsqEditor th:last-child, .SsqEditor td:last-child, .ck-content .table th:last-child, .ck-content .table td:last-child {
  //  border-right: none;
  //}

  .SsqEditor tr:last-child th, .SsqEditor tr:last-child td, .ck-content .table tr:last-child th, .ck-content .table tr:last-child td {
    border-bottom: none;
  }

  .SsqEditor table p, .ck-content .table table p {
    padding: 0px;
    margin: 0px;
  }

  .SsqEditor figure:not(.image) img {
    max-width: 100% !important;
    height: auto;
    margin: 35px 0
  }

  .ck-content span > img {
    margin: auto;
  }

  .cke_widget_embed img {
    margin: 0;
  }

  .SsqEditor .iframely-responsive {
    height: auto !important;
    padding: 0 !important;
  }

  @media screen and (max-width: 764px) {
    .SsqEditor table {
      width: 100% !important;
      max-width: 100%;
      table-layout: fixed;
      word-break: break-word;
    }

    .ck-content .table table {
      width: 100% !important;
      max-width: 100%;
      table-layout: fixed;
      word-break: break-word;
    }
  }

  .SsqEditor u, .ck-content u {
    text-decoration: underline;
  }

  // ckeditor style 


  /* @ckeditor/ckeditor5-table/theme/tablecolumnresize.css */
  //
  //.ck-content .table .ck-table-resized {
  //  table-layout: fixed;
  //}
  //
  ///* @ckeditor/ckeditor5-table/theme/tablecolumnresize.css */
  //
  //.ck-content .table table {
  //  overflow: hidden;
  //}
  //
  ///* @ckeditor/ckeditor5-table/theme/tablecolumnresize.css */
  //
  //.ck-content .table td,
  //.ck-content .table th {
  //  overflow-wrap: break-word;
  //  position: relative;
  //}

  /* @ckeditor/ckeditor5-table/theme/tablecaption.css */

  .ck-content .table > figcaption {
    //display: table-caption;
    caption-side: top;
    word-break: break-word;
    text-align: center;
    //color: var(--ck-color-table-caption-text);
    //background-color: var(--ck-color-table-caption-background);
    padding: .6em;
    font-size: .75em;
    //outline-offset: -1px;
  }

  /* @ckeditor/ckeditor5-table/theme/table.css */
  //
  //.ck-content .table {
  //  margin: 0.9em auto;
  //  //    display: table;
  //}
  //
  ///* @ckeditor/ckeditor5-table/theme/table.css */
  //
  //.ck-content .table table {
  //  border-collapse: collapse;
  //  border-spacing: 0;
  //  width: 100%;
  //  height: 100%;
  //  //border: 1px double hsl(0, 0%, 70%);
  //}
  //
  ///* @ckeditor/ckeditor5-table/theme/table.css */
  //
  //.ck-content .table table td,
  //.ck-content .table table th {
  //  min-width: 2em;
  //  padding: .4em;
  //  //border: 1px solid hsl(0, 0%, 75%);
  //}
  //
  ///* @ckeditor/ckeditor5-table/theme/table.css */
  //
  //.ck-content .table table th {
  //  font-weight: bold;
  //  //background: hsla(0, 0%, 0%, 5%);
  //}
  //
  ///* @ckeditor/ckeditor5-table/theme/table.css */
  //
  //.ck-content[dir="rtl"] .table th {
  //  text-align: right;
  //}
  //
  ///* @ckeditor/ckeditor5-table/theme/table.css */
  //
  //.ck-content[dir="ltr"] .table th {
  //  text-align: left;
  //}

  /* @ckeditor/ckeditor5-page-break/theme/pagebreak.css */
  //
  //.ck-content .page-break {
  //  position: relative;
  //  clear: both;
  //  padding: 5px 0;
  //  display: flex;
  //  align-items: center;
  //  justify-content: center;
  //}
  //
  ///* @ckeditor/ckeditor5-page-break/theme/pagebreak.css */
  //
  //.ck-content .page-break::after {
  //  content: '';
  //  position: absolute;
  //  border-bottom: 2px dashed hsl(0, 0%, 77%);
  //  width: 100%;
  //}
  //
  ///* @ckeditor/ckeditor5-page-break/theme/pagebreak.css */
  //
  //.ck-content .page-break__label {
  //  position: relative;
  //  z-index: 1;
  //  padding: .3em .6em;
  //  display: block;
  //  text-transform: uppercase;
  //  border: 1px solid hsl(0, 0%, 77%);
  //  border-radius: 2px;
  //  font-family: Helvetica, Arial, Tahoma, Verdana, Sans-Serif;
  //  font-size: 0.75em;
  //  font-weight: bold;
  //  color: hsl(0, 0%, 20%);
  //  background: hsl(0, 0%, 100%);
  //  box-shadow: 2px 2px 1px hsla(0, 0%, 0%, 0.15);
  //  -webkit-user-select: none;
  //  -moz-user-select: none;
  //  -ms-user-select: none;
  //  user-select: none;
  //}

  /* @ckeditor/ckeditor5-media-embed/theme/mediaembed.css */

  .ck-content .media {
    clear: both;
    margin: 0.9em 0;
    display: block;
    min-width: 15em;
  }

  /* @ckeditor/ckeditor5-list/theme/todolist.css */
  //
  //.ck-content .todo-list {
  //  list-style: none;
  //}
  //
  ///* @ckeditor/ckeditor5-list/theme/todolist.css */
  //
  //.ck-content .todo-list li {
  //  margin-bottom: 5px;
  //}
  //
  ///* @ckeditor/ckeditor5-list/theme/todolist.css */
  //
  //.ck-content .todo-list li .todo-list {
  //  margin-top: 5px;
  //}
  //
  ///* @ckeditor/ckeditor5-list/theme/todolist.css */
  //
  //.ck-content .todo-list .todo-list__label > input {
  //  -webkit-appearance: none;
  //  display: inline-block;
  //  position: relative;
  //  width: var(--ck-todo-list-checkmark-size);
  //  height: var(--ck-todo-list-checkmark-size);
  //  vertical-align: middle;
  //  border: 0;
  //  left: -25px;
  //  margin-right: -15px;
  //  right: 0;
  //  margin-left: 0;
  //}
  //
  ///* @ckeditor/ckeditor5-list/theme/todolist.css */
  //
  //.ck-content .todo-list .todo-list__label > input::before {
  //  display: block;
  //  position: absolute;
  //  box-sizing: border-box;
  //  content: '';
  //  width: 100%;
  //  height: 100%;
  //  border: 1px solid hsl(0, 0%, 20%);
  //  border-radius: 2px;
  //  transition: 250ms ease-in-out box-shadow, 250ms ease-in-out background, 250ms ease-in-out border;
  //}
  //
  ///* @ckeditor/ckeditor5-list/theme/todolist.css */
  //
  //.ck-content .todo-list .todo-list__label > input::after {
  //  display: block;
  //  position: absolute;
  //  box-sizing: content-box;
  //  pointer-events: none;
  //  content: '';
  //  left: calc(var(--ck-todo-list-checkmark-size) / 3);
  //  top: calc(var(--ck-todo-list-checkmark-size) / 5.3);
  //  width: calc(var(--ck-todo-list-checkmark-size) / 5.3);
  //  height: calc(var(--ck-todo-list-checkmark-size) / 2.6);
  //  border-style: solid;
  //  border-color: transparent;
  //  border-width: 0 calc(var(--ck-todo-list-checkmark-size) / 8) calc(var(--ck-todo-list-checkmark-size) / 8) 0;
  //  transform: rotate(45deg);
  //}
  //
  ///* @ckeditor/ckeditor5-list/theme/todolist.css */
  //
  //.ck-content .todo-list .todo-list__label > input[checked]::before {
  //  background: hsl(126, 64%, 41%);
  //  border-color: hsl(126, 64%, 41%);
  //}
  //
  ///* @ckeditor/ckeditor5-list/theme/todolist.css */
  //
  //.ck-content .todo-list .todo-list__label > input[checked]::after {
  //  border-color: hsl(0, 0%, 100%);
  //}
  //
  ///* @ckeditor/ckeditor5-list/theme/todolist.css */
  //
  //.ck-content .todo-list .todo-list__label .todo-list__label__description {
  //  vertical-align: middle;
  //}

  /* @ckeditor/ckeditor5-image/theme/image.css */

  .ck-content .image {
    display: table;
    clear: both;
    text-align: center;
    margin: 0.9em auto;
    min-width: 50px;
  }

  /* @ckeditor/ckeditor5-image/theme/image.css */

  .ck-content .image img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    min-width: 100%;
  }

  /* @ckeditor/ckeditor5-image/theme/image.css */

  .ck-content .image-inline {
    /*
     * Normally, the .image-inline would have "display: inline-block" and "img { width: 100% }" (to follow the wrapper while resizing).;
     * Unfortunately, together with "srcset", it gets automatically stretched up to the width of the editing root.
     * This strange behavior does not happen with inline-flex.
     */
    display: inline-flex;
    max-width: 100%;
    align-items: flex-start;
  }

  /* @ckeditor/ckeditor5-image/theme/image.css */

  .ck-content .image-inline picture {
    display: flex;
  }

  /* @ckeditor/ckeditor5-image/theme/image.css */

  .ck-content .image-inline picture,
  .ck-content .image-inline img {
    flex-grow: 1;
    flex-shrink: 1;
    max-width: 100%;
  }

  /* @ckeditor/ckeditor5-image/theme/imageresize.css */

  .ck-content .image.image_resized {
    max-width: 100%;
    display: block;
    box-sizing: border-box;
  }

  /* @ckeditor/ckeditor5-image/theme/imageresize.css */

  .ck-content .image.image_resized img {
    width: 100%;
  }

  /* @ckeditor/ckeditor5-image/theme/imageresize.css */

  .ck-content .image.image_resized > figcaption {
    display: block;
  }

  /* @ckeditor/ckeditor5-image/theme/imagecaption.css */

  //.ck-content .image > figcaption {
  //  display: table-caption;
  //  caption-side: bottom;
  //  word-break: break-word;
  //  color: var(--ck-color-image-caption-text);
  //  background-color: var(--ck-color-image-caption-background);
  //  padding: .6em;
  //  font-size: .75em;
  //  outline-offset: -1px;
  //}

  /* @ckeditor/ckeditor5-highlight/theme/highlight.css */

  .ck-content .marker-yellow {
    background-color: var(--ck-highlight-marker-yellow);
  }

  /* @ckeditor/ckeditor5-highlight/theme/highlight.css */

  .ck-content .marker-green {
    background-color: var(--ck-highlight-marker-green);
  }

  /* @ckeditor/ckeditor5-highlight/theme/highlight.css */

  .ck-content .marker-pink {
    background-color: var(--ck-highlight-marker-pink);
  }

  /* @ckeditor/ckeditor5-highlight/theme/highlight.css */

  .ck-content .marker-blue {
    background-color: var(--ck-highlight-marker-blue);
  }

  /* @ckeditor/ckeditor5-highlight/theme/highlight.css */

  .ck-content .pen-red {
    color: var(--ck-highlight-pen-red);
    background-color: transparent;
  }

  /* @ckeditor/ckeditor5-highlight/theme/highlight.css */

  .ck-content .pen-green {
    color: var(--ck-highlight-pen-green);
    background-color: transparent;
  }

  /* @ckeditor/ckeditor5-list/theme/list.css */

  .ck-content ol {
    list-style-type: decimal;
  }

  /* @ckeditor/ckeditor5-list/theme/list.css */

  .ck-content ol ol {
    list-style-type: lower-latin;
  }

  /* @ckeditor/ckeditor5-list/theme/list.css */

  .ck-content ol ol ol {
    list-style-type: lower-roman;
  }

  /* @ckeditor/ckeditor5-list/theme/list.css */

  .ck-content ol ol ol ol {
    list-style-type: upper-latin;
  }

  /* @ckeditor/ckeditor5-list/theme/list.css */

  .ck-content ol ol ol ol ol {
    list-style-type: upper-roman;
  }

  /* @ckeditor/ckeditor5-list/theme/list.css */

  .ck-content ul {
    list-style-type: disc;
  }

  /* @ckeditor/ckeditor5-list/theme/list.css */

  .ck-content ul ul {
    list-style-type: circle;
  }

  /* @ckeditor/ckeditor5-list/theme/list.css */

  .ck-content ul ul ul {
    list-style-type: square;
  }

  /* @ckeditor/ckeditor5-list/theme/list.css */

  .ck-content ul ul ul ul {
    list-style-type: square;
  }

  /* @ckeditor/ckeditor5-image/theme/imagestyle.css */

  .ck-content .image-style-block-align-left,
  .ck-content .image-style-block-align-right {
    max-width: calc(100% - var(--ck-image-style-spacing));
  }

  /* @ckeditor/ckeditor5-image/theme/imagestyle.css */

  .ck-content .image-style-align-left,
  .ck-content .image-style-align-right {
    clear: none;
  }

  /* @ckeditor/ckeditor5-image/theme/imagestyle.css */

  .ck-content .image-style-side {
    float: right;
    margin-left: var(--ck-image-style-spacing);
    max-width: 50%;
  }

  /* @ckeditor/ckeditor5-image/theme/imagestyle.css */

  .ck-content .image-style-align-left {
    float: left;
    margin-right: var(--ck-image-style-spacing);
  }

  /* @ckeditor/ckeditor5-image/theme/imagestyle.css */

  .ck-content .image-style-align-center {
    margin-left: auto;
    margin-right: auto;
  }

  /* @ckeditor/ckeditor5-image/theme/imagestyle.css */

  .ck-content .image-style-align-right {
    float: right;
    margin-left: var(--ck-image-style-spacing);
  }

  /* @ckeditor/ckeditor5-image/theme/imagestyle.css */

  .ck-content .image-style-block-align-right {
    margin-right: 0;
    margin-left: auto;
  }

  /* @ckeditor/ckeditor5-image/theme/imagestyle.css */

  .ck-content .image-style-block-align-left {
    margin-left: 0;
    margin-right: auto;
  }

  /* @ckeditor/ckeditor5-image/theme/imagestyle.css */

  .ck-content p + .image-style-align-left,
  .ck-content p + .image-style-align-right,
  .ck-content p + .image-style-side {
    margin-top: 0;
  }

  /* @ckeditor/ckeditor5-image/theme/imagestyle.css */

  .ck-content .image-inline.image-style-align-left,
  .ck-content .image-inline.image-style-align-right {
    margin-top: var(--ck-inline-image-style-spacing);
    margin-bottom: var(--ck-inline-image-style-spacing);
  }

  /* @ckeditor/ckeditor5-image/theme/imagestyle.css */

  .ck-content .image-inline.image-style-align-left {
    margin-right: var(--ck-inline-image-style-spacing);
  }

  /* @ckeditor/ckeditor5-image/theme/imagestyle.css */

  .ck-content .image-inline.image-style-align-right {
    margin-left: var(--ck-inline-image-style-spacing);
  }

  /* @ckeditor/ckeditor5-basic-styles/theme/code.css */

  .ck-content code {
    background-color: hsla(0, 0%, 78%, 0.3);
    padding: .15em;
    border-radius: 2px;
  }

  /* @ckeditor/ckeditor5-block-quote/theme/blockquote.css */

  .ck-content blockquote {
    overflow: hidden;
    //padding-right: 1.5em;
    //padding-left: 1.5em;
    padding: 8px 16px;
    margin-left: 0;
    margin-right: 0;
    font-style: normal;
    font-size: 15px;
    //border-left: solid 5px hsl(0, 0%, 80%);
    border-left: solid 2px #407dd9;
    background-color: #f7f8fb;
  }

  /* @ckeditor/ckeditor5-block-quote/theme/blockquote.css */

  //.ck-content[dir="rtl"] blockquote {
  //  border-left: 0;
  //  border-right: solid 5px hsl(0, 0%, 80%);
  //}

  /* @ckeditor/ckeditor5-font/theme/fontsize.css */

  .ck-content .text-tiny {
    font-size: .7em;
  }

  /* @ckeditor/ckeditor5-font/theme/fontsize.css */

  .ck-content .text-small {
    font-size: .85em;
  }

  /* @ckeditor/ckeditor5-font/theme/fontsize.css */

  .ck-content .text-big {
    font-size: 1.4em;
  }

  /* @ckeditor/ckeditor5-font/theme/fontsize.css */

  .ck-content .text-huge {
    font-size: 1.8em;
  }

  /* @ckeditor/ckeditor5-mention/theme/mention.css */

  //.ck-content .mention {
  //  background: var(--ck-color-mention-background);
  //  color: var(--ck-color-mention-text);
  //}

  /* @ckeditor/ckeditor5-horizontal-line/theme/horizontalline.css */

  //.ck-content hr {
  //  margin: 15px 0;
  //  height: 4px;
  //  background: hsl(0, 0%, 87%);
  //  border: 0;
  //}

  /* @ckeditor/ckeditor5-code-block/theme/codeblock.css */

  .ck-content pre {
    padding: 1em;
    color: hsl(0, 0%, 20.8%);
    background: hsla(0, 0%, 78%, 0.3);
    border: 1px solid hsl(0, 0%, 77%);
    border-radius: 2px;
    text-align: left;
    direction: ltr;
    tab-size: 4;
    white-space: pre-wrap;
    font-style: normal;
    min-width: 200px;
  }

  /* @ckeditor/ckeditor5-code-block/theme/codeblock.css */

  .ck-content pre code {
    background: unset;
    padding: 0;
    border-radius: 0;
  }

  //@media print {
  //  /* @ckeditor/ckeditor5-page-break/theme/pagebreak.css */
  //  .ck-content .page-break {
  //    padding: 0;
  //  }
  //
  //  /* @ckeditor/ckeditor5-page-break/theme/pagebreak.css */
  //  .ck-content .page-break::after {
  //    display: none;
  //  }
  //}
`;


