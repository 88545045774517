// @flow
import * as React from 'react';
import {useState} from 'react';
import {PartnerQnaRatingWrap} from "communitypage/partner/qna/partner-qna-popup-style";

type Props = {};
export const PartnerQnaRating = (props: Props) => {

  const [rating, setRating] = useState<number>(0);
  const [ratingFixed, setRatingFixed] = useState(rating);
  const handleLeftHalfEnter = (idx: number) => setRating(idx + 0.5);
  const handleRightHalfEnter = (idx: number) => setRating(idx + 1.0);

  const handleStarClick = () => {
    setRatingFixed(rating);
  };

  const handleStarLeave = () => {
    if (rating !== ratingFixed) {
      setRating(ratingFixed);
    }
  };


  return (
    <PartnerQnaRatingWrap>
      <p className={"rating-num"}> {rating.toFixed(1)}<span>/5.0</span></p>
      <div className={"rating-star"}>
        {Array(5)
          .fill(0)
          .map((i, idx) => (
            <p key={idx} onClick={handleStarClick}>
              {rating - Math.floor(rating) === 0.5 && Math.floor(rating) === idx ? (
                <span className={"half"}
                  key={idx}
                />
              ) : idx + 1 > rating ? (
                <span className={"empty"}
                  key={idx}
                />
              ) : (
                <span className={"fill"}
                  key={idx}
                />
              )}
              <span
                className={"star-left"}
                key={idx + "left"}
                onMouseEnter={() => handleLeftHalfEnter(idx)}
                onMouseLeave={handleStarLeave}
              />
              <span
                className={"star-right"}
                key={idx + "right"}
                onMouseEnter={() => handleRightHalfEnter(idx)}
                onMouseLeave={handleStarLeave}
              />
            </p>
          ))}
      </div>
    </PartnerQnaRatingWrap>
  );
};