import React, {Component} from "react";

import {LanguageService} from "language/LanguageService";
import {SsqImages} from "images/SsqImages";
import {SubmitTerms} from "style/CommonStyledComponents";
import {ISignupValues} from "repositories/AccountService";
import {
  renderTermsKoKr,
  renderTermsEn,
  renderPrivateEssentialKoKr,
  renderPrivateEssentialEn,
  renderPrivateOptionallKoKr
} from "./SubmitTermsPopupItem";
import {LANG_KO_KR} from "language/langutil";

interface Props {
  languageService: LanguageService;
  closeAllPopupFunc: Function;
  signupValues: ISignupValues;
  setSignupValues: Function;
  pageUp: Function;
  setAcceptAll: Function;
  acceptAll: boolean;
  showTermsDetailPopup: Function;
  isManageMyInfo: boolean;
  closeTermsPopup: Function;
}

interface State {
}

export class SubmitTermsPopup extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {

  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {

  }

  getTranslation(key: string): string {
    this.props.languageService.setNamespace("I18N_NAMESPACE_HEADER");
    return this.props.languageService.getTranslationByKey(key) || "";
  }

  closeAllPopup() {
    this.props.closeAllPopupFunc();
  }


  renderSubCheck() {
    return <div className={"input_row input_check sub_check"}>

      <div>
        <input
          id={"check-accept-terms"} type={"checkbox"}
          checked={this.props.signupValues.acceptTerms}
          disabled={this.props.isManageMyInfo}
          onChange={() => this.props.setSignupValues('acceptTerms', !this.props.signupValues.acceptTerms)}
        />

        <label htmlFor={"check-accept-terms"}/>
        <span className={"check_txt"}
          onClick={() => {
            if (!this.props.isManageMyInfo) this.props.setSignupValues('acceptTerms', !this.props.signupValues.acceptTerms);
          }}
        >{this.getTranslation("I18N_SIGNUP_ACCEPT_TERMS")}</span>
        <button onClick={() => this.props.showTermsDetailPopup()} style={{marginLeft: ".8rem"}}>
          {this.getTranslation("I18N_SIGNUP_ACCEPT_TERMS_CONDITIONS")}
        </button>

        {/*(필수) 이용 약관 동의*/}
        {
          this.props.languageService.getLanguage() === LANG_KO_KR ?
            renderTermsKoKr()
            :
            renderTermsEn()
        }
      </div>

      <div>
        <input
          id={"check-accept-private-essential"} type={"checkbox"}
          checked={this.props.signupValues.acceptPrivateEssential}
          disabled={this.props.isManageMyInfo}
          onChange={() => this.props.setSignupValues('acceptPrivateEssential', !this.props.signupValues.acceptPrivateEssential)}
        />
        <label htmlFor={"check-accept-private-essential"}/>
        <span className={"check_txt"}
          onClick={() => {
            if (!this.props.isManageMyInfo) this.props.setSignupValues('acceptPrivateEssential', !this.props.signupValues.acceptPrivateEssential);
          }}
        >{this.getTranslation("I18N_SIGNUP_ACCEPT_PRIVATE_ESSENTIAL")}</span>


        {/*(필수) 개인정보 처리방침 동의*/}
        {
          this.props.languageService.getLanguage() === LANG_KO_KR ?
            renderPrivateEssentialKoKr()
            :
            renderPrivateEssentialEn()
        }

      </div>


      {/*(선택) 개인정보 처리방침 동의*/}
      {
        this.props.languageService.getLanguage() === LANG_KO_KR && <div>
          <input
            id={"check-accept-private-optional"} type={"checkbox"}
            checked={this.props.signupValues.acceptPrivateOptional}
            onChange={() => this.props.setSignupValues('acceptPrivateOptional', !this.props.signupValues.acceptPrivateOptional)}
          />
          <label htmlFor={"check-accept-private-optional"}/>
          <span className={"check_txt"}
            onClick={() => this.props.setSignupValues('acceptPrivateOptional', !this.props.signupValues.acceptPrivateOptional)}
          >{this.getTranslation("I18N_SIGNUP_ACCEPT_PRIVATE_OPTIONAL")}</span>
          {renderPrivateOptionallKoKr()}
        </div>
      }
    </div>;
  }


  render() {
    return <SubmitTerms>

      {/* close button start*/}
      <button className={"close-button"} onClick={() => {
        if (this.props.isManageMyInfo) this.closeAllPopup();
        else {
          if (window.confirm(this.getTranslation("I18N_SIGNUP_LEAVE_POPUP_ALERT"))) {
            this.closeAllPopup();
          }
        }
      }
      }
      ><img alt={"close_small_icon.svg"} src={SsqImages.common.icon.closeSmallIcon()}/>
      </button>
      {/* close button end*/}


      {/* title start*/}
      <div className={"Signup_top"}>

        <div className={"popup_tit"} style={{letterSpacing: '-0.5px'}}>
          {!this.props.isManageMyInfo && <button onClick={() => this.props.closeTermsPopup()}>
            <img alt={"back_button.svg"} src={SsqImages.header.icon.backButton()}/>
          </button>}
          {this.getTranslation("I18N_TITLE_ACCEPT_TERMS")}
        </div>


      </div>
      {/* title end*/}

      <div className={"Terms_Input"}>
        <div className={"Signup-default-info"}>
          <div className={"input_row"}>

            {/* check all start */}
            <div className={"input_row input_check all_check"}>

              <input id={"check-accept-all"} type={"checkbox"}
                checked={this.props.acceptAll}
                onChange={() => this.props.setAcceptAll()}/>
              <label htmlFor={"check-accept-all"}/>
              <span className={"check_txt_all"}
                onClick={() => this.props.setAcceptAll()}
              >{this.getTranslation("I18N_SIGNUP_ACCEPT_ALL")}</span>
            </div>

            {/* check all end */}

            <div className={"popup_notice"}>
              {this.getTranslation("I18N_TITLE_ACCEPT_TERMS_INFORMATION")}
            </div>

            {/* check sub start */}
            {this.renderSubCheck()}
            {/* check sub end */}

          </div>
        </div>
      </div>


      <div className="Next-default-info">
        {(this.props.signupValues.acceptTerms && this.props.signupValues.acceptPrivateEssential) ?
          <div className={"Submit-Button"}
            onClick={() => this.props.pageUp(1)}>
            {this.getTranslation("I18N_TITLE_NEXT_PAGE_UP")}
          </div> :
          <div className={"UnSubmit-Button"}>
            {this.getTranslation("I18N_TITLE_NEXT_PAGE_UP")}
          </div>
        }
      </div>

    </SubmitTerms>;
  }

}