import loading from "images/common/loading.svg";
import close_small_icon from "images/common/close_small_icon.svg";
import select_arrow from "images/common/select_arrow.svg";
import tag_close_x from 'images/common/tag_close_x.svg';
import view_more_icon from 'images/common/view_more_icon.svg';
import icon_user_profile from 'images/common/icon_user_profile.svg';
import icon_user_mail from 'images/common/icon_user_mail.svg';

import add_icon from "images/boris/add_icon.svg";
import search_ent_btn from "images/boris/search_ent_btn.svg";
import icon_setting from "images/service/icon_setting.svg";


export const imgs = {
  common: {
    loading,
    close_small_icon,
    select_arrow,
    tag_close_x,
    view_more_icon,
    icon_user_profile,
    icon_user_mail
  },
  boris: {
    add_icon,
    search_ent_btn,
  },
  icon_setting,

};
