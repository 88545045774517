// @flow
import * as React from 'react';
import {useRef, useState} from 'react';
import styled from "styled-components";
import * as colors from "asset/style/color";
import {AssetImages} from "asset/images/AssetImages";
import {ProjectList} from "./ProjectList";
import {useSelector} from 'react-redux';
import {IfReduxWeatherState} from "store/reducers/reducerWeather";
import {useAssetLastUpdate, useAssetLoadingInfo, useAssetSelDev, useAssetSelPjt} from "store/reducers/reducerAsset";
import {AlarmModal} from "asset/layout/appbar/AlarmModal";
import {Link, useHistory} from "react-router-dom";
import {useSWRConfig} from 'swr';
import {formatDateTime} from "util/formatDateTime";
import {DEV_STAT} from "asset/models/defs";
import {StatusIcon} from "asset/cmpns/common/StatusIcon";
import {fontFamilyBold, fontFamilyMedium, fontFamilyRegular} from "asset/style/AssetFonts";
import clsx from "clsx";
import {useApiAlarmListByUserId} from "asset/apis/useApiAlarm";
import {useLoginInfo} from "hooks/useLoginInfo";
import {getIcon} from "asset/utils/asset-util";
import {i18ntrans} from "language/i18nkey";
import {getAppBarTitle} from "asset/utils/appbar-util";
import {AssetAppBarLoading} from "./AssetAppBarLoading";

type Props = {};
export const AssetAppBar = ({}: Props) => {
  const [showProjectList, setShowProjectList] = useState(false);
  const [selPjt, setSelPjt] = useAssetSelPjt();
  const [selDev, setSelDev] = useAssetSelDev();
  const buttonRef = useRef();
  const loginInfo = useLoginInfo();
  //const [, mutate] = useApiAssetProjectList({userId: loginInfo.userId});
  const {mutate} = useSWRConfig();
  const [showAlarmModal, setShowAlarmModal] = useState(false);
  const [alarmList] = useApiAlarmListByUserId({userId: loginInfo.userId});
  const pjtButtonRef = useRef();
  const t = i18ntrans.asset;
  const handleRefresh = () => {
    mutate(() => true);
    setLastUpdate();
  };

  const weather = useSelector((state: any): IfReduxWeatherState => state.weather);

  const w = weather?.weather;

  const history = useHistory();
  const isDifferentAppBarRender = history.location.pathname.startsWith("/asset/settings") || history.location.pathname.startsWith("/asset/profile");
  const [lastUpdate, setLastUpdate] = useAssetLastUpdate();
  const menuIcon = () => {
    if (history.location.pathname.startsWith('/asset/setting')) {
      return <img src={AssetImages.common.appbar.setting()} alt={"appbar_setting.svg"}/>;
    } else if (history.location.pathname.startsWith('/asset/profile')) {
      return <img src={AssetImages.common.appbar.account()} alt={"appbar_account.svg"}/>;
    }
  };

  const loading = useAssetLoadingInfo();
  return (
    <AssetAppBarWrap>
      <AppBarLt>
        <AppBarTitle>
          <p className="mobile menu_tit">
            {getAppBarTitle(history.location.pathname)}
          </p>
          {isDifferentAppBarRender ?
            <div className={"menu_tit non_mobile"}>
              {menuIcon()}
              {getAppBarTitle(history.location.pathname)}
            </div> :
            <div ref={pjtButtonRef}>
              {/* 상태에 맞는 icon 만 노출 */}
              {selDev?.devName &&
                <TitleIcon>
                  <StatusIcon icon={getIcon(selDev?.status as DEV_STAT)}/>
                </TitleIcon>
              }
              <h3 onClick={(e) => {
                setShowProjectList(showProjectList => !showProjectList);
              }}>{selDev?.devName || selPjt?.projectName || t("I18N_AST_ALL")} <span
                className={clsx("arrow_icon", showProjectList && 'on')}></span></h3>
              <ProjectList show={showProjectList} hide={() => setShowProjectList(false)} buttonRef={pjtButtonRef}/>
            </div>
          }
          {/* home이 아닐때만 navibar 노출 */}
          {
            (selPjt?.projectId && !isDifferentAppBarRender) &&
            <NaviBar>
              <Link to="#" onClick={(e) => {
                e.preventDefault();
                setSelPjt(null);
                setSelDev(null);
                if (history.location.pathname.startsWith('/asset/monitor')) {
                  history.push('/asset/monitor');
                } else {
                  history.push(history.location.pathname);
                }
              }}>{t("I18N_AST_ALL")}</Link>
              <Link to={"#"} onClick={(e) => {
                e.preventDefault();
                setSelDev(null);
                if (history.location.pathname.startsWith('/asset/monitor')) {
                  history.push("/asset/monitorproject/" + selPjt.projectId);
                } else {
                  history.push(history.location.pathname + "?projectId=" + selPjt.projectId);
                }
              }}>{selPjt.projectName}</Link>
              {selDev?.deviceId &&
                <Link to={"#"} onClick={(e) => {
                  e.preventDefault();
                  if (history.location.pathname.startsWith('/asset/monitor')) {
                    history.push("/asset/monitordevice/" + selDev.deviceId);
                  } else {
                    history.push(history.location.pathname + "?projectId=" + selPjt.projectId + "&deviceId=" + selDev.deviceId);
                  }
                }}>{selDev.devName}</Link>
              }
            </NaviBar>
          }
        </AppBarTitle>
      </AppBarLt>
      <AppBarRt>
        <EtcWrap>
          <p className="time">Last update<span>{formatDateTime(lastUpdate)}</span></p>
          <button className="btn_refresh" onClick={handleRefresh}>
            <img src={AssetImages.common.icon.refresh()} alt={"icon_refresh.svg"}/>
          </button>
          {/* 새로운 alerts이 있는경우 new_alert 클래스 추가 */}
          <button className={`alert_wrap ${alarmList?.some(ele => !ele.resolvedTime) ? 'new_alert' : ''}`}
            ref={buttonRef}
            onClick={(e) => {
              setShowAlarmModal(showAlarmModal => !showAlarmModal);
            }}>
            {
              alarmList?.some(ele => !ele.resolvedTime) && (
                <div>
                  <span>{t("I18N_AST_NEW_ALERTS")}</span>
                  <span className="badge">{alarmList.filter(ele => !ele.resolvedTime).length}</span>
                </div>
              )
            }
            <p className="icon"></p>
          </button>
        </EtcWrap>
      </AppBarRt>
      <AlarmModal show={showAlarmModal} hide={() => setShowAlarmModal(false)} buttonRef={buttonRef} alarmList={alarmList}/>
      <AssetAppBarLoading width={loading.width} loading={loading.loading} show={loading.show}/>
    </AssetAppBarWrap>
  );
};

const AssetAppBarWrap = styled.div`
  background: #fff;
  height: 76px;
  border-bottom: solid 1px ${colors.divider01};
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 5;
  position: relative;
  @media screen and (max-width: 1199px) {
    background: ${colors.lnb01};
    height: 110px;
    display: block;
    z-index: 99;
    border-bottom: none;

    //ios notch 영역 여백
    padding-top: calc(constant(safe-area-inset-top) + 5px);
    padding-top: calc(env(safe-area-inset-top));
    padding-left: calc(constant(safe-area-inset-left) + 10px);
    padding-right: calc(constant(safe-area-inset-right) + 10px);
    padding-left: calc(env(safe-area-inset-left) + 10px);
    padding-right: calc(env(safe-area-inset-left) + 10px);
  }
`;
const AppBarLt = styled.div`
  @media screen and (max-width: 1199px) {
    position: absolute;
    top: 42px;
    left: 0;
    width: 100%;
    height: 68px;
    display: flex;
    align-items: center;
    padding: 0 20px;
  }
`;
const AppBarTitle = styled.div`
  display: flex;
  align-items: center;

  > div {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  h3 {
    font-size: 2.2rem;
    ${fontFamilyBold};
    margin: 0 7px;
    color: #000;
    display: inline-flex;
    align-items: center;
  }

  .arrow_icon {
    display: inline-block;
    margin-left: 16px;
    background-image: url(${AssetImages.common.icon.boxArrowDown()});
    width: 18px;
    height: 18px;
  }

  @media screen and (min-width: 1200px) {
    .menu_tit {
      font-size: 2rem;
      ${fontFamilyBold};
      color: ${colors.text02};
      cursor: default;

      img {
        margin-right: 7px;
      }
    }

    .arrow_icon.on {
      background-image: url(${AssetImages.common.icon.boxArrowDownOn()});
    }
  }
}

@media screen and (max-width: 1199px) {
  width: 100%;
  justify-content: space-between;

  .menu_tit {
    font-size: 1.6rem;
    color: #fff;
  }

  h3 {
    color: #fff;
    font-size: 1.6rem;
    ${fontFamilyMedium};
    font-weight: 500 !important;
    margin: 0;
  }

  .arrow_icon {
    margin-left: 3px;
    background-image: url(${AssetImages.common.icon.arrowDown()});
    width: 20px;
    height: 20px;

    &.on {
      transform: scaleY(-1);
    }
  }
`;
const TitleIcon = styled.div`
  height: 28px;
  @media screen and (max-width: 1199px) {
    display: none !important;
  }
`;
const NaviBar = styled.div`
  background: ${colors.component02};;
  padding: 0 16px;
  height: 28px;
  border-radius: 14px;
  color: ${colors.text04};
  display: flex;
  align-items: center;
  margin-left: 12px;

  a {
    font-size: 1.5rem;

    &:not(:last-of-type):after {
      display: inline-block;
      content: '>';
      margin: 0 5px;
    }
  }

  @media screen and (max-width: 1199px) {
    display: none !important;
  }
`;
const MobileNaviBar = styled.div`
  background: ${colors.lnb01};
  justify-content: space-between;
  padding: 24px 0;

  > div {
    font-size: 1.6rem;
    color: #fff;

    &.click_navi {
      span:after {
        display: inline-block;
        content: '';
        width: 20px;
        height: 20px;
        background: url(${AssetImages.common.icon.arrowDown()}) center center;
        vertical-align: middle;
        margin-left: 3px;
      }
    }
  }

  @media screen and (max-width: 1199px) {
    display: flex !important;
  }
`;
const AppBarRt = styled.div`
  font-size: 1.5rem;
  color: ${colors.text07};
  display: flex;
  align-items: center;
  gap: 40px;

  > div {
    display: inline-flex;
    align-items: center;

    button {
      height: 28px;
    }
  }

  @media screen and (max-width: 1199px) {
    justify-content: flex-end;
    padding: 10px 0;
  }
`;

const EtcWrap = styled.div`
  display: flex;
  align-items: center;

  .time {
    ${fontFamilyRegular};
    font-size: 1.5rem;
    color: ${colors.text07};
    margin-right: 12px;

    span {
      margin-left: 3px;
      width: 17ch;
      display: inline-block;
    }
  }

  .alert_wrap {
    display: flex;
    align-items: center;

    &:not(.new_alert) {
      margin-left: 22px;
    }

    &.new_alert {
      background: rgba(243, 0, 0, 0.1);
      height: 32px;
      padding: 0 8px 0 14px;
      border-radius: 27px;
      font-size: 1.5rem;
      ${fontFamilyBold};
      color: ${colors.fault};
      margin-left: 12px;

      .badge {
        min-width: 28px;
        background: ${colors.fault};
      }
    }

    .icon {
      width: 28px;
      height: 28px;
      background: url(${AssetImages.common.icon.noti()}) center center;
    }
  }

  @media screen and (max-width: 1199px) {
    .btn_refresh, .time {
      display: none !important;
    }

    .alert_wrap {

      &.new_alert {
        background: rgba(243, 0, 0, 0.3);
        height: 24px;
        font-size: 1.2rem;

        .badge {
          height: 20px;
          line-height: 20px;
        }

        .icon {
          margin-left: 2px;
        }
      }

      .icon {
        width: 20px;
        height: 20px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cg clip-path='url(%23clip0_1394_6329)'%3E%3Cpath d='M3.3335 15.8334V14.1667H5.00016V8.33341C5.00016 7.18064 5.34738 6.15633 6.04183 5.2605C6.73627 4.36466 7.63905 3.77786 8.75016 3.50008V1.66675H11.2502V3.50008C12.3613 3.77786 13.2641 4.36466 13.9585 5.2605C14.6529 6.15633 15.0002 7.18064 15.0002 8.33341V14.1667H16.6668V15.8334H3.3335ZM10.0002 18.3334C9.54183 18.3334 9.14947 18.1702 8.82308 17.8438C8.49669 17.5174 8.3335 17.1251 8.3335 16.6667H11.6668C11.6668 17.1251 11.5036 17.5174 11.1772 17.8438C10.8509 18.1702 10.4585 18.3334 10.0002 18.3334Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1394_6329'%3E%3Crect width='20' height='20' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E") center center;
      }
    }
  }
`;