// @flow
import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {QnaPagePopupTop} from "communitypage/qna/popup/qna-popup-style";
import SimpleUserProfile from "communitypage/common/SimpleUserProfile";
import {docutil} from "util/docutil";
import clsx from "clsx";
import axios from "axios";
import {IfLoginInfo} from "models/IfLoginInfo";
import useOutsideClick from "hooks/useOutsideClick";
import {DocCommentInfo, IfDocCommentInfo} from "models/IfSsqDataDocCommentEntity";
import {PopReport} from "docwrite/popup/PopReport";
import {i18ntrans} from "language/i18nkey";
import svcs from "services";
import {ViewFileDownload} from "communitypage/qna/popup/ViewFileDownload";
import {ISsqDataDocument} from "repositories/SsqDataRepository";
import {IfLoginPopupConf} from "communitypage/qna/popup/qna-popup-common";
import {AllPopupWrap} from "footer/Footer";
import {AllPopupOverlay, TranPopupOverlay} from "style/CommonStyledComponents";

type Props = {
  //state: QnaPagePopupState,
  question: ISsqDataDocument,
  loginInfo: IfLoginInfo,
  handleClick: (cmd: string, data?) => void,
  handleCmd: (cmd: string | 'show-login' | 'refresh-comment', data?) => void,
  trans,
  renderPopup: (id: string) => void,
  popupConf: IfLoginPopupConf,
  displayLoginPopup: Function,

};
export const QuestionComment = (props: Props) => {
  const ref = useRef();
  const popReport = useRef(null);
  const [comment, setComment] = useState('');
  const [commentUpdate, setCommentUpdate] = useState('');
  const [qcomments, setQcomments] = useState([] as Array<IfDocCommentInfo>);

  const saveCommentRef = useRef<HTMLTextAreaElement>(null);
  const modifyCommentRef = useRef<HTMLTextAreaElement>(null);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (saveCommentRef.current) {
      //높이 초기화
      saveCommentRef.current.style.height = "auto";
      if (window.innerWidth > 764) {
        saveCommentRef.current.style.height = saveCommentRef.current.scrollHeight / 10 + "rem";
      } else {
        saveCommentRef.current.style.height = (saveCommentRef.current.scrollHeight / 10) * 1.09 + "rem";
      }
    }
  });

  useEffect(() => {
    if (modifyCommentRef.current) {
      //높이 초기화
      modifyCommentRef.current.style.height = "2.2rem";
      if (window.innerWidth > 764) {
        modifyCommentRef.current.style.height = modifyCommentRef.current.scrollHeight / 10 + "rem";
      } else {
        modifyCommentRef.current.style.height = (modifyCommentRef.current.scrollHeight / 10) * 1.09 + "rem";
      }
    }
  });

  useEffect(() => {
    if (window.innerWidth < 1199)
      setIsMobile(true);
    else
      setIsMobile(false);
    window.scrollTo(0, 0);
  }, []);

  useOutsideClick(ref, () => {
    let found = false;
    qcomments.forEach(ele => {
      if (ele.modifyReview || ele.reportReview) found = true;
      ele.modifyReview = false;
      ele.reportReview = false;
    });
    if (found) setQcomments(qcomments.slice());
  }, [qcomments]);

  const refVal = useRef({running: false});

  useEffect(() => {
    const id = props.question.id;
    if (!id) setQcomments([]);
    else {
      axios.get('/api/guest/ssqData/doccomment/list', {params: {docId: id}}).then(res => {
        setQcomments(res.data);
      });
    }
  }, [props.question.id]);

  const saveComment = () => {
    if (refVal.current.running) return; // 중복저장 방지.
    refVal.current.running = true;
    let o = new DocCommentInfo();

    o.docId = props.question.id;
    o.commentCnts = comment;
    o.writerNm = props.loginInfo.name;
    o.writerId = props.loginInfo.userId;
    o.writerNcnm = props.loginInfo.nickname;
    o.employ = props.loginInfo.loginType === "WELS";

    axios.post('/api/guest/ssqData/doccomment', o).then(res => {
      console.log("save result ", res.data);
      /*작성 댓글 리스트에 바로 출력함*/
      let commentList = qcomments.slice();
      commentList.push(res.data);
      setQcomments(commentList);
      setComment('');
      props.handleCmd('refresh-comment');
    }).finally(() => refVal.current.running = false);
  };

  const handleClick = (cmd: string | 'save_Comment' | 'update-comment' | 'deleteReview' | 'reportReview', data?) => {
    if (cmd === 'save_Comment') {
      if (!props.loginInfo?.userId) {
        props.handleCmd('show-login');
      } else {
        if (!comment) {
          alert(props.trans("I18N_COMMUNITY_NO_COMMENT"));
        } else {
          console.log("질문 댓글 저장??");
          saveComment();
        }
      }
    } else if (cmd === 'update-comment') {
      const ele = data as IfDocCommentInfo;

      const commentCnts = commentUpdate === '' ? ele.commentCnts : commentUpdate;
      axios.put('/api/guest/ssqData/doccomment/update', {
        commentCnts,
        docCommentId: ele.docCommentId
      }).then(res => {
        console.log("update-comment", res.data);
        ele.showInput = false;
        ele.modifyReview = false;
        ele.commentCnts = commentCnts;
        setQcomments(qcomments.slice());
      }).catch((E) => {
        console.error("E", E);
      });
    } else if (cmd === 'deleteReview') {
      console.log("댓글 삭제 start");
      axios.delete(`/api/guest/ssqData/doccomment/${data.id}`).then(res => {
        console.log("댓글 삭제 delete ok ", res.data);
        setQcomments(qcomments.filter(ele => ele.docCommentId !== data.id));
        props.handleCmd('refresh-comment');
      }).catch(e => {
        console.error("댓글 삭제 delete error ", e);
      });
    } else if (cmd === 'reportReview') {
      const d: IfDocCommentInfo = data;
      if (!props.loginInfo?.userId) {
        props.handleCmd('show-login');
        return;
      }

      popReport.current.show({docId: d.docId, docCommentId: d.docCommentId, reportType: 'COMMENT'})
        .then(res => {
          console.log("res", res);
        }).catch(e => {
        console.error("E", e);
      });
      console.log("-- reportReviewClick");
    } else {
      console.error("unknown cmd e", cmd);
    }
  };

  const handleInputKeyDown = (e) => {
    isMobile ? commentMobileKey(e) : saveCommentPcKey(e);
  };

  //모바일 엔터키
  const commentMobileKey = (e) => {
    if (e.key === 'Enter') {
      if (e.nativeEvent.isComposing === false) {
        if (!props.loginInfo?.userId) {
          props.handleCmd('show-login');
        }
      }
    }
  };

  //Pc 엔터키 및 Shift+Enter
  const saveCommentPcKey = (e) => {
    if (e.key === 'Enter' && e.shiftKey) {
      return;
      // console.log("----질문 댓글 저장");
    } else if (e.key === "Enter" && !e.shiftKey) {
      if (e.nativeEvent.isComposing === false) {
        if (!props.loginInfo?.userId) {
          props.handleCmd('show-login');
        } else {// 추가
          if (comment == "") {
            // console.log("--질문 댓글 내용 없음");
            alert(props.trans("I18N_COMMUNITY_NO_COMMENT"));
          } else {
            saveComment();
          }
        }
      }
    }
    //textarea 자동 줄바꿈 막음
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const clearComments = () => {
    qcomments.forEach(ele => {
      ele.reportReview = false;
      ele.modifyReview = false;
      ele.showInput = false;
    });
  };

  return <React.Fragment>
    {props.question?.versions?.[0]?.files?.length > 0 && <ViewFileDownload ver={props.question.versions[0]} doc={props.question}/>}
    <QnaPagePopupTop>
      {(qcomments || []).map((ele) =>
        <div key={ele.docCommentId} className="review-content">
          <div className="review-content-item">
            <div className="right">
              <ul className="comment-box">
                <SimpleUserProfile userId={ele.writerId} displayLoginPopup={props.displayLoginPopup}/>
              </ul>
              <span className="comment-content" style={{display: ele.showInput ? 'none' : ''}}>{ele.commentCnts}</span>

              <ul className="comment-box">
                <li className="c-time">{ele.showInput == true ? '' : docutil.diffTime((ele.updtDt))}</li>
              </ul>

              {props.loginInfo.userId === ele.writerId ?
                <div className="list-modify-body">
                  {!ele.showInput ?
                    <button className="btn-list-modify" onClick={() => {
                      clearComments();
                      ele.modifyReview = true;
                      setQcomments(qcomments.slice());
                    }}>
                    </button>
                    : ele.modifyReview = false
                  }
                  {!ele.showInput ?
                    ele.modifyReview && <div className={clsx("modify-item-body")}
                      ref={ref}
                      style={{display: ele.modifyReview ? 'inline-block' : ''}}>

                      <button className={clsx("modify-btn modify")}
                        onClick={(e) => {
                          clearComments();
                          ele.showInput = true;
                          ele.modifyReview = false;
                          setQcomments(qcomments.slice());
                        }}
                      >{props.trans('I18N_COMMUNITY_MODIFY')}
                      </button>

                      <button className="modify-btn modify"
                        onClick={() => {
                          handleClick('deleteReview', {id: ele.docCommentId});
                        }}
                      >{props.trans("I18N_COMMUNITY_DELETE")}
                      </button>
                    </div>
                    : ele.modifyReview = false
                  }
                </div>
                :
                props.loginInfo.allowAdminKoKr || props.loginInfo.allowAdminEn || props.loginInfo.allowAdminEnUs === true ?
                  <div className="list-modify-body">
                    {!ele.showInput ?
                      <button className="btn-list-modify" onClick={(e) => {
                        clearComments();
                        ele.modifyReview = true;
                        setQcomments(qcomments.slice());
                      }}>
                      </button>
                      : ele.modifyReview = false
                    }
                    {!ele.showInput ?
                      ele.modifyReview && <div className={clsx("modify-item-body 2")}
                        ref={ref}
                        style={{display: ele.modifyReview ? 'inline-block' : ''}}>

                        <button className={clsx("modify-btn modify")}
                          onClick={(e) => {
                            ele.reportReview = false;
                            ele.modifyReview = false;
                            setQcomments(qcomments.slice());
                            handleClick('reportReview', ele);

                          }}>{props.trans("I18N_COMMUNITY_COMMENT_REPORT")}
                        </button>

                        <button className="modify-btn modify"
                          onClick={() => {
                            ele.modifyReview = false;
                            handleClick('deleteReview', {id: ele.docCommentId});
                          }}
                        >{props.trans("I18N_COMMUNITY_DELETE")}
                        </button>

                      </div>
                      : ele.modifyReview = false
                    }
                  </div>
                  :
                  <div className="list-modify-body">
                    <button className="btn-list-modify" onClick={() => {
                      clearComments();
                      ele.reportReview = true;
                      setQcomments(qcomments.slice());
                    }}></button>

                    {ele.reportReview && <div className={clsx("modify-item-body")}
                      ref={ref}
                      style={{display: ele.reportReview ? 'inline-block' : ''}}>
                      <button className={clsx("modify-btn notify modify")}
                        onClick={(e) => {
                          ele.reportReview = false;
                          setQcomments(qcomments.slice());
                          handleClick('reportReview', ele);
                        }}>{props.trans("I18N_COMMUNITY_COMMENT_REPORT")}
                      </button>
                    </div>}
                  </div>

              }
            </div>
          </div>

          {ele.showInput ? <div className={clsx("review-input", isMobile ? "on" : "modify")} onClick={() => null}>
              <textarea rows={1} name="" placeholder={props.trans("I18N_COMMUNITY_COMMENT_MODIFY_PLACEHOLDER")}
                ref={modifyCommentRef}
                onKeyDown={(e) => {
                  if (isMobile === true) {
                    commentMobileKey(e);
                  } else {
                    if (e.key === "Enter" && e.shiftKey) {
                      return;
                    } else if (e.key === 'Enter' && !e.shiftKey) {
                      handleClick('update-comment', ele);
                    }
                    if (e.key === 'Enter') {
                      e.preventDefault();
                    }
                  }
                }}
                onChange={(e) => setCommentUpdate(e.target.value)}
                defaultValue={ele.commentCnts}
              />

              <button className="review-del"
                onClick={() => {
                  if (confirm(props.trans("I18N_COMMUNITY_COMMENT_MODIFY_CONFIRM"))) {
                    ele.showInput = false;
                    ele.modifyReview = false;
                    setQcomments(qcomments.slice());
                    console.log("수정 취소 성공");
                  } else {
                    console.log("수정유지");
                  }
                }}
              ></button>

              <button className="review-enter" onClick={() => handleClick('update-comment', ele)}></button>
            </div>
            : null
          }
          <div className="mobile-black" style={{display: ele.modifyReview || ele.reportReview ? 'block' : ''}}></div>

        </div>)}
    </QnaPagePopupTop>

    {/*댓글 작성*/}
    <div className="review-input">
      <textarea rows={1} name="" placeholder={props.trans("I18N_COMMUNITY_COMMENT_PLACEHOLDER")}
        ref={saveCommentRef}
        onKeyDown={(e) => {
          handleInputKeyDown(e);
        }}
        onChange={(e) => {
          setComment(e.target.value || '');
        }} value={comment}
      />
      <button className={clsx("ent", isMobile ? "on" : "")}
        onClick={() => handleClick('save_Comment')}></button>
    </div>
    {/*댓글창 비로그인 시 로그인 팝업창 나옴*/}
    <AllPopupWrap>
      {props.popupConf.showLoginPopup && props.renderPopup('loginPopup')}
      {props.popupConf.showSignupPopup && props.renderPopup('renderSignupPopup')}
      {props.popupConf.showSignupSocialPopup && props.renderPopup('renderSignupSocialPopup')}
      {props.popupConf.showTermsDetailPopup && props.renderPopup('renderTermsDetailPopup')}
      {props.popupConf.showTermsDetailPrivatePopup && props.renderPopup('renderTermsDetailPrivatePopup')}
      {props.popupConf.showSendVerifyEmailPopup && props.renderPopup('renderSendVerifyEmailPopup')}
      {props.popupConf.showPasswordResetPopup && props.renderPopup('renderPasswordResetPopup')}
      {props.popupConf.showSendPasswordResetEmailPopup && props.renderPopup('renderSendPasswordResetEmailPopup')}
    </AllPopupWrap>
    {(props.popupConf.showUserPopup) && <TranPopupOverlay aria-label={"popup-overlay"}/>}
    {(props.popupConf.showLoginPopup || props.popupConf.showSignupPopup || props.popupConf.showSignupSocialPopup)
      && <AllPopupOverlay aria-label={"popup-overlay"}/>}

    <PopReport ref={popReport} trans={i18ntrans.admin} ssqDataRepository={svcs.repoSsqData} languageService={svcs.svcLang}/>
  </React.Fragment>;
};