// @flow
import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {QnaPagePopupTop} from "communitypage/qna/popup/qna-popup-style";
import SimpleUserProfile from "communitypage/common/SimpleUserProfile";
import {getEmptyAnswerDoc, IAnswerDocument} from "communitypage/qna/popup/qna-popup-common";
import {IfLoginInfo} from "models/IfLoginInfo";
import {AskEmailReq} from "models/IfAskEmailReq";
import svcs from "services";
import axios from "axios";
import {getEmptyVersion, IFile, ISsqDataDocument, IVersion} from "repositories/SsqDataRepository";
import {docwriteutil} from "docwrite/community/docwriteutil";
import {DocLanguageType} from "definitions/DocLanguageType";
import HttpStatus from "definitions/HttpStatus";
import {AnswerVersionAreaWrap} from "communitypage/qna/popup/AnswerVersionAreaWrap";
import {DocType} from "definitions/DocType";
import {cloneDeep} from "lodash-es";
import SsqEditor5 from "libraries/SsqEditor5";
import styled from "styled-components";
import {SsqImages} from "images/SsqImages";
import {TargetType} from "definitions/TargetType";

type Props = {
  //state: QnaPagePopupState,
  question: ISsqDataDocument,
  answer: IAnswerDocument,
  loginInfo: IfLoginInfo,
  trans,
  cancelAnswer,
  clearModify: () => void,
  editMode: boolean,  // 화면 하단부에서, 항상 editMode = true 로는 동작하지 않도록 막음. 답변 추가 의 경우에는, editorMode = true 로 설정함.
  handleCmd: (cmd: string | 'refresh-answers') => void
  displayLoginPopup: Function,
};

export const AnswerWrite = (props: Props) => {
  const [inputVer, setInputVer] = useState(getEmptyVersion());
  const [answer, setAnswer] = useState(getEmptyAnswerDoc());
  const refVal = useRef({running: false});

  useEffect(() => {
    const ans = cloneDeep(props.answer);
    setAnswer(ans);
    let version = getEmptyVersion();
    if (ans.versions?.length > 0) {
      version = ans.versions[0];
    }
    setInputVer(version);
  }, [props.answer]);

  useEffect(() => {
    const onBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = '';
    };
    if (props.editMode || props.answer.description !== answer.description) {
      window.addEventListener('beforeunload', onBeforeUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [props.editMode, props.answer.description, answer.description]);


  //useEffect(() => console.log("--inputVer", inputVer), [inputVer]);

  const sendNewAnswer = () => {
    const newAnswer: IAnswerDocument = cloneDeep(answer);
    newAnswer.docType = props.question.docType === "QNA_Q" ? DocType.QNA_A : DocType.RND_QNA_A;
    if (!newAnswer.writerId) newAnswer.writerId = props.loginInfo?.userId;
    if (!newAnswer.writerNickname) newAnswer.writerNickname = props.loginInfo?.nickname;
    newAnswer.docTitle = props.question.docTitle;
    newAnswer.exposeSiteLanguage = svcs.svcLang.getLanguage();
    newAnswer.questionId = props.question.id;
    newAnswer.lastModifierId = props.loginInfo?.userId;
    newAnswer.displayTarget = props.question.docType === "RND_QNA_Q" ? TargetType.EMPLOYEE : TargetType.GUEST;

    return new Promise((resolve, reject) => {
      axios.post('/api/guest/ssqData/document/answers/save', newAnswer).then((response) => {
        console.log("response : ", response.data);
        props.clearModify();
        resolve(response.data);
      });
    });

  };

  const uploadAttachedFilesAndAddFilesRow = async (files: any) => {

    console.log("--uploadAttachedFilesAndAddFilesRow : ", files);
    let totalFileLength = files.length + inputVer.files.length;
    const trans = docwriteutil.getadmintrans;
    if (totalFileLength > 10) {
      alert(trans('I18N_ATTACH_ALERT'));
      return;
    }
    const uploadedFiles = await onUploadFiles(files);
    const inputData = inputVer;
    inputData.files = [...inputVer.files, ...uploadedFiles];
    setInputVer({...inputData});
    //props.clearModify();
  };

  const onUploadFiles = async (files: any): Promise<IFile[]> => {
    const trans = docwriteutil.getadmintrans;
    const uploadedBlobInfoList = await svcs.repoSsqData.postFileInBlob(
      "document/" + new Date(Date.now()).valueOf(),
      files
    );

    if (uploadedBlobInfoList.length === 0) {
      alert(trans('I18N_FILE_UPLOAD_ERROR'));
      return [];
    }

    let uploadedFiles: IFile[] = [];

    Array.from(files).forEach((file: any, index: number) => {
      uploadedFiles.push({
        id: 0,
        fileName: file.name,
        fileKey: uploadedBlobInfoList[index].fileKey,
        blobUrlForLargeFile: uploadedBlobInfoList[index].blobUrlForLargeFile,
        fileLang: DocLanguageType.KO,
        fileSize: file.size / 1000,
        fileType: file.fileType,
        targetSoftwareType: file.targetSoftwareType,
        downloadCount: 0,
        writerOnly: false,
      });
    });
    return uploadedFiles;
  };

  const onDeleteFileAndRemoveRow = async (file: any) => {
    console.log("--onDeleteFileAndRemoveRow : ", file);
    await onDeleteFile(file);
    const tempInputVer = {...inputVer};
    tempInputVer.files = inputVer.files.filter(item => item.fileKey !== file.fileKey);
    setInputVer(tempInputVer);
  };

  const onDeleteFile = async (file: IFile) => {
    if (props.question.parentId == 0) {
      const status = await svcs.repoSsqData.deleteFileInBlob(file.fileKey);
      if (status !== HttpStatus.NO_CONTENT) {
        // alert("파일 삭제에 실패하였습니다.")
      }
    }
  };

  const saveVersion = async (docId: number) => {
    const verreq = {
      documentId: docId || 0,
      version: inputVer.version,
      versionDescription: inputVer.versionDescription,
      fileExtract: true,
      files: inputVer.files.map(item => {
        return {
          fileName: item.fileName,
          fileKey: item.fileKey,
          blobUrlForLargeFile: item.blobUrlForLargeFile,
          fileLang: item.fileLang,
          fileSize: item.fileSize,
          fileType: item.fileType,
          targetSoftwareType: item.targetSoftwareType,
          writerOnly: item.writerOnly,
          searchExpose: true,
        };
      })
    };
    console.log('version req ', verreq);
    const versionId = await svcs.repoSsqData.postGuestVersion(verreq);

    if (versionId) {
      console.log("versionId ", versionId, " added");
    } else {
      console.log("version Id Not returned", versionId);
    }
    return versionId;
  };

  const sendAnswerNoticeMail = () => {
    let req = new AskEmailReq();
    req.toEmailId = props.question.writerId;
    req.sendFromEmailId = true;
    req.msg = props.question.docTitle;
    req.lang = svcs.svcLang.getLanguage();
    req.askUrl = window.location.pathname || "https://sol.ls-electric.com/";
    axios.post('/api/guest/sendAnswerNoticeEmail', req)
      .then(res => {
        if (res?.data?.statusCode === 202) {
          console.log("send request ok", res.data);
        } else {
          console.error("send request error", res.data);
        }
      }).catch(e => {
      console.error("E", e);
    });
  };

  return <div className="content write answer-write">
    <QnaPagePopupTop>
      <div className="view-header" style={{marginBottom: "unset", borderBottom: 'none'}}>
        <div className="view-header-item">
          <div className="v-header-left">
            <div className="qna-check">
              <div className="icon-qna red-a"></div>
            </div>
            <ul className="comment-box qna-write">
              <SimpleUserProfile userId={props.answer.id ? props.answer.writerId : props.loginInfo.userId}
                displayLoginPopup={props.displayLoginPopup}/>
            </ul>
          </div>
          <div className="v-header-right">
            {props.editMode ?
              <button className={"batch-button white"} onClick={() => {
                props.cancelAnswer();
              }}>
                {props.trans("I18N_COMMUNITY_CANCEL")}
              </button> : null}
            <button className={"post-button send"}
              onClick={() => {
                if (!answer.description) {
                  alert(props.trans("I18N_COMMUNITY_NO_ANSWER"));
                  return;
                }
                if (refVal.current.running) {
                  console.warn("already save process.. running");
                  return;
                } // 중복저장 방지.
                refVal.current.running = true;
                sendNewAnswer().then(id => {
                  console.log("send new answer...", id);
                  saveVersion(id as number).then((res) => {
                    console.log("save version ", res);
                    props.handleCmd('refresh-answers');
                  }).finally(() => refVal.current.running = false);
                }).catch(e => {
                  console.error("E", e);
                  refVal.current.running = false;
                });

                if (!props.editMode) sendAnswerNoticeMail();
              }}>
              <span>{props.trans("I18N_COMMUNITY_BLOG_FEEDBACK_BUTTON")}</span>
            </button>
          </div>
        </div>
      </div>
    </QnaPagePopupTop>
    <ResizeEditor>
      <SsqEditor5 contents={answer.description} lang={svcs.svcLang.lang()}
        width={'100%'} height={'460px'} onChangeData={(data) => setAnswer({...answer, description: data})}
      />
    </ResizeEditor>

    {/*첨부파일 */}
    <AnswerVersionAreaWrap inputVer={inputVer}
      readonly={false}
      onDeleteFile={(file) => onDeleteFileAndRemoveRow(file)}
      onUploadFile={(files) => uploadAttachedFilesAndAddFilesRow(files)}
      setInputVer={(inputVer: IVersion) => setInputVer(inputVer)}
    />
  </div>;
};

const ResizeEditor = styled.div`

  .ssqeditor-cont, .SsqEditor {
    height: auto !important;
  }

  .SsqEditor {
    .ck-editor__main {
      &:after {
        pointer-events: none;
        content: "";
        font-size: 14px;
        position: absolute;
        text-align: center;
        bottom: 0;
        right: 0;
        z-index: 2;
        width: 18px;
        height: 18px;
        background: #fff url(${SsqImages.community.icon.resizeIcon()});
      }
    }

    .ck-content.ck-editor__editable {
      resize: vertical;
      position: relative;
      min-height: 260px;
      max-height: 960px;

      &::-webkit-resizer {

      }
    }
  }
`;
