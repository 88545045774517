import {useIsLogin} from "hooks/useIsLogin";
import {ACTION_TYPES} from "store/ActionTypes";
import {loginutil} from "util/loginutil";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useEffect} from "react";
import axios from "axios";


export const useVerifyLoginInfo = (): boolean => {
  const history = useHistory();
  const isLogin = useIsLogin();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLogin) return;

    axios.get('/api/guest/loginInfo').then(res => {
      dispatch({type: ACTION_TYPES.MAIN.LOGIN_INFO, payload: res.data});
      console.log("set user info", res.data, history.location);
      const info = res.data;
      if (!loginutil.islogin(info)) {
        // document.location.href = "/requireEmployeeLogin?returnurl=" + encodeURIComponent(returnurl);
        // history.push('/requireMemberLogin', history.location.pathname + (history.location.search ?? ''));
        history.push('/asset/loginrequired', history.location.pathname + (history.location.search ?? ''));
      }
    }).catch(err => {
      console.error("E", err);
    });

  }, [isLogin]);
  return isLogin;
};