import React, {Component} from 'react';
import styled from "styled-components";
import KaKaoLogin from 'react-kakao-login';
import {RouteComponentProps, withRouter} from "react-router-dom";
import {LoginService, SocialSignupInfo} from "../login/LoginService";
import {SsqImages} from "../images/SsqImages";
import {AccountService, IPatchLoginTimeAndCountRequest,} from "../repositories/AccountService";
import {LanguageService} from "../language/LanguageService";
import {LoginInputValues} from "./Header";
import {connect} from "react-redux";
import {setExistSsqAccountNotSnsStore} from "../store/reducers/rootReducer";
import {LOGIN_TYPE_KAKAO} from "../definitions/LoginType";
import TagManager from "react-gtm-module";
import ToolTip from "./ToolTip";
import svcs from "services";
import {i18ntrans} from "language/i18nkey";
import {cookieutil} from "util/cookieutil";

const JS_KEY = '1c79988a2a309701459a792f3168eea8';
const JS_KEY_LOCAL = '1801e7a26c6d42483c38074cf1549150';

const getJS_KEY = () => {
  const host = window.location.host;
  if (host.includes('localhost')) {
    return JS_KEY_LOCAL;
  }
  return JS_KEY;
};

interface Props extends RouteComponentProps<any> {
  languageService: LanguageService;
  loginService: LoginService;
  accountService: AccountService;
  showSignupSocialPopupFunc: Function;
  showLoginPopupFunc: Function;
  showLoginFailPopupFunc: Function;
  isExistSsqAccountNotSnsStore?: boolean;
  setExistSsqAccountNotSnsStore?: Function;
  recentLoginType: string;
}


interface State {
  key: number;
}

class KakaoLoginPage extends Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {key: 1};
  }

  getTranslation(key: string): string {
    this.props.languageService.setNamespace("I18N_NAMESPACE_HEADER");
    return this.props.languageService.getTranslationByKey(key) || "";
  }

  async isAlreadyExistSsqEmail(email: string) {
    const isAlreadyMember = await this.props.accountService.isExistAccountId(email);
    if (isAlreadyMember === true) {
      const loginInputValues: LoginInputValues = {
        id: email,
        password: ""
      };
      this.props.setExistSsqAccountNotSnsStore && this.props.setExistSsqAccountNotSnsStore(true);
      this.props.showLoginFailPopupFunc(true, loginInputValues);
      return false;
    }
    return true;
  }

  render() {
    const t = i18ntrans.header;
    return (
      <Container
        onClick={() => {
          if (cookieutil.isAcceptFunctionConsentCookie(this.props.languageService.lang()))
            svcs.localStorage.setItem('recentLoginType', 'KAKAO');
        }}>
        {this.props.recentLoginType == 'KAKAO' && <ToolTip languageService={this.props.languageService}/>}
        <KaKaoLogin
          key={this.state.key}
          token={getJS_KEY()}
          render={renderProps => (
            <KaKaoCustomButton onClick={(e) => {
              console.log("kakao button on click", e);
              renderProps.onClick();
            }} src={SsqImages.header.sns.kakao()}/>
          )}
          onSuccess={async (res: any) => {

            if (!res.profile.kakao_account.email) {
              console.log("no email alert", res.profile);
              window.alert(t('I18N_LOGIN_NOEMAIL_ALERT'));
              return;
            }


            const isLoggedIn = await this.props.loginService.login(
              res.profile.kakao_account.email, res.profile.id, LOGIN_TYPE_KAKAO
            );
            if (isLoggedIn) {
              TagManager.dataLayer({
                dataLayer: {
                  "event": "login",
                  "login_type": "kakao"
                }
              });
              const patchLoginTimeAndCountRequest: IPatchLoginTimeAndCountRequest = {
                email: res.profile.kakao_account.email
              };
              await this.props.accountService.patchLoginTimeAndCount(patchLoginTimeAndCountRequest);

              if (window.location.href.includes("requireMemberLogin") || window.location.href.includes("requireEmployeeLogin")) {
                this.props.showLoginPopupFunc(false);
              } else {
                let pattern = /^https?:\/\/[^/]+\/(\w\w)\/(\w\w)\/(login)/;
                let match = window.location.href.match(pattern);
                if (match && match[3] === 'login') {
                  const url = window.location.origin + "/" + match[1] + "/" + match[2] + "/main";
                  window.location.assign(url);
                } else {
                  window.location.assign(window.location.href);
                }
              }
            } else {
              this.props.showLoginPopupFunc(false);
              const kakaoSignupInfo: SocialSignupInfo = {
                email: res.profile.kakao_account.email,
                socialId: res.profile.id,
                loginType: LOGIN_TYPE_KAKAO,
              };

              if (window.location.href.includes("requireMemberLogin") || window.location.href.includes("requireEmployeeLogin")) {
                if (await this.isAlreadyExistSsqEmail(kakaoSignupInfo.email) === true) {
                  this.props.history.push("/", kakaoSignupInfo);
                }
              } else {
                if (await this.isAlreadyExistSsqEmail(kakaoSignupInfo.email) === true) {
                  this.props.showSignupSocialPopupFunc(true, kakaoSignupInfo);
                }
              }
            }
          }}
          onFail={console.error}
          onLogout={console.info}
        />
      </Container>
    );
  }
}

export default withRouter(connect(
  (state: any) => ({
    isExistSsqAccountNotSnsStore: state.root.isExistSsqAccountNotSnsStore,
  }),
  dispatch => ({
    setExistSsqAccountNotSnsStore: (isExistSsqAccountNotSnsStore: boolean) => dispatch(setExistSsqAccountNotSnsStore(isExistSsqAccountNotSnsStore))
  })
)(KakaoLoginPage));

const Container = styled.div`
  display: flex;
  flex-flow: column wrap;
`;

const KaKaoCustomButton = styled.img`
  cursor: pointer;
`;

