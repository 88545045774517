import * as React from "react";
import {CbCenterPopupItem} from "borispage/admintab/popuptab/common/PopupTabCommon";
import {SsqImages} from "images/SsqImages";
import {cdn_imag_url_base} from "conf/gconf";
import img_link_image_icon from "images/common/related_arrow.svg";
import img_empty_image_icon from "images/common/no_image.svg";

type Props = {
  popupItem: CbCenterPopupItem;
  closeEventPopup: () => void;
  trans: (p: string) => string;
}

export const CenterEventPopup = (props: Props) => {
  const disableType = [
    {disp: '', val: null},
    {disp: 'I18N_POPUP_DISABLE_DAY', val: 'day'},
    {disp: 'I18N_POPUP_DISABLE_WEEK', val: 'week'},
    {disp: 'I18N_POPUP_DISABLE_NEVER', val: 'never'}
  ];

  const imageSrc = cdn_imag_url_base + '/' + props.popupItem.fileKey;
  const buttons = [props.popupItem.button1, props.popupItem.button2, props.popupItem.button3, props.popupItem.button4];

  const defaultTextColor = "#2A2C2F";
  const defaultButtonColor = "#FFFFFF";

  const renderButtonDesign = (ele: string, index: number) => {
    const value = ele.split(';');
    let url = value[2];
    if (url) {
      if (!url.startsWith('http')) url = `https://${value[2]}`;
    } else url = 'https://www.ls-electric.com';

    return <div key={index} className={"insert-button-area"} onClick={() => window.open(url, '_blank')}
      style={{color: value[1] ? value[1] : defaultTextColor, background: value[3] ? value[3] : defaultButtonColor}}>
      {value[0] || ""}
      <img className={"link_arrow"} src={img_link_image_icon} alt={'image_link_icon.svg'}/>
    </div>;
  };

  const onError = (event) => {
    event.target.src = img_empty_image_icon;
    event.target.classList.add('center_empty_image');
  };

  const setExpireTime = (id: number, type: string, endDate: Date) => {
    let expires = new Date();
    if (type == 'day') expires.setHours(expires.getHours() + 24);
    if (type == 'week') expires.setHours(expires.getHours() + 168);

    if (type == 'never') {
      expires = new Date(endDate);
      expires.setHours(expires.getHours() + 48);
    }
    localStorage.setItem(`center-disabled-${id}`, expires.toDateString());
  };
  let url = props.popupItem.link;
  if (url) if (!url.startsWith('http')) url = `https://${props.popupItem.link}`;

  return <div className={"popup-collider"}>
    <button className={"close-button"} onClick={() =>
      props.closeEventPopup()}>
      <img alt="close_small_icon.svg" src={SsqImages.common.icon.closeSmallIcon()}/>
    </button>

    <div className={"description-base"}>
      <img className={"entered_image"} style={{cursor: url ? "pointer" : ""}} src={imageSrc} alt={"event.png"} onError={onError}
        onClick={() => url && window.open(url)}/>

      <div className={"total-button-area"}>
        {buttons.map((ele, index) => ele && renderButtonDesign(ele, index))}
      </div>

    </div>
    <div className={"exit-base"}>
      <button onClick={() => {
        setExpireTime(props.popupItem.id, props.popupItem.disableType, props.popupItem.popupEnd);
        props.closeEventPopup();
      }}>{props.trans(disableType.find(ele => ele.val == props.popupItem.disableType).disp)}
      </button>
    </div>
  </div>;
};