import styled from "styled-components";
import {fontFamilyBold, fontFamilyMedium, fontFamilyRegular} from "asset/style/AssetFonts";
import * as colors from "asset/style/color";
import {AssetImages} from "asset/images/AssetImages";

export const SearchWrap = styled.div`
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  width: 0;
  height: 100%;
  transition: width 0.5s;
  max-height: 64px;

  input {
    width: 100%;
    height: 62px;
    font-size: 1.5rem;

    &::placeholder {
      color: rgba(141, 141, 141, 0.7);
      font-size: 1.5rem;
      ${fontFamilyMedium}
    }
  }

  .search_input_btn {
    display: flex;
    opacity: 0;
    position: absolute;
    right: 32px;
    top: calc(50% - 12px);
    align-items: center;
    transition: all 0.3s;

    button {
      + button {
        margin-left: 24px;
      }
    }
  }

  &.on {
    display: block;
    width: 100%;
    background: ${colors.bg04};

    input {
      padding: 0 105px 0 32px;
    }

    .search_input_btn {
      opacity: 1;
    }
  }

`;
export const ToggleButton = styled.button`
  position: relative;
  border-radius: 26px;
  width: 48px;
  height: 22px;
  background: ${colors.component04};

  span {
    font-size: 1.5rem;
    ${fontFamilyRegular};
    font-weight: 400;
    margin-left: 58px;
  }

  &:before {
    content: "";
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    transition: left 250ms linear;
    top: calc(50% - 9px)
  }

  &.toggleOff {
    span {
      color: rgba(0, 0, 0, 0.35);
    }

    &:before {
      left: 2px;
      background-color: ${colors.component03};
    }
  }

  &.toggleOn {
    background-color: ${colors.component07};

    span {
      color: ${colors.text03};
    }

    &:before {
      left: 28px;
      background-color: #fff;
    }
  }
`;

export const IconBtn = styled.button`
  width: 24px;
  height: 24px;
  background-position: center center;

  &.search {
    background-image: url(${AssetImages.common.icon.search()});
  }

  &.search_prev {
    background-image: url(${AssetImages.common.icon.searchPrev()});
  }

  &.close {
    background-image: url(${AssetImages.common.icon.close()});
  }

  &.circle_arrow {
    width: 20px !important;
    height: 20px !important;;
    background-image: url(${AssetImages.common.icon.circleArrowRt()});
    vertical-align: top;
  }

  &.more {
    background-image: url(${AssetImages.common.icon.more()});
  }

  &.circle_add {
    background-image: url(${AssetImages.common.icon.circleAdd()});
  }

  &.delete {
    background-image: url(${AssetImages.common.icon.delete()});
  }

  &.arrow_down_bk {
    width: 14px;
    height: 14px;
    background-image: url(${AssetImages.common.icon.arrowDownBk()});
  }

  &.location {
    background-image: url(${AssetImages.common.icon.location()});
  }

  &.refresh {
    background-image: url(${AssetImages.common.icon.refresh()});
    background-size: 24px;
  }

  &.down_xls {
    background-image: url(${AssetImages.common.icon.documentXls()});
    background-size: 20px;
    background-repeat: no-repeat;
  }

  &.filter_off {
    background-image: url(${AssetImages.common.icon.filterOff()});
    background-size: 20px;
  }

`;
export const TextBtn = styled.button`
  width: 144px;
  height: 48px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  color: ${colors.text02};
  background: #fff;
  border-radius: 6px;
  border: solid 1px ${colors.component01};
  ${fontFamilyMedium};

  &.size-lg {
    width: 240px;
    height: 48px;
    font-size: 1.6rem;
  }

  &.size-md {
    width: 112px;
    height: 32px;
    font-size: 1.5rem;
  }

  &:hover {
    background: ${colors.accent03};
    border-color: ${colors.accent01};
    color: ${colors.accent01};
  }

  &:active, &:focus {
    background: #AAD6FF;
    border-color: ${colors.accent01};
    color: ${colors.accent01};
  }

  &:disabled {
    background: ${colors.component03};
    border-color: ${colors.component04};
    color: rgba(141, 141, 141, 0.7);
    cursor: default;
  }

  &.primary {
    background: ${colors.accent01};
    color: #fff;
    border-color: ${colors.accent01};

    &:hover {
      background: ${colors.component07};
    }

    &:active, &:focus {
      background: ${colors.accent02};
      border-color: ${colors.accent02};
    }
  }
`;
export const TextIconBtn = styled.button`
  height: 32px !important;
  display: inline-flex;
  align-items: center;
  padding: 0 16px 0 46px;
  font-size: 1.4rem;
  color: ${colors.text03};
  background-color: #fff;
  border-radius: 6px;
  border: solid 1px ${colors.component01};
  background-repeat: no-repeat;
  background-position: left 16px center;
  ${fontFamilyMedium};

  &.filter_off {
    background-image: url(${AssetImages.common.icon.filterOff()});
    background-size: 20px;
  }

  &.download {
    background-image: url(${AssetImages.common.icon.download()});
    background-size: 20px;
    font-size: 1.5rem;
  }

  &.circle_arrow {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 9.99999L7.68161 13.25L8.84827 14.4375L13.3542 9.99999L8.84827 5.56249L7.68161 6.74999L11 9.99999ZM10.0001 18.3333C8.8473 18.3333 7.76397 18.1146 6.75008 17.6771C5.73619 17.2396 4.85425 16.6458 4.10425 15.8958C3.35425 15.1458 2.7605 14.2639 2.323 13.25C1.8855 12.2361 1.66675 11.1528 1.66675 9.99999C1.66675 8.84721 1.8855 7.76388 2.323 6.74999C2.7605 5.7361 3.35425 4.85416 4.10425 4.10416C4.85425 3.35416 5.73619 2.76041 6.75008 2.32291C7.76397 1.88541 8.8473 1.66666 10.0001 1.66666C11.1529 1.66666 12.2362 1.88541 13.2501 2.32291C14.264 2.76041 15.1459 3.35416 15.8959 4.10416C16.6459 4.85416 17.2397 5.7361 17.6772 6.74999C18.1147 7.76388 18.3334 8.84721 18.3334 9.99999C18.3334 11.1528 18.1147 12.2361 17.6772 13.25C17.2397 14.2639 16.6459 15.1458 15.8959 15.8958C15.1459 16.6458 14.264 17.2396 13.2501 17.6771C12.2362 18.1146 11.1529 18.3333 10.0001 18.3333ZM10.0001 16.6667C11.8612 16.6667 13.4376 16.0208 14.7292 14.7292C16.0209 13.4375 16.6667 11.8611 16.6667 9.99999C16.6667 8.13888 16.0209 6.56249 14.7292 5.27082C13.4376 3.97916 11.8612 3.33332 10.0001 3.33332C8.13897 3.33332 6.56258 3.97916 5.27091 5.27082C3.97925 6.56249 3.33341 8.13888 3.33341 9.99999C3.33341 11.8611 3.97925 13.4375 5.27091 14.7292C6.56258 16.0208 8.13897 16.6667 10.0001 16.6667Z' fill='%23374E92'/%3E%3C/svg%3E%0A");
    background-size: 20px;
    padding: 0 46px 0 16px;
    background-position: right 16px center;
  }

  &.down_xls {
    background-image: url(${AssetImages.common.icon.documentXls()});
    background-size: 20px;
    font-size: 1.5rem;
  }

  &.size-md {
    width: 96px;
    height: 24px;
    font-size: 1rem;
    ${fontFamilyBold}
  }

  &:hover {
    background-color: ${colors.accent03};
    border-color: ${colors.accent01};
    color: ${colors.accent01};
  }

  &:active, &:focus {
    background-color: #AAD6FF;
    border-color: ${colors.accent01};
    color: ${colors.accent01};
  }

  &:disabled {
    background-color: ${colors.component03};
    border-color: ${colors.component04};
    color: rgba(141, 141, 141, 0.7);
    cursor: default;
  }
`;
export const InputCheckBox = styled.div`

  label span {
    display: inline-block;
    margin-left: 8px;
    font-size: 1.4rem;
    color: ${colors.text03};
    vertical-align: middle;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: calc(100% - 26px);
  }

  label input[type="checkbox"] {
    cursor: pointer;
    position: relative;
    width: 18px;
    height: 18px;
    vertical-align: middle;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      display: block;
      width: 18px;
      height: 18px;
      border-radius: 3px;
      border: 1px solid ${colors.component01};
      background-color: #fff;
      box-sizing: border-box;
    }

    &:checked {
      &:before {
        background: ${colors.component07};
        border: 1px solid ${colors.component07};
      }

      &:after {
        position: absolute;
        top: 5px;
        left: 3px;
        content: '';
        display: block;
        width: 12px;
        height: 9px;
        background: url(${AssetImages.common.input.inputCheck()});
      }
    }

    &:disabled:before {
      background: ${colors.component03};
      border: 1px solid ${colors.component04};
    }
  }
`;
export const InputRadioBox = styled.div`

  label span {
    display: inline-block;
    margin-left: 8px;
    font-size: 1.4rem;
    color: ${colors.text03};
    vertical-align: middle;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: calc(100% - 26px);
  }

  label input[type="radio"] {
    cursor: pointer;
    position: relative;
    width: 18px;
    height: 18px;
    vertical-align: middle;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      display: block;
      width: 18px;
      height: 18px;
      border-radius: 100%;
      border: 1px solid ${colors.component01};
      background-color: #fff;
      box-sizing: border-box;
    }

    &:checked {
      &:before {
        border: 1px solid ${colors.component07};
      }

      &:after {
        position: absolute;
        top: calc(50% - 5px);
        left: calc(50% - 5px);
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background: ${colors.accent01};
      }
    }

    &:disabled {
      &:before {
        border: 1px solid ${colors.component04};
      }

      &:checked:after {
        background: ${colors.component04};
      }
    }
  }
`;
export const SelectBox = styled.select`
  -o-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: solid 1px ${colors.divider01};
  width: 100%;
  min-height: 40px;
  padding: 0 35px 0 12px;
  font-size: 1.5rem;
  ${fontFamilyMedium};
  background: #fff url(${AssetImages.common.input.inputSelect()}) calc(100% - 8px) center no-repeat;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const InputFile = styled.div`
  width: 100%;
  background: #fff;
  border: solid 1px ${colors.divider01};
  height: 40px;
  padding: 8px 12px;
  position: relative;
  cursor: pointer;

  .upload-name {
    display: inline-block;
    font-size: 1.5rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: calc(100% - 25px);
  }

  label {
    width: 100%;
    height: 100%;
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    .circle_add {
      width: 24px;
      position: absolute;
      right: 8px;
      top: 7px;
    }
  }

  input[type="file"] {
    display: none;
  }
`;