// @flow
import * as React from 'react';

type Props = {
  cols: Array<number>
};
export const ColGrp = (props: Props) => {
  const tot = props.cols.reduce((acc, cur) => acc + cur, 0);

  const cols = props.cols.map(ele => '' + (ele / tot * 100).toFixed(2) + '%');
  return (
    <colgroup>
      {cols.map((col, idx) => <col key={idx} width={col}/>)}
    </colgroup>
  );
};