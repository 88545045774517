import {IfProjectEntity} from "asset/models/IfProjectEntity";
import useSWR, {KeyedMutator} from "swr";
import {nulllist} from "asset/utils/asset-util";


export const useApiAssetProjectList = ({userId}: { userId?: string }): [IfProjectEntity[], KeyedMutator<IfProjectEntity[]>] => {
  const {data, mutate} = useSWR<IfProjectEntity[]>(userId && ['/api/member/asset/project/list', {userId}]);
  return [data || nulllist, mutate];
};

export const useApiProjectOne = ({projectId}: { projectId: number }): [IfProjectEntity, KeyedMutator<IfProjectEntity>] => {
  const {data, mutate} = useSWR<IfProjectEntity>(projectId && ['/api/member/asset/project/one', {projectId}]);
  return [data, mutate];
};