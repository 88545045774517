import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {Modal} from "ctls/modal/Modal";
import {useRefComponent} from "hooks/useRefComponent";
import {SsqImages} from "images/SsqImages";
import svcs from "services";
import 'react-datepicker/dist/react-datepicker.css';
import {PartnerQnaPopupArea, PartnerQnaPopupWrap} from "communitypage/qna/popup/qna-popup-style";
import {DatePickerWrap, InputWrapForSelect} from "style/CommonStyledComponents";
import DatePicker from 'react-datepicker';
import {useApiAdminUsers} from "docwrite/community/popup/useApiAdminUsers";

type Props = {
  show(): Promise<any>;
}

const PopPartnerQnaStateModify = forwardRef<Props, any>((props, ref) => {
  const [visible, setVisible] = useState<boolean>(false);
  const promise = useRef<{ resolve: any; reject: any }>({resolve: null, reject: null});
  const [keyword, setKeyword] = useState("");
  const admins = useApiAdminUsers(props.docLang, props.loginInfo?.department, true);

  const handleClickOpen = () => {
    setVisible(true);
  };
  const handleClose = () => {
    setVisible(false);
    promise.current.reject();
  };

  useImperativeHandle(ref, () => ({
    show: () => {
      return new Promise((resolve, reject) => {
        promise.current.resolve = resolve;
        promise.current.reject = reject;
        handleClickOpen();
      });
    },
  }));

  useEffect(() => {
    if (!visible) return;
  }, [visible]);

  const getTranslation = (key: string) => {
    svcs.svcLang.setNamespace("I18N_NAMESPACE_SERVICE");
    return svcs.svcLang.getTranslationByKey(key) || "";
  };
  const datePicker = (date: Date, event: (p) => void) => {
    return <span className={"date-pickers"}>
      <DatePicker selected={date} onChange={(e) => event(e)} dateFormat="yy-MM-dd" placeholderText="YY-MM-DD" showMonthDropdown
        showYearDropdown formatWeekDay={(nameOfDay) => nameOfDay.substring(0, 1)}/>
    </span>;
  };

  const renderPopup = () => {
    return <PartnerQnaPopupArea>
      <PartnerQnaPopupWrap>
        <div className={"popup_tit"}>
          <button className={"close-button"} onClick={() => {
            handleClose();
          }}>
            <img alt={"close_small_icon.svg"} src={SsqImages.common.icon.closeSmallIcon()}/>
          </button>
          <div className="tit_lt">
            <p className={"title"}>
              상태 수정하기
            </p>
          </div>
          <div className={"popup_noti"}>
            저장시 고객에게 변경사항에 대한 메일이 발송됩니다.
          </div>
        </div>
        <div className={"popup_input"}>
          <div className={"input_wrap"}>
            <p className={"tit"}>
              문의 유형
            </p>
            <div className={"input_row"}>
              <InputWrapForSelect className={"select_wrap"}>
                <select
                  value="영업 문의"
                >
                  <option value={""}>
                    기술문의
                  </option>
                  <option value={""}>
                    영업 문의
                  </option>
                  <option value={""}>
                    서비스
                  </option>
                  <option value={""}>
                    기타
                  </option>
                </select>
              </InputWrapForSelect>
            </div>
          </div>
          <div className={"input_wrap"}>
            <p className={"tit"}>
              상태
            </p>
            <div className={"input_row"}>
              <InputWrapForSelect className={"select_wrap"}>
                <select
                  value="Open"
                >
                  <option value={""}>
                    Open
                  </option>
                </select>
              </InputWrapForSelect>
            </div>
          </div>
          <div className={"input_wrap"}>
            <p className={"tit"}>
              담당자
            </p>
            <div className={"input_row"}>
              <InputWrapForSelect className={"select_wrap"}>
                <select
                  value="이민용"
                >
                  <option value={""}>
                    이민용
                  </option>
                </select>
              </InputWrapForSelect>
            </div>
          </div>
          <div className={"input_wrap"}>
            <p className={"tit"}>
              예상 완료일
            </p>
            <DatePickerWrap className="input_row">
              {datePicker(props.start, props.startChange)}
            </DatePickerWrap>
          </div>
        </div>
        <div className="popup_btn">
          <div className="Submit-Button cancle">취소</div>
          <div className="Submit-Button save">저장</div>
        </div>
      </PartnerQnaPopupWrap>

    </PartnerQnaPopupArea>;
  };

  return (<>
      {visible ? (<Modal onClick={(e) => e.stopPropagation()}>
        {renderPopup()}
      </Modal>) : null}
    </>
  );
});
PopPartnerQnaStateModify.displayName = "PopPartnerQnaStateModify";
export const usePopPartnerQnaState = () => useRefComponent<Props>(PopPartnerQnaStateModify);