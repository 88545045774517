import axios from "axios";
import {useEffect, useState} from "react";
import {IAdminUser} from "repositories/SsqDataRepository";


export const useApiAdminUsers = (docLang: string, department: string, show: boolean) => {
  const [admins, setAdmins] = useState<IAdminUser[]>([]);

  useEffect(() => {
    if (!department || !docLang || !show) {
      setAdmins([]);
      return;
    }
    axios.get('/api/boris/adminUsers/' + docLang).then(res => {

      // 정렬은, 부서명, 이름 으로 정렬한다.
      // 내부서를 가장먼저 표시하고, 내부서와 가까운 부서부터 배치한다.
      const basedept = (department || "").split("").map(ch => ch.charCodeAt(0));
      const arr = (res.data || []).map(ele => {
        const deptarr = (ele.department || "").split("").map(c => c.charCodeAt(0));
        const deptdiff = deptarr.map((ele, idx) => {
          if (basedept.length > idx) {
            return Math.abs(ele - basedept[idx]);
          }
          return Math.abs(ele);
        });

        return {
          ele,
          name: ele.name,
          dept: ele.department,
          deptarr,
          deptdiff
        };
      });
      const comparr = (a, b) => {
        let l = a.length > b.length ? b.length : a.length;
        for (let i = 0; i < l; i++) {
          if (a[i] === b[i]) continue;
          return a[i] - b[i];
        }
        if (a.length > b.length) return 1;
        return -1;
      };

      arr.sort((a, b) => {
        return comparr(a.deptdiff, b.deptdiff) || (a.name || "").localeCompare(b.name || "");
      });
      console.log("arr", arr);

      setAdmins(arr.map(ele => ele.ele));
    }).catch(e => {
      console.error("", e);
    });
  }, [docLang, department, show]);

  return admins;
};