import {resi18nutil} from "language/resi18nutil";
import {resi18n_all_en, resi18n_all_enus, resi18n_all_ko} from "language/resi18n_all";


const resi18n_footer = {
  I18N_FOOTER_CUSTOMER_CENTER: ["사이트 문의", "Contact", undefined],
  I18N_FOOTER_SITEMAP_PRODUCT: ["제품", "Product", undefined],
  I18N_FOOTER_SITEMAP_PRODUCT_SUB06: ["감속기", "GearBox", undefined],
  I18N_FOOTER_SITEMAP_PRODUCT_SUB07: ["소프트스타터", "SoftStarter", undefined],
  I18N_FOOTER_SITEMAP_SOLUTIONS: ["산업", "Industry", undefined],
  I18N_FOOTER_SITEMAP_SOLUTIONS_SUB01: ["일반 기계", "Machinery", undefined],
  I18N_FOOTER_SITEMAP_SOLUTIONS_SUB02: ["자동차", "Automotive", undefined],
  I18N_FOOTER_SITEMAP_SOLUTIONS_SUB03: ["식음료", "Food&Beverage", undefined],
  I18N_FOOTER_SITEMAP_SOLUTIONS_SUB04: ["팬/펌프", "Fan&Pump", undefined],
  I18N_FOOTER_SITEMAP_SOLUTIONS_SUB05: ["수처리", "Water", undefined],
  I18N_FOOTER_SITEMAP_SOLUTIONS_SUB06: ["엘리베이터", "Elevator", undefined],
  I18N_FOOTER_SITEMAP_SOLUTIONS_SUB07: ["물류", "Logistics", undefined],
  I18N_FOOTER_SITEMAP_SOLUTIONS_SUB08: ["컨베이어", "Conveyor", undefined],
  I18N_FOOTER_SITEMAP_COMMUNITY: ["커뮤니티", "Community", undefined],
  I18N_FOOTER_SITEMAP_COMMUNITY_SUB01: ["Q&A", "Q&A", undefined],
  I18N_FOOTER_SITEMAP_COMMUNITY_SUB02: ["Knowledge Base", "Knowledge Base", undefined],
  I18N_FOOTER_SITEMAP_COMMUNITY_SUB03: ["게시판", "Bulletin Board", undefined],
  I18N_FOOTER_SITEMAP_SERVICE: ["서비스", "Service", undefined],
  I18N_FOOTER_SITEMAP_SERVICE_SUB01: ["샘플 라이브러리", "Sample Library", undefined],
  I18N_FOOTER_SITEMAP_SERVICE_SUB02: ["원격 지원", "Remote Support", undefined],
  I18N_FOOTER_SITEMAP_SERVICE_SUB03: ["실시간 강의실", "Online Training", undefined],
  I18N_FOOTER_SITEMAP_SERVICE_SUB04: ["제품 선정 가이드", "Product Selector", undefined],
  I18N_FOOTER_TERMS: ["이용 약관", "Terms and conditions", undefined],
  I18N_FOOTER_PRIVATE_TERMS: ["개인정보 처리방침", "Privacy Policy", undefined],
  I18N_FOOTER_COOKIE_POPUP_POLICY: ['쿠키 정책', 'Cookie Policy', undefined],
  I18N_INFO_POPUP: ["이용 안내", "Features", undefined],
  I18N_INFO_POPUP_PREV: ["이전", "Prev", undefined],
  I18N_INFO_POPUP_NEXT: ["다음", "Next", undefined],
  I18N_INFO_POPUP_TITLE01: ["제품 선정 가이드", "Product Selector", undefined],
  I18N_INFO_POPUP_TITLE02: ["Q&A", "Q&A", undefined],
  I18N_INFO_POPUP_TITLE03: ["Knowledge Base", "Knowledge Base", undefined],
  I18N_INFO_POPUP_TITLE04: ["AI 자산관리", "Asset Management", undefined],
  I18N_INFO_POPUP_TITLE_COMMUNITY: ["참여형 커뮤니티", "Open Community", undefined],
  I18N_INFO_POPUP_TEXT01: [
    "맞춤형 솔루션을 찾는 것에 어려움을 겪고 있다면 제품 선정 가이드는 최적의 제품을 선정해 드립니다.",
    "Struggling to find a customized solution, the product selector can help you choose the best product for you."
    , undefined],
  I18N_INFO_POPUP_TEXT02: [
    "오픈 커뮤니티 기반의 Q&A를 통해 다양한 관점과 전문 지식을 활용하여 새로운 솔루션을 찾을 수 있습니다.",
    "Open community-based Q&A allows you to leverage diverse perspectives and expertise to find new solutions."
    , undefined],
  I18N_INFO_POPUP_TEXT03: [
    "다양한 현장의 전문가들이 Knowledge Base를 통해 공유한 경험을 적용함으로써 애플리케이션 개발에 가속화할 수 있습니다.",
    "Accelerate application development by applying the experience shared by experts in various fields through the knowledge base."
    , undefined],
  I18N_INFO_POPUP_TEXT04: [
    "데이터와 AI를 접목하여 보다 효율적이과 체계적인 자산 관리 솔루션을 제공합니다. (개발중)",
    "Asset management solutions utilizing the latest technologies in artificial intelligence and data analytics.(in development)"
    , undefined],
  I18N_INFO_POPUP_TEXT_COMMUNITY: [
    "커뮤니티는 제품 엔지니어링이 쉽도록 지식을 나누는 참여형 커뮤니티입니다. 궁금한 점, 노하우 등을 게재해 보세요.",
    "Community is an online discussion board where users can ask questions, share their knowledge, and discuss topics of engineering-related interest. "
    , undefined],
  I18N_INFO_POPUP_LINK01: [
    "제품 선정 가이드 바로가기",
    "Go to Product Selector", undefined
  ],
  I18N_INFO_POPUP_LINK02: [
    "Q&A 바로가기",
    "Go to Q&A", undefined
  ],
  I18N_INFO_POPUP_LINK03: [
    "Knowledge Base 바로가기",
    "Go to Knowledge Base", undefined
  ],
};


const [ko, en, enus] = resi18nutil.getres(resi18n_footer);

export const resi18n_footer_ko = {...resi18n_all_ko, ...ko};
export const resi18n_footer_en = {...resi18n_all_en, ...en};
export const resi18n_footer_enus = {...resi18n_all_enus, ...enus};