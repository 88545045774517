// @flow
import * as React from 'react';
import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {IfAssetToastInfo} from "store/reducers/reducerAssetToast";
import styled from 'styled-components';
import {ACTION_TYPES} from "store/ActionTypes";
import {AnyAction, Dispatch} from 'redux';
import {useHistory} from "react-router-dom";
import {IconBtn} from "asset/style/buttonInput";
import * as colors from "asset/style/color";
import {i18ntrans} from "language/i18nkey";

type Props = {
  info: IfAssetToastInfo;
};
export const AssetToast = ({info}: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const t = i18ntrans.asset;

  useEffect(() => {
    let timerid = setTimeout(() => {
      timerid = null;
      if (info.type !== "clear")
        closeToast(info, dispatch);
    }, 3000);
    return () => {
      if (timerid) clearTimeout(timerid);
    };
  }, []);

  return (
    <Toast className={info.type === "clear" && 'toast-clear'}>
      {info.msgs.map((ele, idx) => <span key={idx} className={"msg"}>{ele}</span>)}
      {info.action === "Setting" && <span className={"link"} onClick={() => {
        history.push("/asset/settings");
        closeToast(info, dispatch);
      }}>{t('I18N_AST_MONITOR_NOT_AUTH_DEVICE_LINK')}</span>}
      {info.type === "clear" && <IconBtn className="close" onClick={() => {
        closeToast(info, dispatch);
      }}/>}
    </Toast>
  );
};

const closeToast = (info: IfAssetToastInfo, dispatch: Dispatch<AnyAction>) => {
  dispatch({type: ACTION_TYPES.ASSET_TOAST.REMOVE, payload: info});
};

const Toast = styled.div`
  min-width: 397px;
  background: black;
  color: white;
  font-size: 1.6rem;
  padding: 20px 32px;
  line-height: 24px;
  animation: slide-top 0.7s both;
  @keyframes slide-top {
    0% {
      -webkit-transform: translateY(100px);
      transform: translateY(100px);
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }

  &.toast-clear {
    padding-right: 16px;

    span.msg {
      display: inline;
      margin-right: 24px;
      word-break: keep-all;
    }

    span.link {
      color: #577EFF;
      cursor: pointer;
      font-size: 1.5rem;
      display: inline-block;
    }

    .close {
      width: 36px;
      height: 20px;
      position: relative;
      vertical-align: middle;
      background-size: 20px auto;
      background-repeat: no-repeat;
      background-position: right center;
      margin-left: 16px;

      &:before {
        display: inline-block;
        content: '';
        width: 1px;
        height: 16px;
        background: ${colors.text07};
        position: absolute;
        left: 0;
        top: calc(50% - 8px);

      }
    }
  }

  @media screen and (max-width: 480px) {
    min-width: initial;
    &.toast-clear {
      padding: 20px 55px 20px 16px;

      button.close {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
      }

    }
  }
`;