import React, {Component} from "react";
import {LanguageService} from "../language/LanguageService";
import {LoginService} from "../login/LoginService";
import {LoginInputValues} from "./Header";
import {SsqImages} from "../images/SsqImages";
import {
  AccountService,
  ILoginFailNumberInfo,
  ILoginFailNumberRequest,
  IPatchLoginFailNumberAndTrialTimeRequest,
  IPatchLoginTimeAndCountRequest,
  IResetLoginFailNumberRequest
} from "../repositories/AccountService";
import GoogleLoginPage from "./GoogleLoginPage";
import NaverLoginPage from "./NaverLoginPage";
import KakaoLoginPage from "./KakaoLoginPage";
import {LOGIN_TYPE_SSQ} from "../definitions/LoginType";
import {getstore} from "store/ssqstore";
import {ACTION_TYPES} from "store/ActionTypes";
import TagManager from "react-gtm-module";
import ToolTip from "./ToolTip";
import svcs from "services";
import {cookieutil} from "util/cookieutil";
import {removePopupSession} from "util/sessionutil";

interface Props {
  languageService: LanguageService;
  loginService: LoginService;
  accountService: AccountService;
  loginInputValues: LoginInputValues;
  showLoginPopupFunc: any;
  showLoginFailPopupFunc: any;
  setIsLoggedInFunc: any;
  setLoginInputValuesFunc: any;
  showSignupPopupFunc: any;
  showSendVerifyEmailPopupFunc: any;
  setSignupEmailFunc: any;
  showSignupSocialPopupFunc: Function;
  showPasswordResetPopupFunc: Function;
}

interface State {
  loginInputValues: LoginInputValues;
  recentLoginType: string;
}

export class LoginPopup extends Component<Props, State> {
  private readonly MAXIMUM_LOGIN_FAIL_NUM = 5;

  constructor(props: Props) {
    super(props);

    this.state = {
      loginInputValues: {
        id: "",
        password: ""
      },
      recentLoginType: ""
    };

    //ssqdebugutil.addobj("LoginPopup", this);
  }

  async componentDidMount() {
    if (cookieutil.isAcceptFunctionConsentCookie(this.props.languageService.lang()))
      this.setState({recentLoginType: svcs.localStorage.getItem('recentLoginType') || ""});
    this.setState({
      loginInputValues: this.props.loginInputValues,
    });
  }

  getTranslation(key: string): string {
    this.props.languageService.setNamespace("I18N_NAMESPACE_HEADER");
    return this.props.languageService.getTranslationByKey(key) || "";
  }

  async processLogin() {
    this.setLoginInputValues();

    const isExistAccount = await this.props.accountService.isExistAccountId(this.state.loginInputValues.id);
    const loginType = await this.props.accountService.getLoginTypeByAccountId(this.state.loginInputValues.id);
    const loginFailNumberRequest: ILoginFailNumberRequest = {
      accountId: this.state.loginInputValues.id
    };
    const resetLoginFailNumberRequest: IResetLoginFailNumberRequest = {
      accountId: this.state.loginInputValues.id
    };

    const loginFailNumber: ILoginFailNumberInfo = await this.props.accountService.getLoginFailNumber(loginFailNumberRequest);

    if (!isExistAccount) {
      this.props.showLoginFailPopupFunc(true);
      return;
    }

    const isVerifyEmailChecked = await this.props.accountService.isVerifyEmailChecked(this.state.loginInputValues.id);
    if (!isVerifyEmailChecked && loginType === LOGIN_TYPE_SSQ) {
      this.setSignupEmailFunc();
      this.showSendVerifyEmailPopup();
      return;
    }

    let isLoggedIn = false;

    await this.props.accountService.resetLoginFailNumber(resetLoginFailNumberRequest);

    if (loginFailNumber.loginFailNumber < this.MAXIMUM_LOGIN_FAIL_NUM) {
      isLoggedIn = await this.props.loginService.login(
        this.state.loginInputValues.id,
        this.state.loginInputValues.password,
        LOGIN_TYPE_SSQ
      );
    }

    const patchLoginFailNumberRequest: IPatchLoginFailNumberAndTrialTimeRequest = {
      accountId: this.state.loginInputValues.id
    };

    if (!isLoggedIn) {
      await this.props.accountService.patchLoginFailNumberAndTrialTime(patchLoginFailNumberRequest);
      this.props.showLoginFailPopupFunc(true);

      return;
    }


    const patchLoginTimeAndCountRequest: IPatchLoginTimeAndCountRequest = {
      email: this.state.loginInputValues.id
    };
    await this.props.accountService.patchLoginTimeAndCount(patchLoginTimeAndCountRequest);

    TagManager.dataLayer({
      dataLayer: {
        "event": "login",
        "login_type": "form"
      }
    });

    this.closeLoginPopup();
    this.setIsLoggedIn();

    let pattern = /^https?:\/\/[^/]+\/(\w\w)\/(\w\w)\/(login)/;
    let match = window.location.href.match(pattern);
    if (match && match[3] === 'login') {
      const url = window.location.origin + "/" + match[1] + "/" + match[2] + "/main";
      window.location.href = url;
    } else {
      window.location.reload();
    }
  }

  closeLoginPopup() {
    this.props.showLoginPopupFunc(false);
    const store = getstore();
    if (store) store.dispatch({type: ACTION_TYPES.MAIN.IS_LOGIN_POPUP, payload: false});
  }

  showSignupPopup() {
    this.props.showSignupPopupFunc(true);
  }

  setIsLoggedIn() {
    this.props.setIsLoggedInFunc(true);
  }

  setLoginInputValues() {
    this.props.setLoginInputValuesFunc(this.state.loginInputValues);
  }

  showSendVerifyEmailPopup() {
    this.props.showSendVerifyEmailPopupFunc(true);
  }

  setSignupEmailFunc() {
    this.props.setSignupEmailFunc(this.state.loginInputValues.id);
  }

  showPasswordResetPopup() {
    this.props.showPasswordResetPopupFunc(true);
  }

  localStorageSetEvent(name: string, value: string) {
    removePopupSession();
    if (cookieutil.isAcceptFunctionConsentCookie(this.props.languageService.lang())) svcs.localStorage.setItem(name, value);
  }

  renderLoginPopup() {
    return <div className="Login_Popup">
      <div className={"login_content_wrap"}>
        <button className={"close-button"} onClick={() => this.closeLoginPopup()}>
          <img alt={"close_small_icon.svg"} src={SsqImages.common.icon.closeSmallIcon()}/>
        </button>
        <div className={"popup_tit"}>{this.getTranslation("I18N_TITLE_LOGIN")}</div>
        <div className={"popup_noti"}>{this.getTranslation("I18N_LOGIN_NOTIFICATION")}</div>
        <div className={"Login-Input-Wrap"}>
          {this.state.recentLoginType == 'SSQ' && <ToolTip languageService={this.props.languageService}/>}
          <p>{this.getTranslation("I18N_SIGNUP_EMAIL")}</p>
          <input className={"login-input"}
            // style={(this.state.loginInputValues.id) ? {transform: 'scale(0.81)',width:'123%',height:'44.46px'} : {}}
            type="text" placeholder="abc@company.com"
            aria-label={'login-input-id'}
            value={this.state.loginInputValues.id}
            onChange={(e) => {
              this.setState({
                loginInputValues: {
                  id: e.target.value,
                  password: this.state.loginInputValues.password
                }
              });
            }}
            onKeyDown={async (e) => {
              if (e.key === "Enter") {
                this.localStorageSetEvent('recentLoginType', 'SSQ');
                await this.processLogin();
              }
            }}
          />
          <p>{this.getTranslation("I18N_TITLE_PASSWORD")}</p>
          <input className={'login-input'} type="password"
            aria-label={'login-input-password'}
            value={this.state.loginInputValues.password}
            style={(this.state.loginInputValues.password) ? {fontSize: '2rem'} : {}}
            onChange={(e) => this.setState({
              loginInputValues: {
                id: this.state.loginInputValues.id,
                password: e.target.value
              }
            })}
            onKeyDown={async (e) => {
              if (e.key === "Enter") {
                this.localStorageSetEvent('recentLoginType', 'SSQ');
                await this.processLogin();
              }
            }}
          />
        </div>
        <div className={"Submit-Button"}
          aria-label={'login-button'}
          onClick={async () => {
            this.localStorageSetEvent('recentLoginType', 'SSQ');
            await this.processLogin();
          }}
        >{this.getTranslation("I18N_TITLE_LOGIN")}
        </div>
        <div className={"login-movebutton"}>
          <button onClick={() => this.showSignupPopup()}>
            {this.getTranslation("I18N_TITLE_JOIN")}
          </button>
          <button
            onClick={() => this.showPasswordResetPopup()}>{this.getTranslation("I18N_TITLE_PASSWORD_RESET")}</button>
        </div>
        <div className={"login-SNS-tit"}>
          <div>{this.getTranslation("I18N_TITLE_SOCIAL_LOGIN")}</div>
        </div>
        <div className={"login-SNS"}>
          <div>
            <KakaoLoginPage
              languageService={this.props.languageService}
              loginService={this.props.loginService}
              accountService={this.props.accountService}
              showSignupSocialPopupFunc={this.props.showSignupSocialPopupFunc}
              showLoginPopupFunc={this.props.showLoginPopupFunc}
              showLoginFailPopupFunc={this.props.showLoginFailPopupFunc}
              recentLoginType={this.state.recentLoginType}
            />
            <NaverLoginPage
              languageService={this.props.languageService}
              recentLoginType={this.state.recentLoginType}
            />
            <GoogleLoginPage
              languageService={this.props.languageService}
              loginService={this.props.loginService}
              accountService={this.props.accountService}
              showSignupSocialPopupFunc={this.props.showSignupSocialPopupFunc}
              showLoginPopupFunc={this.props.showLoginPopupFunc}
              showLoginFailPopupFunc={this.props.showLoginFailPopupFunc}
              recentLoginType={this.state.recentLoginType}
            />
          </div>
        </div>
      </div>
      <button className={"ls-login"} onClick={() => {
        this.closeLoginPopup();
        this.localStorageSetEvent('recentLoginType', 'WELS');
        window.open(`/saml/welsLogin?prevUrl=${encodeURIComponent("/loginCallback")}`);
      }}>
        {this.getTranslation("I18N_TITLE_WELS_LOGIN")}
        {
          this.state.recentLoginType == 'WELS' && <ToolTip languageService={this.props.languageService}/>
        }
      </button>

    </div>;
  }

  render() {
    return <div>

      {this.renderLoginPopup()}
    </div>;
  }
}
