export class CbCenterPopupItem {
  id: number = null;
  expose: boolean = false;
  name: string = null;
  level: string = 'guest';
  type: string = 'CENTER';
  exposeSiteLanguage: string = null;
  popupStart: Date;
  popupEnd: Date;
  popupUtc: number = 90;
  bottomMessage: string = null;
  link: string = null;
  fileKey: string = null;
  disableType: string = null;
  button1: string = null;
  button2: string = null;
  button3: string = null;
  button4: string = null;
  sequence: number = null;
}

export class CbCenterPopupItemList extends CbCenterPopupItem {
  expired: boolean = false;
}

export class CbRadioButtonItem {
  disp: string;
  val: string;
}

export class IFindPopupRequest {
  expose: boolean = null;
  level: string = null;
  exposeSiteLanguage: string = null;
  type: string = null;
  popupUtc: number = null;
}

export class IredLineParam {
  name: boolean = false;
  date: boolean = false;
}

export class ILocalImageRequest {
  imageFile: File = null;
  name: string = null;
  url: string = null;
}

export class IChangeSequenceRequest {
  popupId: number;
  popupSequence: number;
  targetId: number;
  targetSequence: number;
}