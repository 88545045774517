import useSWR, {KeyedMutator} from "swr";
import {IfChkDeviceDetailEntity, IfDeviceDetailRes, IfSettingsFilterRes} from "asset/models/IfDeviceEntity";
import {nulllist} from "asset/utils/asset-util";
import {IfPageRes} from "asset/models/IfPage";


export const useApiDeviceDetailList = ({userId, useYn, projectId, visible = true}
  : {
  userId?: string,
  useYn?: string,
  projectId?: number,
  visible?: boolean
}): [IfDeviceDetailRes[], KeyedMutator<IfDeviceDetailRes[]>, boolean] => {
  const {data, mutate, isLoading} = useSWR<IfDeviceDetailRes[]>(visible && userId && ['/api/member/asset/device/listdetail', {
    userId,
    useYn,
    projectId
  }]);
  return [data || nulllist, mutate, isLoading];
};


export const useApiDeviceDetailOne = ({deviceId}: { deviceId?: string }): [IfDeviceDetailRes, KeyedMutator<IfDeviceDetailRes>, boolean] => {
  const {data, mutate, isLoading} = useSWR<IfDeviceDetailRes>(deviceId && ['/api/member/asset/device/detailone', {deviceId}]);
  return [data, mutate, isLoading];
};

export const useApiDevicePaged = ({userId, pageNumber, searchAsset, filterCondition, sortCondition}
  : {
  userId: string,
  pageNumber: number,
  searchAsset: string,
  filterCondition?: {},
  sortCondition?: {}
}): [IfPageRes<IfChkDeviceDetailEntity>, KeyedMutator<IfPageRes<IfChkDeviceDetailEntity>>] => {
  const {data, mutate} = useSWR<IfPageRes<IfChkDeviceDetailEntity>>(userId && ['/api/member/asset/device/paged', {
    userId,
    pageNumber,
    searchAsset,
    filterCondition,
    sortCondition
  }]);
  return [data, mutate];
};

export const useApiSettingsFilterList = ({userId, type}: {
  userId: string,
  type: string
}): [IfSettingsFilterRes[], KeyedMutator<IfSettingsFilterRes[]>] => {
  const {data, mutate} = useSWR<IfSettingsFilterRes[]>(userId && ['/api/member/asset/device/filter/list', {userId, type}]);
  return [data, mutate];
};