import {useEffect} from "react";

function useOutsideClick(ref, callback, depend) {
  useEffect(() => {
    const refs = Array.isArray(ref) ? ref : [ref];

    function handleClickOutside(e) {
      if (refs.every((ref) => ref.current && !ref.current.contains(e.target))) {
        callback();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, ...depend]);
}

export default useOutsideClick;