import {CbCenterPopupItem} from "borispage/admintab/popuptab/common/PopupTabCommon";
import SwiperCore, {Autoplay, EffectFade, Navigation, Pagination} from "swiper";
import AOS from "aos";
import {BottomPopupArea} from "popup/styledcomp/BottomPopupArea";
import {Swiper, SwiperSlide} from "swiper/react";
import React, {useEffect, useRef, useState} from "react";
import img_navigation_hover_arrow from "images/banner/navigation_hover_arrow.svg";
import img_navigation_arrow from "images/banner/navigation_arrow.svg";
import img_navigation_pause from "images/banner/navigation_pause.svg";
import img_navigation_start from "images/banner/navigation_start.svg";
import {cdn_imag_url_base} from "conf/gconf";
import img_empty_image_icon from "images/common/no_image.svg";
import "swiper/swiper-bundle.css";

type Props = {
  popupList: CbCenterPopupItem[];
  popupClose: () => void;
}

export const BottomPopupItem = (props: Props) => {

  const [left, setLeft] = useState(false);
  const [right, setRight] = useState(false);
  const [auto, setAuto] = useState(true);
  const defaultTextColor = "#2A2C2F";

  SwiperCore.use([Navigation, Autoplay, Pagination, EffectFade]);
  AOS.init({});

  const swiperRef = useRef<SwiperCore>();

  useEffect(() => {
    if (auto) swiperRef.current.autoplay.start();
    else swiperRef.current.autoplay.stop();
  }, [auto]);

  const renderRemoteControl = () => {
    return <div className={"navigation-bar"}>
      <div className="swiper-pagination"/>

      <button className={"swiper-event-button-prev"}
        onMouseEnter={() => setLeft(true)}
        onMouseLeave={() => setLeft(false)}>
        {left ?
          <img src={img_navigation_hover_arrow} alt={"event_arrow.png"}/> :
          <img src={img_navigation_arrow} alt={"event_arrow.png"}/>}
      </button>

      <button className={"swiper-event-button-next"}
        onMouseEnter={() => setRight(true)}
        onMouseLeave={() => setRight(false)}>
        {right ?
          <img src={img_navigation_hover_arrow} alt={"event_arrow.png"}/> :
          <img src={img_navigation_arrow} alt={"event_arrow.png"}/>}
      </button>

      <button className={"swiper-event-button-auto"} onClick={() => setAuto(!auto)}>
        {auto ? <img src={img_navigation_pause} alt={"event_stop.png"}/> :
          <img src={img_navigation_start} alt={"event_start.png"}/>}
      </button>

    </div>;
  };

  const onError = (event) => {
    event.target.src = img_empty_image_icon;
    event.target.classList.add('bottom-empty-image');
  };

  return <BottomPopupArea>

    <Swiper
      onSwiper={(swiper) => (swiperRef.current = swiper)}
      spaceBetween={0}
      navigation={{
        prevEl: '.swiper-event-button-prev',
        nextEl: '.swiper-event-button-next'
      }}
      loop
      effect={"fade"}
      fadeEffect={{
        crossFade: true
      }}
      speed={1000}
      autoplay={{
        delay: 4000,
        disableOnInteraction: false
      }}
      slidesPerView={1}
      pagination={{
        el: '.swiper-pagination',
        type: 'fraction',
      }}
    >

      {props.popupList.map((ele) => {
        const imageSrc = cdn_imag_url_base + '/' + ele.fileKey;
        const value = ele.bottomMessage.split(';');
        let url = ele.link;
        if (url) if (!url.startsWith('http')) url = `https://${ele.link}`;

        return <SwiperSlide
          key={ele.id}
          className="banner"
          onClick={() => url && window.open(url)}>

          <div className="banner-card">
            <img alt={`event-image-${ele.id}.png`} src={imageSrc} className="image-slide" onError={onError}/>
            <div className="slide-txt">
              <div className="slide-text" style={{color: value[1] ? value[1] : defaultTextColor}}>
                {value[0]}
              </div>
            </div>
          </div>
        </SwiperSlide>;
      })}

      {renderRemoteControl()}

    </Swiper>
    <button className="close-button" onClick={() => props.popupClose()}/>
  </BottomPopupArea>;
};