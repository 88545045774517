// @flow
import * as React from 'react';
import {QnaPageFooterCategory, SolutionRelated} from "communitypage/qna/popup/qna-popup-style";
import {SsqImages} from "images/SsqImages";
import {ICategoryForSearch} from "repositories/category/CategoryStore";
import {langutil} from "language/langutil";
import {LanguageService} from "language/LanguageService";

type Props = {
  trans,
  history,
  productCategories: ICategoryForSearch[],
  industryCategories: ICategoryForSearch[],
  applicationLoadCategories: ICategoryForSearch[],
  languageService: LanguageService,
};

export const QnaPageCategory = (props: Props) => {
  const {country, lang} = langutil.splitLangCode(props.languageService.lang());

  return <>
    <QnaPageFooterCategory>
      <div className="view-outer">
        <div className="view-outer-left">
          <div className="conn-category">
            {props.productCategories.length > 0 && <SolutionRelated>
              <div className={"related_tit"}>{props.trans("I18N_PRODUCT_CATEGORY")}<img
                alt={"link_icon.svg"}
                src={SsqImages.product.linkicon()}/></div>
              <div className={"related_list one-line"}>
                {props.productCategories.map((ele, index) => {
                  return <div key={index}>
                  <span onClick={() => props.history.push(`/${country}/${lang}/product/category/` + ele.categoryId)}
                  >{ele.parentsCategory}</span>
                  </div>;
                })}
              </div>
            </SolutionRelated>
            }
          </div>
        </div>

        <div className="view-outer-right">
          {props.industryCategories.length > 0 && <div className="conn-category">
            <SolutionRelated>
              <div className={"related_tit"}>{props.trans("I18N_INDUSTRY_CATEGORY")}
                <img alt={"link_icon.svg"}
                  src={SsqImages.product.linkicon()}/></div>
              <div className={"related_list"}>
                {props.industryCategories.map((ele, index) => {
                  return <div key={index}>
                                        <span
                                          onClick={() => props.history.push(`/${country}/${lang}/solution/category/` + ele.categoryId)}>{ele.categoryName}</span>
                    {(index < (props.industryCategories.length - 1)) &&
                      <span className={"comma"}>, </span>}
                  </div>;
                })}
              </div>
            </SolutionRelated>
          </div>}
          {props.applicationLoadCategories.length > 0 && <div className="conn-category"><SolutionRelated>
            <div className={"related_tit"}>{props.trans("I18N_APPLICATION_LOAD_CATEGORY")}<img
              alt={"link_icon.svg"}
              src={SsqImages.product.linkicon()}/></div>
            <div className={"related_list"}>
              {props.applicationLoadCategories.map((ele, index) => {
                return <div key={index}>
                                        <span
                                          onClick={() => props.history.push(`/${country}/${lang}/solution/category/` + ele.categoryId)}>{ele.categoryName}</span>
                  {(index < (props.applicationLoadCategories.length - 1)) &&
                    <span className={"comma"}>, </span>}
                </div>;
              })}
            </div>
          </SolutionRelated>
          </div>}
        </div>
      </div>
    </QnaPageFooterCategory>
  </>;
};