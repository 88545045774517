import styled, {css} from "styled-components";
import {fontFamilyBold, fontFamilyMedium, fontFamilyRegular} from "../style/fonts";
import {SsqImages} from "../images/SsqImages";
import {AccordionItem} from "react-accessible-accordion";

export const TitleBarContainer = styled.div`
  width: 100%;
  background: #3A3E49;
`;

export const TitleBarContents = styled.div`
  padding: 0 1.6rem;
  height: 3.2rem;
  background: #3A3E49;
  font-size: 1.2rem;
  overflow: hidden;
`;

export const SubmitTerms = styled.div`
  .Signup_top {
    height: 7rem;
    padding: 3rem 3rem 0 3rem;
    width: 100%;

  }

  .popup_tit {
    font-size: 2.4rem;
    ${fontFamilyBold};
  }

  .popup_notice {
    font-size: 1.3rem;
    ${fontFamilyRegular};
    color: #7783A1;
  }

  .check_line {
    background: indianred;
    vertical-align: top;
  }

  .close-button {
    position: absolute;
    right: 2rem;
    top: 2rem;
  }

  .Signup-default-info {
    padding: 0 3rem 1rem 3rem;
    //height: 79%;
    overflow: auto;
    //height: -webkit-fill-available;
  }

  .Next-default-info {
    width: 100%;
    background-color: #fff;
    text-align: center;
    position: relative;
    bottom: 0;
    //height: 9rem;
    padding: 0 3rem 2rem 3rem;
    border-radius: 0 0 4px 4px;
  }

  .Submit-Button {
    color: white;
    border-radius: 100px;
    width: 100%;
    height: 4.8rem;
    line-height: 4.8rem;
    background: #407EDB;
    text-align: center;
    font-size: 1.6rem;
    ${fontFamilyBold};
    cursor: pointer;

    :hover {
      background: #3B71C4;
    }

    :active {
      background: #3463AB;
    }
  }

  .UnSubmit-Button {
    color: white;
    border-radius: 100px;
    width: 100%;
    height: 4.8rem;
    line-height: 4.8rem;
    background: #C3D6F1;
    text-align: center;
    font-size: 1.6rem;
    ${fontFamilyBold};

    //opacity: 30%;
  }

  .popup_tit {
    button {
      margin-right: .8rem;
      vertical-align: middle;
    }
  }

  .Signup_top {
    .popup_noti {
      position: relative;

      .requ {
        position: absolute;
        right: 0;
        bottom: 0;
        font-size: 1rem;
        color: #7783A1 !important;

        span:first-child {
          color: #E6003E
        }
      }
    }
  }

  .Signup_Input {

  }

  .Terms_Input {
    flex: 1;
    width: 100%;
    height: calc(100% - 16rem);
    //height: 82vh;
    //margin-bottom: 6rem;
    overflow-y: auto;
    overflow-x: hidden;

    .input_row {
      margin-bottom: 1.5rem;
      ${fontFamilyRegular};

      p {
        font-size: 1.3rem;
        margin-bottom: .4rem;

        span + span {
          color: #E6003E;
        }
      }
    }

    .sub_check {
      padding-top: 0.5rem;
      padding-left: 1.5rem;
      border-top: 1px solid #C7D6EA;
    }

    .all_check {

      padding-bottom: 1rem;


      .check_txt_all {
        cursor: pointer;
        font-size: 1.5rem;
        ${fontFamilyMedium};
        margin-left: .8rem;
        position: relative;
        transform: translateY(-3px);
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none
      }
    }

    .input_check {
      margin: 1rem 0 0;

      > div:first-child button {
        margin-left: 2.4rem;
        margin-top: .4rem;
      }

      input[type="checkbox"] {
        display: none;
      }

      input[type="checkbox"] + label {
        display: inline-block;
        width: 1.6rem;
        height: 1.6rem;
        border: 1px solid #C7D6EA;
        border-radius: 2px;
        cursor: pointer;
        vertical-align: middle;
      }

      input[type="checkbox"]:checked + label {
        background: #407EDB url(${SsqImages.common.icon.CheckboxCheck()}) center center no-repeat;
        border: 1px solid #407EDB;
      }

      input[type="checkbox"]:checked:disabled + label {
        background: #C7D6EA url(${SsqImages.common.icon.CheckboxCheck()}) center center no-repeat;
        border: 1px solid #C7D6EA;
      }


      .check_txt {
        font-size: 1.3rem;
        ${fontFamilyMedium};
        margin-left: .8rem;
        vertical-align: bottom;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        cursor: pointer;
      }


      button {
        ${fontFamilyRegular};
        font-size: 1.3rem;
        margin-left: .8rem;
        color: #407EDB;
        border-bottom: solid 1px #407EDB;
      }

      div + div {
        margin-top: 1.5rem;
      }

      .Mailing_Info {
        margin-top: .5rem;
        text-align: center;
        font-size: 1rem;
        color: #7783A1;


      }
    }
  }


  .TermsDetail_content {
    ${fontFamilyRegular};
    margin: .8rem 0;
    height: 8rem;

    p {
      font-size: 1.4rem;
      margin-bottom: 1rem;
    }

    > div + div {
      margin-top: 1.6rem;
    }

    .TermsDetail {
      border: 1px solid #C7D6EA;
      border-radius: 4px;
      height: 100%;
      max-height: initial;
      overflow-y: auto;
      padding: 0.4em 1rem 1rem 1rem;
      font-size: 1.3rem;
      line-height: 1.9rem;

      .span_cursor {
        cursor: pointer;
      }

      span {
        color: #7783A1;
        display: block;
        margin-bottom: 2.4rem;
      }

      .h3_text {
        font-size: 1.4rem;
        ${fontFamilyMedium};
        margin-bottom: 2.4rem;
        color: black;
      }

      .h4_text {
        ${fontFamilyMedium};
        margin-bottom: 1.6rem;
        color: black;
      }

      .h5_text {
        ${fontFamilyMedium};
        font-size: 1.3rem;
        color: black;
      }
    }
  }

  @media screen and (max-width: 764px) {
    .Signup_Input {
      .input_row {
        margin-bottom: 1rem !important;
      }

      .input_phone input[type="text"] {
        width: calc(123% - 12.5rem) !important;
      }

      .select_wrap select option {
        font-size: 13px;
      }
    }

    .Next-default-info {
      padding: 0 3rem 4rem 3rem;
      width: 100%;
      position: relative;
      bottom: 0;
    }

  }
  @media screen and (max-width: 360px) {
    > div {
      width: 95%;
      //max-height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
    }

    .popup_tit, .popup_noti, .input_row * {
      word-break: keep-all
    }

    .popup_img {
      max-width: 100%;
    }

    .Terms_Input {
      height: 100vh;
    }

    .Next-default-info {
      bottom: 0;
    }
  }
  //@media screen and (max-height: 640px) {
  //  > div {
  //    width: 95%;
  //    max-height: 100%;
  //    overflow-x: hidden;
  //    overflow-y: auto;
  //  }
  //}
`;

export const TitleBarItem = styled.div`
  color: white;
  padding-left: 0.6rem;
  position: relative;
  height: 3.2rem;
  line-height: 3.4rem;
  display: inline-flex;
  align-items: center;
  min-width: 112px;

  a {
    text-decoration: none;
    color: white;
    display: block;
    width: 100%;
  }

  :first-child {
    padding-left: 0;
  }

  &:not(.selected) {
    &:after {
      content: '';
      position: absolute;
      width: 12px;
      height: 32px;
      top: 0;
      right: 0;
      z-index: 3;
      background-image: url(${SsqImages.header.titlebarbg()});
    }
  }

  &.selected {
    background-color: #3875D2;
    background-image: none;
    position: relative;
    margin-left: -12px;
    padding: 0 16px 0 20px;

    &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: 0;
      right: -10px;
      border-top: 16px solid transparent;
      border-left: 10px solid #3875D2;
      border-bottom: 16px solid transparent;
    }
  }

`;

export const TabItem = styled.div`

  .react-tabs {
    -webkit-tap-highlight-color: transparent;
    position: relative;
  }

  .react-tabs__tab-list {
    border-bottom: 1px solid #90A0B6;
    padding: 0;
    display: flex;
    margin-bottom: 2rem;

    span {
      color: #407EDB;
      font-size: 1.2rem;
      margin-left: 0.8rem;
    }
  }

  .react-tabs__tab {
    min-width: 9%;
    height: 4.3rem;
    line-height: 3.9rem;
    text-align: center;
    bottom: -1px;
    list-style: none;
    cursor: pointer;
    font-size: 1.4rem;
    position: relative;
    padding: 0 1rem;
    flex-shrink: 0;
  }

  .react-tabs__tab--selected {
    color: black;
    position: relative;
  }

  .react-tabs__tab--selected:after {
    display: block;
    content: '';
    background: #407EDB;
    width: 100%;
    height: 4px;
    position: absolute;
    left: 0;
    bottom: 0;
    animation-duration: 0.3s;
    transform-origin: left;
    animation-name: slideright;
  }


  .react-tabs__tab--disabled {
    color: GrayText;
    cursor: default;
  }

  .react-tabs__tab-panel {
    display: none;
  }

  .react-tabs__tab-panel--selected {
    display: block;
  }

  @media screen and (max-width: 1199px) {
    .react-tabs__tab {
      min-width: calc(100% / 7);
      padding: 0 1rem;
      flex: 0 0 auto
    }

    .react-tabs__tab-list {
      overflow-x: auto;
      overflow-y: hidden;

      ::-webkit-scrollbar {
        display: none;
      }
    }
  }

`;


export const ToggleEnableButton = styled.label`
  position: relative;
  display: inline-block;
  line-height: 0;

  input {
    width: 6.25rem;
    height: 2rem;
    margin: 0;

    &:before {
      display: block;
      width: 6.25rem;
      height: 2rem;
      border: 0.0625rem solid #0A78FF;
      border-radius: 0.25rem;
      background-color: #fff;
      cursor: pointer;
      box-sizing: border-box;
      content: '';
    }

    &:after {
      position: absolute;
      top: 0.25rem;
      left: 0.25rem;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 0.1875rem;
      background-color: #0A78FF;
      cursor: pointer;
      content: '';
    }

    &:disabled {
      &:before {
        border: 0.0625rem solid #E8EDEF;
        background-color: #fff;
        cursor: default;
      }

      &:after {
        background-color: #F0F2F4;
        cursor: default;
      }

      & + .text {
        color: #F0F2F4;
        cursor: default;
      }
    }

    &:checked {
      &:before {
        background-color: #0A78FF;
      }

      &:after {
        right: 0.25rem;
        left: auto;
        background-color: #fff;
      }

      & + .text {
        right: auto;
        left: 50%;
        transform: translateX(-50%);
        padding-right: 1.5rem;
        padding-left: 0;
        color: #fff;
      }

      &:disabled {
        &:before {
          border-color: #F0F2F4;
          background-color: #F0F2F4;
        }

        &:after {
          background-color: #fff;
        }

        & + .text {
          color: #fff;
        }
      }
    }
  }

  .text {
    position: absolute;
    top: 0.625rem;
    right: 50%;
    transform: translateX(50%);
    padding-left: 1.5rem;
    ${fontFamilyMedium};
    font-size: 0.875rem;
    line-height: 0.875rem;
    color: #0A78FF;
    cursor: pointer;
  }
`;

export const LevelAvatar = styled.div`

  width: 7.2rem;
  height: 7.2rem;
  text-align: center;
  vertical-align: middle;
  font-size: 4rem;
  text-transform: uppercase;
  line-height: 6.4rem;
  ${fontFamilyMedium};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .lv-supreme {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    //border: 3px solid #EDAE0A !important;
    box-sizing: border-box;
  }

  .lv-master {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    //border: 0.3rem solid #FF7A00 !important;
    box-sizing: border-box;
  }

  .lv-major {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    //border: 0.3rem solid #7B5DF1 !important;
    box-sizing: border-box;
  }

  .lv-rookie {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    //border: 3px solid #407EDB !important;
    box-sizing: border-box;
  }

  .lv-user {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    //border: 3px solid #7C899E !important;
    box-sizing: border-box;
  }
`;

export const LevelScore = styled.div`
  display: flex;
  justify-content: center;
  position: relative;

  .my-level {
    color: #BD8B67;
    font-size: 1.4rem;
    font-weight: 700;
  }

  .name-supreme {
    color: #EDAE0A !important;
  }

  .name-master {
    color: #FF7A00 !important;
  }

  .name-major {
    color: #7B5DF1 !important;
  }

  .name-rookie {
    color: #407EDB !important;
  }

  .name-user {
    color: #7C899E !important;
  }

  .my-score {
    background: #BD8B67;
    padding: .2rem 1.2rem;
    margin-left: .6rem;
    border-radius: 5rem;
    font-size: 1.2rem;
    ${fontFamilyBold};
  }

  .lv-supreme {
    background: #EDAE0A !important;
    color: #fff !important;
  }

  .lv-master {
    background: #FF7A00 !important;
    color: #fff !important;
  }

  .lv-major {
    background: #7B5DF1 !important;
    color: #fff !important;
  }

  .lv-rookie {
    background: #407EDB !important;
    color: #fff !important;
  }

  .lv-user {
    background: #7C899E !important;
    color: #fff !important;
  }

  .lv-expert {
    background: #34A597 !important;
    color: #fff !important;
  }

  .lv-worker {
    background: #7783A1 !important;
    color: #fff !important;
  }
`;

export const InputWrapForCheckBox = styled.div`
  /*checkbox 공통*/

  & > input[type="checkbox"] {
    display: none;
  }

  & > input[type="checkbox"] + label {
    cursor: pointer;
    position: relative;
    padding-left: 2.5rem;

    span {
      color: #407EDB;
    }
  }

  & > input[type="checkbox"] + label:before {
    position: absolute;
    top: 0rem;
    left: 0;
    content: '';
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 2px;
    border: 1px solid #C7D6EA;
    background-color: #fff;
  }

  & > input[type="checkbox"]:checked + label:before {
    background: #407EDB;
    border: 1px solid #407EDB;
  }

  & > input[type="checkbox"]:checked + label:after {
    position: absolute;
    top: 3px;
    left: 3px;
    content: '';
    display: block;
    width: 8px;
    height: 5px;
    border: 2px solid #fff;
    border-top: transparent;
    border-right: transparent;
    transform: rotate(-45deg);
  }

  & > input[type="checkbox"]:disabled + label:before {
    width: 16px;
    height: 16px;
    background: #F0F2F4;
    border: 0;
  }
`;

export const InputWrapForCheckBox2 = styled.div`

  & > label > input[type="checkbox"] {
    //display: none;
  }

  & > label input[type="checkbox"] {
    cursor: pointer;
    position: relative;
    padding-left: 1.4rem;

    &.text {
      padding-left: 2.6rem;

      &.gap20 {
        padding-left: 2rem;
      }
    }

    span {
      color: #407EDB;
    }
  }

  & > label input[type="checkbox"]:before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 2px;
    border: 1px solid #C7D6EA;
    background-color: #fff;
  }

  & > label input[type="checkbox"]:checked:before {
    background: #407EDB;
    border: 1px solid #407EDB;
  }

  & > label input[type="checkbox"]:checked:after {
    position: absolute;
    top: 2px;
    left: 3px;
    content: '';
    display: block;
    width: 8px;
    height: 5px;
    border: 2px solid #fff;
    border-top: transparent;
    border-right: transparent;
    transform: rotate(-45deg);
  }

  & > label input[type="checkbox"]:disabled:before {
    width: 16px;
    height: 16px;
    background: #F0F2F4;
    border: 0;
  }
`;

export const InputWrapForSelect = styled.div`
  /*select 공통*/
  @media screen and (max-width: 499px) {
    min-width: 100%;
  }

  select {
    position: relative;
    border: 1px solid #C7D6EA;
    border-radius: 4px;
    font-size: 1.3rem;
    width: 8.8rem;
    padding: 0 .5rem;
    ${fontFamilyRegular};
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    filter: alpha(opacity=0);
    background: #fff url(${SsqImages.common.icon.select()}) no-repeat center right;

    :active {
      background: #fff url(${SsqImages.common.icon.select02()}) no-repeat center right;
    }

    ::-ms-expand {
      display: none;
    }

    :disabled:active {
      background: #fff url(${SsqImages.common.icon.select()}) no-repeat center right;
    }

    :disabled {
      background: rgba(199, 214, 234, 0.5);
      border: 1px solid #C7D6EA;
      color: #2A2C2F;

      option:disabled {
        color: #2A2C2F !important;
      }
    }
  }
`;

export const admin_popup = css`
  background: white;
  font-size: 1.5rem;
  width: 36rem;
  height: 38.7rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1002;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  color: #2A2C2F;

  .popup-top {
    border-bottom: solid 1px #DDE2E9;
    padding: .8rem 1.6rem;

    h2 {
      font-size: 1.6rem;
      line-height: 3.2rem;
      ${fontFamilyMedium};
      display: inline-block;
    }

    button {
      line-height: 3.2rem;
      float: right;
      cursor: pointer !important;

      img {
        vertical-align: middle;
      }
    }
  }

  .popup-content {
    padding: 2.4rem 3.2rem;
    font-size: 1.3rem;
    height: calc(100% - 11rem);

    .time {
      font-size: 1rem;
      margin-left: .8rem;
      color: #7783A1;
    }

    .comm {
      margin-top: 1.2rem;
      font-size: 1.4rem;
      line-height: 2.4rem;
    }
  }

  .popup-bt {
    border-top: solid 1px #DDE2E9;
    text-align: center;
    padding: 1rem 0;

    button {
      cursor: pointer !important;
      min-width: 11.2rem;
    }

    button + button {
      margin-left: 1rem;
    }
  }

  @media screen and (max-width: 360px) {
    //width: 95%;
    //max-width: 36rem;
    //max-height: 97%;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-height: 640px) {
    top: 5px !important;
    transform: translate(-50%, 0);
    overflow-y: auto;
    height: auto;
    max-height: 97%;
  }
`;
export const InputWrapFortoggle = styled.div`
  input {
    display: none;
    cursor: pointer;

    :checked + label {
      background: rgba(199, 214, 234, 0.5);
      border: 1px solid #C7D6EA;
      color: #2A2C2F;
      padding-right: 0;
      padding-left: 2.7rem;
    }

    :checked + label:before {
      left: .4rem;
    }

    :checked + label:after {
      content: '';
      color: #2A2C2F;
    }

  }

  label {
    position: relative;
    display: inline-block;
    width: 96px;
    height: 32px;
    line-height: 32px;
    border-radius: 4px;
    background: #407DD9;
    border: 1px solid #407DD9;
    cursor: pointer;
    font-size: 1.2rem;
    color: #fff;
    text-align: center;
    padding-right: 2.7rem;
    transition: all .5s;

    :before {
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      background: #fff;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
      border-radius: .2rem;
      vertical-align: middle;
      position: absolute;
      right: 4px;
      top: calc(50% - 12px);
    }

    :after {
      content: '';
      display: block;
      color: #fff;
      font-size: 1.2rem;
      line-height: 3.2rem;
    }
  }

  &.approval_row.version_top {
    background: transparent;
    display: flex;
  }

`;


export const LoadingArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  background-color: rgba(255, 255, 255, .5);
  text-align: center;

  img {
    animation: rotation .7s infinite linear;
    top: calc(50% - 16px);
    position: absolute;

    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(359deg);
      }
    }
  }
`;

export const LoadingIcon = styled.div`
  img {
    animation: rotation .7s infinite linear;

    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(359deg);
      }
    }
  }
`;

export const HistoryArea = styled.div`
  background: none !important;
  padding: 1.6rem 0 !important;

  .history-item {
    font-size: 1.2rem;
    display: flex;

    span + span {
      margin-left: .8rem
    }

    .his_type {
      color: #7783A1;
      margin-right: .8rem;
    }

    .his_data {
      color: #7783A1;
      font-size: 1rem;
    }

    .his_comm {
      font-size: 1.3rem;
      margin-top: .8rem;
    }

    + .history-item {
      margin-top: 1.6rem
    }
`;
export const Btn = styled.div`
  margin-left: 1rem;
  color: white;
  border-radius: 4px;
  width: 100px;
  height: 30px;
  background: #407EDB;
  cursor: pointer;
`;
export const ViewmoreArea = styled.div`
  .view-more-list-box {
    position: relative;
    width: 100%;
    height: 4.8rem;
    line-height: 4.8rem;
    margin: 2rem auto 0;
    background: #fff;

    border: 1px solid #EEF1F6;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;

    :hover {
      background: rgba(64, 125, 217, 0.1);
    }

    :active {
      background: rgba(64, 126, 219, 0.3);
    }
  }

  &:not(:has(.view-more-list-box)) {
    position: relative;
    width: 100%;
    height: 4.8rem;
    line-height: 4.8rem;
    margin: 2rem auto 0;
    background: #fff;

    border: 1px solid #EEF1F6;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;

    :hover {
      background: rgba(64, 125, 217, 0.1);
    }

    :active {
      background: rgba(64, 126, 219, 0.3);
    }
  }

  .ViewmoreBtn {
    button {
      width: 100%;
      color: #407EDB;
      font-size: 12px;
      vertical-align: middle;
      ${fontFamilyMedium};
      display: block;
      line-height: 4.8rem;

      :after {
        content: '';
        display: inline-block;
        width: 24px;
        height: 24px;
        background: url(${SsqImages.common.icon.viewMoreIcon()}) no-repeat center center / cover;
        vertical-align: middle;
        margin-left: .8rem;
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(90deg);
      }
    }
  }

  .ViewmoreNum {
    position: absolute;
    right: 1.6rem;
    top: 0;
    font-size: 1.2rem;
    color: #90A0B6;

    .page-count {
      color: #407EDB;

      :after {
        display: inline-block;
        content: '/';
        margin-left: .3rem;
        color: #90A0B6
      }
    }
  }

  @media screen and (max-width: 764px) {
    padding: 0 1.6rem;
    .view-more-list-box {
      width: 100% !important;
    }

    &.viewmore-area-100 {
      padding: 0 !important;
    }
  }
`;

export const TechnicalDocumentListArea = styled.div`
  &.container {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 20px 20px rgb(0 0 0 / 10%);
    padding: 3.2rem;
    min-height: 70rem;
  }

  .result-area {

    .result-item {
      span {
        b {
          color: #407EDB;
          font-weight: inherit;
          margin-left: .4rem;
          margin-left: .4rem;
        }
      }

      + .result-item {
        margin-top: 2.2rem;
        padding-top: 1.8rem;
        border-top: solid 1px #EEF1F6;
      }

      .result-item-tit {
        .category_name {
          display: inline-block !important;
          cursor: pointer;
        }

        .tit {
          color: #407EDB;
          font-size: 1.9rem;
          ${fontFamilyBold};
          display: flex;

          span {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            cursor: pointer;
          }

          .level {
            display: inline-block;
            background: #7783A1;
            color: #fff;
            border-radius: 16px;
            width: 4.8rem;
            height: 2rem;
            line-height: 2rem;
            font-size: 9px;
            text-align: center;
            vertical-align: middle;
            margin: 0 .4rem 0 1rem;
          }
        }
      }


      .result-item-info {
        font-size: 1.3rem;
        margin-top: 1.2rem;
        overflow: hidden;

        .img-wrap {
          width: 92px;
          height: 92px;
          line-height: 92px;
          background: #F6F8FB;
          border: 1px solid #EEF1F5;
          border-radius: 4px;
          margin-right: 1.2rem;
          cursor: pointer;
          float: left;
          position: relative;
          overflow: hidden;


          img {
            max-width: 100%;
            max-height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .desc-wrap > span {
          display: -webkit-box;
          word-wrap: break-word;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          cursor: pointer;
          line-height: 1.7;
          ${fontFamilyRegular}
        }

        .txt_bt {
          color: #7783A1;
          font-size: 1.2rem;
          display: -webkit-box !important;
          word-wrap: break-word;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;

          ${fontFamilyRegular}
          span {
            vertical-align: middle;
            display: inline-block;
          }

          .line {
            width: 1px;
            height: 15px;
            background: #C7D6EA;
            vertical-align: middle;
            margin: 0 4px;
          }

          img {
            vertical-align: middle;
            margin-right: .7rem;
            cursor: pointer;
          }

          .view-detail {
            margin-left: 1.6rem;
            text-decoration: none;
            color: #407EDB;
            cursor: pointer;
            display: inline-block;

            img {
              margin: 0;
            }
          }
        }

        .related_bt {
          .comma {
            margin-right: .4rem;
          }


          span {
            :before {
              display: none
            }
          }
        }

      }

      .tit-txt {
        display: block;
        color: #7783A1;
        font-size: 1.2rem;
        margin-top: 1rem;
        ${fontFamilyRegular};
      }

      .result-item-img-info {
        .txt-wrap {
          width: calc(100% - 115px);
          float: right;
        }
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .txt-wrap {
      max-height: 8.6rem
    }
  }

  @media screen and (max-width: 1199px) {
    box-shadow: none;
    &.container {
      padding: 3.2rem 1.6rem;
    }

    .txt-wrap {
      max-height: 8.6rem
    }
  }
  @media screen and (max-width: 764px) {
    //padding: 2rem !important;
    .result-item-info {
      position: relative;

      .img-wrap {
        width: 90px !important;
        height: 90px !important;
        margin-right: 1rem !important;
      }

      .desc-wrap {
        display: inline-block;
        max-height: 80px;
        overflow: hidden;
      }

      .desc-wrap span {
        -webkit-line-clamp: 3 !important;
        font-size: 12px;
        line-height: 1.5 !important;
      }

      .tit-txt {
        margin-top: 0 !important;
      }

      .txt_bt {
        clear: both;

        + .tit-txt {
          margin-top: .5rem !important;
        }
      }
    }

    .result-item-img-info {
      .txt-wrap {
        width: calc(100% - 100px) !important;
      }
    }
  }
`;
export const Editorlist = styled.div`
  button strong {
    font-weight: initial;
  }

  h1 > button {
    font-size: 1.3rem;
    color: #2A2C2F;
    line-height: 2.8rem;
    position: relative;
    padding-left: 1.6rem;

    ${fontFamilyMedium}
    :before {
      display: inline-block;
      content: '';
      width: 4px;
      height: 32px;
      background: #C7D6EA;
      position: absolute;
      left: 0;
    }
  }

  h2 > button {
    font-size: 1.1rem;
    color: #2A2C2F;
    line-height: 2.1rem;
    padding-left: 3.2rem;
    position: relative;

    ${fontFamilyMedium}
    :before {
      display: inline-block;
      content: '';
      width: 4px;
      height: 24px;
      background: #C7D6EA;
      position: absolute;
      left: 0;
    }
  }

  h3 > button {
    font-size: 1.1rem;
    color: #7783A1;
    padding-left: 4.8rem;
    line-height: 2.4rem;
    position: relative;

    ${fontFamilyRegular}
    :before {
      display: inline-block;
      content: '';
      width: 4px;
      height: 24px;
      background: #C7D6EA;
      position: absolute;
      left: 0;
    }
  }

  @media screen and (max-width: 1199px) {
    display: none;
  }
`;
export const ErrorpageWrap = styled.div`
  width: 100%;
  height: 100%;
  background: #EEF1F6;
  min-height: 96rem;

  .Errorpage {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    //min-height: 85vh;
    background: #fff;
    max-width: 120rem;
    width: 100%;
    margin: 0 auto;
    padding: 10rem 0 20rem 0;
    min-height: 96rem;

    > .container {
      position: relative;
      width: 100%;
    }

    .error-image {
      margin-top: -40rem;
    }

    .error-tit {
      font-size: 1.6rem;
      margin: 2rem 0px 0.4rem;
    }

    .error-txt {
      font-size: 1.4rem;
      color: #909FB7;
      ${fontFamilyRegular}
    }

    .error-no-button-txt {
      font-size: 1.4rem;
      color: #909FB7;
      margin-bottom: 8rem;
      ${fontFamilyRegular}
    }

    .error-button {
      display: inline-block;
      background: #407EDB;
      border-radius: 100px;
      width: 32rem;
      height: 4.8rem;
      line-height: 4.8rem;
      color: #fff;
      margin-top: 3.2rem;
      font-size: 1.6rem;
      ${fontFamilyBold};
      cursor: pointer;

      :hover {
        background: #3B71C4;
      }

      :active {
        background: #3463AB;
      }
    }

    .error-info {
      position: absolute;
      top: 50%;
      right: 6rem;
      transform: translateY(-50%);

      p {
        color: #407EDB;
        font-size: 1.2rem;
      }
    }
  }

  /* 제품 > 기술문서/샘플 > 카테고리 선택 시 문서 없는 경우 사용*/

  .Errorpage-product {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 120rem;
    width: 100%;
    margin: 0 auto;
    padding: 10rem 0 20rem 0;

    > .container {
      position: relative;
      width: 100%;
    }

    .error-image {
      margin-top: -40rem;
    }

    .error-tit {
      font-size: 1.6rem;
      margin: 2rem 0px 0.4rem;
    }

    .error-txt {
      font-size: 1.4rem;
      color: #909FB7;
      ${fontFamilyRegular}
    }

    .error-button {
      display: inline-block;
      background: #407EDB;
      border-radius: 100px;
      width: 32rem;
      height: 4.8rem;
      line-height: 4.8rem;
      color: #fff;
      margin-top: 3.2rem;
      font-size: 1.6rem;
      ${fontFamilyBold};
      cursor: pointer;

      :hover {
        background: #3B71C4;
      }

      :active {
        background: #3463AB;
      }
    }

    .error-info {
      position: absolute;
      top: 50%;
      right: 6rem;
      transform: translateY(-50%);

      p {
        color: #407EDB;
        font-size: 1.2rem;
      }
    }
  }

  @media screen and (max-width: 764px) {
    .Errorpage .error-image {
      margin-top: -10rem;
    }
  }

  @media screen and (max-width: 1199px) {
    .error-info {
      display: none;
    }
  }
`;

/*기술블로그용 검색 결과 없을 시 나오는 창*/
export const ErrorpageWrapComm = styled.div`
  width: 100%;
  height: 100%;

  .Errorpage_Comm {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 54rem;
    max-width: 120rem;
    width: 100%;
    margin: 0 auto;

    > .container {
      position: relative;
      width: 100%;
    }

    .error-image {
      //margin-top: -40rem;
    }

    .error-tit {
      font-size: 1.6rem;
      margin: 2rem 0px 0.4rem;
    }

    .error-txt {
      font-size: 1.4rem;
      color: #909FB7;
      ${fontFamilyRegular}
    }

    .error-button {
      display: inline-block;
      background: #407EDB;
      border-radius: 100px;
      width: 32rem;
      height: 4.8rem;
      line-height: 4.8rem;
      color: #fff;
      margin-top: 3.2rem;
      font-size: 1.6rem;
      ${fontFamilyBold};
      cursor: pointer;

      :hover {
        background: #3B71C4;
      }

      :active {
        background: #3463AB;
      }
    }

    .error-info {
      position: absolute;
      top: 50%;
      right: 6rem;
      transform: translateY(-50%);

      p {
        color: #407EDB;
        font-size: 1.2rem;
      }
    }
  }

  @media screen and (max-width: 764px) {

  }

  @media screen and (max-width: 1199px) {
    .error-info {
      display: none;
    }
  }
`;


export const HiddenH1 = styled.h1`
  border: 0 none;
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;

export const CommentWrap = styled.div`
  display: flex;
  flex-wrap: wrap;

  &:not(.view-comment-wrap) {
    margin-top: .5rem;
  }

  &.view-comment-wrap:not(.view-comment-wrap_mo) {
    align-items: center;

    .c-name {
      font-size: 1.3rem;
    }

    .c-name span:first-child {
      width: 3.2rem;
      height: 3.2rem;
      display: inline-block;
      vertical-align: middle;
      border: 1px solid #F7B91B;
      border-radius: 100%;
      overflow: hidden;
      margin-right: .4rem;
      font-size: 1.6rem;
      line-height: 3rem;
    }
  }

  @media screen and (max-width: 764px) {
    &.view-comment-wrap {
      display: none;
    }
  }
`;

export const StyledAccordionItem = styled(AccordionItem)`
`;

export const DatePickerWrap = styled.div`
  *::-webkit-scrollbar {
    width: 12px;
    height: 12px;

    &-thumb {
      background-color: rgba(141, 141, 141, 0.7);
      border-radius: 26px;
      background-clip: padding-box;
      border: 3px solid transparent;
    }

    &-track {
      background-color: transparent;
    }

    &-button {
      width: 0;
      height: 0;
    }
  }

  .date-pickers {
    z-index: 2;

    & .red-line {
      border: 1px solid red;
      border-radius: .4rem;
    }

    .react-datepicker__input-container {
      position: relative;
      width: 9.2rem;
      height: 3.2rem;
      border: 1px solid #C7D6EA;
      background: url(${SsqImages.common.icon.dateInput()}) .2rem center transparent no-repeat;
      border-radius: .4rem;
      line-height: 1.2rem;
      margin-right: .4rem;
      text-align: right;
      vertical-align: top;

      input[type="text"] {
        padding-left: 2.8rem;
        width: 100%;
        height: 100%;
        text-align: left;
        font-size: 12px;
        color: #2A2C2F;

        &::placeholder {
          font-size: 1.1rem;
        }
      }
    }
  }

  .react-datepicker-popper {
    width: 300px;
    min-height: 308px;
    background: #F7F8FB;
    border: solid 1px #C7D6EA;
    box-shadow: 6px 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding-bottom: 20px;
    top: -10px !important;
    overflow: hidden;
  }

  .react-datepicker {
    background: transparent;
    height: 100%;
    ${fontFamilyRegular};
    font-size: 1.2rem;
    width: 100%;
    border: none;
    border-radius: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .react-datepicker__navigation {
      width: 24px;
      height: 24px;
      top: 12px;
      display: block;

      &-icon {
        display: block;
        width: 100%;
        left: auto;
        text-align: center;

        &::before {
          border: none;
          width: 24px;
          height: 24px;
          transform: none;
          top: 0;
          right: 0;
          left: auto;
          background-image: url(${SsqImages.common.icon.dateArrow()});
        }

        &--previous {
          transform: scaleX(-1);
        }
      }

      &--previous {
        left: 12px;
      }

      &--next {
        right: 12px;
      }

    }

    .react-datepicker__header__dropdown > div {
      margin: 0 22px 0 0 !important;

      &.react-datepicker__year-dropdown-container, &.react-datepicker__month-dropdown-container {

        .react-datepicker__year-read-view, .react-datepicker__month-read-view {
          visibility: visible !important;

          &--down-arrow {
            border: none;
            width: 18px;
            height: 18px;
            background-image: url(${SsqImages.common.icon.boxArrowDown()});
            transform: none;
            top: calc(50% - 9px);
            right: -22px;
          }
        }

        &:has(.react-datepicker__year-dropdown), &:has(.react-datepicker__month-dropdown) {
          .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow {
            background-image: url(${SsqImages.common.icon.boxArrowDownOn()});
          }
        }
      }
    }

    .react-datepicker__month-container {
      width: 100%;
    }

    .react-datepicker__header {
      border: none;
      padding: 0;
      background: transparent;

      .react-datepicker__current-month, .react-datepicker__header__dropdown > div {
        font-size: 1.6rem;
        ${fontFamilyBold};
        line-height: 48px;
        border-bottom: solid 1px #EEF1F6;
      }

      .react-datepicker__current-month {
        font-size: 0;
        background: #fff;
        margin-bottom: 12px;
      }

      .react-datepicker__header__dropdown {
        position: absolute;
        top: 0;
        z-index: 99;
        width: calc(100% - 80px);
        height: 48px;
        text-align: center;
        display: flex;
        justify-content: center;
        margin: 0 40px 12px;

        .react-datepicker__month-dropdown {
          width: 96px;
          left: 30px !important;
        }

        .react-datepicker__year-dropdown {
          width: 75px;
          left: auto;

          .react-datepicker__year-option {
            text-align: center !important;
          }
        }

        .react-datepicker__month-dropdown, .react-datepicker__year-dropdown {
          background: #fff;
          box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
          z-index: 3;
          height: 180px;
          overflow-y: auto;
          top: 36px;
          left: auto;
          border: none;
          border-radius: 4px;

          .react-datepicker__month-option, .react-datepicker__year-option {
            color: #2A2C2F;
            font-size: 1.2rem;
            line-height: 36px;
            padding: 0 16px;
            text-align: left;
            ${fontFamilyMedium};

            + div {
              border-top: solid 1px #C7D6EA;
            }

            &:hover {
              background: rgba(64, 125, 216, 0.1);
            }

            &--selected_month, &--selected_year {
              ${fontFamilyBold};
              background: rgba(64, 125, 216, 0.3);
              color: #407DD8;
            }

            &--selected {
              display: none;
            }

            .react-datepicker__navigation--years {
              background-image: url(${SsqImages.common.icon.dateArrow()});
              top: 0;
              transform: rotate(90deg);
              width: 20px;
              height: 20px;
              background-size: 20px;

              &-upcoming {
                transform: rotate(-90deg);
              }
            }
          }
        }
      }

      .react-datepicker__day-name {
        ${fontFamilyBold}
      }
    }

    .react-datepicker__month {
      margin: 0;

      .react-datepicker__day {
        position: relative;
        border-radius: 100%;
        ${fontFamilyRegular};

        &--today {
          z-index: 2;
          background: #fff;
          color: #407EDB;
          outline: solid 1px #407EDB;
          ${fontFamilyBold}
        }

        &--selected {
          background: #407EDB;
          color: #fff;
          ${fontFamilyBold}
        }

        &--disabled, &--outside-month {
          color: #7783A1;
        }

        &--keyboard-selected {
          background: transparent;
        }
      }
    }


    .react-datepicker__day-name, .react-datepicker__day {
      width: 38px;
      line-height: 38px;
      margin: 0;
      color: #2A2C2F;
      ${fontFamilyMedium};
    }
  }

  .react-datepicker__triangle {
    display: none;
  }

  .date-line {
    padding: 0 .4rem 0 .4rem;
    height: 3.2rem;
    line-height: 3.2rem;
    text-align: center;
    vertical-align: center;
    color: #2A2C2F
  }

  .time-picker {
    height: 3.2rem;
    width: 6.7rem;
    border: 1px solid #C7D6EA;
    border-radius: .4rem;
    line-height: 1.2rem;
    font-size: 12px;

    padding-left: .9rem;
    text-align: left;
    appearance: none;
    ${fontFamilyMedium};

    &.red-line {
      border: 1px solid red;
    }
  }



`;

export const AllPopupOverlay = styled.div`
  position: fixed !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  background: rgba(0, 0, 0, 0.5);
`;

export const TranPopupOverlay = styled(AllPopupOverlay)`
  z-index: 10;
  background: transparent;
  @media screen and (max-width: 1199px) {
    background: rgba(0, 0, 0, 0.5);
    z-index: 13;
  }
`;

export const DefaultPopupWrap = styled.div`
  > div {
    background: white;
    z-index: 1002;
    position: fixed;
    width: 36rem;
    height: 64rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    padding: 3rem;
    color: #2A2C2F
  }

  .popup_tit {
    font-size: 2.4rem;
    ${fontFamilyBold};
  }

  .popup_noti {
    font-size: 1.4rem;
    ${fontFamilyRegular};
    margin-top: 1rem;
  }

  .close-button {
    position: absolute;
    right: 2rem;
    top: 2rem;
  }

  .Submit-Button {
    color: white;
    border-radius: 100px;
    width: 100%;
    height: 4.8rem;
    line-height: 4.8rem;
    background: #407EDB;
    text-align: center;
    font-size: 1.6rem;
    ${fontFamilyBold};
    cursor: pointer;

    :hover {
      background: #3B71C4;
    }

    :active {
      background: #3463AB;
    }
  }

  .TermsDetail_Wrap .popup_tit button {
    display: none;
  }

  @media screen and (max-width: 360px) {
    > div {
      width: 95%;
      max-width: 36rem;
      max-height: 97%;
      overflow-x: hidden;
      overflow-y: auto;
      height: auto;
      min-height: 63rem;
    }

    .popup_tit, .popup_noti, .Info_content * {
      word-break: keep-all
    }

    .popup_img {
      max-width: 100%;
    }
  }
  @media screen and (max-height: 640px) {
    > div {
      top: 5px !important;
      transform: translate(-50%, 0);
      height: auto;
      max-height: 100%;
      overflow-y: auto;
    }
  }
`;
export const TagSearchWrap = styled.div`

  .select-title.s-tag {
    padding: 0 1.2rem 0 .8rem;

    .plus {
      width: 2rem;
      height: 2rem;
      display: inline-block;
      background-image: url(${SsqImages.common.icon.tagInput()});
      background-repeat: no-repeat;
      background-position: center;
      vertical-align: middle;
      transition: transform .4s;

      &.on {
        transform: rotate(-360deg);
      }
    }

    &.active > .plus {
      background-image: url(${SsqImages.common.icon.tagPlusOn()});
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .tag-search {
    position: absolute;
    z-index: 10;
    width: auto;
    min-width: 20rem;
    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    color: #2A2C2F;
    display: none;
    line-height: initial;

    .tag-input-item {
      height: 4rem;
      border-bottom: 1px solid #C7D6EA;
      background-image: url(${SsqImages.common.icon.tagSearch()});
      background-repeat: no-repeat;
      background-position: 1rem center;

      input.tag-input {
        font-size: 1.6rem;
        ${fontFamilyMedium};
        height: 3.2rem;
        margin: .4rem 0 .4rem 3.4rem;
        width: calc(100% - 3.8rem);
      }

      .close {
        width: 4rem;
        height: 1.6rem;
        background-image: url(${SsqImages.common.icon.closeSmallIcon()});
        background-repeat: no-repeat;
        background-size: 4rem 1.6rem;
        right: 0;
        position: absolute;
        margin-top: 1.3rem;
        cursor: pointer;
      }
    }

    li {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      span.team {
        ${fontFamilyRegular};
        margin-left: 3px;
      }
    }

    .tag-last li {
      padding: .8rem 1.2rem .8rem 3.6rem;
      background-image: url(${SsqImages.common.icon.tagTime()});
      background-repeat: no-repeat;
      background-position: 0.8rem center;
      ${fontFamilyMedium};
      font-size: 1.3rem;
      user-select: none;
      cursor: pointer;

      > span {
        color: #909FB7;
      }

      :hover {
        background-color: rgba(64, 126, 219, 0.1);
      }

      &.on {
        background-color: rgba(64, 126, 219, 0.3);
      }
    }

    .tag-plus li {
      padding: .8rem 1.2rem .8rem 3.6rem;
      background-image: url(${SsqImages.common.icon.tagPlus()});
      background-repeat: no-repeat;
      background-position: .8rem center;
      ${fontFamilyBold};
      font-size: 1.3rem;
      user-select: none;
      cursor: pointer;

      > span {
        color: #909FB7;
      }

      :hover {
        background-color: rgba(64, 126, 219, 0.1);
      }

      &.on {
        background-color: rgba(64, 126, 219, 0.3);
      }
    }
  }

`;
export const CommuBottomCont = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  > div {
    width: 65%;
  }

  @media screen and (max-width: 1199px) {
    .talk-idea {
      padding-right: 3rem;
    }

    .talk-idea:only-child {
      width: 100%;
    }
  }
  @media screen and (max-width: 764px) {
    flex-direction: column;
    .talk-idea:only-child {
      width: 100%;
    }

    .talk-idea {
      border-top: 1px solid #C7D6EA;
      padding: 3rem;
      width: 100%;
      margin-top: 0;
    }
  }
`;

export const CommuFeedback = styled.div`
  margin-bottom: 3.2rem;
  width: 100%;

  > .title {
    color: #2A2C2F;
    font-size: 1.2rem;
    ${fontFamilyMedium};
    line-height: 2.4rem;
    margin-bottom: 8px;
  }

  .feedback_btn {
    display: flex;
    gap: 10px;

    button {
      font-size: 1rem;
      color: #2a2c2f;
      ${fontFamilyMedium}
    }

    button:before {
      width: 2.4rem;
      height: 2.4rem;
      content: '';
      display: inline-block;
      background-repeat: no-repeat;
      background-position: center;
      vertical-align: middle;
    }
  }

  .feedback_btn_Good {
    .clickGood:before {
      background-image: url("${SsqImages.community.icon.communitySmileOn()}");
    }

    button:before {
      background-image: url("${SsqImages.community.icon.communitySmile()}");
    }
  }


  .feedback_btn_Bad {
    .clickBad:before {
      background-image: url("${SsqImages.community.icon.communityAngryOn()}");
    }

    button:before {
      background-image: url("${SsqImages.community.icon.communityAngry()}");
    }
  }

  .idea-review {
    position: relative;
  }

  .idea-review textarea {
    width: 100%;
    height: 8rem;
    background: #fff;
    border: 1px solid #C7D6EA;
    border-radius: 4px;
    margin-top: 1.6rem;
    padding: 8px 24px 8px 8px;
    ${fontFamilyRegular};
    color: #000;
    font-size: 1.3rem;
  }

  .idea-review textarea::placeholder {
    color: #7783A1;
    font-size: 1rem;
  }

  .idea-review > button {
    width: 20px;
    height: 20px;
    background: url(${SsqImages.community.icon.feedbackSend()}) center center no-repeat;
    background-size: 16px;
    position: absolute;
    bottom: 7px;
    right: 4px;
  }

`;