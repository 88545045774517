import React, {Component} from "react";
import {SsqImages} from "../images/SsqImages";
import {LanguageService} from "../language/LanguageService";
import parse from "html-react-parser";
import {AccountService, emptyLoginFailNumberInfo, ILoginFailNumberInfo, ILoginFailNumberRequest} from "../repositories/AccountService";
import {connect} from "react-redux";
import {setExistSsqAccountNotSnsStore} from "../store/reducers/rootReducer";
import {LOGIN_TYPE_GOOGLE, LOGIN_TYPE_KAKAO, LOGIN_TYPE_NAVER, LOGIN_TYPE_SSQ, LOGIN_TYPE_WELS} from "../definitions/LoginType";


interface Props {
  languageService: LanguageService;
  accountService: AccountService;
  showLoginFailPopupFunc: any;
  loginFailedAccountId: string;
  isExistSsqAccountNotSnsStore?: boolean;
  setExistSsqAccountNotSnsStore?: Function;
}

interface State {
  isExistAccount: boolean;
  loginFailedNumber: ILoginFailNumberInfo;
  loginType: string;
}

class LoginFailPopup extends Component<Props, State> {
  private readonly MAXIMUM_LOGIN_FAIL_NUM = 5;

  constructor(props: Props) {
    super(props);

    this.state = {
      isExistAccount: false,
      loginFailedNumber: emptyLoginFailNumberInfo,
      loginType: ""
    };
  }

  async componentDidMount() {
    const loginFailedUserId: ILoginFailNumberRequest = {
      accountId: this.props.loginFailedAccountId
    };

    const isExistAccount = await this.props.accountService.isExistAccountId(this.props.loginFailedAccountId);
    const loginFailedNumber: ILoginFailNumberInfo = await this.props.accountService.getLoginFailNumber(loginFailedUserId);
    const loginType = await this.props.accountService.getLoginTypeByAccountId(this.props.loginFailedAccountId);

    this.setState({
      isExistAccount,
      loginFailedNumber,
      loginType
    });
  }

  componentWillUnmount() {
    this.props.setExistSsqAccountNotSnsStore && this.props.setExistSsqAccountNotSnsStore(false);
  }

  getTranslation(key: string): string {
    this.props.languageService.setNamespace("I18N_NAMESPACE_HEADER");
    return this.props.languageService.getTranslationByKey(key) || "";
  }

  closeLoginFailPopup() {
    this.props.showLoginFailPopupFunc(false);
  }

  renderNotificationText = () => {
    if (this.props.isExistSsqAccountNotSnsStore) {
      // 아이디가 존재하는지 검사
      if ([LOGIN_TYPE_GOOGLE, LOGIN_TYPE_NAVER, LOGIN_TYPE_KAKAO].includes(this.state.loginType)) {
        // SNS 계정인경우
        return this.renderLoginFailExistSnsNotification();
      } else {
        // SNS 계정이 아닌 일반 계정인 경우
        return this.renderLoginFailExistSsqNotification();
      }
    } else if (this.state.loginType === LOGIN_TYPE_WELS) {
      return this.renderLoginFailExistWelsNotification();
    } else if (this.state.loginType === LOGIN_TYPE_SSQ) {
      return this.renderLoginFailNumberCountNotification();
    } else if ([LOGIN_TYPE_GOOGLE, LOGIN_TYPE_NAVER, LOGIN_TYPE_KAKAO].includes(this.state.loginType)) {
      return this.renderLoginFailExistSnsNotification();
    } else {
      // 아이디가 존재하지 않을경우
      return this.getTranslation("I18N_LOGIN_FAIL_NOTIFICATION");
    }
  };


  renderLoginFailNotification() {
    return <div>
      {this.renderNotificationText()}
    </div>;
  }

  renderLoginFailExistSsqNotification() {
    return <div>
      {parse(this.getTranslation("I18N_LOGIN_FAIL_EXIST_SSQ"))}
    </div>;
  }

  renderLoginFailExistWelsNotification() {
    return <div>
      {parse(this.getTranslation("I18N_LOGIN_FAIL_EXIST_WELS"))}
    </div>;
  }

  renderLoginFailExistSnsNotification() {
    return <div>
      {parse(this.getTranslation("I18N_LOGIN_FAIL_EXIST_SNS"))}
    </div>;
  }

  renderLoginFailNumberCountNotification() {
    return <div>
      {parse(this.getTranslation("I18N_LOGIN_FAIL_COUNT_NOTIFICATION"))}
      <span>({this.state.loginFailedNumber.loginFailNumber}/{this.MAXIMUM_LOGIN_FAIL_NUM})</span>
    </div>;
  }

  renderLoginFail() {
    if (this.state.loginFailedNumber.loginFailNumber >= this.MAXIMUM_LOGIN_FAIL_NUM) {
      return parse(this.getTranslation("I18N_LOGIN_BLOCKED_NOTIFICATION"));
    } else {
      return this.renderLoginFailNotification();
    }
  }

  renderLoginFailPopup() {
    return <div className={"Login_Icon_Popup LoginFail_Popup"}>
      <div className={"popup_tit"}>
        <button onClick={() => this.closeLoginFailPopup()}>
          <img alt={"back_button.svg"} src={SsqImages.header.icon.backButton()}/>
        </button>
        {this.getTranslation("I18N_LOGIN_FAIL_TITLE")}
      </div>
      <div className={"popup_noti"}>
        {this.renderLoginFail()}
      </div>
      <div className={"popup_img"}>
        <img alt={"popup_loginfail_icon.svg"} src={SsqImages.header.popup.loginfail()}/>
      </div>
      <button className={"Submit-Button"} onClick={() => this.closeLoginFailPopup()}>
        {this.getTranslation("I18N_BACK_TO_LOGIN")}
      </button>
    </div>;
  }

  render() {
    return <div>
      {this.renderLoginFailPopup()}
    </div>;
  }
}

export default connect(
  (state: any) => ({
    isExistSsqAccountNotSnsStore: state.root.isExistSsqAccountNotSnsStore
  }),
  dispatch => ({
    setExistSsqAccountNotSnsStore: (isExistSsqAccountNotSnsStore: boolean) => dispatch(setExistSsqAccountNotSnsStore(isExistSsqAccountNotSnsStore))
  })
)(LoginFailPopup);
