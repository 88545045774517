import React, {Component} from "react";
import clsx from "clsx";
import {CbCenterPopupItem} from "borispage/admintab/popuptab/common/PopupTabCommon";
import {ISsqdataLecture} from "repositories/SsqDataRepository";
import svcs from "services";
import {i18ns} from "language/i18nkey";
import {CenterPopupArea} from "popup/styledcomp/CenterPopupArea";
import {CenterEventPopup} from "popup/comp/center/CenterEventPopup";
import {CenterLecturePopup} from "popup/comp/center/CenterLecturePopup";
import {AssetPopupItem} from "popup/comp/asset/AssetPopupItem";

interface Props {
  totalPopupCount: number;
  currentPopupCount: number;
  styleIndex: number;
  mobileKey: number;
  changeValue: number;
  overlayPopupCountFunc: (i: number, j: number) => void;

  centerPopupItem: CbCenterPopupItem;
  lecturePopupItem: ISsqdataLecture;
  assetPopupItem: CbCenterPopupItem;
}

interface State {
  visiable: boolean;
  windowWidth: number;
  positionKey: number;
  changeKey: number;
}

export class CenterPopupItem extends Component<Props, State> {
  // common
  pcSecondStyle = [39, 61];
  pcThirdStyle = [27, 50, 73];
  subPcSecondStyle = [30, 70];
  tabletThirdStyle = [17, 50, 83];

  constructor(props: Props) {
    super(props);
    this.state = {
      visiable: true,
      windowWidth: window.innerWidth,
      positionKey: this.props.styleIndex,
      changeKey: 3,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (this.props.totalPopupCount >= 4) {

      if (this.props.totalPopupCount != this.props.currentPopupCount) {
        if (prevProps.currentPopupCount != this.props.currentPopupCount) {

          this.setState({
            changeKey: this.state.changeKey + 1
          });


          if (this.state.positionKey >= 3) {
            if (this.state.changeKey == this.state.positionKey) {
              this.setState({positionKey: this.props.changeValue});
            }
          }
        }
      }
    }
  }

  handleResize = () => {
    this.setState({windowWidth: window.innerWidth});
  };

  closeEventPopup = () => {
    this.setState({visiable: false});
    this.props.overlayPopupCountFunc(this.state.positionKey, this.props.styleIndex);
  };

  popupStyle = () => {
    if (window.innerWidth < 1600) {
      if (this.props.totalPopupCount == 2) {
        return `${this.subPcSecondStyle[this.props.styleIndex]}%`;
      } else if (this.props.totalPopupCount >= 3) {
        if (this.props.styleIndex <= 2)
          return `${this.tabletThirdStyle[this.props.styleIndex]}%`;
        else return `${this.tabletThirdStyle[this.state.positionKey]}%`;
      }
      return "50%";
    } else {
      if (this.props.totalPopupCount == 2) {
        return `${this.pcSecondStyle[this.props.styleIndex]}%`;
      } else if (this.props.totalPopupCount >= 3) {
        if (this.props.styleIndex <= 2)
          return `${this.pcThirdStyle[this.props.styleIndex]}%`;
        else return `${this.pcThirdStyle[this.state.positionKey]}%`;
      }
      return "50%";
    }
  };

  trans = (key: string) => {
    svcs.svcLang.setNamespace(i18ns.svc);
    return svcs.svcLang.getTranslationByKey(key) || "";
  };

  render() {
    const popupPcStyle = {left: `${this.popupStyle()}`, display: this.state.positionKey >= 3 ? "none" : ""};
    const popupMobileStyle = {display: this.state.positionKey >= 3 ? "none" : this.props.styleIndex == this.props.mobileKey ? "" : "none"};

    return <CenterPopupArea>

      {this.state.visiable &&
        <div className={clsx(window.innerWidth > 1199 ? "popup_base" : "popup_base_mobile")}
          style={window.innerWidth > 1199 ? popupPcStyle : popupMobileStyle}>
          {this.props.centerPopupItem.id &&
            <CenterEventPopup popupItem={this.props.centerPopupItem} closeEventPopup={this.closeEventPopup} trans={this.trans}/>}
          {this.props.lecturePopupItem.lectureId &&
            <CenterLecturePopup popupItem={this.props.lecturePopupItem} closeEventPopup={this.closeEventPopup} trans={this.trans}/>}
          {this.props.assetPopupItem.id &&
            <AssetPopupItem popupItem={this.props.assetPopupItem} closeEventPopup={this.closeEventPopup} trans={this.trans}/>}
        </div>
      }

    </CenterPopupArea>;
  }
}
