import React, {Component} from "react";
import {EmptyLectureFilterItemAll, ISsqdataLecture} from "repositories/SsqDataRepository";
import {CbCenterPopupItem, IFindPopupRequest} from "borispage/admintab/popuptab/common/PopupTabCommon";
import axios from "axios";
import {RECENT_EXCEPT_LECTURE_DATE_KEY, RECENT_EXCEPT_VALUE_STORAGE_KEY} from "util/LocalStorageWrapper";
import svcs from "services";
import {CenterPopupItem} from "popup/comp/center/CenterPopupItem";
import {LanguageService} from "language/LanguageService";
import {connect} from "react-redux";
import {IfDisplayPopupItem} from "models/IfDisplayPopupItem";
import {ACTION_TYPES} from "store/ActionTypes";
import {appendSessionLanguage} from "util/sessionutil";
import {AllPopupOverlay} from "style/CommonStyledComponents";

interface Props {
  languageService: LanguageService;
  displayPopupItem: IfDisplayPopupItem;
  setDisplayPopupItem: (b: IfDisplayPopupItem) => void;
}

interface State {
  lecturePopupList: ISsqdataLecture[];
  popupList: CbCenterPopupItem[];
  currentPopupCount: number;
  totalPopupCount: number;
  popupIndex: number;
  mobilePopupKey: number;
  popupArray: number[];
}

export class CenterPopup extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      popupList: [],
      lecturePopupList: [],
      currentPopupCount: 0,
      totalPopupCount: 0,
      popupIndex: 0,
      mobilePopupKey: 0,
      popupArray: []
    };
  }

  async checkCenterPopup() {
    const findPopupRequest: IFindPopupRequest = {
      ...new IFindPopupRequest(),
      exposeSiteLanguage: this.props.languageService.getLanguage(),
      type: 'CENTER'
    };
    await axios.post('/api/guest/popup/select', findPopupRequest).then(async (res) => {
      if (res.data.length == 0) return null;
      const storageKeys = Object.keys(localStorage);
      const today = new Date();
      const disableKey = storageKeys
        .filter(key => key.startsWith("center-disabled-"))
        .filter(key => {
          const keyDate = localStorage.getItem(key);
          if (!keyDate) return key;
          if (new Date(keyDate) > today) return key;
          else localStorage.removeItem(key);
        }).map(key => key.replace("center-disabled-", ""));

      const popupList = res.data.filter(ele => !disableKey.includes(`${ele.id}`));
      await this.setState({
        popupList
      });
    });
  }

  checkExceptLecturePopup() {
    if ([null, ''].includes(localStorage.getItem(RECENT_EXCEPT_LECTURE_DATE_KEY))) {
      localStorage.removeItem(RECENT_EXCEPT_VALUE_STORAGE_KEY);
    } else {
      const expires = new Date().toDateString();
      if (new Date(expires) > new Date(localStorage.getItem(RECENT_EXCEPT_LECTURE_DATE_KEY))) {
        localStorage.removeItem(RECENT_EXCEPT_LECTURE_DATE_KEY);
        localStorage.removeItem(RECENT_EXCEPT_VALUE_STORAGE_KEY);
      }
    }
  }

  async checkLecturePopup() {
    const param = {
      ...EmptyLectureFilterItemAll,
      exceptId: localStorage.getItem(RECENT_EXCEPT_VALUE_STORAGE_KEY),
      lang: this.props.languageService.lang(),
      timezone: new Date().getTimezoneOffset()
    };

    await Promise.all([svcs.repoSsqData.getLectrueAlarmMainByFilter(param)]).then(([res]) => {
      this.setState({
        lecturePopupList: res
      });
    });
  }

  async componentDidMount() {
    const lang = this.props.languageService.getLanguage();
    appendSessionLanguage('DISPLAY_CENTER_SESSION', lang);

    await this.checkCenterPopup();
    this.checkExceptLecturePopup();
    await this.checkLecturePopup();

    const count = this.state.popupList.length + this.state.lecturePopupList.length;
    const popupArray = Array.from({length: count}, (_, index) => index);
    this.setState({
      currentPopupCount: count,
      totalPopupCount: count,
      popupArray
    });
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (prevState.currentPopupCount != this.state.currentPopupCount) {
      if (this.state.currentPopupCount == 0) this.props.setDisplayPopupItem({...this.props.displayPopupItem, center: false});
    }
  }

  overlayPopupCountFunc = (key: number, mobileKey: number) => {
    const nextPopupArray = this.state.popupArray.filter(ele => ele != mobileKey);
    this.setState({
      currentPopupCount: this.state.currentPopupCount - 1,
      mobilePopupKey: nextPopupArray[0] ? nextPopupArray[0] : 0,
      popupArray: nextPopupArray
    });
    if (this.state.totalPopupCount >= 4) {
      this.setState({popupIndex: key});
    }
  };

  renderCenterAlarmPopup() {
    const popup = [...this.state.popupList, ...this.state.lecturePopupList];
    const emptyAsset = new CbCenterPopupItem();

    return <React.Fragment>
      {popup.map((item, index) => {
        const eventItem = item as CbCenterPopupItem;
        const lectureItem = item as ISsqdataLecture;
        return <CenterPopupItem
          key={index}
          styleIndex={index}
          centerPopupItem={eventItem}
          lecturePopupItem={lectureItem}
          totalPopupCount={this.state.totalPopupCount}
          currentPopupCount={this.state.currentPopupCount}
          overlayPopupCountFunc={this.overlayPopupCountFunc}
          mobileKey={this.state.mobilePopupKey}
          changeValue={this.state.popupIndex}
          assetPopupItem={emptyAsset}
        />;
      })}
    </React.Fragment>;
  }

  render() {
    return <div>
      {
        this.state.currentPopupCount > 0 && <AllPopupOverlay aria-label={"popup-overlay"} className={"event_popup_overlay"}/>
      }

      {
        this.state.currentPopupCount > 0 && this.renderCenterAlarmPopup()
      }
    </div>;
  }
}

export default connect(
  (state: any) => ({
    displayPopupItem: state.main.displayPopupItem
  }),
  dispatch => ({
    setDisplayPopupItem: (b: IfDisplayPopupItem) => dispatch({type: ACTION_TYPES.MAIN.DISPLAY_POPUP_ITEM, payload: b}),
  })
)(CenterPopup);