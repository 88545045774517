// @flow
import * as React from 'react';
import {forwardRef, RefObject, useCallback, useImperativeHandle, useRef, useState} from 'react';
import {Modal} from "ctls/modal/Modal";
import * as colors from "asset/style/color";
import styled from 'styled-components';
import {AssetModalWrap} from "asset/style/AssetStyled";
import {IconBtn, TextBtn} from "asset/style/buttonInput";
import {fontFamilyMedium} from "asset/style/AssetFonts";

class ConfirmPopupParam {
  title: string;
  msgs: string[];
  savebtn?: string;
  cancelbtn?: string;
  alert?: boolean;
}

export interface IfConfirmPopupParam extends ConfirmPopupParam {
}

export type RefConfirmPopup = {
  show(param: IfConfirmPopupParam): Promise<any>;
};

export type RefConfirm = RefObject<RefConfirmPopup>
export const ConfirmPopup = forwardRef<RefConfirmPopup, any>((props, ref) => {
  const [visible, setVisible] = useState<boolean>(false);
  const promise = useRef<{ resolve: any; reject: any }>({resolve: null, reject: null});
  const [param, setParam] = useState(new ConfirmPopupParam());

  const handleClickOpen = () => {
    setVisible(true);
  };
  const handleClose = () => {
    promise.current.reject();
    setVisible(false);
  };

  const handleConfirm = () => {
    promise.current.resolve();
    setVisible(false);
  };

  useImperativeHandle(ref, () => ({
    show: (param: IfConfirmPopupParam) => {
      return new Promise((resolve, reject) => {
        promise.current.resolve = resolve;
        promise.current.reject = reject;
        param.alert = param.alert ?? false;
        param.savebtn = param.savebtn ?? 'Confirm';
        param.cancelbtn = param.cancelbtn ?? 'Cancel';
        setParam(param);
        handleClickOpen();
      });
    },
  }));
  return (<>
      {visible ? (<Modal onClick={(e) => e.stopPropagation()} onScroll={true}>
        <PopCnts>
          <div className={"pop_tit"}>
            <span>
              {/*Delete Project*/}
              {param.title}
            </span>
            <IconBtn onClick={handleClose} className={"close"}></IconBtn>
          </div>
          <div className={"pop_body"}>
            {/*The Project will be deleted.<br/>*/}
            {/*Are you sure to proceed?*/}
            {param.msgs
              .map((ele, idx) => <React.Fragment key={`${idx}_${ele}`}>{ele}</React.Fragment>)
              .reduce<JSX.Element[]>((accu, elem, idx) => {
                return accu === null ? [elem] : [...accu, <br key={idx}/>, elem];
              }, null)
            }
          </div>
          <div className={'pop_btn action'}>
            {!param.alert && <TextBtn className="size-md" onClick={handleClose}>{param.cancelbtn}</TextBtn>}
            <TextBtn className="size-md primary" onClick={handleConfirm}>{param.savebtn}</TextBtn>
          </div>
        </PopCnts>
      </Modal>) : null}
    </>
  );
});

export const PopCnts = styled(AssetModalWrap)`
  width: 482px;
  min-height: 205px;

  .pop_tit {
    padding: 0 32px;
    min-height: 68px;
    border-bottom: none;

    span {
      color: #1A1D24;
    }
  }

  .pop_body {
    background: ${colors.bg04};
    padding: 24px 32px;
    font-size: 1.8rem;
    line-height: 2.4rem;
    ${fontFamilyMedium};
    height: calc(100% - 132px);
  }

  .pop_btn {
    background: #F0F2F7;
    height: 64px;
    padding: 0 32px;
    border-top: none;
  }

  @media screen and (max-width: 1199px) {
    max-height: 100%;
    max-width: 97% !important;
    width: 482px !important;
    height: initial;
  }
`;
ConfirmPopup.displayName = "ConfirmPopup";

export const useConfirmPopup = (): [v: RefObject<RefConfirmPopup>, e: () => JSX.Element] => {
  const ref = useRef<RefConfirmPopup>(null);
  const [cmpn] = useState(<ConfirmPopup ref={ref}/>);
  const element = useCallback(() => cmpn, [cmpn]);
  return [ref, element];
};
