import React, {useState} from "react";
import img_back_button from "images/header/back_button.svg";
import img_close_small_icon from "images/common/close_small_icon.svg";
import {ColGrp} from "uiutil/ColGrp";
import img_icon_notice from "images/boris/icon_notice.svg";
import {ConsentParam} from "cookie/common/consentParam";
import clsx from "clsx";

type Props = {
  setPopupPage: (p: number) => void;
  setDisplayCookiePopup: (p: boolean) => void;
  trans: (p: string) => string;
  consentParam: ConsentParam;
  setConsentParam: (p: ConsentParam) => void;
  cookieConfigureUpdate: (p: ConsentParam) => void;
  loadIntroPage: (p: string) => void
}

export const CookieConfigure = (props: Props) => {

  const [onEssential, setOnEssential] = useState(false);
  const [onAnalytical, setOnAnalytical] = useState(false);
  const [onFunctionality, setOnFunctionality] = useState(false);
  const [onAdvertising, setOnAdvertising] = useState(false);
  const [test, setTest] = useState(false);

  return (
    <div className={"cookie_popup_base"}>
      <button className={"close-button"}
        onClick={() => props.setDisplayCookiePopup(false)}
      >
        <img alt={"close_small_icon.svg"} src={img_close_small_icon}/>
      </button>
      <div className={"popup_top"}>
        <div className={"popup_tit"}>
          <button
            onClick={() => props.setPopupPage(0)}
          >
            <img alt={"back_button.svg"} src={img_back_button}/>
          </button>
          {props.trans('I18N_COOKIE_POPUP_CONFIGURE')}
        </div>
      </div>


      <div className={"popup_middle_table_mode"}>

        <div className={"introduce table_mode"}>
          {props.trans('I18N_COOKIE_POPUP_CONFIGURE_INTRO')}
        </div>
        <div className={"cookie_table_wrap"}>
          {/* table start */}
          <table className={"cookie_table"}>
            <ColGrp cols={[70, 30]}/>


            {/* header start */}
            <thead>
            <tr>
              <td>{props.trans('I18N_COOKIE_POPUP_TYPE')}</td>
              <td>{props.trans('I18N_COOKIE_POPUP_AGREE')}</td>
            </tr>
            </thead>
            {/* header end */}

            <tbody>

            {/* Essential start */}
            <tr className={clsx(onEssential ? "intro_open" : "", "title")} onClick={() => setOnEssential(!onEssential)}>
              <td>
                <div className={'title_area'}><span>{props.trans('I18N_COOKIE_POPUP_ESSENTIAL')}</span>
                  <button className={'icon_information_container'}
                    onClick={() => props.loadIntroPage("ESSENTIAL")}
                  >
                    <img src={img_icon_notice} alt={'icon_information.svg'}/>
                  </button>
                </div>
              </td>
              <td>
                <div className={"check_switch essential_check"}>
                  <input type={"checkbox"} id={"essential"}
                    readOnly={true}
                    checked={props.consentParam.essential}
                  />
                  <label htmlFor={"essential"}/>
                </div>
              </td>
            </tr>

            {onEssential && <tr>
              <td colSpan={2} className={"intro_table_color"}>These cookies are strictly necessary for the provision of the service that you have
                expressly requested or have the sole purpose of enabling or facilitating communication by electronic means. For example, they allow us
                to remember the items you have placed in your shopping basket. These cookies are automatically activated and cannot be deactivated
                because they are essential to enable you to browse our site.
              </td>
            </tr>}
            {/* Essential end */}

            {/* Analytical start */}
            <tr className={clsx(onAnalytical ? "intro_open" : "", "title stripe_table_color")} onClick={() => setOnAnalytical(!onAnalytical)}>
              <td>
                <div className={'title_area'}><span>{props.trans('I18N_COOKIE_POPUP_ANALYTICAL')}</span>
                  <button className={'icon_information_container'}
                    onClick={() => props.loadIntroPage("ANALYTICAL")}>
                    <img alt={'icon_notice.svg'} src={img_icon_notice}/>
                  </button>
                </div>

              </td>
              <td>
                <div className={"check_switch"}>
                  <input type={"checkbox"} id={"analytical"}
                    checked={props.consentParam.analytical}
                    onChange={() => props.setConsentParam({...props.consentParam, analytical: !props.consentParam.analytical})}
                  />
                  <label htmlFor={"analytical"}/>
                </div>
              </td>
            </tr>

            {onAnalytical && <tr>
              <td colSpan={2} className={"intro_table_color"}>These cookies allow us to understand how you access the site and your browsing habits
                (e.g. pages viewed, time spent on a page, content clicked). They allow us to analyze the performance and design of our site and to
                detect possible errors. Owing to these performance indicators, we are constantly improving our site and our products, content and
                offers.
              </td>
            </tr>}
            {/* Analytical end */}

            {/* Functionality start */}
            <tr className={clsx(onFunctionality ? "intro_open" : "", "title")} onClick={() => setOnFunctionality(!onFunctionality)}>
              <td>
                <div className={'title_area'}><span>{props.trans('I18N_COOKIE_POPUP_FUNCTIONALITY')}</span>
                  <button className={'icon_information_container'}
                    onClick={() => props.loadIntroPage("FUNCTIONALITY")}>
                    <img alt={'icon_notice.svg'} src={img_icon_notice}/>
                  </button>
                </div>
              </td>
              <td>
                <div className={"check_switch"}>
                  <input type={"checkbox"} id={"functionality"}
                    checked={props.consentParam.functionality}
                    onChange={() => props.setConsentParam({...props.consentParam, functionality: !props.consentParam.functionality})}
                  />
                  <label htmlFor={"functionality"}/>
                </div>
              </td>
            </tr>

            {onFunctionality && <tr>
              <td colSpan={2} className={"intro_table_color"}>These cookies allow us to offer you customized content according to your profile, your
                interests and your usage. For example, AB test cookies allow us to test different versions of a page/service in order to statistically
                identify visitor preferences. By accepting these cookies, you help us to improve the layout and features of our site.
              </td>
            </tr>}
            {/* Functionality end */}

            {/* Advertising start */}
            <tr className={clsx(onAdvertising ? "intro_open" : "", "title stripe_table_color")} onClick={() => setOnAdvertising(!onAdvertising)}>
              <td>
                <div className={'title_area'}><span>{props.trans('I18N_COOKIE_POPUP_ADVERTISING')}</span>
                  <button className={'icon_information_container'}
                    onClick={() => props.loadIntroPage("ADVERTISING")}>
                    <img alt={'icon_notice.svg'} src={img_icon_notice}/>
                  </button>
                </div>
              </td>
              <td>
                <div className={"check_switch"}>
                  <input type={"checkbox"} id={"advertising"}
                    checked={props.consentParam.advertising}
                    onChange={() => props.setConsentParam({...props.consentParam, advertising: !props.consentParam.advertising})}
                  />
                  <label htmlFor={"advertising"}/>
                </div>
              </td>
            </tr>

            {onAdvertising && <tr>
              <td colSpan={2} className={"intro_table_color"}>These cookies collect information about your browsing habits. They remember that you
                have
                visited our site and share this information with partners, such as advertisers, for the purpose of targeted advertising. The use of
                these cookies include displaying advertisements, impression capping, fraud prevention, billing, and measurement.
              </td>
            </tr>}
            {/* Advertising end */}

            </tbody>

          </table>
          {/* table end */}
        </div>

      </div>

      <div className={"popup_bottom"}>
        <button className={"popup_bottom_submit"}
          onClick={() => props.cookieConfigureUpdate(props.consentParam)}
        >
          {props.trans('I18N_COOKIE_POPUP_CONFIRM_CHOICE')}
        </button>
      </div>
    </div>
  );
};