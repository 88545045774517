// @flow
import * as React from 'react';
import {useState} from 'react';
import {IFileHover, ISsqDataDocument, IVersion, NO_VERSION_TAG} from "repositories/SsqDataRepository";
import {getEncodedStringRemovedUnValidUrl, parseSanitize} from "util/commonUtils";
import {SsqImages} from "images/SsqImages";
import {docutil} from "util/docutil";
import {getFileSize} from "util/fileSize";
import {i18ntrans} from "language/i18nkey";
import CommunityPostHoverText from "communitypage/common/CommunityPostHoverText";
import {ssqutil} from "util/ssqutil";

type Props = {
  ver: IVersion,
  doc: ISsqDataDocument,
};
export const ViewFileDownload = (props: Props) => {
  const trans = i18ntrans.solution;
  const selected: IVersion = props.ver;
  const doc = props.doc;

  const [downlaodHover, setDownloadHover] = useState({file: {} as any});
  return (
    <div className="view-footer">
      <div className="content-download" id={"solution-download-bookmark"}>
        <p className="title">{trans("I18N_SOLUTION_DOWNLOAD")}</p>
        {selected.version !== NO_VERSION_TAG &&
          <div className={"accordion-version"}>
            <div className={"version-tit"}>{trans("I18N_DOC_COL_VERSION")} {selected.version}
            </div>
            <div className={"version-desc"}>{parseSanitize(selected.versionDescription)}</div>
          </div>
        }
        {selected.files.map((file: IFileHover, index) => {
          let fileDownloadUrl: string = `/uploads/${file.fileKey}/${getEncodedStringRemovedUnValidUrl(file.fileName)}`;
          if (file.blobUrlForLargeFile !== null && file.blobUrlForLargeFile !== undefined && file.blobUrlForLargeFile !== "") {
            fileDownloadUrl = ssqutil.getBlobUrlForLargeFile(file.blobUrlForLargeFile);
          }
          return <ul className="down-list" key={file.id}>
            <li>
              <button className="down-title" onClick={async () => {
                await docutil.updateDownloadCount(file.id);
                await docutil.postUserDownloadByFile(file, doc);
              }}>
                <a aria-label={`download-${index}`} href={(fileDownloadUrl)}>{file.fileName}</a>
              </button>
              <div className={"down-icon"}
                onMouseEnter={(e) => {
                  e.stopPropagation();
                  file.downloadHover = true;
                  setDownloadHover({file: file});
                }}
                onMouseLeave={(e) => {
                  e.stopPropagation();
                  file.downloadHover = false;
                  setDownloadHover({file: file});
                }}
                onClick={async () => {
                  await docutil.updateDownloadCount(file.id);
                  await docutil.postUserDownloadByFile(file, doc);
                }}><a aria-label={`download-${index}`} href={(fileDownloadUrl + "?type=attachment")}>
                <img alt={"download_icon.svg"} src={SsqImages.common.icon.downloadImg()}/>
              </a>
                {file.downloadHover && <CommunityPostHoverText type={'download'}/>}
              </div>

              <ul className="file-info">
                <li>{docutil.getFileLangStr(file.fileLang)}</li>
                <li>{getFileSize(file.fileSize)}</li>
                {/*<li>{trans("I18N_DOC_DOWNLOAD_COUNT")} <span className="count">{file.downloadCount}</span></li>*/}
              </ul>
            </li>
          </ul>;
        })
        }
      </div>
    </div>
  );
};