import {i18ntrans} from "language/i18nkey";
import {DocType, getDocTypeLangKey2} from "definitions/DocType";


export const getDocTypeDropDownStr = (doctype: string) => {
  // 여기서는, i18n을 이용해서, namespace 설정까지 한 다음, 결과 문자열을 리턴함.
  const t = i18ntrans.community;

  if (doctype === DocType.TIP) return t('I18N_TITLE_COMMUNITY_BOARD_DOCTYPE_TIP');
  if (doctype === DocType.DOC_NOTI) return t('I18N_TITLE_COMMUNITY_BOARD_DOCTYPE_NOTICE_FILTER');
  if (doctype === DocType.DOC_EVT) return t('I18N_TITLE_COMMUNITY_BOARD_DOCTYPE_EVENT_FILTER');
  if (doctype === DocType.JOB_OFFER) return t('I18N_TITLE_COMMUNITY_BOARD_DOCTYPE_JOB_OFFER_FILTER');
  if (doctype === DocType.JOB_SEARCH) return t('I18N_TITLE_COMMUNITY_BOARD_DOCTYPE_JOB_SEARCH_FILTER');

  return t(getDocTypeLangKey2(doctype));

};