// @flow
import * as React from 'react';
import {useSelector} from 'react-redux';
import {IfReduxAssetToastState} from "store/reducers/reducerAssetToast";
import {AssetToast} from "asset/layout/toast/AssetToast";
import styled from "styled-components";


type Props = {};
export const AssetToastMgr = (props: Props) => {
  const toast = useSelector((state: any): IfReduxAssetToastState => state.assettoast);
  return (
    <ToastWrap>
      {toast.list.map(ele => <AssetToast key={ele.uuid} info={ele}/>)}
    </ToastWrap>
  );
};
const ToastWrap = styled.div`
  position: fixed;
  bottom: 28px;
  right: 28px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  transition: all 0.5s;
  z-index: 999;
  @media screen and (max-width: 480px) {
    right: 10px;
    left: 10px
  }
`;