import {UserRole} from "definitions/UserRole";


export const roleutil = {
  getUserRoles: (role: UserRole, checked: boolean, userRoles: string): string => {
    let roles = (userRoles || "").split(",").filter(ele => !!ele);
    if (checked) {
      // 롤추가.
      const o = roles.find(ele => ele === role);
      if (!o) roles.push(role);
    } else {
      // 록삭제.
      roles = roles.filter(ele => ele !== role);
    }
    roles.sort();
    return roles.join(",");
  },
  hasRole: (role: UserRole, userRoles: string): boolean => {
    if ((userRoles || "").indexOf(role) >= 0) {
      return true;
    }
    return false;
  },
  hasExposeRole: (accountRole: string, exposeLanguage: string): boolean => {
    let exposeLanguageKey = "";
    if (exposeLanguage === "ko-KR") exposeLanguageKey = "PRTN_KR";
    else if (exposeLanguage === "en") exposeLanguageKey = "PRTN_WW";
    else if (exposeLanguage === "en-US") exposeLanguageKey = "PRTN_US";
    else return false;

    if (![null, undefined, ""].includes(accountRole)) {
      const accountRoleList = accountRole.split(",");
      if (accountRoleList.includes(exposeLanguageKey)) return true;
    }

    return false;
  }
};