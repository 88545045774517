import * as React from 'react';
import svcs from "services";
import {i18ns} from "language/i18nkey";
import styled from "styled-components";
import {fontFamilyMedium} from "style/fonts";

type Props = {};
type State = {
  visible: boolean,
  top: number,
  left: number,
};

type ShowParam = {
  top: number,
  left: number,
}

class TechPointGuide extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      visible: false,
      top: 0,
      left: 0,
    };
  }

  show(param: ShowParam) {
    return new Promise(() => {
      this.setState({
        visible: true,
        top: param.top,
        left: param.left,
      });
    });
  }

  hide() {
    return new Promise(() => {
      this.setState({
        visible: false
      });
    });
  }

  trans(key: string): string {
    svcs.svcLang.setNamespace(i18ns.mypage);
    return svcs.svcLang.getTranslationByKey(key) || "";
  }

  render() {
    return (<>{this.state.visible &&
      <TechPointGuideArea style={{position: "fixed", top: this.state.top, left: this.state.left}}>
        <div className={'tp_content'}><li className={'c-name'}>{this.trans('I18N_PROFILE_PAGE_LEGEND')}</li> <span className={'c-txt first'}>1000</span></div>
        <div className={'tp_content'}><li className={'c-name'}>{this.trans('I18N_PROFILE_PAGE_SUPREME')}</li> <span className={'c-txt second'}>500</span></div>
        <div className={'tp_content'}><li className={'c-name'}>{this.trans('I18N_PROFILE_PAGE_MASTER')}</li><span className={'c-txt third'}>100</span></div>
        <div className={'tp_content'}><li className={'c-name'}>{this.trans('I18N_PROFILE_PAGE_ROOKIE')}</li><span className={'c-txt fourth'}>30</span></div>
        <div className={'tp_content'}><li className={'c-name'}>{this.trans('I18N_PROFILE_PAGE_BEGINNER')}</li><span className={'c-txt fifth'}>0</span></div>
      </TechPointGuideArea>}</>);
  }


}

export default TechPointGuide;

const TechPointGuideArea = styled.div`
  display: flex;
  flex-direction: column;
  
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  
  padding: 1rem 1.5rem 1rem 1.5rem;
  z-index: 999;  
  .c-name {
    .search_highlight {
      background: linear-gradient(to top, #FFFAA0 50%, transparent 50%) !important;
      font-weight: initial;
    }
    color: #2A2C2F;
  }
  
  .c-name span {
    width: 2.2rem;
    height: 2.2rem;
    display: inline-block;
    vertical-align: middle;
    border-radius: 2rem;
    overflow: hidden;
    margin-right: .4rem;
    margin-top: -0.2rem;
  }
  
  .c-txt {
    ${fontFamilyMedium};
    height: 1.8rem;
    line-height: 1.8rem;
    padding: 0 1rem;
    font-size: 1rem;
    border-radius: 2rem;
    margin-right: .4rem;
  }
  
  .c-txt.first {
    color: #EDAE0A;
    background: #FBEFCE;
  }
  
  .c-txt.second {
    color: #FF7A00;
    background: #FFE4CC;
  }
  
  .c-txt.third {
    color: #7B5DF1;
    background: #E5DFFC;
  }
  
  .c-txt.fourth {
    color: #407EDB;
    background: #D9E5F8;
  }
  
  .c-txt.fifth {
    color: #7C899E;
    background: #E5E7EC;
  }
  
  .tp_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  @media screen and (max-width: 764px) {
    display: none;  
  }
`;