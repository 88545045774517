import React, {Component} from "react";
import {SsqImages} from "../images/SsqImages";
import styled from "styled-components";
import {fontFamilyBold, fontFamilyMedium, fontFamilyRegular} from "../style/fonts";
import {LanguageService} from "../language/LanguageService";
import {TermsType} from "../definitions/TermsType";
import {connect} from "react-redux";
import {IfLoginInfo} from "models/IfLoginInfo";
import {LANG_EN, LANG_EN_US, LANG_KO, LANG_KO_KR, langutil} from "language/langutil";
import {ToastWrapper} from "productpage/ToastWrapper";
import {ACTION_TYPES} from "store/ActionTypes";
import {DisplayPopupItem, IfDisplayPopupItem} from "models/IfDisplayPopupItem";
import {AllPopupOverlay} from "style/CommonStyledComponents";

const InfoPopup = React.lazy(() => import( "./InfoPopup").then(module => ({default: module.InfoPopup})));
const TermsDetailPopup = React.lazy(() => import( "../header/TermsDetailPopup").then(module => ({default: module.TermsDetailPopup})));
const TermsDetailPrivatePopup = React.lazy(() => import( "../header/TermsDetailPrivatePopup").then(module => ({default: module.TermsDetailPrivatePopup})));

interface Props {
  languageService: LanguageService;
  loginInfo: IfLoginInfo;
  setDisplayPopupItem: (b: IfDisplayPopupItem) => void;
}


interface State {
  showTermsDetailPopup: boolean;
  showInfoPopup: boolean;
  showTermsDetailPrivatePopup: boolean;
  showOriginalVersionTermsDetailPopup: boolean;
}

class Footer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showTermsDetailPopup: false,
      showInfoPopup: false,
      showTermsDetailPrivatePopup: false,
      showOriginalVersionTermsDetailPopup: false
    };
  }

  getTranslatedText(key: string): string {
    this.props.languageService.setNamespace("I18N_NAMESPACE_FOOTER");
    return this.props.languageService.getTranslationByKey(key) || "";
  }

  showTermsDetailPopupFunc = (showTermsDetailPopup: boolean) => {
    this.setState({showTermsDetailPopup: showTermsDetailPopup});
  };

  showInfoPopupFunc = (showInfoPopup: boolean) => {
    this.setState({showInfoPopup: showInfoPopup});
  };

  showTermsDetailPrivatePopupFunc = (showTermsDetailPrivatePopup: boolean) => {
    this.setState({showTermsDetailPrivatePopup});
  };

  showOriginalVersionTermsDetailPopupFunc = (showOriginalVersionTermsDetailPopup: boolean) => {
    this.setState({showOriginalVersionTermsDetailPopup});
  };

  render() {
    const {country, lang} = langutil.splitLangCode(this.props.languageService.lang());

    return <FooterContainer>
      <div className={"container"}>
        <div className="footer-left">
          <a href={`/${country}/${lang}/main`} className={"footer-logo"}>
            <img alt={"footer_ssq.svg"} src={SsqImages.footer.ftssq()}/>
          </a>
          <div>
            <p>{this.getTranslatedText("I18N_FOOTER_CUSTOMER_CENTER")}</p>
            <p className={"mail"}>ssqadmin@ls-electric.com</p>
            <span>LS ELECTRIC ⓒ</span>
          </div>
        </div>

        <div className="footer-center-after">
          <div>
            <dl>
              <dt><a
                href={this.props.languageService.getLanguage() == LANG_KO_KR ? `/${country}/${lang}/community/qna` : `/${country}/${lang}/community/blog`}>{this.getTranslatedText("I18N_FOOTER_SITEMAP_COMMUNITY")}</a>
              </dt>
              {this.props.languageService.getLanguage() == LANG_KO_KR &&
                <dd><a href={`/${country}/${lang}/community/qna`}>{this.getTranslatedText("I18N_FOOTER_SITEMAP_COMMUNITY_SUB01")}</a></dd>}
              <dd><a href={`/${country}/${lang}/community/blog`}>{this.getTranslatedText("I18N_FOOTER_SITEMAP_COMMUNITY_SUB02")}</a></dd>
              <dd><a href={`/${country}/${lang}/community/board`}>{this.getTranslatedText("I18N_FOOTER_SITEMAP_COMMUNITY_SUB03")}</a></dd>
              <dd><a href={`/${country}/${lang}/service/sample`}>{this.getTranslatedText("I18N_FOOTER_SITEMAP_SERVICE_SUB01")}</a></dd>
            </dl>
            <dl>
              <dt><a href={`/${country}/${lang}/product/category/0`}>{this.getTranslatedText("I18N_FOOTER_SITEMAP_PRODUCT")}</a></dt>
              {this.props.languageService.getLanguage() == LANG_KO_KR &&
                <div>
                  <dd><a href={`/${country}/${lang}/product/category/1`}>PLC</a></dd>
                  <dd><a href={`/${country}/${lang}/product/category/3`}>HMI</a></dd>
                  <dd><a href={`/${country}/${lang}/product/category/2`}>LV Drive</a></dd>
                  <dd><a href={`/${country}/${lang}/product/category/4`}>Servo/Motion</a></dd>
                </div>
              }
              {this.props.languageService.getLanguage() === LANG_EN_US &&
                <div>
                  <dd><a href={`/${country}/${lang}/product/category/616`}>LV Drive</a></dd>
                  <dd><a href={`/${country}/${lang}/product/category/626`}>HMI</a></dd>
                  <dd><a href={`/${country}/${lang}/product/category/644`}>Power</a></dd>
                </div>
              }
              {this.props.languageService.getLanguage() === LANG_EN &&
                <div>
                  <dd><a href={`/${country}/${lang}/product/category/427`}>PLC</a></dd>
                  <dd><a href={`/${country}/${lang}/product/category/558`}>HMI</a></dd>
                  <dd><a href={`/${country}/${lang}/product/category/595`}>LV Drive</a></dd>
                  <dd><a href={`/${country}/${lang}/product/category/574`}>Servo/Motion</a></dd>
                </div>
              }
            </dl>
            <dl>
              {this.props.languageService.getLanguage() === LANG_KO_KR && <div>
                <dt><a href={`/${country}/${lang}/solution/category/0`}>{this.getTranslatedText("I18N_FOOTER_SITEMAP_SOLUTIONS")}</a></dt>
                <dd><a href={`/${country}/${lang}/solution/category/88`}>공조</a></dd>
                <dd><a href={`/${country}/${lang}/solution/category/96`}>수처리</a></dd>
                <dd><a href={`/${country}/${lang}/solution/category/70`}>자동차</a></dd>
                <dd><a href={`/${country}/${lang}/solution/category/91`}>식음료</a></dd>
              </div>
              }

              {this.props.languageService.getLanguage() === LANG_EN && <div>
                <dt><a href={`/${country}/${lang}/solution/category/0`}>{this.getTranslatedText("I18N_FOOTER_SITEMAP_SOLUTIONS")}</a></dt>
                <dd><a href={`/${country}/${lang}/solution/category/509`}>HVAC</a></dd>
                <dd><a href={`/${country}/${lang}/solution/category/543`}>Water Treatment</a></dd>
                <dd><a href={`/${country}/${lang}/solution/category/537`}>Automotive</a></dd>
                <dd><a href={`/${country}/${lang}/solution/category/546`}>Food&Beverage</a></dd>
              </div>
              }
              {this.props.languageService.getLanguage() === LANG_EN_US &&
                <div className="flex-center">
                  <dt><a href={`/${country}/${lang}/productselector`}>{this.getTranslatedText("I18N_FOOTER_SITEMAP_SERVICE")}</a></dt>
                  <dd><a href={`/${country}/${lang}/productselector`}>{this.getTranslatedText("I18N_FOOTER_SITEMAP_SERVICE_SUB04")}</a></dd>
                  <dd onClick={() => window.open(`https://www.lselectricamerica.com/training/`)}><span>Online Training</span><img
                    src={SsqImages.common.icon.pageLinkGray16()} alt=""/></dd>
                  <dd><a href={`/${country}/${lang}/service/support`}>Remote Support</a></dd>
                  <dd onClick={() => window.open(` https://lselectricamerica.zohodesk.com/portal/en/newticket`)}>Submit a Ticket
                    <img src={SsqImages.common.icon.pageLinkGray16()} alt=""/></dd>
                  <dd onClick={() => window.open(` https://www.lselectricamerica.com/contact-us/`)}>Contact Us
                    <img src={SsqImages.common.icon.pageLinkGray16()} alt=""/></dd>
                </div>
              }
            </dl>
            <dl>{this.props.languageService.getLanguage() !== LANG_EN_US ?
              <div>
                <dt><a href={`/${country}/${lang}/productselector`}>{this.getTranslatedText("I18N_FOOTER_SITEMAP_SERVICE")}</a></dt>
                <dd><a href={`/${country}/${lang}/productselector`}>{this.getTranslatedText("I18N_FOOTER_SITEMAP_SERVICE_SUB04")}</a></dd>
                <dd><a href={`/${country}/${lang}/service/classroom`}>{this.getTranslatedText("I18N_FOOTER_SITEMAP_SERVICE_SUB03")}</a></dd>
                <dd><a href={`/${country}/${lang}/service/support`}>{this.getTranslatedText("I18N_FOOTER_SITEMAP_SERVICE_SUB02")}</a></dd>
              </div>
              :
              <div className="flex-center">
                <dt onClick={() => window.open(`https://www.lselectricamerica.com/`)}>Link</dt>
                <dd onClick={() => window.open(`https://www.lselectricamerica.com/`)}><span>LS Electric America</span><img
                  src={SsqImages.common.icon.pageLinkGray16()} alt=""/></dd>
                <dd onClick={() => window.open(`https://www.ls-electric.com/`)}><span>LS Electric Global</span><img
                  src={SsqImages.common.icon.pageLinkGray16()} alt=""/></dd>
                <dd onClick={() => window.open(`https://www.ls-electric.com/ko/#`)}><span>LS Electric Korea</span><img
                  src={SsqImages.common.icon.pageLinkGray16()} alt=""/></dd>
              </div>
            }
            </dl>
          </div>
        </div>

        <div className="footer-right">
          {this.props.languageService.getLanguage() == LANG_KO_KR &&
            <div className={"sns_wrap"}>
              <a href={"https://www.youtube.com/channel/UCW-XoDJ4ZT3Hmp86l1tqASw"} target={"_blank"}>
                <img alt={"sns_youtube.svg"} src={SsqImages.footer.sns.youtube()}/></a>
              <a href={"https://www.facebook.com/lselectricofficial"} target={"_blank"}>
                <img alt={"sns_facebook.svg"} src={SsqImages.footer.sns.facebook()}/></a>
              <a href={"https://www.instagram.com/lselectric_official/"} target={"_blank"}>
                <img alt={"sns_instagram.svg"} src={SsqImages.footer.sns.instagram()}/></a>
              <a href={"https://blog.naver.com/ls-electric"} target={"_blank"}>
                <img alt={"sns_blog.svg"} src={SsqImages.footer.sns.blog()}/></a>
              <a href={"https://www.linkedin.com/company/lselectric"} target={"_blank"}>
                <img alt={"sns_linked.svg"} src={SsqImages.footer.sns.linked()}/></a>
              <a href={"https://tv.naver.com/lselectric"} target={"_blank"}>
                <img alt={"sns_navertv.svg"} src={SsqImages.footer.sns.navertv()}/></a>
            </div>
          }
          {[LANG_EN, LANG_EN_US].includes(this.props.languageService.getLanguage()) &&
            <div className={"sns_wrap"}>
              <a href={"https://www.linkedin.com/company/lselectricamerica/mycompany/"} target={"_blank"}>
                <img alt={"sns_linked.svg"} src={SsqImages.footer.sns.linked()}/></a>
              <a href={"https://www.instagram.com/lselectric_global/"} target={"_blank"}>
                <img alt={"sns_instagram.svg"} src={SsqImages.footer.sns.instagram()}/></a>
              <a href={"https://www.facebook.com/lselectricglobal/"} target={"_blank"}>
                <img alt={"sns_facebook.svg"} src={SsqImages.footer.sns.facebook()}/></a>
              <a href={this.props.languageService.getLanguage() == LANG_EN_US ? "https://www.youtube.com/channel/UCJUmR2KTcRGAjaC7kyZ3tZQ"
                : "https://www.youtube.com/channel/UCW-XoDJ4ZT3Hmp86l1tqASw"} target={"_blank"}>
                <img alt={"sns_youtube.svg"} src={SsqImages.footer.sns.youtube()}/></a>
            </div>
          }
          <div className={"terms_wrap"}>
            <p
              className={"terms"}
              onClick={() => {
                this.setState({showInfoPopup: true});
              }}>{this.getTranslatedText("I18N_INFO_POPUP")}</p>
            <p
              className={"terms"}
              onClick={() => {
                this.setState({showTermsDetailPopup: true});
              }}>{this.getTranslatedText("I18N_FOOTER_TERMS")}</p>

            {![LANG_KO_KR, LANG_KO].includes(this.props.languageService.getLanguage()) &&
              <p
                className={"terms"}
                onClick={() => {
                  this.props.setDisplayPopupItem({...new DisplayPopupItem(), cookie: true});
                }}>{this.getTranslatedText("I18N_FOOTER_COOKIE_POPUP_POLICY")}</p>}

            <p
              className={"private"}
              onClick={() => {
                this.setState({showTermsDetailPrivatePopup: true});
              }}>{this.getTranslatedText("I18N_FOOTER_PRIVATE_TERMS")}</p>


            {/*{gconf.showPublic && !gconf.ssqrelease1st ? <p onClick={() => {*/}
            {/*  location.href = "opensource";*/}
            {/*}}>Open Source</p> : null}*/}

          </div>
          <React.Suspense fallback={<div></div>}>
            <AllPopupWrap>
              {this.state.showTermsDetailPopup && this.renderTermsDetailPopup()}
              {this.state.showInfoPopup && this.renderInfoPopup()}
              {this.state.showTermsDetailPrivatePopup && this.renderTermsDetailPrivatePopup()}
            </AllPopupWrap>
          </React.Suspense>
          {(this.state.showTermsDetailPopup || this.state.showInfoPopup ||
              this.state.showTermsDetailPrivatePopup || this.state.showOriginalVersionTermsDetailPopup) &&
            <AllPopupOverlay
              aria-label={"popup-overlay"}
            />}
        </div>
        <div className={"fixed-ft-btn"}>
          <div className={"chat-bot"} style={{display: 'none'}}>
            <div className={"txt-cont"}>
              챗봇 서비스를 이용해 보세요.
            </div>
            <div className={"img-cont"} onClick={() => {
              this.renderChatbot();
            }}>
              <p className={"hero"}><img alt={"chat_hero.png"} src={SsqImages.footer.chatBotHero()}/></p>
              <p className={"beta"}><img alt={"beta"} src={SsqImages.footer.chatBotBeta()}/></p>
            </div>
          </div>
          <a href={"#"} className={"top_btn"}><img
            alt={"footer_top.svg"} src={SsqImages.footer.top()}/></a>
        </div>
      </div>
      <ToastWrapper/>
    </FooterContainer>;
  }

  private renderTermsDetailPopup() {
    return <TermsDetailPopup
      languageService={this.props.languageService}
      showTermsDetailPopupFunc={this.showTermsDetailPopupFunc}
    />;
  }

  private renderInfoPopup() {
    return <InfoPopup
      languageService={this.props.languageService}
      showInfoPopupFunc={this.showInfoPopupFunc}
    />;
  }

  private renderTermsDetailPrivatePopup() {
    return <TermsDetailPrivatePopup
      languageService={this.props.languageService}
      showTermsDetailPrivateEssentialPopupFunc={this.showTermsDetailPrivatePopupFunc}
      termsType={TermsType.ALL}
    />;
  }

  private renderChatbot() {
    var wt = screen.width,
      ht = screen.height,
      left = Math.floor((wt - 360) / 2),
      top = Math.floor((ht - 651) / 2);
    window.open(`https://gpters-web-app-dev.azurewebsites.net/`, "_blank", "width=" + 360 + ",height=" + 651 + ",left=" + left + ",top=" + top + ",history=no,resizable=no,status=no,scrollbars=yes,menubar=no");
  }
}

export default connect(
  (state: any) => ({
    loginInfo: state.main.loginInfo
  }),
  dispatch => ({
    setDisplayPopupItem: (b: IfDisplayPopupItem) => dispatch({type: ACTION_TYPES.MAIN.DISPLAY_POPUP_ITEM, payload: b}),
  })
)(Footer);

const FooterContainer = styled.div`
  background: #41444A;
  width: 100%;
  padding: 4rem 0;
  position: relative;
  color: #CDD6E3;

  a {
    color: #CDD6E3;
  }

  .container {
    width: 120rem;
    display: flex;
    justify-content: space-between;
  }

  .footer-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .footer-logo {
      display: block;
    }

    p {
      font-size: 1rem;
    }

    .mail {
      font-size: 1.2rem;
    }

    span {
      margin-top: 1.2rem;
      display: block;
      font-size: .9rem;
    }
  }

  .footer-center {
    font-size: 1.2rem;

    > div {
    }

    dl {
      width: 26rem;
      display: inline-block;

      dt {
        font-size: 1.4rem;
        border-bottom: solid 1px #CDD6E3;
        padding-bottom: .8rem;
        margin-bottom: 1rem;
      }

      dd {
        line-height: 20px;
        width: 50%;
        display: inline-block
      }

      + dl {
        margin-left: 8rem;
      }
    }
  }

  .footer-center-after {
    font-size: 1.2rem;
    cursor: pointer;

    > div {
    }

    .flex-center dd {
      display: flex;
      align-items: center;

      span {
        flex-shrink: 0;
        margin-right: .1rem;
      }
    }

    dl {
      width: 12rem;
      display: inline-block;
      vertical-align: top;

      dt {
        font-size: 1.4rem;
        border-bottom: solid 1px #CDD6E3;
        padding-bottom: .8rem;
        margin-bottom: 1rem;
      }

      dd {
        line-height: 20px;
        width: 100%;
        display: inline-block;
      }

      + dl {
        margin-left: 3.2rem;
      }
    }
  }

  .footer-right {
    .sns_wrap {
      margin-bottom: .8rem;

      a {
        display: inline-block;
      }
    }

    > .terms_wrap {
      float: right;

      > p {
        text-align: right;
        font-size: 1.2rem;
        line-height: 2rem;
        cursor: pointer;
      }

      .terms {
        ${fontFamilyRegular};
        color: #CDD6E3;
      }

      .private {
        color: #fff;
      }
    }
  }

  .fixed-ft-btn {
    position: fixed;
    right: 4rem;
    bottom: 2.8rem;
    z-index: 97;
    text-align: center;

    .chat-bot {
      margin-bottom: 18px;
      position: relative;
      text-align: center;

      .txt-cont {
        font-size: 12px;
        ${fontFamilyMedium};
        color: #fff;
        padding-top: 8px;
        background: url(${SsqImages.footer.chatBotTextBg()}) center center no-repeat;
        background-size: cover;
        width: 168px;
        height: 44px;
        position: absolute;
        right: 0;
        top: -52px;
      }

      .img-cont {
        width: 6.4rem;
        height: 6.4rem;
        position: relative;
        border-radius: 100%;
        border: 1px solid #C7D6EA;
        background: linear-gradient(180deg, #FFFFFF 0%, #EEF2F6 100%);
        padding-bottom: 6px;
        cursor: pointer;

        p.hero {
          overflow: hidden;
          width: 6.4rem;
          height: 6.4rem;

          img {
            width: 45px;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
          }
        }

        p.beta {
          position: absolute;
          bottom: -7px;
          left: 50%;
          transform: translateX(-50%);
          width: 48px;
          height: 14px;
          border-radius: 10px;
          background: #407EDB;
          box-shadow: 0px 4px 8px 0px rgba(64, 126, 219, 0.3);

          img {
            vertical-align: middle;
          }
        }

        &:hover {
          background: linear-gradient(180deg, #DCE8FB 0%, #CFDFF5 100%);
        }
      }
    }

    .top_btn {
      width: 4rem;
      height: 4rem;
      background: linear-gradient(180deg, #FFFFFF 0%, #EEF2F6 100%);
      border-radius: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 4px 8px 0px rgba(64, 126, 219, 0.3);
      border: 1px solid #C7D6EA;
      cursor: pointer;

      &:hover, &:active {
        background: linear-gradient(180deg, #DCE8FB 0%, #CFDFF5 100%);
      }
    }
  }

  @media screen and (max-width: 1199px) {
    padding: 4rem 2.4rem;
    height: 20rem;
    .container {
      width: 100%;
    }

    .footer-left {
      padding-bottom: 1rem;
    }

    .footer-logo {
      line-height: 31px;

      img {
        vertical-align: middle;
      }
    }

    .footer-center {
      display: none;
    }

    .footer-center-after {
      display: none;
    }
  }

  @media screen and (max-width: 764px) {
    padding: 40px 16px;
    height: 30rem;

    .footer-left {
      padding-bottom: 0;
    }

    .footer-logo img {
      width: 120px;
    }

    .footer-right a {
      + a {
        margin-left: -3px;
      }
    }
  }
`;

export const AllPopupWrap = styled.div`
  > div:not(.popup-overlay) {
    background: white;
    z-index: 1002;
    position: fixed;
    width: 36rem;
    height: 64rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    padding: 3.2rem;
    color: #2A2C2F
  }

  .popup_tit {
    font-size: 2.4rem;
    ${fontFamilyBold};
  }

  .popup_noti {
    font-size: 1.4rem;
    ${fontFamilyRegular};
    margin-top: 1rem;
  }

  .close-button {
    position: absolute;
    right: 2rem;
    top: 2rem;
  }

  .Submit-Button {
    color: white;
    border-radius: 100px;
    width: 100%;
    height: 4.8rem;
    line-height: 4.8rem;
    background: #407EDB;
    text-align: center;
    font-size: 1.6rem;
    ${fontFamilyBold};
    cursor: pointer;

    :hover {
      background: #3B71C4;
    }

    :active {
      background: #3463AB;
    }
  }

  .popup_img {
    width: 20rem;
    height: 20rem;
    text-align: center;
    background: #F3F6FB;
    display: block;
    border-radius: 100%;
    margin: 4rem auto;

    img {
      margin-top: calc(50% - 5rem);
    }
  }


  .Login_Popup {
    height: auto;
    min-height: 100%;
    display: flex;
    flex-direction: column;

    .login_content_wrap {
      height: 100%;
      width: 100%;
      flex: 1;
      min-height: 48rem;
    }

    .Login-Input-Wrap {
      margin: 4.8rem 0 0.9rem;
      position: relative;


    }


    p {
      font-size: 1.3rem;
      margin-bottom: .5rem;
      ${fontFamilyRegular};
    }

    .login-input {
      border: 1px solid #C7D6EA;
      border-radius: 0.4rem;
      width: 100%;
      height: 4rem;
      font-size: 1.3rem;
      margin-bottom: 1.5rem;
      padding: 0 1.2rem;

      ::placeholder {
        ${fontFamilyRegular};
        font-size: 1.2rem;
        color: #7783A1 !important;
      }
    }
  }

  .login-movebutton {
    text-align: center;
    margin: 3rem 0 3.4rem;

    button {
      ${fontFamilyMedium};
      color: #407EDB;
      font-size: 1.3rem;

      + button:before {
        content: '';
        display: inline-block;
        width: .1rem;
        height: 1.6rem;
        vertical-align: middle;
        background: #C7D6EA;
        margin: 0 1.6rem;
      }
    }
  }

  .login-SNS-tit {
    color: #7783A1;
    text-align: center;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    position: relative;

    ::before {
      content: "";
      display: inline-block;
      position: absolute;
      height: 0.1rem;
      width: 100%;
      top: 50%;
      left: 0;
      z-index: -1;
      transform: translateY(-50%);
      background: #C7D6EA;
    }

    div {
      margin: 0 auto 3.2rem;
      width: 14rem;
      height: 2rem;
      background: white;
    }
  }

  .login-Tooltip {
    cursor: default;
    flex-shrink: 0;
    position: absolute;
    z-index: 9;
    width: 8.6rem;
    height: 3.8rem;
    bottom: 5.8rem;
    left: 50%;
    transform: translateX(-50%);

    ::before {
      content: "";
      display: inline-block;
      position: absolute;
      z-index: -1;
      width: 0;
      height: 0;
      bottom: 0;
      right: 3.5rem;
      border-top: 1rem solid #666E7A; /* 화살표 */
      border-left: 0.47rem solid transparent;
      border-right: 0.47rem solid transparent;
    }

    div {
      width: 8.6rem;
      height: 3.1rem;
      padding: 0.5rem 0;
      background: #666E7A;
      border-radius: 0.3rem;
      color: #FFF;
      text-align: center;
      font-size: 1.3rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.5;
      letter-spacing: -0.4px;
    }
  }

  .login-SNS {
    text-align: center;

    > div {
      display: flex;
      justify-content: space-between;
      max-width: 20rem;
      margin: 0 auto;

      > div {
        position: relative;
      }
    }

    a + a {
      margin-left: 2.4rem;
    }

    #naverIdLogin_loginButton {
      background: url(${SsqImages.header.sns.naver()});
      width: 56px;
      height: 57px;
      display: block;
    }

    #naverIdLogin_loginButton img {
      display: none
    }
  }

  .ls-login {
    color: #7783A1;
    font-size: 1.3rem;
    float: right;
    margin-top: 5rem !important;
    ${fontFamilyRegular};
    text-align: right;
    position: relative;

    .login-Tooltip {
      bottom: 2.1rem;
      width: 100%;
      display: flex;
      justify-content: end;
    }
  }

  .Login-Input-Wrap {
    position: relative;

    .login-Tooltip {
      top: -1.6rem;
      right: 0rem;
      transform: translateX(6.2rem);
    }

  }
}

.Signup_Popup {
  height: 100%;
  overflow: auto;
  border-radius: 0.4rem;

  > div {
    position: relative;
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    background: #fff;
  }


  .popup_tit {
    button {
      margin-right: .8rem;
      vertical-align: middle;
    }
  }

  .Signup_top {
    .popup_noti {
      position: relative;

      .requ {
        position: absolute;
        right: 0;
        bottom: 0;
        font-size: 1rem;
        color: #7783A1 !important;

        span:first-child {
          color: #E6003E
        }
      }
    }
  }

  .Signup_Input {
    padding-top: 1.6rem;
    margin: 0;
    margin-bottom: 6rem;
    padding-right: 2rem;
    overflow-y: auto;
    overflow-x: hidden;

    .input_row {
      margin-bottom: 1.5rem;
      ${fontFamilyRegular};

      p {
        font-size: 1.3rem;
        margin-bottom: .4rem;

        span + span {
          color: #E6003E;
        }
      }

      input[type="text"], input[type="password"] {
        ${fontFamilyRegular};
        border: 1px solid #C7D6EA;
        border-radius: 0.4rem;
        width: 100%;
        height: 4rem;
        font-size: 1.3rem;
        padding: 0 1.2rem;

        ::placeholder {
          ${fontFamilyRegular};
          font-size: 1.2rem;
          color: #7783A1 !important;
        }

        :disabled {
          background: rgba(199, 214, 234, 0.5);
          border: 1px solid #C7D6EA;
        }
      }

      .select_wrap {
        select {
          width: 100%;
          height: 4rem;
          line-height: 4rem;
          padding: 0 1.2rem;
          background-position: center right 1.2rem;
        }
      }

      .SingUpInvalidAlert {
        color: #E6003E;
        font-size: 1.2rem;
      }


      .input_memtype02 {
        div + div {
          margin-top: 1.5rem
        }
      }
    }

    .input_pw_check {
      input {
        :disabled {
          background: rgba(199, 214, 234, 0.5);
          border: 1px solid #C7D6EA;
        }

        :enabled {
          background: #fff !important;
        }
      }
    }

    .input_phone {
      > div {
        display: flex;
      }

      .select_wrap {
        min-width: 8.4rem;
        margin-right: .8rem;
        display: inline-block;

        select {
          height: 4rem;
          line-height: 4rem;
        }
      }
    }

    .sub_check {
      padding-top: 1.5rem;
      padding-left: 1.5rem;
      border-top: 1px solid #C7D6EA;
    }

    .all_check {
      padding-bottom: 1rem;
    }

    .input_check {
      margin: .5rem 0 0;

      > div:first-child button {
        margin-left: 2.4rem;
        margin-top: .4rem;
      }

      input[type="checkbox"] {
        display: none;
      }

      input[type="checkbox"] + label {
        display: inline-block;
        width: 1.6rem;
        height: 1.6rem;
        border: 1px solid #C7D6EA;
        border-radius: 2px;
        cursor: pointer;
        vertical-align: middle;
      }

      input[type="checkbox"]:checked + label {
        background: #407EDB url(${SsqImages.common.icon.CheckboxCheck()}) center center no-repeat;
        border: 1px solid #407EDB;
      }

      input[type="checkbox"]:checked:disabled + label {
        background: #C7D6EA url(${SsqImages.common.icon.CheckboxCheck()}) center center no-repeat;
        border: 1px solid #C7D6EA;
      }

      .check_txt {
        font-size: 1.3rem;
        ${fontFamilyRegular};
        margin-left: .8rem;
      }

      .check_txt_all {
        font-size: 1.5rem;
        ${fontFamilyMedium};
        margin-left: .8rem;
      }

      button {
        ${fontFamilyRegular};
        font-size: 1.3rem;
        margin-left: .8rem;
        color: #407EDB;
        border-bottom: solid 1px #407EDB;
      }

      div + div {
        margin-top: 1.5rem;
      }

      .Mailing_Info {
        margin-top: .5rem;
        text-align: center;
        font-size: 1rem;
        color: #7783A1;

      }
    }
  }
}

.TermsDetail_Wrap {
  z-index: 1003 !important;

  .close-button {
    display: none
  }

  .popup_tit {
    button {
      margin-right: .8rem;
      vertical-align: middle;
    }
  }
}

.Login_Icon_Popup {
  .popup_tit {
    button {
      margin-right: .8rem;
      vertical-align: middle;
    }
  }
}

.Send_Email_Popup {
  .popup_tit {
    button {
      margin-right: .8rem;
      vertical-align: middle;
    }
  }

  .email_fail_info {
    font-size: 1.4rem;
    margin-top: 4rem;

    > div:nth-child(2) {
      font-size: 1.3rem;
      color: #7783A1;
      margin: .8rem 0;
      ${fontFamilyRegular};
    }

    > button {
      color: #407EDB;
      ${fontFamilyBold};
    }
  }
}

.Password_Reset_Popup {
  .popup_tit {
    button {
      margin-right: .8rem;
      vertical-align: middle;
    }
  }

  .EmailInvalidAlert {
    color: #E6003E;
    font-size: 1.2rem;
  }

  .alert-area {
    height: 2.4rem;
  }

  .email_fail_info {
    font-size: 1.4rem;
    margin-top: 4rem;

    > div:nth-child(2) {
      font-size: 1.3rem;
      color: #7783A1;
      margin: .8rem 0;
      ${fontFamilyRegular};
    }

    > button {
      color: #407EDB;
      ${fontFamilyBold};
    }
  }

  p {
    font-size: 1.3rem;
    margin-bottom: .4rem;
  }

  input[type="text"], input[type="password"] {
    ${fontFamilyRegular};
    border: 1px solid #C7D6EA;
    border-radius: 0.4rem;
    width: 100%;
    height: 4rem;
    font-size: 1.3rem;
    padding: 0 1.2rem;

    ::placeholder {
      ${fontFamilyRegular};
      font-size: 1.2rem;
      color: #7783A1 !important;
    }
  }

}


@media screen and (max-width: 884px) {
  .Signup_Input {

    overflow-y: auto;
  }

  .Login_Popup {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .login_content_wrap {
    min-height: 5rem;
  }

  input[type="password"] {
    font-size: 2rem !important;
  }


  .login-input {
    /* 16/13 = 123% */
    border-radius: 4.92px !important;
    width: 123% !important;
    height: 44.5px !important;
    font-size: 16px !important;
    margin-bottom: 10px !important;
    padding: 0 14.76px !important;

    /* scale by 13/16 = 81% */
    transform: scale(0.81);
    transform-origin: left top;

    ::placeholder {
      font-size: 14px !important;
    }
  }

  .login-Tooltip {
    bottom: 6.6rem;
  }

  .ls-login {
    margin-top: 1.5rem;
    display: flex;
    justify-content: end;
    position: relative;

    .login-Tooltip {
      width: 100%;
      display: flex;
      justify-content: end;
      bottom: 3.1rem;
    }
  }

  .login-movebutton {
    margin: 3rem 0 !important;
  }

  .Signup_Input {
    .input_row {
      margin-bottom: 1rem !important;
    }

    input[type="text"], input[type="password"], .select_wrap select {
      border-radius: 4.92px !important;
      width: 123% !important;
      height: 44.5px !important;
      font-size: 16px !important;
      padding: 0 14.76px !important;
      margin-right: -33.333333333%;

      transform: scale(0.81);
      transform-origin: left top;

      ::placeholder {
        font-size: 14px !important;
      }
    }

    .input_phone input[type="text"] {
      width: calc(123% - 12.5rem) !important;
    }

    .select_wrap select option {
      font-size: 13px;
    }
  }

}

@media screen and (max-width: 763px) {
  > div {
    width: 100% !important;
    height: 100% !important;
    border-radius: 0 !important;
  }

  .Signup_Popup {
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

}

@media screen and (max-width: 360px), (max-width: 360px) and (max-height: 640px) {
  > div {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .Signup_Popup {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .popup_tit, .popup_noti, .input_row * {
    word-break: keep-all
  }

  .popup_img {
    max-width: 100%;
  }

  .Login_Popup {
    width: 100%;
    height: 100%;
    min-height: 7rem;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .login-SNS {
      margin-bottom: 2rem
    }
  }

  .Login-Input-Wrap {
    position: relative !important;

    .login-Tooltip {
      top: -1.6rem;
      right: 0;
      bottom: 6.6rem !important;
      transform: translateX(4.5rem);

      div {
        width: auto;
      }
    }
  }

  .Login-Input-Wrap {
    margin: 1rem 0 0 !important;
  }

  .login-movebutton {
    margin: 1.6rem 0 !important;
  }

  .Signup_Popup {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .TermsDetail_Popup, .Password_Reset_Popup, .Send_Email_Popup, .Login_Icon_Popup, .Password_Reset_Popup {
    width: 36rem;
    height: 63rem;
    position: absolute;
    padding: 4rem;
  }

  .Password_Reset_Popup {
    height: 100%;
    width: 100%;
  }

  .Next-default-info {
    position: relative;
  }

}

@media screen and (max-height: 770px) {
  > div {
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

@media screen and (max-height: 770px) and (max-width: 361px) {
  > div {
    top: 5px !important;
    transform: translate(-50%, 0);
    height: 100%;
    width: 100%;
    overflow-y: auto;
    padding: 0rem;
    overflow-x: hidden;

    &:has(.Login_Popup,.Signup_Popup,.TermsDetail_Popup, .Password_Reset_Popup, .Send_Email_Popup, .Login_Icon_Popup, .Password_Reset_Popup) {
      top: 50% !important;
      padding: 0 !important;
    }
  }

  .Login_Popup, .TermsDetail_Popup, .Password_Reset_Popup, .Send_Email_Popup, .Login_Icon_Popup, .Password_Reset_Popup {
    height: 63rem;
    width: 36rem;
    min-height: auto !important;
    position: absolute;
    padding: 4rem 3rem 4rem 3rem;
  }

  .Login_Popup {
    width: 100%;
    min-height: 70rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .Signup_Popup {
      position: absolute;
      height: 63rem;
      width: 36rem;

      .Signup_Input {
        overflow-y: auto;
      }

    }

    .Login_Popup, .Password_Reset_Popup, .LoginFail_Popup, .Signup_Popup {
      height: 100%;
      width: 100%;
    }
  }
}

@media screen and (max-width: 360px) and (max-width: 280px) {

  .Signup_Input {
    flex: 0 0 44rem !important;
    height: 44rem;
  }

  .ls-login {
    margin-top: 3rem;
    position: relative;
    right: 0;
    bottom: 0;
    padding-bottom: 1rem;

    .login-Tooltip {
      bottom: 3.1rem;
    }
  }
}
`;