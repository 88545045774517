import React, {Component} from "react";
import {SsqImages} from "../images/SsqImages";
import {getMemberTypeLangKey, MemberType} from "../definitions/MemberType";
import {getInterestFieldTypeLangKey, InterestFieldType} from "../definitions/InterestFieldType";
import {LanguageService} from "../language/LanguageService";
import {AccountService, emptySignupValues, ICountryCode, ISignupValues} from "../repositories/AccountService";
import {IVerifySignupEmailRequest, NotificationService} from "../repositories/NotificationService";
import {InputWrapForSelect} from "../style/CommonStyledComponents";
import {SubmitTermsPopup} from "./SubmitTermsPopup";
import {TermsType} from "../definitions/TermsType";
import svcs from "services";
import {LANG_EN, LANG_EN_US, LANG_KO_KR} from "language/langutil";
import styled from "styled-components";
import {fontFamilyRegular} from "style/fonts";
import axios from "axios";

interface Props {
  languageService: LanguageService;
  accountService: AccountService;
  notificationService: NotificationService;
  showSignupPopupFunc: Function;
  closeAllPopupFunc: Function;
  showTermsDetailPopupFunc: Function;
  showTermsDetailPrivatePopupFunc: Function;
  setSignupEmailFunc: Function;
  showSendVerifyEmailPopupFunc: Function;
}


interface State {
  showUserPopup: boolean;
  showLoginPopup: boolean;
  showSignupPopup: boolean;
  isExistEmail: boolean;
  isInValidEmail: boolean;
  isEmployeeEmail: boolean;
  isNoInputPassword: boolean;
  isValidPassword: boolean;
  isCheckedPassword: boolean;
  isExistNickname: boolean;
  isValidNickname: boolean;
  isValidFirstname: boolean;
  isValidLastname: boolean;
  isValidCompany: boolean;
  countryCodes: ICountryCode[];
  isClickedSignupButton: boolean;
  signupValues: ISignupValues;
  scrollPosition: string;
  countryNumber: string[];
  acceptAll: boolean;
  signupPage: number;
  comparySchool: 'company' | 'school';
}

export class SignupPopup extends Component<Props, State> {
  homeRef: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);

    this.state = {
      showUserPopup: false,
      showLoginPopup: false,
      showSignupPopup: false,
      isExistEmail: false,
      isInValidEmail: false,
      isEmployeeEmail: false,
      isNoInputPassword: true,
      isValidPassword: true,
      isCheckedPassword: true,
      isExistNickname: false,
      isValidNickname: true,
      isValidFirstname: true,
      isValidLastname: true,
      isValidCompany: true,
      countryCodes: [],
      isClickedSignupButton: false,
      signupValues: emptySignupValues,
      scrollPosition: "",
      countryNumber: [],
      acceptAll: false,
      signupPage: 0,
      comparySchool: 'company'
    };
    this.homeRef = React.createRef();
  }

  async componentDidMount() {
    let countryCodes = await this.props.accountService.getAllCountryCodes();
    let countryNumber = [];
    countryCodes.forEach(i => !countryNumber.includes(i.countryCode) && countryNumber.push(i.countryCode));

    let array = countryCodes.filter(country => country.countryCode2 == this.getCountryCode());
    if (array[0] !== null) {
      this.setSignupValues('countryCode', array[0].countryCode);
      this.setSignupValues('countryCode2', array[0].countryCode2);
    }

    countryCodes = countryCodes.slice().sort((a, b) => {
      if (this.props.languageService.getLanguage() == LANG_KO_KR) return (a.countryNameKo || "").localeCompare(b.countryNameKo || "");
      else return (a.countryName || "").localeCompare(b.countryName || "");
    });


    this.setState({
      countryCodes,
      countryNumber
    });

  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (this.state.signupValues.acceptTerms != prevState.signupValues.acceptTerms ||
      this.state.signupValues.acceptPrivateEssential != prevState.signupValues.acceptPrivateEssential ||
      this.state.signupValues.acceptPrivateOptional != prevState.signupValues.acceptPrivateOptional) this.acceptAllByCountry();

    if (this.state.signupValues.memberType != prevState.signupValues.memberType) {
      if (this.state.signupValues.memberType == MemberType.STUDENT) this.setState({comparySchool: 'school'});
      else this.setState({comparySchool: 'company'});
    }
  }

  getTranslation(key: string): string {
    this.props.languageService.setNamespace("I18N_NAMESPACE_HEADER");
    return this.props.languageService.getTranslationByKey(key) || "";
  }

  setSignupPage = (page: number) => {
    this.setState({signupPage: page});
  };

  renderMemberTypeOption(memberType: string) {
    return <option value={memberType}>{this.getTranslation(getMemberTypeLangKey(memberType))}</option>;
  }

  renderInterestFieldTypeOption(InterestFieldType: string) {
    return <option
      value={InterestFieldType}>{this.getTranslation(getInterestFieldTypeLangKey(InterestFieldType))}</option>;
  }

  setSignupValues = (dataKey: string, value: any) => {
    this.setState({
      signupValues: {
        ...this.state.signupValues,
        [dataKey]: value
      }
    });
  };

  renderInputAreaForStudent() {
    return <div className={"input_memtype02"}>
      <p>
        <span>{this.getTranslation("I18N_SIGNUP_SCHOOL")}</span>
        {this.state.scrollPosition == "company" ? <span ref={this.homeRef}> * </span> : <span> * </span>}
      </p>
      <div>
        <input type={"text"} aria-label={"signup-input-school"}
          value={this.state.signupValues.school}
          style={(!this.state.isValidCompany || (this.state.isClickedSignupButton && this.state.signupValues.school === ""))
            ? {border: 'solid 1px #E6003E'} : {}}
          onChange={(e) =>
            this.setSignupValues('school', e.target.value)}
          onBlur={() => {
            if (this.state.signupValues.school !== "") {
              this.setState({isValidCompany: true});
            } else {
              this.setState({isValidCompany: false});
            }
          }}
          maxLength={20} placeholder={this.getTranslation("I18N_SIGNUP_SCHOOL_PLACEHOLDER")}
        />
        {(this.state.isClickedSignupButton && this.state.signupValues.school === "") &&
          <div className={"SingUpInvalidAlert"}>{this.getTranslation("I18N_SIGNUP_REQUIRED_ALERT")}</div>}
      </div>

      <div>
        <p>{this.getTranslation("I18N_SIGNUP_MAJOR_GRADE")}</p>
        <input type={"text"} aria-label={"signup-input-majorAndGrade"}
          value={this.state.signupValues.majorAndGrade}
          onChange={(e) =>
            this.setSignupValues('majorAndGrade', e.target.value)}
          maxLength={20}
          placeholder={this.getTranslation("I18N_SIGNUP_MAJOR_GRADE_PLACEHOLDER")}
        />
      </div>
    </div>;
  }

  setScrollPosition() {
    let defaultPosition = "header";
    //이메일 스크롤
    if (this.state.isExistEmail || this.state.isEmployeeEmail || this.state.isInValidEmail) defaultPosition = "email";
    //비밀번호 스크롤
    else if (!this.state.isValidPassword) defaultPosition = "password";
    //비밀번호 확인 스크롤
    else if (!this.state.isCheckedPassword) defaultPosition = "passwordCheck";
    //이름 스크롤
    else if (!this.state.isValidFirstname) defaultPosition = "firstName";
    //성 스크롤
    else if (!this.state.isValidLastname) defaultPosition = "lastName";
    //국가 스크롤
    else if (this.state.isClickedSignupButton && ["", null].includes(this.state.signupValues.countryCode2)) defaultPosition = "nationality";
    //닉네임 스크롤
    else if (this.state.isExistNickname || !this.state.isValidNickname) defaultPosition = "nickname";
    //관심산업 스크롤
    else if (this.state.isClickedSignupButton && this.state.signupValues.interestFieldType === InterestFieldType.SELECT) defaultPosition = "interestFieldType";
    //업종 스크롤
    else if (this.state.isClickedSignupButton && this.state.signupValues.memberType === MemberType.SELECT) defaultPosition = "memberType";
    //회사 스크롤
    else if (!this.state.isValidCompany || (this.state.isClickedSignupButton && this.state.signupValues.company === "")) defaultPosition = "company";
    //체크 스크롤
    else if (this.state.isClickedSignupButton && this.state.signupValues.acceptTerms === false) defaultPosition = "checkBox";

    else if (this.state.isClickedSignupButton && this.state.signupValues.acceptPrivateEssential == false) defaultPosition = "checkBox";

    return defaultPosition;
  }

  scrollOnClick() {
    this.homeRef.current?.scrollIntoView({behavior: 'smooth'});
  }

  renderInputAreaForNotStudent() {
    return <div className={"input_memtype02"}>
      <p>
        <span>{this.getTranslation("I18N_SIGNUP_COMPANY")}</span>
        {this.state.scrollPosition == "company" ? <span ref={this.homeRef}> * </span> : <span> * </span>}
      </p>
      <div>
        <input type={"text"} aria-label={"signup-input-company"}
          value={this.state.signupValues.company}
          style={(!this.state.isValidCompany || (this.state.isClickedSignupButton && this.state.signupValues.company === ""))
            ? {border: 'solid 1px #E6003E'} : {}}
          onChange={(e) =>
            this.setSignupValues('company', e.target.value.trim())}
          onBlur={() => {
            if (this.state.signupValues.company !== "") {
              this.setState({isValidCompany: true});
            } else {
              this.setState({isValidCompany: false});
            }
          }}
          maxLength={20} placeholder={this.getTranslation("I18N_SIGNUP_COMPANY_PLACEHOLDER")}
        />
        {(this.state.isClickedSignupButton && this.state.signupValues.company === "") &&
          <div className={"SingUpInvalidAlert"}>{this.getTranslation("I18N_SIGNUP_REQUIRED_ALERT")}</div>}
      </div>

      <div>
        <p>{this.getTranslation("I18N_SIGNUP_DEPARTMENT_TITLE")}</p>
        <input type={"text"} aria-label={"signup-input-departmentAndTitle"}
          value={this.state.signupValues.departmentAndTitle}
          onChange={(e) =>
            this.setSignupValues('departmentAndTitle', e.target.value)}
          maxLength={20}
          placeholder={this.getTranslation("I18N_SIGNUP_DEPARTMENT_TITLE_PLACEHOLDER")}
        />
      </div>
    </div>;
  }

  closeSignupPopup = () => {
    this.props.showSignupPopupFunc(false);

    this.setState({
      signupValues: {
        ...this.state.signupValues,
        acceptTerms: false,
        acceptPrivateEssential: false,
        acceptMail: false
      }
    });
  };

  closeAllPopup = () => {
    this.props.closeAllPopupFunc("", false, false, false, false, false);
  };

  showTermsDetailPopup = () => {
    this.props.showTermsDetailPopupFunc(true);
  };

  showTermsDetailPrivateEssentialPopup() {
    this.props.showTermsDetailPrivatePopupFunc(true, TermsType.ESSENTIAL);
  }

  showTermsDetailPrivateOptionalPopup() {
    this.props.showTermsDetailPrivatePopupFunc(true, TermsType.OPTIONAL);
  }

  setSignupEmailFunc() {
    this.props.setSignupEmailFunc(this.state.signupValues.email);
  }

  showSendVerifyEmailPopup() {
    this.props.showSendVerifyEmailPopupFunc(true);
  }

  isInValidEmail(email: string): boolean {
    const reg = /^\w+(?:[\.-]\w+)*@\w+(?:[\.-]\w+)*(?:\.\w{2,5})+$/;
    return !reg.test(email);
  }

  isEmployeeEmail(email: string): boolean {
    const reg = /@(ls-electric\.com|lselectricamerica\.com|lselectric\.co\.kr|lsis\.com)$/;
    return reg.test(email);
  }


  async isExistsEmail(email: string): Promise<boolean> {
    return await this.props.accountService.isExistAccountId(email);
  }

  async verifyEmail(email: string) {
    let isInValidEmail = false;
    let isExistEmail = false;
    let isEmployeeEmail = false;


    if (this.isInValidEmail(email)) {
      isInValidEmail = true;
    } else {
      if (this.isEmployeeEmail(email)) {
        isEmployeeEmail = true;
      } else {
        if (await this.isExistsEmail(email)) {
          isExistEmail = true;
        }
      }
    }

    this.setState({
      isInValidEmail,
      isExistEmail,
      isEmployeeEmail
    });
  }

  isValidPassword(password: string): boolean {
    const reg = /^(?=.*\d)(?=.*[a-zA-Z])[0-9a-zA-Z!@#$%^&*()]{10,16}$/;
    return reg.test(password);
  }

  verifyPassword(password: string) {
    let isValidPassword = false;

    if (this.isValidPassword(password)) {
      isValidPassword = true;
    }

    this.setState({isValidPassword});
  }

  verifyPasswordCheck(password: string, passwordCheck: string) {
    let isCheckedPassword = false;

    if (password === passwordCheck) {
      isCheckedPassword = true;
    }

    this.setState({isCheckedPassword});
  }

  async isExistsNickname(nickname: string): Promise<boolean> {
    let check = false;
    await axios.get('/api/guest/isExistNickname', {
      params: {nickname: nickname}
    }).then((res) => {
      check = res.data;
    });

    return check;
  }

  async verifyNickname(nickname: string) {
    let isExistNickname = false;
    let isValidNickname = true;

    if (nickname === "" || nickname.length > 11) {
      isValidNickname = false;
    }

    if (await this.isExistsNickname(nickname)) {
      isExistNickname = true;
    }

    this.setState({
      isExistNickname,
      isValidNickname
    });
  }


  acceptAllByCountry = () => {
    if (this.props.languageService.getLanguage() == LANG_KO_KR) {
      if (this.state.signupValues.acceptTerms && this.state.signupValues.acceptPrivateEssential &&
        this.state.signupValues.acceptPrivateOptional) return this.setState({acceptAll: true});
    } else {
      if (this.state.signupValues.acceptTerms && this.state.signupValues.acceptPrivateEssential) return this.setState({acceptAll: true});
    }
    return this.setState({acceptAll: false});
  };

  acceptAllProgressByCountry = () => {
    if (this.props.languageService.getLanguage() == LANG_KO_KR) {
      this.setState({
        signupValues: {
          ...this.state.signupValues,
          acceptTerms: !this.state.acceptAll,
          acceptPrivateEssential: !this.state.acceptAll,
          acceptPrivateOptional: !this.state.acceptAll,
        }
      });
    } else {
      this.setState({
        signupValues: {
          ...this.state.signupValues,
          acceptTerms: !this.state.acceptAll,
          acceptPrivateEssential: !this.state.acceptAll,
        }
      });
    }
    this.setState({acceptAll: !this.state.acceptAll});
  };

  async verfyFirstname(firstName: string) {
    let isValidFirstname = true;


    if (firstName === "" || firstName.length > 21) {
      isValidFirstname = false;
    }

    this.setState({
      isValidFirstname,
    });
  }

  async verfyLastname(lastName: string) {
    let isValidLastname = true;

    if (lastName === "" || lastName.length > 21) {
      isValidLastname = false;
    }

    this.setState({
      isValidLastname
    });
  }

  getCountryCode() {
    const languageToCountry = {
      cs: "CZ", da: "DK", el: "GR", en: "US", et: "EE", ga: "IE", hi: "IN", he: "IL", ja: "JP",
      ko: "KR", sv: "SE", sl: "SI", zh: "CN", ar: "AE", fa: "IR", be: "BY", uk: "UA", kk: "KZ"
    };


    const language = window.navigator.language || "";
    if (language !== "") {// 브라우저 접속국가코드
      if (language.includes('-')) return language.slice(-2).toUpperCase();
      else {
        if (Object.keys(languageToCountry).includes(language)) {
          return languageToCountry[language];
        } else return language.toUpperCase();
      }
    }

    if (this.props.languageService.getLanguage() == LANG_KO_KR)
      return 'KR';
    else if ([LANG_EN, LANG_EN_US].includes(this.props.languageService.getLanguage()))
      return 'US';

    return "";
  }

  renderSignupInputPopUp() {
    return <div>
      <button className={"close-button"} onClick={() => {
        if (window.confirm(this.getTranslation("I18N_SIGNUP_LEAVE_POPUP_ALERT"))) {
          this.closeAllPopup();
        }
      }}
      >
        <img alt={"close_small_icon.svg"} src={SsqImages.common.icon.closeSmallIcon()}/>
      </button>
      <SignupWrap>
        <div className={"Signup_top"}>
          <div className={"popup_tit"}>
            <button onClick={() => this.setSignupPage(0)}>
              <img alt={"back_button.svg"} src={SsqImages.header.icon.backButton()}/>
            </button>
            {this.getTranslation("I18N_TITLE_JOIN")}
          </div>
          <div className={"popup_noti"}>
            {this.getTranslation("I18N_DUPLICATE_INPUT_INFORMATION")}
            <div className={"requ"}>
              <span> * </span>
              <span>{this.getTranslation("I18N_SIGNUP_MANDATORY_INPUT")}</span>
            </div>
          </div>
        </div>

        <div className={"Signup_Input"}>
          <div className={"Signup-default-info"}>
            <div className={"input_row"}>
              <p>
                <span>{this.getTranslation("I18N_SIGNUP_EMAIL")}</span>
                {this.state.scrollPosition == "email" ? <span ref={this.homeRef}> * </span> : <span> * </span>}
              </p>
              <div>
                <input type={"text"} aria-label={"signup-input-email"}
                  style={(this.state.isExistEmail || this.state.isInValidEmail || this.state.isEmployeeEmail) ? {border: 'solid 1px #E6003E'} : {}}
                  onChange={(e) =>
                    this.setSignupValues('email', e.target.value.trim())}
                  onBlur={async () => {
                    if (this.state.signupValues.email !== "") {
                      await this.verifyEmail(this.state.signupValues.email);
                    }
                  }}
                  placeholder={this.getTranslation("I18N_SIGNUP_PLACEHOLDER_INPUT")}
                />
                {this.state.isInValidEmail &&
                  <div
                    className={"SingUpInvalidAlert"}>{this.getTranslation("I18N_SIGNUP_EMAIL_INVALID_ALERT")}</div>}
                {this.state.isExistEmail &&
                  <div
                    className={"SingUpInvalidAlert"}>{this.getTranslation("I18N_SIGNUP_EMAIL_DUPLICATE_ALERT")}</div>}
                {this.state.isEmployeeEmail &&
                  <div
                    className={"SingUpInvalidAlert"}>{this.getTranslation("I18N_SIGNUP_EMAIL_EMPLOYEE_ALERT")}</div>
                }
              </div>
            </div>
            <div className={"input_row"}>

              <p>
                <span>{this.getTranslation("I18N_TITLE_PASSWORD")}</span>
                {this.state.scrollPosition == "password" ? <span ref={this.homeRef}> * </span> : <span> * </span>}
              </p>
              <div>
                <input
                  type={"password"} aria-label={"signup-input-password"}
                  style={(!this.state.isValidPassword) ? {border: 'solid 1px #E6003E', fontSize: '2rem'} : {}}
                  maxLength={17}
                  value={this.state.signupValues.password}
                  onChange={(e) => {
                    this.setSignupValues('password', e.target.value);

                    this.verifyPassword(e.target.value);
                    if (this.state.isValidPassword && this.state.signupValues.passwordCheck !== "") {
                      this.verifyPasswordCheck(e.target.value, this.state.signupValues.passwordCheck);
                    }
                    this.setState({isNoInputPassword: false});
                  }}
                  placeholder={this.getTranslation("I18N_SIGNUP_INVALID_PASSWORD_PLACEHOLDER")}
                />
                {!this.state.isValidPassword &&
                  <div
                    className={"SingUpInvalidAlert"}>{this.getTranslation("I18N_SIGNUP_INVALID_PASSWORD_ALERT")}</div>}
              </div>
            </div>
            <div className={"input_row input_pw_check"}>
              <p>
                <span>{this.getTranslation("I18N_TITLE_PASSWORD_CHECK")}</span>
                {this.state.scrollPosition == "passwordCheck" ? <span ref={this.homeRef}> * </span> : <span> * </span>}
              </p>
              <div>
                <input
                  disabled={!this.state.isValidPassword || this.state.isNoInputPassword}
                  type={"password"} aria-label={"signup-input-password-check"}
                  style={(this.state.isValidPassword && !this.state.isCheckedPassword) ? {border: 'solid 1px #E6003E'} : {}}
                  maxLength={17}
                  value={this.state.signupValues.passwordCheck}
                  onChange={(e) =>
                    this.setSignupValues('passwordCheck', e.target.value)}
                  onBlur={() => this.verifyPasswordCheck(
                    this.state.signupValues.password, this.state.signupValues.passwordCheck)}
                />
                {!this.state.isCheckedPassword && this.state.isValidPassword &&
                  <div
                    className={"SingUpInvalidAlert"}>{this.getTranslation("I18N_SIGNUP_PASSWORD_CHECK_ALERT")}</div>}
              </div>
            </div>

            <div className={"input_row"}>
              <p>
                <span>{this.getTranslation("I18N_SIGNUP_FIRST_NAME")}</span>
                {this.state.scrollPosition == "firstName" ? <span ref={this.homeRef}> * </span> : <span> * </span>}
              </p>
              <div>
                <input type={"text"} aria-label={"signup-input-name"}
                  maxLength={20}
                  style={(!this.state.isValidFirstname) ? {border: 'solid 1px #E6003E'} : {}}
                  onChange={(e) =>
                    this.setSignupValues('name', e.target.value)}
                  onBlur={async () => this.verfyFirstname(this.state.signupValues.name)}
                  placeholder={this.getTranslation("I18N_SIGNUP_NAME_PLACEHOLDER")}
                />
                {!this.state.isValidFirstname &&
                  <div
                    className={"SingUpInvalidAlert"}>{this.getTranslation("I18N_SIGNUP_NAME_INVALID_ALERT")}</div>}
              </div>
            </div>
            <div className={"input_row"}>
              <p>
                <span>{this.getTranslation("I18N_SIGNUP_LAST_NAME")}</span>
                {this.state.scrollPosition == "lastName" ? <span ref={this.homeRef}> * </span> : <span> * </span>}
              </p>
              <div>
                <input type={"text"} aria-label={"signup-input-name"}
                  maxLength={20}
                  style={(!this.state.isValidLastname) ? {border: 'solid 1px #E6003E'} : {}}
                  onChange={(e) =>
                    this.setSignupValues('familyName', e.target.value)}
                  onBlur={async () => this.verfyLastname(this.state.signupValues.familyName)}
                  placeholder={this.getTranslation("I18N_SIGNUP_NAME_PLACEHOLDER")}
                />
                {!this.state.isValidLastname &&
                  <div
                    className={"SingUpInvalidAlert"}>{this.getTranslation("I18N_SIGNUP_NAME_INVALID_ALERT")}</div>}
              </div>
            </div>
            <div className={"input_row input_memtype"}>
              <p>
                <span>{this.getTranslation("I18N_SIGNUP_COUNTRY")}</span>
                {this.state.scrollPosition == "nationality" ? <span ref={this.homeRef}> * </span> : <span> * </span>}
              </p>
              <div>
                <InputWrapForSelect className={"select_wrap"}>
                  <select aria-label={'select-memberType'}
                    value={this.state.signupValues.countryCode2}
                    onChange={(e) =>
                      this.setSignupValues('countryCode2', e.target.value)}
                    style={
                      (this.state.isClickedSignupButton && ["", null].includes(this.state.signupValues.countryCode2)) ?
                        {border: 'solid 1px #E6003E'} : {}
                    }
                  >
                    {this.state.countryCodes.map((country, index) => {
                      if (![null, ""].includes(country.countryCode2) && ![null, ""].includes(country.countryNameKo)) {
                        return <option key={index}
                          value={country.countryCode2}
                        >{this.props.languageService.getLanguage() == LANG_KO_KR ? country.countryNameKo : country.countryName}</option>;
                      }
                    })}
                  </select>
                  {(this.state.isClickedSignupButton && ["", null].includes(this.state.signupValues.countryCode2)) &&
                    <div className={"SingUpInvalidAlert"}>{this.getTranslation("I18N_SIGNUP_REQUIRED_ALERT")}</div>}
                </InputWrapForSelect>
              </div>
            </div>
            <div className={"input_row input_phone"}>{/*전화번호*/}
              <p>{this.getTranslation("I18N_SIGNUP_PHONE_NUMBER")}</p>
              <div>
                <InputWrapForSelect className={"select_wrap"}>
                  <select aria-label={'select-country-code'}
                    value={this.state.signupValues.countryCode}
                    onChange={(e) =>
                      this.setSignupValues('countryCode', e.target.value)}
                  >
                    {this.state.countryNumber.map((country, index) => {
                      return <option key={index}
                        value={country}>{country}</option>;
                    })}
                  </select>
                </InputWrapForSelect>
                <input type={"text"} aria-label={"signup-input-phoneNumber"}
                  maxLength={15}
                  value={this.state.signupValues.phoneNumber}
                  onChange={(e) =>
                    this.setSignupValues('phoneNumber', e.target.value.replace(/[^\d.]/g, '').replace(/(\..*)\./g, '$1'))}
                  placeholder={this.getTranslation("I18N_SIGNUP_PHONE_NUMBER_PLACEHOLDER")}
                />
              </div>
            </div>
          </div>
          <div className={"Signup-plus-title"}>
            <p>{this.getTranslation("I18N_SIGNUP_BUSINESS")}</p>
          </div>
          <div className={"Signup-plus-info"}>
            <div className={"input_row"}>{/*별명*/}
              <p>
                <span>{this.getTranslation("I18N_SIGNUP_NICKNAME")}</span>
                {this.state.scrollPosition == "nickname" ? <span ref={this.homeRef}> * </span> : <span> * </span>}
              </p>
              <div>
                <input
                  type={"text"} aria-label={"signup-input-nickname"}
                  style={(this.state.isExistNickname || !this.state.isValidNickname) ? {border: 'solid 1px #E6003E'} : {}}
                  maxLength={10}
                  value={this.state.signupValues.nickname}
                  onChange={(e) =>
                    this.setSignupValues('nickname', e.target.value)}
                  onBlur={async (e) => {
                    const nickname = e.target.value.trim();
                    this.setSignupValues('nickname', nickname);
                    this.verifyNickname(nickname);
                  }}
                  placeholder={this.getTranslation("I18N_SIGNUP_NICKNAME_PLACEHOLDER")}
                />
                {this.state.isExistNickname &&
                  <div
                    className={"SingUpInvalidAlert"}>{this.getTranslation("I18N_SIGNUP_NICKNAME_DUPLICATE_ALERT")}</div>}
                {!this.state.isValidNickname &&
                  <div
                    className={"SingUpInvalidAlert"}>{this.getTranslation("I18N_SIGNUP_NICKNAME_INVALID_ALERT")}</div>}
              </div>
            </div>
            <div className={"input_row"}>
              <p>
                <span>{this.getTranslation("I18N_SIGNUP_INTEREST_FIELD")}</span>
                {this.state.scrollPosition == "interestFieldType" ? <span ref={this.homeRef}> * </span> : <span> * </span>}
              </p>
              <div>
                <InputWrapForSelect className={"select_wrap"}>
                  <select aria-label={'select-interestFieldType'}
                    style={
                      (this.state.isClickedSignupButton && this.state.signupValues.interestFieldType === InterestFieldType.SELECT) ?
                        {border: 'solid 1px #E6003E'} : {}
                    }
                    onChange={(e) =>
                      this.setSignupValues('interestFieldType', e.target.value)}
                    value={this.state.signupValues.interestFieldType}
                  >
                    <option value={InterestFieldType.SELECT} hidden={true}>
                      {this.getTranslation(getInterestFieldTypeLangKey(InterestFieldType.SELECT))}
                    </option>
                    {this.renderInterestFieldTypeOption(InterestFieldType.RECHARGEABLE_BATTERY)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.PROCESSING_MACHINE)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.CONSTRUCTION)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.RUBBER)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.AIRPORT_AIR)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.METAL)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.DISPLAY)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.ROBOT)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.MOBILE)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.CARPENTRY)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.SEMICONDUCTOR)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.DEVELOPMENT)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.FIBER)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.WATER_TREATMENT)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.SMART_FACTORY)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.FOOD)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.ELEVATOR)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.GLASS_CEMENT)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.MEDICAL_TREATMENT)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.CAR)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.ELECTRIC)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.RESTRAINT)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.STEEL)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.CONVEYOR)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.PACKING_MACHINE)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.PORT_SHIPBUILDING)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.CHEMISTRY)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.ENVIRONMENT)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.ETC)}
                  </select>
                  {(this.state.isClickedSignupButton && this.state.signupValues.interestFieldType === InterestFieldType.SELECT) &&
                    <div
                      className={"SingUpInvalidAlert"}>{this.getTranslation("I18N_SIGNUP_REQUIRED_ALERT")}</div>}
                </InputWrapForSelect>
              </div>
            </div>
            <div className={"input_row input_memtype"}> {/*구분이 업종으로 변경*/}
              <p>
                <span>{this.getTranslation("I18N_SIGNUP_MEMBER_TYPE")}</span>
                {this.state.scrollPosition == "memberType" ? <span ref={this.homeRef}> * </span> : <span> * </span>}
              </p>
              <div>
                <InputWrapForSelect className={"select_wrap"}>
                  <select aria-label={'select-memberType'}
                    style={
                      (this.state.isClickedSignupButton && this.state.signupValues.memberType === MemberType.SELECT) ?
                        {border: 'solid 1px #E6003E'} : {}
                    }
                    onChange={(e) => {
                      this.setState({
                        signupValues: {
                          ...this.state.signupValues,
                          ['memberType']: e.target.value,
                          ['company']: e.target.value == MemberType.STUDENT ? "" : this.state.signupValues.company || "",
                          ['departmentAndTitle']: e.target.value == MemberType.STUDENT ? "" : this.state.signupValues.departmentAndTitle || "",
                          ['school']: e.target.value != MemberType.STUDENT ? "" : this.state.signupValues.school || "",
                          ['majorAndGrade']: e.target.value != MemberType.STUDENT ? "" : this.state.signupValues.majorAndGrade || ""
                        }
                      });

                    }
                    }
                    value={this.state.signupValues.memberType}
                  >
                    <option value={MemberType.SELECT} hidden={true}>
                      {this.getTranslation(getMemberTypeLangKey(MemberType.SELECT))}
                    </option>
                    {this.renderMemberTypeOption(MemberType.MACHINE_MAKER)}
                    {this.renderMemberTypeOption(MemberType.SMALL_WHOLESALE)}
                    {this.renderMemberTypeOption(MemberType.SET_MAKER)}
                    {this.renderMemberTypeOption(MemberType.ELECTRIC_COMPANY)}
                    {this.renderMemberTypeOption(MemberType.DEALERSHIP)}
                    {this.renderMemberTypeOption(MemberType.PANEL_MAKER)}
                    {this.renderMemberTypeOption(MemberType.STUDENT)}
                    {this.renderMemberTypeOption(MemberType.ETC)}
                  </select>
                  {(this.state.isClickedSignupButton && this.state.signupValues.memberType === MemberType.SELECT) &&
                    <div
                      className={"SingUpInvalidAlert"}>{this.getTranslation("I18N_SIGNUP_REQUIRED_ALERT")}</div>}
                </InputWrapForSelect>
              </div>
            </div>
            <div className={"input_row"}>
              {this.state.signupValues.memberType === MemberType.STUDENT ?
                this.renderInputAreaForStudent() : this.renderInputAreaForNotStudent()}
            </div>
            <div style={{marginBottom: "2rem"}}/>
          </div>
        </div>
        <div className={"Submit-Button-Wrap"}>
          <div className={"Submit-Button"} onClick={async () => {
            this.setState({isClickedSignupButton: true});
            await this.verifyEmail(this.state.signupValues.email);
            this.verifyPassword(this.state.signupValues.password);

            await this.verifyNickname(this.state.signupValues.nickname);
            await this.verfyFirstname(this.state.signupValues.name);
            await this.verfyLastname(this.state.signupValues.familyName);
            await this.verifyPasswordCheck(this.state.signupValues.password, this.state.signupValues.passwordCheck);

            if (this.verifyInputValues()) {
              this.setSignupEmailFunc();
              this.showSendVerifyEmailPopup();
              await this.props.accountService.createAccount(this.state.signupValues);
              let verifySignupEmailRequest: IVerifySignupEmailRequest = {
                email: this.state.signupValues.email,
                lang: svcs.svcLang.getLanguage()
              };
              await this.props.notificationService.postSendVerifySignupEmail(verifySignupEmailRequest);
            } else {
              await this.setState({
                scrollPosition: this.setScrollPosition()
              });
              this.scrollOnClick();
            }
          }}>
            {this.getTranslation("I18N_SIGNUP_BUTTON_SIGNUP")}
          </div>
        </div>
      </SignupWrap>
    </div>;
  }


  showTermsPopup() {
    return <SubmitTermsPopup
      languageService={this.props.languageService}
      closeAllPopupFunc={this.closeAllPopup}
      signupValues={this.state.signupValues}
      setSignupValues={this.setSignupValues}
      pageUp={this.setSignupPage}
      setAcceptAll={this.acceptAllProgressByCountry}
      acceptAll={this.state.acceptAll}
      showTermsDetailPopup={this.showTermsDetailPopup}
      isManageMyInfo={false}
      closeTermsPopup={this.closeSignupPopup}
    />;
  }

  render() {
    return <div style={{"padding": 0}}>
      <div className="Signup_Popup">
        {this.state.signupPage == 0 && this.showTermsPopup()}
        {this.state.signupPage == 1 && this.renderSignupInputPopUp()}
      </div>
    </div>;
  }

  private verifyInputValues() {
    return !this.state.isExistEmail &&
      !this.state.isEmployeeEmail &&
      !this.state.isInValidEmail &&
      this.state.isValidPassword &&
      this.state.isValidFirstname &&
      this.state.isValidLastname &&
      this.state.isCheckedPassword &&
      !this.state.isExistNickname &&
      this.state.isValidNickname &&
      !["", null].includes(this.state.signupValues.countryCode2) &&
      this.state.isValidCompany &&
      (this.state.comparySchool == 'company' && this.state.signupValues.company !== "" || this.state.comparySchool == 'school' && this.state.signupValues.school !== "") &&
      this.state.signupValues.acceptTerms &&
      this.state.signupValues.acceptPrivateEssential &&
      this.state.signupValues.memberType !== MemberType.SELECT &&
      this.state.signupValues.interestFieldType !== InterestFieldType.SELECT;
  }
}

export const SignupWrap = styled.div`
  height: 100%;


  .Signup_top {
    padding: 3rem 3rem 0 3rem;
    width: 100%;
    height: 12rem;
  }

  .Signup_Input {
    width: 100%;
    height: calc(100% - 21rem);
    overflow-y: auto;
    margin-bottom: 6rem;
    position: relative;
  }

  .Submit-Button-Wrap {
    width: 100%;
    background-color: #fff;
    text-align: center;
    position: absolute;
    bottom: 0;
    height: 9rem;
    border-radius: 0 0 4px 4px;
    padding: 0 3rem 0 3rem;

    .Submit-Button {
      position: absolute;
      bottom: 2rem;
      width: calc(100% - 6rem);
      margin: 0;
    }
  }

  .Signup-default-info {
    padding: 0 3rem 1rem 3rem;

    //min-height: 50%;
    @supports (-webkit-touch-callout: none) {
      position: relative;
      //overflow-y: auto;
    }
  }

  .Signup-plus-title {
    border-top: 0.4rem solid #C7D6EA;
    margin-bottom: 1.4rem;

    p {
      padding: 1rem 2rem 0rem 2rem;
      display: block;
      ${fontFamilyRegular};
      font-size: 1.2rem;
      line-height: 1.4rem;
      color: #909FB7;
      margin-left: 0.8rem;
      margin-top: 0.4rem;
    }
  }

  .Signup-plus-info {
    padding: 0 3rem;
    position: absolute;
    bottom: 0;
    width: 100%;

    background-color: #fff;
    border: 1px solid #fff;
  }

  @media screen and (max-width: 763px) {
    .Submit-Button-Wrap {
      padding: 0 3rem 4rem 3rem;
      width: 100%;

      .Submit-Button {
        position: relative;
        bottom: 0;
        width: 100%;
        margin: 0;
      }
    }
  }

  @media screen and (min-width: 361px) and (max-width: 763px) {
    .Signup-plus-info {
      height: auto;
    }

  }


  .Signup_Input {

    .Signup-plus-info {
      position: relative;

    }
  }

  .Signup_Input {
    padding-right: 0 !important;

  }
`;

