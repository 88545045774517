// @flow
import * as React from 'react';

import {ReactComponent as Normal} from 'asset/images/icon/icon_status_normal.svg';
import {ReactComponent as Disconnected} from 'asset/images/icon/icon_status_discon.svg';
import {ReactComponent as Fault} from 'asset/images/icon/icon_status_fault.svg';
import {ReactComponent as Warning} from 'asset/images/icon/icon_status_warning.svg';
import styled from "styled-components";
import clsx from "clsx";


type Props = {
  icon: 'normal' | 'disconnected' | 'fault' | 'warning';
  disabled?: boolean;
  size?: number;
};
export const StatusIcon = ({icon, disabled, size}: Props) => {
  const getIcon = () => {
    if (icon === 'normal') return <Normal/>;
    if (icon === 'disconnected') return <Disconnected/>;
    if (icon === 'fault') return <Fault/>;
    if (icon === 'warning') return <Warning/>;

    return <Normal/>; // default
  };
  return (
    <StatusIconCont className={clsx(disabled ? 'disabled' : '', size ? 'size' + size : '')}>
      {getIcon()}
    </StatusIconCont>
  );
};

const StatusIconCont = styled.p`
  display: inline-block;
  margin-right: 7px;
  vertical-align: middle;

  &.size28, &.size28 svg {
    width: 28px;
    height: 28px;
  }

  &.size20, &.size20 svg {
    width: 20px;
    height: 20px;
  }

  &.size24, &.size24 svg {
    width: 24px;
    height: 24px;
  }

  &.disabled path {
    fill: rgba(141, 141, 141, 0.7);
  }
`;