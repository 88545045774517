// @flow
import * as React from 'react';
import {Modal} from "ctls/modal/Modal";
import styled from "styled-components";
import clsx from "clsx";
import {imgs} from "images/imgs";
import axios from "axios";
import {AskEmailReq} from "models/IfAskEmailReq";
import svcs from "services";
import {i18ntrans} from "language/i18nkey";
import {fontFamilyBold, fontFamilyMedium, fontFamilyRegular} from "style/fonts";


type Props = {};
type State = {
  visible: boolean,
  resolve,
  reject,
  sendMyMailId: boolean,
  msg: string,
  toEmailId: string,
  askUrl: string,
  userNickname: string,
  profilePhotoFileKey: string
  techPoint: number
};

type PopAskShowProp = {
  askUrl: string;
  userId: string;
  userNickname: string;
  profilePhotoFileKey: string;
  techPoint: number;
}

/**
 * 신고하기 팝업.
 */
export class PopAsk extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      visible: false,
      reject: null,
      resolve: null,
      sendMyMailId: true,
      msg: '',
      toEmailId: '',
      askUrl: '',
      userNickname: '',
      profilePhotoFileKey: '',
      techPoint: 0
    };
  }

  show(param: PopAskShowProp) {
    return new Promise((resolve, reject) => {
      this.setState({
        resolve,
        reject,
        visible: true,
        toEmailId: param.userId,
        askUrl: param.askUrl,
        sendMyMailId: true,
        msg: '',
        userNickname: param.userNickname,
        profilePhotoFileKey: param.profilePhotoFileKey,
        techPoint: param.techPoint
      });
    });
  }

  resolve(res) {
    if (res?.cmd == 'sendok') alert(i18ntrans.community('I18N_CMMNTY_ASK_COMPLETE'));
    this.setState({visible: false});
    this.state.resolve(res);
  }

  reject(res) {
    this.setState({visible: false});
    this.state.reject(res);
  }

  handleClick(e, cmd, param?) {
    //console.log("cmd is ", cmd);
    const state = this.state;
    if (e && e.preventDefault) e.preventDefault();
    if (e && e.stopPropagation) e.stopPropagation();
    if (cmd === 'cancel' || cmd == 'close') {
      this.reject({cmd});
    } else if (cmd === 'send-email') {
      // console.log("확인 : ", cmd);
      // this.resolve({cmd: 'confirm'});
      //console.log("sendMyMailId : ", state.sendMyMailId, " msg : ", state.msg);
      let req = new AskEmailReq();
      req.toEmailId = this.state.toEmailId;
      req.sendFromEmailId = this.state.sendMyMailId;
      req.msg = this.state.msg;
      req.lang = svcs.svcLang.getLanguage();
      req.askUrl = this.state.askUrl || "https://sol.ls-electric.com/";
      req.profilePhotoFileKey = this.state.profilePhotoFileKey || '';
      req.techPoint = this.state.techPoint || 0;
      axios.post('/api/guest/sendAskEmail', req)
        .then(res => {
          if (res?.data?.statusCode === "202" || res?.data?.statusCode === 202) {
            console.log("send request ok", res.data);
            this.resolve({cmd: 'sendok'});
          } else {
            //console.error("send request error", res.data);
            this.reject({cmd: 'err', e: res.data});
          }
        }).catch(e => {
        console.error("E", e);
        this.reject({cmd: 'error', e});
      });
    } else if (cmd === 'ok') {
      this.resolve(null);
    } else {
      console.error("unknown cmd ", cmd, " param ", param);
    }
  }


  render() {
    const t = i18ntrans.community;
    const state = this.state;
    return (<>
        {state.visible ? (<Modal onClick={(e) => {
          e.stopPropagation();
        }}>
          <PopCnts className={clsx("")}>
            <div className="popup_base_sm">
              <div>
                <div className="popup_header">
                  <button className="close-button" onClick={(e) => this.handleClick(e, 'close')}>
                    <img alt="close_small_icon.svg" src={imgs.common.close_small_icon}/>
                  </button>
                  <div className="popup_tit">{t('I18N_CMMNTY_ASK')}</div>
                </div>

                <div className="input_item">
                  <div className="input_row content-scroll">
                    <div className="popup-content">
                      <p>{t('I18N_CMMNTY_ASK_DESC', {nickname: this.state.userNickname || '별명'})}
                      </p>
                    </div>
                  </div>
                  <div className={"input_row "}>
                    <textarea className={"textarea"} rows={15} placeholder={t('I18N_CMMNTY_ASK_PLACEHOLDER', {len: 300})} value={this.state.msg || ""}
                      onChange={(e) => this.setState({msg: e.target.value})} maxLength={300}/>
                  </div>

                  <label className={clsx("input_row label")}>
                    <input type="checkbox" checked={this.state.sendMyMailId} onChange={e => {
                      //console.log("e.target", e, e.target.checked, this.state);
                      this.setState({sendMyMailId: e.target.checked});
                    }}/>
                    {t('I18N_CMMNTY_ASK_SEND_WITH_MY_EMAILID')}
                  </label>

                </div>
                <div className="pop-btn-body">
                  <div className="Submit-Button-sm" onClick={(e) => this.handleClick(e, 'send-email')}>{t('I18N_CMMNTY_ASK')}</div>
                </div>
              </div>

            </div>
          </PopCnts>
        </Modal>) : null}
      </>
    );
  };
};


export const PopCnts = styled.div`
  height: 100%;

  .popup_base_sm {
    background: white;
    position: fixed;
    width: 36rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    padding: 0;
    color: #2a2c2f;
    box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.2);
    padding: 3rem;

    > div {
      height: auto;
      min-height: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  .popup_base_sm .popup_header {
    height: 4.8rem;
    line-height: 4.8rem;
    padding: 0 0;
  }

  .close-button {
    position: absolute;
    right: 2rem;
    top: 2rem;
  }

  .popup_tit {
    font-size: 2.4rem;
    ${fontFamilyBold};
  }

  .popup_noti {
    font-size: 1.4rem;
    ${fontFamilyRegular};
    margin-top: 1rem;
  }

  .popup-content {
    min-height: 2rem;
    max-height: 30rem;
    overflow: auto;
    padding-right: 2rem;
  }

  .content-scroll {
    padding-right: 1.2rem !important;
  }

  .popup-content p {
    font-size: 1.4rem;
    ${fontFamilyRegular};
    font-style: normal;
  }

  .input_item {
    /* margin: 1.6rem 0px; */
    height: 100%;
    width: 100%;
    flex: 1;
  }

  .textarea {
    width: 100%;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #C7D6EA;
    border-radius: 4px;
    padding: 1.2rem;
    font-size: 1.3rem;
    line-height: 2.1rem;
    color: #2a2c2f;
    ${fontFamilyMedium};
  }

  .textarea::placeholder {
    color: #7783A1;
    font-size: 1.2rem;
    ${fontFamilyRegular};
  }


  .label > input[type="checkbox"] {
    display: none;
    cursor: pointer;
    position: relative;
    display: inline-block;
    width: auto;
    font-size: 1.3rem;
    background: #fff;
    border: 1px solid #C7D6EA;
    text-align: center;
    border-radius: 100px;
    margin-right: 0.8rem;
    user-select: none;
    top: 2px;
  }

  .label {
    color: #2A2C2F;
    ${fontFamilyMedium};
    font-style: normal;
    font-size: 1.3rem;
    width: 100%
  }


  .input_row {
    display: block;
    margin-bottom: 1.5rem;
    ${fontFamilyRegular};
    font-style: normal;
  }

  .popup_base_sm .input_row {
    padding: 0 0;
  }

  .pop-btn-body {
    margin-top: 3.2rem;
    height: 6rem;
    padding: 1rem 0;
  }

  .Leave-Button-sm {
    border-radius: 4px;
    width: 11.2rem;
    height: 4rem;
    line-height: 4rem;
    margin-right: 1rem;
    background: #fff;
    color: #2A2C2F;
    border: 1px solid #C7D6EA;
    text-align: center;
    font-size: 1.3rem;
    font-size: 2.4rem;
    ${fontFamilyBold};
    font-style: normal;
    cursor: pointer;
  }

  .Submit-Button-sm {
    color: white;
    border-radius: 100px;
    //width: 11.2rem;
    height: 4.8rem;
    line-height: 4.8rem;
    background: #407edb;
    text-align: center;
    font-size: 1.6rem;
    ${fontFamilyBold};
    font-style: normal;
    cursor: pointer;
    margin: auto;
    margin: 0 0;

  }

  .popup_tit,
  .popup_noti,
  .input_row * {
    word-break: keep-all;
  }

  @media screen and (max-height: 640px) {

    .popup_base_sm {
      //transform: translate(-50%, 0px);
      height: auto;
      max-height: 100%;
      overflow-y: auto;
      //top: 5px !important;

    }
  }

  @media screen and (max-width: 764px) {

    .popup_base_sm {
      width: 100%;
      max-height: auto;
      overflow: hidden auto;
      height: 100%;;
    }

    .popup_tit,
    .popup_noti,
    .input_row * {
      word-break: keep-all;
    }
  }
  @media screen and (max-width: 360px) {
    .popup_base_sm {
      height: 100vh;
      min-height: 100vh;
      min-height: -webkit-fill-available;

    }
  }
`;