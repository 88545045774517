import {createContext, useContext} from "react";
import {RES_ASSET} from "language/resi18n_asset";


export type IfAssetContext = {
  asset: (key: string, p?) => string;
  tasset: (key: RES_ASSET, p?) => string;
}

export type IfAssetContextWrap = {
  ctx: IfAssetContext;
  setCtx: (v: IfAssetContext) => void;
}

export const AssetContext = createContext<{ ctx: IfAssetContext, setCtx: (v: IfAssetContext) => void } | undefined>(undefined);


export const useAssetContext = (): IfAssetContextWrap => {
  const {ctx, setCtx} = useContext(AssetContext);
  return {ctx, setCtx};
};