import {VersionArea} from "borispage/admintab/doctab/AdminNewDocumentPopupPage";
import {VersionFileAttachArea} from "borispage/common/AdminCommonComponents";
import {docwriteutil} from "docwrite/community/docwriteutil";
import React from "react";
import styled from "styled-components";
import {IVersion} from "repositories/SsqDataRepository";

interface Props {
  inputVer: any;
  onDeleteFile: (file: any) => void;
  onUploadFile: (files: any) => void;
  setInputVer: (ver: IVersion) => void;
  readonly: boolean;
}


export const AnswerVersionAreaWrap = (props: Props) => {
  return (<>
    <NewVersionArea className={"answer-file"}>
      <VersionArea className={"version_row "}>
        <div className={"not-use-version no-shadow-line-add"}>
          <div className={"file_row"}>
            <VersionFileAttachArea
              files={props.inputVer.files}
              versionIndex={null}
              onDeleteFile={props.onDeleteFile}
              onUploadFile={props.onUploadFile}
              readonly={props.readonly}
              getTranslation={docwriteutil.getadmintrans}
              setFileLang={(lang: string, index: number) => {
                if (props.readonly) return;
                props.inputVer.files[index].fileLang = lang;
                props.setInputVer({...props.inputVer});
              }}
              setFileType={(fileType: string, fileIndex: number) => {
                if (props.readonly) return;
                props.inputVer.files[fileIndex].fileType = fileType;
                props.setInputVer({...props.inputVer});
              }}
              setTargetSoftwareType={(targetSoftwareType: string, fileIndex: number) => {
                if (props.readonly) return;
                props.inputVer.files[fileIndex].targetSoftwareType = targetSoftwareType;
                props.setInputVer({...props.inputVer});
              }}
            />
          </div>
        </div>
      </VersionArea>
    </NewVersionArea>
  </>);
};

const NewVersionArea = styled.div`
  &.answer-file .version_row {
    margin-top: 0;

    .not-use-version {
      padding: 1.6rem 2.4rem !important;
      border-radius: .4rem;

      .file_add_btn {

        img {
          vertical-align: middle;
        }

        label {
          display: flex;
          align-items: center;
          justify-content: center;
          width: auto;
        }
      }

      &.no-shadow-line-add {
        box-shadow: none;
        border-top: .1rem solid #C7D6EA;
        margin-top: 0;
      }
    }
  }
`;