import React, {Component} from "react";
import {connect} from "react-redux";
import {ACTION_TYPES} from "store/ActionTypes";
import {CbCenterPopupItem, IFindPopupRequest} from "borispage/admintab/popuptab/common/PopupTabCommon";
import axios from "axios";
import {BottomPopupItem} from "popup/comp/bottom/BottomPopupItem";
import {LanguageService} from "language/LanguageService";

interface Props {
  setDisplayBottomPopup: (b: boolean) => void;
  languageService: LanguageService;
}

interface State {
  popupList: CbCenterPopupItem[];
  visible: boolean;
}

class BottomPopup extends Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      popupList: [],
      visible: false
    };
  }

  async componentDidMount() {
    const findPopupRequest: IFindPopupRequest = {
      ...new IFindPopupRequest(),
      exposeSiteLanguage: this.props.languageService.getLanguage(),
      type: 'BOTTOM'
    };

    await axios.post('/api/guest/popup/select', findPopupRequest).then(async (res) => {
      if (res.data.length == 0) return this.props.setDisplayBottomPopup(false);
      this.setState({popupList: res.data});
    });

    if (this.state.popupList.length > 0) this.setState({visible: true});
  }

  popupClose() {
    let displayBottomSession;
    try {
      const sessionData = sessionStorage.getItem('DISPLAY_BOTTOM_SESSION');
      displayBottomSession = sessionData ? JSON.parse(sessionData) : [];
    } catch (e) {
      displayBottomSession = [];
    }

    displayBottomSession.push(this.props.languageService.getLanguage());
    sessionStorage.setItem('DISPLAY_BOTTOM_SESSION', JSON.stringify(displayBottomSession));
    this.props.setDisplayBottomPopup(false);
  }

  render() {
    return <React.Fragment>
      {this.state.visible && <BottomPopupItem popupList={this.state.popupList} popupClose={this.popupClose.bind(this)}/>}
    </React.Fragment>;
  }

}

export default connect(
  (state: any) => ({}),
  dispatch => ({
    setDisplayBottomPopup: (b: boolean) => dispatch({type: ACTION_TYPES.MAIN.DISPLAY_BOTTOM_POPUP, payload: b}),
  })
)(BottomPopup);