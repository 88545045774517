import * as React from "react";
import {ModalTrans} from "../modal/ModalTrans";
import svcs from "services";
import {TermsDetailPopup} from "header/TermsDetailPopup";
import {AllPopupOverlay, DefaultPopupWrap} from "style/CommonStyledComponents";


type Props = {};
type State = {
  visible: boolean,
  resolve,
  reject
};

type PopTermsDetailProp = {}

/**
 * 신고하기 팝업.
 */
export class PopTermsDetail extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      visible: false,
      reject: null,
      resolve: null,
    };
  }

  show(param: PopTermsDetailProp) {
    return new Promise((resolve, reject) => {
      this.setState({
        resolve,
        reject,
        visible: true,
      });
    });
  }

  resolve(res) {
    this.setState({visible: false});
    this.state.resolve(res);
  }

  reject(res) {
    this.setState({visible: false});
    this.state.reject(res);
  }

  render() {
    const state = this.state;
    return (<>
        {state.visible ? (<ModalTrans onClick={(e) => e.stopPropagation()}>
          <AllPopupOverlay>
            <DefaultPopupWrap>
              <TermsDetailPopup languageService={svcs.svcLang} showTermsDetailPopupFunc={(e) => this.resolve({})}/>
            </DefaultPopupWrap>
          </AllPopupOverlay>
        </ModalTrans>) : null}
      </>
    );
  };
};
