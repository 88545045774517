import {resi18nutil} from "language/resi18nutil";
import {resi18n_all_en, resi18n_all_enus, resi18n_all_ko} from "language/resi18n_all";

const resi18n_my_page = {
  I18N_MY_PAGE_MAIN_TITLE: ["메인", "Main", undefined],
  I18N_MY_PAGE_TITLE: ["마이페이지", "My Page", undefined],
  I18N_MY_PAGE_DESCRIPTION: ["내 활동 내역을 확인하세요", "View my activity log", undefined],
  I18N_MY_PAGE_ALARM: ["내 알림", "Notification", undefined],
  I18N_MY_PAGE_DOWNLOAD: ["내 다운로드", "Download", undefined],
  I18N_MY_PAGE_SHARE: ["내 공유", "Share", undefined],
  I18N_FILTER_APPROVED: ["승인 완료", "Approved", undefined],
  I18N_FILTER_NOT_APPROVED: ["승인중", "Approval Request", undefined],
  I18N_FILTER_DEL_REQUESTED: ["삭제요청", "Delete Request", undefined],
  I18N_FILTER_REJECT: ["반려", "Reject", undefined],
  I18N_FILTER_REPLIED: ["댓글", "Replied", undefined],
  I18N_FILTER_ANSWERED: ["답변", "Answered", undefined],
  I18N_FILTER_DELETED: ["삭제", "Delete", undefined],
  I18N_TAB_MANUAL: ["사용 설명서", "User Manuals", undefined],
  I18N_TAB_CATALOG: ["카탈로그", "Catalogs", undefined],
  I18N_TAB_SOFTWARE: ["소프트웨어", "Software", undefined],
  I18N_TAB_CERTIFICATION: ["인증/성적서", "Certificates", undefined],
  I18N_TAB_CAD_DATA: ["2D/3D 도면", "Drawings", undefined],
  I18N_TAB_DATASHEET: ["데이터시트", "Datasheets", undefined],
  I18N_TAB_OS: ["OS/펌웨어", "OS/Firmware", undefined],
  I18N_TAB_APPLICATION_NOTE: ["Application Note", "Application Note", undefined],
  I18N_TAB_TECHNICAL_GUIDE: ["Technical Guide", "Technical Guide", undefined],
  I18N_TAB_SAMPLE: ["Sample", "Sample", undefined],
  I18N_TAB_EDUCATION_NOTE: ["교육 자료", "Education Note", undefined],
  I18N_TAB_EDUCATION_VIDEO: ["교육 동영상", "Education Video", undefined],
  I18N_DOC_VIEW_MORE: ["더 보기", "View More", undefined],
  I18N_PROFILE_PAGE_MAIN_TITLE: ["메인", "Main", undefined],
  I18N_PROFILE_PAGE_TITLE: ["프로필", "Profile", undefined],
  I18N_PROFILE_PAGE_INTRO: ["소개", "Profile", undefined],
  I18N_PROFILE_PAGE_TAG: ["관심 태그", "Tags", undefined],
  I18N_PROFILE_PAGE_ADDTAG: ["태그 선택", "Add Tags", undefined],
  I18N_PROFILE_PAGE_EDIT: ["수정하기", "Modify", undefined],
  I18N_PROFILE_PAGE_NICKNAME_ALERT_EXIST: ["이미 존재하는 별명입니다.", "This nickname already exists", undefined],
  I18N_PROFILE_PAGE_NICKNAME_ALERT_INVALID: ["10자 이하 텍스트를 입력해 주세요.", "Input max. 10 characters.", undefined],
  I18N_PROFILE_PAGE_POST: ["작성 글", "Document", undefined],
  I18N_PROFILE_PAGE_ANSWER: ["답변", "Answer", undefined],
  I18N_PROFILE_PAGE_REPLY: ["댓글", "Comment", undefined],
  I18N_PROFILE_PAGE_BOOKMARK: ["북마크", "Bookmark", undefined],
  I18N_PROFILE_PAGE_LIKE: ["좋아요", "Like", undefined],
  I18N_PROFILE_PAGE_BEGINNER: ["입문", "Beginner", undefined],
  I18N_PROFILE_PAGE_ROOKIE: ["초보", "Rookie", undefined],
  I18N_PROFILE_PAGE_MASTER: ["중수", "Master", undefined],
  I18N_PROFILE_PAGE_SUPREME: ["고수", "Supreme", undefined],
  I18N_PROFILE_PAGE_LEGEND: ["전설", "Legend", undefined],
  I18N_PROFILE_PAGE_EXPERT: ["전문가", "Expert", undefined],
  I18N_PROFILE_PAGE_WORKER: ["임직원", "Employees", undefined],
  I18N_PROFILE_PAGE_ALARM: ["알림", "Alarms", undefined],
  I18N_PROFILE_PAGE_MY_ACCOUNT: ["계정관리", "My Account", undefined],
  I18N_PROFILE_PAGE_DOWNLOAD: ["다운로드", "Download", undefined],
  I18N_PROFILE_PAGE_SHARE: ["공유", "Share", undefined],
  I18N_PROFILE_PAGE_ALERT_ACCESS: ["잘못된 접근입니다. 메인 페이지로 이동합니다.", "This page can’t be found. The main page will be shown shortly.", undefined],
  I18N_PROFILE_PAGE_ALERT_REQUIRE_LOGIN: ["회원가입 또는 로그인해 주세요.", "Sign up today or Login", undefined],
  I18N_PROFILE_PAGE_ALERT_DELETE: ["삭제된 문서의 파일 입니다.", "This is the file of the deleted documents.", undefined],
  I18N_PROFILE_PAGE_ALERT_DELETE_DOC: ["삭제된 문서입니다.", "This is a deleted document.", undefined],
  I18N_PROFILE_PAGE_ALERT_DELETE_WRONG: ["삭제된 문서 또는 잘못된 문서입니다.", "This is a deleted or invalid document ", undefined],
  I18N_PROFILE_PAGE_ALERT_EDIT_DELETE: ["해당 문서가 수정되었거나, 파일이 삭제되었습니다. 문서 변경내역을 확인하시기 바랍니다.", "The document has been modified or the file has been deleted. Please check the document change history.", undefined],
  I18N_PROFILE_PAGE_ALERT_DOWNLOAD: ["버전 다운로드 기능이 제공되지 않습니다. 해당 문서로 이동하시어 개별 파일을 다운로드 받으시기 바랍니다.", "Version download is not available. Please go to the appropriate document to download the individual file.", undefined],
  I18N_PROFILE_PAGE_ALERT_BROWSER: ["복사 기능이 지원되지 않는 브라우저입니다.", "This browser does not support the copy and paste function.", undefined],
  I18N_PROFILE_PAGE_ALERT_SHARE: ["현재 웹페이지의 주소(URL)가 클립보드에 복사되었습니다.", "The address (URL) of the current webpage has been copied to the clipboard.", undefined],
  I18N_PROFILE_PAGE_NOT_DOWNLOAD: ["선택한 문서의 버전에는 다운로드할 파일이 존재하지 않습니다.", "The selected version of the document does not have a downloadable file.", undefined],
  I18N_PROFILE_PAGE_ALERT_TAG_OVER: ["10개이하의 태그를 입력해 주세요.", "Please enter no more than 10 tags.", undefined],
  I18N_PROFILE_PAGE_ALERT_TAG_INVALID: ["삭제된 태그 또는 잘못된 태그입니다.", "This is a deleted or invalid tag.", undefined],
  I18N_PROFILE_PAGE_NOT_VERSION: ["선택한 문서의 버전은 존재하지 않습니다.", "The version of the selected document does not exist.", undefined],
  I18N_PROFILE_PAGE_INTRO_NULL: ["소개 글을 입력하세요.", "Enter an introduction.", undefined],
  I18N_PROFILE_PAGE_INTRO_PLACE_HOLDER: ["소개를 입력하세요. (80자 이내)", "Describe yourself. (Max. 80 Characters)", undefined],
  I18N_PROFILE_PAGE_TAG_NULL: ["관심 태그를 추가하세요.", "Add tags.", undefined],
  I18N_PROFILE_PAGE_MY_PICTURE: ["내 사진", "My Picture", undefined],
  I18N_PROFILE_PAGE_DELETE: ["삭제", "Delete", undefined],
  I18N_PROFILE_NO_ALARM: [
    "조회된 자료가 없습니다.",
    "No result found."
    , undefined],
  I18N_PROFILE_NO_ALARM_NOTICE: [
    "내 알림 내역이 표시됩니다.",
    "Display alarm notifications."
    , undefined],
  I18N_PROFILE_NO_DOWNLOAD: [
    "조회된 자료가 없습니다.",
    "No result found."
    , undefined],
  I18N_PROFILE_NO_DOWNLOAD_NOTICE: [
    "파일을 다운받아 보세요.",
    "You have not downloaded any files."
    , undefined],
  I18N_PROFILE_NO_SHARE: [
    "조회된 자료가 없습니다.",
    "No result found."
    , undefined],
  I18N_ALERT_NOT_EXPOSE: ["비공개 포스트입니다.", "This is a private post.", undefined],
  I18N_PROFILE_NO_SHARE_NOTICE: [
    "자료의 링크를 복사하여 공유해 보세요.",
    "Copy link to clipboard for sharing."
    , undefined],
  I18N_ALL_SEARCH_RELATED_VIEW_DETAIL: ["자세히 보기", "View Detail", undefined],
  I18N_SW_SERVICE: ['SW 솔루션', 'Software Solution', undefined],
};

const [ko, en, enus] = resi18nutil.getres(resi18n_my_page);

export const resi18n_my_page_ko = {...resi18n_all_ko, ...ko};
export const resi18n_my_page_en = {...resi18n_all_en, ...en};
export const resi18n_my_page_enus = {...resi18n_all_enus, ...enus};
