import styled from "styled-components";
import {fontFamilyBold, fontFamilyMedium, fontFamilyRegular} from "style/fonts";

export const CenterPopupArea = styled.div`
  .popup_base_mobile {
    display: none;
  }

  .popup_base {
    z-index: 1002;
    position: fixed;
    width: 36rem;
    height: 64rem;
    //height: 61.2rem;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    color: #2a2c2f;
    left: 50%;

    .popup-header {
      width: 36rem;
      height: 31rem;

      img {
        width: 100%;
        height: 100%;
        border-radius: 4px 4px 0 0;
      }
    }
  }

  .entered_image {
    width: 36rem;
    height: 60.8rem;
    border-radius: 5px 5px 0 0;

    &.asset {
      height: 60.1rem;
      border-radius: 0px;
    }

    &.empty {
      background: #F6F7FC;
    }

  }

  .center_empty_image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 16rem;
    height: 16rem;
    border-radius: 0px;
  }


  .popup-collider {
    width: 100%;
    height: 100%;
    position: relative;
    background: #fff;
    border-radius: 5px;

    .popup-image {
      width: 100%;
      height: 100%;
      position: absolute;
      border-radius: 5px;
    }

    .close-button {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
      padding: 1.6rem 1.6rem 0 0;
    }

    .description-base {
      position: relative;
      width: 100%;
      height: calc(100% - 3rem);
    }

    .total-button-area {
      position: absolute;
      width: 31.2rem;
      left: 6%;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      &.asset {
        width: 29.6rem;
        left: 9%;
        bottom: 2%;
      }

      .insert-button-area {
        position: relative;
        width: 100%;
        height: 4.8rem;
        border-radius: 100px;
        box-shadow: 0px 4px 10px 0px #0000001A;
        margin-bottom: 1rem;
        padding-left: 3.2rem;
        vertical-align: middle;
        line-height: 4.8rem;
        font-size: 1.5rem;
        cursor: pointer;

        ${fontFamilyMedium}
        &.asset {
          border: 1px solid #97A4C8;
          border-radius: 6px;
          padding-left: 2.4rem;
          font-size: 1.6rem;
        }

        .link_arrow {
          position: absolute;
          right: 7%;
          top: 40%;

          &.asset {
            right: 5%;
            top: 23%;
          }

        }

        &:last-child {
          margin-bottom: 2rem;
        }
      }
    }

    .exit-base {
      width: 100%;
      height: 3.2rem;
      position: absolute;
      background: #fff;
      padding: 0.8rem 1.3rem 1rem 1.3rem;
      border-radius: 0px 0px 4px 4px;


      button {
        top: 50%;
        font-size: 1rem;
        color: #407EDB;
        vertical-align: middle;
        ${fontFamilyMedium}
      }
    }
  }

  .input_item {
    background: #fff;
    padding: 2rem 3.2rem;
    width: 36rem;

    table {
      height: 16.7rem
    }
  }


  .input_item p.lec-info {
    text-align: center;
    color: #34373D;
    font-size: 1.4rem;
    line-height: 2.4rem;
    margin-bottom: .8rem;
  }

  > .popup_base table {
    padding: 0;
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    border-top: .2rem solid #404D6E;
    border-bottom: .2rem solid #404D6E;
  }

  table {
    border-top: 2px solid #404D6E;
    border-bottom: 1px solid #404D6E;
    border-spacing: 0;
    width: 100%;
  }

  table th,
  table td {
    border-bottom: .1rem solid #ECECEC;
  }

  table tr:last-child th,
  table tr:last-child td {
    border-bottom: none;
  }

  table th {
    font-size: 1.1rem;
    ${fontFamilyRegular};
    font-weight: 400;
    color: #4A4F57;
    width: 7.2rem;
    text-align: left;
    padding: 1rem 1.2rem;
    border-right: .1rem solid #ECECEC;
  }

  table td {
    width: auto;
    font-size: 1.2rem;
    ${fontFamilyMedium};
    padding: .8rem 1.2rem;
  }

  table td.class-name {
    ${fontFamilyBold};
  }


  .content {
    text-align: left;
    position: relative;
    display: -webkit-box;
    max-height: 4rem;
    line-height: 2rem;
    overflow: hidden;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    white-space: pre-line;
  }

  .hover-selector {
    position: absolute;
    background: #FFFFFF;
    border: 1px solid #C7D6EA;
    border-radius: 4px;
    height: auto;
    line-height: 1.6rem;
    min-width: 30px;
    padding: 0.2rem 0.5rem;
    //text-align: center;
    z-index: 1003;
    font-size: 10px;
    //white-space: nowrap;
    ${fontFamilyRegular};
    white-space: pre-line;
    color: #2A2C2F;
    text-align: left;
    transform: translateY(-10%) translateX(0%);
  }

  .lec-jump {
    width: 21.6rem;
    height: 4rem;
    line-height: 4rem;
    margin: auto;
    display: block;
    background: #404D6E;
    color: #fff;
    ${fontFamilyBold};
    font-size: 1.3rem;
    margin-top: 2rem;
    border-radius: .4rem;
  }


  @media screen and (max-width: 1199px) {
    .popup_base {
      display: none;
    }

    .popup_base_mobile {
      display: block;
      z-index: 1002;
      position: fixed;
      width: 36rem;
      height: 64rem;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 4px;
      color: #2a2c2f;

      .popup-header {
        width: 36rem;
        height: 31rem;

        img {
          width: 100%;
          height: 100%;
          border-radius: 4px 4px 0 0;
        }
      }

      .popup-collider {
        width: 100%;
        height: 100%;
        position: relative;
        background: #fff;

        /* 닫기 버튼 */

        .close-button {
          position: absolute;
          right: 2rem;
          top: 2rem;
          z-index: 1;
        }

        .description-base {
          position: relative;
          width: 100%;
          height: calc(100% - 3rem);
        }

        .exit-base {
          width: 100%;
          height: 3rem;
          position: absolute;
          background: #fff;
          padding: 0rem 1.3rem;
          border-radius: 0px 0px 4px 4px;

          button {
            top: 50%;
            height: 100%;
            font-size: 1rem;
            color: #407EDB;
            vertical-align: middle;
            line-height: 1.4rem;
            ${fontFamilyMedium}
          }
        }
      }
    }
  }
`;