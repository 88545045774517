import {IfDisplayPopupItem} from "models/IfDisplayPopupItem";
import React, {Component} from "react";
import {connect} from "react-redux";
import {ACTION_TYPES} from "store/ActionTypes";
import {AssetPopup} from "popup/comp/asset/AssetPopup";

interface Props {
  displayPopupItem: IfDisplayPopupItem;
  setDisplayPopupItem: (b: IfDisplayPopupItem) => void;
}

interface State {
}


export class AssetPopupBase extends Component<Props, State> {

  render() {
    return <div>
      {this.props.displayPopupItem.asset &&
        <AssetPopup displayPopupItem={this.props.displayPopupItem} setDisplayPopupItem={this.props.setDisplayPopupItem}/>}
    </div>;
  }
}

export default connect(
  (state: any) => ({
    displayPopupItem: state.main.displayPopupItem
  }),
  dispatch => ({
    setDisplayPopupItem: (b: IfDisplayPopupItem) => dispatch({type: ACTION_TYPES.MAIN.DISPLAY_POPUP_ITEM, payload: b}),
  })
)(AssetPopupBase);