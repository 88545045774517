import React, {useEffect, useState} from "react";
import {LanguageService} from "language/LanguageService";
import styled from "styled-components";
import {getLangSuffix, HelmetTitleAndH1} from "ctls/common/HelmetTitleAndH1";
import {tcommona} from "language/i18nkey";
import {TitleBarContainer, TitleBarContents, TitleBarItem} from "style/CommonStyledComponents";
import {langutil} from "language/langutil";
import svcs from "services";
import {SsqImages} from "images/SsqImages";
import {fontFamilyBold, fontFamilyRegular} from "style/fonts";
import AOS from 'aos';
import "aos/dist/aos.css";
import parse from "html-react-parser";

type Props = {
  languageService: LanguageService;
}
type State = {}
const AssetInsight = (props: Props) => {
  const trans = (key: string) => {
    svcs.svcLang.setNamespace("I18N_NAMESPACE_SERVICE_PAGE");
    return svcs.svcLang.getTranslationByKey(key) || "";
  };
  const {country, lang} = langutil.splitLangCode(svcs.svcLang.lang());
  AOS.init({
    duration: 900
  });
  const [position, setPosition] = useState(0);

  function onScroll() {
    const scrollWrap = document.querySelector(".sec03-img");
    const positionTop = (window.scrollY - 1000) / 10;
    if (scrollWrap.classList.contains('aos-animate')) {
      setPosition(positionTop);
    } else {
      setPosition(0);
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return <React.Fragment>
    <HelmetTitleAndH1 title={tcommona('I18N_COMMON_TIT_ENERGY_SAVE', [], [getLangSuffix()])}/>
    <TitleBarContainer>
      <TitleBarContents className={"container"}>
        <TitleBarItem><a href={`/${country}/${lang}/main`}>{trans("I18N_TITLE_MAIN")}</a></TitleBarItem>
        <TitleBarItem>{trans("I18N_TITLE_SERVICE")}</TitleBarItem>
        <TitleBarItem className="selected">Asset Insight</TitleBarItem>
      </TitleBarContents>
    </TitleBarContainer>
    <AssetInsightArea className={"wrapper"}>
      <AssetSection01 className={"container"}>
        <h2>Asset Insight</h2>
        <p>{parse(trans("I18N_ASSET_INSIGHT_SEC01_TEXT"))}</p>
        <a href=""><span>{trans('I18N_ASSET_INSIGHT_SEC01_BTN')}<img src={SsqImages.service.asset.linkBtn()}
          alt={trans('I18N_ASSET_INSIGHT_SEC01_BTN')}/></span></a>
        <img src={SsqImages.service.asset.section01Img()} alt={"section01_img.png"} className={"section-img"} style={{width: '700px'}}
          data-aos="fade-left"/>
      </AssetSection01>
      <AssetSection02>
        <div className={"container"}>
          <h2>{parse(trans("I18N_ASSET_INSIGHT_SEC02_TITLE"))}</h2>
          <div className={"keyword"} data-aos="fade" data-aos-anchor-placement={"bottom-center"}>
            <div>
              <p><span>{trans('I18N_ASSET_INSIGHT_SEC02_KEYWORD01')}</span></p>
              <p><span>{trans('I18N_ASSET_INSIGHT_SEC02_KEYWORD02')}</span></p>
            </div>
            <div>
              <p><span>{trans('I18N_ASSET_INSIGHT_SEC02_KEYWORD03')}</span></p>
              <p><span>{trans('I18N_ASSET_INSIGHT_SEC02_KEYWORD04')}</span></p>
            </div>
          </div>
          <img src={SsqImages.service.asset.section02Img()} alt={"section02_img.png"} className={"section-img"} style={{width: '965px'}}
            data-aos="fade" data-aos-anchor-placement={"center-bottom"}/>
        </div>
      </AssetSection02>
      <AssetSection03 className={props.languageService.getLanguage()}>
        <div className={"container flex"}>
          <div>
            <h2>{parse(trans("I18N_ASSET_INSIGHT_SEC03_TITLE"))}</h2>
            <div className={"keyword"} data-aos="fade">
              <div>
                <p><span>{trans('I18N_ASSET_INSIGHT_SEC03_KEYWORD01')}</span></p>
                <p><span>{trans('I18N_ASSET_INSIGHT_SEC03_KEYWORD02')}</span></p>
              </div>
              <div>
                <p><span>{trans('I18N_ASSET_INSIGHT_SEC03_KEYWORD03')}</span></p>
                <p><span>{trans('I18N_ASSET_INSIGHT_SEC03_KEYWORD04')}</span></p>
              </div>
            </div>
          </div>
          <div data-aos="" className={"sec03-img"} data-aos-anchor-placement={"top-center"}>
            <img src={SsqImages.service.asset.section03Img()} alt={"section03_screen_img.png"} className={"section-img frame"}
              style={{width: '375px'}}/>
            <img src={SsqImages.service.asset.section03Screen()} alt={"section03_screen_img.png"} className={"section-img screen"}
              style={{width: '375px', marginTop: `${-position}%`}}/>
          </div>
          <div className={"sec03-bg"}/>
        </div>
      </AssetSection03>
      <AssetSection04>
        <div className={"container"} data-aos-anchor-placement={"bottom-center"}>
          <h3>{trans('I18N_ASSET_INSIGHT_SEC04_TITLE')}</h3>
          <div className={"flex"}>
            <div>
              <ul>
                <li>{trans('I18N_ASSET_INSIGHT_SEC04_GUIDE01')}</li>
                <li>{trans('I18N_ASSET_INSIGHT_SEC04_GUIDE02')}</li>
                <li>{trans('I18N_ASSET_INSIGHT_SEC04_GUIDE03')}</li>
                <li>{trans('I18N_ASSET_INSIGHT_SEC04_GUIDE04')}</li>
              </ul>
              <p>{trans('I18N_ASSET_INSIGHT_SEC04_GUIDE_REMARK')}</p>
            </div>
            <img src={SsqImages.service.asset.section04Img()} alt={"section04_img.png"} className={"section-img"} style={{width: '527px'}}
              data-aos="fade"/>
          </div>
        </div>
      </AssetSection04>
    </AssetInsightArea>
  </React.Fragment>
    ;
};

export default AssetInsight;

const AssetInsightArea = styled.div`
  section {
    padding: 100px 0 40px;
    text-align: center;

    h2 {
      position: relative;
      font-size: clamp(2.4rem, 12vw, 5.2rem);
      font-weight: 700;
      word-break: keep-all;
    }

    p {
      word-break: keep-all;
    }

    .section-img {
      max-width: 100%;
    }

    .keyword {
      display: inline-block;

      > div {
        display: inline-flex;
        gap: 40px;

        + div {
          margin-left: 40px
        }
      }

      p {
        display: inline-block;
        font-size: 1.8rem;
        position: relative;

        span {
          position: relative;

          &:before {
            position: absolute;
            z-index: -1;
            content: '';
            background: #1D44B2;
            height: 9px;
            left: 0;
            bottom: 0;
            width: 0;
            opacity: 0.7;
            transition: all 0.6s 0.2s;
          }
        }
      }

      &.aos-animate span:before {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 1199px) {
    section {
      .keyword {
        display: flex;
        flex-direction: column;
        align-items: center;

        > div {
          display: flex;

          + div {
            margin-left: 0;
            margin-top: 12px;
          }
        }

        flex-wrap: wrap;
      }
    }
  }
`;
const AssetSection01 = styled.section`
  h2 {
    display: inline-block;
    padding-right: 70px;
    background: url(${SsqImages.service.asset.betaText()}) bottom right no-repeat;

    span.beta-img {
      position: absolute;
      bottom: -10px;
      right: 0;
    }
  }

  p {
    font-size: 1.8rem;
    ${fontFamilyRegular};
    line-height: 24px;
    margin: 24px 0 40px;

    br {
      display: none;
    }
  }

  a {
    display: flex;
    margin: 0 auto 50px;
    max-width: 100%;
    width: 340px;
    height: 64px;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 40px;
    font-size: 2.4rem;
    ${fontFamilyBold};
    position: relative;
    overflow: hidden;
    background: #000;
    cursor: pointer;

    span {
      width: 100%;
      z-index: 3;
    }

    img {
      position: absolute;
      right: 20px;
      top: calc(50% - 10px);
    }

    &:after {
      position: absolute;
      content: "";
      width: 300%;
      height: 100%;
      top: 0;
      left: 100%;
      z-index: -1;
      background-image: linear-gradient(to right, #000 0%, #1D44B2 50%);
    }

    &:hover:after {
      animation: colorChange 2s ease forwards;
      z-index: 2;
    }

    @keyframes colorChange {
      100% {
        opacity: 1;
        left: -150%
      }
    }
  }

  @media screen and (max-width: 1199px) {
    p br {
      display: inline-block;
    }

    a {
      max-width: calc(100% - 40px);
    }
  }
`;
const AssetSection02 = styled.section`
  background: #000;
  color: #fff;

  .keyword {
    margin: 45px 0 64px;
  }

  h2 br {
    display: none;
  }

  @media screen and (max-width: 1199px) {
    h2 br {
      display: inline-block;
    }
  }
`;
const AssetSection03 = styled.section`
  background: #161618;
  color: #fff;
  padding: 0 !important;
  overflow: hidden;
  height: 790px;

  &.ko-KR h2 br {
    display: none;
  }

  .flex {
    position: relative;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    > div:first-child {
      max-width: 650px;
      position: relative;
      z-index: 2;

      .keyword {
        margin-top: 64px;
      }
    }

    .sec03-bg {
      display: inline-block;
      content: '';
      width: 100%;
      height: 790px;
      background: #161618 url(${SsqImages.service.asset.section03Bg()}) no-repeat;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 530px;
    }

    .sec03-img {
      margin-top: 83px;
      position: relative;
      height: 707px;
      width: 375px;
      overflow: hidden;
      z-index: 2;
      mask-image: url(${SsqImages.service.asset.section03Mask()});
      mask-size: cover;

      .screen {
        padding: 0 7px;
        transition: all 0.5s;
        position: absolute;
        z-index: -1;
        left: 0;
      }
    }
  }

  @media screen and (max-width: 1199px) {
    height: 840px;

    .container {
      padding: 80px 20px 0 !important;

      h2 br {
        display: inline-block;
      }
    }

    div.flex {
      flex-direction: column;
      align-items: center;
      gap: 72px;

      .sec03-bg {
        left: 0;
        height: 100%;
        background-size: contain;
        background-position: center center;
        background-repeat: repeat;
      }

      .sec03-img {
        max-width: 100%;
        margin-top: 0;
        overflow: hidden;
        height: auto;
      }
    }
  }
`;
const AssetSection04 = styled.section`
  background: #EBEEF6;
  padding: 130px 0 150px !important;

  div {
    text-align: left;

    &.flex {
      justify-content: space-between;
      align-items: center;
    }
  }

  h3 {
    font-size: 2.4rem;
    ${fontFamilyBold};
    margin-bottom: 28px;
    line-height: 1;

    &::after {
      content: '';
      display: block;
      margin-top: 18px;
      background: #8893A8;
      width: 28px;
      height: 2px;
    }
  }

  ul {
    counter-reset: num;

    li {
      display: flex;
      align-items: center;
      font-size: 1.6rem;
      line-height: 24px;
      ${fontFamilyRegular};

      &::before {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
        font-size: 1.2rem;
        ${fontFamilyBold};
        color: #fff;
        background: #41444A;
        min-width: 24px;
        width: 24px;
        height: 24px;
        border-radius: 100%;
        content: counter(num);
        counter-increment: num 1;
      }

      + li {
        margin-top: 16px;
      }
    }
  }

  p {
    margin-top: 20px;
    color: #8893A8;
    font-size: 1.6rem;
    line-height: 24px;
    ${fontFamilyRegular};
  }

  @media screen and (max-width: 1199px) {
    padding: 70px 40px !important;
    div.flex {
      flex-direction: column;
      align-items: flex-start;
      gap: 72px;

      ul li {
        align-items: flex-start;
      }
    }
  }
`;