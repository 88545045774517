import {Dispatch, SetStateAction, useEffect, useState} from 'react';

const useDebounceCallback = <T>(v: T, delay: number, callback?: (v: T) => void): [v: T, v2: T, v: Dispatch<SetStateAction<T>>] => {
  const [value, setValue] = useState<T>(v);
  const [debounceValue, setDebounceValue] = useState<T>(v);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounceValue(value);
      if (callback) callback(v);
    }, delay);
    return () => clearTimeout(handler);
  }, [value, delay]);

  return [value, debounceValue, setValue];
};

export default useDebounceCallback;