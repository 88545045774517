import React, {Component} from "react";
import {SsqImages} from "../images/SsqImages";
import {LanguageService} from "../language/LanguageService";
import {TermsType} from "../definitions/TermsType";
import {TermsPopup} from "./TermsDetailPopup";
import {LANG_EN, LANG_EN_US, LANG_KO_KR} from "language/langutil";

interface Props {
  languageService: LanguageService;
  showTermsDetailPrivateEssentialPopupFunc: Function;
  termsType: string;
}

interface State {
  isShowRecentPrivacyTerms: boolean,
  isShowOriginalPrivacyTermsV0: boolean
  isShowOriginalPrivacyTermsV1: boolean
}


export class TermsDetailPrivatePopup extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isShowRecentPrivacyTerms: true,
      isShowOriginalPrivacyTermsV0: false,
      isShowOriginalPrivacyTermsV1: false
    };
  }

  getTranslation(key: string): string {
    this.props.languageService.setNamespace("I18N_NAMESPACE_HEADER");
    return this.props.languageService.getTranslationByKey(key) || "";
  }

  closeTermsDetailPopup() {
    this.props.showTermsDetailPrivateEssentialPopupFunc(false);
  }

  renderEssentialInfo() {
    return <div className={'term_imformation'}>
      <div className={'term_title'}>[필수정보]</div>
      1. 이메일 주소<br/>
      2. 비밀번호<br/>
      3. 이름<br/>
      4. 성<br/>
      5. 국가<br/>
      6. 프로필 이름<br/>
      7. 관심 산업<br/>
      8. 업종<br/>
      9. 회사명<br/><br/>
    </div>;
  }

  renderOptionalInfo() {
    return <div className={'term_imformation'}>
      <div className={'term_title'}>[선택정보]</div>
      1. 성명<br/>
      2. 전화 번호<br/>
      3. 부서/직위<br/>
    </div>;
  }

  renderEssentialInfoOriginalV1() {
    return <div className={'term_imformation'}>
      <div className={'term_title'}>[필수정보]</div>
      1. 이메일 주소<br/>
      2. 비밀번호<br/>
      3. 별명<br/>
      4. 회사명<br/>
      5. 산업 분야<br/>
      6. 관심 산업<br/><br/>
    </div>;
  }

  renderOptionalInfoOriginalV1() {
    return <div className={'term_imformation'}>
      <div className={'term_title'}>[선택정보]</div>
      1. 성명<br/>
      2. 전화 번호<br/>
      3. 부서/직위<br/>
    </div>;
  }

  renderPrivateInfoByType() {
    return <div>
      {(this.props.termsType === TermsType.ALL || this.props.termsType === TermsType.ESSENTIAL) && this.renderEssentialInfo()}
      <br/>
      {(this.props.termsType === TermsType.ALL || this.props.termsType === TermsType.OPTIONAL) && this.renderOptionalInfo()}
    </div>;
  }

  renderPrivateInfoByTypeOriginalV1() {
    return <div>
      {(this.props.termsType === TermsType.ALL || this.props.termsType === TermsType.ESSENTIAL) && this.renderEssentialInfoOriginalV1()}
      <br/>
      {(this.props.termsType === TermsType.ALL || this.props.termsType === TermsType.OPTIONAL) && this.renderOptionalInfoOriginalV1()}
    </div>;
  }

  renderOriginalPrivateTermsDetailPopupV0() {
    return <div>
      <button className={"close-button"} onClick={() => this.closeTermsDetailPopup()}>
        <img alt={"close_small_icon.svg"} src={SsqImages.common.icon.closeSmallIcon()}/>
      </button>
      <div className={"popup_tit"}>
        <button onClick={() => this.closeTermsDetailPopup()}>
          <img alt={"back_button.svg"} src={SsqImages.header.icon.backButton()}/>
        </button>
        {this.getTranslation("I18N_TERMS_DETAIL_POPUP_EVENT")}
      </div>
      <div className={"TermsDetail_content"}>
        <div className={"TermsDetail"} style={{overflowX: "hidden"}}>
          <span>개정일 2021.12.20</span>

          <h3>총칙</h3>

          LS ELECTRIC(이하 "회사") Solution Square 웹사이트는 이용자의 개인 정보를 보호하기 위하여 「정보통신망 이용 촉진 및 정보보호 등에 관한 법률」 및
          「개인정보보호법」 등 관련 법령상의 개인정보 보호 규정을 준수하고 있으며 다음과 같은 개인정보 처리 방침을 가지고 있습니다.<br/><br/>

          회사는 개인정보 처리방침을 통하여 이용자의 개인정보가 어떠한 목적과 방식으로 수집, 이용되고 있으며, 이용자의 개인정보 보호를 위해 Solution Square가 어떠한
          조치를 취하고 있는지 알려드립니다.<br/><br/>

          본 개인정보 처리 방침은 관련 법의 개정이나 회사의 정책에 따라 변경될 수 있으며, 회사는 Solution Square 웹사이트를 통하여 이를 알려드리오니, Solution
          Square 웹사이트 이용 시에 수시로 확인하여 주시기 바랍니다.<br/><br/>

          1. 개인정보의 처리 목적<br/>
          2. 처리하는 개인정보의 항목<br/>
          3. 개인정보의 수집방법<br/>
          4. 개인정보의 처리 및 보유기간<br/>
          5. 개인정보처리의 위탁에 관한 사항<br/>
          6. 개인정보의 제3자 제공에 관한 사항<br/>
          7. 개인정보의 파기에 관한 사항<br/>
          8. 정보주체의 권리,의무 및 그 행사방법에 관한 사항<br/>
          9. 개인정보의 안전성 확보 조치에 관한 사항<br/>
          10. 쿠키에 의한 개인정보 수집<br/>
          11. 개인정보 보호책임자에 관한 사항<br/>
          12. 정보주체의 권익침해에 대한 구제 방법<br/>
          13. 개인정보 처리방침의 변경에 관한 사항<br/>
          <br/><br/>

          <h3>1. 개인정보의 처리 목적</h3>

          회사는 수집한 개인정보를 고객 관리 및 고객 정보를 통한 시장정보분석과 다양한 마케팅 활동(뉴스레터와 세미나, 제품정보, 특별 판매, 교육, 이벤트 관련 정보 등에 대해
          e-mail, DM, 전화, SNS, MMS를 통한 안내 등)에 활용합니다.<br/>

          <br/><br/>
          <h3>2. 처리하는 개인정보의 항목</h3>

          회사는 기타 판촉/마케팅 활동 등으로 추가로 개인정보를 수집할 수 있으며, 이 경우 해당 페이지에 개인정보의 수집 목적, 수집 항목, 보유 기간을 별도 고지하며 추가 동의를
          받습니다.<br/><br/>

          개인정보 수집 시 필수 항목의 수집ㆍ이용에 대한 동의를 거부하실 수 있으며, 동의를 거부하실 경우 서비스 이용이 제한 될 수 있습니다. 선택 항목의 수집ㆍ이용 동의를 거부
          하실 경우에는 서비스 이용은 제한되지 않습니다.<br/><br/>

          <h5>수집하는 개인정보 항목</h5>
          1. 성명<br/>
          2. 회사명<br/>
          3. 부서명<br/>
          4. 산업 분야<br/>
          5. 관심 산업<br/>
          6. 전화번호<br/>
          7. 이메일 주소<br/>
          8. 국가정보<br/>

          <br/><br/>
          <h3>3. 개인정보의 수집방법</h3>

          1. 웹사이트를 통한 “이용자”의 직접 입력, “이용자”의 서면 작성<br/>
          2. 로그분석 프로그램을 통한 자동 수집<br/><br/><br/>


          <h3>4. 개인정보의 처리 및 보유기간</h3>

          회사는 개인정보 수집 시 이용자에게 고지하고 동의 받은 보유 기간이 도래하면 해당 정보를 지체 없이 파기합니다. 다만 상법, 국세기본법, 전자상거래 등에서의 소비자 보호에
          관한 법률 등 관련 법령의 규정에 의하여 다음과 같이 거래 관련 권리 의무 관계의 확인 등을 이유로 일정기간 보유하여야 할 필요가 있을 경우에는 일정기간 보유합니다. 이
          경우 회사는 보관하는 개인정보를 그 보관의 목적으로만 이용하며 보존 기간 및 보존 항목은 아래와 같습니다<br/><br/>

          1. 계약 또는 청약철회 등에 관한 기록 : 5 년(전자상거래 등에서의 소비자보호에 관한 법률)<br/>
          2. 대금 결제 및 재화 등의 공급에 관한 기록 : 5년(전자상거래 등에서의 소비자보호에 관한 법률)<br/>
          3. 소비자 불만 또는 분쟁 처리에 관한 기록 : 3년(전자상거래 등에서의 소비자보호에 관한 법률)<br/>
          4. 납세 증거에 관한 기록 : 5년(국세 기본법)<br/>
          5. 보존 항목 : 이름, 이메일주소, 유선전화번호, 휴대전화번호<br/><br/><br/>


          <h3>5. 개인정보처리의 위탁에 관한 사항</h3>

          회사는 원활한 개인정보 업무처리 및 보다 나은 서비스 향상을 위해서 다음과 같이 개인정보 처리업무를 외부 전문업체에 위탁하여 운영하고 있습니다.<br/><br/>

          <h5>수탁자</h5><br/>

          <h5>LS ITC</h5>
          그룹웨어 운영 / 정보 전산 처리 및 유지관리 / ERP 시스템 위탁 운영 (전산 아웃소싱)<br/><br/>

          <h5>LG CNS</h5>
          정보 전산 처리 및 유지관리 / 임직원 정보시스템 위탁 운영 (전산 아웃소싱)<br/><br/>

          <h5>삼양정보시스템(SYDS)</h5>
          보안운영 / 정보 전산 처리 및 유지관리 / 협력업체 정보시스템 위탁 운영 (전산 아웃소싱)<br/><br/>

          Solution Square 위탁계약 체결 시 개인정보보호법 제25조에 따라 위탁업무 수행목적 외 개인정보 처리 금지, 기술적•관리적 보호조치, 재위탁 제한, 수탁자에 대한
          관리 및 감독, 개인정보보호 관련 법규의 준수, 개인정보에 대한 비밀유지, 손해배상 등 책임과 관한 사항을 계약서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를
          감독하고 있습니다.<br/>
          회사는 위탁하는 업무의 내용이나 수탁자가 변경될 시 지체 없이 본 개인정보 처리(취급)방침을 통해 공개하도록 하겠습니다.<br/><br/><br/>


          <h3>6. 개인정보의 제3자 제공에 관한 사항</h3>

          회사는 이용자의 동의가 있거나 관련 법령의 규정에 의한 경우를 제외하고는 어떠한 경우에도 "1. 처리하는 개인정보의 항목", "2. 개인정보의 처리 목적"에서 고지한 범위를
          넘어 이용자의 개인정보를 이용하거나 제3자에게 제공하지 않습니다. 다만, 다음의 경우에는 예외로 합니다.<br/><br/>

          1. 이용자가 사전에 동의한 경우<br/>
          2. 관련 법령의 규정에 의하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우<br/>
          3. 계약 이행을 위해 필요한 경우<br/>
          4. 타인에게 정신적,물질적 피해를 줌으로써 그에 대한 법적 조치를 취하기 위해 이용자의 정보를 공개해야 한다고 판단되는 충분한 근거가 있는 경우<br/>
          5. 통계작성, 마케팅분석 또는 시장조사를 위해 필요한 경우로 특정 개인을 식별할 수 없는 형태로 가공하여 외부 기관 또는 단체 등에 제공하는 경우<br/>
          6. 서비스의 제공에 관한 계약의 이행을 위하여 필요한 개인정보로서 경제적/기술적인 사유로 통상의 동의를 받는 것이 현저히 곤란한 경우<br/><br/>

          <h5>개인정보 제3자 제공</h5><br/>

          1. 개인정보를 제공받는 자: 엘에스일렉트릭 주식회사의 대리점 및 협력사<br/>
          2. 개인정보를 제공받는 자의 개인정보 이용 목적: 고객 관리 및 고객 정보를 통한 마케팅에의 활용<br/>
          3. 제공하는 개인정보의 항목: 성명, 회사명, 부서먕, 산업 분야, 사용 기계 부하 정보, 유선전화번호, 휴대전화번호, 이메일 주소, 국가정보<br/>
          4. 개인정보를 제공받는 자의 개인정보 보유 및 이용 기간: 수집일로부터 5년<br/>
          5. 동의거부권 및 동의 거부에 따른 불이익 안내
          : 귀하는 위와 같이 개인정보를 제공하는 데 대한 동의를 거부할 권리가 있습니다. 그러나 동의를 거부할 경우 Solution Square 웹사이트 서비스 이용에 제한이 있을
          수 있습니다.<br/><br/><br/>


          <h3>7. 개인정보의 파기에 관한 사항</h3>

          <h5>1. 파기절차</h5>
          o 회원께서 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라 (보유 및 이용기간 참조) 일정 기간 저장된 후
          파기합니다.<br/>
          o 동 개인정보는 법률에 의한 경우가 아니고서는, 보유 이외의 다른 목적으로 이용되지 않습니다.<br/><br/>

          <h5>2. 파기방법</h5>
          o 종이에 출력된 개인정보 : 분쇄기로 분쇄하거나 소각<br/>
          o 전자적 파일형태로 저장된 개인정보 : 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제<br/><br/><br/>


          <h3>8. 정보주체의 권리 의무 및 그 행사방법에 관한 사항</h3>

          이용자 및 법정대리인의 개인정보를 최신의 상태로 정확하게 입력하여 불의의 사고를 예방해 주시기 바랍니다. 이용자 및 법정대리인이 입력한 부정확한 정보로 인해 발생하는 사고의
          책임은 이용자 및 법정대리인 자신에게 있으며 타인 정보의 도용 등 허위 정보를 입력할 경우 서비스 이용이 제한 될 수 있습니다. 이용자 및 법정대리인은 개인정보를 보호받을
          권리와 함께 스스로를 보호하고 타인의 정보를 침해하지 않을 의무도 가지고 있습니다. 이용자 및 법정대리인의 개인 정보가 유출되지 않도록 조심하시고 게시물을 포함한 타인의
          개인정보를 훼손하지 않도록 유의해 주십시오. 만약 이 같은 책임을 다하지 못하고 타인의 정보 및 존엄성을 훼손할 시에는 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』,
          『개인정보보호법』 등에 의해 처벌 받을 수 있습니다.<br/><br/>

          1. 개인정보 열람, 정정, 삭제 요구의 권리:<br/>
          이용자 및 법정대리인은 언제든지 등록되어 있는 자신의 개인정보를 열람하거나 정정, 삭제하실 수 있습니다. 개인정보 열람 및 정정, 삭제를 하고자 할 경우에는
          개인정보보호책임자 및 담당자에게 서면, 전화 또는 전자우편주소로 연락하시면 지체 없이 조치하겠습니다.<br/><br/>

          2. 개인정보 수집, 이용, 제공에 대한 동의 철회의 권리:<br/>
          이용자 및 법정대리인은 서비스 이용을 위해 입력하신 개인정보의 수집, 이용, 제공, 저장에 대해 동의하신 내용을 철회하실 수 있습니다.
          동의 철회는 개인정보보호 담당자에게 서면, 전화 또는 전자우편주소로 연락하시면 본인 확인 절차 후 개인정보의 삭제 등 필요한 조치를 하겠습니다.<br/><br/>
          <br/>

          <h3>9. 개인정보의 안전성 확보 조치에 관한 사항</h3>

          회사는 고객의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 「개인정보보호법 제24조 제2항, 제29조 및 동 법 시행령 제30조에
          따라 다음과 같이 안전성 확보에 필요한 기술적, 관리적, 물리적 조치를 하고 있습니다. 단, 고객 개인의 부주의나 인터넷 상의 문제로 개인정보가 유출되어 발생한 문제에
          대해서는 회사에서 일체의 책임을 지지 않습니다.<br/><br/>

          <h5>1. 관리적 조치</h5>
          o 회사는 개인정보 보호에 대한 내부 업무규정을 마련하여 소속 직원들에게 이를 숙지하고 준수하도록 하고 있습니다.<br/>
          o 회사는 개인정보 관련 취급 직원을 담당자에 한정시키고 있고, 개인정보 취급자에 대해서는 정기 및 수시 교육을 통하여 개인정보를 안전하게 관리하고 있습니다.<br/>
          o 회사는 개인정보 처리(취급)방침에 대한 이행사항 및 담당자의 준수 여부를 감사하기 위한 내부절차를 마련하여 지속적으로 시행하고 있으며, 사내 개인정보보호 전담기구를
          통하여 문제 발견 시 즉시 시정 조치하도록 운영하고 있습니다.<br/>
          o 회사는 컴퓨터를 이용하여 이용자의 개인정보를 처리하는 경우 개인정보에 대한 접근권한을 가진 담당자를 지정하여 식별부호(ID) 및 비밀번호를 부여하고, 해당 비밀번호를
          정기적으로 갱신하고 있습니다.<br/>
          o 회사는 신규 직원 채용 시 개인정보보호서약서에 서명함으로 소속 직원에 의한 개인정보 유출을 사전에 방지하고, 퇴직시에도 이용자의 개인정보를 취급하였던 담당자가 직무상
          알게 된 개인정보를 훼손ㆍ침해 또는 누설하지 않도록 하고 있습니다.<br/>
          o 개인정보 관련 취급자의 업무 인수인계는 보안이 유지된 상태에서 철저하게 이루어지고 있으며, 입사 및 퇴사 후 개인정보 침해사고에 대한 책임을 명확화하고
          있습니다.<br/><br/>

          <h5>2. 기술적 조치</h5>
          o 회사는 최신 백신 프로그램을 이용하여 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 침해를 방지하기 위한 조치를 취하고 있습니다.<br/>
          o 회사는 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 사내 네트워크를 보호하고 있으며, 기타 시스템적으로 보안성을 확보하기 위하여 가능한 모든 기술적 장치를
          갖추려 최선의 노력을 다 하고 있습니다.<br/>
          o 회사는 고유식별정보 등 중요 개인정보에 대하여서는 철저히 암호화하여 저장 및 관리하고 있습니다.<br/><br/>

          <h5>3. 물리적 조치</h5>
          o 회사는 전산실 및 자료 보관실 등을 특별 보호구역으로 설정하여 출입을 철저히 통제하고 있습니다.<br/><br/>
          <br/>

          <h3>10. 쿠키에 의한 개인정보 수집</h3>

          회사는 이용자의 정보를 수시로 저장하고 찾아내는 ' 쿠키( cookie)'를 설치 운용합니다. 쿠키란 Solution Square 웹사이트를 운영하는데 이용되는 서버가
          이용자의 브라우저에 보내는 아주 작은 텍스트 파일로서 이용자의 컴퓨터 하드디스크에 저장됩니다.<br/><br/>

          <h5>1. 쿠키 사용 목적</h5>
          o 이용자의 접속빈도 또는 머문 시간 등을 분석하여 이용자의 취향과 관심분야를 파악하여 서비스 개선에 활용<br/>
          o 웹사이트 방문 품목들에 대한 정보와 관심 있게 둘러본 품목들에 대한 자취를 추적하여 다음 번 웹사이트 방문 때 맞춤 정보를 제공<br/><br/>

          <h5>2. 쿠키 설정 거부 방법</h5>
          o 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 쿠키 설정을 거부하는 방법으로는 사용하시는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를
          저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.<br/>
          o 설정방법의 예시(웹 브라우저의 경우) : 웹 브라우저 상단의 도구&gt;인터넷 옵션&gt;개인정보에서 변경. 단, 이용자께서 쿠키 설치를 거부하였을 경우 서비스 제공에
          어려움이 있을 수 있습니다.<br/>
          o 처리중인 개인정보가 인터넷 홈페이지, P2P, 공유설정 등을 통하여 권한이 없는 자에게 공개되지 않도록 개인정보처리 시스템 및 개인정보처리자의 PC를
          설정합니다.<br/>
          o 개인정보 수집, 활용 및 파기 시 그에 대한 법률이 권고하는 기준(정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 개인정보보호법 등 관련 법령)에 따라 수집한 근거를
          남기도록 하고 있으며, 이와 관련하여 내부 정책과 프로세스를 규정하고 교육을 실시합니다.<br/><br/>

          Solution Square 웹사이트에 광고를 게재하는 배너에서도 광고주나 마케팅회사에 의해 쿠키가 사용되는 경우도 있음을 알려드립니다. 이 경우 당해 쿠키는 시스템
          관리와 광고주에게 제공할 통계 집계 등을 위하여 이용자들의 IP 주소를 모으기도 합니다. 당해 쿠키가 수집하는 개인정보에 관한 사항은 광고주나 마케팅회사의 개인정보보호방침에
          따릅니다.
          <br/><br/><br/>

          <h3>11. 개인정보보호책임자에 관한 사항</h3>

          회사는 고객의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 개인정보 보호 책임자를 두고 있습니다<br/><br/>

          1. 개인정보 보호 책임자: 송정섭 시니어 매니저<br/>
          2. 개인정보 보호 담당자 : 변민경 매니저<br/>
          3. 개인정보 운영 책임자: 채대석 이사<br/>
          4. 전화번호: 02-2034-4328<br/>
          5. 이메일 주소: mkbyun@ls-electric.com<br/><br/>


          <h3>12. 정보주체의 권익침해에 대한 구제 방법</h3>

          개인정보에 관한 상담이 필요한 경우에는 개인정보침해 신고센터, 대검찰청 인터넷 범죄수사센터, 경찰청 사이버테러대응센터 등으로 문의하실 수 있습니다.<br/><br/>

          1. 개인정보침해신고센터<br/>
          o 전화 : (국번없이) 118<br/>
          o URL : http://privacy.kisa.or.kr<br/><br/>

          2. 개인정보분쟁조정위원회<br/>
          o 전화 : 02-2100-2499<br/>
          o URL : http://www.kopico.go.kr<br/><br/>

          3. 대검찰청 사이버수사과<br/>
          o 전화 : (국번없이) 1301<br/>
          o URL : http://www.spo.go.kr<br/><br/>

          4. 경찰청 사이버안전국<br/>
          o 전화 : (국번없이) 182<br/>
          o URL : http://cyberbureau.police.go.kr<br/><br/>
          <br/>

          <h3>13. 개인정보 취급방침의 변경에 관한 사항</h3>
          이 개인정보처리방침은 법령ㆍ정책 또는 보안 기술의 변경에 따라 내용의 추가ㆍ삭제 및 수정이 있을 시에는 아래와 같은 방법으로 사전 공지하도록 하겠습니다.<br/><br/>

          • Solution Square 웹페이지 첫 화면에 수정내용 공지

        </div>
      </div>
      <button className={"Submit-Button"} onClick={() => this.closeTermsDetailPopup()}>
        확인
      </button>
    </div>;
  }


  renderOriginalPrivateTermsDetailPopupV1() {
    return <div>
      <button className={"close-button"} onClick={() => this.closeTermsDetailPopup()}>
        <img alt={"close_small_icon.svg"} src={SsqImages.common.icon.closeSmallIcon()}/>
      </button>
      <div className={"popup_tit"}>
        <button onClick={() => this.closeTermsDetailPopup()}>
          <img alt={"back_button.svg"} src={SsqImages.header.icon.backButton()}/>
        </button>
        {this.getTranslation("I18N_TERMS_DETAIL_POPUP_EVENT")}
      </div>
      <div className={"TermsDetail_content"}>
        <div className={"TermsDetail"} style={{overflowX: "hidden"}}>
          <span>개정일 2022.04.18</span>

          <h3>총칙</h3>

          LS ELECTRIC(이하 "회사") Solution Square 웹사이트는 이용자의 개인 정보를 보호하기 위하여
          「개인정보보호법」 등 관련 법령상의 개인정보 보호 규정을 준수하고 있으며 다음과 같은 개인정보 처리 방침을 가지고 있습니다.<br/><br/>

          회사는 개인정보 처리방침을 통하여 이용자의 개인정보가 어떠한 목적과 방식으로 수집, 이용되고 있으며, 이용자의 개인정보 보호를 위해 Solution Square가 어떠한
          조치를 취하고 있는지 알려드립니다.<br/><br/>

          본 개인정보 처리 방침은 관련 법의 개정이나 회사의 정책에 따라 변경될 수 있으며, 회사는 Solution Square 웹사이트를 통하여 이를 알려드리오니, Solution
          Square 웹사이트 이용 시에 수시로 확인하여 주시기 바랍니다.<br/><br/>

          1. 개인정보의 처리 목적<br/>
          2. 처리하는 개인정보의 항목<br/>
          3. 개인정보의 수집방법<br/>
          4. 개인정보의 처리 및 보유기간<br/>
          5. 개인정보처리의 위탁에 관한 사항<br/>
          6. 개인정보의 제3자 제공에 관한 사항<br/>
          7. 개인정보의 파기에 관한 사항<br/>
          8. 정보주체의 권리,의무 및 그 행사방법에 관한 사항<br/>
          9. 개인정보의 안전성 확보 조치에 관한 사항<br/>
          10. 쿠키에 의한 개인정보 수집<br/>
          11. 개인정보 보호책임자에 관한 사항<br/>
          12. 정보주체의 권익침해에 대한 구제 방법<br/>
          13. 개인정보 처리방침의 변경에 관한 사항<br/>
          <br/><br/>

          <h3>1. 개인정보의 처리 목적</h3>

          - 고객 관리<br/>
          - 시장정보 분석<br/>
          - 다양한 마케팅 활동<br/>

          <br/><br/>
          <h3>2. 처리하는 개인정보의 항목</h3>

          회사는 기타 판촉/마케팅 활동 등으로 추가로 개인정보를 수집할 수 있으며, 이 경우 해당 페이지에 개인정보의 수집 목적, 수집 항목, 보유 기간을 별도 고지하며 추가 동의를
          받습니다.<br/><br/>

          {this.renderPrivateInfoByTypeOriginalV1()}

          <br/><br/>
          <h3>3. 개인정보의 수집방법</h3>

          1. 웹사이트를 통한 “이용자”의 직접 입력, “이용자”의 서면 작성<br/>
          2. 로그분석 프로그램을 통한 자동 수집<br/><br/><br/>


          <h3>4. 개인정보의 처리 및 보유기간</h3>

          회사는 개인정보 수집 시 이용자에게 고지하고 동의 받은 보유 기간이 도래하면 해당 정보를 지체 없이 파기합니다. <br/>
          (다만, 수집목적 또는 제공 받은 목적이 달성된 경우에도 상법 등 법령의 규정에 의하여 보존할 필요성이 있는 경우에는 회원의 개인정보를 보유할 수 있습니다.) <br/>

          고객님은 위 사항에 대하여 원하지 않는 경우 동의를 거부할 수 있습니다. 다만, 고객님이 동의를 거부하는 경우 서비스의 전부 또는 일부를 이용할 수 없음을
          알려드립니다. <br/><br/>

          1. 계약 또는 청약철회 등에 관한 기록 : 5 년(전자상거래 등에서의 소비자보호에 관한 법률)<br/>
          2. 대금 결제 및 재화 등의 공급에 관한 기록 : 5년(전자상거래 등에서의 소비자보호에 관한 법률)<br/>
          3. 소비자 불만 또는 분쟁 처리에 관한 기록 : 3년(전자상거래 등에서의 소비자보호에 관한 법률)<br/>
          4. 납세 증거에 관한 기록 : 5년(국세 기본법)<br/>
          5. 보존 항목 : 이름, 이메일주소, 유선전화번호, 휴대전화번호<br/><br/><br/>


          <h3>5. 개인정보처리의 위탁에 관한 사항</h3>

          회사는 원활한 개인정보 업무처리 및 보다 나은 서비스 향상을 위해서 다음과 같이 개인정보 처리업무를 외부 전문업체에 위탁하여 운영하고 있습니다.<br/><br/>

          <h5>수탁자</h5><br/>

          <h5>LS ITC</h5>
          그룹웨어 운영 / 정보 전산 처리 및 유지관리 / ERP 시스템 위탁 운영 (전산 아웃소싱)<br/><br/>

          <h5>LG CNS</h5>
          정보 전산 처리 및 유지관리 / 임직원 정보시스템 위탁 운영 (전산 아웃소싱)<br/><br/>

          <h5>삼양정보시스템(SYDS)</h5>
          보안운영 / 정보 전산 처리 및 유지관리 / 협력업체 정보시스템 위탁 운영 (전산 아웃소싱)<br/><br/>

          Solution Square 위탁계약 체결 시 개인정보보호법 제25조에 따라 위탁업무 수행목적 외 개인정보 처리 금지, 기술적•관리적 보호조치, 재위탁 제한, 수탁자에 대한
          관리 및 감독, 개인정보보호 관련 법규의 준수, 개인정보에 대한 비밀유지, 손해배상 등 책임과 관한 사항을 계약서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를
          감독하고 있습니다.<br/>
          회사는 위탁하는 업무의 내용이나 수탁자가 변경될 시 지체 없이 본 개인정보 처리(취급)방침을 통해 공개하도록 하겠습니다.<br/><br/><br/>


          <h3>6. 개인정보의 제3자 제공에 관한 사항</h3>

          회사는 이용자의 동의가 있거나 관련 법령의 규정에 의한 경우를 제외하고는 어떠한 경우에도 "1. 처리하는 개인정보의 항목", "2. 개인정보의 처리 목적"에서 고지한 범위를
          넘어 이용자의 개인정보를 이용하거나 제3자에게 제공하지 않습니다. 다만, 다음의 경우에는 예외로 합니다.<br/><br/>

          1. 이용자가 사전에 동의한 경우<br/>
          2. 관련 법령의 규정에 의하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우<br/>
          3. 계약 이행을 위해 필요한 경우<br/>
          4. 타인에게 정신적,물질적 피해를 줌으로써 그에 대한 법적 조치를 취하기 위해 이용자의 정보를 공개해야 한다고 판단되는 충분한 근거가 있는 경우<br/>
          5. 서비스의 제공에 관한 계약의 이행을 위하여 필요한 개인정보로서 경제적/기술적인 사유로 통상의 동의를 받는 것이 현저히 곤란한 경우<br/><br/>

          <h3>7. 개인정보의 파기에 관한 사항</h3>

          <h5>1. 파기절차</h5>
          o 회원께서 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라 (보유 및 이용기간 참조) 일정 기간 저장된 후
          파기합니다.<br/>
          o 동 개인정보는 법률에 의한 경우가 아니고서는, 보유 이외의 다른 목적으로 이용되지 않습니다.<br/><br/>

          <h5>2. 파기방법</h5>
          o 종이에 출력된 개인정보 : 분쇄기로 분쇄하거나 소각<br/>
          o 전자적 파일형태로 저장된 개인정보 : 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제<br/><br/><br/>


          <h3>8. 정보주체의 권리 의무 및 그 행사방법에 관한 사항</h3>

          이용자 및 법정대리인의 개인정보를 최신의 상태로 정확하게 입력하여 불의의 사고를 예방해 주시기 바랍니다. 이용자 및 법정대리인이 입력한 부정확한 정보로 인해 발생하는 사고의
          책임은 이용자 및 법정대리인 자신에게 있으며 타인 정보의 도용 등 허위 정보를 입력할 경우 서비스 이용이 제한 될 수 있습니다. 이용자 및 법정대리인은 개인정보를 보호받을
          권리와 함께 스스로를 보호하고 타인의 정보를 침해하지 않을 의무도 가지고 있습니다. 이용자 및 법정대리인의 개인 정보가 유출되지 않도록 조심하시고 게시물을 포함한 타인의
          개인정보를 훼손하지 않도록 유의해 주십시오. 만약 이 같은 책임을 다하지 못하고 타인의 정보 및 존엄성을 훼손할 시에는 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』,
          『개인정보보호법』 등에 의해 처벌 받을 수 있습니다.<br/><br/>

          1. 개인정보 열람, 정정, 삭제 요구의 권리:<br/>
          이용자 및 법정대리인은 언제든지 등록되어 있는 자신의 개인정보를 열람하거나 정정, 삭제하실 수 있습니다. 개인정보 열람 및 정정, 삭제를 하고자 할 경우에는
          개인정보보호책임자 및 담당자에게 서면, 전화 또는 전자우편주소로 연락하시면 지체 없이 조치하겠습니다.<br/><br/>

          2. 개인정보 수집, 이용, 제공에 대한 동의 철회의 권리:<br/>
          이용자 및 법정대리인은 서비스 이용을 위해 입력하신 개인정보의 수집, 이용, 제공, 저장에 대해 동의하신 내용을 철회하실 수 있습니다.
          동의 철회는 개인정보보호 담당자에게 서면, 전화 또는 전자우편주소로 연락하시면 본인 확인 절차 후 개인정보의 삭제 등 필요한 조치를 하겠습니다.<br/><br/>
          <br/>

          <h3>9. 개인정보의 안전성 확보 조치에 관한 사항</h3>

          회사는 고객의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 「개인정보보호법 제24조 제2항, 제29조 및 동 법 시행령 제30조에
          따라 다음과 같이 안전성 확보에 필요한 기술적, 관리적, 물리적 조치를 하고 있습니다. 단, 고객 개인의 부주의나 인터넷 상의 문제로 개인정보가 유출되어 발생한 문제에
          대해서는 회사에서 일체의 책임을 지지 않습니다.<br/><br/>

          <h5>1. 관리적 조치</h5>
          o 회사는 개인정보 보호에 대한 내부 업무규정을 마련하여 소속 직원들에게 이를 숙지하고 준수하도록 하고 있습니다.<br/>
          o 회사는 개인정보 관련 취급 직원을 담당자에 한정시키고 있고, 개인정보 취급자에 대해서는 정기 및 수시 교육을 통하여 개인정보를 안전하게 관리하고 있습니다.<br/>
          o 회사는 개인정보 처리(취급)방침에 대한 이행사항 및 담당자의 준수 여부를 감사하기 위한 내부절차를 마련하여 지속적으로 시행하고 있으며, 사내 개인정보보호 전담기구를
          통하여 문제 발견 시 즉시 시정 조치하도록 운영하고 있습니다.<br/>
          o 회사는 컴퓨터를 이용하여 이용자의 개인정보를 처리하는 경우 개인정보에 대한 접근권한을 가진 담당자를 지정하여 식별부호(ID) 및 비밀번호를 부여하고, 해당 비밀번호를
          정기적으로 갱신하고 있습니다.<br/>
          o 회사는 신규 직원 채용 시 개인정보보호서약서에 서명함으로 소속 직원에 의한 개인정보 유출을 사전에 방지하고, 퇴직시에도 이용자의 개인정보를 취급하였던 담당자가 직무상
          알게 된 개인정보를 훼손ㆍ침해 또는 누설하지 않도록 하고 있습니다.<br/>
          o 개인정보 관련 취급자의 업무 인수인계는 보안이 유지된 상태에서 철저하게 이루어지고 있으며, 입사 및 퇴사 후 개인정보 침해사고에 대한 책임을 명확화하고
          있습니다.<br/><br/>

          <h5>2. 기술적 조치</h5>
          o 회사는 최신 백신 프로그램을 이용하여 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 침해를 방지하기 위한 조치를 취하고 있습니다.<br/>
          o 회사는 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 사내 네트워크를 보호하고 있으며, 기타 시스템적으로 보안성을 확보하기 위하여 가능한 모든 기술적 장치를
          갖추려 최선의 노력을 다 하고 있습니다.<br/>
          o 회사는 고유식별정보 등 중요 개인정보에 대하여서는 철저히 암호화하여 저장 및 관리하고 있습니다.<br/><br/>

          <h5>3. 물리적 조치</h5>
          o 회사는 전산실 및 자료 보관실 등을 특별 보호구역으로 설정하여 출입을 철저히 통제하고 있습니다.<br/><br/>
          <br/>

          <h3>10. 쿠키에 의한 개인정보 수집</h3>

          회사는 이용자의 정보를 수시로 저장하고 찾아내는 ' 쿠키( cookie)'를 설치 운용합니다. 쿠키란 Solution Square 웹사이트를 운영하는데 이용되는 서버가
          이용자의 브라우저에 보내는 아주 작은 텍스트 파일로서 이용자의 컴퓨터 하드디스크에 저장됩니다.<br/><br/>

          <h5>1. 쿠키 사용 목적</h5>
          o 이용자의 접속빈도 또는 머문 시간 등을 분석하여 이용자의 취향과 관심분야를 파악하여 서비스 개선에 활용<br/>
          o 웹사이트 방문 품목들에 대한 정보와 관심 있게 둘러본 품목들에 대한 자취를 추적하여 다음 번 웹사이트 방문 때 맞춤 정보를 제공<br/><br/>

          <h5>2. 쿠키 설정 거부 방법</h5>
          o 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 쿠키 설정을 거부하는 방법으로는 사용하시는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를
          저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.<br/>
          o 설정방법의 예시(웹 브라우저의 경우) : 웹 브라우저 상단의 도구&gt;인터넷 옵션&gt;개인정보에서 변경. 단, 이용자께서 쿠키 설치를 거부하였을 경우 서비스 제공에
          어려움이 있을 수 있습니다.<br/>
          o 처리중인 개인정보가 인터넷 홈페이지, P2P, 공유설정 등을 통하여 권한이 없는 자에게 공개되지 않도록 개인정보처리 시스템 및 개인정보처리자의 PC를
          설정합니다.<br/>
          o 개인정보 수집, 활용 및 파기 시 그에 대한 법률이 권고하는 기준(정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 개인정보보호법 등 관련 법령)에 따라 수집한 근거를
          남기도록 하고 있으며, 이와 관련하여 내부 정책과 프로세스를 규정하고 교육을 실시합니다.<br/><br/>

          Solution Square 웹사이트에 광고를 게재하는 배너에서도 광고주나 마케팅회사에 의해 쿠키가 사용되는 경우도 있음을 알려드립니다. 이 경우 당해 쿠키는 시스템
          관리와 광고주에게 제공할 통계 집계 등을 위하여 이용자들의 IP 주소를 모으기도 합니다. 당해 쿠키가 수집하는 개인정보에 관한 사항은 광고주나 마케팅회사의 개인정보보호방침에
          따릅니다.
          <br/><br/><br/>

          <h3>11. 개인정보보호책임자에 관한 사항</h3>

          회사는 고객의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 개인정보 보호 담당부서를 두고 있습니다. <br/>
          개인정보와 관련한 문의사항이 있으시면 아래의 개인정보보호담당 부서에 연락하여 주시기 바랍니다.<br/><br/>

          1. 소속 : IT혁신팀<br/>
          2. 이메일 주소: mkbyun@ls-electric.com<br/>
          3. 전화번호: 02-2034-4328<br/><br/>

          <h3>12. 정보주체의 권익침해에 대한 구제 방법</h3>

          개인정보에 관한 상담이 필요한 경우에는 개인정보침해 신고센터, 대검찰청 인터넷 범죄수사센터, 경찰청 사이버테러대응센터 등으로 문의하실 수 있습니다.<br/><br/>

          개인정보의 열람청구를 접수·처리하는 부서<br/>

          개인정보침해신고센터 - 전화 : 118 / URL : https://privacy.kisa.or.kr/ <br/>
          <br/>
          정보주체의 권익침해에 대한 구제 방법<br/><br/>

          1. 개인정보분쟁조정위원회<br/>
          o 전화 : (국번없이) 1833-6972<br/>
          o URL : http://www.kopico.go.kr<br/><br/>

          2. 개인정보침해신고센터<br/>
          o 전화 : (국번없이) 118<br/>
          o URL : http://privacy.kisa.or.kr<br/><br/>

          3. 대검찰청<br/>
          o 전화 : (국번없이) 1301<br/>
          o URL : http://www.spo.go.kr<br/><br/>

          4. 사이버범죄 신고시스템<br/>
          o 전화 : (국번없이) 182<br/>
          o URL : http://ecrm.police.go.kr<br/><br/>
          <br/>

          <h3>13. 개인정보 취급방침의 변경에 관한 사항</h3>
          본 개인정보 처리방침은 2022년 4월 30일 부터 적용되며, 개인정보 처리방침 변경 시 시행의 시기, 변경된 내용을 지속적으로 공개합니다. <br/>
          이전의 개인정보 처리방침은 아래에서 확인하실 수 있습니다.<br/>

          개인정보처리방침 버전번호 : V1.1<br/>
          개인정보처리방침 시행일자 : 2022.04.18<br/><br/><br/>

        </div>
      </div>
      <button className={"Submit-Button"} onClick={() => this.closeTermsDetailPopup()}>
        확인
      </button>
    </div>;
  }

  renderTermsDetailPopup() {
    return <div>
      <button className={"close-button"} onClick={() => this.closeTermsDetailPopup()}>
        <img alt={"close_small_icon.svg"} src={SsqImages.common.icon.closeSmallIcon()}/>
      </button>
      <div className={"popup_tit"}>
        <button onClick={() => this.closeTermsDetailPopup()}>
          <img alt={"back_button.svg"} src={SsqImages.header.icon.backButton()}/>
        </button>
        {this.getTranslation("I18N_TERMS_DETAIL_POPUP_EVENT")}
      </div>
      <div className={"TermsDetail_content"}>
        <div className={"TermsDetail"} style={{overflowX: "hidden"}}>
          <span>개정일 2023.04.22</span>

          <h3>총칙</h3>

          LS ELECTRIC(이하 "회사") Solution Square 웹사이트는 이용자의 개인 정보를 보호하기 위하여
          「개인정보보호법」 등 관련 법령상의 개인정보 보호 규정을 준수하고 있으며 다음과 같은 개인정보 처리 방침을 가지고 있습니다.<br/><br/>

          회사는 개인정보 처리방침을 통하여 이용자의 개인정보가 어떠한 목적과 방식으로 수집, 이용되고 있으며, 이용자의 개인정보 보호를 위해 Solution Square가 어떠한
          조치를 취하고 있는지 알려드립니다.<br/><br/>

          본 개인정보 처리 방침은 관련 법의 개정이나 회사의 정책에 따라 변경될 수 있으며, 회사는 Solution Square 웹사이트를 통하여 이를 알려드리오니, Solution
          Square 웹사이트 이용 시에 수시로 확인하여 주시기 바랍니다.<br/><br/>

          1. 개인정보의 처리 목적<br/>
          2. 처리하는 개인정보의 항목<br/>
          3. 개인정보의 수집방법<br/>
          4. 개인정보의 처리 및 보유기간<br/>
          5. 개인정보처리의 위탁에 관한 사항<br/>
          6. 개인정보의 제3자 제공에 관한 사항<br/>
          7. 개인정보의 파기에 관한 사항<br/>
          8. 정보주체의 권리,의무 및 그 행사방법에 관한 사항<br/>
          9. 개인정보의 안전성 확보 조치에 관한 사항<br/>
          10. 쿠키에 의한 개인정보 수집<br/>
          11. 행태정보의 수집,이용 및 거부 등에 관한 사항<br/>
          12. 개인정보 보호책임자에 관한 사항<br/>
          13. 정보주체의 권익침해에 대한 구제 방법<br/>
          14. 개인정보 처리방침의 변경에 관한 사항<br/>
          <br/><br/>

          <h3>1. 개인정보의 처리 목적</h3>

          - 고객 관리<br/>
          - 시장정보 분석<br/>
          - 다양한 마케팅 활동<br/>

          <br/><br/>
          <h3>2. 처리하는 개인정보의 항목</h3>

          회사는 기타 판촉/마케팅 활동 등으로 추가로 개인정보를 수집할 수 있으며, 이 경우 해당 페이지에 개인정보의 수집 목적, 수집 항목, 보유 기간을 별도 고지하며 추가 동의를
          받습니다.<br/><br/>

          {this.renderPrivateInfoByType()}

          <br/><br/>
          <h3>3. 개인정보의 수집방법</h3>

          1. 웹사이트를 통한 “이용자”의 직접 입력, “이용자”의 서면 작성<br/>
          2. 로그분석 프로그램을 통한 자동 수집<br/><br/><br/>


          <h3>4. 개인정보의 처리 및 보유기간</h3>

          회사는 개인정보 수집 시 이용자에게 고지하고 동의 받은 보유 기간이 도래하면 해당 정보를 지체 없이 파기합니다. <br/>
          (다만, 수집목적 또는 제공 받은 목적이 달성된 경우에도 상법 등 법령의 규정에 의하여 보존할 필요성이 있는 경우에는 회원의 개인정보를 보유할 수 있습니다.) <br/>

          고객님은 위 사항에 대하여 원하지 않는 경우 동의를 거부할 수 있습니다. 다만, 고객님이 동의를 거부하는 경우 서비스의 전부 또는 일부를 이용할 수 없음을
          알려드립니다. <br/><br/>

          1. 계약 또는 청약철회 등에 관한 기록 : 5 년(전자상거래 등에서의 소비자보호에 관한 법률)<br/>
          2. 대금 결제 및 재화 등의 공급에 관한 기록 : 5년(전자상거래 등에서의 소비자보호에 관한 법률)<br/>
          3. 소비자 불만 또는 분쟁 처리에 관한 기록 : 3년(전자상거래 등에서의 소비자보호에 관한 법률)<br/>
          4. 납세 증거에 관한 기록 : 5년(국세 기본법)<br/>
          5. 보존 항목 : 이름, 이메일주소, 전화번호, 회사명<br/>
          6. 개인정보의 국외 이전<br/>
          당사의 플랫폼과 관련하여 당사가 수집(또는 처리)하는 개인정보는 기술적인 백업 및 운영을 위해 한국 및 기타 국가에서 보관될 것입니다. 귀하의 개인정보를 이전 받는 자들은 귀하의 개인정보가 수집된 국가 이외의 국가에 소재할 수
          있습니다<br/><br/><br/>


          <h3>5. 개인정보처리의 위탁에 관한 사항</h3>

          회사는 원활한 개인정보 업무처리 및 보다 나은 서비스 향상을 위해서 다음과 같이 개인정보 처리업무를 외부 전문업체에 위탁하여 운영하고 있습니다.<br/><br/>

          <h5>수탁자</h5><br/>

          <h5>LS ITC</h5>
          그룹웨어 운영 / 정보 전산 처리 및 유지관리 / ERP 시스템 위탁 운영 (전산 아웃소싱)<br/><br/>

          <h5>LG CNS</h5>
          정보 전산 처리 및 유지관리 / 임직원 정보시스템 위탁 운영 (전산 아웃소싱)<br/><br/>

          <h5>삼양정보시스템(SYDS)</h5>
          보안운영 / 정보 전산 처리 및 유지관리 / 협력업체 정보시스템 위탁 운영 (전산 아웃소싱)<br/><br/>

          Solution Square 위탁계약 체결 시 개인정보보호법 제25조에 따라 위탁업무 수행목적 외 개인정보 처리 금지, 기술적•관리적 보호조치, 재위탁 제한, 수탁자에 대한
          관리 및 감독, 개인정보보호 관련 법규의 준수, 개인정보에 대한 비밀유지, 손해배상 등 책임과 관한 사항을 계약서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를
          감독하고 있습니다.<br/>
          회사는 위탁하는 업무의 내용이나 수탁자가 변경될 시 지체 없이 본 개인정보 처리(취급)방침을 통해 공개하도록 하겠습니다.<br/><br/><br/>


          <h3>6. 개인정보의 제3자 제공에 관한 사항</h3>

          회사는 이용자의 동의가 있거나 관련 법령의 규정에 의한 경우를 제외하고는 어떠한 경우에도 "1. 처리하는 개인정보의 항목", "2. 개인정보의 처리 목적"에서 고지한 범위를
          넘어 이용자의 개인정보를 이용하거나 제3자에게 제공하지 않습니다. 다만, 다음의 경우에는 예외로 합니다.<br/><br/>

          1. 이용자가 사전에 동의한 경우<br/>
          2. 관련 법령의 규정에 의하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우<br/>
          3. 계약 이행을 위해 필요한 경우<br/>
          4. 타인에게 정신적,물질적 피해를 줌으로써 그에 대한 법적 조치를 취하기 위해 이용자의 정보를 공개해야 한다고 판단되는 충분한 근거가 있는 경우<br/>
          5. 서비스의 제공에 관한 계약의 이행을 위하여 필요한 개인정보로서 경제적/기술적인 사유로 통상의 동의를 받는 것이 현저히 곤란한 경우<br/><br/>

          <h3>7. 개인정보의 파기에 관한 사항</h3>

          <h5>1. 파기절차</h5>
          o 회원께서 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라 (보유 및 이용기간 참조) 일정 기간 저장된 후
          파기합니다.<br/><br/>
          o 동 개인정보는 법률에 의한 경우가 아니고서는, 보유 이외의 다른 목적으로 이용되지 않습니다.<br/><br/>

          <h5>2. 파기방법</h5>
          o 종이에 출력된 개인정보 : 분쇄기로 분쇄하거나 소각<br/><br/>
          o 전자적 파일형태로 저장된 개인정보 : 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제<br/><br/><br/>


          <h3>8. 정보주체의 권리 의무 및 그 행사방법에 관한 사항</h3>

          이용자 및 법정대리인의 개인정보를 최신의 상태로 정확하게 입력하여 불의의 사고를 예방해 주시기 바랍니다. 이용자 및 법정대리인이 입력한 부정확한 정보로 인해 발생하는 사고의
          책임은 이용자 및 법정대리인 자신에게 있으며 타인 정보의 도용 등 허위 정보를 입력할 경우 서비스 이용이 제한 될 수 있습니다. 이용자 및 법정대리인은 개인정보를 보호받을
          권리와 함께 스스로를 보호하고 타인의 정보를 침해하지 않을 의무도 가지고 있습니다. 이용자 및 법정대리인의 개인 정보가 유출되지 않도록 조심하시고 게시물을 포함한 타인의
          개인정보를 훼손하지 않도록 유의해 주십시오. 만약 이 같은 책임을 다하지 못하고 타인의 정보 및 존엄성을 훼손할 시에는 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』,
          『개인정보보호법』 등에 의해 처벌 받을 수 있습니다.<br/><br/>

          1. 개인정보 열람, 정정, 삭제 요구의 권리:<br/>
          이용자 및 법정대리인은 언제든지 등록되어 있는 자신의 개인정보를 열람하거나 정정, 삭제하실 수 있습니다. 개인정보 열람 및 정정, 삭제를 하고자 할 경우에는
          개인정보보호책임자 및 담당자에게 서면, 전화 또는 전자우편주소로 연락하시면 지체 없이 조치하겠습니다.<br/><br/>

          2. 개인정보 수집, 이용, 제공에 대한 동의 철회의 권리:<br/>
          이용자 및 법정대리인은 서비스 이용을 위해 입력하신 개인정보의 수집, 이용, 제공, 저장에 대해 동의하신 내용을 철회하실 수 있습니다.
          동의 철회는 개인정보보호 담당자에게 서면, 전화 또는 전자우편주소로 연락하시면 본인 확인 절차 후 개인정보의 삭제 등 필요한 조치를 하겠습니다.<br/><br/>
          <br/>

          <h3>9. 개인정보의 안전성 확보 조치에 관한 사항</h3>

          회사는 고객의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 「개인정보보호법 제24조 제2항, 제29조 및 동 법 시행령 제30조에
          따라 다음과 같이 안전성 확보에 필요한 기술적, 관리적, 물리적 조치를 하고 있습니다. 단, 고객 개인의 부주의나 인터넷 상의 문제로 개인정보가 유출되어 발생한 문제에
          대해서는 회사에서 일체의 책임을 지지 않습니다.<br/><br/>

          <h5>1. 관리적 조치</h5>
          o 회사는 개인정보 보호에 대한 내부 업무규정을 마련하여 소속 직원들에게 이를 숙지하고 준수하도록 하고 있습니다.<br/><br/>
          o 회사는 개인정보 관련 취급 직원을 담당자에 한정시키고 있고, 개인정보 취급자에 대해서는 정기 및 수시 교육을 통하여 개인정보를 안전하게 관리하고 있습니다.<br/><br/>
          o 회사는 개인정보 처리(취급)방침에 대한 이행사항 및 담당자의 준수 여부를 감사하기 위한 내부절차를 마련하여 지속적으로 시행하고 있으며, 사내 개인정보보호 전담기구를
          통하여 문제 발견 시 즉시 시정 조치하도록 운영하고 있습니다.<br/><br/>
          o 회사는 컴퓨터를 이용하여 이용자의 개인정보를 처리하는 경우 개인정보에 대한 접근권한을 가진 담당자를 지정하여 식별부호(ID) 및 비밀번호를 부여하고, 해당 비밀번호를
          정기적으로 갱신하고 있습니다.<br/><br/>
          o 회사는 신규 직원 채용 시 개인정보보호서약서에 서명함으로 소속 직원에 의한 개인정보 유출을 사전에 방지하고, 퇴직시에도 이용자의 개인정보를 취급하였던 담당자가 직무상
          알게 된 개인정보를 훼손ㆍ침해 또는 누설하지 않도록 하고 있습니다.<br/><br/>
          o 개인정보 관련 취급자의 업무 인수인계는 보안이 유지된 상태에서 철저하게 이루어지고 있으며, 입사 및 퇴사 후 개인정보 침해사고에 대한 책임을 명확화하고
          있습니다.<br/><br/>

          <h5>2. 기술적 조치</h5>
          o 회사는 최신 백신 프로그램을 이용하여 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 침해를 방지하기 위한 조치를 취하고 있습니다.<br/><br/>
          o 회사는 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 사내 네트워크를 보호하고 있으며, 기타 시스템적으로 보안성을 확보하기 위하여 가능한 모든 기술적 장치를
          갖추려 최선의 노력을 다 하고 있습니다.<br/><br/>
          o 회사는 고유식별정보 등 중요 개인정보에 대하여서는 철저히 암호화하여 저장 및 관리하고 있습니다.<br/><br/>

          <h5>3. 물리적 조치</h5>
          o 회사는 전산실 및 자료 보관실 등을 특별 보호구역으로 설정하여 출입을 철저히 통제하고 있습니다.<br/><br/>
          <br/>

          <h3>10. 쿠키에 의한 개인정보 수집</h3>

          회사는 이용자의 정보를 수시로 저장하고 찾아내는 ' 쿠키( cookie)'를 설치 운용합니다. 쿠키란 Solution Square 웹사이트를 운영하는데 이용되는 서버가
          이용자의 브라우저에 보내는 아주 작은 텍스트 파일로서 이용자의 컴퓨터 하드디스크에 저장됩니다.<br/><br/>

          <h5>1. 쿠키 사용 목적</h5>
          o 이용자의 접속빈도 또는 머문 시간 등을 분석하여 이용자의 취향과 관심분야를 파악하여 서비스 개선에 활용<br/><br/>
          o 웹사이트 방문 품목들에 대한 정보와 관심 있게 둘러본 품목들에 대한 자취를 추적하여 다음 번 웹사이트 방문 때 맞춤 정보를 제공<br/><br/>

          <h5>2. 쿠키 설정 거부 방법</h5>
          o 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 쿠키 설정을 거부하는 방법으로는 사용하시는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를
          저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.<br/><br/>
          o 설정방법의 예시(웹 브라우저의 경우) : 웹 브라우저 상단의 도구&gt;인터넷 옵션&gt;개인정보에서 변경. 단, 이용자께서 쿠키 설치를 거부하였을 경우 서비스 제공에
          어려움이 있을 수 있습니다.<br/><br/>
          o 처리중인 개인정보가 인터넷 홈페이지, P2P, 공유설정 등을 통하여 권한이 없는 자에게 공개되지 않도록 개인정보처리 시스템 및 개인정보처리자의 PC를
          설정합니다.<br/><br/>
          o 개인정보 수집, 활용 및 파기 시 그에 대한 법률이 권고하는 기준(정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 개인정보보호법 등 관련 법령)에 따라 수집한 근거를
          남기도록 하고 있으며, 이와 관련하여 내부 정책과 프로세스를 규정하고 교육을 실시합니다.<br/><br/>

          Solution Square 웹사이트에 광고를 게재하는 배너에서도 광고주나 마케팅회사에 의해 쿠키가 사용되는 경우도 있음을 알려드립니다. 이 경우 당해 쿠키는 시스템
          관리와 광고주에게 제공할 통계 집계 등을 위하여 이용자들의 IP 주소를 모으기도 합니다. 당해 쿠키가 수집하는 개인정보에 관한 사항은 광고주나 마케팅회사의 개인정보보호방침에
          따릅니다.
          <br/><br/><br/>

          <h3>11. 행태정보의 수집 이용 및 거부 등에 관한 사항</h3>

          o 회사는 서비스 이용 과정에서 이용자에게 최적화된 맞춤형 서비스 및 혜택, 온라인 맞춤형 광고 등을 제공하기 위하여 행태정보를 수집이용하고 있으며, 이를 위해 구글(Google)에서 제공하는 웹로그 분석도구인 구글 애널리틱스를 이용하고 있습니다.
          구글 애널리틱스는 쿠키를 통하여 당사 웹사이트 이용자의 행태정보를 수집하게 되며, 이 경우 이용자 개인을 식별할 수 없는 비식별정보만이 수집됩니다. 그럼에도 이용자는 구글 애널리틱스 차단 브라우저 부가기능의
          설치(https://tools.google.com/dlpage/gaoptout) 또는 웹브라우저의 쿠키 설정 거부를 통해 구글 애널리틱스 이용을 거부할 수 있습니다.<br/><br/>

          o 수집하는 행태정보의 항목<br/>
          홍보사이트 GA 등 익명의 쿠키 정보를 토대로 웹로그 및 인구통계학적 데이터를 수집 및 산출<br/><br/>
          o 행태정보 수집 방법<br/>
          컴퓨터에 저장되는 텍스트 파일인 “쿠키”를 사용하여 사용자의 웹사이트 이용 방식을 분석.<br/>
          쿠키를 통하여 수집된 정보는 미국에 소재한 Google 서버로 이전되어 저장.<br/>
          고객은 별도로 Google 쿠키 이용을 거부하지 않는 한, Solution Square 서비스를 이용함으로써 Google 쿠키 및 Google Analytics를 통하여 생성된 모든 정보의 이용에 동의하게 됨.<br/><br/>

          o 행태정보 수집 목적<br/>
          고객에게 더 나은 서비스를 제공하기 위한 목적으로 Google, Inc. 이 제공하는 웹 분석 서비스인 Google Analytics를 사용하여 고객들이 Solution Square 서비스를 어떻게 이용하는지 분석 및 평가하고 고객의 수요를 파악하며,
          서비스를 개선하고 맞춤화하여 효율적인 서비스를 제공하는 것에 목적이 있음<br/><br/>
          o 보유 이용기간 및 이후 정보처리 방법<br/>
          Google Analytics를 통해 수집되는 정보의 처리는 Google 개인정보보호정책과 Google Analytics 이용약관을 적용 받음<br/>
          * Google 개인정보보호정책 : https://www.google.com/intl/ko/policies/privacy/<br/>
          * Google Analytics 이용약관 : https://www.google.com/analytics/terms/kr.html<br/><br/>


          o 웹브라우저를 통한 맞춤형 광고 차단/허용 방법은 다음과 같습니다.<br/>
          (1) 인터넷 익스플로러(Windows 10용 Internet Explorer 11)<br/>
          - Internet Explorer에서 도구 버튼을 선택한 다음 인터넷 옵션을 선택<br/>
          - 개인 정보 탭을 선택하고 설정에서 고급을 선택한 다음 쿠키의 차단 또는 허용을 선택<br/>
          (2) Microsoft Edge<br/>
          - Edge에서 오른쪽 상단 ‘…’ 표시를 클릭한 후, 설정을 클릭합니다.<br/>
          - 설정 페이지 좌측의 ‘개인정보, 검색 및 서비스’를 클릭 후 「추적방지」 섹션에서 ‘추적방지’ 여부 및 수준을 선택합니다.<br/>
          - ‘InPrivate를 검색할 때 항상 “엄격” 추적 방지 사용’ 여부를 선택합니다.<br/>
          - 아래 「개인정보」 섹션에서 ‘추적 안함 요청보내기’ 여부를 선택합니다.<br/>
          (3) 크롬 브라우저<br/>
          - Chrome에서 오른쪽 상단 ‘⋮’ 표시(chrome 맞춤설정 및 제어)를 클릭한 후, 설정 표시를 클릭합니다.<br/>
          - 설정 페이지 하단에 ‘고급 설정 표시’를 클릭하고 「개인정보」 섹션에서 콘텐츠 설정을 클릭합니다.<br/>
          - 쿠키 섹션에서 ‘타사 쿠키 및 사이트 데이터 차단’의 체크박스를 선택합니다<br/><br/>

          o 이용자는 본 지침에 기재된 개인정보보호담당 부서의 담당자 연락처로 행태정보와 관련하여 궁금한 사항과 거부권 행사, 피해 신고 접수 등을 문의할 수 있습니다.<br/><br/><br/>


          <h3>12. 개인정보보호책임자에 관한 사항</h3>

          회사는 고객의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 개인정보 보호 담당부서를 두고 있습니다. <br/>
          개인정보와 관련한 문의사항이 있으시면 아래의 개인정보보호담당 부서에 연락하여 주시기 바랍니다.<br/><br/>

          1. 소속 : IT혁신팀<br/>
          2. 이메일 주소: mkbyun@ls-electric.com<br/>
          3. 전화번호: 02-2034-4328<br/><br/>

          <h3>13. 정보주체의 권익침해에 대한 구제 방법</h3>

          개인정보에 관한 상담이 필요한 경우에는 개인정보침해 신고센터, 대검찰청 인터넷 범죄수사센터, 경찰청 사이버테러대응센터 등으로 문의하실 수 있습니다.<br/><br/>

          개인정보의 열람청구를 접수·처리하는 부서<br/>

          개인정보침해신고센터 - 전화 : 118 / URL : https://privacy.kisa.or.kr/ <br/>
          <br/>
          정보주체의 권익침해에 대한 구제 방법<br/><br/>

          1. 개인정보분쟁조정위원회<br/>
          o 전화 : (국번없이) 1833-6972<br/>
          o URL : http://www.kopico.go.kr<br/><br/>

          2. 개인정보침해신고센터<br/>
          o 전화 : (국번없이) 118<br/>
          o URL : http://privacy.kisa.or.kr<br/><br/>

          3. 대검찰청<br/>
          o 전화 : (국번없이) 1301<br/>
          o URL : http://www.spo.go.kr<br/><br/>

          4. 사이버범죄 신고시스템<br/>
          o 전화 : (국번없이) 182<br/>
          o URL : http://ecrm.police.go.kr<br/><br/>
          <br/>

          <h3>14. 개인정보 취급방침의 변경에 관한 사항</h3>
          본 개인정보 처리방침은 2023년 4월 22일 부터 적용되며, 개인정보 처리방침 변경 시 시행의 시기, 변경된 내용을 지속적으로 공개합니다.<br/>
          이전의 개인정보 처리방침은 아래에서 확인하실 수 있습니다.<br/>
          개인정보처리방침 버전번호 : V1.1<br/>
          개인정보처리방침 시행일자 : 2023.04.22<br/><br/>

          <span className={"span_cursor"} onClick={() => {
            this.setState({
              isShowRecentPrivacyTerms: false,
              isShowOriginalPrivacyTermsV0: true,
              isShowOriginalPrivacyTermsV1: false
            });
          }}>이전 개인정보 처리방침 보기 V1.0 (~2021.04.17)</span>
          <span className={"span_cursor"} onClick={() => {
            this.setState({
              isShowRecentPrivacyTerms: false,
              isShowOriginalPrivacyTermsV0: false,
              isShowOriginalPrivacyTermsV1: true
            });
          }}>이전 개인정보 처리방침 보기 V1.1 (~2023.04.21)</span>
          <br/>

        </div>
      </div>
      <button className={"Submit-Button"} onClick={() => this.closeTermsDetailPopup()}>
        확인
      </button>
    </div>;
  }

  renderOriginalPrivateTermsDetailPopupEnV0() {
    return <div>
      <button className={"close-button"} onClick={() => this.closeTermsDetailPopup()}>
        <img alt={"close_small_icon.svg"} src={SsqImages.common.icon.closeSmallIcon()}/>
      </button>
      <div className={"popup_tit"}>
        <button onClick={() => this.closeTermsDetailPopup()}>
          <img alt={"back_button.svg"} src={SsqImages.header.icon.backButton()}/>
        </button>
        {this.getTranslation("I18N_TERMS_DETAIL_POPUP_EVENT")}
      </div>
      <div className={"TermsDetail_content"}>
        <div className={"TermsDetail"} style={{overflowX: "hidden"}}>

          <h3>Introduction</h3>
          LS ELECTRIC Co. Ltd. and its subsidiaries (“LS ELECTRIC”, “We”, “Our” or “Us”) understand that you
          are aware of and care about your own privacy, and we take that seriously. This Privacy Notice is
          intended to provide you with information about what personal information LS ELECTRIC collects about
          you and how it is used.
          <br/><br/>

          If you have any queries about the process or how we handle your information, please contact our Data
          Protection Officer (DPO).
          <br/><br/>
          DPO’s contact information is as follows:
          <br/><br/>
          1st Floor, Tupolevlaan 48, 1119 NZ, Schiphol-Rijk
          privacy@ls-electric.com
          +31 020 654 1421
          <br/><br/><br/>

          <h4>1. Purpose of Personal Data Processing</h4>
          The legal basis for the processing of your personal information is your consent, legal obligation,
          performance of a contract and/or any other applicable legal basis, such as our legitimate interest
          in engaging in commerce, offering products and services including websites.
          <br/><br/>
          LS ELECTRIC may process your personal information for the following purposes:
          <br/><br/>
          Website Visitors
          <br/><br/>
          • Registration of Partner – When you register as a partner, we collect and use your personal
          information and company information that you provide to us to register you as a partner.
          <br/><br/>
          • Responding to your requests - When you contact us with any problem, comments or questions, we will
          collect and use your personal information to respond to your requests.
          <br/><br/>
          • Providing online services – We use cookies and other technologies to make website work, to work
          more efficiently as well as to recognize you when you return to our website.
          For further information about Cookies including how to manage them, please visit
          www.allaboutcookies.org.
          <br/><br/>
          • Preventing and investigating security issues and abuse – We collect and process your log
          information to keep the website secure and to prevent abuse and fraud.
          <br/><br/>
          Customers, Attendees, Contractors & Other External Partners
          <br/><br/>
          • Business Contact – When you or your company is in business relationship with us, we may process
          your personal information to communicate with you in accordance with the service or contract such as
          billing, invoicing, account management, payment, project management and other administrative
          matters.
          <br/><br/>
          • Responding to your requests - When you contact us with any problem, comments or questions, we will
          collect and use your personal information to respond to your requests.
          <br/><br/>
          • Communicating with you for marketing purposes – Upon your requests or expression of interests, we
          will send you an email to keep you updated on our products, services, promotions, news and events.
          You may manage your receipt of the email by clicking on the “unsubscribe” link located on the bottom
          of the emails.
          <br/><br/>
          • Training – We may process your personal information to provide you training courses.
          <br/><br/>
          • Technical Services – When you have problems with our products, we may process your contact
          information to provide you technical services. We may also disclose your contact information to
          third parties who provide technical service supports on behalf of us.
          <br/><br/>
          • Preventing and investigating security issues and abuse – We collect and process your log
          information to keep the website secure and to prevent abuse and fraud.
          <br/><br/>
          • Authorizing, granting, administering, monitoring and terminating access to or use of company
          systems, facilities, records, property and infrastructure
          <br/><br/>
          • Problem resolution, internal investigations, auditing, compliance, risk management and security
          <br/><br/><br/>
          <h4>2. Categories of Personal Data</h4>

          We do not collect more information than we need to fulfill our stated purposes and will not retain
          it for longer than is necessary.
          <br/><br/>
          LS ELECTRIC may collect and use the following information:
          <br/><br/>
          • Account creation information – Visitors who wants to register as a partner on our website should
          provide personal information such as name, email address, phone number, mobile number, ID, password,
          and company information such as company name, representative name to create an account.
          <br/><br/>
          • Contact information – We may collect contact information such as name, company name, e-mail
          address, phone number and mobile number to communicate with you in accordance with contract or any
          service you have requested or we believe you are interested in.
          <br/><br/>
          • Requests – We may collect your contact information and details of your comments, requests and
          questions to respond.
          <br/><br/>
          • Activity records – When you participate in activity such as training and events, we may collect
          your contact information, location data, and the information of program, training, certificate or
          activities in which you participated.
          <br/><br/>
          • Log information – When you use the online services, our servers automatically collect information
          that your browser or mobile app sends whenever you use online service. This log data may include
          your IP address, the date and time of your use, and cookie data.
          <br/><br/>
          • Cookies – We use Cookies which are small text files that are placed on your computer by websites
          that you visit. Cookies located on your computer do not contain your name but an IP address. In many
          case, after the user's session is cancelled the information contained in the cookies is no longer
          available to us. You can refuse to accept cookies by activating the setting on your browser which
          allows you to refuse the setting of cookies. However, if you refuse to accept cookies it may affect
          your experience of our website.
          For further information about Cookies including how to manage them, please visit
          www.allaboutcookies.org.
          <br/><br/>
          The information we ask for is necessary for the purposes described in this notice. It might affect
          the stated purposes if you don’t provide the personal information we ask for.
          <br/><br/><br/>
          <h4>3. Collection of Personal Data</h4>


          We collect your personal information directly from you but in certain circumstances we may also
          collect your contact information from third parties such as your employer or agents/partners when it
          is relevant to the stated purposes.
          <br/><br/><br/>
          <h4>4. Provision of Personal Data</h4>

          LS ELECTRIC, a global company headquartered in Korea, may share your personal information to
          headquarter, affiliates and/or subsidiaries only when it is relevant to the stated purposes.
          <br/><br/>
          We may share and disclose your information to third party such as logistics companies in accordance
          with contracts or any service you have requested, and in compliance with applicable law and legal
          process.
          <br/><br/>
          If you sell our products or provide technical services on behalf of us, we may disclose your contact
          information to our customers or make it public on our website so that customers can contact you.
          <br/><br/>
          We may share your information with third party service providers who provide products/services on
          our behalf. These service providers may, for example, process your information as a trade agency or
          provide IT services or technical service support on our behalf. We have contracts in place with
          those service providers. This means that they cannot do anything with your personal information
          unless we have instructed them to do it. They will not share your personal information with any
          organization apart from us. They will hold it securely and retain it for the period we instruct.
          <br/><br/>
          From time to time, we may disclose your information to our business partners such as distributors
          with your permit or expression of interest. LS ELECTRIC does not control our business partners’ use
          of the data that we collect, and their use of the information will be in accordance with their own
          privacy policies. Please check the privacy policies and notices of these business partners for any
          questions.
          <br/><br/>
          We may also share or disclose your information when required by law or to respond to legal process;
          to protect the rights, property or safety of individual; to protect our rights, property or safety;
          to maintain security.
          <br/><br/><br/>
          <h4>5. Transfers of Personal Data</h4>


          Your personal information we collect from you may be stored and managed in Korea. Korea has not
          sought nor received a finding of “adequacy” from the European Commission under Article 45 of the
          GDPR. We use Model Clauses which is approved by European Commission in order to adduce adequate
          safeguards with respect to the protection of privacy and fundamental rights and freedoms of
          individuals for the transfer of the personal information. If you wish to know more about
          international transfers of your personal information, you may contact our Data Protection Officer at
          privacy@ls-electric.com
          <br/><br/><br/>
          <h4>6. Retention of Personal Information</h4>

          We will retain your information only for as long as is necessary for the stated purposes. We will
          retain and use your information to the extent necessary to comply with our legal obligations (for
          example, if we are required to retain your information to comply with applicable tax/revenue laws),
          resolve disputes, and enforce our agreements.
          <br/><br/><br/>
          <h4>7. Data Subject Rights</h4>

          The European Union’s General Data Protection Regulation and other countries’ privacy laws provide
          certain rights for data subjects (“You”).
          <br/><br/>
          If you wish to confirm that we are processing your personal information, or to have access to the
          personal information we may have about you, please contact us at privacy@ls-electric.com
          <br/><br/>
          You may also request information about: the purpose of the processing; the categories of personal
          information concerned; who else might have received the data from us; what the source of the
          information was (if you didn’t provide it directly to us); and how long it will be stored. You have
          a right to correct (rectify) the record of your personal information maintained by us if it is
          inaccurate. You may request that we erase that data or cease processing it, subject to certain
          exceptions. You may obtain a copy of your personal information being processed in automated means,
          subject to certain exceptions.
          <br/><br/>
          When we process your personal information upon your consent, you can withdraw your consent at any
          time at privacy@ls-electric.com.
          <br/><br/>
          Reasonable access to your personal information will be provided at no cost to data subjects upon
          request made to us at privacy@ls-electric.com. If access cannot be provided within a reasonable time
          frame, we will provide you with a date when the information will be provided. If for some reason
          access is denied, we will provide an explanation as to why access has been denied.
          <br/><br/><br/>
          <h4>8. Complaints or Queries</h4>
          We take any complaints we receive about this very seriously. We encourage people to bring it to our
          attention if they think that our collection or use of information is unfair, misleading or
          inappropriate. We would also welcome any suggestions for improving our procedures.
          <br/><br/>
          We ask that you first submit any such complaints directly to us via privacy@ls-electric.com. If you
          aren't satisfied with our response or have concerns about how we process your personal information,
          you have a right to lodge a complaint with the appropriate data protection authority in your
          country.
          <br/>
          Effective date : April 18, 2022


        </div>
      </div>
      <button className={"Submit-Button"} onClick={() => this.closeTermsDetailPopup()}>
        Confirm
      </button>
    </div>;
  }

  renderTermsDetailPopupEn() {
    return <div>
      <button className={"close-button"} onClick={() => this.closeTermsDetailPopup()}>
        <img alt={"close_small_icon.svg"} src={SsqImages.common.icon.closeSmallIcon()}/>
      </button>
      <div className={"popup_tit"}>
        <button onClick={() => this.closeTermsDetailPopup()}>
          <img alt={"back_button.svg"} src={SsqImages.header.icon.backButton()}/>
        </button>
        {this.getTranslation("I18N_TERMS_DETAIL_POPUP_EVENT")}
      </div>
      <div className={"TermsDetail_content"}>
        <div className={"TermsDetail"} style={{overflowX: "hidden"}}>

          <h3>Introduction</h3>
          LS ELECTRIC Co. Ltd. and its subsidiaries (“LS ELECTRIC”, “We”, “Our” or “Us”) understand that you
          are aware of and care about your own privacy, and we take that seriously. This Privacy Notice is
          intended to provide you with information about what personal information LS ELECTRIC collects about
          you and how it is used.
          <br/><br/>

          If you have any queries about the process or how we handle your information, please contact our Data
          Protection Officer (DPO).
          <br/><br/>
          DPO’s contact information is as follows:
          <br/><br/>
          1st Floor, Tupolevlaan 48, 1119 NZ, Schiphol-Rijk
          privacy@ls-electric.com
          +31 020 654 1421
          <br/><br/><br/>

          <h4>1. Purpose of Personal Data Processing</h4>
          The legal basis for the processing of your personal information is your consent, legal obligation,
          performance of a contract and/or any other applicable legal basis, such as our legitimate interest
          in engaging in commerce, offering products and services including websites.
          <br/><br/>
          LS ELECTRIC may process your personal information for the following purposes:
          <br/><br/>
          Website Visitors
          <br/><br/>
          • Registration of Partner – When you register as a partner, we collect and use your personal
          information and company information that you provide to us to register you as a partner.
          <br/><br/>
          • Responding to your requests - When you contact us with any problem, comments or questions, we will
          collect and use your personal information to respond to your requests.
          <br/><br/>
          • Providing online services – We use cookies and other technologies to make website work, to work
          more efficiently as well as to recognize you when you return to our website.
          For further information about Cookies including how to manage them, please visit
          www.allaboutcookies.org.
          <br/><br/>
          • Preventing and investigating security issues and abuse – We collect and process your log
          information to keep the website secure and to prevent abuse and fraud.
          <br/><br/>
          Customers, Attendees, Contractors & Other External Partners
          <br/><br/>
          • Business Contact – When you or your company is in business relationship with us, we may process
          your personal information to communicate with you in accordance with the service or contract such as
          billing, invoicing, account management, payment, project management and other administrative
          matters.
          <br/><br/>
          • Responding to your requests - When you contact us with any problem, comments or questions, we will
          collect and use your personal information to respond to your requests.
          <br/><br/>
          • Communicating with you for marketing purposes – Upon your requests or expression of interests, we
          will send you an email to keep you updated on our products, services, promotions, news and events.
          You may manage your receipt of the email by clicking on the “unsubscribe” link located on the bottom
          of the emails.
          <br/><br/>
          • Training – We may process your personal information to provide you training courses.
          <br/><br/>
          • Technical Services – When you have problems with our products, we may process your contact
          information to provide you technical services. We may also disclose your contact information to
          third parties who provide technical service supports on behalf of us.
          <br/><br/>
          • Preventing and investigating security issues and abuse – We collect and process your log
          information to keep the website secure and to prevent abuse and fraud.
          <br/><br/>
          • Authorizing, granting, administering, monitoring and terminating access to or use of company
          systems, facilities, records, property and infrastructure
          <br/><br/>
          • Problem resolution, internal investigations, auditing, compliance, risk management and security
          <br/><br/><br/>
          <h4>2. Categories of Personal Data</h4>

          We do not collect more information than we need to fulfill our stated purposes and will not retain
          it for longer than is necessary.
          <br/><br/>
          LS ELECTRIC may collect and use the following information:
          <br/><br/>
          • Account creation information – Visitors who wants to register as a partner on our website should
          provide personal information such as name, email address, phone number, mobile number, ID, password,
          and company information such as company name, representative name to create an account.
          <br/><br/>
          • Contact information – We may collect contact information such as name, company name, e-mail
          address, phone number and mobile number to communicate with you in accordance with contract or any
          service you have requested or we believe you are interested in.
          <br/><br/>
          • Requests – We may collect your contact information and details of your comments, requests and
          questions to respond.
          <br/><br/>
          • Activity records – When you participate in activity such as training and events, we may collect
          your contact information, location data, and the information of program, training, certificate or
          activities in which you participated.
          <br/><br/>
          • Log information – When you use the online services, our servers automatically collect information
          that your browser or mobile app sends whenever you use online service. This log data may include
          your IP address, the date and time of your use, and cookie data.
          <br/><br/>
          • Cookies – We use Cookies which are small text files that are placed on your computer by websites
          that you visit. Cookies located on your computer do not contain your name but an IP address. In many
          case, after the user's session is cancelled the information contained in the cookies is no longer
          available to us. You can refuse to accept cookies by activating the setting on your browser which
          allows you to refuse the setting of cookies. However, if you refuse to accept cookies it may affect
          your experience of our website.
          For further information about Cookies including how to manage them, please visit
          www.allaboutcookies.org.
          <br/><br/>
          The information we ask for is necessary for the purposes described in this notice. It might affect
          the stated purposes if you don’t provide the personal information we ask for.
          <br/><br/><br/>
          <h4>3. Collection of Personal Data</h4>


          We collect your personal information directly from you but in certain circumstances we may also
          collect your contact information from third parties such as your employer or agents/partners when it
          is relevant to the stated purposes.
          <br/><br/><br/>
          <h4>4. Provision of Personal Data</h4>

          LS ELECTRIC, a global company headquartered in Korea, may share your personal information to
          headquarter, affiliates and/or subsidiaries only when it is relevant to the stated purposes.
          <br/><br/>
          We may share and disclose your information to third party such as logistics companies in accordance
          with contracts or any service you have requested, and in compliance with applicable law and legal
          process.
          <br/><br/>
          If you sell our products or provide technical services on behalf of us, we may disclose your contact
          information to our customers or make it public on our website so that customers can contact you.
          <br/><br/>
          We may share your information with third party service providers who provide products/services on
          our behalf. These service providers may, for example, process your information as a trade agency or
          provide IT services or technical service support on our behalf. We have contracts in place with
          those service providers. This means that they cannot do anything with your personal information
          unless we have instructed them to do it. They will not share your personal information with any
          organization apart from us. They will hold it securely and retain it for the period we instruct.
          <br/><br/>
          From time to time, we may disclose your information to our business partners such as distributors
          with your permit or expression of interest. LS ELECTRIC does not control our business partners’ use
          of the data that we collect, and their use of the information will be in accordance with their own
          privacy policies. Please check the privacy policies and notices of these business partners for any
          questions.
          <br/><br/>
          We may also share or disclose your information when required by law or to respond to legal process;
          to protect the rights, property or safety of individual; to protect our rights, property or safety;
          to maintain security.
          <br/><br/><br/>
          <h4>5. Transfers of Personal Data</h4>


          Your personal information we collect from you may be stored and managed in Korea. Korea has not
          sought nor received a finding of “adequacy” from the European Commission under Article 45 of the
          GDPR. We use Model Clauses which is approved by European Commission in order to adduce adequate
          safeguards with respect to the protection of privacy and fundamental rights and freedoms of
          individuals for the transfer of the personal information. If you wish to know more about
          international transfers of your personal information, you may contact our Data Protection Officer at
          privacy@ls-electric.com
          <br/><br/><br/>
          <h4>6. Retention of Personal Information</h4>

          We will retain your information only for as long as is necessary for the stated purposes. We will
          retain and use your information to the extent necessary to comply with our legal obligations (for
          example, if we are required to retain your information to comply with applicable tax/revenue laws),
          resolve disputes, and enforce our agreements.
          <br/><br/><br/>
          <h4>7. Data Subject Rights</h4>

          The European Union’s General Data Protection Regulation and other countries’ privacy laws provide
          certain rights for data subjects (“You”).
          <br/><br/>
          If you wish to confirm that we are processing your personal information, or to have access to the
          personal information we may have about you, please contact us at privacy@ls-electric.com
          <br/><br/>
          You may also request information about: the purpose of the processing; the categories of personal
          information concerned; who else might have received the data from us; what the source of the
          information was (if you didn’t provide it directly to us); and how long it will be stored. You have
          a right to correct (rectify) the record of your personal information maintained by us if it is
          inaccurate. You may request that we erase that data or cease processing it, subject to certain
          exceptions. You may obtain a copy of your personal information being processed in automated means,
          subject to certain exceptions.
          <br/><br/>
          When we process your personal information upon your consent, you can withdraw your consent at any
          time at privacy@ls-electric.com.
          <br/><br/>
          Reasonable access to your personal information will be provided at no cost to data subjects upon
          request made to us at privacy@ls-electric.com. If access cannot be provided within a reasonable time
          frame, we will provide you with a date when the information will be provided. If for some reason
          access is denied, we will provide an explanation as to why access has been denied.
          <br/><br/><br/>

          <h4>8. About Cookies and Similar Technologies</h4>


          Solution Square(LS), our service providers and third parties collect information, which may include personal information, from your browser,
          devices, or apps when you use our Platform using a variety of methods, such as cookies, pixel tags, identifiers for mobile devices, and
          other similar technologies. The information collected by these cookies and similar technologies may include your: IP address; unique cookie
          identifier and information obtained through cookies; unique device identifier and device type; domain, browser type and language, operating
          system, and system settings; country and time zone; previously visited websites; information about your interaction with our Platform such
          as click behavior, purchases and indicated preferences; and access times and referring URLs.<br/><br/>

          We use cookies and similar technologies to analyze and understand how you access, use, and interact with our Platform and our consumer’s
          preferences (such as country and language choices), as well as to assess, secure, protect, optimize, and improve the performance of our
          Platform. This enables us to provide services to our consumers and improve their online experience. We also use cookies and Google tags to
          obtain aggregate data about Platform traffic and interaction, to conduct analytics, identify trends and obtain statistics so that we can
          improve our Platform. We also use cookies and similar technologies to target advertising and content across our Platform and third-party
          sites and services.<br/><br/>

          Third-Party Technologies and Services.<br/>
          Some of the cookies and similar technologies we use are operated by third party companies. We use third-party services, such as Google
          Analytics and its advertising products, such as Google Signals and Google Ads, that may collect usage data (using cookies, pixel tags and
          similar tools) about our Platform to provide us with reports and metrics that help us to evaluate usage of our Platform, improve performance
          and user experiences, and serve relevant advertising to you. In some cases, the processing of this data is subject to these third parties’
          privacy policies (for example, for Google Analytics, see Google’s Privacy & Terms).<br/><br/>

          You may see our ads on other websites, such as our partner’s websites, because we use third-party ad services. Through these ad services, we
          can show you ads that may be tailored to your individual interests. These ad services may also track your online activities over time and
          across multiple websites and apps by collecting information through automated means. This data collection takes place both on our Platform
          and on third-party websites and apps that participate in these ad services.<br/><br/><br/>


          <h4>9. Complaints or Queries</h4>
          We take any complaints we receive about this very seriously. We encourage people to bring it to our
          attention if they think that our collection or use of information is unfair, misleading or
          inappropriate. We would also welcome any suggestions for improving our procedures.
          <br/><br/>
          We ask that you first submit any such complaints directly to us via privacy@ls-electric.com. If you
          aren't satisfied with our response or have concerns about how we process your personal information,
          you have a right to lodge a complaint with the appropriate data protection authority in your
          country.
          <br/>
          Effective date : April 22, 2023
          <br/><br/>
          {/*<span className={"span_cursor"} onClick={() => {
            this.setState({
              isShowRecentPrivacyTerms: false,
              isShowOriginalPrivacyTermsV0: true,
              isShowOriginalPrivacyTermsV1: false
            });
          }}>이전 개인정보 처리방침 보기 V1.0 (~2021.12.20)</span>
          <br/>*/}

        </div>
      </div>
      <button className={"Submit-Button"} onClick={() => this.closeTermsDetailPopup()}>
        Confirm
      </button>
    </div>;
  }

  render() {
    return <TermsPopup className={"TermsDetail_Wrap"}>
      {this.props.languageService.getLanguage() === LANG_KO_KR && <div className={"TermsDetail_Popup"}>
        {this.state.isShowRecentPrivacyTerms && this.renderTermsDetailPopup()}
        {this.state.isShowOriginalPrivacyTermsV0 && this.renderOriginalPrivateTermsDetailPopupV0()}
        {this.state.isShowOriginalPrivacyTermsV1 && this.renderOriginalPrivateTermsDetailPopupV1()}
      </div>}
      {[LANG_EN, LANG_EN_US].includes(this.props.languageService.getLanguage()) &&
        <div className={"TermsDetail_Popup"}>
          {this.state.isShowRecentPrivacyTerms && this.renderTermsDetailPopupEn()}
          {this.state.isShowOriginalPrivacyTermsV0 && this.renderOriginalPrivateTermsDetailPopupEnV0()}
        </div>}
    </TermsPopup>;
  }
}
