import React, {useEffect, useState} from "react";
import img_back_button from "images/header/back_button.svg";
import {ColGrp} from "uiutil/ColGrp";
import {
  CookieIntroAdvertising,
  CookieIntroAnalytical,
  CookieIntroEssential,
  CookieIntroFunctionality,
  CookieIntroItem
} from "cookie/common/CookieIntroItem";
import clsx from "clsx";

type Props = {
  setPopupPage: (p: number) => void;
  setDisplayCookiePopup: (p: boolean) => void
  trans: (p: string) => string
  introCookieType: string
}

export const CookieIntro = (props: Props) => {
  const [introItem, setIntroItem] = useState<CookieIntroItem[]>([]);
  const [displayIndex, setdisplayIndex] = useState<number[]>([]);

  const introEvent = (index: number) => {
    if (displayIndex.includes(index))
      setdisplayIndex(displayIndex.filter(ele => ele !== index));
    else
      setdisplayIndex([...displayIndex, index]);
  };

  useEffect(() => {
    if (props.introCookieType == 'ESSENTIAL') setIntroItem(CookieIntroEssential);
    if (props.introCookieType == 'ANALYTICAL') setIntroItem(CookieIntroAnalytical);
    if (props.introCookieType == 'FUNCTIONALITY') setIntroItem(CookieIntroFunctionality);
    if (props.introCookieType == 'ADVERTISING') setIntroItem(CookieIntroAdvertising);
  }, []);

  return (
    <div className={"cookie_popup_base cookie_intro"}>
      <div className={"popup_top"}>
        <div className={"popup_tit"}>
          <button onClick={() => props.setPopupPage(1)}>
            <img alt={"back_button.svg"} src={img_back_button}/>
          </button>
          {props.trans(`I18N_COOKIE_POPUP_${props.introCookieType}`)}
        </div>
      </div>
      <div className={"popup_middle_table_mode"}>
        <div className={"introduce table_mode"}>
          {props.trans('I18N_COOKIE_POPUP_CONFIGURE_PART')}
        </div>
        <div className={"cookie_table_wrap"}>
          <table className={"cookie_table"}>
            <ColGrp cols={[40, 60]}/>
            <thead>
            <tr>
              <td>{props.trans('I18N_COOKIE_POPUP_COOKIE_COMPANY')}</td>
              <td>{props.trans('I18N_COOKIE_POPUP_COOKIE_DOMAIN')}</td>
            </tr>
            </thead>
            <tbody>
            {introItem.map((ele, index: number) => {
              return <React.Fragment key={index}>
                <tr className={clsx("title", index % 2 == 0 ? 'stripe_table_color' : '', displayIndex.includes(index) ? 'intro_open' : '')}
                  onClick={() => introEvent(index)}>
                  <td>{ele.company}</td>
                  <td className={"domain"}>{ele.domain}</td>
                </tr>

                {displayIndex.includes(index) && <tr>
                  <td colSpan={2} className={"intro_table_color"}>
                    {ele.intro}

                    <div className={"more_info_link"}>
                      {!["", undefined].includes(ele.termsAndConditions) && <a href={ele.termsAndConditions}>Terms and Conditions</a>}
                      {!["", undefined].includes(ele.dataSubjectRights) && <a href={ele.dataSubjectRights}>Data Subject Rights</a>}
                      {!["", undefined].includes(ele.privacyPolicy) && <a href={ele.privacyPolicy}>Privacy Policy</a>}
                    </div>
                  </td>
                </tr>}
              </React.Fragment>;
            })}


            </tbody>
          </table>
        </div>
      </div>

      <div className={"popup_bottom"}>
        <button className={"popup_bottom_submit"} onClick={() => props.setPopupPage(1)}>
          {props.trans("I18N_COOKIE_POPUP_OK")}
        </button>
      </div>
    </div>
  );
};