import React, {Component} from 'react';
import {LanguageService} from "../language/LanguageService";

interface Props {
  languageService: LanguageService;
}

interface State {
}

export class ToolTip extends Component <Props, State> {
  getTranslation(key: string): string {
    this.props.languageService.setNamespace("I18N_NAMESPACE_HEADER");
    return this.props.languageService.getTranslationByKey(key) || "";
  }

  render() {

    return <div className={"login-Tooltip"}
      onClick={(e) => e.stopPropagation()}
    >
      <div>{this.getTranslation("I18N_TITLE_LAST_LOGIN")}</div>
    </div>;
  }
}

export default ToolTip; //다른 JS파일에서 불러올 수 있도록 내보내주기