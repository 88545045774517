import {useEffect, useState} from 'react';

const useDebounce = <T, >(value: T, delay): T => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export default useDebounce;


export const useDebounceDeps = <T, >(value: T, delay, def: T, ...deps): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(def);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [...deps, delay]);

  return debouncedValue;
};

