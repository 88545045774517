// @flow
import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {QnaPagePopupTop} from "communitypage/qna/popup/qna-popup-style";
import SimpleUserProfile from "communitypage/common/SimpleUserProfile";
import {docutil} from "util/docutil";
import clsx from "clsx";
import axios from "axios";
import {IfLoginInfo} from "models/IfLoginInfo";
import useOutsideClick from "hooks/useOutsideClick";
import {DocCommentInfo, IfDocCommentInfo} from "models/IfSsqDataDocCommentEntity";
import {ViewFileDownload} from "communitypage/qna/popup/ViewFileDownload";
import {IAnswerDocument} from "communitypage/qna/popup/qna-popup-common";

type Props = {
  document: IAnswerDocument,
  loginInfo: IfLoginInfo,
  trans,
  handleClick: (cmd: string, data?) => void,
  handleLoginPopup,
  updateDownloadCount: (fileId) => void,
  postUserDownloadByFile: (file, doc) => void,
  displayLoginPopup: Function,
  handleCmd: (cmd: string | 'show-login', data?) => void,
  handleAnswerCmd: (cmd: string | 'refresh-comment', data?) => void,
};

export const AnswerComment = ({document, ...props}: Props) => {
  const ref = useRef();
  const refVal = useRef({running: false});

  const [commentInput, setCommentInput] = useState('');
  const [commentUpdate, setCommentUpdate] = useState('');
  const [comments, setComments] = useState([] as Array<IfDocCommentInfo>);

  const saveCommentRef = useRef<HTMLTextAreaElement>(null);
  const modifyCommentRef = useRef<HTMLTextAreaElement>(null);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (saveCommentRef.current) {
      //높이 초기화
      saveCommentRef.current.style.height = "auto";
      if (window.innerWidth > 764) {
        saveCommentRef.current.style.height = saveCommentRef.current.scrollHeight / 10 + "rem";
      } else {
        saveCommentRef.current.style.height = (saveCommentRef.current.scrollHeight / 10) * 1.09 + "rem";
      }
    }
  });

  useEffect(() => {
    if (modifyCommentRef.current) {
      //높이 초기화
      modifyCommentRef.current.style.height = "2.2rem";
      if (window.innerWidth > 764) {
        modifyCommentRef.current.style.height = modifyCommentRef.current.scrollHeight / 10 + "rem";
      } else {
        modifyCommentRef.current.style.height = (modifyCommentRef.current.scrollHeight / 10) * 1.09 + "rem";
      }
    }
  });

  useEffect(() => {
    if (window.innerWidth < 1199)
      setIsMobile(true);
    else
      setIsMobile(false);
    window.scrollTo(0, 0);
  }, []);

  useOutsideClick(ref, () => {
    let found = false;
    comments.forEach(ele => {
      if (ele.reportReview || ele.modifyReview) {
        ele.reportReview = false;
        ele.modifyReview = false;
        found = true;
      }
    });
    if (found) setComments(comments.slice());
  }, [comments]);

  const handleInputKeyDown = (e) => {
    isMobile ? commentMobileKey(e) : saveCommentPcKey(e);
  };

  //모바일 엔터키
  const commentMobileKey = (e) => {
    if (e.key === 'Enter') {
      if (e.nativeEvent.isComposing === false) {
        if (!props.loginInfo?.userId) {
          props.handleLoginPopup();
        }
      }
    }
  };

  //Pc 엔터키 및 Shift+Enter
  const saveCommentPcKey = (e) => {
    if (e.key === 'Enter' && e.shiftKey) {
      return;
    } else if (e.key === 'Enter' && !e.shiftKey) {
      console.log("----답변 댓글 저장");
      if (e.nativeEvent.isComposing === false) {
        if (!props.loginInfo?.userId) {
          props.handleLoginPopup();
        } else {// 추가
          if (commentInput == "") {
            alert(props.trans("I18N_COMMUNITY_NO_COMMENT"));
          } else {
            saveComment();
          }
        }
      }
    }
    //textarea 자동 줄바꿈 막음
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const handleClick = (cmd: string | 'update-comment', data?) => {

    if (cmd === 'update-comment') {
      let ele = data as IfDocCommentInfo;
      const commentCnts = commentUpdate || ele.commentCnts;
      axios.put('/api/guest/ssqData/doccomment/update', {
        commentCnts, docCommentId: ele.docCommentId
      }).then(res => {
        console.log("update-comment", res.data);
        ele.showInput = false;
        ele.commentCnts = commentCnts;
        setComments(comments.slice());
        setCommentUpdate("");
      }).catch((E) => {
        console.error("E", E);
      });
    } else {
      console.error("error unknown ", cmd);
    }
  };

  const saveComment = () => {
    if (commentInput == "") {
      alert(props.trans("I18N_COMMUNITY_NO_COMMENT"));
      return;
    }
    if (refVal.current.running) return; // 중복저장 방지.
    refVal.current.running = true;

    let comment = new DocCommentInfo();
    comment.docId = document.id;
    comment.commentCnts = commentInput;
    comment.writerNm = props.loginInfo.name;
    comment.writerId = props.loginInfo.userId;
    comment.writerNcnm = props.loginInfo.nickname;
    comment.employ = props.loginInfo.loginType === "WELS";

    /*두번 클릭 방지*/
    setCommentInput("");
    console.log("comment ----", commentInput);

    axios.post('/api/guest/ssqData/doccomment', comment).then(res => {
      console.log("save comment result ", res.data);

      let newCommentList = comments.slice();
      newCommentList.push(res.data);

      /*댓글 리스트 출력 후 작성한 댓글 사라짐*/
      setComments(newCommentList);
      setCommentInput("");
      props.handleAnswerCmd('refresh-comment');
    }).catch((e) => {
      console.error("E", e);
    }).finally(() => refVal.current.running = false);

  };


  const deleteReview = (commentId) => {
    console.log("--댓글 삭제", commentId);
    axios.delete(`/api/guest/ssqData/doccomment/${commentId}`).then(res => {
      console.log("--댓글 삭제 ok : ", res.data);
      setComments(comments.filter((ele) => ele.docCommentId !== commentId));
      props.handleAnswerCmd('refresh-comment');
    }).catch(error => {
      console.error("--댓글 삭제 error : ", error);
    });
  };

  useEffect(() => {
    const docId = document.id;
    axios.get('/api/guest/ssqData/doccomment/list', {params: {docId}}).then(r => setComments(r.data));
  }, [document.id]);

  const clearComments = () => {
    comments.forEach(ele => {
      ele.reportReview = false;
      ele.modifyReview = false;
      ele.showInput = false;
    });
  };
  return <>
    {document?.versions?.length > 0 && document.versions[0].files?.length > 0 && <ViewFileDownload ver={document.versions[0]} doc={document}/>}
    <QnaPagePopupTop>
      {(comments || []).map((ele) =>
        <div key={ele.docCommentId} className="review-content">
          <div className="review-content-item">
            <div className="right">
              <ul className="comment-box">
                <SimpleUserProfile userId={ele.writerId} displayLoginPopup={props.displayLoginPopup}/>
              </ul>
              <span className="comment-content" style={{display: ele.showInput ? 'none' : ''}}>{ele.commentCnts}</span>

              <ul className="comment-box">
                <li className="c-time">{ele.showInput == true ? '' : docutil.diffTime((ele.updtDt))}</li>
              </ul>

              {props.loginInfo.userId === ele.writerId ?
                <div className="list-modify-body">
                  {!ele.showInput ?
                    <button className="btn-list-modify" onClick={() => {
                      clearComments();
                      ele.modifyReview = true;
                      setComments(comments.slice());
                    }}></button> : ele.modifyReview = false
                  }
                  {!ele.showInput ?
                    ele.modifyReview && <div className={clsx("modify-item-body")} ref={ref}
                      style={{display: ele.modifyReview ? 'inline-block' : ''}}>

                      <button className={clsx("modify-btn modify")} onClick={() => {
                        clearComments();
                        ele.showInput = true;
                        ele.modifyReview = false;
                        setComments(comments.slice());
                        setCommentUpdate("");
                      }}>{props.trans('I18N_COMMUNITY_MODIFY')}</button>
                      <button className="modify-btn modify" onClick={() => deleteReview(ele.docCommentId)}>{props.trans("I18N_COMMUNITY_DELETE")}
                      </button>
                    </div>
                    : ele.modifyReview = false
                  }
                </div>
                :
                props.loginInfo.allowAdminKoKr || props.loginInfo.allowAdminEn || props.loginInfo.allowAdminEnUs === true ?
                  <div className="list-modify-body">
                    {!ele.showInput ?
                      <button className="btn-list-modify" onClick={() => {
                        clearComments();
                        ele.modifyReview = true;
                        setComments(comments.slice());
                        setCommentUpdate("");
                      }}>
                      </button>
                      : ele.modifyReview = false
                    }
                    {!ele.showInput ? ele.modifyReview &&
                      <div className={clsx("modify-item-body")} style={{display: ele.modifyReview ? 'inline-block' : ''}} ref={ref}>
                        <button className={clsx("modify-btn modify")}
                          onClick={() => {
                            clearComments();
                            ele.reportReview = false;
                            ele.modifyReview = false;
                            setComments(comments.slice());
                            props.handleClick('reportReview', ele);
                          }}>{props.trans("I18N_COMMUNITY_COMMENT_REPORT")}
                        </button>
                        <button className="modify-btn modify" onClick={() => deleteReview(ele.docCommentId)}>
                          {props.trans("I18N_COMMUNITY_DELETE")}
                        </button>
                      </div>
                      : ele.modifyReview = false
                    }
                  </div>
                  :
                  <div className="list-modify-body">
                    <button className="btn-list-modify" onClick={() => {
                      clearComments();
                      ele.reportReview = true;
                      setComments(comments.slice());
                    }}></button>

                    {ele.reportReview && <div className={clsx("modify-item-body")} ref={ref}
                      style={{display: ele.reportReview ? 'inline-block' : ''}}>

                      <button className={clsx("modify-btn notify modify")}
                        onClick={() => {
                          clearComments();
                          ele.reportReview = false;
                          ele.modifyReview = false;
                          setComments(comments.slice());
                          props.handleClick('reportReview', ele);
                        }}>{props.trans("I18N_COMMUNITY_COMMENT_REPORT")}
                      </button>
                    </div>}
                  </div>
              }
            </div>
          </div>

          {ele.showInput ? <div className={clsx("review-input", isMobile ? "on" : "modify")} onClick={() => null}>
              <textarea rows={1} name="" placeholder={props.trans("I18N_COMMUNITY_COMMENT_MODIFY_PLACEHOLDER")}
                ref={modifyCommentRef}
                onKeyDown={(e) => {
                  if (isMobile === true) {
                    commentMobileKey(e);
                  } else {
                    if (e.key === 'Enter' && e.shiftKey) {
                      return;
                    } else if (e.key === 'Enter' && !e.shiftKey) {
                      handleClick('update-comment', ele);
                    }
                    if (e.key === 'Enter') {
                      e.preventDefault();
                    }
                  }
                }}
                onChange={(e) => {
                  console.log("--editor value : ", e.target.value);
                  setCommentUpdate(e.target.value || '');
                }}
                defaultValue={ele.commentCnts}
              />

              <button className="review-del"
                onClick={() => {
                  if (confirm(props.trans("I18N_COMMUNITY_COMMENT_MODIFY_CONFIRM"))) {
                    ele.showInput = false;
                    ele.modifyReview = false;
                    setComments(comments.slice());
                    console.log("수정 취소 성공");
                    setCommentUpdate('');
                  } else {
                    console.log("수정유지");
                  }
                }}
              ></button>

              <button className="review-enter" onClick={() => handleClick('update-comment', ele)}
              ></button>
            </div>
            : null
          }
          <div className="mobile-black" style={{display: ele.modifyReview || ele.reportReview ? 'block' : ''}}></div>
        </div>)}
    </QnaPagePopupTop>


    {/*댓글 작성*/}
    <div className="review-input">
      <textarea rows={1} name={String(document.id)} placeholder={props.trans("I18N_COMMUNITY_COMMENT_PLACEHOLDER")}
        ref={saveCommentRef}
        onKeyDown={(e) => handleInputKeyDown(e)}
        onChange={(e) => setCommentInput(e.target.value)}
        value={commentInput}
      />
      <button className={clsx("ent", isMobile ? "on" : "")}
        onClick={() => {
          if (!props.loginInfo?.userId) {
            props.handleCmd('show-login');
          } else {
            saveComment();
          }
        }}></button>
    </div>
  </>;
};