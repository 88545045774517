import React from "react";
import styled from "styled-components/macro";
import {fontFamilyBold, fontFamilyMedium, fontFamilyRegular} from "style/fonts";
import {admin_popup} from "style/CommonStyledComponents";


export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-bottom: 1.6rem;
  z-index: 11;

  .batch-button, .post-button {
    position: relative;
    z-index: 12;
    width: 7rem;
    height: 4rem !important;
    white-space: nowrap;
  }

  .delete-button {
    width: 5.4rem !important;
  }

  .delete-button.width-auto {
    width: auto !important;
    padding: 0 1rem;
  }

  .tempo-button-wrap {
    display: flex;
    width: auto;
    height: 4rem;
    position: relative;
    background: #7783a1;
    border-radius: .4rem;
    margin-left: .8rem;

    .batch-button {
      width: auto;
      margin-left: 0;
      padding: 0 .5rem;
    }

    .tempo-count-button {
      color: white;
      width: 4rem;
      height: 4rem;
      line-height: 4rem;
      border-radius: 0 .4rem .4rem 0;
      background: #596278;
      ${fontFamilyBold};
      font-size: 1.2rem;
      text-align: center;
      z-index: 12;
      margin-left: 0;

      :hover {
        background: #4F5870 !important;
      }

      :active {
        background: #444D64 !important;
      }
    }
  }

  .post-button {
    margin-left: .8rem;
  }

  @media screen and (max-width: 764px) {
    padding: 0 1.6rem;
  }
`;

export const VersionArea = styled.div`
  position: relative;
  margin-top: 1.6rem;

  .version_top {
    display: flex;
  }

  .not-use-version {
    margin-top: 1px;
    padding: 2.4rem 3.2rem;
    background: #fff;
  }

  .file-item {
    font-size: 1.2rem;

    .remove_btn {
      margin-right: .8rem;
      vertical-align: middle;
    }

    .file-name {
      background: rgba(199, 214, 234, 0.5);
      border: 1px solid #C7D6EA;
      border-radius: 4px;
      width: 32.8rem;
      height: 3.2rem;
      line-height: 3.2rem;
      color: #407DD9;
      ${fontFamilyBold};
      display: inline-block;
      padding: 0 1.2rem;
      vertical-align: middle;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .file-size {
      color: #7783A1;
      margin-left: 1.3rem;
      display: inline-block;
      width: 53px;
    }

    + .file-item {
      margin-top: 1.2rem
    }

    :last-of-type {
      margin-bottom: 1.2rem
    }

    .file-radio {
      display: inline-block;
      margin-left: .6rem;
    }
  }

  .file_add_btn {
    color: #407EDB;
    ${fontFamilyBold};
    font-size: 1.2rem;
    line-height: 16px;

    img {
      margin-right: .8rem;
      vertical-align: top
    }
  }
`;


export const RequestApprovalPopup = styled.div`
  ${admin_popup};
  height: 49rem;

  .popup-content {

    > div:first-of-type {
      margin-bottom: 2rem;

      .input-error-text {
        bottom: -20px
      }
    }
  }

  select {
    width: 100%;
    height: 3.2rem;
  }

  textarea {
    width: 100%;
    height: 18rem;
    border: 1px solid #C7D6EA;
    border-radius: 4px;
    padding: .8rem;

    ${fontFamilyRegular}
    ::placeholder {
      color: #7783A1;
      font-size: 1rem;
    }
  }

  .input_wrap {
    position: relative;

    .input-error {
      position: relative;
    }

    .input-error-text {
      color: #E6003E;
      font-size: 1.2rem;
      position: absolute;
      bottom: -15px;
      left: 0;
    }
  }

  p {
    margin-bottom: 1.2rem;
  }

  .sub_tit {
    margin-bottom: .4rem;
  }
`;

export const TemporaryDataPopup = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 11;
  ${fontFamilyRegular};

  position: absolute;
  top: 4rem;
  right: 0;

  width: 24rem;

  .tit {
    color: #7783A1;
    font-size: 1rem;
    line-height: 23px;
    padding: 0 1rem;
    border-bottom: solid 1px #EEF1F6;
  }

  > div {
    border-bottom: solid 1px #EEF1F6;
    padding: .8rem 1.6rem;
    cursor: pointer;

    .Tempo_item_tit_wrap {
      display: flex;
      align-items: center;
    }

    .Tempo_item_tit {
      font-size: 1.2rem;
      ${fontFamilyMedium};
      line-height: 2.4rem;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
    }

    .Tempo_item_new {
      font-size: .8rem;
      margin-left: .4rem;
      color: #407EDA;
    }

    .Tempo_item_time {
      display: block;
      font-size: 1rem;
      color: #7783A1;
      line-height: 1.6rem;
    }

    :hover {
      background: rgba(64, 126, 219, 0.1) !important
    }

    :active {
      background: rgba(64, 126, 219, 0.3) !important
    }
  }

  .txt {
    font-size: 1rem;
    line-height: 23px;
    padding: 0 1rem;
    color: #7783A1;
    ${fontFamilyRegular};
  }
`;
