import React from "react";
import img_close_small_icon from "images/common/close_small_icon.svg";
import svcs from "services";
import {LANG_EN, LANG_EN_US, LANG_KO_KR} from "language/langutil";

type Props = {
  setPopupPage: (p: number) => void;
  setDisplayCookiePopup: (p: boolean) => void
  trans: (p: string) => string
  cookieTotalUpdate: (p: boolean) => void;
}

export const CookiePolicy = (props: Props) => {
  const lang = svcs.svcLang.lang();
  const introKorean = () => {
    return (
      <div className={"introduce"}>
        Solution Square는 당사 및 제3자가 제공하는 쿠키를 사용합니다. 일부 쿠키는 웹사이트 운영에 필수적인 반면, 다른 쿠키는 언제든지 사용자가 조정할 수 있으며, 특히 웹사이트의 성능을 이해하고, 소셜 미디어 기능을 제공하며, 관련 콘텐츠 및
        광고로 더 나은 경험을 제공할 수 있도록 하는 쿠키가 있습니다. 이 사이트를 사용함으로써 귀하는 쿠키를 수락하거나 거부할 수 있습니다. 자세한 내용은 <span className={"privacy_policy_link"}
        onClick={() => props.setPopupPage(3)}>쿠키
        정책</span>을 참조하시기 바랍니다.
      </div>
    );
  };

  const introEnglish = () => {
    return (
      <div className={"introduce"}>
        Our website uses cookies which are necessary for running the website and for providing the services you request. For more information,
        please read our <span className={"privacy_policy_link"} onClick={() => props.setPopupPage(3)}>Cookie Policy</span>.
      </div>
    );
  };

  return (
    <div className={"cookie_popup_base"}>
      <button className={"close-button"}
        onClick={() => props.setDisplayCookiePopup(false)}
      >
        <img alt={"close_small_icon.svg"} src={img_close_small_icon}/>
      </button>


      <div className={"popup_top"}>
        <div className={"popup_tit"}>
          {props.trans('I18N_COOKIE_POPUP_POLICY')}
        </div>
      </div>

      <div className={"popup_policy_total"}>


        <div className={"popup_policy_middle"}>
          {lang === LANG_KO_KR && introKorean()}
          {[LANG_EN_US, LANG_EN].includes(lang) && introEnglish()}


        </div>
        <button className={"policy_configure_button"} onClick={() => props.setPopupPage(1)}>
          {props.trans('I18N_COOKIE_POPUP_CONFIGURE')}
        </button>
        <button className={"policy_submit_button refuse"}
          onClick={() => props.cookieTotalUpdate(false)}>
          {props.trans('I18N_COOKIE_POPUP_REFUSE_ALL')}
        </button>
        <button className={"policy_submit_button accept"}
          onClick={() => props.cookieTotalUpdate(true)}>
          {props.trans('I18N_COOKIE_POPUP_ACCEPT_ALL')}
        </button>
      </div>
    </div>
  );
};