// @flow
import * as React from 'react';
import {useEffect, useState} from 'react';
import {Route} from "react-router-dom";
import {AssetAppBar} from "asset/layout/appbar/AssetAppBar";
import {AssetLeftMenu} from "asset/layout/leftmenu/AssetLeftMenu";
import styled from "styled-components";
import {AssetStyle} from "asset/style/AssetStyled";
import {LoadingLayer} from "ctls/modal/LoadingLayer";
import {useVerifyLoginInfo} from "asset/apis/useVerifyLoginInfo";
import {AssetToastMgr} from "asset/layout/toast/AssetToastMgr";
import AssetPopupBase from "popup/comp/asset/AssetPopupBase";
import {useConfirmPopup} from "asset/cmpns/popup/common/ConfirmModal";
import {useSelector} from 'react-redux';
import {IfReduxAssetToastState, useAssetToast} from "store/reducers/reducerAssetToast";
import {i18ntrans} from "language/i18nkey";
import axios from "axios";
import {useLoginInfo} from "hooks/useLoginInfo";
import {setRefConfirm, setRefWarning} from "store/reducers/reducerAsset";
import {useWarningPopup} from "asset/cmpns/popup/common/WarningModal";
import {AssetContext, IfAssetContext, IfAssetContextWrap} from "./ctx/AssetContext";

export const AssetLayout = (props: { exact?: boolean, path: string | string[], children }) => {
  const isLogin = useVerifyLoginInfo();
  const loginInfo = useLoginInfo();
  const [confirm, ConfirmPopup] = useConfirmPopup();
  const [warning, WarningPopup] = useWarningPopup();
  const toast = useAssetToast();
  const toastState = useSelector((state: any): IfReduxAssetToastState => state.assettoast);
  const t = i18ntrans.asset;

  useEffect(() => {
    if (!isLogin) return;
    Promise.all([axios.get('/api/member/asset/device/reAuthRequiredList', {params: {userId: loginInfo.userId}}),
      axios.get('/api/member/asset/devshare/reAuthRequiredList', {params: {userId: loginInfo.userId}})])
      .then(([resMyDevice, resSharedDevice]) => {
        // console.log('resMyDevice', resMyDevice, 'resSharedDevice', resSharedDevice);
        if (resMyDevice.data?.length + resSharedDevice.data?.length > 0) {
          showAuthSnackBar();
        }
      });
  }, [isLogin]);

  const showAuthSnackBar = () => {
    if (toastState.list.length === 0) {
      toast({
        msgs: [t("I18N_AST_MONITOR_NOT_AUTH_DEVICE")], type: "clear",
        action: "Setting"
      });
    }
  };

  useEffect(() => {
    setRefConfirm(confirm);
  }, [confirm]);

  useEffect(() => {
    setRefWarning(warning);
  }, [warning]);

  const [ctx, setCtx] = useState<IfAssetContext>({asset: i18ntrans.asset, tasset: i18ntrans.tasset});
  const [wrap] = useState<IfAssetContextWrap>({ctx, setCtx});

  return <React.Fragment>
    <AssetContext.Provider value={wrap}>
      <ConfirmPopup/>
      <WarningPopup/>
      <Route path={props.path}
        exact={!!props.exact}
        render={() => {
          return <React.Suspense fallback={<LoadingLayer visible={true}/>}>
            <AssetStyle/>
            {isLogin && <div>
              <AssetLeftMenuWrap>
                <AssetLeftMenu/>
              </AssetLeftMenuWrap>
              <AssetPageWrap id={"asset-page-wrap"}>
                <AssetAppBar/>
                <AssetContent id={"asset-content"}>
                  {React.Children.map(props.children, child => React.cloneElement(child))}
                </AssetContent>
              </AssetPageWrap>
              <AssetToastMgr/>
              <AssetPopupBase/>
            </div>}
          </React.Suspense>;
        }}/>
    </AssetContext.Provider>
  </React.Fragment>;
};
const AssetLeftMenuWrap = styled.div`
  z-index: 9;
  position: relative;
`;
const AssetPageWrap = styled.div`
  margin-left: 92px;
  z-index: 1;
  position: relative;

  .overlay {
    left: 92px
  }

  @media screen and (max-width: 1199px) {
    margin-left: 0;
  }
`;

const AssetContent = styled.div`
  width: 100%;
  position: relative;
`;
