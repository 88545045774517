// @flow
import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {AnswerWrite} from "communitypage/qna/popup/AnswerWrite";
import {QnaPageFooter, QnaPagePopupTitleTag, QnaPagePopupTop, SolutionResult} from "communitypage/qna/popup/qna-popup-style";
import SimpleUserProfile from "communitypage/common/SimpleUserProfile";
import {docutil} from "util/docutil";
import clsx from "clsx";
import img_accept_check from "images/community/board_check.svg";
import {AnswerComment} from "communitypage/qna/popup/AnswerComment";
import {IAnswerDocument} from "communitypage/qna/popup/qna-popup-common";
import {IfLoginInfo} from "models/IfLoginInfo";
import svcs from "services";
import {IDocGoodBadInfo, ISsqDataDocument} from "repositories/SsqDataRepository";
import axios from "axios";
import useOutsideClick from "hooks/useOutsideClick";
import {PopReport} from "docwrite/popup/PopReport";
import CommunityPostInfo from "communitypage/common/CommunityPostInfo";
import CommunityPostHoverText from "communitypage/common/CommunityPostHoverText";
import {IfDocCommentInfo} from "models/IfSsqDataDocCommentEntity";
import {CommentWrap} from "style/CommonStyledComponents";
import {roleutil} from "util/roleutil";
import {parseSanitize} from "util/commonUtils";

type Props = {
  question: ISsqDataDocument,
  answer: IAnswerDocument,
  trans,
  loginInfo: IfLoginInfo,
  handleCmd: (cmd: string | 'refresh-doc-detail' | 'setstate-answers' | 'refresh-answers') => void,
  displayLoginPopup: Function;
  commentList: Array<IfDocCommentInfo>;
};
export const PartnerAnswerViewItem = (props: Props) => {
  const ref = useRef();
  const popReport = useRef(null as PopReport);
  const answer = props.answer;
  const [editMode, setEditMode] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const [moreHover, setMoreHover] = useState(false);
  const [goodHover, setGoodHover] = useState(false);
  const [badHover, setBadHover] = useState(false);

  const [rndKr, setRndKr] = useState(false);
  const [rndEn, setRndEn] = useState(false);
  const [rndEnUs, setRndEnUs] = useState(false);

  const [comments, setComments] = useState([] as Array<IfDocCommentInfo>);

  useOutsideClick(ref, () => {
    setShowMenu(false);
  }, [showMenu]);
  const handleCmd = (cmd: string) => {
    if (cmd === 'refresh-answers') {
      props.handleCmd('refresh-answers');
    } else {
      console.error("unknown cmd ", cmd);
    }
  };
  const handleClick = (cmd: string | 'refresh-answers' | 'delete-answer' | 'clickFinish', data?) => {
    if (cmd === 'report' || cmd === 'reportReview') {
      if (!props.loginInfo.userId) {
        props.handleCmd("show-login");
        return;
      }
      popReport.current.show({docId: props.question.id, reportType: 'DOC'})
        .then(res => {
          console.log("res", res);
        }).catch(e => {
        console.error("E", e);
      });
      console.log("--answer report", data);
    } else if (cmd === 'clickFinish') {
      const answer: IAnswerDocument = data;
      console.log("채택하기. or 채택취소 ", answer.finished);
      axios.patch(`/api/guest/ssqData/document/answerfinish?documentId=${answer.id}&finished=${!answer.finished}`).then((res) => {
        console.log("채택하기. ");
        props.handleCmd('refresh-doc-detail');
      }).catch((err) => {
        console.log("에러", err);
      });
      console.log('CLICK FINISH BUTTON');
    } else if (cmd === 'delete-answer') {
      if (confirm(props.trans("I18N_COMMUNITY_CONFIRM_DELETE"))) {
        svcs.repoSsqData.deleteCommunityDocument(answer.id).then(r => {
          if (r === 200) {
            alert(props.trans("I18N_COMMUNITY_CONFIRM_DELETE_SUCCESS"));
            //this.getAnswerList(this.state.questionDetail.id);
            props.handleCmd('refresh-answers');
          } else {
            alert(props.trans("I18N_COMMUNITY_ERROR_DELETE"));
          }
        });
      }
    } else {
      console.error("unknown cmd ", cmd, " data is ", data);
    }
  };

  /* 추천, 비추천 생성 */
  const createDocGoodBad = async (data: IDocGoodBadInfo) => {
    const response = await axios.post('/api/guest/ssqData/docgoodbad/create', data);
    console.log("추천 생성 완료", response, data.goodBad);
    return response.data;
  };

  /* 추천, 비추천 삭제 */
  const deleteDocGoodBad = async (data: IDocGoodBadInfo) => {
    const response = await axios.post('/api/guest/ssqData/docgoodbad/delete', data);
    console.log("추천 삭제 완료", response, data.goodBad);
    return response.data;
  };

  const updateUserGoodBad = async (type: string, document: IAnswerDocument, requestData) => {
    switch (type) {
      case "GOOD":

        if (!document.isUserGood) {
          const count = await createDocGoodBad(requestData);
          console.log("--답변 Good 생성", type, count);
          document.isUserGood = true;
          document.isUserBad = false;
          document.userGoodCnt = count.goodCount;
          document.userBadCnt = count.badCount;
          console.log("--답변 Good 생성 결과 : ", type, count, document);
        } else {
          const count = await deleteDocGoodBad(requestData);
          console.log("--답변 Good 삭제", type, count);
          document.isUserGood = false;
          document.isUserBad = false;
          document.userGoodCnt = count.goodCount;
          document.userBadCnt = count.badCount;
          console.log("--답변 Good 삭제 결과 : ", type, count, document);
        }
        return document;


      case "BAD":

        if (!document.isUserBad) {
          const count = await createDocGoodBad(requestData);
          console.log("--답변 Bad 생성", type, count);
          document.isUserGood = false;
          document.isUserBad = true;
          document.userGoodCnt = count.goodCount;
          document.userBadCnt = count.badCount;
          console.log("--답변 Bad 생성 결과 : ", type, count, document);
        } else {
          const count = await deleteDocGoodBad(requestData);
          console.log("--답변 Bad 삭제", type, count);
          document.isUserGood = false;
          document.isUserBad = false;
          document.userGoodCnt = count.goodCount;
          document.userBadCnt = count.badCount;
          console.log("--답변 Bad 삭제 결과 : ", type, count, document);
        }
        return document;
    }
  };

  const requestUserGoodBad = async (document, type: string) => {
    if (!props.loginInfo?.userId) {
      props.handleCmd("show-login");
      return console.log("로그인되지 않음");
    }

    const requestData: IDocGoodBadInfo = {
      docId: document.id,
      userId: props.loginInfo?.userId,
      docType: document.docType,
      docTitle: document.docTitle,
      goodBad: type,
      updtDt: new Date(0)
    };

    updateUserGoodBad(type, answer, requestData).then((data) => {
      console.log("--updateUserGoodBad response : ", data);
      props.handleCmd('setstate-answers');
    });

  };

  //전체 새로고침 시 불러옴
  useEffect(() => {
    const docId = answer.id;
    axios.get('/api/guest/ssqData/doccomment/list', {params: {docId}}).then(r => {
      setComments(r.data);
    });
  }, [answer.id]);

  //댓글 추가 및 삭제 시 댓글 갯수 카운트 용
  const getCommentList = async (questionId: number) => {
    await axios.get('/api/guest/ssqData/doccomment/list', {
      params: {docId: questionId}
    }).then((res) => {
      setComments(res?.data || []);
    });
  };

  const handleAnswerCmd = (cmd: string | 'refresh-comment', data?) => {
    if (cmd === 'refresh-comment') {
      getCommentList(answer.id);
    }
  };

  useEffect(() => {
    checkAdminRnd();
  }, []);

  const checkAdminRnd = () => {
    if (props.loginInfo.allowAdminKoKr && roleutil.hasRole("RND", props.loginInfo.userRoles)
      && props.question.exposeSiteLanguage == "ko-KR" && props.loginInfo.allowAdminManage) {
      return setRndKr(true);
    } else if (props.loginInfo.allowAdminEn && roleutil.hasRole("RND", props.loginInfo.userRoles)
      && props.question.exposeSiteLanguage == "en" && props.loginInfo.allowAdminManage) {
      return setRndEn(true);
    } else if (props.loginInfo.allowAdminEnUs && roleutil.hasRole("RND", props.loginInfo.userRoles)
      && props.question.exposeSiteLanguage == "en-US" && props.loginInfo.allowAdminManage) {
      return setRndEnUs(true);
    }
    return false;
  };

  return (
    <div className="content">
      {editMode ?/* 수정모드. - 자체에서 처리하도록 할 것. */
        <AnswerWrite
          displayLoginPopup={props.displayLoginPopup}
          question={props.question}
          handleCmd={handleCmd}
          answer={props.answer}
          loginInfo={props.loginInfo} trans={props.trans}
          editMode={true}
          cancelAnswer={() => setEditMode(false)}
          clearModify={() => {
            setEditMode(false);
          }}
        /> : /* 보기 모드. */
        <QnaPagePopupTop>
          <div className="view-header">
            <div className="view-header-item">
              <div className="v-header-left">
                <div className="qna-check">
                  <div style={{display: !answer.finished ? "none" : ""}} className="icon-qna a">
                      <span style={{display: svcs.svcLang.getLanguage() == "en" ? "none" : ""}}>
                        {props.trans('I18N_TITLE_COMMUNITY_BOARD_DOCTYPE_QNA_FINISH')}
                      </span>
                  </div>
                  <div className="icon-qna red-a"></div>
                </div>
                <CommentWrap className="view-comment-wrap">
                  <SimpleUserProfile userId={answer.writerId} displayLoginPopup={props.displayLoginPopup}/>
                  <CommunityPostInfo type={"qna-a"} time={answer.updateTime} ssqDocument={answer} commentList={comments}/>
                </CommentWrap>
              </div>
              <div className="v-header-right">
                <ul className="comment-box">
                  {props.loginInfo?.userId && (answer.docType === 'QNA_A' || answer.docType === "RND_QNA_A") &&
                    <div style={{display: props.loginInfo?.userId == props.question.writerId ? '' : 'none'}}>
                      {props.question.finished ?
                        <button
                          style={{display: props.question.finished && answer.finished ? '' : 'none'}}
                          className={clsx("finish-button", !answer.finished ? "on" : "")}
                          onClick={(e) => handleClick('clickFinish', answer)}><img src={img_accept_check} alt=""/>
                          <div>{answer.finished ?
                            props.trans('I18N_TITLE_COMMUNITY_QNA_QUESTION_STATE_CANCEL_ACCEPT') :
                            props.trans('I18N_TITLE_COMMUNITY_QNA_QUESTION_STATE_ACCEPT')
                          }</div>
                        </button> : <button
                          style={{display: ''}}
                          className={clsx("finish-button on")}
                          onClick={(e) => handleClick('clickFinish', answer)}>
                          <img src={img_accept_check} alt=""/>
                          <div>{answer.finished ?
                            props.trans('I18N_TITLE_COMMUNITY_QNA_QUESTION_STATE_CANCEL_ACCEPT') :
                            props.trans('I18N_TITLE_COMMUNITY_QNA_QUESTION_STATE_ACCEPT')
                          }</div>
                        </button>
                      }
                    </div>
                  }
                  <li>
                    <button className={clsx("view-like", answer.isUserGood ? 'on' : '')}
                      onMouseEnter={(e) => {
                        e.stopPropagation();
                        setGoodHover(true);
                      }}
                      onMouseLeave={(e) => {
                        e.stopPropagation();
                        setGoodHover(false);
                      }}
                      onClick={(e) => requestUserGoodBad(answer, "GOOD")}>
                      {answer.userGoodCnt}</button>
                    {goodHover && <CommunityPostHoverText type={'good-a'}/>}
                  </li>


                  <li>
                    <button className={clsx("view-bad", answer.isUserBad ? 'on' : '')}
                      onMouseEnter={(e) => {
                        e.stopPropagation();
                        setBadHover(true);
                      }}
                      onMouseLeave={(e) => {
                        e.stopPropagation();
                        setBadHover(false);
                      }}
                      onClick={(e) => requestUserGoodBad(answer, "BAD")}>
                      {answer.userBadCnt}</button>
                    {badHover && <CommunityPostHoverText type={'bad-a'}/>}
                  </li>

                </ul>
                <button className={clsx("btn-list-modify")} ref={ref}
                  onMouseEnter={(e) => {
                    e.stopPropagation();
                    setMoreHover(true);
                  }}
                  onMouseLeave={(e) => {
                    e.stopPropagation();
                    setMoreHover(false);
                  }}
                  onClick={() => /*props.modifyAnswerOn(answer.id, answer.writerId)*/ setShowMenu(!showMenu)}>
                  {moreHover && <CommunityPostHoverText type={'more-a'}/>}
                </button>

              </div>
            </div>

            {props.loginInfo?.userId === answer.writerId ?
              <div className={clsx("modify-item-body")}
                ref={ref}
                style={{display: showMenu ? 'block' : ''}}>
                <button className={clsx("modify-btn modify")}
                  onClick={() => {
                    //handleClick('modifyAnswer', {mode: 'modify', answer: answer});
                    console.log("modify");
                    setEditMode(true);
                    setShowMenu(false);
                  }}
                >{props.trans('I18N_COMMUNITY_MODIFY')}
                </button>
                <button className="modify-btn modify"
                  onClick={() => {
                    //handleClick('modifyAnswer', {mode: 'delete', id: answer.id});
                    handleClick('delete-answer');
                    setShowMenu(false);
                  }}
                > {props.trans('I18N_COMMUNITY_DELETE')}
                </button>
              </div>
              :
              ((rndKr || rndEn || rndEnUs == true) && answer.docType == "RND_QNA_A") ||
              (!["RND_QNA_A"].includes(answer.docType) && (props.loginInfo.allowAdminKoKr || props.loginInfo.allowAdminEn || props.loginInfo.allowAdminEnUs === true)) ?
                <div className={clsx("modify-item-body")}
                  ref={ref}
                  style={{display: showMenu ? 'block' : ''}}>
                  <button className={clsx("modify-btn modify")}
                    onClick={() => {
                      handleClick('report');
                      setShowMenu(false);
                    }}
                  >{props.trans('I18N_COMMUNITY_COMMENT_REPORT')}
                  </button>
                  <button className={clsx("modify-btn modify")}
                    onClick={() => {
                      setEditMode(true);
                      setShowMenu(false);
                    }}
                  >{props.trans('I18N_COMMUNITY_MODIFY')}
                  </button>
                </div>
                :
                <div className={clsx("modify-item-body")}
                  ref={ref}
                  style={{display: showMenu ? 'block' : ''}}>
                  <button className={clsx("modify-btn notify modify")}
                    onClick={() => {
                      handleClick('report');
                      setShowMenu(false);
                    }}
                  >{props.trans('I18N_COMMUNITY_COMMENT_REPORT')}
                  </button>
                </div>
            }
          </div>
          <div className="mobile-black" style={{display: showMenu ? 'block' : 'none'}}></div>
        </QnaPagePopupTop>}

      {/*상단 제목 태그들*/}
      {editMode ? <></> :
        <QnaPagePopupTitleTag>
          <div className="view-content">

            {/*모바일에서 답변단 작성자 정보 출력*/}
            <ul className="comment-box solution-profile qna-view">
              <SimpleUserProfile userId={answer.writerId}
                displayLoginPopup={props.displayLoginPopup}/>
              <CommunityPostInfo type={"qna-a"} time={answer.updateTime} ssqDocument={answer} commentList={comments}/>
            </ul>
            {/*확인 필요 끝*/}

            <SolutionResult>
              <div className={"SsqEditor ck-content"}>{parseSanitize(answer.description)}</div>
              <div><p style={{color: "rgb(42, 44, 47)", fontSize: 15, margin: 0}}><br/></p></div>
            </SolutionResult>
          </div>
          {/*하단 다운로드, 댓글 출력*/}
          {editMode ? <></> :
            <QnaPageFooter>
              <AnswerComment document={answer} loginInfo={props.loginInfo}
                displayLoginPopup={props.displayLoginPopup}
                trans={props.trans} handleClick={handleClick}
                handleLoginPopup={() => props.handleCmd("show-login")}
                updateDownloadCount={async (fileId) => await svcs.repoSsqData.addDownloadCount([fileId])}
                postUserDownloadByFile={(file, doc) => docutil.postUserDownloadByFile(file, doc)}
                handleCmd={props.handleCmd} handleAnswerCmd={handleAnswerCmd}
              />
            </QnaPageFooter>}
        </QnaPagePopupTitleTag>}

      <PopReport ref={popReport} trans={null} ssqDataRepository={null} languageService={null}/>
    </div>
  );
};