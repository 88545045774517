import React, {Component} from "react";
import {SsqImages} from "../images/SsqImages";
import {LanguageService} from "../language/LanguageService";
import styled from "styled-components";
import {fontFamilyBold, fontFamilyMedium, fontFamilyRegular} from "../style/fonts";
import {LANG_EN, LANG_EN_US, LANG_KO_KR} from "language/langutil";

interface Props {
  languageService: LanguageService;
  showTermsDetailPopupFunc: Function;
}

interface State {
  isShowRecentTerms: boolean;
  isShowOriginalTermsV0: boolean;
  isShowOriginalTermsV1: boolean;
}


export class TermsDetailPopup extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isShowRecentTerms: true,
      isShowOriginalTermsV0: false,
      isShowOriginalTermsV1: false
    };
  }

  getTranslation(key: string): string {
    this.props.languageService.setNamespace("I18N_NAMESPACE_HEADER");
    return this.props.languageService.getTranslationByKey(key) || "";
  }

  closeTermsDetailPopup() {
    this.props.showTermsDetailPopupFunc(false);
  }

  renderOriginalTermsDetailPopupV0() {
    return <div>
      <button className={"close-button"} onClick={() => this.closeTermsDetailPopup()}>
        <img alt={"close_small_icon.svg"} src={SsqImages.common.icon.closeSmallIcon()}/>
      </button>
      <div className={"popup_tit"}>
        <button onClick={() => this.closeTermsDetailPopup()}>
          <img alt={"back_button.svg"} src={SsqImages.header.icon.backButton()}/>
        </button>
        {this.getTranslation("I18N_TERMS_DETAIL_POPUP_TERMS")}
      </div>
      <div className={"TermsDetail_content"}>
        <div className={"TermsDetail"}>
          <span>개정일 2021.12.20</span>

          <h3>[제 1장 총칙]</h3>

          <h4>제 1조 목적</h4>

          이 약관은 LS ELECTRIC(이하 "회사") Solution Square 웹페이지에서 제공하는 모든 서비스의 이용조건 및 절차에 관한 사항과 기타 필요한 사항을
          전기통신사업법 및 동법 시행령이 정하는 대로 준수하고 규정함을 목적으로 합니다.<br/>
          <br/><br/>

          <h4>제 2조 정의</h4>

          "이용자"라 함은 웹페이지에 접속하여 이 약관에 따라 웹페이지가 제공하는 서비스를 받는 회원 및 비회원을 말합니다.<br/>
          "회원"이라 함은 웹페이지에 개인정보를 제공하여 회원등록을 한 자로서, 웹페이지의 정보를 지속적으로 제공 받으며, 웹페이지가 제공하는 서비스를 계속적으로 이용할 수 있는
          자를 말합니다.<br/>
          "비회원"이라 함은 회원에 가입하지 않고 Solution Square 웹페이지에서 제공하는 서비스를 이용하는 자를 말합니다.<br/>
          <br/><br/>

          <h4>제 3조 약관의 효력과 변경</h4>

          이 약관은 회사 Solution Square 웹페이지의 초기 서비스 화면에 이용자에게 공시함으로써 효력이 발생합니다.<br/>
          회사는 사정 변경의 경우와 영업상 중요 사유가 있을 때 약관의 규제 등에 관한 법률 등 관련법을 위반하지 않는 범위에서 이 약관을 변경할 수 있으며, 이 경우에는 적용일자
          및 개정사유, 변경되는 내용을 명시하여 현행 약관과 함께 초기 서비스 화면에 그 적용일자 7일전부터 적용일자 전일까지 공지합니다.<br/>
          <br/><br/>

          <h4>제 4조 약관 외 준칙</h4>

          이 약관에 명시되지 않은 사항이 관계 법령에 규정되어 있을 경우에는 그 규정에 따릅니다.<br/>

          <br/><br/><br/>

          <h3>[제 2장 회원 가입 및 서비스 이용]</h3>

          <h4>제 1조 서비스 이용 계약의 성립</h4>

          회사 Solution Square 웹페이지상 서비스 이용 계약은 이용자가 회원 가입에 따른 서비스 이용 신청에 대한 회사의 이용 승낙과 이용자의 이 약관에 동의한다는
          의사표시로 성립됩니다.
          이용자가 회원에 가입하여 회사 Solution Square 웹페이지상 서비스를 이용하고자 하는 경우, 회원은 회사에서 요청하는 개인 신상정보를 제공해야 합니다.
          이용자의 회사 Solution Square 웹페이지상 서비스 이용신청에 대하여 회사가 승낙한 경우, 회사는 회원 이메일과 기타 회사가 필요하다고 인정하는 내용을 이용자에게
          통지합니다.<br/><br/>

          회사는 다음에 해당하는 서비스 이용 신청에 대하여는 이를 승낙하지 아니합니다.<br/><br/>

          1. 다른 사람의 명의를 사용하여 신청하였을 때<br/>
          2. 본인의 실명으로 신청하지 않았을 때<br/>
          3. 서비스 이용 계약 신청서의 내용을 허위로 기재하였을 때<br/>
          4. 사회의 안녕과 질서 혹은 미풍양속을 저해할 목적으로 신청하였을 때<br/>

          <br/><br/>
          <h4>제 2조 서비스 이용 및 제한</h4>

          회사 Solution Square 웹페이지상 서비스 이용은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간을 원칙으로 합니다.<br/>
          전항의 서비스 이용시간은 정보통신 시스템 등 정기점검/교체 등 Solution Square가 필요한 경우, 회원에게 사전 통지한 후, 제한할 수 있습니다.<br/>
          서비스 중단의 경우에는 회사는 회원이 회사 Solution Square 웹페이지에 제출한 전자우편 주소로 개별 통지하거나, 불특정다수 회원에 대하여는 1주일 이상 게시판에
          게시함으로써 개별 통지에 갈음할 수 있습니다.

          <br/><br/><br/>

          <h3>[제 3장 의무]</h3>

          <h4>제 1조 회사의 의무</h4>

          회사는 특별한 사정이 없는 한 회원이 신청한 서비스 제공 개시일에 서비스를 이용할 수 있도록 합니다.<br/>
          회사는 이 약관에서 정한 바에 따라 계속적, 안정적으로 서비스를 제공할 의무가 있습니다.<br/>
          회사는 회원으로부터 소정의 절차에 의해 제기되는 의견에 대해서는 적절한 절차를 거쳐 처리하며, 처리시 일정 기간이 소요될 경우 회원에게 그 사유와 처리 일정을 알려주어야
          합니다.<br/>
          회사는 회원의 정보를 철저히 보안 유지하며, 양질의 서비스를 운영하거나 개선하는 경우 또는 제품소개 등 회사 내부 목적으로 이용하는 데만 사용하고, 이외의 다른 목적으로 타
          기관 및 제3자에게 양도하지 않습니다.<br/>

          <br/><br/>
          <h4>제 2조 회원의 의무</h4>

          회원 이메일과 비밀번호에 관한 모든 관리의 책임은 회원에게 있습니다.<br/>
          회원 이메일과 비밀번호를 제3자에게 이용하게 해서는 안됩니다.<br/>
          회원 이메일과 비밀번호를 도난당 하거나 제3자가 사용하고 있음을 인지하는 경우에는 회원은 반드시 회사에 그 사실을 통보해야 합니다.<br/>
          회원은 이 약관 및 관계 법령에서 규정한 사항을 준수하여야 합니다.<br/><br/>

          이용자는 개인 정보 입력 시 다음 행위를 하여서는 안됩니다.<br/><br/>

          1. 신청 또는 변경 시 허위내용의 등록<br/>
          2. 회사 Solution Square 웹페이지가 정한 정보 이외의 정보(컴퓨터 프로그램 등)의 송신 또는 게시<br/>
          3. 회사 Solution Square 웹페이지 기타 제3자의 저작권 등 지적재산권에 대한 침해<br/>
          4. 회사 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위<br/>
          5. 외설 또는 폭력적인 메시지, 화상, 음성 기타 공서양속에 반하는 정보를 공개 또는 게시하는 행위<br/>

          <br/><br/><br/>

          <h3>[제 4장 서비스 이용 계약 해지 및 이용 제한]</h3>

          <h4>제 1조 서비스 이용 계약 해지 및 이용 제한</h4>

          회원이 서비스 이용 계약을 해지하고자 하는 때에는 회원 정보 페이지에서 본인이 직접 계약을 해지할 수 있습니다.<br/><br/>

          회사는 회원이 다음 사항에 해당하는 행위를 하였을 경우, 사전 통지 없이 서비스 이용 계약을 해지하거나 또는 상당한 기간을 정하여 서비스 이용을 중지할 수
          있습니다.<br/><br/>

          1. 공공 질서 및 미풍 양속에 반하는 경우<br/>
          2. 범죄적 행위에 관련되는 경우<br/>
          3. 국익 또는 사회적 공익을 저해할 목적으로 서비스 이용을 계획 또는 실행할 경우<br/>
          4. 타인의 ID(이메일)을 도용한 경우<br/>
          5. 타인의 명예를 손상시키거나 불이익을 주는 경우<br/>
          6. 같은 사용자가 다른 ID(이메일)로 이중 등록을 한 경우<br/>
          7. 서비스에 위해를 가하는 등 건전한 이용을 저해하는 경우<br/>
          8. 기타 관련 법령이나 회사가 정한 이용조건에 위배되는 경우<br/>
          <br/><br/>

          <h4>제 2조 서비스 이용 제한의 해제 절차</h4>

          회사는 긴급하게 서비스 이용을 중지해야 할 필요가 있다고 인정하는 경우에 서비스 이용을 제한할 수 있습니다.<br/>
          회사는 서비스 이용중지 기간 중에 그 이용중지 사유가 해소된 것이 확인된 경우에 한하여 이용중지 조치를 즉시 해제합니다.
        </div>
      </div>
      <button className={"Submit-Button"} onClick={() => this.closeTermsDetailPopup()}>
        확인
      </button>
    </div>;
  }

  renderOriginalTermsDetailPopupV1() {
    return <div>
      <button className={"close-button"} onClick={() => this.closeTermsDetailPopup()}>
        <img alt={"close_small_icon.svg"} src={SsqImages.common.icon.closeSmallIcon()}/>
      </button>
      <div className={"popup_tit"}>
        <button onClick={() => this.closeTermsDetailPopup()}>
          <img alt={"back_button.svg"} src={SsqImages.header.icon.backButton()}/>
        </button>
        {this.getTranslation("I18N_TERMS_DETAIL_POPUP_TERMS")}
      </div>
      <div className={"TermsDetail_content"}>
        <div className={"TermsDetail"}>
          <span>개정일 2022.04.18</span>

          <h3>[제 1장 총칙]</h3>

          <h4>제 1조 목적</h4>

          이 약관은 LS ELECTRIC Solution Square 홈페이지에서 제공하는 모든 서비스의 이용조건 및 절차에 관한 사항과
          기타 필요한 사항을 관련 법령이 정하는 대로 준수하고 규정함을 목적으로 합니다.<br/>
          <br/><br/>

          <h4>제 2조 정의</h4>
          이 약관에서 사용하는 용어의 정의는 다음과 같습니다. <br/>

          1. “회사”라 함은 LS ELECTRIC 주식회사를 말합니다.<br/>
          2. “서비스”라 함은 홈페이지에서 회사가 제공하는 모든 자동화 엔지니어링 관련 정보 제공 서비스 및 일반 회원을 대상으로 다양한 정보의 제공을 중개하거나 해당 정보
          제공자와의 연결을 매개하는 플랫폼 서비스 등의 관련 서비스를 말합니다.<br/>
          3. “홈페이지”라 함은 회사가 서비스를 이용자에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 운영하는 온라인 홈페이지로서 Solution Square를
          말합니다.<br/>
          4. "이용자"라 함은 홈페이지에 접속하여 이 약관에 따라 홈페이지가 제공하는 서비스를 받는 일반회원 및 비회원을 말합니다.<br/>
          5. "일반 회원"이라 함은 홈페이지에 개인정보 및/또는 기업정보를 제공하여 회원등록을 한 자로서, 권한에 따라 홈페이지 일부의 정보를 지속적으로 제공 받으며, 홈페이지가
          제공하는 서비스의 일부를 계속적으로 이용할 수 있는 자를 말합니다.<br/>
          6. "비회원"이라 함은 회원에 가입하지 않고 회사 홈페이지에서 제공하는 서비스를 이용하는 자를 말합니다.<br/>

          <br/><br/>

          <h4>제 3조 약관의 효력과 변경</h4>

          1. 이 약관은 회사 홈페이지의 초기 서비스 화면에 이용자에게 공시함으로써 효력이 발생합니다. <br/>
          2. 회사는 사정 변경의 경우와 영업상 중요 사유가 있을 때 약관의 규제 등에 관한 법률 등 관련법을 위반하지 않는 범위에서 이 약관을 변경할 수 있으며, 이 경우에는
          적용일자 및 개정 사유, 변경되는 내용을 명시하여 현행 약관과 함께 초기 서비스 화면에 그 적용일자 7일전부터 적용일자 전일까지 공지합니다. 단 이용자에게 불리하거나 중대한
          사항의 변경은 30일 전부터 공지합니다. <br/>
          3. 제2항의 약관 변경의 경우에 회사는 필요한 경우 기존 이용자에게는 서면 또는 전화, 모사전송, 전자우편(e-mail) 등의 방법을 이용하여 개별 통지할 수
          있습니다. <br/>
          4. 제2항에 의거, 변경된 약관은 제1항과 같은 방법으로 효력이 발생합니다. <br/>
          5. 이용자가 변경 약관에 동의하지 않는 경우 회사는 이용자와의 계약을 해지하거나 이용자의 서비스 이용을 제한할 수 있습니다. <br/>

          <br/><br/>

          <h4>제 4조 약관 외 준칙</h4>

          이 약관에 명시되지 않은 사항이 관계 법령에 규정되어 있을 경우에는 그 규정에 따릅니다.<br/>

          <br/><br/>

          <h3>[제 2장 회원 가입 및 서비스 이용]</h3>

          <h4>제 1조 서비스 이용 계약의 성립</h4>

          1. 홈페이지 상 서비스 이용 계약은 이용자가 회원 가입에 따른 서비스 이용 신청에 대한 회사의 이용 승낙과 이용자의 이 약관에 동의한다는 의사표시로 성립됩니다.<br/>
          2. 이용자가 회원에 가입하여 홈페이지 상 서비스를 이용하고자 하는 경우, 회원은 회사에서 요청하는 개인 신상정보 및/또는 기업정보를 제공해야 합니다.<br/>
          3. 회사는 다음 각 호에 해당하는 서비스 이용 신청에 대하여는 이를 승낙하지 아니할 수 있습니다.<br/>
          가. 다른 사람의 개인정보 또는 다른 기업의 정보를 사용하여 신청하였을 때<br/>
          나. 본인의 실명으로 신청하지 않았을 때<br/>
          다. 허위의 정보를 기재하였을 때<br/>
          라. 사회의 안녕과 질서 혹은 미풍양속을 저해할 목적으로 신청하였을 때<br/>
          마. 가입신청자가 제4장 제1조 제5항에 의하여 이전에 회원자격을 상실한 적이 있는 때<br/>
          <br/><br/>

          <h4>제 2조 서비스 이용 및 제한</h4>

          1. 홈페이지 상 서비스 이용은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간을 원칙으로 합니다. <br/>
          2. 회사는 다음 각호의 사유가 있는 경우 서비스의 전부 또는 일부를 중지하거나 제한, 변경 또는 종료할 수 있습니다. <br/>
          가. 정보통신 시스템 등 정기점검/교체, 수리 등 회사가 필요한 경우 <br/>
          나. 통신 두절, 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우 <br/>
          다. 경영상 이유 등으로 인하여 서비스를 제공할 수 없는 부득이한 사정이 발생한 경우 <br/>
          라. 기타 천재지변, 국가비상사태, 각 국가의 법령/정책 상의 규제 또는 제한 등 불가항력적 사유가 있는 경우 <br/>

          3. 제2항에 의한 서비스 중지, 변경, 종료 등의 경우에는 회사는 제1장 제3조 제3항에서 정한 방법 기타 적절한 방법으로 이용자에게 통지하거나,
          불특정다수 회원에 대하여는 1주일 이상 게시판에 게시함으로써 개별 통지에 갈음할 수 있습니다. 단, 회사가 통제할 수 없는 사유 또는 긴급한 사유
          등으로 인하여 이용자에 대한 공지 또는 통지가 불가능한 경우에는 그러하지 아니합니다. <br/>

          <br/><br/><br/>

          <h3>[제 3장 의무]</h3>

          <h4>제 1조 회사의 의무</h4>

          1. 회사는 특별한 사정이 없는 한 회원이 신청한 서비스 제공 개시일에 서비스를 이용할 수 있도록 합니다.<br/>
          2. 회사는 이 약관에서 정한 바에 따라 계속적, 안정적으로 서비스를 제공할 의무가 있습니다.<br/>
          3. 회사는 회원으로부터 소정의 절차에 의해 제기되는 의견에 대해서는 적절한 절차를 거쳐 처리하며, 처리시 일정 기간이 소요될 경우 회원에게 그 사유와 처리 일정을
          알려주어야 합니다.<br/>
          4. 회사는 회원의 정보를 철저히 보안 유지하며, 양질의 서비스를 운영하거나 개선하는 경우 또는 일반 회원간의 중개 목적, 기타 회원이 동의한 목적에만 사용하고, 이외의
          다른 목적으로 타 기관 및 제3자에게 제공, 공개 또는 양도하지 않습니다.<br/>
          5. 일반 회원이 서비스 이용을 위하여 회사에 제공하거나 게시를 요청한 회원의 기업 및 사업 정보 등은 회사가 홈페이지에 대신 게시할 수 있습니다(단, 회원이 회사에
          제공하거나 게시를 요청한 정보는 회원의 사업∙영업∙기술상 비밀정보가 아닌 것으로 간주됩니다).
          이 때 회사는 회원이 제공하거나 게시를 요청한 정보를 게시에 필요한 범위 내에서 가공할 수 있을 뿐 “있는 그대로” 게시합니다.<br/>

          <br/><br/>
          <h4>제 2조 회원의 의무</h4>

          1. ID와 비밀번호에 관한 모든 관리의 책임은 회원에게 있습니다.<br/>
          2. 회원은 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.<br/>
          3. 회원은 ID 및 비밀번호를 도난 당하거나 제3자가 사용하고 있음을 인지하는 경우에는 반드시 회사에 그 사실을 통보하고 회사의 안내가 있는 경우에는 그에 따라야 합니다.<br/>
          4. 회원은 이 약관 및 관계 법령에서 규정한 사항을 준수하여야 합니다.<br/>
          5. 이용자는 제4장 제1조 제5항 각호의 행위를 하여서는 안됩니다.<br/>
          6. 회원은 회원가입 시 등록한 사항에 변경이 있는 경우, 상당한 기간 이내에 회원정보 수정 기타 적절한 방법으로 회사에 그 변경사항을 알려야 합니다.<br/>
          7. 일반 회원이 회사에 제공하거나 게시를 요청한 정보 또는 일반 회원이 직접 홈페이지에 게시한 정보에 대하여 회원은 그 내용에 대한 정확성, 신뢰성 및 적법성을
          담보합니다.<br/>
          8. 회원은 홈페이지 내 자신의 개인정보나 사업∙영업∙기술상 비밀 정보를 게시할 수 없으며, 회원이 홈페이지에 자신의 개인정보나 사업∙영업∙기술상 비밀 정보를 게시,
          노출하여 발생하는 일체의 결과, 손해에 대해 회사는 면책됩니다.<br/>
          9. 이용자는 개인 정보 입력 시 다음 행위를 하여서는 안됩니다.<br/>
          가. 신청 또는 변경 시 허위내용의 등록<br/>
          나. 회사 Solution Square 웹 페이지가 정한 정보 이외의 정보(컴퓨터 프로그램 등)의 송신 또는 게시<br/>
          다. 회사 Solution Square 웹 페이지 기타 제3자의 저작권 등 지적재산권에 대한 침해<br/>
          라. 회사 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위<br/>
          마. 외설 또는 폭력적인 메시지, 화상, 음성 기타 공서양속에 반하는 정보를 공개 또는 게시하는 행위<br/>

          <br/><br/><br/>

          <h3>[제 4장 서비스 이용 계약 해지 및 이용 제한]</h3>

          <h4>제 1조 서비스 이용 계약 해지 및 이용 제한</h4>

          1. 회원이 서비스 이용 계약을 해지하고자 하는 때에는 홈페이지 회원 정보 페이지에서 본인이 직접 서비스 이용 계약을 해지할 수 있습니다.<br/>
          2. 회원이 1년의 기간 동안 가입 정보를 통한 이용기록(로그인 등)이 없을 경우 회사는 휴면 상태 전환 30일 전 이 약관 제1장 제3조 제3항에 의한 방법에 따라 해당
          내용에 대해서 회원에게 통지하고, 회원의 개인정보(이용자ID, 비밀번호, 이름, 연락처 등)를 별도 관리하며, 그 외의 회원정보는 모두 폐기합니다.<br/>
          3. 휴면 계정 전환 후 3년의 기간 동안 본인인증 서비스를 통해 휴면 계정 해지를 신청하지 않을 시, 회원의 개인정보는 모두 파기됩니다.<br/>
          4. 회원 가입 및 서비스 이용 계약 해지 여부는 기존의 ID, 비밀번호로 로그인이 되지 않으면 해지된 것이며, 한번 해지된 ID는 기존 이용자라도 사용할 수 없음을
          알려드립니다.<br/>
          5. 회사는 회원에게 다음과 같은 경우 또는 이에 해당하는 사실이 존재한다고 의심할만한 합리적 이유가 있는 경우 서비스의 전부 또는 일부의 이용을 중지 또는 제한하거나 본
          조 제6항에 따라 회원자격을 상실할 수 있습니다. 또한 회사는 해당 이용자에게 회사가 입은 손해의 배상을 청구할 수 있습니다.<br/>
          가. 제2장 제1조 제3항 각 호의 사유가 발견된 경우<br/>
          나. 타인의 ID 또는 비밀정보를 도용한 경우<br/>
          다. 같은 이용자가 다른 ID로 이중 등록을 한 경우<br/>
          라. 이용자가 허위의 정보를 제공하여 회사로 하여금 게시하도록 하거나 직접 게시한 경우<br/>
          마. 불법코드, 악성코드 등 허가되지 않은 불법 프로그램을 송신하거나 게시한 경우<br/>
          바. 이용자가 회사를 포함한 제3자의 지식재산권을 침해하는 행위를 하는 경우<br/>
          사. 이용자가 서비스에 위해를 가하거나 회사의 기술적 보호조치를 회피 또는 무력화하는 행위를 하는 경우<br/>
          아. 외설 또는 폭력적인 메시지, 화상, 음성 기타 공서양속에 반하는 정보 또는 불법 정보를 공개 또는 게시하는 경우<br/>
          자. 국익 또는 사회적 공익을 저해할 목적으로 서비스 이용을 계획 또는 실행할 경우<br/>
          차. 서비스 이용 중 지득, 취득한 제3자의 정보를 서비스 이용 목적 외 유용하거나 정보 주체의 동의 없이 다른 제3자에 공개, 제공, 유출하는 경우<br/>
          카. 이용자가 이 약관 위반행위 및 회사를 포함한 제3자의 명예를 훼손하거나 업무를 방해하는 등 법령위반 행위, 범죄행위 또는 미풍양속에 반하는 행위를 하는 경우<br/>
          타. 기타 회사가 정한 이용조건에 위배되거나 서비스 이용의 중지, 제한 또는 회원자격 박탈이 필요하다고 회사가 합리적으로 판단하는 경우<br/>
          6. 전항에 따라 회원자격을 상실하는 경우 회사는 이용자에게 사전에 사유를 통지하고, 회원자격 말소 전 30일 이상의 기간을 두어 이의신청 기회를 부여합니다. 그 이의가
          정당하다고 회사가 인정하는 경우 회사는 즉시 서비스의 이용을 재개합니다.<br/>
          7. 일부 회원 게시물에 대한 삭제는 고객 요청 또는 서비스내 관리 기능을 통해서 삭제될 수 있으며, 이는 고객 센터 요청을 통해서 이뤄집니다.<br/>

          <br/><br/>

          <h4>제 2조 서비스 이용 제한의 해제 절차</h4>

          1. 회사는 회원의 서비스 이용 제한을 하고자 하는 경우에는 이 약관에 달리 정하지 않는 한 그 사유,
          일시 및 기간을 정하여 제1장 제1조 제3항에 기재한 방법을 이용하여 해당 회원 또는 대리인에게 통지합니다.
          다만, 회사가 긴급하게 이용을 중지해야 할 필요가 있다고 인정하는 경우에는 전항의 과정 없이 서비스 이용을 제한할 수 있습니다.<br/>
          2. 전항의 규정에 의하여 서비스 이용 중지 또는 제한을 통지 받은 회원 또는 그 대리인은 서비스 이용 중지 또는 제한에 대하여 이의신청을 할 수 있습니다.<br/>
          3. 회사는 서비스 이용 중지∙제한 기간 중에 그 이용 중지∙제한 사유가 해소된 것이 확인된 경우에 한하여 이용 중지∙제한 조치를 즉시 해제합니다.<br/>

          <br/><br/>

          <h3>[제5장 책임의 한계]</h3>

          <h4>제1조 회사 책임의 한계</h4><br/>
          1. 회사는 홈페이지를 통해 자동화 엔지니어링 기술 관련 다양한 정보의 제공을 중개하거나 해당 정보 제공자와의 연결을 매개하고 있습니다. 회사는 정보중개자로서 이미 생산된
          정보를 "있는 그대로" 중개 또는 매개합니다.<br/>
          2. 홈페이지 내 일반 회원을 대상으로 회사가 게시하는 정보는 이용자(회원)가 작성∙제공한 게시물 또는 이용자(회원)가 표시한 상품 정보를 “있는 그대로” 게시한 것으로서
          이는 해당 정보의 제공자가 독립적으로 작성 또는 제공한 정보이기 때문에, 일반 회원이 직접 작성, 게시한 정보와 같이, 홈페이지 서비스에서 중개 또는 매개된 정보라도
          원칙적으로 그 정보의 정확성, 신뢰성 및 적법성은 해당 정보를 제공 혹은 제작한 당사자가 담보하고 이에 따른 책임을 부담합니다. 따라서 이러한 정보의 신뢰에 대한 결정은
          전적으로 이용자가 하여야 합니다.<br/>
          3. 회사는 홈페이지 서비스를 매개로 전달되었더라도 회사가 제작(본 조 제2항 등에 따라 회사가 홈페이지에 단순 게시하는 행위를 제외합니다)하지 않은 정보에 대하여 관련
          법령에 별도의 규정이 있거나 회사가 해당 정보를 홈페이지 게시에 필요한 범위에서 가공한 범위를 넘어 "있는 그대로" 중개 또는 매개하지 않은 특별한 사정이 없는 한, 그
          정확성, 신뢰성 또는 적법성에 관한 어떠한 보증도 하지 않습니다.<br/>
          4. 회사는 회사의 귀책사유가 없는 한 홈페이지 내 이용자의 기업 및 사업 정보, 이용자가 제공하는 상품이나 서비스, 또는 게재되는 내용에 대해 책임지지 않습니다.<br/>
          5. 이용자가 본 조 제2항 내지 제4항의 정보를 신뢰함으로써 입게 된 손해에 대해 관련 법령에서 별도로 규정하거나 회사에 귀책사유가 없는 한 회사는 그 재산적·정신적
          손해, 직·간접손해, 결과적손해, 특별손해, 징벌적손해 등에 대한 어떠한 배상책임을 부담하지 않습니다.<br/>
          6. 허위 또는 제3자의 권리를 침해한 정보를 회사에 제공하거나 홈페이지에 게시하여 회사에 손해를 입힌 이용자는 회사에 대하여 손해배상책임이 발생할 수 있습니다.<br/>
          7. 이용자 상호간 또는 이용자와 제3자간에 분쟁이 발생한 경우 회사의 귀책사유가 없는 한 회사는 어떠한 책임이나 의무도 부담하지 않습니다.<br/>
          8. 회사는 천재지변 또는 이에 준하는 불가항력 또는 회사의 귀책사유 아닌 사유로 인하여 서비스를 제공할 수 없는 경우, 이용자에게 발생한 손해에 대하여 책임을 지지
          않습니다.<br/>

          <br/><br/>

          <h4>제2조 거래의 책임</h4><br/>
          1. 이용자는 홈페이지에 게시된 기업 또는 상품에 대한 정보를 신뢰하고 거래를 개시할 것인지에 대한 전적인 선택권과 그에 수반되는 책임을 부담합니다.<br/>
          2. 회사는 실제 다른 이용자와의 사이에 거래 개시 후 이용자가 입은 어떠한 손해, 손실, 상해 등 이용자간에 행하여지는 거래에 대해서 명시적으로 어떠한 책임이나 의무도
          부담하지 아니합니다.<br/>
          <br/><br/>

          <h3>[제6장 저작권 안내]</h3>

          홈페이지 서비스의 모든 게시물은 법적인 저작물로서 저작권의 보호 대상이 됩니다. 또한, 타인의 지식재산권에 속하는 대상물을 홈페이지 서비스 내에서 이용하실 경우에는 반드시
          해당 권리자의 사전 동의를 받으셔야 합니다. 홈페이지 서비스 내에서 발생될 수 있는 저작권 및 지식재산권 관련 사항에 대하여 아래와 같이 그 기준과 원칙을
          안내합니다.<br/>
          <h4>제1조 홈페이지 서비스게시물의 저작권</h4>
          <br/><br/>
          1. 회원이 서비스 내에 게시한 게시물의 저작권은 해당 게시물의 저작자에게 귀속됩니다.<br/>
          2. 회원이 서비스 내에 게시하는 게시물은 검색결과 내지 서비스 프로모션 등에 노출될 수 있으며, 해당 노출을 위해 필요한 범위 내에서는 일부 수정, 편집되어 게시될 수
          있습니다. 이 경우, 회원은 언제든지 고객센터 또는 서비스 내 관리기능을 통해 해당 게시물에 대해 삭제, 검색결과 제외, 비공개 등의 조치를 취할 수 있습니다.<br/>
          3. 회사는 제2항 이외의 방법으로 회원의 게시물을 이용하고자 하는 경우에는 전화, 팩스, 전자우편 등을 통해 사전에 회원의 동의를 얻어야 합니다.<br/>
          <br/>
          <h4>제2조 회원 및 제3자의 저작권 보호</h4><br/>
          1. 회원은 타인의 저작권을 포함한 지식재산권 및 기타 권리를 침해해서는 안 됩니다. 만일 이와 같은 내용의 게시물로 인해 발생하는 결과에 대한 모든 책임은 회원 본인에게
          있습니다.<br/>
          가. 저작권자의 명시적 동의 없이 타인의 저작물의 전부 또는 일부를 복제, 배포, 전송 등의 방법으로 이용하는 것은 저작권자의 권리를 침해하는 행위임으로 복제 등의 방법으로
          타인의 저작권을 침해한 게시물을 서비스에 게재 또는 등록하여서는 안 됩니다.<br/>
          나. 회원은 공표된 저작물의 보도·비평·교육·연구 등을 위하여 정당한 범위 안에서 공정한 관행에 합치되게 이를 인용할 수 있으며, 반드시 출처를 명시해야 합니다. 하지만
          인용이 저작자의 저작 인격권을 침해하는 것이 되어서는 안 되며 이러한 경우 출처를 밝히더라도 저작권 침해에 해당될 수 있습니다.<br/>
          2. 회원의 게시물에 대하여 제3자로부터 저작권 및 기타 권리의 침해로 이의가 제기된 경우 회사는 당해 게시물을 임시 삭제할 수 있으며, 이의를 제기한 자와 게시물 등록자
          간에 소송, 합의 등을 통해 당해 게시물에 관한 법적 문제가 종결된 후 이를 근거로 회사에 신청이 있는 경우에만 상기 임시 삭제된 게시물은 다시 등록될 수
          있습니다.<br/>
          3. 회원은 서비스를 이용함으로써 얻은 정보를 회사의 사전 승낙 없이 복제, 송신, 출판, 전송, 배포, 방송 기타 방법에 의하여 영리 목적으로 이용하거나 제3자에게
          이용하게 해서는 안 됩니다.<br/>
          4. 회사의 서비스에 게재 또는 등록된 회원의 게시물을 제3자가 사용하고자 할 경우에는 반드시 해당 회원의 명시적 동의를 얻은 뒤 사용해야 합니다.<br/>
          5. 서비스 상의 게시물은 회원들간의 지식 공유를 위해 회원이 게재하거나 회사가 회원의 요청에 따라 “있는 그대로” 게시한 것이므로 회사는 게시물에 대한 정확성에 대해서는
          보증을 하지 않습니다.<br/>
          6. 회사는 회원들의 게시물 이용에 따른 동의 절차를 중개하거나 그 과정에 개입하지 않습니다.<br/>
          <br/>
          <h4>제3조 저작권 침해 게시물의 게시중단 요청</h4><br/>
          1. 저작권 침해에 대해 더 궁금한 내용이 있거나 권리자 본인의 저작권을 침해한 게시물을 신고하시려면 고객 센터를 통해 신고하시면 됩니다.<br/>
          <br/>

          <h3>[제7장 기타]</h3>

          1. 이 약관의 일부 조항이 효력을 상실할 경우 이 약관의 나머지 조항은 여전히 유효하며 이행 가능합니다.<br/>
          2. 이용자는 회사의 사전 서면 동의 없이 이 약관에 따라 발생하는 이용자의 모든 권리나 의무를 제3자에게 이전, 양도, 대여 또는 달리 처분할 수 없습니다.<br/>
          3. 이 약관 및 이 약관에 따른 이용자와 회사의 관계에는 대한민국 법률이 적용됩니다.<br/><br/>
        </div>
      </div>
      <button className={"Submit-Button"} onClick={() => this.closeTermsDetailPopup()}>
        확인
      </button>
    </div>;
  }

  renderTermsDetailPopup() {
    return <div>
      <button className={"close-button"} onClick={() => this.closeTermsDetailPopup()}>
        <img alt={"close_small_icon.svg"} src={SsqImages.common.icon.closeSmallIcon()}/>
      </button>
      <div className={"popup_tit"}>
        <button onClick={() => this.closeTermsDetailPopup()}>
          <img alt={"back_button.svg"} src={SsqImages.header.icon.backButton()}/>
        </button>
        {this.getTranslation("I18N_TERMS_DETAIL_POPUP_TERMS")}
      </div>
      <div className={"TermsDetail_content"}>
        <div className={"TermsDetail"}>
          <span>개정일 2023.04.22</span>

          <h3>[제 1장 총칙]</h3>

          <h4>제 1조 목적</h4>

          이 약관은 LS ELECTRIC Solution Square 홈페이지에서 제공하는 모든 서비스의 이용조건 및 절차에 관한 사항과
          기타 필요한 사항을 관련 법령이 정하는 대로 준수하고 규정함을 목적으로 합니다.<br/>
          <br/><br/>

          <h4>제 2조 정의</h4>
          이 약관에서 사용하는 용어의 정의는 다음과 같습니다. <br/>

          1. “회사”라 함은 LS ELECTRIC 주식회사를 말합니다.<br/>
          2. “서비스”라 함은 홈페이지에서 회사가 제공하는 모든 자동화 엔지니어링 관련 정보 제공 서비스 및 일반 회원을 대상으로 다양한 정보의 제공을 중개하거나 해당 정보
          제공자와의 연결을 매개하는 플랫폼 서비스 등의 관련 서비스를 말합니다.<br/>
          3. “홈페이지”라 함은 회사가 서비스를 이용자에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 운영하는 온라인 홈페이지로서 Solution Square를
          말합니다.<br/>
          4. "이용자"라 함은 홈페이지에 접속하여 이 약관에 따라 홈페이지가 제공하는 서비스를 받는 일반회원 및 비회원을 말합니다.<br/>
          5. "일반 회원"이라 함은 홈페이지에 개인정보 및/또는 기업정보를 제공하여 회원등록을 한 자로서, 권한에 따라 홈페이지 일부의 정보를 지속적으로 제공 받으며, 홈페이지가
          제공하는 서비스의 일부를 계속적으로 이용할 수 있는 자를 말합니다.<br/>
          6. "비회원"이라 함은 회원에 가입하지 않고 회사 홈페이지에서 제공하는 서비스를 이용하는 자를 말합니다.<br/>

          <br/><br/>

          <h4>제 3조 약관의 효력과 변경</h4>

          1. 이 약관은 회사 홈페이지의 초기 서비스 화면에 이용자에게 공시함으로써 효력이 발생합니다. <br/>
          2. 회사는 사정 변경의 경우와 영업상 중요 사유가 있을 때 약관의 규제 등에 관한 법률 등 관련법을 위반하지 않는 범위에서 이 약관을 변경할 수 있으며, 이 경우에는
          적용일자 및 개정 사유, 변경되는 내용을 명시하여 현행 약관과 함께 초기 서비스 화면에 그 적용일자 7일전부터 적용일자 전일까지 공지합니다. 단 이용자에게 불리하거나 중대한
          사항의 변경은 30일 전부터 공지합니다. <br/>
          3. 제2항의 약관 변경의 경우에 회사는 필요한 경우 기존 이용자에게는 서면 또는 전화, 모사전송, 전자우편(e-mail) 등의 방법을 이용하여 개별 통지할 수
          있습니다. <br/>
          4. 제2항에 의거, 변경된 약관은 제1항과 같은 방법으로 효력이 발생합니다. <br/>
          5. 이용자가 변경 약관에 동의하지 않는 경우 회사는 이용자와의 계약을 해지하거나 이용자의 서비스 이용을 제한할 수 있습니다. <br/>

          <br/><br/>

          <h4>제 4조 약관 외 준칙</h4>

          이 약관에 명시되지 않은 사항이 관계 법령에 규정되어 있을 경우에는 그 규정에 따릅니다.<br/>

          <br/><br/>

          <h3>[제 2장 회원 가입 및 서비스 이용]</h3>

          <h4>제 1조 서비스 이용 계약의 성립</h4>

          1. 홈페이지 상 서비스 이용 계약은 이용자가 회원 가입에 따른 서비스 이용 신청에 대한 회사의 이용 승낙과 이용자의 이 약관에 동의한다는 의사표시로 성립됩니다.<br/>
          2. 이용자가 회원에 가입하여 홈페이지 상 서비스를 이용하고자 하는 경우, 회원은 회사에서 요청하는 개인 신상정보 및/또는 기업정보를 제공해야 합니다.<br/>
          3. 회사는 다음 각 호에 해당하는 서비스 이용 신청에 대하여는 이를 승낙하지 아니할 수 있습니다.<br/>
          가. 다른 사람의 개인정보 또는 다른 기업의 정보를 사용하여 신청하였을 때<br/>
          나. 본인의 실명으로 신청하지 않았을 때<br/>
          다. 허위의 정보를 기재하였을 때<br/>
          라. 사회의 안녕과 질서 혹은 미풍양속을 저해할 목적으로 신청하였을 때<br/>
          마. 가입신청자가 제4장 제1조 제5항에 의하여 이전에 회원자격을 상실한 적이 있는 때<br/>
          <br/><br/>

          <h4>제 2조 서비스 이용 및 제한</h4>

          1. 홈페이지 상 서비스 이용은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간을 원칙으로 합니다. <br/>
          2. 회사는 다음 각호의 사유가 있는 경우 서비스의 전부 또는 일부를 중지하거나 제한, 변경 또는 종료할 수 있습니다. <br/>
          가. 정보통신 시스템 등 정기점검/교체, 수리 등 회사가 필요한 경우 <br/>
          나. 통신 두절, 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우 <br/>
          다. 경영상 이유 등으로 인하여 서비스를 제공할 수 없는 부득이한 사정이 발생한 경우 <br/>
          라. 기타 천재지변, 국가비상사태, 각 국가의 법령/정책 상의 규제 또는 제한 등 불가항력적 사유가 있는 경우 <br/>

          3. 제2항에 의한 서비스 중지, 변경, 종료 등의 경우에는 회사는 제1장 제3조 제3항에서 정한 방법 기타 적절한 방법으로 이용자에게 통지하거나,
          불특정다수 회원에 대하여는 1주일 이상 게시판에 게시함으로써 개별 통지에 갈음할 수 있습니다. 단, 회사가 통제할 수 없는 사유 또는 긴급한 사유
          등으로 인하여 이용자에 대한 공지 또는 통지가 불가능한 경우에는 그러하지 아니합니다. <br/>

          <br/><br/><br/>

          <h3>[제 3장 의무]</h3>

          <h4>제 1조 회사의 의무</h4>

          1. 회사는 특별한 사정이 없는 한 회원이 신청한 서비스 제공 개시일에 서비스를 이용할 수 있도록 합니다.<br/>
          2. 회사는 이 약관에서 정한 바에 따라 계속적, 안정적으로 서비스를 제공할 의무가 있습니다.<br/>
          3. 회사는 회원으로부터 소정의 절차에 의해 제기되는 의견에 대해서는 적절한 절차를 거쳐 처리하며, 처리시 일정 기간이 소요될 경우 회원에게 그 사유와 처리 일정을
          알려주어야 합니다.<br/>
          4. 회사는 회원의 정보를 철저히 보안 유지하며, 양질의 서비스를 운영하거나 개선하는 경우 또는 일반 회원간의 중개 목적, 기타 회원이 동의한 목적에만 사용하고, 이외의
          다른 목적으로 타 기관 및 제3자에게 제공, 공개 또는 양도하지 않습니다.<br/>
          5. 일반 회원이 서비스 이용을 위하여 회사에 제공하거나 게시를 요청한 회원의 기업 및 사업 정보 등은 회사가 홈페이지에 대신 게시할 수 있습니다(단, 회원이 회사에
          제공하거나 게시를 요청한 정보는 회원의 사업∙영업∙기술상 비밀정보가 아닌 것으로 간주됩니다).
          이 때 회사는 회원이 제공하거나 게시를 요청한 정보를 게시에 필요한 범위 내에서 가공할 수 있을 뿐 “있는 그대로” 게시합니다.<br/>

          <br/><br/>
          <h4>제 2조 회원의 의무</h4>

          1. ID와 비밀번호에 관한 모든 관리의 책임은 회원에게 있습니다.<br/>
          2. 회원은 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.<br/>
          3. 회원은 ID 및 비밀번호를 도난 당하거나 제3자가 사용하고 있음을 인지하는 경우에는 반드시 회사에 그 사실을 통보하고 회사의 안내가 있는 경우에는 그에 따라야 합니다.<br/>
          4. 회원은 이 약관 및 관계 법령에서 규정한 사항을 준수하여야 합니다.<br/>
          5. 이용자는 제4장 제1조 제5항 각호의 행위를 하여서는 안됩니다.<br/>
          6. 회원은 회원가입 시 등록한 사항에 변경이 있는 경우, 상당한 기간 이내에 회원정보 수정 기타 적절한 방법으로 회사에 그 변경사항을 알려야 합니다.<br/>
          7. 일반 회원이 회사에 제공하거나 게시를 요청한 정보 또는 일반 회원이 직접 홈페이지에 게시한 정보에 대하여 회원은 그 내용에 대한 정확성, 신뢰성 및 적법성을
          담보합니다.<br/>
          8. 회원은 홈페이지 내 자신의 개인정보나 사업∙영업∙기술상 비밀 정보를 게시할 수 없으며, 회원이 홈페이지에 자신의 개인정보나 사업∙영업∙기술상 비밀 정보를 게시,
          노출하여 발생하는 일체의 결과, 손해에 대해 회사는 면책됩니다.<br/>
          9. 이용자는 개인 정보 입력 시 다음 행위를 하여서는 안됩니다.<br/>
          가. 신청 또는 변경 시 허위내용의 등록<br/>
          나. 회사 Solution Square 웹 페이지가 정한 정보 이외의 정보(컴퓨터 프로그램 등)의 송신 또는 게시<br/>
          다. 회사 Solution Square 웹 페이지 기타 제3자의 저작권 등 지적재산권에 대한 침해<br/>
          라. 회사 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위<br/>
          마. 외설 또는 폭력적인 메시지, 화상, 음성 기타 공서양속에 반하는 정보를 공개 또는 게시하는 행위<br/>

          <br/><br/><br/>

          <h3>[제 4장 서비스 이용 계약 해지 및 이용 제한]</h3>

          <h4>제 1조 서비스 이용 계약 해지 및 이용 제한</h4>

          1. 회원이 서비스 이용 계약을 해지하고자 하는 때에는 홈페이지 회원 정보 페이지에서 본인이 직접 서비스 이용 계약을 해지할 수 있습니다.<br/>
          2. 회원이 1년의 기간 동안 가입 정보를 통한 이용기록(로그인 등)이 없을 경우 회사는 휴면 상태 전환 30일 전 이 약관 제1장 제3조 제3항에 의한 방법에 따라 해당
          내용에 대해서 회원에게 통지하고, 회원의 개인정보(이용자ID, 비밀번호, 이름, 연락처 등)를 별도 관리하며, 그 외의 회원정보는 모두 폐기합니다.<br/>
          3. 회사는 회원에게 다음과 같은 경우 또는 이에 해당하는 사실이 존재한다고 의심할만한 합리적 이유가 있는 경우 서비스의 전부 또는 일부의 이용을 중지 또는 제한하거나 본 조 제5항에 따라 회원자격을 상실할 수 있습니다. 또한 회사는 해당 이용자에게
          회사가 입은 손해의 배상을 청구할 수 있습니다.<br/>
          가. 제2장 제1조 제3항 각 호의 사유가 발견된 경우<br/>
          나. 타인의 ID 또는 비밀정보를 도용한 경우<br/>
          다. 같은 이용자가 다른 ID로 이중 등록을 한 경우<br/>
          라. 이용자가 허위의 정보를 제공하여 회사로 하여금 게시하도록 하거나 직접 게시한 경우<br/>
          마. 불법코드, 악성코드 등 허가되지 않은 불법 프로그램을 송신하거나 게시한 경우<br/>
          바. 이용자가 회사를 포함한 제3자의 지식재산권을 침해하는 행위를 하는 경우<br/>
          사. 이용자가 서비스에 위해를 가하거나 회사의 기술적 보호조치를 회피 또는 무력화하는 행위를 하는 경우<br/>
          아. 외설 또는 폭력적인 메시지, 화상, 음성 기타 공서양속에 반하는 정보 또는 불법 정보를 공개 또는 게시하는 경우<br/>
          자. 국익 또는 사회적 공익을 저해할 목적으로 서비스 이용을 계획 또는 실행할 경우<br/>
          차. 서비스 이용 중 지득, 취득한 제3자의 정보를 서비스 이용 목적 외 유용하거나 정보 주체의 동의 없이 다른 제3자에 공개, 제공, 유출하는 경우<br/>
          카. 이용자가 이 약관 위반행위 및 회사를 포함한 제3자의 명예를 훼손하거나 업무를 방해하는 등 법령위반 행위, 범죄행위 또는 미풍양속에 반하는 행위를 하는 경우<br/>
          타. 기타 회사가 정한 이용조건에 위배되거나 서비스 이용의 중지, 제한 또는 회원자격 박탈이 필요하다고 회사가 합리적으로 판단하는 경우<br/>
          4. 전항에 따라 회원자격을 상실하는 경우 회사는 이용자에게 사전에 사유를 통지하고, 회원자격 말소 전 30일 이상의 기간을 두어 이의신청 기회를 부여합니다. 그 이의가 정당하다고 회사가 인정하는 경우 회사는 즉시 서비스의 이용을 재개합니다.<br/>
          5. 일부 회원 게시물에 대한 삭제는 고객 요청 또는 서비스내 관리 기능을 통해서 삭제될 수 있으며, 이는 고객 센터 요청을 통해서 이뤄집니다.<br/>
          6. 전항에 따라 회원자격을 상실하는 경우 회사는 이용자에게 사전에 사유를 통지하고, 회원자격 말소 전 30일 이상의 기간을 두어 이의신청 기회를 부여합니다. 그 이의가
          정당하다고 회사가 인정하는 경우 회사는 즉시 서비스의 이용을 재개합니다.<br/>
          7. 일부 회원 게시물에 대한 삭제는 고객 요청 또는 서비스내 관리 기능을 통해서 삭제될 수 있으며, 이는 고객 센터 요청을 통해서 이뤄집니다.<br/>

          <br/><br/>

          <h4>제 2조 서비스 이용 제한의 해제 절차</h4>

          1. 회사는 회원의 서비스 이용 제한을 하고자 하는 경우에는 이 약관에 달리 정하지 않는 한 그 사유,
          일시 및 기간을 정하여 제1장 제1조 제3항에 기재한 방법을 이용하여 해당 회원 또는 대리인에게 통지합니다.
          다만, 회사가 긴급하게 이용을 중지해야 할 필요가 있다고 인정하는 경우에는 전항의 과정 없이 서비스 이용을 제한할 수 있습니다.<br/>
          2. 전항의 규정에 의하여 서비스 이용 중지 또는 제한을 통지 받은 회원 또는 그 대리인은 서비스 이용 중지 또는 제한에 대하여 이의신청을 할 수 있습니다.<br/>
          3. 회사는 서비스 이용 중지∙제한 기간 중에 그 이용 중지∙제한 사유가 해소된 것이 확인된 경우에 한하여 이용 중지∙제한 조치를 즉시 해제합니다.<br/>

          <br/><br/>

          <h3>[제5장 책임의 한계]</h3>

          <h4>제1조 회사 책임의 한계</h4><br/>
          1. 회사는 홈페이지를 통해 자동화 엔지니어링 기술 관련 다양한 정보의 제공을 중개하거나 해당 정보 제공자와의 연결을 매개하고 있습니다. 회사는 정보중개자로서 이미 생산된
          정보를 "있는 그대로" 중개 또는 매개합니다.<br/>
          2. 홈페이지 내 일반 회원을 대상으로 회사가 게시하는 정보는 이용자(회원)가 작성∙제공한 게시물 또는 이용자(회원)가 표시한 상품 정보를 “있는 그대로” 게시한 것으로서
          이는 해당 정보의 제공자가 독립적으로 작성 또는 제공한 정보이기 때문에, 일반 회원이 직접 작성, 게시한 정보와 같이, 홈페이지 서비스에서 중개 또는 매개된 정보라도
          원칙적으로 그 정보의 정확성, 신뢰성 및 적법성은 해당 정보를 제공 혹은 제작한 당사자가 담보하고 이에 따른 책임을 부담합니다. 따라서 이러한 정보의 신뢰에 대한 결정은
          전적으로 이용자가 하여야 합니다.<br/>
          3. 회사는 홈페이지 서비스를 매개로 전달되었더라도 회사가 제작(본 조 제2항 등에 따라 회사가 홈페이지에 단순 게시하는 행위를 제외합니다)하지 않은 정보에 대하여 관련
          법령에 별도의 규정이 있거나 회사가 해당 정보를 홈페이지 게시에 필요한 범위에서 가공한 범위를 넘어 "있는 그대로" 중개 또는 매개하지 않은 특별한 사정이 없는 한, 그
          정확성, 신뢰성 또는 적법성에 관한 어떠한 보증도 하지 않습니다.<br/>
          4. 회사는 회사의 귀책사유가 없는 한 홈페이지 내 이용자의 기업 및 사업 정보, 이용자가 제공하는 상품이나 서비스, 또는 게재되는 내용에 대해 책임지지 않습니다.<br/>
          5. 이용자가 본 조 제2항 내지 제4항의 정보를 신뢰함으로써 입게 된 손해에 대해 관련 법령에서 별도로 규정하거나 회사에 귀책사유가 없는 한 회사는 그 재산적·정신적
          손해, 직·간접손해, 결과적손해, 특별손해, 징벌적손해 등에 대한 어떠한 배상책임을 부담하지 않습니다.<br/>
          6. 허위 또는 제3자의 권리를 침해한 정보를 회사에 제공하거나 홈페이지에 게시하여 회사에 손해를 입힌 이용자는 회사에 대하여 손해배상책임이 발생할 수 있습니다.<br/>
          7. 이용자 상호간 또는 이용자와 제3자간에 분쟁이 발생한 경우 회사의 귀책사유가 없는 한 회사는 어떠한 책임이나 의무도 부담하지 않습니다.<br/>
          8. 회사는 천재지변 또는 이에 준하는 불가항력 또는 회사의 귀책사유 아닌 사유로 인하여 서비스를 제공할 수 없는 경우, 이용자에게 발생한 손해에 대하여 책임을 지지
          않습니다.<br/>

          <br/><br/>

          <h4>제2조 거래의 책임</h4><br/>
          1. 이용자는 홈페이지에 게시된 기업 또는 상품에 대한 정보를 신뢰하고 거래를 개시할 것인지에 대한 전적인 선택권과 그에 수반되는 책임을 부담합니다.<br/>
          2. 회사는 실제 다른 이용자와의 사이에 거래 개시 후 이용자가 입은 어떠한 손해, 손실, 상해 등 이용자간에 행하여지는 거래에 대해서 명시적으로 어떠한 책임이나 의무도
          부담하지 아니합니다.<br/>
          <br/><br/>

          <h3>[제6장 저작권 안내]</h3>

          홈페이지 서비스의 모든 게시물은 법적인 저작물로서 저작권의 보호 대상이 됩니다. 또한, 타인의 지식재산권에 속하는 대상물을 홈페이지 서비스 내에서 이용하실 경우에는 반드시
          해당 권리자의 사전 동의를 받으셔야 합니다. 홈페이지 서비스 내에서 발생될 수 있는 저작권 및 지식재산권 관련 사항에 대하여 아래와 같이 그 기준과 원칙을
          안내합니다.<br/>
          <h4>제1조 홈페이지 서비스게시물의 저작권</h4>
          <br/><br/>
          1. 회원이 서비스 내에 게시한 게시물의 저작권은 해당 게시물의 저작자에게 귀속됩니다.<br/>
          2. 회원이 서비스 내에 게시하는 게시물은 검색결과 내지 서비스 프로모션 등에 노출될 수 있으며, 해당 노출을 위해 필요한 범위 내에서는 일부 수정, 편집되어 게시될 수
          있습니다. 이 경우, 회원은 언제든지 고객센터 또는 서비스 내 관리기능을 통해 해당 게시물에 대해 삭제, 검색결과 제외, 비공개 등의 조치를 취할 수 있습니다.<br/>
          3. 회사는 제2항 이외의 방법으로 회원의 게시물을 이용하고자 하는 경우에는 전화, 팩스, 전자우편 등을 통해 사전에 회원의 동의를 얻어야 합니다.<br/>
          <br/>
          <h4>제2조 회원 및 제3자의 저작권 보호</h4><br/>
          1. 회원은 타인의 저작권을 포함한 지식재산권 및 기타 권리를 침해해서는 안 됩니다. 만일 이와 같은 내용의 게시물로 인해 발생하는 결과에 대한 모든 책임은 회원 본인에게
          있습니다.<br/>
          가. 저작권자의 명시적 동의 없이 타인의 저작물의 전부 또는 일부를 복제, 배포, 전송 등의 방법으로 이용하는 것은 저작권자의 권리를 침해하는 행위임으로 복제 등의 방법으로
          타인의 저작권을 침해한 게시물을 서비스에 게재 또는 등록하여서는 안 됩니다.<br/>
          나. 회원은 공표된 저작물의 보도·비평·교육·연구 등을 위하여 정당한 범위 안에서 공정한 관행에 합치되게 이를 인용할 수 있으며, 반드시 출처를 명시해야 합니다. 하지만
          인용이 저작자의 저작 인격권을 침해하는 것이 되어서는 안 되며 이러한 경우 출처를 밝히더라도 저작권 침해에 해당될 수 있습니다.<br/>
          2. 회원의 게시물에 대하여 제3자로부터 저작권 및 기타 권리의 침해로 이의가 제기된 경우 회사는 당해 게시물을 임시 삭제할 수 있으며, 이의를 제기한 자와 게시물 등록자
          간에 소송, 합의 등을 통해 당해 게시물에 관한 법적 문제가 종결된 후 이를 근거로 회사에 신청이 있는 경우에만 상기 임시 삭제된 게시물은 다시 등록될 수
          있습니다.<br/>
          3. 회원은 서비스를 이용함으로써 얻은 정보를 회사의 사전 승낙 없이 복제, 송신, 출판, 전송, 배포, 방송 기타 방법에 의하여 영리 목적으로 이용하거나 제3자에게
          이용하게 해서는 안 됩니다.<br/>
          4. 회사의 서비스에 게재 또는 등록된 회원의 게시물을 제3자가 사용하고자 할 경우에는 반드시 해당 회원의 명시적 동의를 얻은 뒤 사용해야 합니다.<br/>
          5. 서비스 상의 게시물은 회원들간의 지식 공유를 위해 회원이 게재하거나 회사가 회원의 요청에 따라 “있는 그대로” 게시한 것이므로 회사는 게시물에 대한 정확성에 대해서는
          보증을 하지 않습니다.<br/>
          6. 회사는 회원들의 게시물 이용에 따른 동의 절차를 중개하거나 그 과정에 개입하지 않습니다.<br/>
          <br/>
          <h4>제3조 저작권 침해 게시물의 게시중단 요청</h4><br/>
          1. 저작권 침해에 대해 더 궁금한 내용이 있거나 권리자 본인의 저작권을 침해한 게시물을 신고하시려면 고객 센터를 통해 신고하시면 됩니다.<br/>
          <br/>

          <h3>[제7장 기타]</h3>

          1. 이 약관의 일부 조항이 효력을 상실할 경우 이 약관의 나머지 조항은 여전히 유효하며 이행 가능합니다.<br/>
          2. 이용자는 회사의 사전 서면 동의 없이 이 약관에 따라 발생하는 이용자의 모든 권리나 의무를 제3자에게 이전, 양도, 대여 또는 달리 처분할 수 없습니다.<br/>
          3. 이 약관 및 이 약관에 따른 이용자와 회사의 관계에는 대한민국 법률이 적용됩니다.<br/><br/>

          <span className={"span_cursor"} onClick={() => {
            this.setState({
              isShowRecentTerms: false,
              isShowOriginalTermsV0: true,
              isShowOriginalTermsV1: false
            });
          }}>이전 이용 약관 보기 V1.0 (~2022.04.18)</span>
          <span className={"span_cursor"} onClick={() => {
            this.setState({
              isShowRecentTerms: false,
              isShowOriginalTermsV0: false,
              isShowOriginalTermsV1: true
            });
          }}>이전 이용 약관 보기 V1.1 (~2022.04.21)</span><br/>
        </div>
      </div>
      <button className={"Submit-Button"} onClick={() => this.closeTermsDetailPopup()}>
        확인
      </button>
    </div>;
  }

  renderTermsDetailPopupEn() {
    return <div>
      <button className={"close-button"} onClick={() => this.closeTermsDetailPopup()}>
        <img alt={"close_small_icon.svg"} src={SsqImages.common.icon.closeSmallIcon()}/>
      </button>
      <div className={"popup_tit"}>
        <button onClick={() => this.closeTermsDetailPopup()}>
          <img alt={"back_button.svg"} src={SsqImages.header.icon.backButton()}/>
        </button>
        {this.getTranslation("I18N_TERMS_DETAIL_POPUP_TERMS")}
      </div>
      <div className={"TermsDetail_content"}>
        <div className={"TermsDetail"}>

          <h3>[ General Rules ]</h3>

          <h4>Purpose</h4>
          The purpose of these Terms and Conditions is to comply with and stipulate matters concerning the terms, conditions, and procedures for the
          use of all services provided by the LS ELECTRIC Solution Square website and other necessary matters as stipulated by relevant laws and
          regulations.
          <br/><br/>

          <h4>Definitions</h4>
          The terms used in these Terms are defined as follows.<br/>
          1. "Company" refers to LS ELECTRIC Co.<br/>
          2. "Service" refers to all automation engineering-related information provision services provided by the Company on the Website and related
          services such as platform services that mediate the provision of various information to general members or connections to such information
          providers.<br/>
          3. "Website" refers to Solution Square, an online website operated by the Company using information and communication facilities such as
          computers to provide services to users.<br/>
          4. "User" refers to general members and non-members who access the Website and receive the services provided by the Website in accordance
          with these Terms.<br/>
          5. "General Member" refers to a person who has registered as a member by providing personal information and/or corporate information to the
          website, and can continue to receive information on some parts of the website according to the authorization, and can continue to use some
          of the services provided by the website.<br/>
          6. "Non-member" refers to a person who uses the services provided by the Company's website without registering as a member.<br/><br/>

          <h4>Effect and Change of Terms</h4>
          1. These Terms and Conditions shall be effective upon disclosure to users on the initial service screen of the Company's website.<br/>
          2. The Company may change these Terms to the extent that it does not violate relevant laws such as the Act on the Regulation of Terms and
          Conditions, etc. in the event of a change in circumstances and when there are important reasons for business, and in this case, the date of
          application, reason for revision, and contents to be changed shall be notified on the initial service screen together with the current Terms
          from 7 days before the date of application to the day before the date of application. However, changes that are unfavorable or significant
          to the user shall be notified 30 days in advance.<br/>
          3. In the case of a change in the terms and conditions under Paragraph 2, the Company may, if necessary, notify existing users individually
          in writing or by using methods such as telephone, simulated transmission, or e-mail.<br/>
          4. In accordance with Paragraph 2, the changed terms and conditions shall take effect in the same manner as Paragraph 1.<br/>
          5. If the user does not agree to the changed terms, the company may terminate the contract with the user or restrict the user's use of the
          service.<br/><br/>

          <h4>Non-Terms and Conditions</h4>
          If any matters not specified in these Terms and Conditions are stipulated in the relevant laws and regulations, they shall be governed by
          them.<br/><br/><br/>


          <h3>[Membership and Service Use]</h3>

          <h4>Establishment of Service Use Contract</h4>
          1. The service use contract on the homepage is established by the user's acceptance of the company's application for service use by
          registering as a member and the user's expression of agreement to these terms and conditions.<br/>
          2. If a user wishes to register as a member and use the services on the website, the member shall provide personal identification
          information and/or corporate information requested by the company.<br/>
          3. The Company may not approve an application for service use that falls under any of the following items.<br/>
          A. When the application is made using another person's personal information or information of another company.<br/>
          B. The application is not made under the user's real name<br/>
          C. When false information is provided<br/>
          D. The application is made for the purpose of interfering with the well-being and order of society or public morals and customs.<br/>
          E. When the applicant has previously been disqualified from membership under Chapter 4, Article 1, Paragraph 5.<br/><br/>

          <h4>Service Use and Restrictions</h4>
          1. The use of the service on the website shall be in principle 24 hours a day, 7 days a week, unless there is a special hindrance due to the
          company's business or technology.<br/>
          2. The Company may suspend, limit, change, or terminate all or part of the Service if there are any of the following reasons.<br/>
          A. When the Company needs to perform regular inspection/replacement or repair of information and communication systems, etc.<br/>
          B. If there is any interference with the normal use of the Service due to communication interruption, power outage, failure of various
          facilities, or excessive usage volume.<br/>
          C. In case of unavoidable circumstances that prevent us from providing the service due to management reasons, etc.<br/>
          D. If there are other force majeure reasons such as natural disasters, national emergencies, and regulations or restrictions under the laws
          and policies of each country.<br/>
          3. In the case of suspension, modification, termination of service under Paragraph 2, the Company may notify the user by the method
          prescribed in Chapter 1, Article 3, Paragraph 3 or other appropriate methods, or may replace individual notification by posting on the
          bulletin board for more than one week for unspecified members. However, this shall not apply if it is impossible to notify or inform users
          due to reasons beyond the control of the Company or urgent reasons, etc.<br/><br/><br/>


          <h3>[Obligations]</h3>

          <h4>Obligations of the Company</h4>
          1. The Company shall make the Service available on the date of commencement of service provision requested by the Member unless there are
          special circumstances.<br/>
          2. The Company shall be obligated to provide the Service continuously and stably as stipulated in these Terms and Conditions.<br/>
          3. The Company shall handle opinions raised by members through the prescribed procedures, and if the processing takes a certain period of
          time, the Company shall inform the member of the reason and the processing schedule.<br/>
          4. The Company shall strictly maintain the security of the member's information and use it only for the purpose of operating or improving
          quality services or for the purpose of intermediation between general members and other purposes agreed to by the member, and shall not
          provide, disclose or transfer it to other organizations and third parties for any other purpose.<br/>
          5. Corporate and business information of the member that the general member provides to the company or requests to be posted in order to use
          the service may be posted by the company on the homepage instead (however, the information provided by the member to the company or
          requested to be posted shall be deemed not to be confidential business, sales, or technical information of the member). In this case, the
          Company may process the information provided by the Member or requested to be posted to the extent necessary for posting, but will post it
          "as is".<br/><br/>

          <h4>Member's Obligations</h4>
          1. Members are responsible for all management of their IDs and passwords.<br/>
          2. Members shall not allow third parties to use their ID and password.<br/>
          3. If the Member recognizes that the ID and password have been stolen or are being used by a third party, the Member must notify the Company
          and follow the Company's instructions, if any.<br/>
          4. Members shall comply with the matters stipulated in these Terms and Conditions and related laws and regulations.<br/>
          5. The user shall not engage in any of the acts set forth in Chapter 4, Article 1, Paragraph 5.<br/>
          6. If there is a change in the information registered at the time of registration, the member shall notify the company of the change by
          modifying the member information or other appropriate methods within a reasonable period of time.<br/>
          7. The member shall guarantee the accuracy, reliability, and legality of the contents of the information provided by the general member to
          the company or requested to be posted, or the information posted on the homepage by the general member himself.<br/>
          8. Members shall not post their personal information or business, business, or technical secret information on the website, and the Company
          shall be indemnified against any consequences or damages arising from the posting or exposure of their personal information or business,
          business, or technical secret information on the website.<br/>
          9. Users shall not engage in the following acts when entering personal information.<br/>
          A. Register false information when applying or changing<br/>
          B. Transmitting or posting information (computer programs, etc.) other than the information specified on the Company's Solution Square
          webpage<br/>
          C. Infringement of intellectual property rights such as copyrights of the Company's Solution Square webpage or other third parties<br/>
          D. Acts that damage the reputation or interfere with the business of the company or other third parties<br/>
          E. Disclosing or posting obscene or violent messages, images, audio, or other information contrary to public order and
          morals.<br/><br/><br/>

          <h3>[Termination of Service Use Agreement and Restrictions on Use].</h3>

          <h4>Termination of Service Use Agreement and Restrictions on Use</h4>
          1. If a member wishes to terminate the service use contract, he/she can directly terminate the service use contract on the member
          information page of the homepage.<br/>
          2. If the member does not have a usage record (login, etc.) through the subscription information for a period of one year, the company will
          notify the member about the contents in accordance with the method under General Rules, Paragraph 3 of Effect and Change of Terms these
          Terms and Conditions 30 days before the conversion to dormant status, and all of the member's information will be destroyed.<br/>
          3. The Company may suspend or restrict the use of all or part of the Service or disqualify the Member in accordance with Paragraph 5 of this
          Article if the Member has the following cases or has reasonable reasons to suspect that the corresponding facts exist. In addition, the
          Company may claim compensation for damages incurred by the Company from the User.<br/>
          A. In the event that any of the grounds of Chapter 2, Article 1, Paragraph 3 are found<br/>
          B. In case of stealing another person's ID or secret information<br/>
          C. In case of double registration by the same user with different IDs<br/>
          D. The user provides false information and causes the Company to post it or posts it himself or herself.<br/>
          E. The user sends or posts unauthorized and illegal programs such as illegal code, malware, etc.<br/>
          F. If the user engages in behavior that infringes on the intellectual property rights of third parties, including the Company.<br/>
          G. If the user engages in behavior that causes harm to the Service or evades or neutralizes the Company's technical protection
          measures.<br/>
          H. The user discloses or posts obscene or violent messages, images, voices, or other information contrary to public order and morals, or
          illegal information.<br/>
          I. Planning or executing the use of the Service for the purpose of undermining national interest or social public interest<br/>
          J. If the user uses the information of a third party acquired during the use of the service for purposes other than the use of the service
          or discloses, provides, or leaks to another third party without the consent of the information subject<br/>
          K. If the user violates these Terms and Conditions and engages in acts that violate laws, criminal acts, or acts against public order and
          morals, such as defaming or interfering with the business of a third party, including the Company.<br/>
          L. If the user violates any other terms and conditions set by the company or if the company reasonably determines that it is necessary to
          suspend, restrict, or revoke the use of the service or membership.<br/>
          4. In the event of loss of membership pursuant to the preceding paragraph, the Company shall notify the user of the reason in advance and
          give the user an opportunity to appeal for a period of not less than 30 days before the termination of membership. If the Company recognizes
          that the appeal is justified, the Company shall immediately resume the use of the service.<br/>
          5. Some member posts may be deleted by customer request or through the management function in the service, which is done through a customer
          center request.<br/><br/>

          <h4>Procedure for lifting service use restrictions</h4>

          1. If the Company intends to restrict a member's use of the Service, unless otherwise specified in these Terms and Conditions, the Company
          shall determine the reason, date, and period and notify the member or his/her agent using the method described in Chapter 1, Article 1,
          Paragraph 3. However, if the company recognizes that it is necessary to stop using the service urgently, it may restrict the use of the
          service without the process of the preceding paragraph.<br/>
          2. A member or his/her representative who has been notified of the suspension or restriction of service use in accordance with the
          provisions of the preceding paragraph may appeal against the suspension or restriction of service use.<br/>
          3. The Company shall immediately cancel the suspension or restriction of service use only if it is confirmed that the reason for the
          suspension or restriction has been resolved during the period of suspension or restriction of service use.<br/><br/><br/>


          <h3>[Limitation of Liability]</h3>
          <h4>Limitation of Company's Liability</h4>
          1. The Company mediates the provision of various information related to automation engineering technology through its website or mediates
          the connection with the information provider. As an information intermediary, the Company intermediates or mediates information that has
          already been produced on an "as is" basis.<br/>
          2. The information posted by the Company for general members on the website is posted "as is" in the form of posts created and provided by
          users (members) or product information displayed by users (members), which is information created or provided independently by the provider
          of such information. Therefore, even if the information is intermediated or mediated in the website service, such as information created and
          posted by general members, in principle, the accuracy, reliability and legality of the information is guaranteed by the party providing or
          creating the information and bears the responsibility accordingly. Therefore, the decision on the reliability of such information must be
          made solely by the user.<br/>
          3. The Company does not provide any warranty regarding the accuracy, reliability, or legality of information that is not produced by the
          Company (excluding the simple posting by the Company on the Website pursuant to Paragraph 2 of this Article), even if it is delivered
          through the Website service, unless there is a separate provision in the relevant laws and regulations or there are special circumstances
          that the Company has not intermediated or mediated the information "as is" beyond the extent that it has been processed to the extent
          necessary for posting on the Website.<br/>
          4. The Company shall not be responsible for the corporate and business information of the User, the products or services provided by the
          User, or the contents posted on the Website unless there are reasons attributable to the Company.<br/>
          5. The Company shall not be liable for any property or mental damages, direct or indirect damages, consequential damages, special damages,
          or punitive damages, unless otherwise stipulated by relevant laws and regulations, or unless there is a reason attributable to the Company,
          for damages incurred by the User by relying on the information in Paragraphs 2 through 4 of this Article.<br/>
          6. Users who cause damage to the Company by providing false or infringing information to the Company or posting it on the website may be
          liable for damages to the Company.<br/>
          7. In the event of a dispute between users or between users and a third party, the Company shall not bear any responsibility or obligation
          unless there is a reason attributable to the Company.<br/>
          8. If the Company is unable to provide the Service due to natural disasters or equivalent force majeure or reasons not attributable to the
          Company, the Company shall not be liable for any damages caused to the User.<br/><br/>


          <h4>Responsibility for Transactions</h4>

          1. Users shall have the sole option and responsibility to trust the information about companies or products posted on the website and to
          initiate transactions.<br/>
          2. The Company expressly disclaims any responsibility or liability for transactions conducted between users, including any damages, losses,
          or injuries suffered by users after initiating transactions with other users.<br/><br/><br/>


          <h3>[Copyright Notice].</h3>
          All posts on the website service are legal works and are subject to copyright protection. In addition, if you use objects belonging to other
          people's intellectual property rights within the website service, you must obtain the prior consent of the relevant right holder. The
          standards and principles for copyright and intellectual property rights related matters that may occur within the website service are
          provided below.<br/><br/>

          <h4>Copyright of Homepage Service Posts</h4>

          1. The copyright of the postings made by the member in the service belongs to the author of the postings.<br/>
          2. The posts posted by members in the Service may be exposed in search results or service promotions, and may be partially modified, edited,
          and posted to the extent necessary for such exposure. In this case, the member may take measures such as deleting, excluding from search
          results, or disclosing the post at any time through the customer center or the management function within the service.<br/>
          3. If the Company intends to use the Member's postings in a manner other than Paragraph 2, it must obtain the Member's consent in advance
          through telephone, fax, e-mail, etc.<br/>


          <h4>Copyright Protection of Members and Third Parties</h4>

          1. Members shall not infringe on intellectual property rights and other rights, including copyrights, of others. If a member does so, the
          member shall bear all responsibility for any consequences arising from the posting of such content.<br/>
          A. Using all or part of another person's work by reproduction, distribution, transmission, etc. without the express consent of the copyright
          holder is an act that infringes on the rights of the copyright holder, so you must not post or register posts that infringe on another
          person's copyright by reproduction, etc. on the Service.<br/>
          B. Members may cite published works for reporting, criticism, education, research, etc. within a reasonable scope and in accordance with
          fair practices, and must specify the source. However, citation must not infringe on the author's moral rights, and in such cases, even if
          the source is identified, it may constitute copyright infringement.<br/>
          2. If a member's post is objected to by a third party as an infringement of copyright and other rights, the Company may temporarily delete
          the post, and the temporarily deleted post may be re-registered only if the Company receives an application on this basis after the legal
          issues concerning the post are concluded through litigation, settlement, etc. between the objector and the post registrant.<br/>
          3. Members shall not use the information obtained by using the service for commercial purposes by reproduction, transmission, publication,
          transmission, distribution, broadcasting, or other methods without the prior consent of the company, or have a third party use it.<br/>
          4. If a third party wishes to use a member's postings posted or registered on the Company's services, it must obtain the express consent of
          the member.<br/>
          5. Since the posts on the Service are posted by members for knowledge sharing among members or posted "as is" by the Company at the request
          of members, the Company does not guarantee the accuracy of the posts.<br/>
          6. The Company does not mediate or intervene in the consent process for the use of posts by members.<br/><br/>

          <h4>Request for Takedown of Copyright Infringing Posts</h4>
          1. If you have more questions about copyright infringement or wish to report a post that infringes on your copyright, you can report it
          through the customer center.<br/><br/><br/>


          <h3>[Miscellaneous]</h3>
          1. If any provision of these Terms is held to be invalid, the remaining provisions of these Terms shall remain valid and enforceable.<br/>
          2. You may not transfer, assign, lease, or otherwise dispose of any of your rights or obligations arising under these Terms to any third
          party without the prior written consent of the Company.<br/>
          3. These Terms and the relationship between the User and the Company under these Terms shall be governed by the laws of the Republic of
          Korea.
          <br/>
          Effective date : April 22, 2023
          <br/><br/>


        </div>
      </div>
      <button className={"Submit-Button"} onClick={() => this.closeTermsDetailPopup()}>
        Confirm
      </button>
    </div>;
  }

  render() {
    return <TermsPopup className={"TermsDetail_Wrap"}>
      {this.props.languageService.getLanguage() === LANG_KO_KR && <div className={"TermsDetail_Popup"}>
        {this.state.isShowRecentTerms && this.renderTermsDetailPopup()}
        {this.state.isShowOriginalTermsV0 && this.renderOriginalTermsDetailPopupV0()}
        {this.state.isShowOriginalTermsV1 && this.renderOriginalTermsDetailPopupV1()}
      </div>}
      {[LANG_EN, LANG_EN_US].includes(this.props.languageService.getLanguage()) &&
        <div className={"TermsDetail_Popup"}>
          {this.state.isShowRecentTerms && this.renderTermsDetailPopupEn()}
        </div>}
    </TermsPopup>;
  }
}


export const TermsPopup = styled.div`
  z-index: 1003;


  .popup_tit {
    button {
      margin-right: .8rem;
      vertical-align: middle;
    }
  }

  .term_imformation {
    background: rgba(199, 214, 234, 0.2);
    border: solid 1px #C7D6EB;
    padding: 1.2rem;
    border-radius: 4px;

    .term_title {
      ${fontFamilyBold};
    }
  }


  .TermsDetail_content {
    ${fontFamilyRegular};
    margin: 1.6rem 0;
    height: 46.9rem;

    p {
      font-size: 1.4rem;
      margin-bottom: 1rem;
    }

    > div + div {
      margin-top: 1.6rem;
    }

    .TermsDetail {
      border: 1px solid #C7D6EA;
      border-radius: 4px;
      height: 100%;
      max-height: initial;
      overflow-y: auto;
      padding: 1.6rem;
      font-size: 1.3rem;
      line-height: 1.9rem;

      .span_cursor {
        cursor: pointer;
      }

      span {
        color: #7783A1;
        display: block;
        margin-bottom: 2.4rem;
      }

      h3 {
        font-size: 1.4rem;
        ${fontFamilyBold};
        margin-bottom: 2.4rem;
      }

      h4 {
        ${fontFamilyBold};
        margin-bottom: 1.6rem;
      }

      h5 {
        ${fontFamilyBold};
        font-size: 1.3rem;
      }
    }
  }
`;