import React from 'react';
import useScript from "hooks/useScript";
import {LanguageService} from "../language/LanguageService";
import ToolTip from "./ToolTip";
import svcs from "services";
import {cookieutil} from "util/cookieutil";

declare global {
  interface Window {
    naver: any;
  }
}

interface User {
  nickname: string;
  image: string;
}

interface Image {
  url: string;
}

interface State {
}

type Props = {
  languageService: LanguageService;
  recentLoginType: string;
}

function NaverLoginPage(props: Props) {
  const Login = () => {
    if (cookieutil.isAcceptFunctionConsentCookie(this.props.languageService.lang()))
      svcs.localStorage.setItem('recentLoginType', 'NAVER');
    Naver();
  };
  //useEffect(Login, []);

  useScript('https://static.nid.naver.com/js/naveridlogin_js_sdk_2.0.2.js', 'script_naveridlogin', () => {
    Naver();
  });

  const getClientIdByUrl = () => {
    let clientId;
    const host = window.location.host;
    if (host.includes("sol.ls-electric.com") ||
      host.includes("ssq.ls-electric.com") ||
      host.includes("ssq.lselectric.co.kr")) {
      clientId = 'qOR9naadODCNpmtcBWw_';
    } else if (host.includes("localhost:8087") || host.includes('112.171.39.238:8087')) {
      clientId = 'dFyOK9cpbmnze4IuJY3y';
    } else if (host.includes("localhost:3000")) {
      clientId = 'dFyOK9cpbmnze4IuJY3y';
    }

    return clientId;
  };

  const Naver = () => {
    const {naver} = window;
    const naverLogin = new naver.LoginWithNaverId({
      clientId: getClientIdByUrl(),
      callbackUrl: window.location.protocol + '//' + window.location.host + '/naverLoginCallback',
      isPopup: false,
      loginButton: {color: 'green', type: 1, height: 40},
      callbackHandle: true,
    });
    naverLogin.init();
  };

  return (
    <div className="login">
      {props.recentLoginType == 'NAVER' && <ToolTip languageService={props.languageService}/>}
      <div onClick={Login} id="naverIdLogin"/>
    </div>
  );
}

export default NaverLoginPage;