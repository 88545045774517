// @flow
import * as React from 'react';
import {useRef} from 'react';
import {IAnswerDocument} from "communitypage/qna/popup/qna-popup-common";
import {IfLoginInfo} from "models/IfLoginInfo";
import useOutsideClick from "hooks/useOutsideClick";
import {ISsqDataDocument} from "repositories/SsqDataRepository";
import {IfDocCommentInfo} from "models/IfSsqDataDocCommentEntity";
import {PartnerAnswerViewItem} from "communitypage/partner/qna/PartnerAnswerViewItem";

type Props = {
  answers: IAnswerDocument[],
  question: ISsqDataDocument,
  handleClick: (cmd: string, data?) => void,
  //requestUserGoodBad: (document, type: string) => void,
  trans,
  loginInfo: IfLoginInfo,
  handleCmd: (cmd: string | 'show-login' | 'refresh-answers', data?) => void,
  displayLoginPopup: Function;
  commentList: Array<IfDocCommentInfo>;
};

// renderAnswerView
export const PartnerAnswerView = (props: Props) => {
  const ref = useRef();
  useOutsideClick(ref, () => null, []);

  return <div>{props.answers.map((answer, index) =>
    <PartnerAnswerViewItem
      commentList={props.commentList}
      displayLoginPopup={props.displayLoginPopup}
      key={answer.id}
      question={props.question}
      answer={answer}
      trans={props.trans}
      loginInfo={props.loginInfo}
      handleCmd={props.handleCmd}/>
  )}</div>;
};