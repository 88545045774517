import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {SsqImages} from "images/SsqImages";
import parse from "html-react-parser";
import {getHighlightedTextFromHtml} from "util/commonUtils";
import {fontFamilyMedium, fontFamilyRegular} from "style/fonts";
import img_icon_c_time from "images/common/icon_c_time.svg";
import img_icon_c_not_bad from "images/common/icon_c_not_bad.svg";
import img_unknown from "images/avatar/unknown-user.svg";
import styled from "styled-components";
import TechPointGuide from "./TechPointGuide";
import {useSelector} from "react-redux";
import {IfLoginInfo} from "models/IfLoginInfo";
import {docutil} from "util/docutil";
import {Link} from "react-router-dom";
import {imgs} from "images/imgs";
import svcs from "services";
import {i18ns} from "language/i18nkey";
import {PopAsk} from "../../docwrite/popup/PopAsk";
import {langutil} from "language/langutil";
import {ssqutil} from "util/ssqutil";
import {useShowToast} from "hooks/useToast";
import clsx from 'clsx';

interface Props {
  userId: string;
  rank?: number;
  highlightedNickName?: string;
  qrtrTechPoint?: number;
  displayLoginPopup: Function;
  askUrl?: string;
}

const SimpleUserProfile = (props: Props) => {
  const [user, setUser] = useState({
    allowExpert: false,
    loginType: "",
    nickname: "",
    profilePhotoFileKey: null,
    techPoint: 0,
    userRoles: "",
  });
  const ask = useRef(null as PopAsk);
  const [overlayEdit, setOverlayEdit] = useState(false);
  const [modifyPost, setModifyPost] = useState(false);

  const loginInfo = useSelector((state: any): IfLoginInfo => state.main.loginInfo);
  const showToast = useShowToast();

  const isEmploy = () => loginInfo.loginType === 'WELS';

  //const [isEmploy, setIsEmploy] = useState(false);

  const usermenu = useRef(null);
  const tpguide = useRef(null);
  const tpref = useRef(null);
  const [askUrl, setAskUrl] = useState('');

  const isUnknown = () => {
    if ((props.userId || "").toLowerCase() === 'unknown') {
      return true;
    }
    return false;
  };

  // async function getLoginType() {
  //   const loginInfo = await axios.get('/api/guest/loginInfo');
  //   const loginType = loginInfo.data.loginType;
  //   setIsEmploy(loginType === "WELS");
  // }

  useEffect(() => {
    axios.get('/api/guest/communityprofile', {
      params: {id: props.userId}
    }).then((res) => {
      setUser(res.data);
    });
  }, [props.userId]);

  // useEffect(() => {
  //   getLoginType();
  // }, []);

  useEffect(() => {
    // props.askurl이 있는경우 : 문서 뷰 화면이 아닌 곳 (작성자가 표시되는 문서 목록)
    if (props.askUrl) setAskUrl(props.askUrl);
    else setAskUrl(window.location.href);
  });

  const renderProfileImage = () => {
    if (isUnknown()) return img_unknown;
    return user.profilePhotoFileKey == null || user.profilePhotoFileKey == "" ? SsqImages.mypage.avatar["avatar06"]() :
      user.profilePhotoFileKey.startsWith("avatar") ? SsqImages.mypage.avatar[user.profilePhotoFileKey]() :
        user.profilePhotoFileKey.startsWith("myphoto") ? ssqutil.getCdnUrl(user.profilePhotoFileKey) : null;
  };

  const trans = (key: string) => {
    svcs.svcLang.setNamespace(i18ns.cmmnty);
    return svcs.svcLang.getTranslationByKey(key) || "";
  };

  const handleClick = (e, cmd: string) => {
    switch (cmd) {
      case 'modifyPost' :
        setModifyPost(!modifyPost);
        break;

      case 'email' :
        loginInfo.userId == undefined ? props.displayLoginPopup() :
          ask.current.show({
            userId: props.userId,
            askUrl: askUrl,
            userNickname: user.nickname,
            profilePhotoFileKey: loginInfo.profilePhotoFileKey,
            techPoint: loginInfo.techPoint
          });
        break;
    }
  };

  const {country, lang} = langutil.splitLangCode(svcs.svcLang.lang());
  let userAvatar = [null, ""].includes(user.profilePhotoFileKey) ? false : true;

  return (
    <SimpleUserProfileArea>
      <ul key={props.userId} className="simple-user-item">
        {props.rank && <span className="best-number">{props.rank}</span>}
        {overlayEdit && <div className={"edit-overlay"} onClick={() => {
          setOverlayEdit(false);
          setModifyPost(false);
        }}></div>}
        <li
          className={`c-name cursor-pointer`}
          onClick={(e) => {
            if ((props.userId || "").toLowerCase() === 'unknown') {
              console.log("unknown user.");
              showToast('unknown');
              return;
            }
            setOverlayEdit(!overlayEdit);
            handleClick(e, 'modifyPost');
          }}>
          <span
            className={clsx(`${isUnknown() ?
              "unknown" : user.techPoint >= 1000 ?
                'first' : user.techPoint >= 500 ?
                  'second' : user.techPoint >= 100 ?
                    'third' : user.techPoint >= 30 ?
                      'fourth' : 'fifth'}`)}>
                    {(userAvatar || isUnknown()) &&
                      <img src={renderProfileImage()} style={{width: '100%', height: '100%', objectFit: 'cover'}} alt=""/>}
            {((!userAvatar && props.highlightedNickName) || user.nickname?.length !== undefined) && user.nickname[0]}
                  </span> {props.highlightedNickName ? parse(getHighlightedTextFromHtml(props.highlightedNickName)) : user.nickname}
          <div className={"class-option"}>
            <div className="user-click-box" style={{display: modifyPost ? 'block' : ''}}>
              <ul>
                <li>
                  <Link to={{pathname: `/${country}/${lang}/myPage`, state: {id: props.userId}}}>
                    <button className="user-click-profile" onClick={(e) => handleClick(e, "profile")}>
                      {trans('I18N_USERPOPMENU_PROFILE')}</button>
                  </Link>
                </li>
                <li>
                  <button className="user-click-mail" onClick={(e) => handleClick(e, "email")}>{trans('I18N_USERPOPMENU_MAIL')}</button>
                </li>
              </ul>
            </div>
          </div>
        </li>
        {!isUnknown() && <>
          {(props.qrtrTechPoint >= 0 && props.qrtrTechPoint !== undefined && props.qrtrTechPoint !== null) ? <li
              className={`c-txt count fifth`}
              onMouseOver={() => tpguide.current.show({
                top: tpref.current.getBoundingClientRect().bottom,
                left: tpref.current.getBoundingClientRect().right
              })}
              onMouseOut={() => tpguide.current.hide()}
            >
              {props.qrtrTechPoint}</li> :
            <li ref={tpref}
              className={`c-txt count ${user.techPoint >= 1000 ? 'first' : user.techPoint >= 500 ? 'second' : user.techPoint >= 100 ? 'third' : user.techPoint >= 30 ? 'fourth' : 'fifth'}`}
              onMouseOver={() => tpguide.current.show({
                top: tpref.current.getBoundingClientRect().bottom,
                left: tpref.current.getBoundingClientRect().right
              })}
              onMouseOut={() => tpguide.current.hide()}
            >
              {user.techPoint}</li>
          }
          {/*전문가*/}
          {user.allowExpert && <li className="c-txt expert">{docutil.getSimpleUserProfileTrans('I18N_COMMUNITY_USER_EXPERT')}</li>}
          {/*임직원*/}
          {!props.rank && isEmploy() && (user.loginType === "WELS") &&
            <li className="c-txt employee">{docutil.getSimpleUserProfileTrans('I18N_SOLUTION_EMPLOYEE')}</li>}
          {/*파트너*/}
          {!props.rank && (!["", undefined, null, "RND"].includes(user.userRoles)) && (isEmploy() || !["", undefined, null, "RND"].includes(loginInfo.userRoles)) &&
            <li className="c-txt partner">{trans("I18N_FILTER_PARTNER")}</li>}
        </>}
      </ul>
      <TechPointGuide ref={tpguide}/>
      <PopAsk ref={ask}/>
    </SimpleUserProfileArea>
  );
};
export default SimpleUserProfile;


export const SimpleUserProfileArea = styled.div`

  user-select: none;

  .edit-overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 7;
  }

  .class-option {
    position: absolute;
    display: flex;

    .user-click-box {
      position: absolute;
      left: 3.8rem;
      top: -2rem;
      width: 9rem;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      background: white;
      display: none;
      z-index: 8;

      > ul > li {
        width: 9rem;
        padding: .4rem .8rem .4rem 1.6rem;
        border-bottom: .1rem solid #C7D6EA;

        &:last-child {
          border: none;
        }

        button {
          width: 100%;
          height: 3.2rem;
          line-height: 3.2rem;
          font-size: 1.2rem;
          ${fontFamilyMedium};
          text-align: left;
          color: #2A2C2F;

          &.user-click-profile {
            background: url("${imgs.common.icon_user_profile}") no-repeat center right;
          }

          &.user-click-mail {
            background: url("${imgs.common.icon_user_mail}") no-repeat center right;
          }
        }
      }
    }
  }


  .simple-user-item {
    display: flex;
    align-items: center;
  }

  li {
    margin-right: 1rem;
    font-size: 1.2rem;
    ${fontFamilyMedium};
    color: #7783A1;
    line-height: 2.4;
  }

  .c-name {
    color: #2A2C2F;

    .search_highlight {
      background: linear-gradient(to top, #FFFAA0 50%, transparent 50%) !important;
      font-weight: initial;
    }

    span {
      width: 2.2rem;
      height: 2.2rem;
      display: inline-block;
      vertical-align: middle;
      border-radius: 2rem;
      overflow: hidden;
      margin-right: .4rem;
      margin-top: -0.2rem;
      color: white;
      text-align: center;
      font-size: 1.2rem;
      line-height: 2rem;
      text-transform: uppercase;

      img {
        background: #EEF1F6;
      }

      &.unknown {
        border: none;
      }
    }
  }

  .c-name span {
    &.first {
      border: 1px solid #EDAE0A !important;
      background: #EDAE0A;
    }

    &.second {
      border: 1px solid #FF7A00 !important;
      background: #FF7A00;
    }

    &.third {
      border: 1px solid #7B5DF1 !important;
      background: #7B5DF1;
    }

    &.fourth {
      border: 1px solid #407EDB !important;
      background: #407EDB;
    }

    &.fifth {
      border: 1px solid #7C899E !important;
      background: #7C899E;
    }
  }

  .c-time {
    background: url(${img_icon_c_time}) no-repeat left center;
    padding-left: 1.8rem;
    margin-left: .8rem;
    background-size: 1.6rem;
    ${fontFamilyRegular};
  }

  .c-view {
    color: #407EDB;
    background: url("${SsqImages.community.icon.communityView()}") no-repeat left center;
    background-size: 1.6rem;
    padding-left: 1.8rem;
    ${fontFamilyRegular};
  }

  .c-good {
    color: #407EDB;
    padding-left: 1.8rem;
    background: url("${SsqImages.community.icon.communityGood()}") no-repeat left center;
  }

  .c-not-bad {
    color: #FF215D;
    background: url(${img_icon_c_not_bad}) no-repeat left center;
    padding-left: 1.8rem;
  }

  .c-txt {
    ${fontFamilyMedium};
    height: 1.8rem;
    line-height: 1.8rem;
    padding: 0 1rem;
    font-size: 1rem;
    border-radius: 2rem;
    margin-right: .4rem;
  }

  .c-txt.count {
    &.first {
      color: #EDAE0A;
      background: rgba(247, 185, 27, 0.10);
    }

    &.second {
      color: #FF7A00;
      background: #FFE4CC;
    }

    &.third {
      color: #7B5DF1;
      background: #E5DFFC;
    }

    &.fourth {
      color: #407EDB;
      background: #D9E5F8;
    }

    &.fifth {
      color: #7C899E;
      background: #E5E7EC;
    }
  }

  .c-txt.expert {
    color: #34A597;
    background: #EBF6F5;
  }

  .c-txt.employee {
    color: #7783A1;
    background: #F1F3F6;
  }

  .c-txt.partner {
    color: #EDAE0A;
    background: rgba(247, 185, 27, 0.10);
  }
`;