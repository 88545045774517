import React from "react";
import img_back_button from "images/header/back_button.svg";

type Props = {
  setPopupPage: (p: number) => void;
  setDisplayCookiePopup: (p: boolean) => void
  trans: (p: string) => string
}

export const CookiePolicyDetail = (props: Props) => {
  return (
    <div className={"cookie_popup_base"}>
      <div className={"popup_top"}>
        <div className={"popup_tit"}>
          <button
            onClick={() => props.setPopupPage(0)}
          >
            <img alt={"back_button.svg"} src={img_back_button}/>
          </button>
          {props.trans('I18N_COOKIE_POPUP_POLICY_DETAIL')}
        </div>
      </div>

      <div className={"popup_middle"}>
        <div className={"cookie_policy_content"}>
          <div className={"cookie_detail"}>

            <h2>Cookies</h2>
            <p><br/></p>

            <p>
              Cookies are small files that store information on your computer, TV, mobile phone, or other device. They
              enable the entity that put the cookie on your device to recognise you across different websites, services, devices, and/or browsing
              sessions. Cookies serve many useful purposes.
            </p><br/>
            <p>For example:</p>
            <ul>
              <li>
                <p>Cookies can remember your sign-in credentials so you don’t have to enter those credentials each time you log on to a service.</p>
              </li>
              <li>
                <p>Cookies help us and third parties understand which parts of our Services are the most popular because they help us to see which
                  pages and features visitors are accessing and how much time they are spending on the pages. By studying this kind of information, we
                  are better able to adapt the services on our website and provide you with a better experience.</p></li>
              <li>
                <p>Cookies help us and third parties understand which ads you have seen so that we can tailor a more relevant communication and
                  service to you.</p>
              </li>
              <li>
                <p>Cookies help us and third parties provide you with relevant content and advertising by collecting information about your use of
                  our Services and other websites and apps.</p>
              </li>
            </ul>
            <br/>
            <p>When you access the website, you will be asked to accept all cookies on our Cookies Management Tool, which have been categorized into
              ‘types’ outlined in more detail below. This tool will also allow you to configure your preferences to accept certain cookies, if you do
              not want to accept all cookies. You can also configure your preferences in your browser settings which will affect the cookies that you
              will see on our Cookies Management Tool (for example, you may not be able to see third party cookies). Each browser is different, so
              check the “Help” menu of your browser to learn how to change your cookie preferences. The operating system of your device may contain
              additional controls for cookies.<br/>Please note, however, that some aspects of our website are designed to work using cookies and that
              disabling cookies may affect your ability to use those aspects of our website, or certain parts of them. For more information on how
              your preferences will impact our website functionality, please refer to our Cookies Management Tool: <a
                href="https://www.samsung.com/uk/info/privacy/cookie-policy/#" target="_blank"><u>Cookie Preferences</u></a>.
            </p>

            <p><br/><br/></p>

            <p>We use the following
              types of cookies on our website:<br/><b>Essential Cookies:</b>&nbsp;enable you to receive the services you request via our
              website. Without these cookies, services that you have asked for cannot be provided. For example, essential cookies enable us to
              remember
              the items you place in the shopping basket so you can order products. These cookies are automatically enabled and cannot be turned off
              because they are essential to enable you to browse our website. Without these cookies our website and e-store services could not be
              provided.<br/>We will use these type of cookies for a period of 13 months.

            </p>
            <br/>
            <p>

              <b>Performance Cookies:</b>&nbsp;enable us to
              analyse the performance and design of our website and detect errors. For example, this type of cookie enables us to recognise that you
              have visited our website before and shows which sections of our website are most popular by allowing us to see which pages visitors
              access
              most frequently and how much time visitors spend on each page. We use, without limitation, Google Analytics, Adobe, and similar
              analytics
              cookies to achieve this.<br/>For example, the information generated by the cookie about the use of the website will normally be stored
              on
              a Google server in the United States and your IP address will be shortened by Google beforehand. In exceptional cases only, your full IP
              address will be transmitted to a Google server in the United States and shortened there. On our behalf, Google will use this information
              for the purpose of compiling reports on website activity and providing other services relating to website activity and internet usage.
              The
              IP address transmitted from your browser as part of Google Analytics will not be associated with any other data held by Google.<br/>For
              more information on other service providers, please contact us<br/>This type of cookie is automatically disabled and can be configured
              to
              your particular preferences.<br/>We will use these type of cookies for a period of 12 months.
            </p>

            <br/>
            <p><b>Functional
              Cookies:</b>&nbsp;allow us to deliver a better user experience. For example, this type of cookie ensures that the information
              displayed on your next visit to our website will match up with your user preferences, or your Solution Square account from which you
              linked to our website, or the fact that you linked to our website via an email sent to you by LS ELECTRIC or one of our trusted third
              party service providers contacting you on our behalf.<br/>This cookie is automatically disabled and can be configured to your particular
              preferences.<br/>We will use these type of cookies for a period of 13 months.
            </p>
            <br/>
            <p>

              <b>Advertising Cookies:</b>&nbsp;enables us
              and our carefully selected partners to personalise our website, deliver advertisements to you, or contact you directly where you have
              separately consented to such communications, in a way which is relevant to you and which matches your interests by, for example, using
              information about products you have browsed or ordered on our website. For example, and without limitation, we use Google remarketing
              tags
              to allow our retail partners to advertise products which you have browsed on our website. For further information about how Google
              remarketing tags work, please&nbsp;<a href="https://www.thinkwithgoogle.com/intl/en-gb/" target="_blank"><u>click here</u></a>.<br/>We
              may
              also allow
              carefully selected third parties to place cookies or similar technologies onto our site for this purpose.<br/>We may use and combine
              your
              cookie data with the information we collect about you from other sources to provide you with a better experience for example to provide
              you with more relevant direct marketing (with your separate consent).<br/>We may also work with carefully selected business partners to
              deliver advertising jointly; this may involve combining cookie data collected from our website with data collected by our business
              partner.<br/>Depending which cookies you enable, we may also use information collected to understand our customers’ preferences and to
              influence future advertising campaigns including how we select who we direct our marketing to.<br/>We and carefully selected business
              partners may place cookies when we show ads for our products or services on third-party websites and when you click on those ads, so we
              know which sites our customers see and click on our ads. We and our business partners only use these cookies if you give your consent to
              the operator of the third-party site, and you can adjust your preferences in relation to these cookies on those third-party sites.<br/>We
              will use these type of cookies for a period of 13 months.
            </p>

            <p><br/><br/></p>

            <p><b>Third party cookies:</b>&nbsp;Our website may
              also display content from a third party website or service, which may result in cookies from third parties being stored on your hard
              drive
              or browser. These third parties can be seen and their cookies can be blocked in our Cookies Management Tool:&nbsp;<a
                href="https://www.samsung.com/uk/info/privacy/cookie-policy/#" target="_blank"><u>Cookie Preferences</u></a>. We do not control the
              storage and use of
              these cookies by these third parties and you should refer to the privacy policies of the relevant third parties for more
              information.<br/>This
              type of cookie is automatically disabled and can be configured to your particular preferences.
            </p>
            <br/>

            <br/>
            <h2>Other Local Storage</h2>
            <br/>
            <p>We, along
              with certain third parties, may use other kinds of local storage technologies, such as Local Shared Objects (also referred to as “Flash
              cookies”) and HTML5 local storage, in connection with our Services. These technologies are similar to the cookies discussed above in
              that
              they are stored on your device and can be used to store certain information about your activities and preferences. However, these
              technologies may make use of different parts of your device from standard cookies, and so you might not be able to control them using
              standard browser tools and settings. For information about disabling or deleting information contained in Flash cookies, please&nbsp; <a
                href="https://www.allaboutcookies.org/manage-cookies/" target="_blank"><u>click here</u></a>.</p>

            <br/><br/><h2>Pixels</h2><br/><p>Pixels on our website also deliver
            information about the website usage to ls-elelctric.com &amp; third party tracing servers, but do not store the information on the users'
            browser like a cookie does. Pixels allow Samsung to ensure the site is running and pages are loading.&nbsp;</p><p>&nbsp;</p><p>Pixels on
            our website are categorised and managed in the same way as cookies.&nbsp; Performance, functional and advertising pixels are disabled and
            can be configured to your preferences using the Cookies Management Tool.</p><p>&nbsp;</p><p>An e-mail pixel is a similar technology used
            by <a href="http://ls-electric.com" target="_blank">ls-electric.com</a>
            in communications sent via e-mail. Where you have separately consented to receiving communications which have been carefully selected from
            our partners to deliver advertisements to you, each communication contains both a cookie and pixel.&nbsp;</p>

            <br/><br/><h2>Managing cookies and other technologies</h2>
            <br/><p>If you are based in Europe, you also can adjust your cookie preferences and withdraw your consent by adjusting
            the Cookie Settings through the Cookies Management Tool, which can be found here: &nbsp;<a
              href="https://www.samsung.com/uk/info/privacy/cookie-policy/#" target="_blank"><u>Cookie Preferences</u></a>.</p><p>You can also update
            your browser
            settings at any time, if you want to remove or block cookies from your device (consult your browser's "help" menu to learn how to remove
            or block cookies). LS Electric is not responsible for your browser settings. You can find good and simple instructions on how to manage
            cookies on the different types of web browsers at&nbsp;<a href="https://www.allaboutcookies.org/"
              target="_blank"><u>https://www.allaboutcookies.org/</u></a>.
          </p><p>Please be aware that rejecting cookies may affect your ability to perform certain transactions on the website, and our ability to
            recognize your browser from one visit to the next</p>
            <br/><br/>
            <b>Updated 2024.04.17</b>

          </div>
        </div>
      </div>

      <div className={"popup_bottom"}>
        <button className={"popup_bottom_submit"} onClick={() => props.setPopupPage(0)}>
          {props.trans('I18N_COOKIE_POPUP_CONFIRM')}
        </button>
      </div>


    </div>
  );
};
