// @flow
import * as React from 'react';
import {forwardRef, RefObject, useCallback, useImperativeHandle, useRef, useState} from 'react';
import {Modal} from "ctls/modal/Modal";
import styled from 'styled-components';
import {IconBtn, TextBtn} from "asset/style/buttonInput";
import {PopCnts} from "asset/cmpns/popup/common/ConfirmModal";
import * as colors from "asset/style/color";
import {StatusIcon} from "asset/cmpns/common/StatusIcon";

class WarningPopupParam {
  msgs: string[];
  savebtn?: string;
  cancelbtn?: string;
  alert?: boolean;
  fault?: boolean;
  title?: string;
}

export interface IfWarningPopupParam extends WarningPopupParam {
}

export type RefWarningPopup = {
  show(param: IfWarningPopupParam): Promise<any>;
};

export type RefWarning = RefObject<RefWarningPopup>
export const WarningPopup = forwardRef<RefWarningPopup, any>((props, ref) => {
  const [visible, setVisible] = useState<boolean>(false);
  const promise = useRef<{ resolve: any; reject: any }>({resolve: null, reject: null});
  const [param, setParam] = useState(new WarningPopupParam());

  const handleClickOpen = () => {
    setVisible(true);
  };
  const handleClose = () => {
    promise.current.reject();
    setVisible(false);
  };

  const handleConfirm = () => {
    promise.current.resolve();
    setVisible(false);
  };

  useImperativeHandle(ref, () => ({
    show: (param: IfWarningPopupParam) => {
      return new Promise((resolve, reject) => {
        promise.current.resolve = resolve;
        promise.current.reject = reject;
        param.alert = param.alert ?? false;
        param.fault = param.fault ?? false;
        param.savebtn = param.savebtn ?? 'Confirm';
        param.cancelbtn = param.cancelbtn ?? 'Cancel';
        setParam(param);
        handleClickOpen();
      });
    },
  }));
  return (<>
      {visible ? (<Modal onClick={(e) => e.stopPropagation()} onScroll={true}>
        <WarningPopCnts className={param.fault && 'fault_popup'}>
          <div className={"pop_tit"}>
            {param.fault ? <span><StatusIcon icon={"fault"} size={24}/>{param.title ?? 'Fault'}</span> :
              <span><StatusIcon icon={"warning"} size={24}/>{param.title ?? 'Warning'}</span>}
            <IconBtn onClick={handleClose} className={"close"}></IconBtn>
          </div>
          <div className={"pop_body"}>
            {/*The Project will be deleted.<br/>*/}
            {/*Are you sure to proceed?*/}
            {param.msgs
              .map((ele, idx) => <React.Fragment key={`${idx}_${ele}`}>{ele}</React.Fragment>)
              .reduce<JSX.Element[]>((accu, elem, idx) => {
                return accu === null ? [elem] : [...accu, <br key={idx}/>, elem];
              }, null)
            }
          </div>
          <div className={'pop_btn action'}>
            {!param.alert && <TextBtn className="size-md" onClick={handleClose}>{param.cancelbtn}</TextBtn>}
            <TextBtn className="size-md primary" onClick={handleConfirm}>{param.savebtn}</TextBtn>
          </div>
        </WarningPopCnts>
      </Modal>) : null}
    </>
  );
});

const WarningPopCnts = styled(PopCnts)`
  border-top: solid 3px ${colors.warning};

  &.fault_popup {
    border-top-color: ${colors.fault};
  }

  .pop_tit span {
    display: inline-flex;
    align-items: center;

    .size24 {
      margin-right: 14px;
    }
  }
`;
WarningPopup.displayName = "WarningPopup";

export const useWarningPopup = (): [v: RefObject<RefWarningPopup>, e: () => JSX.Element] => {
  const ref = useRef<RefWarningPopup>(null);
  const [cmpn] = useState(<WarningPopup ref={ref}/>);
  const element = useCallback(() => cmpn, [cmpn]);
  return [ref, element];
};
