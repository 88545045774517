import {resi18nutil} from "language/resi18nutil";
import {resi18n_all_en, resi18n_all_enus, resi18n_all_ko} from "language/resi18n_all";

const resi18n_header = {
  I18N_TITLE_MENU: ["메뉴", "Menu", undefined],
  I18N_TITLE_PRODUCT: ["제품", "Products", undefined],
  I18N_TITLE_SOLUTIONS: ["산업", "Industry", undefined],
  I18N_TITLE_SOLUTIONS_TYPE01: ["산업", "Industry", undefined],
  I18N_TITLE_SOLUTIONS_TYPE02: ["적용 부하", "Application", undefined],
  I18N_TITLE_COMMUNITY_QNA: ["Q&A", "Q&A", undefined],
  I18N_TITLE_COMMUNITY_BLOG: ["Knowledge Base", "Knowledge Base", undefined],
  I18N_TITLE_COMMUNITY_BOARD: ["게시판", "Bulletin Board", undefined],
  I18N_TITLE_COMMUNITY_LS_PARTNER: ["LS Partners", "LS Partners", undefined],
  I18N_TITLE_COMMUNITY_RND_MARK: ["R&D 전용", "EN_R&D 전용", undefined],
  I18N_TITLE_COMMUNITY_PARTNER_MARK: ["Partner Only", "Partner Only", undefined],
  I18N_TITLE_COMMUNITY_RND: ["알록달록 연구소", "Colorful LS", undefined],
  I18N_TITLE_COMMUNITY_QNA_INTRO: ["묻고 답하기", "Be a problem solver", undefined],
  I18N_TITLE_COMMUNITY_BLOG_INTRO: ["기술 블로그", "Knowledge-Sharing for your expertise", undefined],
  I18N_TITLE_COMMUNITY_BOARD_INTRO: ["자유 게시판", "Everything you need", undefined],
  I18N_TITLE_COMMUNITY_LS_PARTNER_INTRO: ["파트너 전용 게시판", "Partner Only", undefined],
  I18N_TITLE_COMMUNITY_RND_INTRO: ["자동화 Global RnD Campus 소식", "Colorful LS", undefined],
  I18N_TITLE_SERVICE_SAMPLE: ["샘플 라이브러리", "Sample Library", undefined],
  I18N_TITLE_SERVICE_CLASSROOM: ["실시간 강의실", "Online Training", undefined],
  I81N_TITLE_SERVICE_PROD_SELECTOR: ["제품 선정 가이드", "Product Selector", undefined],
  I18N_TITLE_SW_SERVICE: ['SW 솔루션', 'Software Solution', undefined],
  I18N_TITLE_SW_SERVICE_INFO: ['SW 솔루션', 'Software Solution', undefined],
  I81N_TITLE_SERVICE_PROD_SELECTOR_INTRO: ["Drive / HMI 제품 선정 가이드", "LV Drive / HMI", undefined],
  I18N_TITLE_SERVICE_REMOTE: ["원격 지원", "Remote Technical Support", undefined],
  I18N_TITLE_SERVICE_SAMPLE_INTRO: ["샘플, 라이브러리, 템플릿 공유", "Access to a variety of sample codes and projects", undefined],
  I18N_TITLE_SERVICE_CLASSROOM_INTRO: ["실시간 온라인 교육", "Join online training courses", undefined],
  I18N_TITLE_SERVICE_REMOTE_INTRO: ["원격 지원 서비스", "Get remote assistance on your screen", undefined],
  I18N_TITLE_COMMUNITY: ["커뮤니티", "Community", undefined],
  I18N_TITLE_SERVICE: ["서비스", "Service", undefined],
  I18N_TITLE_LOGIN: ["로그인", "Login", undefined],
  I18N_TITLE_LOGIN_HEADER: ["로그인 / 회원가입", "Login / Sign Up", undefined],
  I18N_TITLE_SOCIAL_LOGIN: ["소셜 로그인 / 가입하기", "Social Login / Sign Up", undefined],
  I18N_TITLE_LAST_LOGIN: ["최근 로그인", "Last Login", undefined],
  I18N_TITLE_LOGOUT: ["로그아웃", "Logout", undefined],
  I18N_TITLE_WELS_LOGIN: ["임직원 로그인", "Login with WeLS ID", undefined],
  I18N_TITLE_MYINFO: ["내정보 바로가기", "Go to my account", undefined],
  I18N_TITLE_LEVEL: ["회원", "Member", undefined],
  I18N_TITLE_LEVEL_ADMIN: ["관리자", "Admin", undefined],
  I18N_TITLE_MYPAGE: ["내 프로필", "Profile", undefined],
  I18N_TITLE_USER_SETTINGS: ["계정 설정", "User Settings", undefined],
  I18N_TITLE_ALARMS: ['내 알림', "Alarms", undefined],
  I18N_TITLE_POST: ["글쓰기", "Write", undefined],
  I18N_TITLE_SETTINGS: ["관리자 설정", "Admin Settings", undefined],
  I18N_TITLE_SEARCH: ["검색..", "Search..", undefined],
  I18N_FILTER_KOREA: ["대한민국", "대한민국", undefined],
  I18N_FILTER_GLOBAL: ["Global", "Global", undefined],
  I18N_FILTER_USA: ["USA", "USA", undefined],
  I18N_FILTER_KOREAN: ["한국어", "한국어", undefined],
  I18N_FILTER_ENGLISH: ["영어", "English", undefined],
  I18N_FILTER_ENGLISH_US: ["영어(US)", "English(US)", undefined],
  I18N_FILTER_LANGUAGE_KO_KR: ["한국어", "Korean", undefined],
  I18N_FILTER_LANGUAGE_EN: ["English", "English", undefined],
  I18N_FILTER_LANGUAGE_EN_US: ["English", "English", undefined],
  I18N_FILTER_KO_KR: ["대한민국", "Korea", undefined],
  I18N_FILTER_EN: ["Global", "Global", undefined],
  I18N_FILTER_EN_US: ["USA", "USA", undefined],
  I18N_LOGIN_NOTIFICATION: ["일부 컨텐츠를 이용하시려면 로그인이 필요합니다.", "To access more content, create an account", undefined],
  I18N_TITLE_PASSWORD: ["비밀번호", "Password", undefined],
  I18N_TITLE_PASSWORD_CHECK: ["비밀번호 확인", "Confirm Password", undefined],
  I18N_TITLE_JOIN: ["회원가입", "Sign Up", undefined],
  I18N_TITLE_ACCEPT_TERMS: ["약관 동의", "Terms and Conditions", undefined],
  I18N_TITLE_ACCEPT_TERMS_INFORMATION: ["필수 및 선택 정보에 대한 동의도 포함되며, 개별선택 가능합니다. 선택 항목 거부시에도 서비스 이용이 가능합니다.", "This includes agreements to all required and optional terms. You may choose them individually.", undefined],
  I18N_TITLE_NEXT_PAGE_UP: ["다음", "Next", undefined],
  I18N_TITLE_PASSWORD_RESET: ["PW 초기화", "Reset PW", undefined],
  I18N_LOGIN_FAIL_TITLE: ["계정 정보 확인", "Invalid user information.", undefined],
  I18N_LOGIN_FAIL_NOTIFICATION: ["입력하신 정보에 해당하는 회원 정보가 없습니다.", "Verify your login information", undefined],
  I18N_LOGIN_FAIL_COUNT_NOTIFICATION: ["로그인 5회 실패시 차단됩니다.", "If you fail 5 times, it will be blocked.", undefined],
  I18N_LOGIN_BLOCKED_NOTIFICATION: ["로그인을 5회 실패하여 차단되었습니다. PW초기화를 통해 차단을 해제하세요", "The login failed 5 times and was blocked. Unblock it by initializing the password.", undefined],
  I18N_BACK_TO_LOGIN: ["로그인 화면으로 가기", "Go to Login page.", undefined],
  I18N_LOGIN_FAIL_EXIST_SSQ: ["해당 계정은 일반 계정으로 가입된 주소입니다. 메일 주소/비밀번호를 입력하여 로그인 해주세요.", "This account is a registered address as a regular account. <br/>Please enter your email address/password to log in.", undefined],
  I18N_LOGIN_FAIL_EXIST_SNS: ["해당 계정은 소셜 계정으로 가입된 주소입니다. 소셜 서비스를 이용하여 로그인 해주세요.", "This account is a social account registered address. <br/>Please log in using social services.", undefined],
  I18N_LOGIN_FAIL_EXIST_WELS: ["해당 계정은 임직원으로 가입된 주소입니다. 임직원 로그인을 이용하여 로그인 해주세요.", "This account is an address that has been registered as an employee. <br/>Please log in using the employee log in.", undefined],
  I18N_LOGIN_NOEMAIL_ALERT: ['이메일 정보가 없습니다. 해당 소셜에서 이메일이 등록되어 있는지 확인해 주세요', 'There is no email information. Check if the e-mail is registered on the social media', undefined],
  I18N_SIGNUP_AUTHENTICATION: ["메일 인증", "Email verification", undefined],
  I18N_SIGNUP_WRITE_EMAIL: ["가입하실 메일 주소를 입력해 주세요.", "Please use your email address to create your account.", undefined],
  I18N_SIGNUP_MANDATORY_INPUT: ["필수 입력값", "Required fields", undefined],
  I18N_SIGNUP_EMAIL: ["메일 주소", "E-mail", undefined],
  I18N_SIGNUP_PLACEHOLDER_INPUT: ["ex) abc@company.com", "ex) abc@company.com", undefined],
  I18N_SIGNUP_EMAIL_DUPLICATE_ALERT: ["이미 존재하는 메일 주소 입니다.", "This email already exists.", undefined],
  I18N_SIGNUP_EMAIL_EMPLOYEE_ALERT: ["‘임직원 로그인’ (로그인 팝업 우측하단)으로 접속해 주세요.", "Login with WeLS ID (Go to the login pop-up).", undefined],
  I18N_SIGNUP_EMAIL_INVALID_ALERT: ["메일 주소 형식을 확인해 주세요.", "Please enter a valid email address", undefined],
  I18N_SIGNUP_EMAIL_IS_NOT_SSQ: ["일반 로그인 이외 소셜/임직원 회원께서는 비밀번호 초기화를 할 수 없습니다.", "Social/employee members other than regular logins cannot reset their passwords."],
  I18N_SIGNUP_AUTHENTICATION_MAIL: ["인증 메일 전송", "Verification email has been sent.", undefined],
  I18N_SIGNUP_CHECK: ["확인", "Confirm", undefined],
  I18N_DUPLICATE_CHECK_EMAIL: ["메일 주소 확인", "Confirm email address", undefined],
  I18N_DUPLICATE_INPUT_ANOTHER_EMAIL: ["다른 메일 주소 입력하기", "Input another email address", undefined],
  I18N_DUPLICATE_INPUT_INFORMATION: ["정보를 입력해 주세요.", "Please enter your information", undefined],
  I18N_SIGNUP_LEAVE_POPUP_ALERT: ["회원가입 페이지를 나가시겠습니까? 이미 입력된 정보는 사라집니다", "Are you sure you want to leave this page?", undefined],
  I18N_SIGNUP_INVALID_PASSWORD_PLACEHOLDER: ["10~16자 텍스트, 영문 / 숫자 포함", "10~16 characters, a mix of letters, numbers.", undefined],
  I18N_SIGNUP_INVALID_PASSWORD_ALERT: ["10~16자 텍스트, 영문 / 숫자를 포함하여 입력해 주세요.", "10-16 characters, a mix of letters and numbers", undefined],
  I18N_SIGNUP_PASSWORD_CHECK_ALERT: ["비밀번호가 일치하지 않습니다.", "The password does not matched.", undefined],
  I18N_SIGNUP_NICKNAME: ["프로필 이름", "Nickname", undefined],
  I18N_SIGNUP_BUSINESS: ["추가 정보", "Business", undefined],
  I18N_SIGNUP_NICKNAME_PLACEHOLDER: ["10자 이하 텍스트", "Max. 10 characters", undefined],
  I18N_SIGNUP_NICKNAME_INVALID_ALERT: ["10자 이하 텍스트를 입력해 주세요.", "Input max. 10 characters.", undefined],
  I18N_SIGNUP_NAME_PLACEHOLDER: ["20자 이하 텍스트", "Max. 20 characters", undefined],
  I18N_SIGNUP_NAME_INVALID_ALERT: ["20자 이하 텍스트를 입력해 주세요.", "Enter max. 20 characters", undefined],
  I18N_SIGNUP_NICKNAME_DUPLICATE_ALERT: ["이미 존재하는 별명입니다.", "This nickname already exists", undefined],
  I18N_SIGNUP_NAME: ["성명", "Name", undefined],
  I18N_SIGNUP_FIRST_NAME: ["이름", "First name", undefined],
  I18N_SIGNUP_LAST_NAME: ["성", "Last name", undefined],
  I18N_SIGNUP_COUNTRY: ["국가", "Country", undefined],
  I18N_SIGNUP_PHONE_NUMBER: ["전화 번호", "Phone Number", undefined],
  I18N_SIGNUP_PHONE_NUMBER_PLACEHOLDER: ["숫자만", "Only number", undefined],
  I18N_SIGNUP_COUNTRY_TYPE_SELECT: ["선택", "Select", undefined],
  I18N_SIGNUP_MEMBER_TYPE: ["업종", "Job", undefined],
  I18N_SIGNUP_MEMBER_TYPE_SELECT: ["선택", "Select", undefined],
  I18N_SIGNUP_MEMBER_TYPE_MACHINE_MAKER: ["기계업체", "Machine builder", undefined],
  I18N_SIGNUP_MEMBER_TYPE_SMALL_WHOLESALE: ["소/도매", "Wholesaler", undefined],
  I18N_SIGNUP_MEMBER_TYPE_SET_MAKER: ["세트메이커", "System Integrator", undefined],
  I18N_SIGNUP_MEMBER_TYPE_ELECTRIC_COMPANY: ["전기업체", "Electrical", undefined],
  I18N_SIGNUP_MEMBER_TYPE_DEALERSHIP: ["특약점", "Distributor", undefined],
  I18N_SIGNUP_MEMBER_TYPE_PANEL_MAKER: ["판넬업체", "Panel maker", undefined],
  I18N_SIGNUP_MEMBER_TYPE_STUDENT: ["학생", "Educational Institution", undefined],
  I18N_SIGNUP_MEMBER_TYPE_ETC: ["기타", "Etc.", undefined],
  I18N_SIGNUP_SCHOOL: ["학교", "Institution", undefined],
  I18N_SIGNUP_SCHOOL_PLACEHOLDER: ["ex) OO대학교", "ex) OOO University", undefined],
  I18N_SIGNUP_MAJOR_GRADE: ["학과/학년", "Department", undefined],
  I18N_SIGNUP_MAJOR_GRADE_PLACEHOLDER: ["ex) 전기전자공학/3학년", "ex) Major / 3rd", undefined],
  I18N_SIGNUP_COMPANY: ["회사", "Company", undefined],
  I18N_SIGNUP_COMPANY_PLACEHOLDER: ["ex) LS ELECTRIC", "ex) LS ELECTRIC", undefined],
  I18N_SIGNUP_DEPARTMENT_TITLE: ["부서/직위", "Department / Job level", undefined],
  I18N_SIGNUP_DEPARTMENT_TITLE_PLACEHOLDER: ["ex) 기술팀/과장", "ex)Marketing / Manager", undefined],
  I18N_SIGNUP_INTEREST_FIELD: ["관심 산업", "Industry", undefined],
  I18N_SIGNUP_INTEREST_FIELD_TYPE_SELECT: ["선택", "Select", undefined],
  I18N_SIGNUP_INTEREST_FIELD_TYPE_RECHARGEABLE_BATTERY: ["2차전지", "Battery", undefined],
  I18N_SIGNUP_INTEREST_FIELD_TYPE_PROCESSING_MACHINE: ["가공기", "Machining Center", undefined],
  I18N_SIGNUP_INTEREST_FIELD_TYPE_CONSTRUCTION: ["건설", "Construction", undefined],
  I18N_SIGNUP_INTEREST_FIELD_TYPE_RUBBER: ["고무", "Rubber / Plastic", undefined],
  I18N_SIGNUP_INTEREST_FIELD_TYPE_AIRPORT_AIR: ["공항/항공", "Airport", undefined],
  I18N_SIGNUP_INTEREST_FIELD_TYPE_METAL: ["금속", "Metals", undefined],
  I18N_SIGNUP_INTEREST_FIELD_TYPE_DISPLAY: ["디스플레이", "Display", undefined],
  I18N_SIGNUP_INTEREST_FIELD_TYPE_ROBOT: ["로봇", "Robotics", undefined],
  I18N_SIGNUP_INTEREST_FIELD_TYPE_MOBILE: ["모바일", "Mobile", undefined],
  I18N_SIGNUP_INTEREST_FIELD_TYPE_CARPENTRY: ["목공", "Wood", undefined],
  I18N_SIGNUP_INTEREST_FIELD_TYPE_SEMICONDUCTOR: ["반도체", "Semiconductor", undefined],
  I18N_SIGNUP_INTEREST_FIELD_TYPE_DEVELOPMENT: ["발전", "Power", undefined],
  I18N_SIGNUP_INTEREST_FIELD_TYPE_FIBER: ["섬유", "Fiber / Textiles", undefined],
  I18N_SIGNUP_INTEREST_FIELD_TYPE_WATER_TREATMENT: ["수처리", "Water", undefined],
  I18N_SIGNUP_INTEREST_FIELD_TYPE_SMART_FACTORY: ["스마트공장", "Smart Factory", undefined],
  I18N_SIGNUP_INTEREST_FIELD_TYPE_FOOD: ["식품", "Food / Beverage", undefined],
  I18N_SIGNUP_INTEREST_FIELD_TYPE_ELEVATOR: ["엘리베이터", "Elevator", undefined],
  I18N_SIGNUP_INTEREST_FIELD_TYPE_GLASS_CEMENT: ["유리/시멘트", "Glass / Cement", undefined],
  I18N_SIGNUP_INTEREST_FIELD_TYPE_MEDICAL_TREATMENT: ["의료", "Healthcare / Bio", undefined],
  I18N_SIGNUP_INTEREST_FIELD_TYPE_CAR: ["자동차", "Automotive", undefined],
  I18N_SIGNUP_INTEREST_FIELD_TYPE_ELECTRIC: ["전기", "Electric Utility", undefined],
  I18N_SIGNUP_INTEREST_FIELD_TYPE_RESTRAINT: ["제지", "Pulp / Paper", undefined],
  I18N_SIGNUP_INTEREST_FIELD_TYPE_STEEL: ["철강", "Steel", undefined],
  I18N_SIGNUP_INTEREST_FIELD_TYPE_CONVEYOR: ["컨베이어", "Conveyor", undefined],
  I18N_SIGNUP_INTEREST_FIELD_TYPE_PACKING_MACHINE: ["포장기", "Packaging", undefined],
  I18N_SIGNUP_INTEREST_FIELD_TYPE_PORT_SHIPBUILDING: ["항만/조선", "Marine", undefined],
  I18N_SIGNUP_INTEREST_FIELD_TYPE_CHEMISTRY: ["화학", "Chemical", undefined],
  I18N_SIGNUP_INTEREST_FIELD_TYPE_ENVIRONMENT: ["환경", "Environment", undefined],
  I18N_SIGNUP_INTEREST_FIELD_TYPE_ETC: ["기타", "Etc.", undefined],
  I18N_SIGNUP_ACCEPT_TERMS_CONDITIONS: ["이용 약관", "Terms", undefined],
  I18N_SIGNUP_ACCEPT_ALL: ["전체 동의", "Accept All", undefined],
  I18N_SIGNUP_ACCEPT_TERMS: ["(필수) 이용 약관 동의", "(Required) Terms and Conditions", undefined],
  I18N_SIGNUP_ACCEPT_PRIVATE_ESSENTIAL: ["(필수) 개인정보 수집 및 이용 동의", "(Required) Personal Information Collection and Use Agreement", undefined],
  I18N_SIGNUP_ACCEPT_PRIVATE_ESSENTIAL_DETAIL: ["이용 약관", "Terms", undefined],
  I18N_SIGNUP_ACCEPT_PRIVATE_OPTIONAL: ["(선택) 마케팅 활용 동의", "(Optional) Privacy Policy", undefined],
  I18N_SIGNUP_ACCEPT_PRIVATE_OPTIONAL_DETAIL: ["이용 약관", "Terms", undefined],
  I18N_SIGNUP_ACCEPT_MAIL: ["(선택) 뉴스레터 수신 동의", "(Optional) Consent to receive newsletter", undefined],
  I18N_SIGNUP_ACCEPT_MAIL_NOTIFICATION: ["수신 동의 시 LS ELECTRIC 뉴스레터를 메일로 보내드립니다.", "Subscribe to LS ELECTRIC Newsletter", undefined],
  I18N_TERMS_DETAIL_POPUP_TERMS: ["이용 약관", "Terms and Conditions", undefined],
  I18N_TERMS_DETAIL_POPUP_EVENT: ["개인정보 처리방침", "Privacy Policy", undefined],
  I18N_SIGNUP_REQUIRED_ALERT: ["필수 항목 입니다.", "This field is required", undefined],
  I18N_SIGNUP_BUTTON_SIGNUP: ["가입하기", "Registration", undefined],
  I18N_SEND_VERIFY_EMAIL_NOTIFICATION1: ["가입이 완료되었습니다. ", "Your account has been successfully created. ", undefined],
  I18N_SEND_VERIFY_EMAIL_NOTIFICATION2: [" 메일 주소로 인증 메일이 전송 되었으니, 인증 후 계속 진행해 주세요.", " A verification link has been sent to your email account", undefined],
  I18N_SEND_VERIFY_EMAIL_RECEIVE: ["인증 메일이 도착하지 않으셨나요?", "Didn't receive the email?", undefined],
  I18N_SEND_VERIFY_EMAIL_RETRY_NOTIFICATION: ["스팸메일함을 확인하거나, 잠시후 다시 시도해 주시기 바랍니다.", "Check your spam list, try it again.", undefined],
  I18N_SEND_VERIFY_EMAIL_RESEND: ["인증 메일 다시 보내기", "Resend verification email", undefined],
  I18N_MANAGE_USERINFO: ["내 정보", "My information", undefined],
  I18N_MANAGE_USERINFO_NOTIFICATION: ["정보를 입력한 후 저장해 주세요.", "Register your info. and save it.", undefined],
  I18N_MANAGE_USERINFO_LEAVE: ["탈퇴하기", "Delete", undefined],
  I18N_MANAGE_USERINFO_SAVE: ["저장하기", "Save", undefined],
  I18N_MANAGE_USERINFO_SAVE_ALERT: ["정보가 변경되었습니다.", "Information has been changed", undefined],
  I18N_BROWSER_CHECK_ALERT: ["접속하신 브라우저는 지원하지 않습니다. (권장하는 브라우저는 Chrome 입니다.)", "Current browser is not supported. (Recommended browser is Chrome.)", undefined],
  I18N_SOCIAL_SIGNUP_SUCCESS: ["회원 가입이 정상적으로 완료 되었습니다.", "Signup is completed successfully.", undefined],
  I18N_PASSWORD_RESET_TITLE: ["로그인 정보 변경", "Change login information", undefined],
  I18N_PASSWORD_RESET_NOTIFICATION: ["가입하신 메일 주소를 입력해 주세요", "Please enter your email address", undefined],
  I18N_PASSWORD_CHANGE: ["비밀번호 변경하기", "Change Password", undefined],
  I18N_EMAIL_ADDRESS: ["메일 주소", "Email Address", undefined],
  I18N_NOT_SIGNED_UP_EMAIL_ALERT: ["회원 정보가 없습니다. 신규 가입이 필요합니다.", "There is no account. Please sign up.", undefined],
  I18N_SEND_EMAIL_RESET_PASSWORD_TITLE: ["비밀번호 변경 메일 전송", "Send password reset email.", undefined],
  I18N_SEND_EMAIL_RESET_PASSWORD_NOTIFICATION: ["메일 주소로 인증 메일이 전송 되었으니, 인증 후 계속 진행해 주세요.", "Authentication mail has been sent to the email, please proceed after authentication.", undefined],
  I18N_SEND_EMAIL_RESET_PASSWORD_LOGIN_BUTTON: ["로그인하기", "To Login", undefined],
  I18N_SEND_EMAIL_RESET_PASSWORD_QUESTION: ["메일이 도착하지 않았나요?", "Didn't you receive the email?", undefined],
  I18N_SEND_EMAIL_RESET_PASSWORD_GUIDE: ["스팸메일함을 확인하거나, 잠시 후 다시 시도해 주시기 바랍니다.", "Please check your spam mailbox or try again in a moment.", undefined],
  I18N_SEND_EMAIL_RESET_PASSWORD_RESEND: ["메일 다시 보내기", "Resend the email.", undefined],
  I18N_UNSUPPORTED_URL_TIT: ["주소가 만료 되었거나, 요청하신 페이지가 없습니다.", "The URL address has expired or does not exist.", undefined],
  I18N_UNSUPPORTED_URL_TXT: ["URL주소를 확인해 주세요.", "Please check the URL address.", undefined],
  I18N_UNSUPPORTED_BROWSER_WELCOME: ["LS ELECTRIC 자동화 엔지니어링 정보 검색 포탈", "Welcome to", undefined],
  I18N_UNSUPPORTED_BROWSER_TIT: ["지원하지 않는 브라우저 입니다.", "Unsupported Browser", undefined],
  I18N_UNSUPPORTED_BROWSER_TXT: ["Chrome 또는 Microsoft Edge를 이용해 주세요.", "Use Chrome or Microsoft Edge for Web Experience.", undefined],
  I18N_COMMUNITY_IMAGE: ["community_kor.svg", "community_eng.svg", undefined],
  I18N_UNSUPPORTED_BROWSER_WELCOME02: ["에 오신 것을 환영합니다.", null, undefined],
  I18N_SIGNUP_RESEND_MAIL: ['인증 메일을 재전송 하였습니다.', 'The authentication mail has been resent.', undefined],
  I18N_MANAGE_USERINFO_LEAVE_STRING: ['탈퇴하시겠습니까?', 'Are you sure you want to delete your account?', undefined],
  I18N_PRODUCT_SELECTOR: ['제품 선택 가이드', 'Product Selector', undefined],
  I18N_PRODUCT_SELECTOR_GEARBOX: ['감속기', 'GearBox', undefined],
  I18N_PRODUCT_SELECTOR_LINEAR_MOTOR: ['리니어모터', 'Linear Motor', undefined],
  I18N_COOKIE_POPUP_POLICY: ['쿠키 동의', 'Cookie Policy', undefined],
  I18N_COOKIE_POPUP_POLICY_DETAIL: ['쿠키 정책', 'Cookie Policy', undefined],
  I18N_COOKIE_POPUP_CONFIGURE: ['쿠키 설정', 'Configure', undefined],
  I18N_COOKIE_POPUP_CONFIGURE_INTRO: ['Solution Square는 쿠키를 사용합니다.', 'Our website uses cookies.', undefined],
  I18N_COOKIE_POPUP_CONFIGURE_PART: ['사용 중인 기능 쿠키 목록 입니다.', 'List of functional cookies in use.', undefined],
  I18N_COOKIE_POPUP_ESSENTIAL: ['필수 쿠키', 'Essential Cookies', undefined],
  I18N_COOKIE_POPUP_ANALYTICAL: ['분석 쿠키', 'Analytical / Performance Cookies', undefined],
  I18N_COOKIE_POPUP_FUNCTIONALITY: ['기능 쿠키', 'Functionality and Profile Cookies', undefined],
  I18N_COOKIE_POPUP_ADVERTISING: ['마케팅 활용 쿠키', 'Advertising / Targeting Cookies', undefined],
  I18N_COOKIE_POPUP_REFUSE_ALL: ['전체 거절', 'Refuse All', undefined],
  I18N_COOKIE_POPUP_ACCEPT: ['동의', 'Accept', undefined],
  I18N_COOKIE_POPUP_AGREE: ['동의', 'Agree', undefined],
  I18N_COOKIE_POPUP_ACCEPT_ALL: ['전체 수락', 'Accept All', undefined],
  I18N_COOKIE_POPUP_CONFIRM_CHOICE: ['선택 저장', 'Confirm My Choice', undefined],
  I18N_COOKIE_POPUP_TYPE: ['구분', 'Cookie Type', undefined],
  I18N_COOKIE_POPUP_COOKIE_NAME: ['쿠키명', 'Cookie Name', undefined],
  I18N_COOKIE_POPUP_COOKIE_DOMAIN: ['도메인', 'Domain', undefined],
  I18N_COOKIE_POPUP_COOKIE_COMPANY: ['회사명', 'Company', undefined],
  I18N_COOKIE_POPUP_OK: ['확인', 'OK', undefined],
  I18N_COOKIE_POPUP_CONFIRM: ['확인', 'Confirm', undefined]
};

export type RES_HEADER = keyof typeof resi18n_header;

const [ko, en, enus] = resi18nutil.getres(resi18n_header);

export const resi18n_header_ko = {...resi18n_all_ko, ...ko};
export const resi18n_header_en = {...resi18n_all_en, ...en};
export const resi18n_header_enus = {...resi18n_all_enus, ...enus};