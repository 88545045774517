import React, {Component} from 'react';
import styled from 'styled-components';
import {GoogleLogin} from 'react-google-login';
import {LoginService, SocialSignupInfo} from "../login/LoginService";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {SsqImages} from "../images/SsqImages";
import {AccountService, IPatchLoginTimeAndCountRequest} from "../repositories/AccountService";
import {LanguageService} from "../language/LanguageService";
import {LoginInputValues} from "./Header";
import {connect} from "react-redux";
import {setExistSsqAccountNotSnsStore} from "../store/reducers/rootReducer";
import {LOGIN_TYPE_GOOGLE} from "../definitions/LoginType";
import TagManager from "react-gtm-module";
import ToolTip from "header/ToolTip";
import svcs from "services";
import {cookieutil} from "util/cookieutil";


const CLIENT_ID = '855915529157-nr94vpjnjp0nr1ef24n3pu8fe56elpma.apps.googleusercontent.com';

interface Props extends RouteComponentProps<any> {
  languageService: LanguageService;
  loginService: LoginService;
  accountService: AccountService;
  showSignupSocialPopupFunc: Function;
  showLoginPopupFunc: Function;
  showLoginFailPopupFunc: Function;
  isExistSsqAccountNotSnsStore?: boolean;
  setExistSsqAccountNotSnsStore?: Function;
  recentLoginType: string;
}

interface State {

}

class GoogleLoginPage extends Component<Props, State> {

  getTranslation(key: string): string {
    this.props.languageService.setNamespace("I18N_NAMESPACE_HEADER");
    return this.props.languageService.getTranslationByKey(key) || "";
  }

  async isAlreadyExistSsqEmail(email: string) {
    const isAlreadyMember = await this.props.accountService.isExistAccountId(email);
    if (isAlreadyMember === true) {
      const loginInputValues: LoginInputValues = {
        id: email,
        password: ""
      };
      this.props.setExistSsqAccountNotSnsStore && this.props.setExistSsqAccountNotSnsStore(true);
      this.props.showLoginFailPopupFunc(true, loginInputValues, true);
      return false;
    }
    return true;
  }

  render() {
    return (
      <Container onClick={() => {
        if (cookieutil.isAcceptFunctionConsentCookie(this.props.languageService.lang()))
          svcs.localStorage.setItem('recentLoginType', 'GOOGLE');
      }}>
        {this.props.recentLoginType == 'GOOGLE' && <ToolTip languageService={this.props.languageService}/>}
        <GoogleLogin
          clientId={CLIENT_ID}
          render={renderProps => (
            <GoogleCustomButton
              onClick={renderProps.onClick}
              src={SsqImages.header.sns.google()}/>
          )}
          buttonText="Login"
          onSuccess={async (res: any) => {
            const isLoggedIn = await this.props.loginService.login(
              res.profileObj.email, res.profileObj.googleId, LOGIN_TYPE_GOOGLE
            );
            if (isLoggedIn) {

              TagManager.dataLayer({
                dataLayer: {
                  "event": "login",
                  "login_type": "google"
                }
              });

              const patchLoginTimeAndCountRequest: IPatchLoginTimeAndCountRequest = {
                email: res.profileObj.email
              };
              await this.props.accountService.patchLoginTimeAndCount(patchLoginTimeAndCountRequest);

              if (window.location.href.includes("requireMemberLogin") || window.location.href.includes("requireEmployeeLogin")) {
                this.props.showLoginPopupFunc(false);
              } else {
                let pattern = /^https?:\/\/[^/]+\/(\w\w)\/(\w\w)\/(login)/;
                let match = window.location.href.match(pattern);
                if (match && match[3] === 'login') {
                  const url = window.location.origin + "/" + match[1] + "/" + match[2] + "/main";
                  window.location.assign(url);
                } else {
                  window.location.assign(window.location.href);
                }
                //window.location.assign(window.location.href);
              }
            } else {
              this.props.showLoginPopupFunc(false);
              const googleSignupInfo: SocialSignupInfo = {
                email: res.profileObj.email,
                socialId: res.profileObj.googleId,
                loginType: LOGIN_TYPE_GOOGLE
              };

              if (window.location.href.includes("requireMemberLogin") || window.location.href.includes("requireEmployeeLogin")) {
                if (await this.isAlreadyExistSsqEmail(googleSignupInfo.email) === true) {
                  this.props.history.push("/", googleSignupInfo);
                }
              } else {
                if (await this.isAlreadyExistSsqEmail(googleSignupInfo.email) === true) {
                  this.props.showSignupSocialPopupFunc(true, googleSignupInfo);
                }
              }
            }
          }}
          onFailure={(res) => {
            console.log(JSON.stringify(res));
          }}
          isSignedIn={false}
        />
      </Container>
    );
  }
}

export default withRouter(connect(
  (state: any) => ({
    isExistSsqAccountNotSnsStore: state.root.isExistSsqAccountNotSnsStore,
  }),
  dispatch => ({
    setExistSsqAccountNotSnsStore: (isExistSsqAccountNotSnsStore: boolean) => dispatch(setExistSsqAccountNotSnsStore(isExistSsqAccountNotSnsStore))
  })
)(GoogleLoginPage));

const Container = styled.div`
  display: flex;
  flex-flow: column wrap;
`;

const GoogleCustomButton = styled.img`
  cursor: pointer;
`;