import React, {Component} from "react";
import {SsqImages} from "../images/SsqImages";
import {LanguageService} from "../language/LanguageService";
import {IResetPasswordEmailRequest, NotificationService} from "../repositories/NotificationService";
import {AccountService} from "../repositories/AccountService";
import {LOGIN_TYPE_GOOGLE, LOGIN_TYPE_KAKAO, LOGIN_TYPE_NAVER, LOGIN_TYPE_SSQ, LOGIN_TYPE_WELS} from "../definitions/LoginType";
import svcs from "services";

interface Props {
  languageService: LanguageService;
  accountService: AccountService;
  notificationService: NotificationService;
  signupEmail: string;
  showPasswordResetPopupFunc: any;
  showSignupPopupFunc: any;
  showSendPasswordResetEmailPopupFunc: any;
  closeAllPopupFunc: any;
  setResetPasswordEmailFunc: any;
}


interface State {
  signupEmail: string;
  emailAddress: string;
  isInValidEmail: boolean;
  isExistEmail: boolean;
}

export class PasswordResetPopup extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      signupEmail: "",
      emailAddress: "",
      isInValidEmail: false,
      isExistEmail: true
    };

  }

  async componentDidMount() {
    this.setState({
      signupEmail: this.props.signupEmail
    });
  }

  getTranslation(key: string): string {
    this.props.languageService.setNamespace("I18N_NAMESPACE_HEADER");
    return this.props.languageService.getTranslationByKey(key) || "";
  }

  closePasswordResetPopup() {
    this.props.showPasswordResetPopupFunc(false);
  }

  showSignupPopup() {
    this.props.showSignupPopupFunc(true);
  }

  showSendPasswordResetEmailPopup() {
    this.props.showSendPasswordResetEmailPopupFunc(true);
  }

  closeAllPopup() {
    this.props.closeAllPopupFunc(false, false, false, false, false, false);
  }

  setResetPasswordEmail() {
    this.props.setResetPasswordEmailFunc(this.state.emailAddress);
  }

  isInValidEmail(email: string): boolean {
    const reg = /^\w+(?:[\.-]\w+)*@\w+(?:[\.-]\w+)*(?:\.\w{2,5})+$/;
    return !reg.test(email);
  }

  async isExistsEmail(email: string): Promise<boolean> {
    return await this.props.accountService.isExistAccountId(email);
  }

  async verifyEmail(email: string) {
    let isInValidEmail = false;
    let isExistEmail = false;

    if (this.isInValidEmail(email)) {
      isInValidEmail = true;
    } else {
      if (await this.isExistsEmail(email)) {
        isExistEmail = true;
      }
    }

    this.setState({
      isInValidEmail,
      isExistEmail
    });
  }

  async isSsqLoginType() {
    const loginType = await this.props.accountService.getLoginTypeByAccountId(this.state.emailAddress);
    return (loginType === LOGIN_TYPE_SSQ);
  }

  renderPasswordResetPopup() {
    return <div className="Password_Reset_Popup">
      <div className={"popup_tit"}>
        <button onClick={() => this.closePasswordResetPopup()}>
          <img alt={"back_button.svg"} src={SsqImages.header.icon.backButton()}/>
        </button>
        {this.getTranslation("I18N_PASSWORD_RESET_TITLE")}
      </div>
      <div className={"popup_noti"}>
        {this.getTranslation("I18N_PASSWORD_RESET_NOTIFICATION")}
      </div>
      <button className={"close-button"} onClick={() => this.closeAllPopup()}>
        <img alt={"close_small_icon.svg"} src={SsqImages.common.icon.closeSmallIcon()}/>
      </button>
      <div className={"popup_img"}>
        <img alt={"popup_password_reset_icon.svg"} src={SsqImages.header.popup.passwordReset()}/>
      </div>
      <p>
        {this.getTranslation("I18N_EMAIL_ADDRESS")}
      </p>
      <div>
        <input type={"text"}
          style={(this.state.isExistEmail === false || this.state.isInValidEmail) ? {border: 'solid 1px #E6003E'} : {}}
          onChange={(e) => {
            this.setState({emailAddress: e.target.value});
          }}
          onBlur={async () => {
            await this.verifyEmail(this.state.emailAddress);
          }}
          placeholder={this.getTranslation("I18N_SIGNUP_PLACEHOLDER_INPUT")}
        />
        <div className={"alert-area"}>
          {this.state.isInValidEmail === true &&
            <div
              className={"EmailInvalidAlert"}>{this.getTranslation("I18N_SIGNUP_EMAIL_INVALID_ALERT")}</div>}
          {(this.state.isInValidEmail === false && this.state.isExistEmail === false) &&
            <div
              className={"EmailInvalidAlert"}>{this.getTranslation("I18N_NOT_SIGNED_UP_EMAIL_ALERT")}</div>}
        </div>
      </div>

      <button className={"Submit-Button"} onClick={async () => {
        const loginType = await this.props.accountService.getLoginTypeByAccountId(this.state.emailAddress);
        if (loginType == LOGIN_TYPE_SSQ) {
          let resetPasswordEmailRequest: IResetPasswordEmailRequest = {
            email: this.state.emailAddress,
            lang: svcs.svcLang.getLanguage()
          };
          await this.props.notificationService.postSendResetPasswordEmail(resetPasswordEmailRequest);

          this.showSendPasswordResetEmailPopup();
          this.setResetPasswordEmail();
        } else if ([LOGIN_TYPE_WELS, LOGIN_TYPE_GOOGLE, LOGIN_TYPE_NAVER, LOGIN_TYPE_KAKAO].includes(loginType)) {
          alert(this.getTranslation("I18N_SIGNUP_EMAIL_IS_NOT_SSQ"));
        }
      }}>
        {this.getTranslation("I18N_PASSWORD_CHANGE")}
      </button>
    </div>;
  }

  render() {
    return <div>
      {this.renderPasswordResetPopup()}
    </div>;
  }
}
