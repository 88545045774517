import svcs from "services";
import axios from "axios";

async function checkExpose(documentId: string) {
  await axios.get(`/api/guest/ssqData/document/expose/answerId`, {
    params: {id: documentId}
  }).then((res) => {
    if (!res.data) {
      checkAdmin(documentId);
    }
  });
}

async function checkAdmin(documentId: string) {
  try {
    await axios.get('/api/guest/getAccountInfo').then(res => {
      if (!res.data.allowAdminManage) {
        checkMyPost(documentId, res.data.id);
      }
    });
  } catch {
    alert(trans("I18N_ALERT_NOT_EXPOSE"));
    window.location.assign("/");
  }
}

async function checkMyPost(documentId: string, accountId: string) {
  await axios.get('/api/guest/ssqData/document/writerId', {
    params: {id: documentId}
  }).then(res => {
    if (res.data !== accountId) {
      alert(trans("I18N_ALERT_NOT_EXPOSE"));
      window.location.assign("/");
    }
  });
}


const trans = (key: string) => {
  svcs.svcLang.setNamespace("I18N_NAMESPACE_COMMUNITY_PAGE");
  return svcs.svcLang.getTranslationByKey(key) || "";
};

export const exposeCommunityLink = (documentId: string) => {
  checkExpose(documentId);
};