import * as React from "react";
import {ISsqdataLecture} from "repositories/SsqDataRepository";
import {cdn_imag_url_base} from "conf/gconf";
import {formatStartToEnd} from "util/formatDateTime";
import {docutil} from "util/docutil";
import {LANG_KO_KR, langutil} from "language/langutil";
import {SsqImages} from "images/SsqImages";
import svcs from "services";
import {useState} from "react";
import {RECENT_EXCEPT_LECTURE_DATE_KEY, RECENT_EXCEPT_VALUE_STORAGE_KEY} from "util/LocalStorageWrapper";
import mainPopupImg from "images/event/Main_Popup_Img.jpg";
import mainPopupImgEn from "images/event/Main_Popup_Img_en.jpg";

type Props = {
  popupItem: ISsqdataLecture
  closeEventPopup: () => void;
  trans: (p: string) => string;
}

export const CenterLecturePopup = (props: Props) => {

  const [fullDescription, setFullDescription] = useState<boolean>(false);
  const utc = () => {
    return "UTC" + (props.popupItem.lectureUtcDiff >= 0 ? "+" + props.popupItem.lectureUtcDiff / 10 : props.popupItem.lectureUtcDiff / 10);
  };

  const startToEnd = () => {
    return formatStartToEnd(docutil.utcDateConvert(props.popupItem.lectureStartDt), docutil.utcDateConvert(props.popupItem.lectureEndDt));
  };

  const {country, lang} = langutil.splitLangCode(svcs.svcLang.getLanguage());

  const setExpireTime = (id: string) => {
    // 하루 동안 보이지 않게 처리
    const expires = new Date().toDateString();
    const tmpStorage = localStorage.getItem(RECENT_EXCEPT_VALUE_STORAGE_KEY)?.split(",") || [];
    localStorage.setItem(RECENT_EXCEPT_LECTURE_DATE_KEY, expires);
    localStorage.setItem(RECENT_EXCEPT_VALUE_STORAGE_KEY, tmpStorage.length != 0 ? tmpStorage + "," + id : id);
  };

  return <div className={"popup-collider"}>
    <img className={"popup-image"} src={svcs.svcLang.getLanguage() == LANG_KO_KR ? mainPopupImg : mainPopupImgEn} alt={"classroom.png"}/>
    <div className={"description-base"}>
      <div className={"popup-header"}>
        <button className="close-button" onClick={() => props.closeEventPopup()}>
          <img alt="close_small_icon.svg" src={SsqImages.common.icon.closeSmallIcon()}/>
        </button>
      </div>

      <div className="input_item">
        <p className="lec-info">{props.trans("I18N_SERVICE_LECTURE_EVENT_POPUP_TITLE:")}</p>
        <table>
          <tbody>
          <tr>
            <th>{props.trans("I18N_SERVICE_LECTURE_NAME_POPUP")}</th>
            <td className="class-name">{props.popupItem.lectureNm}</td>
          </tr>
          <tr>
            <th>{props.trans("I18N_SERVICE_LECTURE_DATE")}</th>
            <td>{startToEnd()}({utc()})</td>
          </tr>
          <tr>
            <th>{props.trans("I18N_SERVICE_LECTURE_TEACHER")}</th>
            <td>{props.popupItem.lectureTeacherNm}</td>
          </tr>
          <tr>
            <th>{props.trans("I18N_SERVICE_LECTURE_INTRO")}</th>
            <td onMouseEnter={(e) => {
              e.stopPropagation();
              setFullDescription(true);
            }}
              onMouseLeave={(e) => {
                e.stopPropagation();
                setFullDescription(false);
              }}>
              <div className={'content'}
              >{props.popupItem.lectureCnts}</div>
              {fullDescription && <div className={'hover-selector'}>{props.popupItem.lectureCnts}</div>}
            </td>
          </tr>
          </tbody>
        </table>
        <button className="lec-jump"
          onClick={() => {
            window.open(`/${country}/${lang}/service/classroom`, "_blank");
          }}
        >{props.trans("I18N_SERVICE_LECTURE_EVENT_POPUP_BUTTON")}</button>

      </div>
    </div>

    <div className="exit-base">
      <button onClick={() => {
        setExpireTime(props.popupItem.lectureId.toString());
        props.closeEventPopup();
      }}>{props.trans("I18N_SERVICE_LECTURE_EVENT_POPUP_SKIP")}</button>
    </div>
  </div>;
};