export class SsqDataDocCommentEntity {
  docCommentId: number = undefined;
  commentCnts: string = '';
  docId: number = undefined;
  employ: boolean = false;
  updtDt: Date = undefined;
  writerId: string = '';
  writerNcnm: string = '';
  writerNm: string = '';
}


export interface IfSsqDataDocCommentEntity extends SsqDataDocCommentEntity {
}


export class DocCommentInfo extends SsqDataDocCommentEntity {
  modifyReview: boolean = false;
  reportReview: boolean = false;
  showInput: boolean = false;
}

export interface IfDocCommentInfo extends DocCommentInfo {
}
