export const FileType = Object.freeze({
  NONE: "NONE",
  SAMPLE: "SAMPLE",
  LIBRARY: "LIBRARY",
  TEMPLATE: "TEMPLATE",
});

export const getFileTypeLangKey = (value: string) => {
  switch (value) {
    case FileType.NONE:
      return 'I18N_FILE_TYPE_NONE';
    case FileType.SAMPLE:
      return 'I18N_FILE_TYPE_SAMPLE';
    case FileType.LIBRARY:
      return 'I18N_FILE_TYPE_LIBRARY';
    case FileType.TEMPLATE:
      return 'I18N_FILE_TYPE_TEMPLATE';
    default:
      return '';
  }
};
