// @flow
import * as React from 'react';
import styled from "styled-components";
import * as colors from "asset/style/color";
import {Modal} from 'ctls/modal/Modal';
import "./asset-appbar-loading.scss";

type Props = {
  width: number;
  loading: boolean;
  show: boolean;
};
export const AssetAppBarLoading = ({width, loading, show}: Props) => {
  return (
    <React.Fragment>
      {show && <Modal bg={'transparent'}>
        <LoadingWrap className={'asset-appbar-loader'}>
          {!loading && <p className={"loading-bar"} style={{width: width + '%'}}></p>}
          {loading && <p className={"asset-appbar-loaderBar"}></p>}
        </LoadingWrap>
      </Modal>}
    </React.Fragment>
  );
};

const LoadingWrap = styled.div`
  background: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;

  .loading-bar {
    height: 6px;
    background: ${colors.accent01};
    position: absolute;
    top: 0;
    left: 0
  }

  @media screen and (max-width: 1199px) {
    left: 0;
    top: 0;
  }
`;