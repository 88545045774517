// @flow
import * as React from 'react';
import {Component} from 'react';
import {Modal} from "ctls/modal/Modal";
import styled from "styled-components";
import clsx from "clsx";
import {imgs} from "images/imgs";
import {ICreateReportComment, ICreateReportPost, SsqDataRepository} from "repositories/SsqDataRepository";
import {FeedbackType} from "definitions/FeedbackType";
import {LanguageService} from "language/LanguageService";
import {i18ntrans} from "language/i18nkey";
import svcs from "services";
import {fontFamilyBold, fontFamilyRegular} from "style/fonts";


type Props = {
  ssqDataRepository: SsqDataRepository;
  languageService: LanguageService;
  trans;
};


type State = {
  visible: boolean,
  resolve,
  reject
  reportComment: string;
  docCommentId: number,
  docId: number,
  reportMsg: string,

  reportType: string | "COMMENT" | "DOC";
  errorMsgFilter: boolean

};

/**
 * 신고하기 팝업.
 */
export class PopReport extends Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      visible: false,
      reject: null,
      resolve: null,
      reportComment: "",
      docCommentId: 0,
      docId: 0,
      reportMsg: '',

      reportType: "",
      errorMsgFilter: false,
    };
  }

  /*언어 설정*/

  getTranslation = (key: string) => {
    if (!this.props.languageService) {
      const trans = i18ntrans.community;
      return trans(key) || "";
    }
    this.props.languageService.setNamespace("I18N_NAMESPACE_COMMUNITY_PAGE");
    return this.props.languageService.getTranslationByKey(key) || "";
  };


  show(param) {
    return new Promise((resolve, reject) => {
      this.setState({
        resolve, reject,
        visible: true,
        docCommentId: param.docCommentId,
        docId: param.docId,
        reportType: param.reportType,
      });
    });
  }

  resolve(res) {
    this.setState({visible: false});
    this.state.resolve(res);
  }

  reject(res) {
    this.setState({visible: false});
    this.state.reject(res);
  }


  handleClick(e, cmd, param?) {
    const ssqDataRepository = this.props.ssqDataRepository ?? svcs.repoSsqData;
    console.log("cmd is ", cmd);
    if (e && e.preventDefault) e.preventDefault();
    if (cmd === 'cancel') {
      this.reject({cmd: 'cancel'});
    } else if (cmd === 'close') {
      this.reject({cmd});
    } else if (cmd === 'do-report') {

      if ([null, ""].includes(this.state.reportMsg)) {
        this.setState({errorMsgFilter: true});
        return;
      }

      if (this.state.reportType === "DOC") {

        /*글 신고*/
        let reportPost: ICreateReportPost = {
          feedbackType: FeedbackType.RPT_DOC,
          comment: "[" + this.state.reportMsg + "] " + this.state.reportComment,
          feedbackWriterId: "",
          feedbackWriterNickname: "",
          documentId: this.state.docId,
        };
        ssqDataRepository.postReportPost(reportPost).then((res) => {
          console.log("글 신고 완료", this.state.docId);

          this.setState({
            reportComment: "",
          });
          this.reject({cmd: 'cancel'});

        }).catch((e) => {
          console.log("글 신고 실패", e);
        });
      } else if (this.state.reportType === "COMMENT") {

        /*댓글 신고*/
        let reportComment: ICreateReportComment = {
          feedbackType: FeedbackType.RPT_COMMENT,
          comment: "[" + this.state.reportMsg + "] " + this.state.reportComment,
          feedbackWriterId: "",
          feedbackWriterNickname: "",
          docCommentId: this.state.docCommentId,
          documentId: this.state.docId,

        };
        ssqDataRepository.postReportComment(reportComment).then((res) => {
          console.log("댓글 신고 완료", this.state.docId);
          //alert("댓글이 신고되었습니다.");

          this.setState({
            reportComment: "",
          });
          this.reject({cmd: 'cancel'});

        }).catch((e) => {
          console.log("댓글 신고 실패", e);
        });
      } else {
        console.error(" report type error ", this.state.reportType);
      }

    } else if (cmd === 'ok') {
      this.resolve(null);
    } else {
      console.error("unknown cmd ", cmd, " param ", param);
    }
  }

  render() {
    const state = this.state;

    const msgs = [
      this.getTranslation("I18N_COMMUNITY_REPORT_STRING3"),
      this.getTranslation("I18N_COMMUNITY_REPORT_STRING4"),
      this.getTranslation("I18N_COMMUNITY_REPORT_STRING5"),
      this.getTranslation("I18N_COMMUNITY_REPORT_STRING6")
    ];

    return (<>
        {state.visible ? (<Modal>
          <PopReportCnts className={clsx("ssq-center ")}>
            <div className="popup_base">
              <div>
                <button className="close-button" onClick={(e) => this.handleClick(e, 'close')}>
                  <img alt="close_small_icon.svg" src={imgs.common.close_small_icon}/>
                </button>
                <div className="popup_tit">{this.getTranslation('I18N_COMMUNITY_REPORT')}</div>
                <div className="popup_noti">{this.getTranslation('I18N_COMMUNITY_REPORT_STRING1')}</div>

                <div className="input_item">
                  <div className="input_row">
                    <div>
                      <div className="select_wrap">
                        <select aria-label="" value={this.state.reportMsg}
                          className={clsx("filter-setting", this.state.errorMsgFilter ? "filter-error" : "")}
                          onChange={(e) => {
                            this.setState({reportMsg: e.target.value});
                            if (this.state.errorMsgFilter) this.setState({errorMsgFilter: false});
                          }}>
                          <option value="" hidden={false}>{this.getTranslation('I18N_COMMUNITY_REPORT_STRING2')}</option>
                          {msgs.map((msg) => <option key={msg} value={msg}>{msg}</option>)}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="input_row">
                    <div><textarea rows={10} cols={50} placeholder={this.getTranslation("I18N_COMMUNITY_REPORT_PLACEHOLDER")}
                      onChange={(e) => this.setState({reportComment: e.target.value || ''})}
                      maxLength={300} value={this.state.reportComment}
                    ></textarea></div>
                  </div>

                </div>
                <div className="pop-btn-body">
                  <div className="Submit-Button" onClick={(e) => this.handleClick(e, 'do-report')}>
                    {this.getTranslation('I18N_COMMUNITY_REPORT')}
                  </div>
                </div>
              </div>
            </div>
          </PopReportCnts>
        </Modal>) : null}
      </>
    );
  };
}


export const PopReportCnts = styled.div`
  height: 100%;

  .popup_base {
    background: white;
    z-index: 1002;
    position: fixed;
    width: 36rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    padding: 3rem;
    color: #2a2c2f;

    > div {
      height: auto;
      min-height: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  .close-button {
    position: absolute;
    right: 2rem;
    top: 2rem;
  }

  .popup_tit {
    font-size: 2.4rem;
    ${fontFamilyBold};
    font-style: normal;
  }

  .popup_noti {
    font-size: 1.4rem;
    ${fontFamilyRegular};
    font-style: normal;
    margin-top: 1rem;
  }

  .input_item {
    margin: 1.6rem 0px;
    height: 100%;
    width: 100%;
    flex: 1;
  }

  .input_row {
    margin-bottom: 1.5rem;
    ${fontFamilyRegular};
    font-style: normal;
  }

  .input_row .select_wrap select {
    width: 100%;
    height: 4rem;
    line-height: 4rem;
    padding: 0 3.2rem 0 1.2rem;
    background-position: right 1.2rem center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
  }

  .filter-setting {
    &.filter-error {
      border: 1px solid rgb(230, 0, 62);
    }
  }


  .input_row select {
    position: relative;
    border: 1px solid #C7D6EA;
    border-radius: 4px;
    font-size: 1.3rem;
    line-height: 4rem;
    width: 8.8rem;
    padding: 0 0.5rem;
    ${fontFamilyRegular};
    font-style: normal;
    appearance: none;
    background: url(${imgs.common.select_arrow}) right center no-repeat rgb(255, 255, 255);
  }

  .input_row .select_wrap select option {
    font-size: 1.3rem;
    line-height: 4rem;
    color: #2a2c2f;
    padding: 0 0.5rem;
  }

  .input_row input[type="text"] {
    ${fontFamilyRegular};
    font-style: normal;
    border: 1px solid #C7D6EA;
    border-radius: 0.4rem;
    width: 100%;
    height: 4rem;
    font-size: 1.3rem;
    padding: 0px 1.2rem;
  }

  .input_row textarea {
    ${fontFamilyRegular};
    font-style: normal;
    border: 1px solid #C7D6EA;
    border-radius: 0.4rem;
    width: 100%;
    font-size: 1.3rem;
    padding: 1.2rem;
    resize: none;
  }

  .pop-btn-body {
    margin-top: 5.5rem;
  }


  .Submit-Button {
    color: white;
    border-radius: 100px;
    width: 100%;
    height: 4.8rem;
    line-height: 4.8rem;
    background: #407edb;
    text-align: center;
    font-size: 1.6rem;
    ${fontFamilyBold};
    font-style: normal;
    cursor: pointer;
  }

  @media screen and (max-width: 764px) {
    .popup_base {
      width: 100vw;
      height: 100%;
    }
  }

  @media screen and (max-width: 360px) {

    .popup_base {
      //width: 95%;
      overflow: hidden auto;
      height: 100vh;
      min-height: 100vh;
      min-height: -webkit-fill-available;
    }

    .popup_tit, .popup_noti, .input_row * {
      word-break: keep-all;
    }
  }
  @media screen and (max-width: 320px) {
    .popup_base {
      height: 90vh;
    }
  }
`;