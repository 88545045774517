import React, {Component} from 'react';
import {RouteComponentProps, withRouter} from "react-router-dom";
import {AccountService} from "repositories/AccountService";
import {LanguageService} from "language/LanguageService";
import {CategoryRepository} from "repositories/category/CategoryRepository";
import {emptySsqDataDocument, ISsqDataDocMinInfo, SsqDataRepository} from "repositories/SsqDataRepository";
import CategoryStore from "repositories/category/CategoryStore";
import {RoleType} from "definitions/RoleType";
import {TargetType} from "definitions/TargetType";
import {CategoryType} from "definitions/CategoryType";
import NotFoundPage from "NotFoundPage";
import parse from "html-react-parser";
import axios from "axios";
import {connect} from "react-redux";
import {IfLoginInfo} from "models/IfLoginInfo";
import {ACTION_TYPES} from "store/ActionTypes";
import {emptySocialSignupInfo, LoginService, SocialSignupInfo} from "login/LoginService";
import {NotificationService} from "repositories/NotificationService";
import {LoginInputValues} from "communitypage/blog/BlogPagePopup";
import {PopReport} from "docwrite/popup/PopReport";
import svcs from "services";
import {LoginPopup} from "header/LoginPopup";
import {SignupPopup} from "header/SignupPopup";
import {SignupSocialPopup} from "header/SignupSocialPopup";
import {TermsDetailPopup} from "header/TermsDetailPopup";
import {TermsDetailPrivatePopup} from "header/TermsDetailPrivatePopup";
import {SendVerifyEmailPopup} from "header/SendVerifyEmailPopup";
import {PasswordResetPopup} from "header/PasswordResetPopup";
import {SendPasswordResetEmailPopup} from "header/SendPasswordResetEmailPopup";
import {QnaPageCategory} from "communitypage/qna/popup/QnaPageCategory";
import {QnaPageAsideWrap} from "communitypage/qna/popup/QnaPageAsideWrap";
import {AnswerWrite} from "communitypage/qna/popup/AnswerWrite";
import {loginutil} from "util/loginutil";
import {apiutil} from "util/apiutil";
import {exposeCommunityLink} from "communitypage/CommunityExpose";
import {docutil} from "util/docutil";
import {orderBy} from "lodash-es";
import {langutil} from "language/langutil";
import ToastPopup from "productpage/ToastPopup";
import {getLangSuffix, HelmetTitleAndH1} from "ctls/common/HelmetTitleAndH1";
import {helmetFormat, tcommona} from "language/i18nkey";
import {ssqutil} from "util/ssqutil";
import {SsqImages} from "images/SsqImages";
import {Editorlist} from "style/CommonStyledComponents";
import {docwriteutil} from "docwrite/community/docwriteutil";
import {PopTermsDetail} from "ctls/popup/PopTermsDetail";
import LoginFailPopup from "header/LoginFailPopup";
import {roleutil} from "util/roleutil";
import {getEmptyAnswerDoc, IAnswerDocument, LoginPopupConf, QnaPagePopupState} from "communitypage/qna/popup/qna-popup-common";
import {authutil} from "util/authutil";
import {PartnerQnaPagePopupMain} from './partner-qna-popup-style';
import {PartnerQuestionView} from "communitypage/partner/qna/PartnerQuestionView";
import {PartnerAnswerView} from "communitypage/partner/qna/PartnerAnswerView";
import {QnaPageAside} from "communitypage/qna/popup/qna-popup-style";
import {PartnerQnaProcess} from "communitypage/partner/qna/PartnerQnaProcess";

interface IQnaPageMatchParams {
  documentId: string;
  country?: string;
  lang?: string;
}


interface Props extends RouteComponentProps<IQnaPageMatchParams, any> {
  accountService: AccountService;
  languageService: LanguageService;
  categoryRepository: CategoryRepository;
  ssqDataRepository: SsqDataRepository;
  categoryStore: CategoryStore;
  loginInfo: IfLoginInfo;

  loginService: LoginService;
  setLoginInfo: (info: IfLoginInfo) => void;

  notificationService: NotificationService;
  displayLoginPopup: Function;
  displayLoginFailPopup: Function;
}


class PartnerQnaPagePopup extends Component<Props, QnaPagePopupState> {
  popReport: React.RefObject<PopReport>;
  outSideClickPost: React.RefObject<HTMLInputElement>;
  private readonly refTermsDetail: React.RefObject<PopTermsDetail>;

  // 로그인 체크는,
  constructor(props: Props) {
    super(props);
    this.refTermsDetail = React.createRef<PopTermsDetail>();

    this.state = {
      commentList: [],
      answers: [],
      questionDetail: emptySsqDataDocument,
      clickCount: 0,
      productCategories: [],
      industryCategories: [],
      applicationLoadCategories: [],
      isMounted: false,

      showPopupMenu: false,

      loginInputValues: {id: "", password: "",},
      signupEmail: "",
      socialSignupInfo: emptySocialSignupInfo,
      resetPasswordEmail: "",
      termsType: "",
      popupConf: new LoginPopupConf(),
      questionUserGoodBad: "",

      newAnswer: getEmptyAnswerDoc(),
      seq: 0,
      toastType: '',

    };

    this.popReport = React.createRef();

    this.outSideClickPost = React.createRef();

  }

  getLoginInfo() {
    return new Promise((resolve) => {
      axios.get('/api/guest/loginInfo').then(res => {
        // console.log("login info ", res.data);
        this.props.setLoginInfo(res.data);
        // console.log("after setlogininfo");
      }).catch(err => {
        console.error("E", err);
      }).finally(() => resolve({}));
    });

  }

  async componentDidMount() {
    if (!langutil.isCountryLangUrl()) { // url에 국가/언어가 없으면.
      const docId = this.props.match.params.documentId;
      const minInfo: ISsqDataDocMinInfo = (await axios.get('/api/guest/ssqData/docmininfo/' + docId)).data;
      document.location.href = langutil.getCountryLangUrl(minInfo.exposeSiteLanguage);
      return; // 다음으로 진행하지 않도록.
    }

    await exposeCommunityLink(this.props.match.params.documentId);
    await this.setCategoryStore();
    await this.getLoginInfo();
    await this.updateClickCount();
    await this.initPage();

    if (this.state.questionDetail.docType == "RND_QNA_Q") {
      document.getElementById("helmetTitle").innerText += " - " +
        helmetFormat([this.state.questionDetail.docTitle, ' - '], 'I18N_COMMON_TIT_RND', [getLangSuffix()]);
    } else {
      document.getElementById("helmetTitle").innerText += " - " +
        helmetFormat([this.state.questionDetail.docTitle, ' - '], 'I18N_COMMON_TIT_QNA', [getLangSuffix()]);
    }
  }

  async initPage() {
    // 권한체크.
    await this.getDocumentDetail();
    this.setState({isMounted: true,});
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<QnaPagePopupState>, snapshot?: any) {
    if (this.props.loginInfo?.userId && this.props?.loginInfo?.userId !== prevProps?.loginInfo?.userId) {
      this.initPage().then(() => null);
    }
    if (this.props.location.pathname !== prevProps.location.pathname && this.state.popupConf.showUserPopup) {
      this.setState({popupConf: {...this.state.popupConf, showUserPopup: false}});
    }
  }

  /*신고하기 팝업창 언어*/
  getReportTrans(key: string): string {
    svcs.svcLang.setNamespace("I18N_NAMESPACE_ADMIN_PAGE");
    return svcs.svcLang.getTranslationByKey(key) || "";
  }

  async updateClickCount() {
    const patchClickCountResponse = await this.props.ssqDataRepository.addClickCount(Number(this.props.match.params.documentId));
    this.setState({clickCount: patchClickCountResponse.updatedClickCount});
  }

  async setCategoryStore() {
    await apiutil.start('allCategories');
    if (this.props.categoryStore.allCategories.length === 0) {
      let language = this.props.languageService.getLanguage();
      const allCategories = await this.props.categoryRepository.getExposedCategories(language);
      await this.props.categoryStore.setAllCategories(allCategories);
    }
    apiutil.end('allCategories');
  }

  verifyUserRole(loginAuth: string, doc) {
    const dispTarget = doc?.displayTarget;
    const pathname = this.props.history.location.pathname;
    if (loginAuth === RoleType.ROLE_ANONYMOUS && dispTarget === TargetType.LOGIN) {
      this.props.history.push(`/requireMemberLogin`, pathname);
    } else if (loginAuth === RoleType.ROLE_ANONYMOUS && dispTarget === TargetType.EMPLOYEE) {
      this.props.history.push(`/requireEmployeeLogin`, pathname);
    } else if (loginAuth === RoleType.ROLE_MEMBER && dispTarget === TargetType.EMPLOYEE) {
      this.props.history.push(`/requireEmployeeLogin`, pathname);
    }

    // 로그인 확인 에러발생시 ----------------

    //정상적인 케이스는 페이지 보여주고,,,
    if ((loginAuth === RoleType.ROLE_ANONYMOUS && dispTarget === TargetType.GUEST)
      || (loginAuth === RoleType.ROLE_MEMBER && (dispTarget === TargetType.GUEST) || (dispTarget === TargetType.LOGIN))
      || (loginAuth === RoleType.ROLE_ADMIN && (dispTarget === TargetType.GUEST) || (dispTarget === TargetType.LOGIN) || (dispTarget === TargetType.EMPLOYEE))
    ) {
      // 페이지 보여주고,,,
    }
    // 로그인 확인 에러발생시
    else {
      this.props.history.push(`/requireMemberLogin`, pathname);
    }
  }

  async getComments(docId) {
    return axios.get('/api/guest/ssqData/doccomment/list', {params: {docId}});
  }


  async getDocumentDetail() {
    const questionId = Number(this.props.match.params.documentId);
    console.log("getDocumentDetail questionId=", questionId);
    const questionDetail = await this.props.ssqDataRepository.getSsqDataByDocumentId(questionId);

    const docType = questionDetail?.docType;
    if (!authutil.isMatchUrlDocType(docType, this.props.location.pathname)) {
      const prefix = langutil.countryLangPrefix(this.props.location.pathname);
      document.location.href = prefix + "/404";
      return;
    }

    this.verifyUserRole(loginutil.firstrole(this.props.loginInfo), questionDetail);

    const pathname = this.props.location.pathname;
    if (authutil.isRnd(this.props.location.pathname)) {
      if (!loginutil.iswels(this.props.loginInfo) || !roleutil.hasRole("RND", this.props.loginInfo?.userRoles)) {
        this.props.history.push(`/requireEmployeeLogin`, pathname);
      }
    }

    this.setStateCategories(questionDetail.categories);
    const goobadres = await docutil.getCountDocGoodBad(questionId);
    questionDetail.userGoodCnt = goobadres.data.goodCount;
    questionDetail.userBadCnt = goobadres.data.badCount;

    const comments = await this.getComments(questionId);

    /* 질문 추천, 비추천 체크 표시 여부 호출 */
    await axios.post('/api/guest/ssqData/docgoodbad/mycheck', {docId: questionDetail.id, userId: this.props.loginInfo?.userId}
    ).then(response => {
      this.setState({questionUserGoodBad: response.data.goodBad || ""});
    });
    await this.setState({questionDetail});

    /* 답변 목록 호출 */
    this.getAnswerList(questionId);

    let descriptionParsedResult = ssqutil.toarray(parse(questionDetail.description));
    this.setTitleTagsForBookmark(descriptionParsedResult);
    const clickCount = questionDetail.clickCount;

    this.setState({clickCount});
    this.setState({commentList: comments.data,});
  }

  sortAnswers(answers: IAnswerDocument[]) {
    return orderBy(answers, ['finished', (o: IAnswerDocument) => o.userGoodCnt - o.userBadCnt, 'id'], ['desc', 'desc', 'desc']);
  }

  getAnswerList(questionId: number) {

    axios.get(`/api/guest/ssqData/document/answers`, {params: {questionId: questionId}}).then((response) => {
      let answers: IAnswerDocument[] = response.data;
      // 정렬 먼저하고 정렬 순서대로 기타정보 조회.
      const promises = answers.map((answer) => {
        /* 추천, 비추천 Count 정보 호출 및 업데이트 */
        //console.log("answer.....", answer);
        return new Promise((resolve, reject) => {
          docutil.getCountDocGoodBad(answer.id).then((counts) => {
            answer.userGoodCnt = counts.data.goodCount;
            answer.userBadCnt = counts.data.badCount;
            answer.isUserGood ??= false;
            answer.isUserBad ??= false;
            /* 내가 선택한 추천, 비추천 정보 호출 및 업데이트 */
            axios.post('/api/guest/ssqData/docgoodbad/mycheck', {docId: answer.id, userId: this.props.loginInfo?.userId}
            ).then(response => {
              if (response.data) {
                answer.isUserGood = response.data.goodBad === "GOOD";
                answer.isUserBad = response.data.goodBad === "BAD";
              }
            }).finally(() => resolve({}));
          });
        });
      });
      Promise.all(promises).then(res => {
        answers = this.sortAnswers(answers);
        this.setState({answers: (answers || []).slice()});
      });
      this.setState({answers: answers});
    });
  }

  getCommentList(questionId: number) {
    axios.get('/api/guest/ssqData/doccomment/list', {
      params: {docId: questionId}
    }).then((res) => {
      this.setState({commentList: res?.data || []});
    });
  }

  setTitleTagsForBookmark(parsedResult: any) {
    if (parsedResult) {
      return parsedResult.map((element: any, index: number) => {
          if (element && element.type && (element.type === "h1" || element.type === "h2" || element.type === "h3")) {
            console.log(`#${element.type}-${index}`);
            let elementCopy = {
              ...element,
              props: {
                ...element.props,
                id: `${element.type}-${index}`
              }
            };
            return elementCopy;
          } else {
            return element;
          }
        }
      );
    }
  }

  setStateCategories(categories: number[]) {
    let productCategories = categories.map(categoryId =>
      this.props.categoryStore.getCategorySearchItemByIdAndType(categoryId, CategoryType.PRODUCT.toString())
    ).filter((category) => category.categoryId !== 0).sort((a, b) => {
        if (a.parentsCategory < b.parentsCategory) return -1;
        if (a.parentsCategory > b.parentsCategory) return 1;
        return 0;
      }
    );

    let industryCategories = categories.map(categoryId =>
      this.props.categoryStore.getCategorySearchItemByIdAndType(categoryId, CategoryType.INDUSTRY.toString())
    ).filter((category) => category.categoryId !== 0);

    let applicationLoadCategories = categories.map(categoryId =>
      this.props.categoryStore.getCategorySearchItemByIdAndType(categoryId, CategoryType.APPLICATION_LOAD.toString())
    ).filter((category) => category.categoryId !== 0);

    this.setState({
      productCategories,
      industryCategories,
      applicationLoadCategories
    });
  }

  trans(key: string): string {
    this.props.languageService.setNamespace("I18N_NAMESPACE_COMMUNITY_PAGE");
    return this.props.languageService.getTranslationByKey(key) || "";
  }


  handleCmd(cmd: string | 'refresh-doc-detail' | 'setstate-answers' | 'refresh-answers' | 'popup-menu' | 'state-question', data?) {
    if (cmd === 'show-login') {
      this.setState({popupConf: {...this.state.popupConf, showUserPopup: false, showLoginPopup: true}});
    } else if (cmd === 'refresh-doc-detail') {
      this.getDocumentDetail().then(res => null);
    } else if (cmd === 'popup-menu') {
      this.setState({showPopupMenu: data});
    } else if (cmd === 'setstate-answers') {
      this.setState({answers: this.sortAnswers(this.state.answers)});
    } else if (cmd === 'refresh-answers') {
      this.getAnswerList(this.state.questionDetail.id);
    } else if (cmd === 'state-question') {
      this.setState({questionDetail: {...this.state.questionDetail}});
    } else if (cmd === 'refresh-comment') {
      this.getCommentList(this.state.questionDetail.id);
    } else {
      console.error("unknow ", cmd, " data ", data);
    }
  }

  handleClick(cmd: string, data?) {
    console.log("--handleClick--", cmd, data);
    switch (cmd) {
    }
  }

  // page rendering.
  render() {
    const trans = docwriteutil.getadmintrans;
    return <div>{
      this.state.questionDetail === emptySsqDataDocument ? this.state.isMounted ?
        <NotFoundPage languageService={this.props.languageService}/> : <div></div> : <PartnerQnaPagePopupMain>
        <HelmetTitleAndH1 title={["RND_QNA_Q"].includes(this.state.questionDetail?.docType)
          ? tcommona('I18N_COMMON_TIT_RND', [this.state.questionDetail?.docTitle, " - "], [getLangSuffix()])
          : tcommona('I18N_COMMON_TIT_QNA', [this.state.questionDetail?.docTitle, " - "], [getLangSuffix()])}/>

        <section className="community-view">
          <div className="wrapper container view-container">
            <div className="community__content">
              <div>
                <PartnerQnaProcess
                  displayLoginPopup={this.props.displayLoginPopup}
                  loginInfo={this.props.loginInfo}
                  trans={this.trans.bind(this)}
                />
                <PartnerQuestionView
                  commentList={this.state.commentList}
                  displayLoginPopup={this.props.displayLoginPopup}
                  question={this.state.questionDetail}
                  popupConf={this.state.popupConf}
                  handleClick={this.handleClick.bind(this)}
                  handleCmd={this.handleCmd.bind(this)}
                  loginInfo={this.props.loginInfo}
                  ssqDataRepository={this.props.ssqDataRepository}
                  trans={this.trans.bind(this)}
                  renderPopup={this.renderPopup.bind(this)}
                  openToast={(type) => {
                    this.setState({toastType: type, seq: this.state.seq + 1});
                  }}
                />

                <PartnerAnswerView
                  commentList={this.state.commentList}
                  displayLoginPopup={this.props.displayLoginPopup}
                  question={this.state.questionDetail}
                  answers={this.state.answers}
                  loginInfo={this.props.loginInfo}
                  trans={this.trans.bind(this)}
                  handleClick={this.handleClick.bind(this)}
                  handleCmd={this.handleCmd.bind(this)}
                />

                {/*답변 작성 id?: number, description?: string, writerId?: string, nickName?: string */}
                <AnswerWrite question={this.state.questionDetail}
                  displayLoginPopup={this.props.displayLoginPopup}
                  answer={this.state.newAnswer}
                  handleCmd={this.handleCmd.bind(this)}
                  loginInfo={this.props.loginInfo} trans={this.trans.bind(this)}
                  editMode={false}
                  cancelAnswer={(data) => {
                    this.setState({newAnswer: getEmptyAnswerDoc()});
                  }}
                  clearModify={() => this.setState({newAnswer: getEmptyAnswerDoc()})}
                />
                {/*하단 연관 카테고리 출력*/}

                <div className="commu_bottom_cont">
                  {/*하단 연관 카테고리 출력*/}
                  {this.state.productCategories.length > 0 ? <QnaPageCategory
                    languageService={this.props.languageService}
                    productCategories={this.state.productCategories}
                    applicationLoadCategories={this.state.applicationLoadCategories}
                    industryCategories={this.state.industryCategories}
                    trans={this.trans.bind(this)}
                    history={this.props.history}
                  /> : null}
                </div>
              </div>
              {/*오른쪽 사이드 출력*/}
              <aside className="community-view-aside">
                <div className="aside-content">
                  <QnaPageAside>
                    <QnaPageAsideWrap documentId={this.state.questionDetail.id} trans={this.trans.bind(this)}/>
                    <Editorlist className={"editor_list"}
                      onClick={(e) => {
                        if ((e.target as any)?.classList?.contains?.('terms-condition')) {
                          this.refTermsDetail.current.show({}).then(e => console.log("e", e));
                        }
                      }}>
                <span className={"list_empty"}><img alt={"list_empty.svg"}
                  src={SsqImages.boris.listempty()}/>
                  {parse(trans('I18N_CMMNTY_TITLE_LIST_EMPTY'))}
                  </span>
                    </Editorlist>
                  </QnaPageAside>
                </div>
              </aside>

            </div>
          </div>
        </section>
        <ToastPopup languageService={this.props.languageService} loginInfo={this.props.loginInfo}
          seq={this.state.seq + 1}
          type={this.state.toastType}
        ></ToastPopup>
      </PartnerQnaPagePopupMain>
    }
      <PopTermsDetail ref={this.refTermsDetail}/>
      <PopReport ref={this.popReport} trans={this.getReportTrans.bind(this)} ssqDataRepository={this.props.ssqDataRepository}
        languageService={this.props.languageService}/>
    </div>;
  }

  showLoginPopupFunc = (showLoginPopup: boolean) => {
    this.setState({popupConf: {...this.state.popupConf, showLoginPopup: showLoginPopup}});
  };

  setIsLoggedInFunc = (b) => {

  };


  setLoginInputValuesFunc = (loginInputValues: LoginInputValues) => {
    this.setState({loginInputValues: loginInputValues});
  };

  /*로그인 실패*/
  showLoginFailPopupFunc = (showLoginFailPopup: boolean, loginInputValues?: LoginInputValues) => {
    this.setState({
      popupConf: {...this.state.popupConf, showLoginFailPopup: showLoginFailPopup, showLoginPopup: true},
    });

    if (loginInputValues) {
      this.setState({loginInputValues});
    }
  };

  /*회원가입*/
  showSignupPopupFunc = (showSignupPopup: boolean) => {
    this.setState({popupConf: {...this.state.popupConf, showSignupPopup: showSignupPopup}});
  };

  showSendVerifyEmailPopupFunc = (showSendVerifyEmailPopup: boolean) => {
    this.setState({popupConf: {...this.state.popupConf, showSendVerifyEmailPopup: showSendVerifyEmailPopup}});
  };


  setSignupEmailFunc = (signupEmail: string) => {
    this.setState({signupEmail: signupEmail});
  };

  showSignupSocialPopupFunc = (
    showSignupSocialPopup: boolean,
    socialSignupInfo: SocialSignupInfo) => {

    this.setState({
      popupConf: {...this.state.popupConf, showSignupSocialPopup: showSignupSocialPopup},
      socialSignupInfo: socialSignupInfo
    });
  };

  closeSocialSignupPopupFunc = () => {
    this.setState({
      popupConf: {...this.state.popupConf, showSignupSocialPopup: false, showLoginPopup: true}
    });
  };

  showPasswordResetPopupFunc = (showPasswordResetPopup: boolean) => {
    this.setState({popupConf: {...this.state.popupConf, showPasswordResetPopup: showPasswordResetPopup}});
  };

  closeAllPopupFunc = (showUserPopup: boolean,
    showLoginPopup: boolean,
    showSignupPopup: boolean,
    showSendVerifyEmailPopup: boolean,
    showPasswordResetPopup: boolean,
    showSendPasswordResetEmailPopup: boolean) => {
    this.setState({
      popupConf: {
        ...this.state.popupConf,
        showUserPopup: showUserPopup,
        showLoginPopup: showLoginPopup,
        showSignupPopup: showSignupPopup,
        showSendVerifyEmailPopup: showSendVerifyEmailPopup,
        showPasswordResetPopup: showPasswordResetPopup,
        showSendPasswordResetEmailPopup: showSendPasswordResetEmailPopup
      },
      loginInputValues: {id: "", password: ""}
    });
  };


  showTermsDetailPopupFunc = (showTermsDetailPopup: boolean) => {
    this.setState({popupConf: {...this.state.popupConf, showTermsDetailPopup: showTermsDetailPopup}});
  };

  showTermsDetailPrivatePopupFunc = (showTermsDetailPrivatePopup: boolean, termsType: string) => {
    this.setState({
      popupConf: {...this.state.popupConf, showTermsDetailPrivatePopup},
      termsType
    });
  };


  showSendPasswordResetEmailPopupFunc = (showSendPasswordResetEmailPopup: boolean) => {
    this.setState({popupConf: {...this.state.popupConf, showSendPasswordResetEmailPopup: showSendPasswordResetEmailPopup}});
  };

  setResetPasswordEmailFunc = (resetPasswordEmail: string) => {
    this.setState({resetPasswordEmail: resetPasswordEmail});
  };


  private renderPopup(id: string) {
    if (id === 'loginPopup') return this.renderLoginPopupOrLoginFailPopup();
    else if (id === 'renderSendPasswordResetEmailPopup') return this.renderSendPasswordResetEmailPopup();
    else if (id === 'renderPasswordResetPopup') return this.renderPasswordResetPopup();
    else if (id === 'renderSendVerifyEmailPopup') return this.renderSendVerifyEmailPopup();
    else if (id === 'renderSignupPopup') return this.renderSignupPopup();
    else if (id === 'renderSignupSocialPopup') return this.renderSignupSocialPopup();
    else if (id === 'renderTermsDetailPopup') return this.renderTermsDetailPopup();
    else if (id === 'renderTermsDetailPrivatePopup') return this.renderTermsDetailPrivatePopup();
    else {
      console.error("unknown popupid : ", id);
    }
  }

  /*댓글 로그인 창 */
  private renderLoginPopupOrLoginFailPopup() {
    return <>
      {this.state.popupConf.showLoginFailPopup && <LoginFailPopup
          languageService={this.props.languageService}
          accountService={this.props.accountService}
          showLoginFailPopupFunc={this.showLoginFailPopupFunc}
          loginFailedAccountId={this.state.loginInputValues.id}
        />
        || <LoginPopup
          languageService={this.props.languageService}
          loginService={this.props.loginService}
          accountService={this.props.accountService}
          loginInputValues={this.state.loginInputValues}
          showLoginPopupFunc={this.showLoginPopupFunc}
          showLoginFailPopupFunc={this.showLoginFailPopupFunc}
          setIsLoggedInFunc={this.setIsLoggedInFunc}
          setLoginInputValuesFunc={this.setLoginInputValuesFunc}
          showSignupPopupFunc={this.showSignupPopupFunc}
          showSendVerifyEmailPopupFunc={this.showSendVerifyEmailPopupFunc}
          setSignupEmailFunc={this.setSignupEmailFunc}
          showSignupSocialPopupFunc={this.showSignupSocialPopupFunc}
          showPasswordResetPopupFunc={this.showPasswordResetPopupFunc}
        />}
    </>;
  }

  private renderSignupPopup() {
    return <SignupPopup
      languageService={this.props.languageService}
      accountService={this.props.accountService}
      notificationService={this.props.notificationService}
      showSignupPopupFunc={this.showSignupPopupFunc}
      closeAllPopupFunc={this.closeAllPopupFunc}
      showTermsDetailPopupFunc={this.showTermsDetailPopupFunc}
      showTermsDetailPrivatePopupFunc={this.showTermsDetailPrivatePopupFunc}
      setSignupEmailFunc={this.setSignupEmailFunc}
      showSendVerifyEmailPopupFunc={this.showSendVerifyEmailPopupFunc}
    />;
  }

  private renderSignupSocialPopup() {
    return <SignupSocialPopup
      languageService={this.props.languageService}
      accountService={this.props.accountService}
      notificationService={this.props.notificationService}
      showTermsDetailPopupFunc={this.showTermsDetailPopupFunc}
      showTermsDetailPrivatePopupFunc={this.showTermsDetailPrivatePopupFunc}
      showSignupSocialPopupFunc={this.showSignupSocialPopupFunc}
      showLoginPopupFunc={this.showLoginPopupFunc}
      socialSignupInfo={this.state.socialSignupInfo}
      closeSocialSignupPopupFunc={this.closeSocialSignupPopupFunc}
    />;
  }


  private renderTermsDetailPopup() {
    return <TermsDetailPopup
      languageService={this.props.languageService}
      showTermsDetailPopupFunc={this.showTermsDetailPopupFunc}
    />;
  }

  private renderTermsDetailPrivatePopup() {
    return <TermsDetailPrivatePopup
      languageService={this.props.languageService}
      showTermsDetailPrivateEssentialPopupFunc={this.showTermsDetailPrivatePopupFunc}
      termsType={this.state.termsType}
    />;
  }

  private renderSendVerifyEmailPopup() {
    return <SendVerifyEmailPopup
      languageService={this.props.languageService}
      notificationService={this.props.notificationService}
      signupEmail={this.state.signupEmail}
      showSendVerifyEmailPopupFunc={this.showSendVerifyEmailPopupFunc}
      showSignupPopupFunc={this.showSignupPopupFunc}
      closeAllPopupFunc={this.closeAllPopupFunc}
    />;
  }

  private renderPasswordResetPopup() {
    return <PasswordResetPopup languageService={this.props.languageService}
      accountService={this.props.accountService}
      notificationService={this.props.notificationService}
      signupEmail={this.state.signupEmail}
      showPasswordResetPopupFunc={this.showPasswordResetPopupFunc}
      showSendPasswordResetEmailPopupFunc={this.showSendPasswordResetEmailPopupFunc}
      showSignupPopupFunc={this.showSignupPopupFunc}
      closeAllPopupFunc={this.closeAllPopupFunc}
      setResetPasswordEmailFunc={this.setResetPasswordEmailFunc}
    />;
  }

  private renderSendPasswordResetEmailPopup() {
    return <SendPasswordResetEmailPopup languageService={this.props.languageService}
      accountService={this.props.accountService}
      notificationService={this.props.notificationService}
      resetPasswordEmail={this.state.resetPasswordEmail}
      showSendPasswordResetEmailPopupFunc={this.showSendPasswordResetEmailPopupFunc}
      showLoginPopupFunc={this.showLoginPopupFunc}
      closeAllPopupFunc={this.closeAllPopupFunc}
    />;
  }

}

export default withRouter(connect(
  (state: any) => ({
    loginInfo: state.main.loginInfo,
  }),
  dispatch => ({
    setLoginInfo: (info: IfLoginInfo) => dispatch({type: ACTION_TYPES.MAIN.LOGIN_INFO, payload: info}),
  })
)(PartnerQnaPagePopup));
