import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

export const useParamPjtDev = (): [number, string] => {
  const location = useLocation();
  const [projectId, setProjectId] = useState<number>(null);
  const [deviceId, setDeviceId] = useState<string>(null);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    const projectId = parseInt(urlSearchParams.get('projectId'));
    const deviceId = urlSearchParams.get('deviceId');
    setProjectId(isNaN(projectId) ? null : projectId);
    setDeviceId(deviceId);
  }, [location.search]);

  return [projectId, deviceId];
};