import React, {Component} from "react";
import {SsqImages} from "../images/SsqImages";
import {getMemberTypeLangKey, MemberType} from "../definitions/MemberType";
import {getInterestFieldTypeLangKey, InterestFieldType} from "../definitions/InterestFieldType";
import {LanguageService} from "../language/LanguageService";
import {AccountService, emptyAccountInfo, emptySignupValues, IAccountInfo, ICountryCode, ISignupValues} from "../repositories/AccountService";
import {NotificationService} from "../repositories/NotificationService";
import {InputWrapForSelect} from "../style/CommonStyledComponents";
import {emptySocialSignupInfo, SocialSignupInfo} from "../login/LoginService";
import {TermsType} from "../definitions/TermsType";
import axios from "axios";
import {LANG_EN, LANG_EN_US, LANG_KO_KR} from "language/langutil";
import {SubmitTermsPopup} from "./SubmitTermsPopup";
import styled from "styled-components";
import {SignupWrap} from "header/SignupPopup";

interface Props {
  languageService: LanguageService;
  accountService: AccountService;
  notificationService: NotificationService;
  showTermsDetailPopupFunc: Function;
  showTermsDetailPrivatePopupFunc: Function;
  socialSignupInfo: SocialSignupInfo;
  showSignupSocialPopupFunc: Function;
  showLoginPopupFunc: Function;
  closeSocialSignupPopupFunc: Function;
}

interface State {
  showUserPopup: boolean;
  showLoginPopup: boolean;
  showSignupPopup: boolean;
  isExistEmail: boolean;
  isInValidEmail: boolean;
  isExistNickname: boolean;
  isValidNickname: boolean;
  isValidCompany: boolean;
  isEmployeeEmail: boolean;
  countryCodes: ICountryCode[];
  isClickedSignupButton: boolean;
  signupValues: ISignupValues;
  accountInfo: IAccountInfo;
  isValidFirstname: boolean;
  isValidLastname: boolean;
  scrollPosition: string;
  countryNumber: string[];
  acceptAll: boolean;
  signupPage: number;
  comparySchool: 'company' | 'school';
}

export class SignupSocialPopup extends Component<Props, State> {
  homeRef: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);

    this.state = {
      showUserPopup: false,
      showLoginPopup: false,
      showSignupPopup: false,
      isExistEmail: false,
      isInValidEmail: false,
      isExistNickname: false,
      isValidNickname: true,
      isValidCompany: true,
      countryCodes: [],
      isClickedSignupButton: false,
      signupValues: emptySignupValues,
      accountInfo: emptyAccountInfo,
      isValidFirstname: true,
      isValidLastname: true,
      isEmployeeEmail: false,
      scrollPosition: "",
      countryNumber: [],
      acceptAll: false,
      signupPage: 0,
      comparySchool: 'company'
    };
    this.homeRef = React.createRef();
  }

  async componentDidMount() {
    let countryCodes = await this.props.accountService.getAllCountryCodes();
    let countryNumber = [];
    let defaultCode = "";
    let defaultNumber = "";
    countryCodes.forEach(i => !countryNumber.includes(i.countryCode) && countryNumber.push(i.countryCode));

    let array = countryCodes.filter(country => country.countryCode2 == this.getCountryCode());
    if (array[0] !== null) {
      defaultNumber = array[0].countryCode;
      defaultCode = array[0].countryCode2;
    }

    countryCodes = countryCodes.slice().sort((a, b) => {
      if (this.props.languageService.getLanguage() == LANG_KO_KR) return (a.countryNameKo || "").localeCompare(b.countryNameKo || "");
      else return (a.countryName || "").localeCompare(b.countryName || "");
    });

    let signupValues: ISignupValues = {
      ...emptySignupValues,
      email: this.props.socialSignupInfo.email,
      password: this.props.socialSignupInfo.socialId,
      loginType: this.props.socialSignupInfo.loginType,
      countryCode2: defaultCode,
      countryCode: defaultNumber
    };

    this.setState({
      countryCodes,
      signupValues,
      countryNumber
    });
  }


  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (this.state.signupValues.acceptTerms != prevState.signupValues.acceptTerms ||
      this.state.signupValues.acceptPrivateEssential != prevState.signupValues.acceptPrivateEssential ||
      this.state.signupValues.acceptPrivateOptional != prevState.signupValues.acceptPrivateOptional) this.acceptAllByCountry();

    if (this.state.signupValues.memberType != prevState.signupValues.memberType) {
      if (this.state.signupValues.memberType == MemberType.STUDENT) this.setState({comparySchool: 'school'});
      else this.setState({comparySchool: 'company'});
    }
  }

  getTranslation(key: string): string {
    this.props.languageService.setNamespace("I18N_NAMESPACE_HEADER");
    return this.props.languageService.getTranslationByKey(key) || "";
  }

  setSignupPage = (page: number) => {
    this.setState({signupPage: page});
  };

  renderMemberTypeOption(memberType: string) {
    return <option value={memberType}>{this.getTranslation(getMemberTypeLangKey(memberType))}</option>;
  }

  renderInterestFieldTypeOption(InterestFieldType: string) {
    return <option
      value={InterestFieldType}>{this.getTranslation(getInterestFieldTypeLangKey(InterestFieldType))}</option>;
  }

  setSignupValues = (dataKey: string, value: any) => {
    this.setState({
      signupValues: {
        ...this.state.signupValues,
        [dataKey]: value
      }
    });
  };

  setScrollPosition() {
    let defaultPosition = "header";
    //이메일 스크롤
    if (this.state.isExistEmail || this.state.isEmployeeEmail || this.state.isInValidEmail) defaultPosition = "email";
    //이름 스크롤
    if (!this.state.isValidFirstname) defaultPosition = "firstName";
    //성 스크롤
    else if (!this.state.isValidLastname) defaultPosition = "lastName";
    //국가 스크롤
    else if (this.state.isClickedSignupButton && ["", null].includes(this.state.signupValues.countryCode2)) defaultPosition = "nationality";
    //닉네임 스크롤
    else if (this.state.isExistNickname || !this.state.isValidNickname) defaultPosition = "nickname";
    //관심산업 스크롤
    else if (this.state.isClickedSignupButton && this.state.signupValues.interestFieldType === InterestFieldType.SELECT) defaultPosition = "interestFieldType";
    //업종 스크롤
    else if (this.state.isClickedSignupButton && this.state.signupValues.memberType === MemberType.SELECT) defaultPosition = "memberType";
    //회사 스크롤
    else if (!this.state.isValidCompany || (this.state.isClickedSignupButton && this.state.signupValues.company === "")) defaultPosition = "company";
    //체크 스크롤
    else if (this.state.isClickedSignupButton && this.state.signupValues.acceptTerms === false) defaultPosition = "checkBox";

    else if (this.state.isClickedSignupButton && this.state.signupValues.acceptPrivateEssential == false) defaultPosition = "checkBox";

    return defaultPosition;
  }

  scrollOnClick() {
    this.homeRef.current?.scrollIntoView({behavior: 'smooth'});
  }

  renderInputAreaForStudent() {
    return <div className={"input_memtype02"}>
      <p>
        <span>{this.getTranslation("I18N_SIGNUP_SCHOOL")}</span>
        {this.state.scrollPosition == "company" ? <span ref={this.homeRef}> * </span> : <span> * </span>}
      </p>
      <div>
        <input type={"text"} aria-label={"signup-input-school"}
          value={this.state.signupValues.school}
          style={(!this.state.isValidCompany || (this.state.isClickedSignupButton && this.state.signupValues.school === ""))
            ? {border: 'solid 1px #E6003E'} : {}}
          onChange={(e) =>
            this.setSignupValues('school', e.target.value)}
          onBlur={() => {
            if (this.state.signupValues.school !== "") {
              this.setState({isValidCompany: true});
            } else {
              this.setState({isValidCompany: false});
            }
          }}
          maxLength={20} placeholder={this.getTranslation("I18N_SIGNUP_SCHOOL_PLACEHOLDER")}
        />
        {(this.state.isClickedSignupButton && this.state.signupValues.school === "") &&
          <div className={"SingUpInvalidAlert"}>{this.getTranslation("I18N_SIGNUP_REQUIRED_ALERT")}</div>}
      </div>

      <div>
        <p>{this.getTranslation("I18N_SIGNUP_MAJOR_GRADE")}</p>
        <input type={"text"} aria-label={"signup-input-majorAndGrade"}
          value={this.state.signupValues.majorAndGrade}
          onChange={(e) =>
            this.setSignupValues('majorAndGrade', e.target.value)}
          maxLength={20}
          placeholder={this.getTranslation("I18N_SIGNUP_MAJOR_GRADE_PLACEHOLDER")}
        />
      </div>
    </div>;
  }

  renderInputAreaForNotStudent() {
    return <div className={"input_memtype02"}>
      <p>
        <span>{this.getTranslation("I18N_SIGNUP_COMPANY")}</span>
        {this.state.scrollPosition == "company" ? <span ref={this.homeRef}> * </span> : <span> * </span>}
      </p>
      <div>
        <input type={"text"} aria-label={"signup-input-company"}
          value={this.state.signupValues.company}
          style={(!this.state.isValidCompany || (this.state.isClickedSignupButton && this.state.signupValues.company === ""))
            ? {border: 'solid 1px #E6003E'} : {}}
          onChange={(e) =>
            this.setSignupValues('company', e.target.value.trim())}
          onBlur={() => {
            if (this.state.signupValues.company !== "") {
              this.setState({isValidCompany: true});
            } else {
              this.setState({isValidCompany: false});
            }
          }}
          maxLength={20} placeholder={this.getTranslation("I18N_SIGNUP_COMPANY_PLACEHOLDER")}
        />
        {(this.state.isClickedSignupButton && this.state.signupValues.company === "") &&
          <div className={"SingUpInvalidAlert"}>{this.getTranslation("I18N_SIGNUP_REQUIRED_ALERT")}</div>}
      </div>

      <div>
        <p>{this.getTranslation("I18N_SIGNUP_DEPARTMENT_TITLE")}</p>
        <input type={"text"} aria-label={"signup-input-departmentAndTitle"}
          value={this.state.signupValues.departmentAndTitle}
          onChange={(e) =>
            this.setSignupValues('departmentAndTitle', e.target.value)}
          maxLength={20}
          placeholder={this.getTranslation("I18N_SIGNUP_DEPARTMENT_TITLE_PLACEHOLDER")}
        />
      </div>
    </div>;
  }

  closeSignupSocialPopup = () => {
    this.props.showSignupSocialPopupFunc(false, emptySocialSignupInfo);
  };

  closeLoginPopup() {
    this.props.showLoginPopupFunc(false);
  }

  closeSignupPopup = () => {
    this.props.closeSocialSignupPopupFunc();

    this.setState({
      signupValues: {
        ...this.state.signupValues,
        acceptTerms: false,
        acceptPrivateEssential: false,
        acceptMail: false
      }
    });
  };

  showTermsDetailPopup = () => {
    this.props.showTermsDetailPopupFunc(true);
  };

  showTermsDetailPrivateEssentialPopup() {
    this.props.showTermsDetailPrivatePopupFunc(true, TermsType.ESSENTIAL);
  }

  showTermsDetailPrivateOptionalPopup() {
    this.props.showTermsDetailPrivatePopupFunc(true, TermsType.OPTIONAL);
  }

  isInValidEmail(email: string): boolean {
    const reg = /^\w+(?:[\.-]\w+)*@\w+(?:[\.-]\w+)*(?:\.\w{2,5})+$/;
    return !reg.test(email);
  }

  isEmployeeEmail(email: string): boolean {
    const reg = /@(ls-electric\.com|lselectricamerica\.com|lselectric\.co\.kr|lsis\.com)$/;
    return reg.test(email);
  }

  async isExistsEmail(email: string): Promise<boolean> {
    return await this.props.accountService.isExistAccountId(email);
  }

  async verifyEmail(email: string) {
    let isInValidEmail = false;
    let isExistEmail = false;
    let isEmployeeEmail = false;

    if (this.isInValidEmail(email)) {
      isInValidEmail = true;
    } else {
      if (await this.isExistsEmail(email)) {
        isExistEmail = true;
      } else {
        if (await this.isExistsEmail(email)) {
          isExistEmail = true;
        }
      }
    }

    this.setState({
      isInValidEmail,
      isExistEmail,
      isEmployeeEmail
    });
  }

  async isExistsNickname(nickname: string): Promise<boolean> {
    let check = false;
    await axios.get('/api/guest/isExistNickname', {
      params: {nickname: nickname}
    }).then((res) => {
      check = res.data;
    });

    return check;
  }

  async verifyNickname(nickname: string) {
    let isExistNickname = false;
    let isValidNickname = true;

    if (nickname === "" || nickname.length > 11) {
      isValidNickname = false;
    }

    if (await this.isExistsNickname(nickname)) {
      isExistNickname = true;
    }

    this.setState({
      isExistNickname,
      isValidNickname
    });
  }


  acceptAllByCountry = () => {
    if (this.props.languageService.getLanguage() == LANG_KO_KR) {
      if (this.state.signupValues.acceptTerms && this.state.signupValues.acceptPrivateEssential &&
        this.state.signupValues.acceptPrivateOptional) return this.setState({acceptAll: true});
    } else {
      if (this.state.signupValues.acceptTerms && this.state.signupValues.acceptPrivateEssential) return this.setState({acceptAll: true});
    }
    return this.setState({acceptAll: false});
  };

  acceptAllProgressByCountry = () => {
    if (this.props.languageService.getLanguage() == LANG_KO_KR) {
      this.setState({
        signupValues: {
          ...this.state.signupValues,
          acceptTerms: !this.state.acceptAll,
          acceptPrivateEssential: !this.state.acceptAll,
          acceptPrivateOptional: !this.state.acceptAll,
        }
      });
    } else {
      this.setState({
        signupValues: {
          ...this.state.signupValues,
          acceptTerms: !this.state.acceptAll,
          acceptPrivateEssential: !this.state.acceptAll,
        }
      });
    }
    this.setState({acceptAll: !this.state.acceptAll});
  };

  async verfyFirstname(firstName: string) {
    let isValidFirstname = true;


    if (firstName === "" || firstName.length > 21) {
      isValidFirstname = false;
    }

    this.setState({
      isValidFirstname,
    });
  }

  async verfyLastname(lastName: string) {
    let isValidLastname = true;

    if (lastName === "" || lastName.length > 21) {
      isValidLastname = false;
    }

    this.setState({
      isValidLastname
    });
  }

  getCountryCode() {
    const languageToCountry = {
      cs: "CZ", da: "DK", el: "GR", en: "US", et: "EE", ga: "IE", hi: "IN", he: "IL", ja: "JP",
      ko: "KR", sv: "SE", sl: "SI", zh: "CN", ar: "AE", fa: "IR", be: "BY", uk: "UA", kk: "KZ"
    };


    const language = window.navigator.language || "";
    if (language !== "") {// 브라우저 접속국가코드
      if (language.includes('-')) return language.slice(-2).toUpperCase();
      else {
        if (Object.keys(languageToCountry).includes(language)) {
          return languageToCountry[language];
        } else return language.toUpperCase();
      }
    }

    if (this.props.languageService.getLanguage() == LANG_KO_KR)
      return 'KR';
    else if ([LANG_EN, LANG_EN_US].includes(this.props.languageService.getLanguage()))
      return 'US';

    return "";
  }


  renderSignupInputPopUp() {
    return <div>
      <button className={"close-button"} onClick={() => {
        if (window.confirm(this.getTranslation("I18N_SIGNUP_LEAVE_POPUP_ALERT"))) {
          this.closeSignupSocialPopup();
        }
      }}
      >
        <img alt={"close_small_icon.svg"} src={SsqImages.common.icon.closeSmallIcon()}/>
      </button>
      <SolcialSignupWrap>
        <div className={"Signup_top"}>
          <div className={"popup_tit"}>
            <button onClick={() => this.setSignupPage(0)}>
              <img alt={"back_button.svg"} src={SsqImages.header.icon.backButton()}/>
            </button>
            {this.getTranslation("I18N_TITLE_JOIN")}
          </div>
          <div className={"popup_noti"}>
            {this.getTranslation("I18N_DUPLICATE_INPUT_INFORMATION")}
            <div className={"requ"}>
              <span> * </span>
              <span>{this.getTranslation("I18N_SIGNUP_MANDATORY_INPUT")}</span>
            </div>
          </div>
        </div>

        <div className={"Signup_Input"}>
          <div className={"Signup-default-info"}>
            <div className={"input_row"}>
              <p>
                <span>{this.getTranslation("I18N_SIGNUP_EMAIL")}</span>
                {this.state.scrollPosition == "email" ? <span ref={this.homeRef}> * </span> : <span> * </span>}
              </p>
              <div>
                <input type={"text"} aria-label={"signup-input-email"}
                  disabled={true}
                  style={(this.state.isExistEmail || this.state.isInValidEmail) ? {border: 'solid 1px #E6003E'} : {}}
                  value={this.props.socialSignupInfo.email}
                  onChange={(e) =>
                    this.setSignupValues('email', e.target.value)}
                  onBlur={async () => {
                    if (this.state.signupValues.email !== "") {
                      await this.verifyEmail(this.state.signupValues.email);
                    }
                  }}
                  placeholder={this.getTranslation("I18N_SIGNUP_PLACEHOLDER_INPUT")}
                />
                {this.state.isInValidEmail &&
                  <div
                    className={"SingUpInvalidAlert"}>{this.getTranslation("I18N_SIGNUP_EMAIL_INVALID_ALERT")}</div>}
                {this.state.isExistEmail &&
                  <div
                    className={"SingUpInvalidAlert"}>{this.getTranslation("I18N_SIGNUP_EMAIL_DUPLICATE_ALERT")}</div>}
              </div>
            </div>
            <div className={"input_row"}>
              <p>
                <span>{this.getTranslation("I18N_SIGNUP_FIRST_NAME")}</span>
                {this.state.scrollPosition == "firstName" ? <span ref={this.homeRef}> * </span> : <span> * </span>}
              </p>
              <div>
                <input type={"text"} aria-label={"signup-input-name"}
                  maxLength={20}
                  style={(!this.state.isValidFirstname) ? {border: 'solid 1px #E6003E'} : {}}
                  onChange={(e) =>
                    this.setSignupValues('name', e.target.value)}
                  onBlur={async () => this.verfyFirstname(this.state.signupValues.name)}
                  placeholder={this.getTranslation("I18N_SIGNUP_NAME_PLACEHOLDER")}
                />
                {!this.state.isValidFirstname &&
                  <div
                    className={"SingUpInvalidAlert"}>{this.getTranslation("I18N_SIGNUP_NAME_INVALID_ALERT")}</div>}
              </div>
            </div>

            <div className={"input_row"}>
              <p>
                <span>{this.getTranslation("I18N_SIGNUP_LAST_NAME")}</span>
                {this.state.scrollPosition == "lastName" ? <span ref={this.homeRef}> * </span> : <span> * </span>}
              </p>
              <div>
                <input type={"text"} aria-label={"signup-input-name"}
                  maxLength={20}
                  style={(!this.state.isValidLastname) ? {border: 'solid 1px #E6003E'} : {}}
                  onChange={(e) =>
                    this.setSignupValues('familyName', e.target.value)}
                  onBlur={async () => this.verfyLastname(this.state.signupValues.familyName)}
                  placeholder={this.getTranslation("I18N_SIGNUP_NAME_PLACEHOLDER")}
                />
                {!this.state.isValidLastname &&
                  <div
                    className={"SingUpInvalidAlert"}>{this.getTranslation("I18N_SIGNUP_NAME_INVALID_ALERT")}</div>}
              </div>
            </div>

            <div className={"input_row input_memtype"}>
              <p>
                <span>{this.getTranslation("I18N_SIGNUP_COUNTRY")}</span>
                {this.state.scrollPosition == "nationality" ? <span ref={this.homeRef}> * </span> : <span> * </span>}
              </p>
              <div>
                <InputWrapForSelect className={"select_wrap"}>
                  <select aria-label={'select-memberType'}
                    value={this.state.signupValues.countryCode2}
                    onChange={(e) =>
                      this.setSignupValues('countryCode2', e.target.value)}
                    style={
                      (this.state.isClickedSignupButton && ["", null].includes(this.state.signupValues.countryCode2)) ?
                        {border: 'solid 1px #E6003E'} : {}
                    }
                  >
                    {this.state.countryCodes.map((country, index) => {
                      if (![null, ""].includes(country.countryCode2) && ![null, ""].includes(country.countryNameKo)) {
                        return <option key={index}
                          value={country.countryCode2}
                        >{this.props.languageService.getLanguage() == LANG_KO_KR ? country.countryNameKo : country.countryName}</option>;
                      }
                    })}
                  </select>
                  {(this.state.isClickedSignupButton && ["", null].includes(this.state.signupValues.countryCode2)) &&
                    <div className={"SingUpInvalidAlert"}>{this.getTranslation("I18N_SIGNUP_REQUIRED_ALERT")}</div>}
                </InputWrapForSelect>
              </div>
            </div>

            <div className={"input_row input_phone"}>{/*전화번호*/}
              <p>{this.getTranslation("I18N_SIGNUP_PHONE_NUMBER")}</p>
              <div>
                <InputWrapForSelect className={"select_wrap"}>
                  <select aria-label={'select-country-code'}
                    value={this.state.signupValues.countryCode}
                    onChange={(e) =>
                      this.setSignupValues('countryCode', e.target.value)}
                  >
                    {this.state.countryNumber.map((country, index) => {
                      return <option key={index}
                        value={country}>{country}</option>;
                    })}
                  </select>
                </InputWrapForSelect>
                <input type={"text"} aria-label={"signup-input-phoneNumber"}
                  value={this.state.signupValues.phoneNumber}
                  maxLength={15}
                  onChange={(e) =>
                    this.setSignupValues('phoneNumber', e.target.value.replace(/[^\d.]/g, '').replace(/(\..*)\./g, '$1'))}
                  placeholder={this.getTranslation("I18N_SIGNUP_PHONE_NUMBER_PLACEHOLDER")}
                />
              </div>
            </div>
          </div>
          <div className={"Signup-plus-title"}>
            <p>{this.getTranslation("I18N_SIGNUP_BUSINESS")}</p>
          </div>
          <div className={"Signup-plus-info"}>
            <div className={"input_row"}>{/*별명*/}
              <p>
                <span>{this.getTranslation("I18N_SIGNUP_NICKNAME")}</span>
                {this.state.scrollPosition == "nickname" ? <span ref={this.homeRef}> * </span> : <span> * </span>}
              </p>
              <div>
                <input
                  type={"text"} aria-label={"signup-input-nickname"}
                  style={(this.state.isExistNickname || !this.state.isValidNickname) ? {border: 'solid 1px #E6003E'} : {}}
                  maxLength={10}
                  value={this.state.signupValues.nickname}
                  onChange={(e) =>
                    this.setSignupValues('nickname', e.target.value)}
                  onBlur={async (e) => {
                    const nickname = e.target.value.trim();
                    this.setSignupValues('nickname', nickname);
                    this.verifyNickname(nickname);
                  }}
                  placeholder={this.getTranslation("I18N_SIGNUP_NICKNAME_PLACEHOLDER")}
                />
                {this.state.isExistNickname &&
                  <div
                    className={"SingUpInvalidAlert"}>{this.getTranslation("I18N_SIGNUP_NICKNAME_DUPLICATE_ALERT")}</div>}
                {!this.state.isValidNickname &&
                  <div
                    className={"SingUpInvalidAlert"}>{this.getTranslation("I18N_SIGNUP_NICKNAME_INVALID_ALERT")}</div>}
              </div>
            </div>
            <div className={"input_row"}>
              <p>
                <span>{this.getTranslation("I18N_SIGNUP_INTEREST_FIELD")}</span>
                {this.state.scrollPosition == "interestFieldType" ? <span ref={this.homeRef}> * </span> : <span> * </span>}
              </p>
              <div>
                <InputWrapForSelect className={"select_wrap"}>
                  <select aria-label={'select-interestFieldType'}
                    style={
                      (this.state.isClickedSignupButton && this.state.signupValues.interestFieldType === InterestFieldType.SELECT) ?
                        {border: 'solid 1px #E6003E'} : {}
                    }
                    onChange={(e) =>
                      this.setSignupValues('interestFieldType', e.target.value)}
                    value={this.state.signupValues.interestFieldType}
                  >
                    <option value={InterestFieldType.SELECT} hidden={true}>
                      {this.getTranslation(getInterestFieldTypeLangKey(InterestFieldType.SELECT))}
                    </option>
                    {this.renderInterestFieldTypeOption(InterestFieldType.RECHARGEABLE_BATTERY)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.PROCESSING_MACHINE)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.CONSTRUCTION)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.RUBBER)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.AIRPORT_AIR)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.METAL)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.DISPLAY)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.ROBOT)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.MOBILE)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.CARPENTRY)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.SEMICONDUCTOR)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.DEVELOPMENT)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.FIBER)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.WATER_TREATMENT)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.SMART_FACTORY)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.FOOD)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.ELEVATOR)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.GLASS_CEMENT)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.MEDICAL_TREATMENT)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.CAR)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.ELECTRIC)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.RESTRAINT)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.STEEL)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.CONVEYOR)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.PACKING_MACHINE)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.PORT_SHIPBUILDING)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.CHEMISTRY)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.ENVIRONMENT)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.ETC)}
                  </select>
                  {(this.state.isClickedSignupButton && this.state.signupValues.interestFieldType === InterestFieldType.SELECT) &&
                    <div
                      className={"SingUpInvalidAlert"}>{this.getTranslation("I18N_SIGNUP_REQUIRED_ALERT")}</div>}
                </InputWrapForSelect>
              </div>
            </div>
            <div className={"input_row input_memtype"}>
              <p>
                <span>{this.getTranslation("I18N_SIGNUP_MEMBER_TYPE")}</span>
                {this.state.scrollPosition == "memberType" ? <span ref={this.homeRef}> * </span> : <span> * </span>}
              </p>
              <div>
                <InputWrapForSelect className={"select_wrap"}>
                  <select aria-label={'select-memberType'}
                    style={
                      (this.state.isClickedSignupButton && this.state.signupValues.memberType === MemberType.SELECT) ?
                        {border: 'solid 1px #E6003E'} : {}
                    }
                    onChange={(e) => {
                      this.setState({
                        signupValues: {
                          ...this.state.signupValues,
                          ['memberType']: e.target.value,
                          ['company']: e.target.value == MemberType.STUDENT ? "" : this.state.signupValues.company || "",
                          ['departmentAndTitle']: e.target.value == MemberType.STUDENT ? "" : this.state.signupValues.departmentAndTitle || "",
                          ['school']: e.target.value != MemberType.STUDENT ? "" : this.state.signupValues.school || "",
                          ['majorAndGrade']: e.target.value != MemberType.STUDENT ? "" : this.state.signupValues.majorAndGrade || ""
                        }
                      });

                    }
                    }
                    value={this.state.signupValues.memberType}
                  >
                    <option value={MemberType.SELECT} hidden={true}>
                      {this.getTranslation(getMemberTypeLangKey(MemberType.SELECT))}
                    </option>
                    {this.renderMemberTypeOption(MemberType.MACHINE_MAKER)}
                    {this.renderMemberTypeOption(MemberType.SMALL_WHOLESALE)}
                    {this.renderMemberTypeOption(MemberType.SET_MAKER)}
                    {this.renderMemberTypeOption(MemberType.ELECTRIC_COMPANY)}
                    {this.renderMemberTypeOption(MemberType.DEALERSHIP)}
                    {this.renderMemberTypeOption(MemberType.PANEL_MAKER)}
                    {this.renderMemberTypeOption(MemberType.STUDENT)}
                    {this.renderMemberTypeOption(MemberType.ETC)}
                  </select>
                  {(this.state.isClickedSignupButton && this.state.signupValues.memberType === MemberType.SELECT) &&
                    <div
                      className={"SingUpInvalidAlert"}>{this.getTranslation("I18N_SIGNUP_REQUIRED_ALERT")}</div>}
                </InputWrapForSelect>
              </div>
            </div>
            <div className={"input_row"}>
              {this.state.signupValues.memberType === MemberType.STUDENT ?
                this.renderInputAreaForStudent() : this.renderInputAreaForNotStudent()}
            </div>
            {/*
        <div className={"input_row input_check all_check"}>
          <div>
            <input id={"check-accept-all"} type={"checkbox"}
              checked={this.state.acceptAll}
              onChange={() => this.acceptAllProgressByCountry()}/>
            <label htmlFor={"check-accept-all"}></label>
            <span className={"check_txt_all"}>{this.getTranslation("I18N_SIGNUP_ACCEPT_ALL")}</span>
          </div>
        </div>

        <div className={"input_row input_check sub_check"}>
          <div>
            <input id={"check-accept-terms"} type={"checkbox"}
              checked={this.state.signupValues.acceptTerms}
              onChange={() =>
                this.setSignupValues('acceptTerms', !this.state.signupValues.acceptTerms)}
            />
            <label htmlFor={"check-accept-terms"}
              style={(this.state.isClickedSignupButton && this.state.signupValues.acceptTerms === false)
                ? {border: 'solid 1px #E6003E'} : {}}></label>
            <span className={"check_txt"}>{this.getTranslation("I18N_SIGNUP_ACCEPT_TERMS")}</span>
            <button onClick={() => this.showTermsDetailPopup()}>
              {this.getTranslation("I18N_SIGNUP_ACCEPT_TERMS_CONDITIONS")}
            </button>
          </div>
          <div>
            <input id={"check-accept-private-essential"} type={"checkbox"}
              checked={this.state.signupValues.acceptPrivateEssential}
              onChange={() =>
                this.setSignupValues('acceptPrivateEssential', !this.state.signupValues.acceptPrivateEssential)}
            />
            <label htmlFor={"check-accept-private-essential"}
              style={(this.state.isClickedSignupButton && this.state.signupValues.acceptPrivateEssential === false)
                ? {border: 'solid 1px #E6003E'} : {}}></label>
            <span
              className={"check_txt"}>{this.getTranslation("I18N_SIGNUP_ACCEPT_PRIVATE_ESSENTIAL")}</span>
            <button onClick={() => this.showTermsDetailPrivateEssentialPopup()}>
              {this.getTranslation("I18N_SIGNUP_ACCEPT_TERMS_CONDITIONS")}
            </button>
          </div>
          {this.props.languageService.getLanguage() === LANG_KO_KR && <div>
            <input id={"check-accept-private-optional"} type={"checkbox"}
              checked={this.state.signupValues.acceptPrivateOptional}
              onChange={() =>
                this.setSignupValues('acceptPrivateOptional', !this.state.signupValues.acceptPrivateOptional)}
            />
            <label htmlFor={"check-accept-private-optional"}/>
            <span
              className={"check_txt"}>{this.getTranslation("I18N_SIGNUP_ACCEPT_PRIVATE_OPTIONAL")}</span>
            <button onClick={() => this.showTermsDetailPrivateOptionalPopup()}>
              {this.getTranslation("I18N_SIGNUP_ACCEPT_TERMS_CONDITIONS")}
            </button>
          </div>}

          {this.state.scrollPosition == "checkBox" ? <div ref={this.homeRef}/> : <div/>}

          <div>
            <input id={"check-accept-mail"} type={"checkbox"}
              checked={this.state.signupValues.acceptMail}
              onChange={() =>
                this.setSignupValues('acceptMail', !this.state.signupValues.acceptMail)}
            />
            <label htmlFor={"check-accept-mail"}></label>
            <span className={"check_txt"}>{this.getTranslation("I18N_SIGNUP_ACCEPT_MAIL")}</span>
          </div>
        </div>
        */}
          </div>


        </div>
        <div className={"Submit-Button-Wrap"}>
          <div className={"Submit-Button"} onClick={async () => {
            this.setState({isClickedSignupButton: true});
            await this.verifyEmail(this.state.signupValues.email);

            await this.verifyNickname(this.state.signupValues.nickname);
            await this.verfyFirstname(this.state.signupValues.name);
            await this.verfyLastname(this.state.signupValues.familyName);

            if (this.verifyInputValues()) {
              await this.props.accountService.createAccount(this.state.signupValues);
              this.closeSignupSocialPopup();
              this.closeLoginPopup();

              window.alert(this.getTranslation("I18N_SOCIAL_SIGNUP_SUCCESS"));
            } else {
              await this.setState({
                scrollPosition: this.setScrollPosition()
              });
              this.scrollOnClick();
            }
          }}>
            {this.getTranslation("I18N_SIGNUP_BUTTON_SIGNUP")}
          </div>
        </div>
      </SolcialSignupWrap>
    </div>;
  }

  showTermsPopup() {
    return <SubmitTermsPopup
      languageService={this.props.languageService}
      closeAllPopupFunc={this.closeSignupSocialPopup}
      signupValues={this.state.signupValues}
      setSignupValues={this.setSignupValues}
      pageUp={this.setSignupPage}
      setAcceptAll={this.acceptAllProgressByCountry}
      acceptAll={this.state.acceptAll}
      showTermsDetailPopup={this.showTermsDetailPopup}
      isManageMyInfo={false}
      closeTermsPopup={this.closeSignupPopup}
    />;
  }

  render() {
    return <div style={{"padding": 0}}>
      <div className={"Signup_Popup"}>
        {this.state.signupPage == 0 && this.showTermsPopup()}
        {this.state.signupPage == 1 && this.renderSignupInputPopUp()}
      </div>
    </div>;
  }

  private verifyInputValues() {
    return !this.state.isExistEmail &&
      !this.state.isEmployeeEmail &&
      !this.state.isInValidEmail &&
      this.state.isValidFirstname &&
      this.state.isValidLastname &&
      !this.state.isExistNickname &&
      this.state.isValidNickname &&
      !["", null].includes(this.state.signupValues.countryCode2) &&
      this.state.isValidCompany &&
      (this.state.comparySchool == 'company' && this.state.signupValues.company !== "" || this.state.comparySchool == 'school' && this.state.signupValues.school !== "") &&
      this.state.signupValues.acceptTerms &&
      this.state.signupValues.acceptPrivateEssential &&
      this.state.signupValues.memberType !== MemberType.SELECT &&
      this.state.signupValues.interestFieldType !== InterestFieldType.SELECT;
  }


}

const SolcialSignupWrap = styled(SignupWrap)`
  .Submit-Button-Wrap {
    padding: 0;

    .Submit-Button {
      width: 30rem;
      margin: 0 3rem;
    }
  }
`;

