// @flow
import * as React from 'react';
import {Route} from "react-router-dom";
import {LoadingLayer} from "ctls/modal/LoadingLayer";
import {useVerifyLoginInfo} from "asset/apis/useVerifyLoginInfo";
import {AssetStyle} from "asset/style/AssetStyled";
import {AssetToastMgr} from "asset/layout/toast/AssetToastMgr";
import styled from 'styled-components';

export const AssetBlankLayout = (props: { exact?: boolean, path: string | string[], children }) => {
  const isLogin = useVerifyLoginInfo();

  return <Route path={props.path}
    exact={!!props.exact}
    render={() => {
      return <React.Suspense fallback={<LoadingLayer visible={true}/>}>
        <AssetStyle/>
        {isLogin ? <LayoutBody>
          {React.Children.map(props.children, child => React.cloneElement(child))}
          <AssetToastMgr/>
        </LayoutBody> : <></>}
      </React.Suspense>;
    }}/>;
};

const LayoutBody = styled.div`
  
`;