export class CookieIntroItem {
  company: string = "";
  domain: string = "";
  intro: string = "";
  termsAndConditions: string = "";
  dataSubjectRights: string = "";
  privacyPolicy: string = "";
}

export const CookieIntroEssential: CookieIntroItem[] = [
  {
    company: "LS ELECTRIC",
    domain: ".ls-electric.com sol.ls-electric.com .sol.ls-electric.com",
    intro: "LS ELECTRIC has been playing a pivotal role in building the national key industry power grid and industrial automation through power equipment and systems used to supply power and protect systems, industrial automation that realizes production automation and energy optimization, and green business projects represented by smart grids.",
    termsAndConditions: "https://www.ls-electric.com/etc/privacy-notice",
    dataSubjectRights: "https://www.ls-electric.com/etc/privacy-notice",
    privacyPolicy: "https://www.ls-electric.com/etc/privacy-notice"
  },
  {
    company: "Google Inc.",
    domain: ".business.google.com accounts.google.com chat.google.com chromewebstore.google.com .google.com .play.google.com .policies.google.com .google.com",
    intro: "Google Inc. is an American multinational technology company that specializes in Internet-related services and products, which includes a search engine, cloud computing, software, and hardware.",
    termsAndConditions: "https://policies.google.com/terms",
    dataSubjectRights: "",
    privacyPolicy: "https://policies.google.com/privacy"
  },
  {
    company: "Kakao Corporation",
    domain: ".kakao.com .ds.kakao.com",
    intro: "Kakao Corporation is a Korean IT company and has its main product, search engine Daum, and SNS communication application Kakao Talk.",
    termsAndConditions: "https://www.kakao.com/policy/terms?lang=en&type=ts",
    dataSubjectRights: "https://www.kakao.com/policy/privacy?type=p&lang=en",
    privacyPolicy: "https://www.kakao.com/policy/privacy?type=p&lang=en"
  },
  {
    company: "Naver Corporation",
    domain: ".naver.com .ni.naver.com .nid.naver.com .blog.naver.com .smartstore.naver.com .wcs.naver.com talk.naver.com  help.naver.com ",
    intro: "Naver Corporation is a South Korean internet conglomerate headquartered in Seongnam that operates the search engine Naver.",
    termsAndConditions: "https://policy.naver.com/policy/service_en.html",
    dataSubjectRights: "",
    privacyPolicy: "https://policy.naver.com/policy/privacy_en.html"
  },
  {
    company: "Mapbox, Inc.",
    domain: ".mapbox.com",
    intro: "Mapbox is an American provider of custom online maps for websites and applications such as Foursquare, Lonely Planet, the Financial Times, The Weather Channel, Instacart, and Snapchat.",
    termsAndConditions: "https://www.mapbox.com/legal/tos",
    dataSubjectRights: "https://www.mapbox.com/legal/cookies",
    privacyPolicy: "https://www.mapbox.com/legal/privacy"
  }
];

export const CookieIntroAnalytical: CookieIntroItem[] = [
  {
    company: "Google Inc.",
    domain: ".google.com .tagmanager.google.com",
    intro: "Google Inc. is an American multinational technology company that specializes in Internet-related services and products, which includes a search engine, cloud computing, software, and hardware.",
    termsAndConditions: "https://policies.google.com/terms",
    dataSubjectRights: "",
    privacyPolicy: "https://policies.google.com/privacy"
  },
  {
    company: "FOUR GRIT Inc",
    domain: "sol.ls-electric.com",
    intro: "4Grit Inc. is a South Korean IT company, leverages 'beusable' to enhance website usability by analyzing it. This tool assists in improving the user experience of websites.",
    termsAndConditions: "https://www.beusable.net/en/terms",
    dataSubjectRights: "",
    privacyPolicy: "https://www.beusable.net/en/privacy"
  },
];
export const CookieIntroFunctionality: CookieIntroItem[] = [
  {
    company: "LS ELECTRIC",
    domain: ".ls-electric.com sol.ls-electric.com .sol.ls-electric.com",
    intro: "LS ELECTRIC has been playing a pivotal role in building the national key industry power grid and industrial automation through power equipment and systems used to supply power and protect systems, industrial automation that realizes production automation and energy optimization, and green business projects represented by smart grids.",
    termsAndConditions: "ls_terms",
    dataSubjectRights: "ls_data",
    privacyPolicy: "ls_privacy"
  }
];
export const CookieIntroAdvertising: CookieIntroItem[] = [
  {
    company: "Google Inc.",
    domain: ".google.com .tagmanager.google.com .doubleclick.net",
    intro: "Google Inc. is an American multinational technology company that specializes in Internet-related services and products, which includes a search engine, cloud computing, software, and hardware.",
    termsAndConditions: "https://policies.google.com/terms",
    dataSubjectRights: "",
    privacyPolicy: "https://policies.google.com/privacy"
  },
];