// @flow
import * as React from 'react';
import {RefObject, useEffect, useRef, useState} from 'react';
import * as colors from "asset/style/color";
import styled from 'styled-components';
import {useLoginInfo} from "hooks/useLoginInfo";
import {IconBtn, SearchWrap} from "asset/style/buttonInput";
import {AssetImages} from "asset/images/AssetImages";
import {fontFamilyMedium, fontFamilyRegular} from "style/fonts";
import {Link, useHistory} from "react-router-dom";
import {StatusIcon} from "../../cmpns/common/StatusIcon";
import {useApiAssetProjectList} from 'asset/apis/useApiProject';
import {useApiDeviceDetailList} from "asset/apis/useApiDevice";
import {useApiProjectDeviceListByUserId} from "asset/apis/useApiProjectDevice";
import {IfDeviceDetailRes} from "asset/models/IfDeviceEntity";
import {IfProjectDeviceRes} from "asset/models/IfProjectDeviceEntity";
import {groupBy} from "lodash-es";
import {useAssetSelDev, useAssetSelPjt} from "store/reducers/reducerAsset";
import {DEV_STAT} from 'asset/models/defs';
import {IfProjectEntity} from "asset/models/IfProjectEntity";
import useDebounceCallback from "hooks/useDebounceCallback";
import useOutsideClick from "hooks/useOutsideClick";
import {getIcon} from "asset/utils/asset-util";
import {i18ntrans} from "language/i18nkey";
import {useParamPjtDev} from 'asset/hooks/useParamPjtDev';
import {AssetOverlay} from "asset/style/AssetStyled";

type Props = {
  show: boolean,
  hide: () => void,
  buttonRef: RefObject<HTMLButtonElement>,
};


export const ProjectList = ({show, hide, buttonRef}: Props) => {
  const [projectId, deviceId] = useParamPjtDev();
  const loginInfo = useLoginInfo();
  const [selPjt, setSelPjt] = useAssetSelPjt();
  const [, setSelDev] = useAssetSelDev();
  const [pjts] = useApiAssetProjectList({userId: loginInfo.userId,});
  const [devs] = useApiDeviceDetailList({userId: loginInfo.userId, useYn: 'Y'});
  const [pjtdevs] = useApiProjectDeviceListByUserId({userId: loginInfo.userId, useYn: 'Y', verified: true});
  const [devmap, setDevmap] = useState<{ string?: IfDeviceDetailRes }>({});
  const [devGroup, setDevGroup] = useState<{ number?: IfProjectDeviceRes[]; }>({});
  const [keyword, dbnKeyword, setKeyword] = useDebounceCallback('', 500);
  const history = useHistory();
  const [pjtModalAni, setPjtModalAni] = useState(false);
  const [isMobile, setisMobile] = useState(false);
  const t = i18ntrans.asset;

  useEffect(() => {
    setSelDev(devmap[deviceId] ?? null);
  }, [devmap]);

  useEffect(() => {
    setSelPjt(pjts.find(ele => ele.projectId === projectId) ?? null);
  }, [pjts]);


  useEffect(() => {
    setDevGroup(groupBy(pjtdevs, "projectId"));
  }, [pjtdevs]);
  useEffect(() => {
    setDevmap(devs.reduce((acc, cur) => {
      acc[cur.deviceId] = cur;
      return acc;
    }, {}));
  }, [devs]);

  const [localPjt, setLocalPjt] = useState<IfProjectEntity>();

  useEffect(() => {
    setLocalPjt(selPjt);
  }, []);


  const [searchOpen, setSearchOpen] = useState(false);
  const resizingHandler = () => {
    if (window.innerWidth <= 1199) {
      setisMobile(true);
    } else {
      setisMobile(false);
    }
  };

  const ref = useRef();
  useOutsideClick([buttonRef], () => hide(), []);

  useEffect(() => {
    if (window.innerWidth <= 1199) {
      if (show) {
        setPjtModalAni(true);
      } else {
        setTimeout(() => {
          setPjtModalAni(false);
        }, 400);
      }
    } else {
      if (show) {
        setPjtModalAni(true);
      } else {
        setPjtModalAni(false);
      }
    }
    return () => {
      window.removeEventListener("resize", resizingHandler);
    };
  }, [show]);
  const search = useRef<HTMLInputElement>(null);
  return (
    <>
      {pjtModalAni && <div onClick={(e) => e.stopPropagation()}>
        <AppbarList className={(show ? 'open' : 'off')}>
          <div className="appbarlist_top">
            <Link to="#" className="flex" onClick={(e) => {
              e.preventDefault();
              setSelPjt(null);
              setSelDev(null);
              if (history.location.pathname.startsWith('/asset/monitor')) {
                history.push('/asset/monitor');
              } else {
                history.push(history.location.pathname);
              }
            }}>
              <div className="icon">
                <img src={AssetImages.common.icon.home()} alt={"icon_home.svg"}/>
              </div>
              <p onClick={hide}>{t("I18N_AST_ALL")}</p>
            </Link>
            <div className="flex">
              <div className="search_box">
                <IconBtn className="search" onClick={e => {
                  setSearchOpen(!searchOpen);
                  //search.current.focus();
                }}></IconBtn>
                <SearchWrap className={'search_input ' + (searchOpen ? 'on' : '')}
                  onTransitionEnd={(e) => (e.currentTarget.offsetWidth > 100) && search.current.focus()}>
                  <input value={keyword || ''} type="text" className="input-keyword" placeholder={t("I18N_AST_SEARCHING_FOR_ASSET")}
                    ref={search} onChange={(e) => setKeyword(e.target.value || '')}/>
                  <div className="search_input_btn">
                    <IconBtn className="search"></IconBtn>
                    <IconBtn className="close" onClick={e => {
                      setSearchOpen(!searchOpen);
                      setKeyword('');
                    }}></IconBtn>
                  </div>
                </SearchWrap>
              </div>
              <IconBtn className="close" onClick={hide}></IconBtn>
            </div>
          </div>
          <ul className="project_list">
            {pjts
              //.filter(pjt => pjt.projectName?.toUpperCase().includes((debounceKeyword || "").toUpperCase()))
              .filter(pjt => (devGroup[pjt.projectId] || [])
                .map(ele => devmap[ele.deviceId] ?? {})
                .filter(dev => dev.devName?.toUpperCase().includes((dbnKeyword || '').toUpperCase())).length > 0)
              .map(pjt => <li
                className={(localPjt?.projectId === pjt.projectId ? 'active' : '')}
                key={pjt.projectId}>
                <div className="project">
                  <Link to={'#'} onClick={(e) => {
                    setSelPjt(pjt);
                    setSelDev(null);
                    hide();
                    if (history.location.pathname.startsWith('/asset/monitor')) {
                      history.push("/asset/monitorproject/" + pjt.projectId);
                    } else {
                      e.preventDefault();
                      history.push(history.location.pathname + "?projectId=" + pjt.projectId);
                    }
                  }}>{pjt.projectName}</Link>
                  <IconBtn className="arrow_down_bk" onClick={e => pjt === localPjt ? setLocalPjt(null) : setLocalPjt(pjt)}></IconBtn>
                </div>
                <div className="asset_list">
                  {(devGroup[pjt.projectId] || [])
                    .map(ele => devmap[ele.deviceId] ?? {})
                    .filter(dev => dev.devName?.toUpperCase().includes((dbnKeyword || '').toUpperCase()))
                    .map((dev: IfDeviceDetailRes) =>
                      <Link to={'#'} key={dev.deviceId} onClick={(e) => {
                        e.preventDefault();
                        setSelPjt(localPjt);
                        setSelDev(dev);
                        hide();
                        if (history.location.pathname.startsWith('/asset/monitor')) {
                          history.push("/asset/monitordevice/" + dev.deviceId);
                        } else {
                          e.preventDefault();
                          history.push(history.location.pathname + "?projectId=" + localPjt.projectId + "&deviceId=" + dev.deviceId);
                        }
                      }}>
                        <StatusIcon icon={getIcon(dev.status as DEV_STAT)} size={20}/>{dev.devName}</Link>)}
                </div>
              </li>)}
          </ul>
        </AppbarList>
        {show &&
          <AssetOverlay className={"overlay tran-overlay " + (show ? 'on' : '')} style={{zIndex: '-1', cursor: 'default'}} onClick={e => {
            hide();
          }}></AssetOverlay>
        }
      </div>}
    </>);
};
const AppbarList = styled.div`
  width: 317px;
  height: calc(100vh - 75px);
  background: ${colors.snb02};
  border: solid 1px ${colors.divider01};
  border-left: none;
  position: absolute;;
  font-size: 1.5rem;
  top: 75px;
  left: 0;
  z-index: 9;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0;
  }

  .appbarlist_top {
    position: relative;
    height: 56px;
    border-bottom: solid 1px ${colors.divider01};
    padding: 0 20px 0 24px;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    justify-content: space-between;
    overflow: hidden;

    > div {
      height: 24px;
      align-items: center;
    }

    > a {
      align-items: center;
      color: ${colors.text02};
      height: 100%;
      padding-right: 20px;
      width: 100%;

      > p {
        width: 100%;
        height: 100%;
        line-height: 55px;
      }
    }

    .icon {
      margin-right: 5px;
      height: 20px;
    }

    .search_box {
      height: 24px;

      + .close {
        margin-left: 24px;
      }
    }

    .search_input {
      display: block;
      padding: 6px;
      transition: width 0.5s;
      max-height: initial;
      right: -6px;
      z-index: -1;

      input {
        background: ${colors.snb04};
        padding: 0 90px 0 16px;
        height: 44px;
      }

      .search_input_btn {
        right: 20px
      }

      &.on {
        right: 0;
        z-index: 2;
      }
    }
  }

  ul.project_list {
    li {
      position: relative;
      width: 100%;
      font-size: 1.5rem;
      ${fontFamilyRegular};

      div.project {
        display: flex;
        align-items: center;
        border-bottom: solid 1px ${colors.divider01};
        height: 56px;
        position: relative;

        a {
          display: flex;
          align-items: center;
          width: calc(100% - 64px);
          height: 100%;
          padding: 0 24px;
          color: ${colors.text02};

          &:before {
            display: inline-block;
            content: '';
            width: 20px;
            height: 20px;
            margin-right: 5px;
            background-size: cover;
            background-image: url(${AssetImages.common.icon.folder()});
          }
        }

        button {
          position: absolute;
          right: 0;
          width: 64px;
          height: 100%;
          background-position: center center;
          background-repeat: no-repeat;
        }

        &:hover, &:active {
          background: ${colors.accent03};
          ${fontFamilyMedium}
        }
      }

      .asset_list {
        background: ${colors.snb03};
        width: 100%;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.7s ease-out;

        a {
          position: relative;
          display: flex;
          align-items: center;
          height: 56px;
          padding: 0 24px 0 44px;
          color: ${colors.text02};

          &:before {
            display: inline-block;
            content: '';
            width: 3px;
            height: 100%;
            background: ${colors.component07};
            position: absolute;
            left: 0
          }

          &:hover, &:active {
            background: ${colors.accent03};
            ${fontFamilyMedium};

            &:before {
              width: 4px;
              background: ${colors.accent01};
            }
          }
        }
      }

      &.active {
        div.project button {
          transform: scaleY(-1);
        }

        .asset_list {
          max-height: 100vh;
          transition: max-height 2s ease-out;
        }
      }
    }
  }

  @media screen and (max-width: 1199px) {
    position: fixed;
    width: 100%;
    top: 110px;
    border: none;
    &.open {
      opacity: 1;
      animation: AniDown 0.5s forwards;
    }

    &.off {
      animation: AniUp 0.5s forwards;
    }

    @keyframes AniDown {
      from {
        height: 0;
      }

      to {
        height: calc(100vh - 110px);
      }
    }

    @keyframes AniUp {
      from {
        height: calc(100vh - 110px);
      }

      to {
        height: 0;
      }
    }

    ul.project_list {
      li a {
        padding-left: 44px !important;
        ${fontFamilyMedium}
      }

      .asset_list a {
        padding-left: 64px !important;
      }
    }
  }
`;