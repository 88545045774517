// @flow
import * as React from 'react';
import {useEffect, useState} from 'react';
import {DocType} from "definitions/DocType";

import styled from "styled-components";
import {getDocTypeDropDownStr} from "definitions/DocTypeUtil";
import {SiteLanguageType} from "definitions/SiteLanguageType";
import {docwriteutil} from "docwrite/community/docwriteutil";
import {LoginUtil} from "util/loginutil";
import {roleutil} from "util/roleutil";

type Props = {
  loginUtil: LoginUtil;
  docType: string;
  setDocType: (docType: string) => void;
  readonly?: boolean;
  siteLang: string;
};


const i18all = {
  main_qna: "I18N_MAIN_QNA",
  main_tech_blog: "I18N_MAIN_TECH_BLOG",
  main_info_share: "I18N_MAIN_INFO_SHARE",
  main_sample_store: "I18N_MAIN_SAMPLE_STORE",
  main_doc: "I18N_MAIN_DOC",
  main_rnd: "I18N_MAIN_RND",
};
type MainType = "main_qna" | "main_tech_blog" | "main_info_share" | "main_sample_store" | "main_doc" | "main_rnd";

export const downloadtypes = [DocType.MANUAL, DocType.CATALOG, DocType.CERTIFICATION, DocType.CAD_DATA, DocType.DATASHEET, DocType.SOFTWARE,
  DocType.OS, DocType.EDUCATION_NOTE, DocType.EDUCATION_VIDEO, DocType.SW_SERVICE, DocType.TROUBLE_SHOOTING];

export const rndTypes = [DocType.RND_NOTI, DocType.RND_QNA_Q, DocType.RND_TALK];
/*export const downloadtypes = [DocType.MANUAL, DocType.CATALOG, DocType.CERTIFICATION, DocType.CAD_DATA, DocType.DATASHEET, DocType.SOFTWARE,
  DocType.OS, DocType.EDUCATION_NOTE, DocType.EDUCATION_VIDEO];*/

export const DropDownCmmntyDocType = (props: Props) => {
  const getMainTypes = () => {
    if ([SiteLanguageType.KO_KR].includes(props.siteLang)) {
      if ((roleutil.hasRole('RND', props.loginUtil.info.userRoles))) {
        return ["main_doc", "main_qna", "main_tech_blog", "main_info_share", "main_sample_store", "main_rnd"];
      }
      return ["main_doc", "main_qna", "main_tech_blog", "main_info_share", "main_sample_store"];
      //return ["main_qna", "main_tech_blog", "main_info_share", "main_sample_store"];
    }
    return ["main_doc", "main_tech_blog", "main_info_share", "main_sample_store"];
    //return ["main_tech_blog", "main_info_share", "main_sample_store"];
  };
  const [subtypes, setSubtypes] = useState([]);
  const [maintype, setMaintype] = useState("main_qna" as MainType);
  const [maintypes, setMaintypes] = useState(getMainTypes() as Array<string>);
  const [subtype, setSubtype] = useState("");


  useEffect(() => {
    setMaintypes(getMainTypes());
    if ([SiteLanguageType.EN_US].includes(props.siteLang)) {
      const t = props.docType;
      if (t === DocType.QNA_A || t === DocType.QNA_Q) {
        props.setDocType(DocType.APPLICATION_NOTE);
        //props.setDocType(DocType.TECH_GENERAL);
        return;
      }
    }
  }, [props.siteLang]);


  useEffect(() => {
    //
    const t = props.docType;
    if (t === DocType.QNA_A || t === DocType.QNA_Q) {
      setMaintype("main_qna");
      setSubtype("");
      setSubtypes([]);
    } else if (blogtypes.includes(t)) {
      setMaintype("main_tech_blog");
      setSubtypes(blogtypes);
      setSubtype(t);
    } else if (infotypes.includes(t)) {
      setMaintype("main_info_share");
      setSubtypes(infotypes);
      setSubtype(t);
    } else if (downloadtypes.includes(t)) {
      setMaintype("main_doc");
      setSubtypes(downloadtypes);
      setSubtype(t);
    } else if (sampletypes.includes(t)) {
      setMaintype("main_sample_store");
      setSubtypes(sampletypes);
      setSubtype(t);
    } else if ((roleutil.hasRole('RND', props.loginUtil.info.userRoles)) && rndTypesAdminMage.includes(t)) {
      setMaintype("main_rnd");
      setSubtypes(rndTypesAdminMage);
      setSubtype(t);
    }
  }, [props.docType]);


  //const maintypes = gconf.ssqrelease1st ? ["main_qna", "main_tech_blog", "main_info_share"] : ["main_qna", "main_tech_blog", "main_info_share", "main_sample_store"];
  // let maintypes = ["main_qna", "main_tech_blog", "main_info_share", "main_sample_store"];
  // if (ssqutil.islangen()) {
  //   // 일반회원 영어.
  //   maintypes = ["main_tech_blog", "main_info_share", "main_sample_store"];
  // }
  const blogtypes = [DocType.APPLICATION_NOTE, DocType.TECHNICAL_GUIDE, DocType.TECH_GENERAL];
  const sampletypes = [DocType.SAMPLE, DocType.USER_SAMPLE];
  const infotypes = [DocType.TIP, DocType.DOC_NOTI, DocType.DOC_EVT, DocType.JOB_OFFER, DocType.JOB_SEARCH];
  const rndTypesAdminMage = [DocType.RND_NOTI, DocType.RND_QNA_Q, DocType.RND_TALK];
  const rndTypes = [DocType.RND_QNA_Q, DocType.RND_TALK];

  const handleCmd = (e, cmd, param?) => {
    //console.log("cmd ", cmd, " param ", param, maintype, subtype);
    if (cmd === 'main-change') {
      setMaintype(param);

      if (param === "main_tech_blog") {
        props.setDocType(blogtypes[0]);
      } else if (param === "main_sample_store") {
        props.setDocType(sampletypes[0]);
      } else if (param === "main_info_share") {
        props.setDocType(infotypes[0]);
      } else if (param === "main_doc") {
        props.setDocType(downloadtypes[0]);
      } else if (param === "main_qna") {
        props.setDocType(DocType.QNA_Q);
      } else if ((roleutil.hasRole('RND', props.loginUtil.info.userRoles)) && param === "main_rnd") {
        props.setDocType(rndTypesAdminMage[0]);
      } else {
        setSubtypes([]);
        setSubtype("");
      }

    } else if (cmd === 'sub-change') {
      //setSubtype(param);
      props.setDocType(param);
    } else {
      console.error("unknown cmd ", cmd, " param ", param);
    }
  };
  const trans = docwriteutil.getadmintrans;
  return (
    <Container>
      <select className={"select-sm"} aria-label={'select-technical-doctype'}
        disabled={props.readonly || false}
        onChange={(e) => {
          if (props.readonly) return;
          handleCmd(e, "main-change", e.target.value);
        }}
        value={maintype}>
        {maintypes.map(docType => <option key={docType} value={docType}>{trans(i18all[docType])}</option>)}
      </select>
      {subtypes.length > 0 ? <>
        <div className="w-2"/>
        <select className={"select-sm"} aria-label={'select-technical-doctype'}
          disabled={props.readonly || false}
          onChange={(e) => {
            if (props.readonly) return;
            handleCmd(e, "sub-change", e.target.value);
          }}
          value={props.docType}>
          {subtypes.map(docType => <option key={docType} value={docType}>{getDocTypeDropDownStr(docType)}</option>)}
        </select></> : null}
    </Container>


  );
};

const Container = styled.div`
  display: flex;

  .select-sm {
    width: auto !important;
    //min-width: 12.8rem;
    padding: 0 2.4rem 0 1.2rem !important;
    font-size: 1.2rem;
    font-weight: 500;
  }
`;
