export const getFileSize = (fileSize: number): string => {
  fileSize = fileSize * 1;
  if (fileSize === 0) {
    return '- KB';
  } else {
    const unitOfArray = ['KB', 'MB', 'GB', 'TB', 'PB'];
    if (fileSize < 1) {
      return `${(fileSize).toFixed(1)} KB`;
    }
    const presentUnit = Math.floor(Math.log(fileSize) / Math.log(1000));
    return (fileSize / Math.pow(1024, presentUnit)).toFixed(1) + " " + unitOfArray[presentUnit];
  }
};
