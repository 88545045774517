export class AskEmailReq {
  toEmailId: string = ""; // 수사니자 email id.
  sendFromEmailId: boolean = false; // email id 를 전송할지 여부.
  askUrl?: string = ''; // 문의 사항 URL
  msg: string; // 전송할 메시지.
  lang: "ko" | "en" | string; // 영문, 한글 구분 ( ko, en )
  profilePhotoFileKey?: string = ''; // 발신자 프로필이미지 (문의메일 전용)
  techPoint?: number;
}

