import useSWR, {KeyedMutator} from "swr";
import {IfPageReq, IfPageRes} from "asset/models/IfPage";
import {IfAlarmFilterRes, IfAlarmRes, IfChkAlarmRes} from "asset/models/IfAlarmEntity";
import {useDebounceDeps} from "hooks/useDebounce";

export const useApiAlarmResPage = (req: IfPageReq<{ userId: string, type: string }>)
  : [IfPageRes<IfChkAlarmRes>, KeyedMutator<IfPageRes<IfChkAlarmRes>>] => {
  const debounce = useDebounceDeps(req, 50, null, JSON.stringify(req));
  const {data, mutate} = useSWR<IfPageRes<IfChkAlarmRes>>(debounce?.search?.userId && ['/api/member/asset/alarm/page', debounce]);
  return [data, mutate];
};

export const useApiAlarmFilterList = ({userId, type, projectId, deviceId}: {
  userId: string,
  type: string,
  projectId: number,
  deviceId: string
}): [IfAlarmFilterRes[], KeyedMutator<IfAlarmFilterRes[]>] => {
  const {data, mutate} = useSWR<IfAlarmFilterRes[]>(userId && ['/api/member/asset/alarm/filter/list', {userId, type, projectId, deviceId}]);
  return [data, mutate];
};

export const useApiAlarmListByUserId = ({userId}: { userId?: string }): [IfAlarmRes[], KeyedMutator<IfAlarmRes[]>] => {
  const {data, mutate} = useSWR<IfAlarmRes[]>(userId && ['/api/member/asset/alarm/list', {userId}]);
  return [data, mutate];
};


export const useApiAlarmDetail = (req: { alarmId: number })
  : [IfAlarmRes, KeyedMutator<IfAlarmRes>] => {
  const {data, mutate} = useSWR<IfAlarmRes>(req?.alarmId && ['/api/member/asset/alarm/detail', req]);
  return [data, mutate];
};