import {Http} from "../fetch/Http";
import HttpStatus from "../definitions/HttpStatus";
import {MemberType} from "../definitions/MemberType";
import {InterestFieldType} from "../definitions/InterestFieldType";

export interface IAdminAuthority {

  allowAdminManage: boolean;

  allowAdminKoKr: boolean,
  allowAdminEn: boolean,
  allowAdminEnUs: boolean,
  allowAdminSw: boolean,

  userRoles: string,
}


export const emptyAdminAuthority: IAdminAuthority = {

  allowAdminManage: false,

  allowAdminKoKr: false,
  allowAdminEn: false,
  allowAdminEnUs: false,
  allowAdminSw: false,
  userRoles: '',
};

export interface IIsExistAccountIdRequest {
  accountId: string;
}

export interface IIsVerifyEmailCheckedRequest {
  email: string;
}

export interface IPatchPasswordRequest {
  encodedEmail: string,
  encodedPassword: string
}

export interface IPatchLoginTimeAndCountRequest {
  email: string;
}

export interface ICreateAccountRequest {
  id: string;
  password: string;
  nickname: string;
  name: string;
  familyName: string;
  company: string;
  department: string;
  school: string;
  major: string;
  countryCode: string;
  countryCode2: string;
  phoneNumber: string;
  memberType: string;
  interestFieldType: string;
  acceptTerms: boolean;
  acceptPrivateEssential: boolean;
  acceptPrivateOptional: boolean;
  acceptMail: boolean;
  loginType: string;
}

export const emptyCreateAccountRequest: ICreateAccountRequest = {
  id: "",
  password: "",
  nickname: "",
  name: "",
  familyName: "",
  company: "",
  department: "",
  school: "",
  major: "",
  countryCode: "",
  countryCode2: "",
  phoneNumber: "",
  memberType: MemberType.ETC,
  interestFieldType: InterestFieldType.ETC,
  acceptTerms: false,
  acceptPrivateEssential: false,
  acceptPrivateOptional: false,
  acceptMail: false,
  loginType: "SSQ"
};

export interface IUpdateAccountRequest {
  accountId: string;
  password: string;
  nickname: string;
  name: string;
  familyName: string;
  company: string;
  department: string;
  school: string;
  major: string;
  countryCode: string;
  countryCode2: string;
  phoneNumber: string;
  memberType: string;
  interestFieldType: string;
  acceptTerms: boolean;
  acceptPrivateEssential: boolean;
  acceptPrivateOptional: boolean;
  acceptMail: boolean;
  loginType: string;
}

export const emptyUpdateAccountRequest: IUpdateAccountRequest = {
  accountId: "",
  password: "",
  nickname: "",
  name: "",
  familyName: "",
  company: "",
  department: "",
  school: "",
  major: "",
  countryCode: "",
  countryCode2: "",
  phoneNumber: "",
  memberType: MemberType.ETC,
  interestFieldType: InterestFieldType.ETC,
  acceptTerms: false,
  acceptPrivateEssential: false,
  acceptPrivateOptional: false,
  acceptMail: false,
  loginType: "SSQ"
};

export interface IDeleteAccountRequest {
  userId: String;
}

export const emptyDeleteAccountRequest: IDeleteAccountRequest = {
  userId: ""
};

export interface IUserProfile {
  email: string;
  nickname: string;
  loginType: string;
  techPoint: number;
  allowExpert: boolean;
  myIntro: string;
  profilePhotoFileKey: string;
}

export const emptyUserProfile: IUserProfile = {
  email: "",
  nickname: "",
  loginType: "",
  techPoint: 0,
  allowExpert: false,
  myIntro: "",
  profilePhotoFileKey: ""
};

export interface ISignupValues {
  email: string;
  password: string;
  passwordCheck: string;
  nickname: string;
  name: string;
  familyName: string;
  school: string;
  majorAndGrade: string;
  company: string;
  departmentAndTitle: string;
  countryCode: string;
  countryCode2: string;
  phoneNumber: string;
  memberType: string;
  interestFieldType: string;
  acceptTerms: boolean;
  acceptPrivateEssential: boolean;
  acceptPrivateOptional: boolean;
  acceptMail: boolean;
  loginType: string;
}

export const emptySignupValues: ISignupValues = {
  email: "",
  password: "",
  passwordCheck: "",
  nickname: "",
  name: "",
  familyName: "",
  school: "",
  majorAndGrade: "",
  company: "",
  departmentAndTitle: "",
  countryCode: "",
  countryCode2: "",
  phoneNumber: "",
  memberType: MemberType.SELECT,
  interestFieldType: InterestFieldType.SELECT,
  acceptTerms: false,
  acceptPrivateEssential: false,
  acceptPrivateOptional: false,
  acceptMail: false,
  loginType: "SSQ"
};

export interface IAccountInfo {
  id: string,
  nickname: string,
  name: string,
  familyName: string,
  company: string,
  department: string,
  school: string,
  major: string,
  countryCode: string,
  countryCode2: string,
  phoneNumber: string,
  memberType: string,
  interestFieldType: string,
  loginType: string,
  numberOfLogin: number,
  techPoint: number,
  myIntro: string,
  profilePhotoFileKey: string,
  signupTime: Date,
  recentLoginTime: Date,
  blockedLoginTime: Date,
  acceptTerms: boolean,
  acceptPrivateEssential: boolean,
  acceptPrivateOptional: boolean,
  acceptMail: boolean,
  allowExpert: boolean,

  allowAdminManage: boolean,

  allowAdminKoKr: boolean,
  allowAdminEn: boolean,
  allowAdminEnUs: boolean,
  allowAdminSw: boolean,
  userRoles: string,
  verifyEmailChecked: boolean
}

export const emptyAccountInfo: IAccountInfo = {
  id: "",
  nickname: "",
  name: "",
  familyName: "",
  company: "",
  department: "",
  school: "",
  major: "",
  countryCode: "",
  countryCode2: "",
  phoneNumber: "",
  memberType: "ETC",
  interestFieldType: "ETC",
  loginType: "",
  numberOfLogin: 0,
  techPoint: 0,
  myIntro: "",
  profilePhotoFileKey: "",
  signupTime: new Date(),
  recentLoginTime: new Date(),
  blockedLoginTime: new Date(),
  acceptTerms: true,
  acceptPrivateEssential: true,
  acceptPrivateOptional: false,
  acceptMail: false,
  allowExpert: false,
  allowAdminManage: false,
  allowAdminKoKr: false,
  allowAdminEn: false,
  allowAdminEnUs: false,
  allowAdminSw: false,
  userRoles: '',
  verifyEmailChecked: false
};

export interface IPatchLoginFailNumberAndTrialTimeRequest {
  accountId: string;
}

export const emptyPatchLoginFailNumberAndTrialTimeRequest: IPatchLoginFailNumberAndTrialTimeRequest = {
  accountId: ""
};

export interface ILoginFailNumberRequest {
  accountId: string;
}

export const emptyLoginFailNumberRequest: ILoginFailNumberRequest = {
  accountId: ""
};

export interface ILoginFailNumberInfo {
  loginFailNumber: number;
}

export const emptyLoginFailNumberInfo: ILoginFailNumberInfo = {
  loginFailNumber: 0
};

export interface IResetLoginFailNumberRequest {
  accountId: string;
}

export const emptyResetLoginFailNumberRequest: IResetLoginFailNumberRequest = {
  accountId: ""
};

export interface ICountsTypeOfUser {
  employeeTotalCount: number,
  userTotalCount: number
}

export const emptyCountsTypeOfUser: ICountsTypeOfUser = {
  employeeTotalCount: 0,
  userTotalCount: 0
};

export interface IUpdateUserManagementRequest {
  accountId: String,
  acceptPrivateOptional?: boolean,
  acceptMail: boolean
  allowExpert: boolean,

  allowAdminManage: boolean,
  allowAdminKoKr: boolean,
  allowAdminEn: boolean,
  allowAdminEnUs: boolean,
  allowAdminSw: boolean,
  userRoles: string,
}

export const EmptyUpdateAccountManagementRequest: IUpdateUserManagementRequest = {
  accountId: "",
  acceptPrivateOptional: false,
  acceptMail: false,
  allowExpert: false,
  allowAdminManage: false,
  allowAdminKoKr: false,
  allowAdminEn: false,
  allowAdminEnUs: false,
  allowAdminSw: false,
  userRoles: '',
};

export interface IUserInfoByAccountIdRequest {
  accountId: String;
}

export interface IUserInfoBySearchValueRequest {
  searchValue: String;
}

export interface INewsLetterUserInfoList {
  accountId: String;
}

export interface IMarketingUserInfoList {
  accountId: string;
  nickname: string;
  name: string;
  school: string;
  majorAndGrade: string;
  company: string;
  departmentAndTitle: string;
  countryCode: string;
  phoneNumber: string;
  industryType: string;
  interestFieldType: string;
  recentLoginTime: Date;

  singUpTime: Date;
  familyName: string;
  verifyEmailChecked: boolean;
  userRoles: string;
  loginType: string;
  countryCode2: string;
}

export interface AttachedProfileBlob {
  fileName: string;
  fileKey: string;
}


/*
country code model.
 */
export interface ICountryCode {
  countryName: string;
  countryCode: string;
  countryCode2: string;
  countryNameKo: string;
}


export interface AccountService {
  isAllowedAdminForSettingMenu(): Promise<boolean>;

  isLoggedInAsAdmin(): Promise<boolean>;

  getAdminAuthorities(): Promise<IAdminAuthority>;

  isLoggedIn(): Promise<boolean>;

  isExistAccountId(accountId: string): Promise<boolean>;

  isExistNickname(nickname: string): Promise<boolean>;

  getAllCountryCodes(): Promise<ICountryCode[]>;

  getLoggedInNickname(): Promise<string>;

  getLoggedInAccountId(): Promise<string>;

  getLoggedInAuthority(): Promise<string>;

  createAccount(signupValues: ISignupValues): Promise<any>;

  updateAccount(updateInputValues: IUpdateAccountRequest): Promise<any>;

  deleteAccount(deleteAccountRequest: IDeleteAccountRequest): Promise<number>;

  isVerifyEmailChecked(email: string): Promise<boolean>;

  checkVerifyEmail(email: string): Promise<boolean>;

  getAccountInfo(): Promise<IAccountInfo>;

  getAccountNameByAccountId(accountId: string): Promise<string>;

  getLoginTypeByAccountId(accountId: string): Promise<string>;

  patchPassword(patchPasswordRequest: IPatchPasswordRequest): Promise<boolean>;

  patchLoginTimeAndCount(patchLoginTimeAndCountRequest: IPatchLoginTimeAndCountRequest): Promise<boolean>;

  patchLoginFailNumberAndTrialTime(patchLoginFailNumberAndTrialTimeRequest: IPatchLoginFailNumberAndTrialTimeRequest): Promise<boolean>;

  getLoginFailNumber(loginFailNumberRequest: ILoginFailNumberRequest): Promise<ILoginFailNumberInfo>;

  resetLoginFailNumber(resetLoginFailNumberRequest: IResetLoginFailNumberRequest): Promise<number>;

  getCountsTypeOfUser(): Promise<ICountsTypeOfUser>;

  getEmployeeInfoList(): Promise<IAccountInfo[]>;

  updateUserManagement(updateUserManageRequest: IUpdateUserManagementRequest): Promise<number>;

  getUserInfoList(pageNum: number): Promise<IAccountInfo[]>;

  getUserInfoByAccountId(userInfoByAccountIdRequest: IUserInfoByAccountIdRequest): Promise<IAccountInfo>;

  getUserInfoBySearchValue(userInfoBySearchValue: IUserInfoBySearchValueRequest): Promise<IAccountInfo[]>;

  getNewsLetterUserInfoList(): Promise<INewsLetterUserInfoList[]>;

  getMarketingUserInfoList(): Promise<IMarketingUserInfoList[]>;

  getEmailByNickName(nickName: string): Promise<string>;

  getNameByNickName(nickName: string): Promise<string>;

  postAttachedProfile(key: string, files: any[]): Promise<AttachedProfileBlob[]>;

  deleteAttachedProfile(key: string): Promise<number>;
}

export class NetworkAccountService implements AccountService {
  http: Http;

  constructor(http: Http) {
    this.http = http;
  }

  async isAllowedAdminForSettingMenu(): Promise<boolean> {
    const response = await this.http.get(`/api/boris/authorities?type=ANY`);
    if (response.status !== HttpStatus.OK) {
      return Promise.resolve(false);
    }
    return response.json();
  }

  async isLoggedInAsAdmin(): Promise<boolean> {
    const response = await this.http.get(`/api/guest/isLoggedInAsAdmin`);
    return response.json();
  }

  async getAdminAuthorities(): Promise<IAdminAuthority> {
    const response = await this.http.get(`/api/boris/getAuthorities`);
    if (response.status !== HttpStatus.OK) {
      return Promise.resolve(emptyAdminAuthority);
    }
    return response.json();
  }

  async isLoggedIn(): Promise<boolean> {
    const response = await this.http.get(`/api/guest/isLoggedIn`);
    if (response.status !== HttpStatus.OK) {
      return Promise.resolve(false);
    }
    return response.json();
  }

  async isExistAccountId(accountId: string): Promise<boolean> {
    const isExistAccountIdRequest = {accountId: accountId};
    const response = await this.http.post(`/api/guest/isExistAccountId`, isExistAccountIdRequest);
    return response.json();
  }

  async isExistNickname(nickname: string): Promise<boolean> {
    const response = await this.http.get(`/api/guest/isExistNickname?nickname=${nickname}`);
    return response.json();
  }

  async getAllCountryCodes(): Promise<ICountryCode[]> {
    const response = await this.http.get("/api/guest/countryCode");

    if (response.status !== HttpStatus.OK) {
      return Promise.resolve([]);
    }
    return response.json();
  }

  async getLoggedInNickname(): Promise<string> {
    const response = await this.http.get(`/api/guest/loggedInNickname`);
    return response.text && await response.text() || "";
  }

  async getLoggedInAccountId(): Promise<string> {
    const response = await this.http.get(`/api/guest/loggedInAccountId`);
    return response.text && await response.text() || "";
  }

  async getLoggedInAuthority(): Promise<string> {
    const response = await this.http.get(`/api/guest/loggedInAuthority`);
    // const response = await this.http.get(`/api/boris/loggedInAuthority`)
    //
    // if (response.status !== HttpStatus.OK) {
    //     return Promise.resolve(RoleType.ROLE_ANONYMOUS)
    // }

    return response.text && await response.text() || "";
  }

  async createAccount(signupValues: ISignupValues): Promise<any> {
    const createAccountRequest: ICreateAccountRequest = {
      id: signupValues.email,
      password: signupValues.password,
      nickname: signupValues.nickname,
      name: signupValues.name,
      familyName: signupValues.familyName,
      company: signupValues.company,
      department: signupValues.departmentAndTitle,
      school: signupValues.school,
      major: signupValues.majorAndGrade,
      countryCode: signupValues.countryCode,
      countryCode2: signupValues.countryCode2,
      phoneNumber: signupValues.phoneNumber,
      memberType: signupValues.memberType,
      interestFieldType: signupValues.interestFieldType,
      acceptTerms: signupValues.acceptTerms,
      acceptPrivateEssential: signupValues.acceptPrivateEssential,
      acceptPrivateOptional: signupValues.acceptPrivateOptional,
      acceptMail: signupValues.acceptMail,
      loginType: signupValues.loginType
    };

    const response = await this.http.post(`/api/guest/createAccount`, createAccountRequest);
    return response.status;
  }

  async updateAccount(updateInputValues: IUpdateAccountRequest): Promise<any> {
    const updateAccountRequest: IUpdateAccountRequest = {
      accountId: updateInputValues.accountId,
      password: updateInputValues.password,
      nickname: updateInputValues.nickname,
      name: updateInputValues.name,
      familyName: updateInputValues.familyName,
      company: updateInputValues.company,
      department: updateInputValues.department,
      school: updateInputValues.school,
      major: updateInputValues.major,
      countryCode: updateInputValues.countryCode,
      countryCode2: updateInputValues.countryCode2,
      phoneNumber: updateInputValues.phoneNumber,
      memberType: updateInputValues.memberType,
      interestFieldType: updateInputValues.interestFieldType,
      acceptTerms: updateInputValues.acceptTerms,
      acceptPrivateEssential: updateInputValues.acceptPrivateEssential,
      acceptPrivateOptional: updateInputValues.acceptPrivateOptional,
      acceptMail: updateInputValues.acceptMail,
      loginType: updateInputValues.loginType
    };

    const response = await this.http.put(`/api/guest/updateAccount`, updateAccountRequest);
    return response.status;
  }

  async deleteAccount(deleteAccountRequest: IDeleteAccountRequest): Promise<number> {
    const response = await this.http.post(`/api/guest/deleteAccount`, deleteAccountRequest);
    return response.status;
  }

  async isVerifyEmailChecked(email: string): Promise<boolean> {
    const isVerifyEmailCheckedRequest: IIsVerifyEmailCheckedRequest = {email: email};
    const response = await this.http.post(`/api/guest/isVerifyEmailChecked`, isVerifyEmailCheckedRequest);
    return response.json();
  }

  async checkVerifyEmail(email: string): Promise<boolean> {
    const response = await this.http.patch(
      `/api/guest/checkVerifyEmail?email=${email}`
    );
    if (response.status !== HttpStatus.OK) {
      return false;
    }
    return true;
  }

  async getAccountInfo(): Promise<IAccountInfo> {
    const response = await this.http.get(`/api/guest/getAccountInfo`);
    return response.json();
  }

  async getAccountNameByAccountId(accountId: string): Promise<string> {
    const getAccountNameByAccountIdRequest = {accountId: accountId};
    const response = await this.http.post(`/api/boris/getAccountName`, getAccountNameByAccountIdRequest);
    if (response.status !== HttpStatus.OK) {
      return "";
    }
    return response.text && await response.text() || "";
  }

  async getLoginTypeByAccountId(accountId: string): Promise<string> {
    const response = await this.http.get(`/api/guest/getLoginType/${accountId}`);
    return response.text && await response.text() || "";
  }

  async patchPassword(patchPasswordRequest: IPatchPasswordRequest): Promise<boolean> {
    const response = await this.http.patch(`/api/guest/patchPassword/`, patchPasswordRequest);
    if (response.status !== HttpStatus.OK) {
      return false;
    }
    return true;
  }

  async patchLoginTimeAndCount(patchLoginTimeAndCountRequest: IPatchLoginTimeAndCountRequest): Promise<boolean> {
    const response = await this.http.patch(`/api/guest/patchLoginTimeAndCount/`, patchLoginTimeAndCountRequest);
    if (response.status !== HttpStatus.OK) {
      return false;
    }
    return true;
  }

  async patchLoginFailNumberAndTrialTime(patchLoginFailNumberAndTrialTimeRequest: IPatchLoginFailNumberAndTrialTimeRequest): Promise<boolean> {
    const response = await this.http.patch(`/api/guest/patchLoginFailNumberAndTrialTime/`, patchLoginFailNumberAndTrialTimeRequest);
    if (response.status !== HttpStatus.OK) {
      return false;
    }
    return true;
  }

  async getLoginFailNumber(loginFailNumberRequest: ILoginFailNumberRequest): Promise<ILoginFailNumberInfo> {
    const response = await this.http.post(`/api/guest/getLoginFailNumber/`, loginFailNumberRequest);
    return response.json();
  }

  async resetLoginFailNumber(resetLoginFailNumberRequest: IResetLoginFailNumberRequest): Promise<number> {
    const response = await this.http.post(`/api/guest/postResetLoginFailNumber`, resetLoginFailNumberRequest);
    return response.status;
  }

  async getCountsTypeOfUser(): Promise<ICountsTypeOfUser> {
    const response = await this.http.get(`/api/boris/getCountsTypeOfUser`);
    return response.json();
  }

  async getEmployeeInfoList(): Promise<IAccountInfo[]> {
    const response = await this.http.get(`/api/boris/getEmployeeInfoList`);
    return response.json();
  }

  async updateUserManagement(updateUserManagementRequest: IUpdateUserManagementRequest): Promise<number> {
    const response = await this.http.put(`/api/boris/updateUserManagement`, updateUserManagementRequest);
    return response.status;
  }

  async getUserInfoList(pageNum: number): Promise<IAccountInfo[]> {
    const response = await this.http.get(`/api/boris/getUserInfoList?pageNum=${pageNum}`);
    return response.json();
  }

  async getUserInfoByAccountId(userInfoByAccountIdRequest: IUserInfoByAccountIdRequest): Promise<IAccountInfo> {
    const response = await this.http.post(`/api/boris/getUserInfoByAccountId`, userInfoByAccountIdRequest);
    return response.json();
  }

  async getUserInfoBySearchValue(userInfoBySearchValueRequest: IUserInfoBySearchValueRequest): Promise<IAccountInfo[]> {
    const response = await this.http.post(`/api/boris/getUserInfoBySearchValue`, userInfoBySearchValueRequest);
    return response.json();
  }

  async getNewsLetterUserInfoList(): Promise<INewsLetterUserInfoList[]> {
    const response = await this.http.get(`/api/boris/getNewsLetterUserInfoList`);
    return response.json();
  }

  async getMarketingUserInfoList(): Promise<IMarketingUserInfoList[]> {
    const response = await this.http.get(`/api/boris/getMarketingUserInfoList`);
    return response.json();
  }

  async getEmailByNickName(nickName: string): Promise<string> {
    const response = await this.http.get(`/api/boris/getEmailByNickName?nickName=${nickName}`);
    return response.text && await response.text() || "";
  }

  async getNameByNickName(nickName: string): Promise<string> {
    const response = await this.http.get(`/api/boris/getNameByNickName?nickName=${nickName}`);
    return response.text && await response.text() || "";
  }

  async postAttachedProfile(key: string, files: any[]): Promise<AttachedProfileBlob[]> {
    const formData = new FormData();
    files.forEach(file => formData.append("uploadFiles", file));

    return (await this.http.postFormData("/api/member/localupload/" + key, formData)).json();
  }

  async deleteAttachedProfile(key: string): Promise<number> {
    return (await this.http.delete("/api/member/uploads/avatar/" + key)).status;
  }
}