// @flow
import * as React from 'react';
import {HelmetTitle} from "./HelmetTitle";
import {HiddenH1} from "../../style/CommonStyledComponents";
import svcs from "services";
import {LANG_EN_US} from "language/langutil";

type Props = {
  title: string;
};
export const HelmetTitleAndH1 = (props: Props) => {
  return (
    <>
      <HelmetTitle title={props.title}/>
      <HiddenH1>{props.title}</HiddenH1>
    </>
  );
};

export const getLangSuffix = () => {
  const lang = svcs.svcLang.lang();
  if (lang === LANG_EN_US) return "(us)";
  return "";
};