import React, {useEffect, useState} from 'react';
import {DocLanguageType, getDocLanguageTypeLangKey} from "definitions/DocLanguageType";
import {getFileSize} from "util/fileSize";
import {IFile, IHistory, IVersion, NO_VERSION_TAG} from "repositories/SsqDataRepository";
import styled from "styled-components";
import {formatDateTime} from "util/formatDateTime";
import {admin_popup, InputWrapForSelect} from "style/CommonStyledComponents";
import {SsqImages} from "images/SsqImages";
import {AdmissionType} from "definitions/AdmissionType";

import {getEncodedStringRemovedUnValidUrl, parseSanitize} from "util/commonUtils";
import {FileType} from "definitions/FileType";
import {TargetSoftwareType} from "definitions/TargetSoftwareType";
import {LANG_KO} from "language/langutil";
import {ssqutil} from "util/ssqutil";
import SsqEditor5 from "libraries/SsqEditor5";
import svcs from 'services';
import {useIsMobile} from "hooks/useIsMobile";
import deleteIconRed from 'images/common/delete-icon-red.svg';
import deleteIconGray from 'images/common/delete-icon-gray.svg';
import {i18ntrans} from "language/i18nkey";

// interface DocTypeDropdownProps {
//   docType: string;
//   setDocType: Function;
//   getTranslation: Function;
// }

// export const DocTypeDropdown = (props: DocTypeDropdownProps) => {
//   function renderDocTypeOption(docType: string) {
//     return <option value={docType}>{props.getTranslation(getDocTypeLangKey(docType))}</option>;
//   }
//
//   useEffect(() => {
//   }, [props.docType]);
//
//   return (
//     <select aria-label={'select-doctype'}
//       onChange={(e) => {
//         props.setDocType(e.target.value);
//       }}
//       value={props.docType}
//     >
//       {renderDocTypeOption(DocType.MANUAL)}
//       {renderDocTypeOption(DocType.CATALOG)}
//       {renderDocTypeOption(DocType.CERTIFICATION)}
//       {renderDocTypeOption(DocType.CAD_DATA)}
//       {renderDocTypeOption(DocType.DATASHEET)}
//       {renderDocTypeOption(DocType.SOFTWARE)}
//       {renderDocTypeOption(DocType.OS)}
//       {renderDocTypeOption(DocType.EDUCATION_VIDEO)}
//       {renderDocTypeOption(DocType.EDUCATION_NOTE)}
//     </select>
//   );
// };

// interface TechnicalDocTypeDropdownProps {
//   docType: string;
//   setDocType: Function;
//   getTranslation: Function;
//   isDisabled?: boolean;
// }
//
// export const TechnicalDocTypeDropdown = (props: TechnicalDocTypeDropdownProps) => {
//   function renderDocTypeOption(docType: string) {
//     return <option value={docType}>{props.getTranslation(getDocTypeLangKey(docType))}</option>;
//   }
//
//   useEffect(() => {
//   }, [props.docType]);
//
//   return (
//     <select aria-label={'select-technical-doctype'}
//       disabled={props.isDisabled || false}
//       onChange={(e) => {
//         props.setDocType(e.target.value);
//       }}
//       value={props.docType}
//     >
//       {renderDocTypeOption(DocType.APPLICATION_NOTE)}
//       {renderDocTypeOption(DocType.TECHNICAL_GUIDE)}
//       {renderDocTypeOption(DocType.SAMPLE)}
//     </select>
//   );
// };


// interface DocTypesDropdownProps {
//   docType: string;
//   setDocType: Function;
//   trans: Function;
//   isDisabled?: boolean;
//   docTypes: Array<string>;
// }
//
// export const DocTypesDropdown = (props: DocTypesDropdownProps) => {
//   return (
//     <select aria-label={'select-technical-doctype'}
//       disabled={props.isDisabled || false}
//       onChange={(e) => {
//         props.setDocType(e.target.value);
//       }}
//       value={props.docType}>
//       {props.docTypes.map(docType => <option key={docType} value={docType}>{props.trans(getDocTypeLangKey(docType))}</option>)}
//     </select>
//   );
// };

interface RadioButtonsProp {
  id: string;
  selectedValue: any;
  valueList: any;
  getDisplayValue: Function;
  onClickItem: Function;
}

export const RadioButtons = (props: RadioButtonsProp) => {
  useEffect(() => {
  }, [props.selectedValue]);

  return props.valueList.map((item: any, index: number) => {
    const generatedId = `${props.id}-${item}`;
    return <span key={index}>
            <input
              type="radio"
              id={generatedId}
              aria-label={generatedId}
              checked={item === props.selectedValue}
              onChange={(e) => {
                props.onClickItem(item);
              }}
            />
            <label htmlFor={generatedId}>{props.getDisplayValue(item)}</label>
        </span>;
  });
};

interface IVersionItemProps {
  version: IVersion;
  displayIndex?: number;
  selectedSiteLang?: string;
  isReadOnly: boolean;
  getTranslation: Function;
  onClickAddVersion?: Function;
  onUploadFiles?: Function;
  onDeleteFile?: Function;
  onDeleteVersion?: Function;
  setEditing: () => void;
  onVersionCheck: Function;
  onVersionDesCheck: Function;
  onVersionFileCheck: Function;
  onVersionFileDelCheck: Function;
}

export const VersionItem = (props: IVersionItemProps) => {
  let [showPopupMenu, setShowPopupMenu] = useState<boolean>(false);
  let [editingMode, setEditingMode] = useState<boolean>(false);

  useEffect(() => {

  }, [props.version, props.selectedSiteLang]);

  const renderVersion = () => {
    return <div>
      {props.version.version !== NO_VERSION_TAG && <div className={"Version_item"}>
        <div className={"Version_tit"}>{props.version.version}</div>
        <div className={"Version_desc"}>{parseSanitize(props.version.versionDescription)}</div>
      </div>}
      {props.version.files.map((file, fileIndex) => {
        let fileDownloadUrl: string = `/uploads/${file.fileKey}/${getEncodedStringRemovedUnValidUrl(file.fileName)}`;
        if (file.blobUrlForLargeFile !== null && file.blobUrlForLargeFile !== undefined && file.blobUrlForLargeFile !== "") {
          fileDownloadUrl = ssqutil.getBlobUrlForLargeFile(file.blobUrlForLargeFile);
        }
        return <div key={fileIndex} className={"file_info"}>
          <p onClick={() => window.open(fileDownloadUrl)}
            className={"file_name"}>{file.fileName}</p>
          <button
            onClick={() => window.open(fileDownloadUrl)}>
            <img
              src={SsqImages.common.icon.downloadImg()}/>
          </button>
          <div>
                    <span
                      className={"file_lang"}>{props.getTranslation(getDocLanguageTypeLangKey(file.fileLang))}</span>
            <span className={"file_size"}>{getFileSize(file.fileSize)}</span>
            <span
              className={"file_count"}>{props.getTranslation("I18N_DOWNLOAD_COUNT")}<b>{file.downloadCount}</b></span>
          </div>
        </div>;
      })}
    </div>;
  };

  return <VersionItemContainer className={"Version_wrap"}>
    {editingMode && <VersionEditItem
      isVersionFileDel={false}
      onVersionFileDelCheck={props.onVersionFileDelCheck}
      isVersionEmpty={false}
      onVersionDesCheck={props.onVersionDesCheck}
      onVersionFileCheck={props.onVersionFileCheck}
      isVersionDesEmpty={false}
      isVersionFileEmpty={false}
      onVersionCheck={props.onVersionCheck}
      setEditing={props.setEditing}
      originalVersion={props.version}
      versionIndex={props.displayIndex || 0}
      selectedSiteLang={props.selectedSiteLang || LANG_KO}
      getTranslation={props.getTranslation}
      onClickAddVersion={(index: number, editedVersion: IVersion) => {
        if (props.onClickAddVersion && props.onClickAddVersion(index, editedVersion)) {
          setEditingMode(false);
        }
      }}
      onUploadFiles={props.onUploadFiles || function () {
      }}
      onDeleteFile={props.onDeleteFile || function () {
      }}
    />}
    {!editingMode && renderVersion()}
    {!props.isReadOnly && <>
      <button className={"Version_edit"} onClick={() => setShowPopupMenu(!showPopupMenu)}><img
        alt={"version_edit.svg"} src={SsqImages.boris.edit()}/></button>
      {showPopupMenu && <div className={"Version_edit_pop"}>
        <VersionPopupMenu>
          <button onClick={() => {
            setEditingMode(true);
            setShowPopupMenu(false);
          }}>{props.getTranslation('I18N_MODIFIED')}
          </button>
          <button onClick={() => {
            props.onDeleteVersion && props.onDeleteVersion(props.version.version);
          }}>{props.getTranslation('I18N_BUTTON_DELETE')}
          </button>
        </VersionPopupMenu>
      </div>}
    </>}
  </VersionItemContainer>;
};

const VersionItemContainer = styled.div`
  border: 1px solid #767676;
`;

const VersionPopupMenu = styled.div`
  position: absolute;
  top: 4.2rem;
  right: 3.5rem;
  z-index: 1002;
  background: #FFFFFF;
  border-radius: 4px;
  width: 8rem;
  text-align: center;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);

  > button {
    display: block;
    width: 100%;
    height: 3.2rem;

    :first-child {
      border-radius: 4px 4px 0 0;
    }

    :last-child {
      border-radius: 0 0 4px 4px;
    }

    :hover {
      background: rgba(9, 120, 255, 0.15);
    }

    :active {
      background: rgba(9, 120, 255, 0.3);
    }
  }
`;

interface IVersionEditItemProps {
  originalVersion: IVersion,
  versionIndex: number | null,
  onClickAddVersion: Function,
  onUploadFiles: Function,
  onDeleteFile: Function,
  selectedSiteLang: string,
  getTranslation: Function,

  setEditing: () => void;
  isVersionEmpty: boolean
  onVersionCheck: Function

  isVersionDesEmpty: boolean;
  onVersionDesCheck: Function;

  onVersionFileCheck: Function;
  isVersionFileEmpty: boolean;

  isVersionFileDel: boolean;
  onVersionFileDelCheck: Function;
}

export const VersionEditItem = (props: IVersionEditItemProps) => {
  let [isVersionInvalid, setIsVersionInvalid] = useState<boolean>(false);
  let [isVersionAlreadyExist, setIsVersionAlreadyExist] = useState<boolean>(false);
  let [isVersionEmpty, setIsVersionEmpty] = useState<boolean>(false);
  let [editingVersion, setEditingVersion] = useState<IVersion>(props.originalVersion);
  const {setEditing} = props;
  const [isInputNotEmpty, setIsInputNotEmpty] = useState(false);
  const [isInputDesNotEmpty, setIsInputDesNotEmpty] = useState(false);
  const isMobile = useIsMobile();
  const [isInputFileNotEmpty, setIsInputFileNotEmpty] = useState(false);
  const [isInputFileDelEmpty, setIsInputFileDelEmpty] = useState(false);

  useEffect(() => {

    setEditingVersion(props.originalVersion);
  }, [props.originalVersion, props.selectedSiteLang]);

  useEffect(() => {
    props.onVersionCheck(isInputNotEmpty);
    props.onVersionDesCheck(isInputDesNotEmpty);
    props.onVersionFileCheck(isInputFileNotEmpty);
    props.onVersionFileDelCheck(isInputFileDelEmpty);
  }, [editingVersion, props.originalVersion, props.selectedSiteLang]);

  return <div>
    <div className={"editor_wrap"}>
      <div
        className={"editor_tit " + ((isVersionInvalid || isVersionAlreadyExist || isVersionEmpty) ? "input-error" : "")}>
        <input
          type={"text"}
          value={editingVersion.version}
          placeholder={props.getTranslation("I18N_VERSION") + " (1.1.0)"}
          maxLength={15}
          onChange={(e) => {
            const regex = /[A-Za-z0-9.\-]$/;
            setIsInputNotEmpty(e.target.value !== '');
            setIsVersionInvalid(!(regex.test(e.target.value) || e.target.value === ""));
            setIsVersionAlreadyExist(false);
            setIsVersionEmpty(false);
            setEditingVersion({...editingVersion, version: e.target.value});
            if (setEditing) setEditing();
          }}
        />
        {isVersionInvalid && <span className={"error-message"}>
            {props.getTranslation("I18N_VERSION_LETTER_ERROR_MESSAGE")}
            </span>}
        {isVersionAlreadyExist && <span className={"error-message"}>
                {props.getTranslation("I18N_VERSION_ALERT")}
            </span>}
        {isVersionEmpty && <span className={"error-message"}>
                {props.getTranslation("I18N_VERSION_EMPTY")}
            </span>}
      </div>
      <div className={"SsqEditor"}>
        <SsqEditor5 contents={editingVersion.versionDescription}
          onChangeData={(data: any) => {
            setEditingVersion({
              ...editingVersion,
              versionDescription: data
            });
            setIsInputDesNotEmpty(data !== '');
            if (setEditing) setEditing();
          }}
          lang={svcs.svcLang.lang()}
          width={isMobile ? '100%' : '840px'}
          height={'227px'}
          border={true}
        />
      </div>
    </div>
    <div>
      <VersionFileAttachArea
        files={editingVersion.files}
        versionIndex={props.versionIndex}
        onDeleteFile={async (file: IFile) => {
          setIsInputFileDelEmpty(file.fileKey !== '');
          await props.onDeleteFile(file);
          setEditingVersion({
            ...editingVersion,
            files: editingVersion.files.filter(item => item.fileKey !== file.fileKey)
          });
          setEditing();
        }}
        onUploadFile={async (selectedFiles: any[]) => {
          const uploadedFiles: IFile[] = await props.onUploadFiles(selectedFiles);
          setIsInputFileNotEmpty(selectedFiles !== []);
          setEditingVersion({
            ...editingVersion,
            files: [...editingVersion.files, ...uploadedFiles]
          });
          setEditing();
        }}
        setFileLang={(lang: string, index: number) => {
          let tempFiles = editingVersion.files;
          tempFiles[index].fileLang = lang;
          setEditingVersion({
            ...editingVersion,
            files: tempFiles
          });
          setEditing();
        }}
        setFileType={(fileType: string, index: number) => {
          let tempFiles = editingVersion.files;
          tempFiles[index].fileType = fileType;
          setEditingVersion({
            ...editingVersion,
            files: tempFiles
          });
          setEditing();
        }}
        setTargetSoftwareType={(targetSoftwareType: string, index: number) => {
          let tempFiles = editingVersion.files;
          tempFiles[index].targetSoftwareType = targetSoftwareType;
          setEditingVersion({
            ...editingVersion,
            files: tempFiles
          });
          setEditing();
        }}
        getTranslation={props.getTranslation.bind(this)}
      />
    </div>
    <button className={"post-button"} onClick={() => {
      setEditing();
      if (!editingVersion.version) {
        alert(props.getTranslation("I18N_VERSION_EMPTY"));
        setIsVersionEmpty(true);
        return;
      }
      setIsInputNotEmpty(false);
      setIsInputDesNotEmpty(false);
      setIsInputFileNotEmpty(false);
      setIsInputFileDelEmpty(false);
      setEditingVersion({...editingVersion, version: "", versionDescription: ''});
      props.onClickAddVersion(props.versionIndex, editingVersion);
    }}>{props.getTranslation("I18N_ADD_VERSION")}</button>
  </div>;
};

const FileItem = styled.div`
  .file-name {
    width: 32.8rem;
    cursor: pointer;
    border: 1px solid #ccc;
  }
`;

const AttachFileButton = styled.button`
  input[type="file"] {
    display: none;
  }

  label {
    cursor: pointer;
    width: 7.6rem;
    height: 3.2rem;

    size: 1.2rem;
    color: #407EDB;
    border-radius: 0.4rem;
  }
`;

interface IVersionFileAttachAreaProps {
  files: IFile[];
  versionIndex: number | null;
  onDeleteFile: Function;
  onUploadFile: Function;
  getTranslation: Function;
  setFileLang: Function;
  setFileType: Function;
  setTargetSoftwareType: Function;
  readonly?: boolean;
}

export const VersionFileAttachArea = (props: IVersionFileAttachAreaProps) => {
  useEffect(() => {

  }, [props.files]);

  let fileTypeList = [FileType.NONE, FileType.SAMPLE, FileType.LIBRARY, FileType.TEMPLATE];
  let targetSoftwareTypeList = [TargetSoftwareType.NONE, TargetSoftwareType.XG5000, TargetSoftwareType.XP_BUILDER, TargetSoftwareType.DRIVEVIEW];

  const renderRadioButton = (docTypeLanguage: string, index: number, fileLang: string) => {
    return <span>
            <input
              type="radio"
              id={`radio-${docTypeLanguage}-${index}`}
              aria-label={`radio-${docTypeLanguage}-${index}`}
              checked={fileLang === docTypeLanguage}
              onChange={() => {
                props.setFileLang(docTypeLanguage, index);
              }}
            />
            <label htmlFor={`radio-${docTypeLanguage}-${index}`}>{props.getTranslation(getDocLanguageTypeLangKey(docTypeLanguage))}</label>
        </span>;
  };

  const renderSelectButtonForFileType = (selectList: string[], index: number) => {
    return <select value={props.files[index].fileType}
      onChange={(e) =>
        props.setFileType(e.target.value, index)}>

      {selectList.map((selectValue, index) => {
        return <option key={index} value={selectValue}>{selectValue}</option>;
      })}
    </select>;
  };

  const renderSelectButtonForTargetSoftwareType = (selectList: string[], index: number) => {
    return <select value={props.files[index].targetSoftwareType}
      onChange={(e) => props.setTargetSoftwareType(e.target.value, index)}>
      {selectList.map((selectValue, index) => {
        return <option key={index} value={selectValue}>{selectValue}</option>;
      })}
    </select>;
  };

  return <>
    <div>
      {props.files.map((file, index) => {
        let fileDownloadUrl: string = `/uploads/${file.fileKey}/${getEncodedStringRemovedUnValidUrl(file.fileName)}`;
        if (file.blobUrlForLargeFile !== null && file.blobUrlForLargeFile !== undefined && file.blobUrlForLargeFile !== "") {
          fileDownloadUrl = ssqutil.getBlobUrlForLargeFile(file.blobUrlForLargeFile);
        }
        return <FileItem key={"file-" + index} className={"file-item radio_wrap"}>
          <button className={"remove_btn"} onClick={async () => {
            await props.onDeleteFile(file);
          }}>
            <img alt={"remove_icon.svg"} src={SsqImages.boris.removerow()}/>
          </button>
          <span className={"file-name"} onClick={() => window.open(fileDownloadUrl)}>
            {file.fileName}
          </span>
          <span className={"file-size"}>{getFileSize(file.fileSize)}</span>
          {/*<div className={"file-radio"}>*/}
          <BoxRadioButton>
            {renderRadioButton(DocLanguageType.KO, index, file.fileLang)}
            {renderRadioButton(DocLanguageType.EN, index, file.fileLang)}
            {renderRadioButton(DocLanguageType.ETC, index, file.fileLang)}
          </BoxRadioButton>
          {/*</div>*/}
          {window.location.href.includes("technical") &&
            <InputWrapForSelect className={"file_select"}>
              {renderSelectButtonForFileType(fileTypeList, index)}
              {renderSelectButtonForTargetSoftwareType(targetSoftwareTypeList, index)}
            </InputWrapForSelect>}
        </FileItem>;
      })}
    </div>
    <div className={"file_add_wrap"}>
      <AttachFileButton className={"file_add_btn"} disabled={props.readonly}>
        <input id={`add-file-version-${props.versionIndex}`} type={"file"} multiple
          disabled={props.readonly}
          onChange={async (e) => {
            if ((e.target.files?.length || 0) === 0) {
              console.log("취소되었습니다.");
              return;
            }
            let totalFileLength = (e.target.files?.length || 0) + props.files.length;
            if (totalFileLength > 10) {
              alert(props.getTranslation("I18N_ATTACH_ALERT"));
              return;
            }
            await props.onUploadFile(e.target.files);
          }}/>
        <label
          htmlFor={`add-file-version-${props.versionIndex}`}><img alt={"add_icon.svg"}
          src={SsqImages.boris.addrow()}/>{props.getTranslation("I18N_ADD_ATTACHED_FILE")}
        </label>
      </AttachFileButton>
    </div>
  </>;
};

export const CommentPopup = (props: { historyItem: IHistory, closePopup: Function, documentId?: number }) => {
  const {historyItem} = props;
  console.log("history", props.historyItem);
  const t = i18ntrans.adminstrict;
  return <div className={"admin_popup comment_popup"}>
    <CommentPopupContainer>
      <div className={"popup-top"}>
        <h2>{historyItem.type === 'DEL_REQUESTED' ? t("I18N_DEL_APPR_REQ_TIT") : t("I18N_COMMENT_TIT")}</h2>
        <button onClick={() => props.closePopup()}><img alt={"close_small_icon.svg"}
          src={SsqImages.common.icon.closeSmallIcon()}/></button>
      </div>
      <div className={"popup-content"}>
        <span className={"name"}>{props.historyItem.nickname}</span>
        <span className={"time"}>{formatDateTime(new Date(props.historyItem.time))}</span>
        <div className={"comm"}>{props.historyItem.comment}</div>
      </div>
      <div className={"popup-bt"}>
        {props.documentId && <button onClick={() => {
          //window.open(`/boris/approval/document/${props.documentId}?showCommentPopup=false`);
          window.open(`/community/approval/${props.documentId}?showCommentPopup=false`);
        }} className={"batch-button"}>{t("I18N_COMMENT_VIEW_DOC")}</button>}
        <button onClick={() => props.closePopup()}
          className={"post-button"}>{t("I18N_BUTTON_CHECK")}</button>
      </div>
    </CommentPopupContainer>
    <Overlay/>
  </div>;
};

const CommentPopupContainer = styled.div`
  ${admin_popup};

  .popup-content .comm {
    height: 18rem;
  }

  @media screen and (max-width: 360px) {
    .popup-content {
      overflow-y: auto;
    }
  }
`;
export const ApprovalState = (props: { recentHistory: IHistory, getTranslation: Function }) => {
  useEffect(() => {

  }, [props.recentHistory]);

  const renderRecentHistory = (recentHistory: IHistory) => {
    switch (recentHistory.type) {
      case AdmissionType.NOT_APPROVED:
        return <>
          <span><img alt={"adm_not.svg"} src={SsqImages.common.icon.admissionnot()}/></span>
          <span>{props.getTranslation("I18N_FILTER_NOT_APPROVED")}</span>
        </>;
      case AdmissionType.APPROVED:
        return <>
          <span><img alt={"adm_approved.svg"} src={SsqImages.common.icon.admissionapproved()}/></span>
          <span>{props.getTranslation("I18N_FILTER_APPROVED")}</span>
          <span className={"name"}>{recentHistory.nickname}</span>
          <span className={"time"}>{formatDateTime(new Date(recentHistory.time))}</span>
        </>;
      case AdmissionType.REJECTED:
        return <>
          <span><img alt={"adm_reject.svg"} src={SsqImages.common.icon.admissionreject()}/></span>
          <span>{props.getTranslation("I18N_FILTER_REJECT")}</span>
          <span className={"name"}>{recentHistory.nickname}</span>
          <span className={"time"}>{formatDateTime(new Date(recentHistory.time))}</span>
        </>;
      case AdmissionType.DELETED:
        return <>
          <span><img alt={"delete-icon-red.svg"} src={deleteIconGray}/></span>
          <span>{props.getTranslation("I18N_FILTER_DELETED")}</span>
          <span className={"name"}>{recentHistory.nickname}</span>
          <span className={"time"}>{formatDateTime(new Date(recentHistory.time))}</span>
        </>;
      case AdmissionType.DEL_REQUESTED:
        return <>
          <span><img alt={"delete-icon-red.svg"} src={deleteIconRed}/></span>
          <span>{props.getTranslation("I18N_FILTER_DEL_REQUESTED")}</span>
          <span className={"name"}>{recentHistory.nickname}</span>
          <span className={"time"}>{formatDateTime(new Date(recentHistory.time))}</span>
        </>;
      default:
        return <>
        </>;
    }
  };

  return <>
    <div>
      {renderRecentHistory(props.recentHistory)}
    </div>
  </>;
};

export const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);

  z-index: 999;
  @media screen and (max-width: 764px) {
    top: 53px !important;
    height: calc(100vh - 53px) !important;
  }
`;

export const TransparentOverlay = styled.div`
  position: fixed;
  left: 0;
  top: 60px;
  width: 100%;
  height: calc(100vh - 60px);
  z-index: 10;
`;


export const DescriptionContents = (props: { parsedDescription: any }) => {
  useEffect(() => {

  }, [props.parsedDescription]);

  const parsedDescription: any = Array.isArray(props.parsedDescription) ? props.parsedDescription : [props.parsedDescription];
  return props.parsedDescription && parsedDescription.map((element: any, index: number) => {
    if (element && element.type && element.type === "h1") {
      return <h1 key={index}>
        <button>{element.props.children}</button>
      </h1>;
    } else if (element && element.type && element.type === "h2") {
      return <h2 key={index}>
        <button>{element.props.children}</button>
      </h2>;
    } else if (element && element.type && element.type === "h3") {
      return <h3 key={index}>
        <button>{element.props.children}</button>
      </h3>;
    }
  }) || <></>;
};

export const BoxRadioButton = styled.div`
  display: inline-block;
  vertical-align: middle;

  input[type="radio"] {
    display: none;

    + label {
      position: relative;
      display: inline-block;
      width: 8rem;
      height: 3.2rem;
      line-height: 3.2rem;
      background: #fff;
      border: 1px solid #C7D6EA;
      text-align: center;
      margin-left: -1px;
      font-size: 1.2rem;
      color: #2A2C2F;
      cursor: pointer;
    }

    :checked + label {
      background: #407DD9 !important;
      color: #fff !important;
      border: solid 1px #407DD9 !important;
      z-index: 1;
      opacity: 1;
    }
  }

  span:first-of-type label {
    border-radius: 4px 0 0 4px;
  }

  span:last-child input + label {
    border-radius: 0 4px 4px 0;
  }

  @media screen and (max-width: 764px) {
    margin: 1.2rem 0 1.2rem 2.7rem;
  }

`;