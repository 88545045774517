import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {fontFamilyBold, fontFamilyMedium, fontFamilyRegular} from "style/fonts";
import {CookiePolicy} from "cookie/CookiePolicy";
import {CookieConfigure} from "cookie/CookieConfigure";
import TagManager from "react-gtm-module";
import {CookiePolicyDetail} from "./CookiePolicyDetail";
import svcs from "services";
import {CookieIntro} from "cookie/CookieIntro";
import {ConsentParam} from "cookie/common/consentParam";
import {LANG_EN, LANG_EN_US} from "language/langutil";
import {cookieutil} from "util/cookieutil";
import {IfDisplayPopupItem} from "models/IfDisplayPopupItem";

type Props = {
  displayPopupItem: IfDisplayPopupItem
  setDisplayPopupItem: (p: IfDisplayPopupItem) => void
}


const CookiePopup = (props: Props) => {
  const [popupPage, setPopupPage] = useState(0);
  const [consentParam, setConsentParam] = useState(new ConsentParam());
  const [introCookieType, setIntroCookieType] = useState("");
  const lang = svcs.svcLang.lang();


  useEffect(() => {
    if ([LANG_EN, LANG_EN_US].includes(lang)) {
      const key = lang == LANG_EN ? 'cookie-agreed-categories' : 'cookie-agreed-categories-us';
      const value = cookieutil.getCookieValue(key);

      if (value) {
        try {
          const settings = JSON.parse(value);

          const consentParams: ConsentParam = {
            essential: true,
            analytical: settings['analytical'] === 'granted',
            functionality: settings['functionality'] === 'granted',
            advertising: settings['advertising'] === 'granted'
          };
          setConsentParam(consentParams);
        } catch (e) {
          setConsentParam(new ConsentParam());
        }
      }
    }
  }, []);

  const loadIntroPage = (cookieType: string) => {
    if (!["", undefined].includes(cookieType)) {
      setIntroCookieType(cookieType);
      setPopupPage(2);
    }
  };

  const deleteFunctional = () => {
    Object.keys(localStorage).forEach(key => {
      if (!['ssq-language', 'firstVisitedTime'].includes(key)) {
        localStorage.removeItem(key);
      }
    });
  };

  const setDisplayCookiePopup = (cookie: boolean) => {
    props.setDisplayPopupItem({...props.displayPopupItem, cookie: cookie});
  };

  const cookieTotalUpdate = (allAccept: boolean) => {
    TagManager.dataLayer({
      dataLayer: {
        "event": "cookie-consent-event",
        "essential": "granted",
        "analytical": allAccept ? "granted" : "denied",
        "functionality": allAccept ? "granted" : "denied",
        "advertising": allAccept ? "granted" : "denied",
        "languageSite": lang,
      }
    });
    if (!allAccept) deleteFunctional();
    setDisplayCookiePopup(false);
  };


  const cookieConfigureUpdate = (cosentParam: ConsentParam) => {
    TagManager.dataLayer({
      dataLayer: {
        "event": "cookie-consent-event",
        "essential": "granted",
        "analytical": cosentParam.analytical ? "granted" : "denied",
        "functionality": cosentParam.functionality ? "granted" : "denied",
        "advertising": cosentParam.advertising ? "granted" : "denied",
        "languageSite": lang,
      }
    });
    if (!cosentParam.functionality) deleteFunctional();
    setDisplayCookiePopup(false);
  };


  const trans = (key: string): string => {
    svcs.svcLang.setNamespace("I18N_NAMESPACE_HEADER");
    return svcs.svcLang.getTranslationByKey(key) || "";
  };

  return (
    <>
      <CookiePopupWrap>
        <div className={"cookie_popup_base"}>
          {popupPage === 0 && <CookiePolicy setPopupPage={setPopupPage} setDisplayCookiePopup={setDisplayCookiePopup} trans={trans}
            cookieTotalUpdate={cookieTotalUpdate}/>}
          {popupPage === 1 && <CookieConfigure setPopupPage={setPopupPage} setDisplayCookiePopup={setDisplayCookiePopup} trans={trans}
            consentParam={consentParam} setConsentParam={setConsentParam} cookieConfigureUpdate={cookieConfigureUpdate} loadIntroPage={loadIntroPage}
          />}
          {popupPage === 2 && <CookieIntro setPopupPage={setPopupPage} setDisplayCookiePopup={setDisplayCookiePopup} trans={trans}
            introCookieType={introCookieType}/>}
          {popupPage === 3 && <CookiePolicyDetail setPopupPage={setPopupPage} setDisplayCookiePopup={setDisplayCookiePopup} trans={trans}/>}
        </div>
      </CookiePopupWrap>

      <CookiePopupOverlay/>
    </>
  );
};


const CookiePopupWrap = styled.div`
  .cookie_popup_base {
    background: white;
    z-index: 1002;
    position: fixed;
    width: 36rem;
    height: 64rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    color: #2A2C2F;

    &.cookie_intro {
      table td {
        line-height: 2rem !important;

        &:first-child {
          width: 40%
        }

        &:last-child {
          width: 60%
        }
      }
    }
  }


  .close-button {
    position: absolute;
    right: 2rem;
    top: 2rem;
  }

  .popup_top {
    min-height: 8.4rem;
    padding: 0 3.2rem;
    width: 100%;
  }

  .popup_tit {
    padding: 3.6rem 0 1.9rem 0;
    font-size: 2.4rem;
    ${fontFamilyBold};
    line-height: 1;

    button {
      margin-right: .8rem;
      vertical-align: middle;
    }
  }

  .popup_middle {
    padding: 0 3.2rem;
    overflow: auto;
    height: calc(100% - 18rem);
  }

  .popup_policy_total {
    height: calc(100% - 8.4rem);
    margin: 0;
    overflow: auto;
    padding: 0 3.2rem 2rem 3.2rem;
  }

  .popup_policy_middle {
    height: 35.6rem;
  }

  .popup_middle_table_mode {
    height: calc(100% - 20rem);
  }

  .introduce {
    font-size: 1.4rem;
    line-height: 2.4rem;
    ${fontFamilyRegular};

    &.table_mode {
      padding: 0 3.2rem;
    }
  }

  .privacy_policy_link {
    color: #407EDB;
    border-bottom: 1px solid #407EDB;
    cursor: pointer;
    ${fontFamilyMedium};
  }

  .privacy_policy_image_container {
    padding-top: 9.2rem;
    display: flex;
    justify-content: center;
  }

  .cookie_table_wrap {
    padding-top: 3.2rem;
    height: calc(100% - 2.4rem);
  }

  .cookie_table {
    width: 100%;
    height: 100%;
    border-collapse: collapse;
    border-spacing: 1.5rem;
    font-size: 1.2rem;
    color: #2A2C2F;

    *::-webkit-scrollbar-thumb {
      background-clip: padding-box;
      border: 2px solid transparent;
    }

    thead {
      height: 4rem;

      tr {
        width: 36rem;
        display: table;
        min-height: 4rem;
      }
    }

    tr {
      width: 100%;
      min-height: 4.8rem;
    }

    td {
      min-height: 4rem;
      padding: 0 1.2rem;
      letter-spacing: 0;

      :first-child {
        padding-left: 3.2rem !important;
      }

      :last-child {
        padding-right: 2.4rem !important;
        text-align: center;

        &.domain {
          text-align: left;
        }
      }
    }

    thead td {
      background: #D8E1EF;
      font-size: 1.2rem;
      line-height: 1.4rem;

      &:first-child {
        width: 70%
      }

      &:last-child {
        width: 30%
      }
    }

    tbody {
      display: block;
      width: 36rem;
      height: 100%;
      overflow: hidden auto;

      tr {
        display: table;
        width: 36rem;

        &.title {
          td:first-child {
            font-size: 1.4rem;
            line-height: 1.4rem;
            ${fontFamilyBold};
          }

          :hover {
            background: rgba(64, 126, 219, 0.1) !important;
          }

          :active {
            background: rgba(64, 126, 219, 0.2) !important;
          }

          &.intro_open {
            background: rgba(64, 126, 219, 0.1) !important;
          }
        }

        td {
          min-height: 4.8rem;
          //padding: 1.4rem .4rem 1.4rem .4rem;


          &.intro_table_color {
            border-top: 1px solid #D8E1EF;
            border-bottom: 1px solid #D8E1EF;
            line-height: 2rem;
            padding: 2rem 0 2rem 0;
            text-align: left;
            font-size: 13px;
            ${fontFamilyRegular}
          }

          &:first-child {
            width: 70%
          }

          &:last-child {
            width: 30%
          }
        }

        &.stripe_table_color {
          background: #F7F8FB;
        }
      }
    }
  }

  .check_switch {
    min-height: 4.8rem;
    display: inline-flex;
    align-items: center;

    input {
      display: none;
      cursor: pointer;

      :checked + label {
        background: #407EDB;
        border: 1px solid #2B6AC7;
        color: #2A2C2F;

        &:before {
          left: 29px;
          transition: left 0.3s;
        }
      }

    }

    label {
      position: relative;
      display: inline-block;
      width: 52px;
      height: 24px;
      border-radius: 12px;
      cursor: pointer;
      text-align: center;
      background: #D8E1EF;
      border: 1px solid #C6CFDC;

      :before {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        background: #fff;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
        border-radius: 100%;
        vertical-align: middle;
        position: absolute;
        left: 1px;
        top: 1px;
        transition: left 0.3s;
      }
    }

    &.essential_check {
      label {
        cursor: default;
        opacity: 0.5;
      }
    }
  }

  .title_area {
    height: 100%;
    line-height: 2rem;

    span {
      vertical-align: middle;
      letter-spacing: 0;
    }
  }

  .icon_information_container {
    position: relative;
    width: 3.2rem;
    height: 2.4rem;

    img {
      vertical-align: middle;
    }
  }


  .popup_bottom {
    padding: 0 3.2rem;
    display: flex;
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 0;
    height: 9.6rem;
    border-radius: 0 0 4px 4px;
    justify-content: center;
    align-items: center;
  }

  .popup_bottom_submit {
    color: white;
    border-radius: 100px;
    width: 100%;
    height: 4.8rem;
    line-height: 4.8rem;
    background: #407EDB;
    text-align: center;
    font-size: 1.6rem;
    ${fontFamilyBold};

    :hover {
      background: #3B71C4;
    }

    :active {
      background: #3463AB;
    }
  }

  .policy_configure_button {
    background: #FFFFFF;
    border: 1px solid #C7D6EA;
    width: 100%;
    height: 4.8rem;
    border-radius: 100px;
    color: #666E7A;
    font-size: 1.4rem;
    ${fontFamilyMedium};

    :hover {
      background: rgba(64, 125, 217, 0.1);
    }

    :active {
      background: rgba(64, 125, 217, 0.3);
    }
  }

  .policy_submit_button {
    margin-top: 1.6rem;
    width: 100%;
    height: 48px;
    border-radius: 100px;
    font-size: 1.4rem;
    ${fontFamilyBold};
    color: #FFFFFF;

    &.refuse {
      background: #7783A1;

      :hover {
        background: #6B758E;
      }

      :active {
        background: #596278;
      }
    }

    &.accept {
      background: #407EDB;

      :hover {
        background: #3B71C4;
      }

      :active {
        background: #3463AB;
      }
    }
  }

  .more_info_link {
    padding-top: .8rem;

    a {
      width: 100%;
      display: block;
      color: #407EDB;
    }
  }

  .cookie_policy_content {
    ${fontFamilyMedium};
    height: 100%;


    *::-webkit-scrollbar-thumb {
      background-clip: padding-box;
      border: 2px solid transparent;
    }

    .cookie_detail {
      border: 1px solid #C7D6EA;
      border-radius: 4px;
      height: 100%;
      max-height: initial;
      overflow-y: auto;
      padding: 1.6rem 0 1.6rem 1.6rem;
      font-size: 1.3rem;
      line-height: 1.9rem;

      h2 {
        font-size: 1.6rem;
        line-height: 3.2rem;
        ${fontFamilyMedium};
        display: inline-block;
      }

      b {
        font-size: 1.4rem;
        ${fontFamilyBold};
        margin-bottom: 2.4rem;
      }

      li {
        margin-left: 2rem;
        list-style-type: disc; /* 동그라미 기호를 사용 */
      }

      a {
        color: #407EDB;
        border-bottom: 1px solid #407EDB;
      }
    }
  }


  @media screen and (max-height: 600px) {
    .cookie_popup_base {
      height: 100%;
      max-height: 60rem;
    }
  }
  @-moz-document url-prefix() {
    .cookie_table tbody {
      height: 350px;
    }
  }

`;

const CookiePopupOverlay = styled.div`
  position: fixed !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  background: rgba(0, 0, 0, 0.5);
`;

export default CookiePopup;