import useSWR, {KeyedMutator} from "swr";
import {isVal} from "util/ssqutil";
import svcs from "services";
import {LANG_KO, LANG_KO_KR} from "language/langutil";


export const useApiWeather = ({lat, lon, lang}: { lat?: number, lon?: number, lang?: string }): [any, KeyedMutator<any>] => {
  const {data, mutate} = useSWR(isVal(lat) && isVal(lon) && isVal(lang) && ['/api/member/asset/weather/get', {lat, lon, lang}]);
  return [data, mutate];
};

export const weatherLang = () => {
  if ([LANG_KO, LANG_KO_KR].includes(svcs.svcLang.lang())) return 'kr';
  return "en";
};