export const authutil = {
  removeCountryLang: (pathname: string): string => {
    if ((pathname || "").startsWith('/us/en')) return pathname.substring(6);
    if ((pathname || "").startsWith('/kr/ko')) return pathname.substring(6);
    if ((pathname || "").startsWith('/ww/en')) return pathname.substring(6);
    return pathname || '';
  },
  isMatchUrlDocType: (docType: string, pathname: string): boolean => {
    pathname = authutil.removeCountryLang(pathname || '');
    if (pathname.startsWith('/community/qna/')) {
      if (["QNA_Q", "QNA_A"].includes(docType)) return true;
    } else if (pathname.startsWith('/service/sample/')) {
      if (["SAMPLE", "USER_SAMPLE"].includes(docType)) return true;
    } else if (pathname.startsWith('/service/software/')) {
      if (["SW_SERVICE"].includes(docType)) return true;
    } else if (pathname.startsWith('/product/document/')) {
      if (["MANUAL", "CATALOG", "CERTIFICATION", "CAD_DATA", "DATASHEET", "SOFTWARE", "OS", "EDUCATION_VIDEO", "EDUCATION_NOTE", "TROUBLE_SHOOTING"].includes(docType)) return true;
    } else if (pathname.startsWith('/community/rnd/qna/')) {
      if (['RND_QNA_Q', 'RND_QNA_A'].includes(docType)) return true;
    } else if (pathname.startsWith('/community/blog/')) {
      if (["APPLICATION_NOTE", "TECHNICAL_GUIDE", "TECH_GENERAL"].includes(docType)) return true;
    } else if (pathname.startsWith('/community/rnd/notice/')) {
      if (['RND_NOTI'].includes(docType)) return true;
    } else if (pathname.startsWith('/community/board/')) {
      if (["DOC_EVT", "DOC_NOTI", "TIP", "JOB_OFFER", "JOB_SEARCH"].includes(docType)) return true;
    } else if (pathname.startsWith('/community/rnd/talk/')) {
      if (['RND_TALK'].includes(docType)) return true;
    } else if (pathname.startsWith('/community/partner/notice/')) {
      if (['PARTNER_NOTI'].includes(docType)) return true;
    } else if (pathname.startsWith('/community/partner/doc/')) {
      if (['PARTNER_DOC'].includes(docType)) return true;
    } else if (pathname.startsWith('/community/partner/qna/')) {
      if (['PARTNER_QNA_A', 'PARTNER_QNA_Q'].includes(docType)) return true;
    } else if (pathname.startsWith('/solution/document/')) {
      if (['TECHNICAL_ARTICLE'].includes(docType)) return true;
    }
    return false;
  },
  isRnd: (pathname: string): boolean => {
    //
    pathname = authutil.removeCountryLang(pathname || '');
    if (pathname.startsWith('/community/rnd/')) return true;
    return false;
  }
};