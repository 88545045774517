import {i18ntrans} from "language/i18nkey";

export const getAppBarTitle = (pathname: string) => {
  const t = i18ntrans.asset;
  if (pathname.startsWith('/asset/monitor')) {
    return t("I18N_AST_MONITORING");
  }
  if (pathname.startsWith('/asset/trend')) {
    return t("I18N_AST_TRENDS");
  }
  if (pathname.startsWith('/asset/alarm')) {
    return t("I18N_AST_ALARM");
  }
  if (pathname.startsWith('/asset/parameter')) {
    return t("I18N_AST_PARAMETER");
  }
  if (pathname.startsWith('/asset/settings')) {
    return t("I18N_AST_SETTING");
  }
  if (pathname.startsWith('/asset/profile')) {
    return t("I18N_AST_USER_ACCOUNT");
  }
};