// @flow
import * as React from 'react';
//import {Helmet} from "react-helmet";
import {Helmet} from 'react-helmet-async';

type Props = {
  title: string;
};
export const HelmetTitle = (props: Props) => {
  return (
    <Helmet>
      <title>{props.title}</title>
    </Helmet>
  );
};