// @flow
import * as React from 'react';
import {IfLoginInfo} from "models/IfLoginInfo";
import {PartnerQuestionProcessWrap} from "communitypage/partner/qna/partner-qna-popup-style";
import {UserProfileImage} from "header/userprofile/UserProfileImage";
import {PartnerQnaRating} from "communitypage/partner/qna/PartnerQnaRating";
import {usePopPartnerQnaState} from "communitypage/partner/qna/PopPartnerQnaStateEdit";

type Props = {
  trans,
  loginInfo: IfLoginInfo,
  displayLoginPopup: Function;
};
export const PartnerQnaProcess = (props: Props) => {
  const [refStateModifyPopup, PopPartnerQnaStateModify] = usePopPartnerQnaState();

  return (
    <PartnerQuestionProcessWrap>
      <div className={"info-wrap"}>
        <div className={"profile"}>
          <UserProfileImage headerStyle={'header'} loginInfo={props.loginInfo}/>
          <div>
            <p>담당자</p>
            <p className={"name"}>아이스아메리카노</p>
          </div>
        </div>
        <div className={"process"}>
          <div className={"complete"}>
            <div className={"num"}><span>1</span></div>
            <p className={"tit"}>열림</p>
          </div>
          <div className={"complete"}>
            <div className={"num"}><span>2</span></div>
            <p className={"tit"}>진행</p>
          </div>
          <div className={"now"}>
            <div className={"num"}><span>3</span></div>
            <p className={"tit"}>닫힘</p>
          </div>
        </div>
        <div className={"right"}>
          {/*<div className={"complete-date"}>*/}
          {/*  <p>완료 예정일</p>*/}
          {/*  <div className={"date"}>*/}
          {/*    <div className={"d-flex"}>*/}
          {/*      /!*<span className={"dday-label"}>D-1</span>*!/*/}
          {/*      /!*<span className={"dday-label"}>D-DAY</span>*!/*/}
          {/*      <span className={"dday-label delayed"}>지연</span>*/}
          {/*      <span className={"dday-label delayed"}>D+5</span>*/}
          {/*    </div>*/}
          {/*    24.04.05*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className={"rating"}>
            <PartnerQnaRating/>
          </div>
          {props.loginInfo.loginType === 'WELS' && <button onClick={() => refStateModifyPopup.current.show()} className={"edit-btn"}>수정</button>}

        </div>

      </div>
      <div className={"step-wrap"}>
        <div><p>Boris님이 닫힘 상태로 변경</p><span>2022-05-01  08:00:05</span></div>
        <div className={"complete"}><p>지연 상태로 변경</p><span>2022-05-01  08:00:05</span></div>
        <div className={"complete"}><p>아이스아메리카노님이 완료 예정일을 24-04-05로 변경</p><span>2022-05-01  08:00:05</span></div>
        <div className={"complete"}><p>아이스아메리카노님이 상태를 진행으로 변경</p><span>2022-05-01  08:00:05</span></div>
        <div className={"complete"}><p>아이스아메리카노님이 담당자를 Boris님으로 변경</p><span>2022-05-01  08:00:05</span></div>
        <div className={"complete"}><p>아이스아메리카노 님이 문의 접수 중</p><span>2022-05-01  08:00:05</span></div>
        <div className={"complete"}><p>아이스아메리카노 님이 문의 접수 중</p><span>2022-05-01  08:00:05</span></div>
      </div>
      <PopPartnerQnaStateModify/>

    </PartnerQuestionProcessWrap>
  );
};