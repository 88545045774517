import React, {useState} from "react";
import {fontFamilyMedium, fontFamilyRegular} from "style/fonts";
import img_icon_c_time from "images/common/icon_c_time.svg";
import img_icon_c_view from "images/common/icon_c_view.svg";
import img_icon_c_good from "images/common/icon_c_good.svg";
import img_icon_c_comment from "images/common/icon_comment.svg";
import img_icon_c_not_bad from "images/common/icon_c_not_bad.svg";
import styled from "styled-components";
import {docutil} from "util/docutil";
import svcs from "services";
import {i18ns} from "language/i18nkey";
import {ISsqDataDocument} from "repositories/SsqDataRepository";
import {IfDocCommentInfo} from "models/IfSsqDataDocCommentEntity";

interface Props {
  ssqDocument?: ISsqDataDocument;
  commentList?: Array<IfDocCommentInfo>;
  time?: any;
  count?: any;
  good?: any;
  bad?: any;
  type?: string;
}

const CommunityPostInfo = (props: Props) => {

  const [timeHover, setTimeHover] = useState(false);
  const [countHover, setCountHover] = useState(false,);
  const [goodHover, setGoodHover] = useState(false,);
  const [badHover, setBadHover] = useState(false,);
  const [commentHover, setCommentHover] = useState(false,);

  const trans = (key: string) => {
    svcs.svcLang.setNamespace(i18ns.cmmnty);
    return svcs.svcLang.getTranslationByKey(key) || "";
  };

  const postList = () => {
    return <>
      <li className="c-time"
        onMouseEnter={(e) => {
          setTimeHover(true);
        }}
        onMouseLeave={(e) => {
          setTimeHover(false);
        }}>{docutil.diffTime(props.ssqDocument.updateTime)}
        {timeHover && <div className={"hover time"}>{trans('I18N_COMMUNITY_HOVER_TIME')}</div>}
      </li>

      {props.commentList.length > 0 && <li className="c-comment"
        onMouseEnter={(e) => {
          setCommentHover(true);
        }}
        onMouseLeave={(e) => {
          setCommentHover(false);
        }}
      >{props.commentList.length}
        {commentHover && <div className={"hover time"}>{trans('I18N_COMMUNITY_COMMENT')}</div>}
      </li>}

      <li className="c-view"
        onMouseEnter={(e) => {
          setCountHover(true);
        }}
        onMouseLeave={(e) => {
          setCountHover(false);
        }}>{props.ssqDocument.clickCount}
        {countHover && <div className={"hover view"}>{trans('I18N_COMMUNITY_HOVER_VIEW')}</div>}
      </li>


      <li className="c-good"
        onMouseEnter={(e) => {
          setGoodHover(true);
        }}
        onMouseLeave={(e) => {
          setGoodHover(false);
        }}
        style={{display: props.ssqDocument.userGoodCnt == 0 ? 'none' : ''}}>{props.ssqDocument.userGoodCnt}
        {goodHover && <div className={"hover"}>{trans('I18N_COMMUNITY_HOVER_GOOD')}</div>}
      </li>

      <li className="c-not-bad"
        onMouseEnter={(e) => {
          setBadHover(true);
        }}
        onMouseLeave={(e) => {
          setBadHover(false);
        }}
        style={{display: props.ssqDocument.userBadCnt == 0 ? 'none' : ''}}>{props.ssqDocument.userBadCnt}
        {badHover && <div className={"hover"}>{trans('I18N_COMMUNITY_HOVER_BAD')}</div>}
      </li>
    </>;
  };

  const viewList = () => {
    return <>
      <li className="c-time"
        onMouseEnter={(e) => {
          setTimeHover(true);
        }}
        onMouseLeave={(e) => {
          setTimeHover(false);
        }}
      >{docutil.diffTime(props.ssqDocument.updateTime)}
        {timeHover && <div className={"hover time"}>{trans('I18N_COMMUNITY_HOVER_TIME')}</div>}
      </li>

      {props.commentList.length > 0 && <li className="c-comment"
        onMouseEnter={(e) => {
          setCommentHover(true);
        }}
        onMouseLeave={(e) => {
          setCommentHover(false);
        }}
      >{props.commentList.length}
        {commentHover && <div className={"hover comment"}>{trans('I18N_COMMUNITY_COMMENT')}</div>}
      </li>}

      <li className="c-view"
        onMouseEnter={(e) => {
          setCountHover(true);
        }}
        onMouseLeave={(e) => {
          setCountHover(false);
        }}>{props.ssqDocument.clickCount}
        {countHover && <div className={"hover view"}>{trans('I18N_COMMUNITY_HOVER_VIEW')}</div>}
      </li>

    </>;
  };

  const qnaAList = () => {
    return <>
      <li className="c-time"
        onMouseEnter={(e) => {
          setTimeHover(true);
        }}
        onMouseLeave={(e) => {
          setTimeHover(false);
        }}
      >{docutil.diffTime(props.ssqDocument.updateTime)}
        {timeHover && <div className={"hover time"}>{trans('I18N_COMMUNITY_HOVER_TIME')}</div>}
      </li>

      {
        props.commentList.length > 0 && <li className="c-comment"
          onMouseEnter={(e) => {
            setCommentHover(true);
          }}
          onMouseLeave={(e) => {
            setCommentHover(false);
          }}
        >{props.commentList.length}
          {commentHover && <div className={"hover comment"}>{trans('I18N_COMMUNITY_COMMENT')}</div>}
        </li>
      }
    </>;
  };

  const qnaQList = () => {
    return <>
      <li className="c-time"
        onMouseEnter={(e) => {
          setTimeHover(true);
        }}
        onMouseLeave={(e) => {
          setTimeHover(false);
        }}
      >{docutil.diffTime(props.ssqDocument.updateTime)}
        {timeHover && <div className={"hover time"}>{trans('I18N_COMMUNITY_HOVER_TIME')}</div>}
      </li>

      <li className="c-view"
        onMouseEnter={(e) => {
          setCountHover(true);
        }}
        onMouseLeave={(e) => {
          setCountHover(false);
        }}>{props.ssqDocument.clickCount}
        {countHover && <div className={"hover view"}>{trans('I18N_COMMUNITY_HOVER_VIEW')}</div>}
      </li>

      {
        props.commentList.length > 0 && <li className="c-comment"
          onMouseEnter={(e) => {
            setCommentHover(true);
          }}
          onMouseLeave={(e) => {
            setCommentHover(false);
          }}
        >{props.commentList.length}
          {commentHover && <div className={"hover comment"}>{trans('I18N_COMMUNITY_COMMENT')}</div>}
        </li>
      }
    </>;
  };

  const type = (type: string) => {
    switch (type) {
      case "list":
        return postList();
      case "view" :
        return viewList();
      case "qna-a":
        return qnaAList();
      case "qna-q":
        return qnaQList();
    }
  };

  return (
    <CommunityPostInfoStyle>
      <ul className="simple-post-item">
        {type(props.type)}
      </ul>
    </CommunityPostInfoStyle>
  );
};
export default CommunityPostInfo;


export const CommunityPostInfoStyle = styled.div`

  .simple-post-item {
    display: flex;
    align-items: center;
  }

  li {
    margin-right: 1rem;
    font-size: 1.2rem;
    ${fontFamilyRegular};
    color: #7783A1;
    line-height: 2.4;
    position: relative;
  }


  .c-time {
    background: url(${img_icon_c_time}) no-repeat left center;
    padding-left: 1.8rem;
    //margin-left: .8rem;
  }

  .c-comment {
    background: url(${img_icon_c_comment}) no-repeat left center;
    padding-left: 1.8rem;
    color: #407EDB;
  }

  .c-view {
    color: #407EDB;
    background: url(${img_icon_c_view}) no-repeat left center;
    padding-left: 1.8rem;
  }

  .c-good {
    color: #407EDB;
    background: url(${img_icon_c_good}) no-repeat left center;
    padding-left: 1.8rem;
  }

  .c-not-bad {
    color: #FF215D;
    background: url(${img_icon_c_not_bad}) no-repeat left center;
    padding-left: 1.8rem;
  }

  .hover {
    background: white;
    border: 0.5px solid #C7D6EA;
    border-radius: 4px;
    height: 1.8rem;
    line-height: 1.6rem;
    min-width: 42px;
    padding: 0 0.6rem;
    text-align: center;
    z-index: 2;
    font-size: 9px;
    white-space: nowrap;
    color: #7783A1;
    ${fontFamilyRegular};
    position: absolute;
    top: -1.1rem;
    left: -1rem;
  }

  .hover.view {
    left: 50%;
    transform: translateX(-50%);
  }

  .hover.time {
    top: -1.3rem;
    left: calc(50% + 0.4rem);
    transform: translateX(-50%);
  }

  .hover.comment {
    top: -1.3rem;
    left: calc(50% + 0.4rem);
    transform: translateX(-50%);
  }
`;