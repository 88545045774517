import React, {Component} from "react";
import {SsqImages} from "../images/SsqImages";
import {LanguageService} from "../language/LanguageService";
import {IResetPasswordEmailRequest, NotificationService} from "../repositories/NotificationService";
import {AccountService} from "../repositories/AccountService";
import svcs from "services";

interface Props {
  languageService: LanguageService;
  accountService: AccountService;
  notificationService: NotificationService;
  resetPasswordEmail: string;
  showSendPasswordResetEmailPopupFunc: any;
  showLoginPopupFunc: any;
  closeAllPopupFunc: any;
}

interface State {
}


export class SendPasswordResetEmailPopup extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  async componentDidMount() {
    this.setState({
      emailAddress: this.props.resetPasswordEmail
    });
  }

  getTranslation(key: string): string {
    this.props.languageService.setNamespace("I18N_NAMESPACE_HEADER");
    return this.props.languageService.getTranslationByKey(key) || "";
  }

  closeSendPasswordResetEmailPopup() {
    this.props.showSendPasswordResetEmailPopupFunc(false);
  }

  showLoginPopup() {
    this.props.showLoginPopupFunc(true);
  }

  closeAllPopup() {
    this.props.closeAllPopupFunc(false, false, false, false, false, false);
  }

  renderPasswordResetPopup() {
    return <div className={"Password_Reset_Popup"}>
      <div className={"popup_tit"}>
        <button onClick={() => this.closeSendPasswordResetEmailPopup()}>
          <img alt={"back_button.svg"} src={SsqImages.header.icon.backButton()}/>
        </button>
        {this.getTranslation("I18N_SEND_EMAIL_RESET_PASSWORD_TITLE")}
      </div>
      <div className={"popup_noti"}>
        '{this.props.resetPasswordEmail}' {this.getTranslation("I18N_SEND_EMAIL_RESET_PASSWORD_NOTIFICATION")}
      </div>
      <button className={"close-button"} onClick={() => this.closeAllPopup()}>
        <img alt={"close_small_icon.svg"} src={SsqImages.common.icon.closeSmallIcon()}/>
      </button>
      <div className={"popup_img"}>
        <img alt={"popup_password_reset_email_icon.svg"} src={SsqImages.header.popup.passwordResetEmail()}/>
      </div>
      <button className={"Submit-Button"} onClick={async () => {
        this.closeAllPopup();
        this.showLoginPopup();
      }}>
        {this.getTranslation("I18N_SEND_EMAIL_RESET_PASSWORD_LOGIN_BUTTON")}
      </button>
      <div className={"email_fail_info"}>
        <div>
          {this.getTranslation("I18N_SEND_EMAIL_RESET_PASSWORD_QUESTION")}
        </div>
        <div>
          {this.getTranslation("I18N_SEND_EMAIL_RESET_PASSWORD_GUIDE")}
        </div>
        <button onClick={async () => {
          let resetPasswordEmailRequest: IResetPasswordEmailRequest = {
            email: this.props.resetPasswordEmail,
            lang: svcs.svcLang.getLanguage()
          };
          await this.props.notificationService.postSendResetPasswordEmail(resetPasswordEmailRequest);

          alert(this.getTranslation('I18N_SIGNUP_RESEND_MAIL'));
        }}>
          {this.getTranslation("I18N_SEND_EMAIL_RESET_PASSWORD_RESEND")}
        </button>
      </div>
    </div>;
  }

  render() {
    return <div>
      {this.renderPasswordResetPopup()}
    </div>;
  }
}
