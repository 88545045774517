import React, {Component} from "react";
import {SsqImages} from "../images/SsqImages";
import {LanguageService} from "../language/LanguageService";
import {IVerifySignupEmailRequest, NotificationService} from "../repositories/NotificationService";
import svcs from "services";

interface Props {
  languageService: LanguageService;
  notificationService: NotificationService;
  signupEmail: string;
  showSendVerifyEmailPopupFunc: any;
  showSignupPopupFunc: any;
  closeAllPopupFunc: any;
}

interface State {
  signupEmail: string;
}

export class SendVerifyEmailPopup extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      signupEmail: ""
    };

  }


  async componentDidMount() {
    this.setState({
      signupEmail: this.props.signupEmail
    });
  }

  getTranslation(key: string): string {
    this.props.languageService.setNamespace("I18N_NAMESPACE_HEADER");
    return this.props.languageService.getTranslationByKey(key) || "";
  }

  closeSendVerifyEmailPopup() {
    this.props.showSendVerifyEmailPopupFunc(false);
  }

  closeSignupPopup() {
    this.props.showSignupPopupFunc(false);
  }

  closeAllPopup() {
    this.props.closeAllPopupFunc(false, false, false, false, false, false);
  }

  renderSendVerifyEmailPopup() {
    return <div className={"Send_Email_Popup"}>
      <div className={"popup_tit"}>
        <button onClick={() => this.closeSendVerifyEmailPopup()}>
          <img alt={"back_button.svg"} src={SsqImages.header.icon.backButton()}/>
        </button>
        {this.getTranslation("I18N_SIGNUP_AUTHENTICATION_MAIL")}
      </div>
      <div className={"popup_noti"}>
        {this.getTranslation("I18N_SEND_VERIFY_EMAIL_NOTIFICATION1")}'{this.state.signupEmail}'{this.getTranslation("I18N_SEND_VERIFY_EMAIL_NOTIFICATION2")}
      </div>
      <button className={"close-button"} onClick={() => this.closeAllPopup()}>
        <img alt={"close_small_icon.svg"} src={SsqImages.common.icon.closeSmallIcon()}/>
      </button>
      <div className={"popup_img"}>
        <img alt={"popup_email_icon.svg"} src={SsqImages.header.popup.sendemail()}/>
      </div>
      <button className={"Submit-Button"} onClick={() => {
        this.closeSendVerifyEmailPopup();
        this.closeSignupPopup();
      }}>
        {this.getTranslation("I18N_BACK_TO_LOGIN")}
      </button>
      <div className={"email_fail_info"}>
        <div>
          {this.getTranslation("I18N_SEND_VERIFY_EMAIL_RECEIVE")}
        </div>
        <div>
          {this.getTranslation("I18N_SEND_VERIFY_EMAIL_RETRY_NOTIFICATION")}
        </div>
        <button onClick={async () => {
          let verifySignupEmailRequest: IVerifySignupEmailRequest = {
            email: this.state.signupEmail,
            lang: svcs.svcLang.getLanguage()
          };
          await this.props.notificationService.postSendVerifySignupEmail(verifySignupEmailRequest);

          alert(this.getTranslation('I18N_SIGNUP_RESEND_MAIL'));
        }}>
          {this.getTranslation("I18N_SEND_VERIFY_EMAIL_RESEND")}
        </button>
      </div>
    </div>;
  }

  render() {
    return <div>
      {this.renderSendVerifyEmailPopup()}
    </div>;
  }
}
