import {IfProjectEntity} from "asset/models/IfProjectEntity";

import {ACTION_TYPES} from "store/ActionTypes";
import {useDispatch, useSelector} from "react-redux";
import {useCallback} from "react";
import {IfDeviceDetailRes} from "asset/models/IfDeviceEntity";
import {RefWarning} from "asset/cmpns/popup/common/WarningModal";
import {RefConfirm} from "asset/cmpns/popup/common/ConfirmModal";


class AssetLoading {
  loading?: boolean;
  width?: number;
  show: boolean = false;
}

export class ReduxAssetState {
  selPjt: IfProjectEntity = null;
  selDev: IfDeviceDetailRes = null;
  lastUpdate: Date = new Date();


  loading = new AssetLoading();
}

export interface IfReduxAssetState extends ReduxAssetState {
}

const initState: IfReduxAssetState = new ReduxAssetState();

const reducerAsset = (state = initState, action) => {
  switch (action.type) {
    case ACTION_TYPES.ASSET.SET_PJT:
      return {...state, selPjt: action.payload};
    case ACTION_TYPES.ASSET.SET_DEV:
      return {...state, selDev: action.payload};
    // case ACTION_TYPES.ASSET.CONFIRM_POPUP:
    //   return {...state, confirm: action.payload};
    case ACTION_TYPES.ASSET.LAST_UPDATE:
      if (action.payload) {
        if (typeof action.payload === 'string')
          return {...state, lastUpdate: new Date(action.payload)};
        return {...state, lastUpdate: action.payload};
      }
      console.log("lastupdate", new Date());
      return {...state, lastUpdate: new Date()};
    case ACTION_TYPES.ASSET.LOADING:
      return {...state, loading: action.payload};
    default:
      return state;
  }
};

export default reducerAsset;

export const useAssetSelPjt = (): [IfProjectEntity, (pjt: IfProjectEntity) => void] => {
  const dispatch = useDispatch();
  const selPjt = useSelector((state: any): IfProjectEntity => state.asset.selPjt);
  const setSelPjt = useCallback((pjt: IfProjectEntity) => {
    dispatch({type: ACTION_TYPES.ASSET.SET_PJT, payload: pjt});
  }, [dispatch]);
  return [selPjt, setSelPjt];
};


export const useAssetSelDev = (): [IfDeviceDetailRes, (dev: IfDeviceDetailRes) => void] => {
  const dispatch = useDispatch();
  const selDev = useSelector((state: any): IfDeviceDetailRes => state.asset.selDev);
  const setSelDev = useCallback((dev: IfDeviceDetailRes) => {
    dispatch({type: ACTION_TYPES.ASSET.SET_DEV, payload: dev});
  }, [dispatch]);
  return [selDev, setSelDev];
};

export const useAssetLastUpdate = (): [Date, (lastUpdate?: Date | string) => void] => {
  const dispatch = useDispatch();
  const lastUpdate = useSelector((state: any): Date => state.asset.lastUpdate);
  const setLastUpdate = useCallback((lastUpdate?: Date | string) => {
    dispatch({type: ACTION_TYPES.ASSET.LAST_UPDATE, payload: lastUpdate});
  }, [dispatch]);
  return [lastUpdate, setLastUpdate];
};


const ctx: { confirm: RefConfirm, warning: RefWarning } = {
  confirm: null, warning: null
};
export const setRefConfirm = (ref: RefConfirm) => {
  ctx.confirm = ref;
};
export const useAssetConfrim = (): RefConfirm => {
  return ctx.confirm;
};


export const setRefWarning = (ref: RefWarning) => {
  ctx.warning = ref;
};
export const useAssetWarning = (): RefWarning => {
  return ctx.warning;
};


export const useAssetLoadingInfo = (): AssetLoading => {
  const loading = useSelector((state: any): AssetLoading => state.asset.loading);
  return loading;
};

export const useAssetLoading = () => {
  const dispatch = useDispatch();
  const callback = useCallback((payload: AssetLoading) => {
    dispatch({type: ACTION_TYPES.ASSET.LOADING, payload});
  }, [dispatch]);
  return callback;
};