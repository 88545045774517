import React, {Component} from "react";
import {SsqImages} from "../images/SsqImages";
import {getMemberTypeLangKey, MemberType} from "../definitions/MemberType";
import {getInterestFieldTypeLangKey, InterestFieldType} from "../definitions/InterestFieldType";
import {LanguageService} from "../language/LanguageService";
import {
  AccountService,
  emptyAccountInfo,
  emptySignupValues,
  IAccountInfo, ICountryCode,
  IDeleteAccountRequest,
  ISignupValues,
  IUpdateAccountRequest
} from "../repositories/AccountService";
import {NotificationService} from "../repositories/NotificationService";
import {InputWrapForSelect} from "../style/CommonStyledComponents";
import styled from "styled-components";
import {LoginService} from "../login/LoginService";
import {TermsType} from "../definitions/TermsType";
import axios from "axios";
import {LANG_KO_KR, langutil} from "language/langutil";
import {SsqDataRepository} from "repositories/SsqDataRepository";
import {SubmitTermsPopup} from "./SubmitTermsPopup";
import {fontFamilyMedium, fontFamilyRegular} from "style/fonts";
import {renderPrivateOptionallKoKr} from "header/SubmitTermsPopupItem";


interface Props {
  languageService: LanguageService;
  accountService: AccountService;
  loginService: LoginService;
  notificationService: NotificationService;
  showTermsDetailPopupFunc: Function;
  showTermsDetailPrivatePopupFunc: any;
  closeManageMyInfoPopup: Function;
  editLinkPopupSwitch?: boolean;
}

interface State {
  showUserPopup: boolean;
  showLoginPopup: boolean;
  showSignupPopup: boolean;
  isNoInputPassword: boolean;
  isValidPassword: boolean;
  isCheckedPassword: boolean;
  isExistNickname: boolean;
  isValidNickname: boolean;
  isValidCompany: boolean;
  countryCodes: ICountryCode[];
  isClickedSignupButton: boolean;
  signupValues: ISignupValues;
  accountInfo: IAccountInfo;
  originalNickname: string;
  isValidFirstname: boolean;
  isValidLastname: boolean;
  scrollPosition: string;
  countryNumber: string[];
  acceptAll: boolean;
  signupPage: number;
  comparySchool: 'company' | 'school';
}

export class ManageMyInfoPopup extends Component<Props, State> {
  homeRef: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);

    this.state = {
      showUserPopup: false,
      showLoginPopup: false,
      showSignupPopup: false,
      isNoInputPassword: true,
      isValidPassword: true,
      isCheckedPassword: true,
      isExistNickname: false,
      isValidNickname: true,
      isValidCompany: true,
      countryCodes: [],
      isClickedSignupButton: false,
      signupValues: emptySignupValues,
      accountInfo: emptyAccountInfo,
      originalNickname: "",
      isValidFirstname: true,
      isValidLastname: true,
      scrollPosition: "",
      countryNumber: [],
      acceptAll: false,
      comparySchool: 'company',
      signupPage: 1 // 0: 약관동의 팝업
    };
    this.homeRef = React.createRef();
  }

  async componentDidMount() {
    let countryCodes = await this.props.accountService.getAllCountryCodes();
    let countryNumber = [];
    countryCodes.forEach(i => !countryNumber.includes(i.countryCode) && countryNumber.push(i.countryCode));


    const accountInfo = await this.props.accountService.getAccountInfo();

    let signupValues: ISignupValues = {
      email: accountInfo.id,
      password: "",
      passwordCheck: "",
      nickname: accountInfo.nickname,
      name: accountInfo.name,
      familyName: accountInfo.familyName || "",
      school: accountInfo.school,
      majorAndGrade: accountInfo.major,
      company: accountInfo.company,
      departmentAndTitle: accountInfo.department,
      countryCode: accountInfo.countryCode,
      countryCode2: accountInfo.countryCode2 || "",
      phoneNumber: accountInfo.phoneNumber,
      memberType: accountInfo.memberType,
      interestFieldType: accountInfo.interestFieldType,
      acceptTerms: accountInfo.acceptTerms,
      acceptPrivateEssential: accountInfo.acceptPrivateEssential,
      acceptPrivateOptional: accountInfo.acceptPrivateOptional,
      acceptMail: accountInfo.acceptMail,
      loginType: accountInfo.loginType
    };


    countryCodes = countryCodes.slice().sort((a, b) => {
      if (this.props.languageService.getLanguage() == LANG_KO_KR) return (a.countryNameKo || "").localeCompare(b.countryNameKo || "");
      else return (a.countryName || "").localeCompare(b.countryName || "");
    });


    this.setState({
      countryCodes,
      signupValues,
      countryNumber,
      originalNickname: accountInfo.nickname
    });
    if (this.props.editLinkPopupSwitch) {
      this.setState({signupPage: 1});
    }
  }


  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (this.state.signupValues.acceptTerms != prevState.signupValues.acceptTerms ||
      this.state.signupValues.acceptPrivateEssential != prevState.signupValues.acceptPrivateEssential ||
      this.state.signupValues.acceptPrivateOptional != prevState.signupValues.acceptPrivateOptional) this.acceptAllByCountry();

    if (this.state.signupValues.memberType != prevState.signupValues.memberType) {
      if (this.state.signupValues.memberType == MemberType.STUDENT) this.setState({comparySchool: 'school'});
      else this.setState({comparySchool: 'company'});
    }
  }

  getTranslation(key: string): string {
    this.props.languageService.setNamespace("I18N_NAMESPACE_HEADER");
    return this.props.languageService.getTranslationByKey(key) || "";
  }

  setSignupPage = (page: number) => {
    this.setState({signupPage: page});
  };

  renderMemberTypeOption(memberType: string) {
    return <option value={memberType}>{this.getTranslation(getMemberTypeLangKey(memberType))}</option>;
  }

  renderInterestFieldTypeOption(InterestFieldType: string) {
    return <option
      value={InterestFieldType}>{this.getTranslation(getInterestFieldTypeLangKey(InterestFieldType))}</option>;
  }

  setSignupValues = (dataKey: string, value: any) => {
    this.setState({
      signupValues: {
        ...this.state.signupValues,
        [dataKey]: value
      }
    });
  };

  setScrollPosition() {
    let defaultPosition = "header";
    //비밀번호 스크롤
    if (!this.state.isValidPassword) defaultPosition = "password";
    //비밀번호 확인 스크롤
    else if (!this.state.isCheckedPassword) defaultPosition = "passwordCheck";
    //이름 스크롤
    else if (!this.state.isValidFirstname) defaultPosition = "firstName";
    //성 스크롤
    else if (!this.state.isValidLastname) defaultPosition = "lastName";
    //국가 스크롤
    else if (this.state.isClickedSignupButton && ["", null].includes(this.state.signupValues.countryCode2)) defaultPosition = "nationality";
    //닉네임 스크롤
    else if (this.state.isExistNickname || !this.state.isValidNickname) defaultPosition = "nickname";
    //관심산업 스크롤
    else if (this.state.isClickedSignupButton && this.state.signupValues.interestFieldType === InterestFieldType.SELECT) defaultPosition = "interestFieldType";
    //업종 스크롤
    else if (this.state.isClickedSignupButton && this.state.signupValues.memberType === MemberType.SELECT) defaultPosition = "memberType";
    //회사 스크롤
    else if (!this.state.isValidCompany || (this.state.isClickedSignupButton && this.state.signupValues.company === "")) defaultPosition = "company";
    //체크 스크롤
    else if (this.state.isClickedSignupButton && this.state.signupValues.acceptTerms === false) defaultPosition = "checkBox";

    else if (this.state.isClickedSignupButton && this.state.signupValues.acceptPrivateEssential == false) defaultPosition = "checkBox";

    return defaultPosition;
  }

  scrollOnClick() {
    this.homeRef.current?.scrollIntoView({behavior: 'smooth'});
  }

  renderInputAreaForStudent() {
    return <div className={"input_memtype02"}>
      <p>
        <span>{this.getTranslation("I18N_SIGNUP_SCHOOL")}</span>
        <span> * </span>
      </p>
      <div>
        <input type={"text"} aria-label={"signup-input-school"}
          value={this.state.signupValues.school}
          onChange={(e) =>
            this.setSignupValues('school', e.target.value)}
          maxLength={20} placeholder={this.getTranslation("I18N_SIGNUP_SCHOOL_PLACEHOLDER")}
        />
        {(this.state.isClickedSignupButton && this.state.signupValues.school === "") &&
          <div className={"SingUpInvalidAlert"}>{this.getTranslation("I18N_SIGNUP_REQUIRED_ALERT")}</div>}
      </div>

      <div>
        <p>{this.getTranslation("I18N_SIGNUP_MAJOR_GRADE")}</p>
        <input type={"text"} aria-label={"signup-input-majorAndGrade"}
          value={this.state.signupValues.majorAndGrade}
          onChange={(e) =>
            this.setSignupValues('majorAndGrade', e.target.value)}
          maxLength={20}
          placeholder={this.getTranslation("I18N_SIGNUP_MAJOR_GRADE_PLACEHOLDER")}
        />
      </div>
    </div>;
  }

  renderInputAreaForNotStudent() {
    return <div className={"input_memtype02"}>
      <p>
        <span>{this.getTranslation("I18N_SIGNUP_COMPANY")}</span>
        {this.state.scrollPosition == "company" ? <span ref={this.homeRef}> * </span> : <span> * </span>}
      </p>
      <div>
        <input type={"text"} aria-label={"signup-input-company"}
          value={this.state.signupValues.company}
          style={(!this.state.isValidCompany || (this.state.isClickedSignupButton && this.state.signupValues.company === ""))
            ? {border: 'solid 1px #E6003E'} : {}}
          onChange={(e) =>
            this.setSignupValues('company', e.target.value.trim())}
          onBlur={() => {
            if (this.state.signupValues.company !== "") {
              this.setState({isValidCompany: true});
            } else {
              this.setState({isValidCompany: false});
            }
          }}
          maxLength={20} placeholder={this.getTranslation("I18N_SIGNUP_COMPANY_PLACEHOLDER")}
        />
        {(this.state.isClickedSignupButton && this.state.signupValues.company === "") &&
          <div className={"SingUpInvalidAlert"}>{this.getTranslation("I18N_SIGNUP_REQUIRED_ALERT")}</div>}
      </div>

      <div>
        <p>{this.getTranslation("I18N_SIGNUP_DEPARTMENT_TITLE")}</p>
        <input type={"text"} aria-label={"signup-input-departmentAndTitle"}
          value={this.state.signupValues.departmentAndTitle}
          onChange={(e) =>
            this.setSignupValues('departmentAndTitle', e.target.value)}
          maxLength={20}
          placeholder={this.getTranslation("I18N_SIGNUP_DEPARTMENT_TITLE_PLACEHOLDER")}
        />
      </div>
    </div>;
  }

  closeSignupPopup = () => {
    this.setState({
      signupValues: {
        ...this.state.signupValues,
        acceptTerms: false,
        acceptPrivateEssential: false,
        acceptMail: false
      }
    });
  };

  showTermsDetailPopup = () => {
    this.props.showTermsDetailPopupFunc(true);
  };

  showTermsDetailPrivateEssentialPopup() {
    this.props.showTermsDetailPrivatePopupFunc(true, TermsType.ESSENTIAL);
  }

  showTermsDetailPrivateOptionalPopup() {
    this.props.showTermsDetailPrivatePopupFunc(true, TermsType.OPTIONAL);
  }

  isValidPassword(password: string): boolean {
    const reg = /^(?=.*\d)(?=.*[a-zA-Z])[0-9a-zA-Z!@#$%^&*()]{10,16}$/;
    return reg.test(password);
  }

  verifyPassword(password: string) {
    let isValidPassword = false;

    if (this.isValidPassword(password)) {
      isValidPassword = true;
    }

    this.setState({isValidPassword});
  }

  verifyPasswordCheck(password: string, passwordCheck: string) {
    let isCheckedPassword = false;

    if (password === passwordCheck) {
      isCheckedPassword = true;
    }

    this.setState({isCheckedPassword});
  }

  async isExistsNickname(nickname: string): Promise<boolean> {
    return await this.props.accountService.isExistNickname(nickname);
  }

  async verifyNickname(nickname: string) {
    if (nickname === this.state.originalNickname) {
      this.setState({
        isExistNickname: false,
        isValidNickname: true
      });
      return;
    }

    let isExistNickname = false;
    let isValidNickname = true;

    if (nickname === "" || nickname.length > 11) {
      isValidNickname = false;
    }

    if (await this.isExistsNickname(nickname)) {
      isExistNickname = true;
    }

    this.setState({
      isExistNickname,
      isValidNickname
    });
  }


  acceptAllByCountry = () => {
    if (this.props.languageService.getLanguage() == LANG_KO_KR) {
      if (this.state.signupValues.acceptTerms && this.state.signupValues.acceptPrivateEssential &&
        this.state.signupValues.acceptPrivateOptional) return this.setState({acceptAll: true});
    } else {
      if (this.state.signupValues.acceptTerms && this.state.signupValues.acceptPrivateEssential) return this.setState({acceptAll: true});
    }
    return this.setState({acceptAll: false});
  };

  acceptAllProgressByCountry = () => {
    if (this.props.languageService.getLanguage() == LANG_KO_KR) {
      this.setState({
        signupValues: {
          ...this.state.signupValues,
          acceptPrivateOptional: !this.state.acceptAll,
        }
      });
    } else {
      this.setState({
        signupValues: {
          ...this.state.signupValues,
        }
      });
    }
    this.setState({acceptAll: !this.state.acceptAll});
  };

  async verfyFirstname(firstName: string) {
    let isValidFirstname = true;


    if (firstName === "" || firstName.length > 21) {
      isValidFirstname = false;
    }

    this.setState({
      isValidFirstname,
    });
  }

  async verfyLastname(lastName: string) {
    let isValidLastname = true;

    if (lastName === "" || lastName.length > 21) {
      isValidLastname = false;
    }

    this.setState({
      isValidLastname
    });
  }


  verifyInputValues() {
    return !this.state.isExistNickname &&
      this.state.isValidPassword &&
      this.state.isCheckedPassword &&
      this.state.isValidNickname &&
      this.state.isValidFirstname &&
      this.state.isValidLastname &&
      !["", null].includes(this.state.signupValues.countryCode2) &&
      this.state.signupValues.acceptTerms &&
      this.state.signupValues.acceptPrivateEssential &&
      this.state.signupValues.memberType !== MemberType.SELECT &&
      this.state.isValidCompany &&
      (this.state.comparySchool == 'company' && this.state.signupValues.company !== "" || this.state.comparySchool == 'school' && this.state.signupValues.school !== "") &&
      this.state.signupValues.interestFieldType !== InterestFieldType.SELECT;
  }

  closeAllPopup = () => {
    this.props.closeManageMyInfoPopup();
  };

  renderSignupInputPopUp() {
    const {country, lang} = langutil.splitLangCode(this.props.languageService.lang());
    return <div>
      <button className={"close-button"} onClick={() => {
        this.props.closeManageMyInfoPopup();
      }}
      >
        <img alt={"close_small_icon.svg"} src={SsqImages.common.icon.closeSmallIcon()}/>
      </button>
      <SignupWrap>
        <div className={"Signup_top"}>
          <div className={"popup_tit"}>
            {/* 뒤로가기
            <button onClick={() => this.setSignupPage(0)}>
              <img alt={"back_button.svg"} src={SsqImages.header.icon.backButton()}/>
            </button>
            */}
            {this.getTranslation("I18N_MANAGE_USERINFO")}
          </div>
          <div className={"popup_noti"}>
            {this.getTranslation("I18N_MANAGE_USERINFO_NOTIFICATION")}
            <div className={"requ"}>
              <span> * </span>
              <span>{this.getTranslation("I18N_SIGNUP_MANDATORY_INPUT")}</span>
            </div>
          </div>
        </div>
        <div className={"Signup_Input"} style={{"padding": 0}}>
          <div className={"Signup-default-info"}>
            <div className={"input_row"}>
              <p>
                <span>{this.getTranslation("I18N_SIGNUP_EMAIL")}</span>
                <span> * </span>
              </p>
              <div>
                <input type={"text"} aria-label={"signup-input-email"}
                  disabled={true}
                  value={this.state.signupValues.email}
                />
              </div>
            </div>
            {this.state.signupValues.loginType === "SSQ" &&
              <div className={"input_row"}>
                <p>
                  <span>{this.getTranslation("I18N_TITLE_PASSWORD")}</span>
                  {this.state.scrollPosition == "password" ? <span ref={this.homeRef}> * </span> :
                    <span> * </span>}
                </p>
                <div>
                  <input
                    type={"password"} aria-label={"signup-input-password"}
                    style={(!this.state.isValidPassword) ? {border: 'solid 1px #E6003E', fontSize: '2rem'} : {}}
                    maxLength={17}
                    value={this.state.signupValues.password}
                    onChange={(e) => {
                      this.setSignupValues('password', e.target.value);

                      this.verifyPassword(e.target.value);
                      if (this.state.isValidPassword && this.state.signupValues.passwordCheck !== "") {
                        this.verifyPasswordCheck(e.target.value, this.state.signupValues.passwordCheck);
                      }
                      this.setState({isNoInputPassword: false});
                    }}
                    placeholder={this.getTranslation("I18N_SIGNUP_INVALID_PASSWORD_PLACEHOLDER")}
                  />
                  {!this.state.isValidPassword &&
                    <div
                      className={"SingUpInvalidAlert"}>{this.getTranslation("I18N_SIGNUP_INVALID_PASSWORD_ALERT")}</div>}
                </div>
              </div>}
            {this.state.signupValues.loginType === "SSQ" &&
              <div className={"input_row input_pw_check"}>
                <p>
                  <span>{this.getTranslation("I18N_TITLE_PASSWORD_CHECK")}</span>
                  {this.state.scrollPosition == "passwordCheck" ?
                    <span ref={this.homeRef}> * </span> : <span> * </span>}
                </p>
                <div>
                  <input
                    disabled={!this.state.isValidPassword || this.state.isNoInputPassword}
                    type={"password"} aria-label={"signup-input-password-check"}
                    style={(this.state.isValidPassword && !this.state.isCheckedPassword) ? {border: 'solid 1px #E6003E'} : {}}
                    maxLength={17}
                    value={this.state.signupValues.passwordCheck}
                    onChange={(e) =>
                      this.setSignupValues('passwordCheck', e.target.value)}
                    onBlur={() => this.verifyPasswordCheck(
                      this.state.signupValues.password, this.state.signupValues.passwordCheck)}
                  />
                  {!this.state.isCheckedPassword && this.state.isValidPassword &&
                    <div
                      className={"SingUpInvalidAlert"}>{this.getTranslation("I18N_SIGNUP_PASSWORD_CHECK_ALERT")}</div>}
                </div>
              </div>}

            <div className={"input_row"}>
              <p>
                <span>{this.getTranslation("I18N_SIGNUP_FIRST_NAME")}</span>
                {this.state.scrollPosition == "firstName" ? <span ref={this.homeRef}> * </span> :
                  <span> * </span>}
              </p>
              <div>
                <input type={"text"} aria-label={"signup-input-name"}
                  maxLength={20}
                  value={this.state.signupValues.name}
                  style={(!this.state.isValidFirstname) ? {border: 'solid 1px #E6003E'} : {}}
                  onChange={(e) =>
                    this.setSignupValues('name', e.target.value)}
                  onBlur={async () => this.verfyFirstname(this.state.signupValues.name)}
                  placeholder={this.getTranslation("I18N_SIGNUP_NAME_PLACEHOLDER")}
                />
                {!this.state.isValidFirstname &&
                  <div
                    className={"SingUpInvalidAlert"}>{this.getTranslation("I18N_SIGNUP_NAME_INVALID_ALERT")}</div>}
              </div>
            </div>

            <div className={"input_row"}>
              <p>
                <span>{this.getTranslation("I18N_SIGNUP_LAST_NAME")}</span>
                {this.state.scrollPosition == "lastName" ? <span ref={this.homeRef}> * </span> :
                  <span> * </span>}
              </p>
              <div>
                <input type={"text"} aria-label={"signup-input-name"}
                  value={this.state.signupValues.familyName}
                  maxLength={20}
                  style={(!this.state.isValidLastname) ? {border: 'solid 1px #E6003E'} : {}}
                  onChange={(e) =>
                    this.setSignupValues('familyName', e.target.value)}
                  onBlur={async () => this.verfyLastname(this.state.signupValues.familyName)}
                  placeholder={this.getTranslation("I18N_SIGNUP_NAME_PLACEHOLDER")}
                />
                {!this.state.isValidLastname &&
                  <div
                    className={"SingUpInvalidAlert"}>{this.getTranslation("I18N_SIGNUP_NAME_INVALID_ALERT")}</div>}
              </div>
            </div>

            <div className={"input_row input_memtype"}>
              <p>
                <span>{this.getTranslation("I18N_SIGNUP_COUNTRY")}</span>
                {this.state.scrollPosition == "nationality" ? <span ref={this.homeRef}> * </span> :
                  <span> * </span>}
              </p>
              <div>
                <InputWrapForSelect className={"select_wrap"}>
                  <select aria-label={'select-memberType'}
                    value={this.state.signupValues.countryCode2}
                    onChange={(e) =>
                      this.setSignupValues('countryCode2', e.target.value)}
                    style={
                      (this.state.isClickedSignupButton && ["", null].includes(this.state.signupValues.countryCode2)) ?
                        {border: 'solid 1px #E6003E'} : {}
                    }
                  >
                    <option value={""} hidden={true}>
                      {this.getTranslation("I18N_SIGNUP_COUNTRY_TYPE_SELECT")}
                    </option>
                    {this.state.countryCodes.map((country, index) => {
                      if (![null, ""].includes(country.countryCode2) && ![null, ""].includes(country.countryNameKo)) {
                        return <option key={index}
                          value={country.countryCode2}
                        >{this.props.languageService.getLanguage() == LANG_KO_KR ? country.countryNameKo : country.countryName}</option>;
                      }
                    })}
                  </select>
                  {(this.state.isClickedSignupButton && ["", null].includes(this.state.signupValues.countryCode2)) &&
                    <div
                      className={"SingUpInvalidAlert"}>{this.getTranslation("I18N_SIGNUP_REQUIRED_ALERT")}</div>}
                </InputWrapForSelect>
              </div>
            </div>

            <div className={"input_row input_phone"}>
              <p>{this.getTranslation("I18N_SIGNUP_PHONE_NUMBER")}</p>
              <div>
                <InputWrapForSelect className={"select_wrap"}>
                  <select aria-label={'select-country-code'}
                    value={this.state.signupValues.countryCode}
                    onChange={(e) =>
                      this.setSignupValues('countryCode', e.target.value)}
                  >
                    {this.state.countryNumber.map((country, index) => {
                      return <option key={index}
                        value={country}>{country}</option>;
                    })}
                  </select>
                </InputWrapForSelect>
                <input type={"text"} aria-label={"signup-input-phoneNumber"}
                  value={this.state.signupValues.phoneNumber}
                  maxLength={15}
                  onChange={(e) =>
                    this.setSignupValues('phoneNumber', e.target.value.replace(/[^\d.]/g, '').replace(/(\..*)\./g, '$1'))}
                  placeholder={this.getTranslation("I18N_SIGNUP_PHONE_NUMBER_PLACEHOLDER")}
                />
              </div>
            </div>
          </div>
          <div className={"Signup-plus-title"}>
            <p>{this.getTranslation("I18N_SIGNUP_BUSINESS")}</p>
          </div>
          <div className={"Signup-plus-info"}>
            <div className={"input_row"}>{/*별명*/}
              <p>
                <span>{this.getTranslation("I18N_SIGNUP_NICKNAME")}</span>
                {this.state.scrollPosition == "nickname" ? <span ref={this.homeRef}> * </span> :
                  <span> * </span>}
              </p>
              <div>
                <input
                  type={"text"} aria-label={"signup-input-nickname"}
                  style={(this.state.isExistNickname || !this.state.isValidNickname) ? {border: 'solid 1px #E6003E'} : {}}
                  maxLength={10}
                  value={this.state.signupValues.nickname}
                  onChange={(e) =>
                    this.setSignupValues('nickname', e.target.value)}
                  onBlur={async (e) => {
                    const nickname = e.target.value.trim();
                    this.setSignupValues('nickname', nickname);
                    this.verifyNickname(nickname);
                  }}
                  placeholder={this.getTranslation("I18N_SIGNUP_NICKNAME_PLACEHOLDER")}
                />
                {this.state.isExistNickname &&
                  <div
                    className={"SingUpInvalidAlert"}>{this.getTranslation("I18N_SIGNUP_NICKNAME_DUPLICATE_ALERT")}</div>}
                {!this.state.isValidNickname &&
                  <div
                    className={"SingUpInvalidAlert"}>{this.getTranslation("I18N_SIGNUP_NICKNAME_INVALID_ALERT")}</div>}
              </div>
            </div>

            <div className={"input_row"}>
              <p>
                <span>{this.getTranslation("I18N_SIGNUP_INTEREST_FIELD")}</span>
                {this.state.scrollPosition == "interestFieldType" ?
                  <span ref={this.homeRef}> * </span> : <span> * </span>}
              </p>
              <div>
                <InputWrapForSelect className={"select_wrap"}>
                  <select aria-label={'select-interestFieldType'}
                    style={
                      (this.state.isClickedSignupButton && this.state.signupValues.interestFieldType === InterestFieldType.SELECT) ?
                        {border: 'solid 1px #E6003E'} : {}
                    }
                    onChange={(e) =>
                      this.setSignupValues('interestFieldType', e.target.value)}
                    value={this.state.signupValues.interestFieldType}
                  >
                    <option value={InterestFieldType.SELECT} hidden={true}>
                      {this.getTranslation(getInterestFieldTypeLangKey(InterestFieldType.SELECT))}
                    </option>
                    {this.renderInterestFieldTypeOption(InterestFieldType.RECHARGEABLE_BATTERY)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.PROCESSING_MACHINE)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.CONSTRUCTION)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.RUBBER)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.AIRPORT_AIR)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.METAL)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.DISPLAY)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.ROBOT)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.MOBILE)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.CARPENTRY)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.SEMICONDUCTOR)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.DEVELOPMENT)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.FIBER)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.WATER_TREATMENT)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.SMART_FACTORY)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.FOOD)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.ELEVATOR)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.GLASS_CEMENT)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.MEDICAL_TREATMENT)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.CAR)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.ELECTRIC)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.RESTRAINT)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.STEEL)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.CONVEYOR)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.PACKING_MACHINE)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.PORT_SHIPBUILDING)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.CHEMISTRY)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.ENVIRONMENT)}
                    {this.renderInterestFieldTypeOption(InterestFieldType.ETC)}
                  </select>
                  {(this.state.isClickedSignupButton && this.state.signupValues.interestFieldType === InterestFieldType.SELECT) &&
                    <div
                      className={"SingUpInvalidAlert"}>{this.getTranslation("I18N_SIGNUP_REQUIRED_ALERT")}</div>}
                </InputWrapForSelect>
              </div>
            </div>
            <div className={"input_row input_memtype"}>
              <p>
                <span>{this.getTranslation("I18N_SIGNUP_MEMBER_TYPE")}</span>
                {this.state.scrollPosition == "memberType" ? <span ref={this.homeRef}> * </span> :
                  <span> * </span>}
              </p>
              <div>
                <InputWrapForSelect className={"select_wrap"}>
                  <select aria-label={'select-memberType'}
                    style={
                      (this.state.isClickedSignupButton && this.state.signupValues.memberType === MemberType.SELECT) ?
                        {border: 'solid 1px #E6003E'} : {}
                    }
                    onChange={(e) =>
                      this.setSignupValues('memberType', e.target.value)}
                    value={this.state.signupValues.memberType}
                  >
                    <option value={MemberType.SELECT} hidden={true}>
                      {this.getTranslation(getMemberTypeLangKey(MemberType.SELECT))}
                    </option>
                    {this.renderMemberTypeOption(MemberType.MACHINE_MAKER)}
                    {this.renderMemberTypeOption(MemberType.SMALL_WHOLESALE)}
                    {this.renderMemberTypeOption(MemberType.SET_MAKER)}
                    {this.renderMemberTypeOption(MemberType.ELECTRIC_COMPANY)}
                    {this.renderMemberTypeOption(MemberType.DEALERSHIP)}
                    {this.renderMemberTypeOption(MemberType.PANEL_MAKER)}
                    {this.renderMemberTypeOption(MemberType.STUDENT)}
                    {this.renderMemberTypeOption(MemberType.ETC)}
                  </select>
                  {(this.state.isClickedSignupButton && this.state.signupValues.memberType === MemberType.SELECT) &&
                    <div
                      className={"SingUpInvalidAlert"}>{this.getTranslation("I18N_SIGNUP_REQUIRED_ALERT")}</div>}
                </InputWrapForSelect>
              </div>
            </div>
            <div className={"input_row"}>
              {this.state.signupValues.memberType === MemberType.STUDENT ?
                this.renderInputAreaForStudent() : this.renderInputAreaForNotStudent()}
            </div>

            {
              this.props.languageService.getLanguage() === LANG_KO_KR && <div>
                <input id={"check-accept-mail"} type={"checkbox"}
                  checked={this.state.signupValues.acceptPrivateOptional}
                  onChange={() =>
                    this.setSignupValues('acceptPrivateOptional', !this.state.signupValues.acceptPrivateOptional)}
                />
                <label htmlFor={"check-accept-private-optional"}/>
                <span className={"check_txt"}
                  onClick={() => this.setSignupValues('acceptPrivateOptional', !this.state.signupValues.acceptPrivateOptional)}
                >{this.getTranslation("I18N_SIGNUP_ACCEPT_PRIVATE_OPTIONAL")}</span>
                {renderPrivateOptionallKoKr()}
              </div>
            }
          </div>
        </div>
        <div className={"Submit-Button-Wrap"}>
          <div className={"Leave-Button"} onClick={async () => {
            if (confirm(this.getTranslation('I18N_MANAGE_USERINFO_LEAVE_STRING'))) {
              let deleteAccountRequest: IDeleteAccountRequest = {
                userId: this.state.signupValues.email
              };

              await axios.delete(`/api/member/ssqData/user/myPage/delete/${this.state.signupValues.email}`);
              await this.props.accountService.deleteAccount(deleteAccountRequest);
              await axios.delete(`/api/guest/ssqData/usertag/delete/${this.state.signupValues.email}`);
              await this.props.loginService.logout();
              this.props.closeManageMyInfoPopup();
              window.location.href = `/${country}/${lang}/main`;
            }
          }}>
            {this.getTranslation("I18N_MANAGE_USERINFO_LEAVE")}
          </div>
          <div className={"Submit-Button"} onClick={async () => {
            this.setState({isClickedSignupButton: true});

            if (this.state.signupValues.loginType === "SSQ") {
              this.verifyPassword(this.state.signupValues.password);
            }

            await this.verifyNickname(this.state.signupValues.nickname);
            await this.verfyFirstname(this.state.signupValues.name);
            await this.verfyLastname(this.state.signupValues.familyName);
            await this.verifyPasswordCheck(this.state.signupValues.password, this.state.signupValues.passwordCheck);


            if (this.verifyInputValues()) {
              let updateInputValue: IUpdateAccountRequest = {
                accountId: this.state.signupValues.email,
                password: this.state.signupValues.password,
                nickname: this.state.signupValues.nickname,
                name: this.state.signupValues.name,
                familyName: this.state.signupValues.familyName,
                company: this.state.signupValues.company,
                department: this.state.signupValues.departmentAndTitle,
                school: this.state.signupValues.school,
                major: this.state.signupValues.majorAndGrade,
                countryCode: this.state.signupValues.countryCode,
                countryCode2: this.state.signupValues.countryCode2,
                phoneNumber: this.state.signupValues.phoneNumber,
                memberType: this.state.signupValues.memberType,
                interestFieldType: this.state.signupValues.interestFieldType,
                acceptTerms: this.state.signupValues.acceptTerms,
                acceptPrivateEssential: this.state.signupValues.acceptPrivateEssential,
                acceptPrivateOptional: this.state.signupValues.acceptPrivateOptional,
                acceptMail: this.state.signupValues.acceptMail,
                loginType: this.state.signupValues.loginType
              };

              await this.props.accountService.updateAccount(updateInputValue);

              window.alert(this.getTranslation("I18N_MANAGE_USERINFO_SAVE_ALERT"));
              this.props.closeManageMyInfoPopup();
            } else {
              await this.setState({
                scrollPosition: this.setScrollPosition()
              });
              this.scrollOnClick();
            }
          }}>
            {this.getTranslation("I18N_MANAGE_USERINFO_SAVE")}
          </div>
        </div>
      </SignupWrap>
    </div>;
  }

  showTermsPopup() {
    return <SubmitTermsPopup
      languageService={this.props.languageService}
      closeAllPopupFunc={this.closeAllPopup}
      signupValues={this.state.signupValues}
      setSignupValues={this.setSignupValues}
      pageUp={this.setSignupPage}
      setAcceptAll={this.acceptAllProgressByCountry}
      acceptAll={this.state.acceptAll}
      showTermsDetailPopup={this.showTermsDetailPopup}
      isManageMyInfo={true}
      closeTermsPopup={this.closeSignupPopup}
    />;
  }

  render() {
    return <div style={{padding: 0}}>
      <div className="Signup_Popup">
        {/*this.state.signupPage == 0 && this.showTermsPopup()*/}
        {this.state.signupPage == 1 && this.renderSignupInputPopUp()}
      </div>
    </div>;
  }
}

const SignupWrap = styled.div`
  height: 100%;

  .Signup_top {
    padding: 3rem 3rem 0 3rem;
    width: 100%;
    height: 12rem;
  }

  .Signup_Input {
    width: 100%;
    height: calc(100% - 21rem);
    overflow-y: auto;
    margin-bottom: 6rem;
    position: relative;
  }

  .Submit-Button-Wrap {
    padding: 0 3rem 2rem 3rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 0;
    height: 9rem;
    border-radius: 0 0 4px 4px;

    .Leave-Button {
      border-radius: 100px;
      width: 10rem;
      height: 4.8rem;
      line-height: 4.8rem;
      margin-right: .8rem;
      margin-top: 1rem;
      background: #fff;
      color: #7783A1;
      border: 1px solid #C7D6EA;
      text-align: center;
      font-size: 1.2rem;
      cursor: pointer;

      :hover {
        background: rgba(64, 126, 219, 0.1);
      }

      :active {
        background: rgba(64, 126, 219, 0.3);
      }
    }

    .Submit-Button {
      width: calc(100% - 10.8rem) !important;
      margin-top: 1rem;
    }
  }

  .TermsDetail_content {
    ${fontFamilyRegular};
    margin: .8rem 0;
    height: 8rem;

    p {
      font-size: 1.4rem;
      margin-bottom: 1rem;
    }

    > div + div {
      margin-top: 1.6rem;
    }

    .TermsDetail {
      border: 1px solid #C7D6EA;
      border-radius: 4px;
      height: 100%;
      max-height: initial;
      overflow-y: auto;
      padding: 0.4em 1rem 1rem 1rem;
      font-size: 1.3rem;
      line-height: 1.9rem;

      .span_cursor {
        cursor: pointer;
      }

      span {
        color: #7783A1;
        display: block;
        margin-bottom: 2.4rem;
      }

      .h3_text {
        font-size: 1.4rem;
        ${fontFamilyMedium};
        margin-bottom: 2.4rem;
        color: black;
      }

      .h4_text {
        ${fontFamilyMedium};
        margin-bottom: 1.6rem;
        color: black;
      }

      .h5_text {
        ${fontFamilyMedium};
        font-size: 1.3rem;
        color: black;
      }
    }
  }

  .Signup-default-info {
    padding: 0 3rem 1rem 3rem;
  }

  .Signup-plus-title {
    border-top: 0.4rem solid #C7D6EA;
    margin-bottom: 1.4rem;

    p {
      ${fontFamilyRegular};
      font-size: 1.2rem;
      line-height: 1.4rem;
      color: #909FB7;
      margin-left: 0.8rem;
      margin-top: 0.4rem;
    }
  }

  .Signup-plus-info {
    padding: 0 3rem;
  }


  .check_txt {
    font-size: 1.3rem;
    ${fontFamilyMedium};
    margin-left: .8rem;
    vertical-align: bottom;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
  }
`;