import React from 'react';
import ModalPortal from './ModalPortal';
import {createGlobalStyle} from "styled-components";
import {fontFamilyBold, fontFamilyMedium, fontFamilyRegular} from "style/fonts";

interface Props {
  children;
  onClick?;
}

export const ModalTrans = (props: Props) => {
  return (
    <ModalPortal>
      <GlobalStyle/>
      <div style={{position: 'fixed', width: '100%', height: '100%', top: 0, left: 0, background: 'rgba(0,0,0,0)', zIndex: 10001}}
        onClick={props.onClick}>
        {props.children}
      </div>
    </ModalPortal>
  );
};


const GlobalStyle = createGlobalStyle`
  html {
    font-size: 62.5%;
    ${fontFamilyMedium};
    color: #2A2C2F;
  }

  * {
    padding: 0;
    margin: 0;
    outline: 0;
    border: 0;
    background: transparent;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
    letter-spacing: -0.2px;
  }


  button, input[type="radio"], select {
    cursor: pointer;
  }

  select {
    height: 3.2rem;
    color: #2A2C2F;

    :disabled {
      cursor: inherit;
    }
  }

  .swiper-button-arrow:after, .swiper-button-next:after, .swiper-button-prev:after {
    display: none;
  }

  *::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #7783A1;
    border-radius: 6px;
  }

  *::-webkit-scrollbar-track {
    background-color: transparent;
  }

  *::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }

  .container {
    max-width: 120rem;
    margin: 0 auto;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 600 !important;
  }

  input[type="text"], input[type="password"] {
    -webkit-appearance: none;
  }

  textarea {
    resize: none;
  }


  button {
    ${fontFamilyRegular};
  }

  .batch-button {
    cursor: pointer;
    color: white;
    border-radius: 4px;
    width: 11.2rem;
    height: 4rem;
    line-height: 4rem;
    font-size: 1.3rem;
    background: #7783A1;
    border: solid 1px #7783A1;
    text-align: center;

    ${fontFamilyBold}
    + .batch-button {
      margin-left: .8rem;
    }

    :hover {
      background: #6B758E;
    }

    :active {
      background: #596278;
    }
  }

  .post-button {
    cursor: pointer;
    color: white;
    width: 11.2rem;
    height: 4rem;
    line-height: 4rem;
    ${fontFamilyBold};
    border-radius: .4rem;
    font-size: 1.3rem;
    text-align: center;
    background: #407EDB;
    border: solid 1px #407EDB;

    :hover {
      background: #3B71C4;
    }

    :active {
      background: #3463AB;
    }
  }

  .delete-button {
    background: #fff !important;
    border: 1px solid #C7D6EA !important;
    color: #2A2C2F !important;
    ${fontFamilyMedium};

    :hover {
      background: rgba(64, 125, 217, 0.1) !important;
    }

    :active {
      background: rgba(64, 125, 217, 0.3) !important;
    }
  }

  @media screen and (max-width: 1199px) {
    .container {
      max-width: 100% !important;
    }
  }

  @media screen and (max-width: 764px) {
    html {
      font-size: 9px;
    }
  }
`;
