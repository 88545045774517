export const TargetSoftwareType = Object.freeze({
  NONE: "NONE",
  XG5000: "XG5000",
  XP_BUILDER: "XP_BUILDER",
  DRIVEVIEW: "DRIVEVIEW",
});

export const getTargetSoftwareTypeLangKey = (value: string) => {
  switch (value) {
    case TargetSoftwareType.NONE:
      return 'I18N_TARGET_SW_TYPE_NONE';
    case TargetSoftwareType.XG5000:
      return 'I18N_TARGET_SW_TYPE_XG5000';
    case TargetSoftwareType.XP_BUILDER:
      return 'I18N_TARGET_SW_TYPE_XP_BUILDER';
    case TargetSoftwareType.DRIVEVIEW:
      return 'I18N_TARGET_SW_TYPE_DRIVEVIEW';
    default:
      return '';
  }
};
