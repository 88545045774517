import React, {useEffect, useState} from 'react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import styled from "styled-components";
import 'ckeditor5-custom-build/build/translations/en';
import {EditorStyle} from "style/SsqEditorStyle";
import {useIsMobile} from "hooks/useIsMobile";

import './ssqeditor.css';
import clsx from 'clsx';
// function SpecialCharactersEmoji(editor) {
//   editor.plugins.get('SpecialCharacters').addItems('Emoji', [
//     {title: 'smiley face', character: '😊'},
//     {title: 'rocket', character: '🚀'},
//     {title: 'wind blowing face', character: '🌬️'},
//     {title: 'floppy disk', character: '💾'},
//     {title: 'heart', character: '❤️'}
//   ], {label: 'Emoticons'});
// }

interface Editor5Props {
  contents: string;
  onChangeData: Function;
  isDisabled?: boolean;
  width: string;
  height: string;
  lang: string;

  border?: boolean;
}

//
// function addTranslations(language, translations, getPluralForm) {
//   if (!global.window.CKEDITOR_TRANSLATIONS) {
//     global.window.CKEDITOR_TRANSLATIONS = {};
//   }
//
//   if (!global.window.CKEDITOR_TRANSLATIONS[language]) {
//     global.window.CKEDITOR_TRANSLATIONS[language] = {} as any;
//   }
//
//   const languageTranslations = global.window.CKEDITOR_TRANSLATIONS[language];
//
//   languageTranslations.dictionary = languageTranslations.dictionary || {};
//   languageTranslations.getPluralForm = getPluralForm || languageTranslations.getPluralForm;
//
//   // Extend the dictionary for the given language.
//   Object.assign(languageTranslations.dictionary, translations);
// }

const trans = {
  ko: {
    'Subtitle': '부제목'
  }, en: {
    'Subtitle': 'Subtitle'
  }
};

const t = (lang, key) => {
  return trans?.[lang]?.[key] || key;
};

const pcToolbar = [
  "heading",
  "|",
  //"bold",
  "italic",
  "underline",
  "removeFormat",
  "|",
  "highlight:yellowMarker",
  "removeHighlight",
  "|",
  "alignment:left",
  "alignment:center",
  "alignment:right",
  "|",
  "bulletedList",
  "blockQuote",
  "horizontalLine",
  "specialCharacters",
  "|",
  "imageInsert",
  "insertTable",
  "mediaEmbed",
  "link",
  "|",
  "codeBlock",
  "sourceEditing",
  "findAndReplace",
];

const mobileToolbar = ["undo", "redo", "|", ...pcToolbar];


function SsqEditor5(props: Editor5Props) {

  const [key, setKey] = useState(1);
  const isMobile = useIsMobile();

  useEffect(() => {
    setKey(key + 1);
  }, [isMobile]);

  const language = (props.lang || "").split("-")[0];

  return (
    <React.Suspense fallback={<div></div>}>
      <Container editorHeight={props.height}
        style={{height: 'calc( ' + props.height + ' + 40px )', width: props.width}} key={key} className="ssqeditor-cont">
        {/*ck-content 클래스는 에디터에서 자동으로 붙임. */}
        <EditorWrap className={clsx("SsqEditor", {'border': props.border})}
          editorHeight={isMobile ? 'calc( ' + props.height + ' + 40px )' : props.height}>
          <CKEditor
            editor={Editor}
            disabled={props.isDisabled}
            config={{
              language,
              simpleUpload: {
                uploadUrl: '/api/member/uploads/editor/image',
              },
              style: {
                definitions: [
                  {name: 'Marker', element: 'span', classes: ['marker'],},
                ]
              },
              heading: {
                options: [
                  {model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph'},
                  {model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1'},
                  {model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2'},
                  {model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3'},
                  {
                    model: 'headingFancy',
                    view: {name: 'p', classes: 'subtitle'},
                    title: t(language, 'Subtitle'),
                    class: 'ck-heading_subtitle',
                    converterPriority: 'high'
                  }
                ]
              },
              toolbar: isMobile ? mobileToolbar : pcToolbar,
              mediaEmbed: {
                previewsInData: true
              },
              list: {properties: {styles: false}},
              table: {
                contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells',
                  // 'toggleTableCaption',
                  //'tableProperties', 'tableCellProperties'
                ]
              }
            } as any}
            data={props.contents || ''}
            onReady={editor => {
              editor.editing.view.change((writer) => {
                const root = editor.editing.view.document.getRoot();
                writer.setStyle("height", props.height, root);
              });


              // 툴바목록.
              // console.log("toolbars", Array.from(editor.ui.componentFactory.names()));
              // console.log("plugins", editor.plugins);
              //console.log("plugins json", JSON.stringify(editor.plugins));
            }}
            onChange={(event, editor) => {
              const data = editor.data;
              props.onChangeData(data.get());
              //console.log({event, editor, data}, data.get());
            }}
            onBlur={(event, editor) => {
              //console.log('Blur.', editor);
            }}
            onFocus={(event, editor) => {
              //console.log('Focus.', editor);
            }}
          />
        </EditorWrap>
      </Container>
    </React.Suspense>

  );
}

const Container = styled.div<{ editorHeight: string }>`
  ${EditorStyle};

  .ck-source-editing-area > textarea {
    height: ${p => p.editorHeight};
    overflow: auto;
    //border: 1px solid #ccc;
  }

  .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
    border-radius: 0;
    border-top: 1px solid rgb(199, 214, 234);
    border-right: none;
    border-bottom: none;
    border-left: none;
    box-shadow: none;
  }

  .SsqEditor.border .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
    border-radius: 0;
    border: 1px solid rgb(199, 214, 234);
    box-shadow: none;
  }

  .ck.ck-toolbar.ck-toolbar_grouping {
    border-top: 1px solid rgb(199, 214, 234);
    border-right: none;
    border-left: none;
  }

  .SsqEditor.border .ck.ck-toolbar.ck-toolbar_grouping {
    border-top: 1px solid rgb(199, 214, 234);
    border-right: 1px solid rgb(199, 214, 234);
    border-left: 1px solid rgb(199, 214, 234);
  }

  .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar {
    border-radius: 0;
  }

  .ck-source-editing-area textarea:not([readonly]),
  .ck-source-editing-area textarea:not([readonly]):focus {
    box-shadow: none;
    border-radius: 0;
    border: none;
    border-top: 1px solid rgb(199, 214, 234)
  }

  .SsqEditor.border .ck-source-editing-area textarea:not([readonly]),
  .SsqEditor.border .ck-source-editing-area textarea:not([readonly]):focus {
    box-shadow: none;
    border-radius: 0;
    border: 1px solid rgb(199, 214, 234)
  }

`;
const EditorWrap = styled.div<{ editorHeight: string }>`
  height: ${p => p.editorHeight};

  .ck-source-editing-area {
    min-height: ${p => p.editorHeight};
  }
`;

export default SsqEditor5;